export default class UsersActivityFilterModel {
  constructor() {
    this.reset();
  }

  toSave() {
    return {};
  }

  reset() {
    this.search = '';   // full name, email, phone
    this.loginDate = {
      start: null,
      end: null
    };   // datepicker
    this.loginStatus = null;  // select
    this.userStatus = null; //select
    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);
    this.filterCount.splice(index, 1);
    switch (dataKey) {
      case "search": {
        this.search = "";
        break;
      }
      case "loginDate": {
        this.loginDate = {
          start: null,
          end: null
        };
        break;
      }
      case "loginStatus": {
        this.loginStatus = null;
        break;
      }
      case "userStatus": {
        this.userStatus = null;
        break;
      }
    }
  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes("search")) {
      this.filterCount.push("search");
    }
    if (this.loginDate?.start && this.loginDate?.end && !this.filterCount.includes("loginDate")) {
      this.filterCount.push("loginDate");
    }
    if ((this.loginStatus || this.loginStatus === 0) && !this.filterCount.includes("loginStatus")) {
      this.filterCount.push("loginStatus");
    }
    if ((this.userStatus || this.userStatus === 0) && !this.filterCount.includes("userStatus")) {
      this.filterCount.push("userStatus");
    }
  }

}
