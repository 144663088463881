<template>
  <div class="ott-input-cont" :class="customInputClass">
    <v-text-field
      :outlined="outlined"
      :label="label"
      :height="height"
      :error="error"
      :dense="dense"
      :type="type"
      :value="inputValue"
      :append-icon="appendedIcon"
      :prepend-icon="prependIcon"
      :prepend-inner-icon="prependInnerIcon"
      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="false"
      :autofocus="autofocus"
      :solo="solo"
      :min="min"
      :max="max"
      :hide-details="hideDetails"
      :list="list"
      :prefix="prefix"
      :format="format"
      @input="inputEmit"
      @change="$emit('onChange')"
      @keypress="disableNumbers"
      @keydown="$emit('onKeydown', $event)"
      @wheel="wheel"
      @focus="focus"
      @focusout="focusout"
      @blur="blur"
      @click:append="appendClick"
      @keyup.native.enter="keyupEmit"
      @click="$emit('click')"
      @paste="$emit('paste', $event)"
    />
  </div>
</template>
<script>
export default {
  name: "OttInput",
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    timeoutInput: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    appendClick: {
      type: Function,
      default: () => null
    },
    blur: {
      type: Function,
      default: () => null
    },
    wheel: {
      type: Function,
      default: () => null
    },
    focus: {
      type: Function,
      default: () => null
    },
    focusout: {
      type: Function,
      default: () => null
    },
    label: {
      type: String
    },
    solo: {
      type: Boolean,
      default: () => false
    },
    min: {
      type: [Number, String],
      default: () => null
    },
    max: {
      type: [Number, String],
      default: () => null
    },
    type: {
      type: String,
      default: "text"
    },
    height: {
      type: Number,
      default: 38
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: [Boolean, String],
      default: false 
    },
    error: {
      type: Boolean
    },
    customInputClass: {
      type: String
    },
    appendedIcon: {
      type: String
    },
    appendedIconCb: {
      type: String
    },
    prependIcon: {
      type: String
    },
    prependInnerIcon: {
      type: String
    },
    maxLength: {
      type: Number
    },
    isPercent: {
      type: Boolean,
      default: false
    },
    list: {
      type: String,
      default: ""
    },
    prefix: {
      type: String,
      default: ""
    },
    format: {
      type: String,
      default: ""
    },

    value: {}
  },
  data() {
    return {
      inputValue: "",
      timeOut: null
    };
  },
  watch: {
    value(data) {
      this.inputValue = data;

      if (this.timeoutInput) {
        this.fetchEntriesDebounced()
      }

    }
  },
  mounted() {
    this.inputValue = this.value;
    if (this.maxLength) {
      let x = document.querySelector(`.${this.customInputClass} input`);
      x.setAttribute("maxlength", this.maxLength.toString());
    }
  },
  methods: {
     fetchEntriesDebounced() {
      // cancel pending call
      clearTimeout(this.timeOut)

      // delay new call 300ms
      this.timeOut = setTimeout(() => {
        this.$emit('timeoutInput', this.value)
      }, 300)
    },
    bounceInput(data) {
      alert(data)
    },
    inputEmit(value) {
      this.$emit("input", value);
      this.$emit("emitFunc");
    },

    disableNumbers(e) {
      this.$emit("emitKeypress", e);
      if (this.type === "number" || this.maxLength) {
        e.returnValue =
          e.keyCode > 48 ||
          (e.keyCode < 57 && (e.keyCode !== 43 || e.keyCode !== 45)) ||
          e.keyCode === 46;
      }
      if (this.isPercent) {
        e = e ? e : window.event;
        let charCode = e.which ? e.which : e.keyCode;
        e.returnValue = !(
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
        );
      }
    },

    keyupEmit() {
      this.$emit('keyup')
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.ott-input-cont::v-deep {
  width: 100%;

  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    min-height: 30px !important;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details,
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding: 0 18px !important;
  }

  .v-text-field--outlined .v-input__slot fieldset {
    padding-left: 14px !important;
  }

  .v-input .v-label {
    font-size: $txt14 !important;
    font-weight: $regular !important;
    color: $neutral-color;
    text-transform: capitalize;
  }

  .v-input--dense .v-label {
    top: 8px !important;
  }

  .v-text-field input {
    line-height: 24px !important;
    font-size: $txt14;
  }

  .v-input--has-state.error--text .v-label {
    -webkit-animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .v-text-field--outlined.v-input--has-state fieldset,
  .v-text-field--outlined.v-input--is-focused fieldset {
    border: 1.5px solid;
  }
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
  > .v-input__control
  > .v-input__slot:hover
  fieldset {
  color: $neutral-color;
}

.theme--light::v-deep {
  &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: $neutral-color;
  }

  .v-label {
    color: $neutral-color;
    text-transform: capitalize !important;
  }

  .v-text-field__slot input {
    color: $secondary-color !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: $neutral-color;
  }
}

.theme--dark::v-deep {
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: $primary-colorDM !important;
  }

  .v-label {
    color:$dark-gray !important;
    text-transform: capitalize !important;
  }

  .v-text-field__slot input {
    color: $white !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color:$dark-gray !important;
  }
}
</style>
