const Chat = () => import("@/views/chat/Chat");

export const chatRout = {
  path: "/chat",
  redirect: "/",
  name: "chat",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "",
      name: "",
      component: Chat,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    }
  ]
};
