const InitialState = {

  isSelectAll: false,
  selectedPackagesList: []

};

export const packagesDiscount = {
  namespaced: true,
  actions: {},

  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {

    setPackagesData(state, data) {
      state.selectedPackagesList = data
    },

    updatePackageGroup(state, data) {

      if (state.selectedPackagesList.includes(data.id)) {
        let index = state.selectedPackagesList.indexOf(data.id)
        state.selectedPackagesList.splice(index, 1)
      } else {
        state.selectedPackagesList.push(data.id)
      }
    },

    resetPackageGroupList(state) {
      state.selectedPackagesList = []
    },

    selectAllPackageGroupList(state, packagesList) {

      if (state.selectedPackagesList.length !== packagesList.length) {
        packagesList.forEach(item => {
          if (!state.selectedPackagesList.includes(item.id)) {
            state.selectedPackagesList.push(item.id)
          }
        })
      } else {
        state.selectedPackagesList = []
      }
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
