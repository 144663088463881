function createScript(src, id, async = true) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;
    script.id = id;
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}

export default createScript
