export default class PackagesFilterModel {
  constructor() {
    this.reset();
  }

  toSave() {
    return {};
  }

  reset() {
    this.search = '';
    this.room = null;
    this.clientPriceGroup = '';
    this.clientDiscounts = null;
    this.providerPriceGroup = '';
    this.clientsTotal = [0, 0];
    this.clients = [0, 0];
    this.providerDiscounts = null;
    this.liveService = false;
    this.archiveService = false;
    this.timeshiftService = false;
    this.vodService = false;
    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);
    this.filterCount.splice(index, 1);
    switch (dataKey) {
      case 'search': {
        this.search = ''
        break;
      }
      case 'room': {
        this.room = null
        break;
      }
      case 'clientPriceGroup': {
        this.clientPriceGroup = ''
        break;
      }
      case 'clientDiscounts': {
        this.clientDiscounts = null
        break;
      }
      case 'providerPriceGroup': {
        this.providerPriceGroup = ''
        break;
      }
      case 'clientsTotal': {
        this.clientsTotal = [0, 0]
        break;
      }
      case 'clients': {
        this.clients = [0, 0]
        break;
      }
      case 'providerDiscounts': {
        this.providerDiscounts = null
        break;
      }
      case 'liveService': {
        this.liveService = false
        break;
      }
      case 'archiveService': {
        this.archiveService = false
        break;
      }
      case 'timeshiftService': {
        this.timeshiftService = false
        break;
      }
      case 'vodService': {
        this.vodService = false
        break;
      }
    }
  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes('search')) {
      this.filterCount.push('search');
    }
    if (this.room && !this.filterCount.includes('room')) {
      this.filterCount.push("room");
    }
    if ((this.clientPriceGroup || this.clientPriceGroup === null) && !this.filterCount.includes('clientPriceGroup')) {
      this.filterCount.push("clientPriceGroup");
    }
    if (this.clientDiscounts && !this.filterCount.includes('clientDiscounts')) {
      this.filterCount.push("clientDiscounts");
    }
    if ((this.providerPriceGroup || this.providerPriceGroup === null) && !this.filterCount.includes('providerPriceGroup')) {
      this.filterCount.push("providerPriceGroup");
    }
    if (
      (this.clients[0] || this.clients[1]) &&
      !this.filterCount.includes("clients")
    ) {
      this.filterCount.push("clients");
    }
    if  (
      (this.clientsTotal[0] || this.clientsTotal[1]) &&
      !this.filterCount.includes("clientsTotal")
    )  {
      this.filterCount.push("clientsTotal");
    }
    if (this.providerDiscounts && !this.filterCount.includes('providerDiscounts')) {
      this.filterCount.push("providerDiscounts");
    }
    if (this.liveService && !this.filterCount.includes('liveService')) {
      this.filterCount.push("liveService");
    }
    if (this.archiveService && !this.filterCount.includes('archiveService')) {
      this.filterCount.push("archiveService");
    }
    if (this.timeshiftService && !this.filterCount.includes('timeshiftService')) {
      this.filterCount.push("timeshiftService");
    }
    if (this.vodService && !this.filterCount.includes('vodService')) {
      this.filterCount.push("vodService");
    }
  }
}
