import RequestService from "@/services/request.service";
import moment from 'moment';
import ProvidersFilterModel from "../../models/providers/ProvidersFilterModel";
const FileDownload = require('js-file-download');

const InitialState = {
  modal: {
    isOpen: false,
    rejectModal: false,
  },
  createProviderModal: {
    isOpen: false,
  },
  activeTab: null,
  disableTabs: 0,
  disableButton: true,
  hasValidTwilio: false,
  hasValidSmtp: false,
  showList: [
    // { name: "General info", title: "generalInfo" },
    { name: "Company Info", title: "companyInfo" },
    { name: "Contact", title: "contact" },
    { name: "Admin Personal info", title: "adminPersonalInfo" },
    { name: "Company Addresses", title: "companyAddresses" },
    {
      name: "Info For Clients Apps",
      title: "contactInfoForClientsApps",
    },
    { name: "Permissions & Settings", title: "permissionsAndSettings" },
    { name: "Balance/Credit", title: "balanceCredit" },
    { name: "Payment Methods", title: "paymentMethods" },
    { name: "Bill/Invoices Generation", title: "billInvoicesGeneration" },
    { name: "Label/Receipt Printers", title: "labelReceiptPrinters" },
    { name: "Sale Taxes", title: "saleTaxes" },
    { name: "Payment Gateway", title: "paymentGateway" },
    { name: "Shipping Providers", title: "shippingProviders" },
    { name: "Other Api Integrations", title: "otherApiIntegrations" },
    { name: "Conversation API", title: "conversationApi" },
    { name: "Ui & Access Customization", title: "uiAndAccessCustomization" },
  ],
  providerData: null,
  providerId: null,

  // 613a019dbde2824b58b2f4fa
  updateData: null,

  //  for  pagination  table
  dataCount: 0,
  currentPage: 0,
  totalPages: 0,
  filter: {
    limit: 20,
    sortBy: "_id:desc",
    page: 1,
  },
  dataTable: [],

  //   filter ranges min and max values
  filterRangeData: {
    balance: [0, 0],
    clients: [0, 0],
    creditEndRemainDay: [0, 0],
    currentMonthIncome: [0, 0],
    currentMonthPayments: [0, 0],
    debt: [0, 0],
    resellers: [0, 0],
    totalLogins: [0, 0],
    activeLogins: [0, 0],
    creditAmount: [0, 0],
  },

  filterModel: new ProvidersFilterModel(),
  dataMain: null,

  currentProviderId: null,
  selectedProvider: null,

  // for  update  table  columns
  defaultSettings: [],
  updateColumnsFromBack: "addProviderModal/updateProviderSettings",
  updateColumnSettingsCommit: "addProviderModal/setDefaultSettings",
  updateColumnSettingsBackCommit: "addProviderModal/setDefaultSettingsForBack",
  getSubList: "addProviderModal/getSubList",
  isDefault: true,

  // provider list total data
  totalResellers: null,
  totalClients: null,
  totalActiveLogins: null,
  totalLogins: null,
  totalInactiveLogins: null,
  totalCurrentMonthPayments: null,
  totalCurrentMonthIncome: null,
  totalCreditFromParent: null,
  totalDebt: null,
  totalOwnChannels: null,

  providersResult: false,
  isAddReseller: false,
  providerList: [], 
  isTableLoading: false,
  parentId: null
};

export const addProviderModal = {
  namespaced: true,
  state: InitialState,
  actions: {
    getProviderSettings({ commit, state }) {
      return RequestService.read(`users/settings`)
        .then((data) => {
          const limitData = data?.data?.ottProviderTableSettings?.limit ? data.data.ottProviderTableSettings.limit : state.filter.limit
          commit("setDefaultSettingsForBack", data.data);
          commit('setLimit', limitData)
          return Promise.resolve(data.data);
        })
        .catch((e) => console.log(e))
    },
    updateProviderSettings({ state, rootState, dispatch }, data) {
      return RequestService.update(`users/settings`, {
        ottProviderTableSettings: {
          providerDefaultSettings: data,
          isDefault: state.isDefault,
          limit: state.filter.limit
        },
      }).then(
        async (data) => {
          await dispatch(
            "addProviderModal/getProvidersList",
            rootState.addProviderModal.filter,
            { root: true }
          );
          return Promise.resolve(data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getSubList({ state, commit }, row) {
      return RequestService.read(`ottProviders?parentId=${row.id}`).then(
        (data) => {
          const body = {
            parentId: row.id,
            data: data.data,
          };
          // commit('setSubList', body)
          return Promise.resolve(data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getProvidersList({ rootState, state, commit }, filter = null) {
      let options = {
        page: state.currentPage ? state.currentPage : 1
      }

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      if (filter) {
        options.limit = state.filter.limit
        Object.assign(options, filter)
      } else {
        Object.assign(options, state.filter)
      }
      
      let query = Object.keys(options).map(
          key => key + '=' + options[key]
      ).join('&')

      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = "blob";
      } else {
        commit('setData', { isTableLoading: true })
      }

      return RequestService.read(`ottProviders?${query}`, readOptions)
        .then((data) => {
          if (!isExcelRequest) {
            if (data.data.results && data.data.results.length) {
              commit("setProvidersResult", true);
            } else {
              commit("setProvidersResult", false);
            }
            commit('setData', { isTableLoading: false });
            commit("fetchDataList", data.data);
            commit("companyInfo/setIsLoading", false, { root: true });
            commit("adminPersonalInfoModule/setIsLoading", false, {
              root: true,
            });
            return Promise.resolve(data.data);
          } else {
            FileDownload(data.data, "provider.xlsx"); // TODO actual file name from response.headers
          }
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          commit('setData', { isTableLoading: false })
        });
    },

    deleteProvider({ rootState, dispatch }, { providerId }) {
      return RequestService.update(`ottProviders/delete/${providerId}`)
        .then(async (response) => {
          await dispatch(
            "addProviderModal/getProvidersList",
            rootState.addProviderModal.filter,
            { root: true }
          );
          return Promise.resolve(response.data);
        })
        .catch((e) => console.log(e));
    },
    createProvider({ commit, rootState, dispatch }, data) {
      return RequestService.create("ottProviders/addByAdmin", data)
        .then(async (data) => {
            await dispatch(
              "addProviderModal/getProvidersList",
              rootState.addProviderModal.filter,
              { root: true }
            );
            return Promise.resolve(data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
    },
    editProvider({ rootState, state, dispatch, commit }, data) {
      return RequestService.update(
        `ottProviders/edit/${state.providerId}`,
        data
      )
        .then(
          async (data) => {
            await dispatch(
              "addProviderModal/getProvidersList",
              rootState.addProviderModal.filter,
              { root: true }
            );
            return Promise.resolve(data.data);
          },
          (error) => {
            return Promise.reject(error);
          }
        )
        .finally(() => {
          commit("companyInfo/setIsLoading", false, { root: true });
        });
    },
    getProvider({ commit, state }, providerId = null) {
      let provider = providerId ? providerId : state.providerId
      return RequestService.read(
        `ottProviders/edit/${provider}`
      ).then(
        (data) => {
          if (data?.data?.parent) {
            commit('setData', { 
              parentId: data?.data?.parent?._id,
            })
          }

          commit('setData', {
            hasValidTwilio: data.data?.hasValidTwilio,
            hasValidSmtp: data.data?.hasValidSmtp
          })
          return Promise.resolve(data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getProviderWitfoutMutation({ commit, state }, providerId = null) {
      let provider = providerId ? providerId : state.providerId
      return RequestService.read(`ottProviders/edit/${provider}`).then((data) => Promise.resolve(data.data))
      .catch((error) => console.log(error));
    },
    validateEmail({ commit }, queryOptions) {
      let query = Object.keys(queryOptions)
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      return RequestService.read(`ottProviders/emails/check-mail?${query}`).then(
        async (data) => {
          return Promise.resolve(data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    validatePhone({ commit }, queryOptions) {
      let query = Object.keys(queryOptions)
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      return RequestService.read(`ottProviders/phones/check-phone?${query}`).then(
        async (data) => {
          return Promise.resolve(data.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getFiltersData({ commit, dispatch, state }) {
      let priceGroupIds = [];
      let priceGroupList = [];

      if (state.dataTable.length) {
        state.dataTable.map((provider) => {
          if ("priceGroup" in provider) {
            if (
              provider.priceGroup.length !== 0 &&
              !priceGroupIds.includes(provider.priceGroup._id)
            ) {
              priceGroupIds.push(provider.priceGroup._id);
            }
          }
        });
      }

      if (priceGroupIds.length) {
        priceGroupIds.map(async (priceGroupId) => {
          await dispatch("priceGroupAdmin/getPriceGroupById", priceGroupId, {
            root: true,
          }).then((priceGroup) => {
            priceGroup.value = priceGroup.id;
            priceGroupList.push(priceGroup);
          });
        });
      }
    },
  },
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue
    },

    setActiveTab(state, value) {
      state.activeTab = value;
    },

    /**
     * Set  provider data
     * **/
    setProviderData(state, data) {
      state.providerData = data;
    },

    /**
     * setSubList function
     * **/

    setSubList(state, data) {
      state.dataTable = data;
    },

    /**
     * get providers list
     * **/

    fetchDataList(state, data) {
      state.dataCount = data.totalResults;

      state.dataTable = data.results.map((item) => {
        if (item.resellers.length) {
          item.child = item.resellers;
        }
        return item;
      });
      state.currentPage = data.page;
      state.totalPages = data.totalPages;
      state.dataMain = data;

      state.totalResellers = data.totalResellers;
      state.totalClients = data.totalClients;
      state.totalActiveLogins = data.totalActiveLogins;
      state.totalLogins = data.totalLogins;
      state.totalInactiveLogins = data.totalInactiveLogins;
      state.totalCurrentMonthPayments = data.totalCurrentMonthPayments;
      state.totalCurrentMonthIncome = data.totalCurrentMonthIncome;
      state.totalCreditFromParent = data.totalCreditFromParent;
      state.totalDebt = data.totalDebt;
      state.totalOwnChannels = data.totalOwnChannels;

      // state.filterRangeData.balance = [0,100]
      // state.filterRangeData.clientsModule = [0,100]
      // state.filterRangeData.creditEndRemainDay = [0,100]
      // state.filterRangeData.currentMonthIncome = [0,100]
      // state.filterRangeData.currentMonthPayments = [0,100]
      // state.filterRangeData.debt = [0,100]
      // state.filterRangeData.resellers = [0,100]
      // state.filterRangeData.totalLogins = [0,100]
      // state.filterRangeData.activeLogins = [0,100]
      // state.filterRangeData.creditAmount = [0,100]
    },

    setFiltersDataRange(state, data) {
      state.filterRangeData = {
        balance: [data.balanceMin, data.balanceMax],
        clients: [data.clientsMin, data.clientsMax],
        creditEndRemainDay: [
          data.creditEndRemainDayMin,
          data.creditEndRemainDayMax,
        ],
        currentMonthIncome: [
          data.currentMonthIncomeMin,
          data.currentMonthIncomeMax,
        ],
        currentMonthPayments: [
          data.currentMonthPaymentsMin,
          data.currentMonthPaymentsMax,
        ],
        debt: [data.debtMin, data.debtMax],
        resellers: [data.resellersMin, data.resellersMax],
        totalLogins: [data.totalLoginMin, data.totalLoginMax],
        activeLogins: [data.activeLoginsMin || 0, data.activeLoginsMax || 0],
        creditAmount: [data.creditAmountMin || 0, data.creditAmountMax || 0],
      };
      state.filterRangeData.balance[0] = 0;
      state.filterRangeData.balance[1] = 100;

      state.filterRangeData.clients = [0, 100];
      state.filterRangeData.creditEndRemainDay = [0, 100];
      state.filterRangeData.currentMonthIncome = [0, 100];
      state.filterRangeData.currentMonthPayments = [0, 100];
      state.filterRangeData.debt = [0, 100];
      state.filterRangeData.resellers = [0, 100];
      state.filterRangeData.totalLogins = [0, 100];
      state.filterRangeData.activeLogins = [0, 100];
      state.filterRangeData.creditAmount = [0, 100];
    },

    openModal(state, data) {
      state.modal.isOpen = true;
      // state.showList["Company info"] = true;
    },
    closeModal(state, data) {
      state.modal.isOpen = false;
      // state.showList["Company info"] = false;
    },
    clickActiveTab(state, data) {
      state.activeTab = data;
    },
    nextTab(state, data) {
      if (state.activeTab < state.showList.length) {
        state.activeTab = ++state.activeTab;
        if (state.activeTab > state.disableTabs) {
          state.disableTabs = ++state.disableTabs;
        }
      }
    },
    resetActiveTabs(state) {
      state.activeTab = 0;
      state.disableTabs = 0;
      state.disableButton = true;
      state.providerId = null;
      state.providerData = null;
      state.updateData = null;
      state.currentProviderId = null;
      state.selectedProvider = null;
    },
    disableSaveCloseButton(state) {
      state.disableButton = false;
      state.disableTabs = state.showList.length;
    },
    setProviderId(state, id) {
      state.providerId = id;
    },

    openCreateModal(state) {
      state.createProviderModal.isOpen = true;
    },
    closeCreateModal(state) {
      state.createProviderModal.isOpen = false;
    },
    setUpdateData(state, value) {
      state.updateData = value;
    },

    /**
     * Set filter pagination
     * **/

    setFilterPage(state, page) {
      state.filter.page = page;
      state.currentPage = page;
    },

    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filter.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filter?.sortBy) {
          state.filter.sortBy = "_id:desc";
        }
      }
    },

    /**
     * Filter Data
     * **/

    filterProviders(state) {
      if (state.filterModel.search) {
        state.filter.search = state.filterModel.search;
      } else {
        delete state.filter.search;
      }

      if (state.filterModel.country) {
        state.filter.country = state.filterModel.country;
      } else {
        delete state.filter.country;
      }
      if (state.filterModel.clientsLimit[1]) {
        state.filter.clientsFrom = state.filterModel.clientsLimit[0];
        state.filter.clientsTo = state.filterModel.clientsLimit[1];
      } else {
        delete state.filter.clientsFrom;
        delete state.filter.clientsTo;
      }
      if (state.filterModel.resellersLimit[1]) {
        state.filter.resellersFrom = state.filterModel.resellersLimit[0];
        state.filter.resellersTo = state.filterModel.resellersLimit[1];
      } else {
        delete state.filter.resellersFrom;
        delete state.filter.resellersTo;
      }
      if (state.filterModel.totalLoginsLimit[1]) {
        state.filter.totalLoginsFrom = state.filterModel.totalLoginsLimit[0];
        state.filter.totalLoginsTo = state.filterModel.totalLoginsLimit[1];
      } else {
        delete state.filter.totalLoginsFrom;
        delete state.filter.totalLoginsTo;
      }
      if (state.filterModel.activeLogins[1]) {
        state.filter.activeLoginsFrom = state.filterModel.activeLogins[0];
        state.filter.activeLoginsTo = state.filterModel.activeLogins[1];
      } else {
        delete state.filter.activeLoginsFrom;
        delete state.filter.activeLoginsTo;
      }
      if (state.filterModel.priceGroup !== '' && state.filterModel.priceGroup !== null) {        
        if (state.filterModel.priceGroup === 'default') {
          state.filter.priceGroup = null;
        } else {
          state.filter.priceGroup = state.filterModel.priceGroup;
        }
      } else {
        delete state.filter.priceGroup;
      }    
  
      if (state.filterModel.balance[1]) {
        state.filter.balanceFrom = state.filterModel.balance[0];
        state.filter.balanceTo = state.filterModel.balance[1];
      } else {
        delete state.filter.balanceFrom;
        delete state.filter.balanceTo;
      }
      if (state.filterModel.debt[1]) {
        state.filter.debtFrom = state.filterModel.debt[0];
        state.filter.debtTo = state.filterModel.debt[1];
      } else {
        delete state.filter.debtFrom;
        delete state.filter.debtTo;
      }

      if (state.filterModel.creditAmount[1]) {
        state.filter.creditAmountFrom = state.filterModel.creditAmount[0];
        state.filter.creditAmountTo = state.filterModel.creditAmount[1];
      } else {
        delete state.filter.creditAmountFrom;
        delete state.filter.creditAmountTo;
      }
      if (
        state.filterModel.creditDate?.start &&
        state.filterModel.creditDate?.end
      ) {
        state.filter.creditDateFrom = 
          moment(state.filterModel.creditDate?.start).format('MM/DD/YYYY');
        state.filter.creditDateTo =
          moment(state.filterModel.creditDate?.end).format('MM/DD/YYYY');
      } else {
        delete state.filter.creditDateFrom;
        delete state.filter.creditDateTo;
      }
      if (state.filterModel.creditAutoextend) {
        state.filter.creditAutoExtend = state.filterModel.creditAutoextend;
      } else {
        delete state.filter.creditAutoExtend;
      }
      if (state.filterModel.daysRemainCredit[1]) {
        state.filter.creditDaysRemainingFrom =
          state.filterModel.daysRemainCredit[0];
        state.filter.creditDaysRemainingTo =
          state.filterModel.daysRemainCredit[1];
      } else {
        delete state.filter.creditDaysRemainingFrom;
        delete state.filter.creditDaysRemainingTo;
      }
      if (state.filterModel.currentMonthPayments[1]) {
        state.filter.currentMonthPaymentsFrom =
          state.filterModel.currentMonthPayments[0];
        state.filter.currentMonthPaymentsTo =
          state.filterModel.currentMonthPayments[1];
      } else {
        delete state.filter.currentMonthPaymentsFrom;
        delete state.filter.currentMonthPaymentsTo;
      }
      if (state.filterModel.currentMonthIncomes[1]) {
        state.filter.currentMonthIncomeFrom =
          state.filterModel.currentMonthIncomes[0];
        state.filter.currentMonthIncomeTo =
          state.filterModel.currentMonthIncomes[1];
      } else {
        delete state.filter.currentMonthIncomeFrom;
        delete state.filter.currentMonthIncomeTo;
      }
      if (state.filterModel.registerDate?.start && state.filterModel.registerDate?.end) {
        state.filter.dateFrom = 
          moment(state.filterModel.registerDate?.start).format('MM/DD/YYYY');
        state.filter.dateTo = 
          moment(state.filterModel.registerDate?.end).format('MM/DD/YYYY');
      } else {
        delete state.filter.dateFrom;
        delete state.filter.dateTo;
      }
      if (state.filterModel.state) {
        state.filter.state = state.filterModel.state;
      } else {
        delete state.filter.state;
      }
    },
    resetFilterModel(state) {
      state.filter = {};

      state.filter.page = 1;
      state.filter.limit = 10;
      state.filter.sortBy = "_id:desc";
    },

    /**
     * Set  settings  changes  for  column  list
     * **/
    setDefaultSettingsForBack(state, data) {
      if (
        data.ottProviderTableSettings &&
        data.ottProviderTableSettings.providerDefaultSettings
      ) {
        state.defaultSettings = data.ottProviderTableSettings.providerDefaultSettings;
        state.isDefault = data.ottProviderTableSettings.isDefault;
      }
    },
    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault;
      }
    },

    setCurrentProviderId(state, value) {
      state.currentProviderId = value;
    },

    setSelectedProvider(state, provider) {
      state.selectedProvider = provider;
    },

    setProvidersResult(state, value) {
      state.providersResult = value;
    },

    setIsAddReseller(state, value) {
      state.isAddReseller = value;
    },
    resetState(state) {
      for (let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]));
      }
    },
  },
};
