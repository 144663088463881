import RequestService from "@/services/request.service";


const InitialState = {
  // permissionsListForProviders: [
  //   {
  //     permissionType: 0,
  //     permissionsName: "Add reseller",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: true
  //   },
  //   {
  //     permissionType: 1,
  //     permissionsName: "Add Icon Set",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: true
  //   },
  //   {
  //     permissionType: 2,
  //     permissionsName: "Add TV Channels",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: false
  //   },
  //   {
  //     permissionType: 3,
  //     permissionsName: "Reorder Channels",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: true
  //   },
  //   {
  //     permissionType: 4,
  //     permissionsName: "Add TV Categories",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: false
  //   },
  //   {
  //     permissionType: 5,
  //     permissionsName: "Reorder TV Categories",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: true
  //   },
  //   {
  //     permissionType: 6,
  //     permissionsName: "Add Language",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: true
  //   },
  //   {
  //     permissionType: 7,
  //     permissionsName: "Translate Units",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: false
  //   },
  //   {
  //     permissionType: 8,
  //     permissionsName: "Custom Contact Address For Clients",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: true
  //   },
  //   {
  //     permissionType: 9,
  //     permissionsName: "Custom Domain",
  //     isOn: false,
  //     isOnChild: false,
  //     parentIsOn: true
  //   }
  // ],
  permissionsListForProviders: [],
  permissionProvidersEnum: {
    addReseller: 0,
    addIconSet: 1,
    addTvChannels: 2,
    recorderChannels: 3,
    addTvCategories: 4,
    recorderTvCategories: 5,
    addLanguage: 6,
    translateUnits: 7,
    customContactAddressForClients: 8,
    customDomain: 9
  }
};

export const permissions = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  actions: {
    getPermissionsList({commit}, {providerId}) {
      return RequestService.read(`ottProviders/permission/edit/${providerId}`)
          .then(response => {
            commit('setData', { permissionsListForProviders: response.data.permissions })
          })
          .catch(e => console.log('error', e))
    },
    editPermission({commit}, {providerId, data}) {
      return RequestService.update(`ottProviders/permission/edit/${providerId}`, data)
          .then(response => {
            console.log('response ', response.data)
          })
          .catch(e => console.log('error', e))
    }
  },
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    resetPermissions(state) {
      state.permissionsListForProviders = JSON.parse(
        JSON.stringify(InitialState)
      ).permissionsListForProviders;
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
