import RequestService from "@/services/request.service";

const InitialState = {
  initialRoles: {
    cashier: false,
    support: false,
    admin: false,
    advancedCashier: false,
    equipmentInstaller: false
  },
  roleList: [
    {
      name: 'cashier',
      value: false,
      id: 0
    },
    {
      name: 'support',
      value: false,
      id: 1
    },
    {
      name: 'admin',
      value: true,
      id: 2
    },
    {
      name: 'advancedCashier',
      value: false,
      id: 3
    },
    {
      name: 'equipmentInstaller',
      value: false,
      id: 4
    }
  ],
  selectedRoleList:[],
  isValidate:false,

};

export const roleUser = {
  namespaced: true,
  actions: {
    getRoleList({commit}){
      return RequestService.read(
        `roles/ott?limit=100000`,
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
  },

  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {    

    /**
     * Fetch Data
     * **/

    fetchData(state, data){
      if(data.roles.length){
        state.selectedRoleList = data.roles.map(item=> item.id)
      }else{
        state.selectedRoleList = []
      }
    },

    /**
     * Get Role  list
     * **/
    fetchDataRole(state, roles){
      state.initialRoles = {...state.initialRoles,...roles};
      for(let i in state.initialRoles) {
        state.roleList.map(item => { 
          if (item.name === i) {
            item.value = state.initialRoles[i]
          }
        })
      }
      // state.roleList = data.results
    },

    /**
     * Select Role For  User
     * **/

    selectRole(state, {roleName, roleValue}){  
      // state.roleList.map(item => item.name === roleName ? item.value = roleValue : '')    
      // let index = state.selectedRileList.indexOf(data.data.id);
      // if (index >= 0) {
      //   state.selectedRileList.splice(index, 1);
      // } else {
      //   state.selectedRileList.push(data.data.id);
      // }
    },
    resetData(state){
      state.selectedRoleList = [];
      state.isValidate = false

    },

    resetRolesList(state) {
      state.roleList = JSON.parse(JSON.stringify(InitialState.roleList))
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
