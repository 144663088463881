export default class UsersFilterModel {
  constructor() {
    this.reset();
  }

  toSave() {
    return {
      search: this.search
    };
  }

  reset() {
    this.search = "";
    this.roles = [];
    this.enable = null;

    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);
    this.filterCount.splice(index, 1);
    switch (dataKey) {
      case "search": {
        this.search = "";
        break;
      }
      case "roles": {
        this.roles = [];
        break;
      }
      case "enable": {
        this.enable = null;
        break;
      }
    }
  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes("search")) {
      this.filterCount.push("search");
    }
    if (this.roles.length && !this.filterCount.includes("roles")) {
      this.filterCount.push("roles");
    }
    if (this.enable && !this.filterCount.includes("enable")) {
      this.filterCount.push("enable");
    }
  }

}
