export default {
    methods: {
        getCardBrand(brand) {
            let brandIcon = ''
            switch (brand) {
                case 'visa':
                    brandIcon = '/creditCard/visa1.svg'
                    break;
                case 'mastercard':
                    brandIcon = '/creditCard/mastercard.svg'
                    break;
                case 'discover':
                    this.$vuetify.theme.dark
                        ? brandIcon = '/creditCard/discover.png'
                        : brandIcon = '/creditCard/discover1.svg'
                    break;
                case 'jcb':
                    brandIcon = '/creditCard/jcb1.svg'
                    break;
                case 'amex':
                    brandIcon = '/creditCard/american-express1.svg'
                    break;
                case 'unionpay':
                    brandIcon = '/creditCard/UnionPay.svg'
                    break;              
            }
            return brandIcon
        },
    }
}