<template>
  <div class="Ott-switch-cont" :class="customSwitchClass">
    <v-switch
      v-model="model"
      :inset="inset"
      :label="label"
      :ripple="ripple"
      :error="error"
      :value="value"
      :disabled="disable"
      @change="changeSwitch"
    >
      <template slot="label">
        <slot name="customLabel"></slot>
        <p :class="className" class="P-text">{{ label }}</p>
      </template>
    </v-switch>
  </div>
</template>

<script>
export default {
  name: "OttSwitch",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    inset: {
      type: Boolean,
      default: true
    },
    disable: {
      type: Boolean,
      default: false
    },
    ripple: {
      type: Boolean,
      default: false
    },
    customSwitchClass: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    }
  },
  data() {
    return {
      model: this.value
    };
  },
  watch: {
    model(currentValue) {
      this.$emit("input", currentValue);
    }
  },
  methods: {
    changeSwitch(value) {
      this.$emit("input", value);
      this.$emit("emitFunction", value);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";

.secondaryColor {
  color: $secondary-color;
}
.Ott-switch-cont::v-deep {
  .v-input--switch--inset .v-input--switch__track {
    height: 18px;
    width: 32px;
    margin-top: 5px;
    left: -2px;
  }
  .v-input--switch--inset .v-input--switch__track,
  .v-input--switch--inset .v-input--selection-controls__input {
    width: 32px;
  }
  .v-input--switch__thumb {
    width: 14px;
    height: 14px;
    background-color: white;
    top: calc(50% - 7px);
  }
  .v-input--switch--inset .v-input--switch__track {
    opacity: 1;
  }
  .theme--light.v-input--switch .v-input--switch__track {
    color: $neutral-color45;
  }
  .theme--dark.v-input--switch .v-input--switch__track {
    color: $neutralBtnMD;
  }
  .v-input--selection-controls__input {
    margin-right: 15px;
    height: 18px;
  }
  .v-input__slot {
    height: 18px;
  }
  .v-input input,
  .v-input textarea {
    height: 18px;
  }
  .v-label {
    font-size: $txt14;
    text-transform: capitalize !important;
  }
  .theme--light {
    &.v-label {
      color: $secondary-color;
    }
    &.v-icon {
      color: $neutral-color45;
    }
  }
  .theme--dark{

  }
  .v-input--selection-controls .v-input__slot > .v-label p,
  .v-input--selection-controls .v-radio > .v-label p {
    margin-bottom: 0 !important;
  }
  .v-input--switch--inset.v-input--is-dirty
    .v-input--selection-controls__ripple,
  .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(14px, 0) !important;
  }
  .v-input--is-label-active .v-input--switch__track {
    background: $primary-color !important;
  }
  .theme--dark {
    &.v-label {
      color: $white;
    }

    &.v-input--switch__thumb {
      //background: $darkBgLighterDM;
    }
    &.v-input--is-label-active .v-input--switch__track {
      background: $primary-colorDM !important;
    }
    .P-text{
      color: $white;
    }
  }
  .P-text {
    text-transform: capitalize;
  }
}

/*.v-input--switch .v-input--switch__track {*/
/*  color: red;*/
/*}*/
</style>
