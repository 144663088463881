const InitialState = {
  isValid: false,
  companyInfo: {
    companyName: null,
    website: null,
    country: null,
    numberOfCustomers: null,
    numberOfTvChannel: null,
    brieflyDescribeProject: null,
    comment: null,
    timezone: null,
    priceGroup: null
  },
  isLoading: false
};

export const companyInfo = {
  namespaced: true,
  actions: {},
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setCompanyInfoKey(state, data ) {
      for (const key in data) {
        state.companyInfo[key] = data[key]
      }
    },
    setDataCompanyInfo(state, data) {
      if (data.companyName) {
        state.companyInfo.companyName = data.companyName;
      }
      if (data.website) {
        state.companyInfo.website = data.website;
      }
      if (data.country) {
        state.companyInfo.country = data.country;
      }
      if ('numberOfCustomers' in data) {
        state.companyInfo.numberOfCustomers = data.numberOfCustomers;
      }
      if ('numberOfTvChannel' in data) {
        state.companyInfo.numberOfTvChannel = data.numberOfTvChannel;
      }
      if (data.brieflyDescribeProject) {
        state.companyInfo.brieflyDescribeProject = data.brieflyDescribeProject;
      }
      if (data.comment) {
        state.companyInfo.comment = data.comment;
      }
      if (data.timezone) {
        state.companyInfo.timezone = data.timezone;
      }
      state.companyInfo.priceGroup = data.priceGroup
    },
    updateCompanyInfo(state, data) {
      if (data.name.length) {
        state.companyInfo.companyName = data.name[0].name;
      }
      if (data.website) {
        state.companyInfo.website = data.website;
      }
      if (data.country) {
        state.companyInfo.country = data.country;
      }
      if ('clientAmount' in data) {
        state.companyInfo.numberOfCustomers = data.clientAmount;
      }
      if ('channelAmount' in data) {
        state.companyInfo.numberOfTvChannel = data.channelAmount;
      }
      if (data.description) {
        state.companyInfo.brieflyDescribeProject = data.description;
      }
      if (data.comment) {
        state.companyInfo.comment = data.comment;
      }
      if (data.timezone) {
        state.companyInfo.timezone = data.timezone;
      }

      // if (data.priceGroup) {
        state.companyInfo.priceGroup = data.priceGroup;
      // }
    },

    resetDataCompanyInfo(state) {
      state.companyInfo = JSON.parse(JSON.stringify(InitialState.companyInfo));
    },

    setIsLoading(state, value) {
      state.isLoading = value
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    },
  }
};
