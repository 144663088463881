import RequestService from "@/services/request.service";

const InitialState = {
  messageList: [],
  isLoadingMessages: true,
  chatType: 'client',
  clientId: null,
  totalPages: null,
  unreadMessages: [],
  selectedChat: null,
  isFirstMessage: true,
  packagesData: null,
  isSendMessage: false
}

export const mainChatModule = {
  namespaced: true,
  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    setMessages(state, data) {
      state.messageList = data
      let firstMessages = data.slice(0, 1)
      if (state.isFirstMessage) {
        state.selectedChat = firstMessages[0]?.client.personalInfo  
      }
    },
    setUnreads(state, data) {
      state.unreadMessages = data
    },
    setClient(state, data) {
      let firstMessages = data.slice(0, 1)
      state.clientId = firstMessages[0]?.to_client || firstMessages[0]?.from_client || firstMessages[0]?.client.id
    }
  },
  actions: {
    fetchMessages({ commit, state }, { setClient }) {
      return RequestService.read(`chats/provider?type=${state.chatType}`)
        .then(response => {
          commit('setMessages', response.data)
          if (setClient) {
            commit('setClient', response.data)
          }
          commit('setData', { isLoadingMessages: false })
          return Promise.resolve(response.data)
        })
        .catch((e) => console.log(e))
    },
    fetchUnreadMessages({ commit, state }, { provider }) {
      return RequestService.read(`chats/provider?type=${state.chatType}&unread=true`)
        .then(response => {
          commit('setUnreads', response.data)
          return Promise.resolve(response.data)
        })
        .catch((e) => console.log(e))
    }
  }
}

