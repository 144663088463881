const error403 = () => import("@/views/forbidden-error/Error403");

export const forbiddenRout = {
    path: "/error/forbidden",
    name: "Forbidden",
    component: {
        render(c) {
            return c("router-view");
        }
    },
    children: [
        {
            path: "",
            name: "",
            component: error403,
            meta: {auth: true, roles: ["ottprovider"], permissions: []}
        }

    ]
};