const equipments = () => import("@/views/equipments/Equipments");
const equipmentsTypes = () => import("@/views/equipments-types/EquipmentsTypes")

export const equipmentsRout = {
  path: "/equipments",
  redirect: "/",
  name: "Equipments",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/equipments/list",
      name: "EquipmentsList",
      component: equipments,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] },
    },
    {
      path: "/equipments/types",
      name: "EquipmentsTypes",
      component: equipmentsTypes,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] },
    },
  ],
};
