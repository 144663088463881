<template>
  <div class="auth-cont">
    <div>
      <auth-header />
      <div class="auth-body">
        <h5 class="title">Can't log in?</h5>
        <div
          class="send-icon"
          :style="{
            backgroundImage: `url( ${require('@/assets/images/designImages/send-email.svg')})`
          }"
        />
        <p class="secondary-txt">We sent a recovery link to you at</p>
        <p class="main-txt">{{ form.email }}</p>
        <span>00:{{ timer }}</span>
        <div class="footer">
          <p class="bold-txt" @click="goToLogIn">Return to log in</p>
          <span v-if="showResetButton" class="bold-txt" @click="resendPassword"
            >Resend</span
          >
        </div>
      </div>
    </div>
    <auth-footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AuthFooter from "./components/authFooter";
import AuthHeader from "./components/authHeader";

export default {
  name: "PasswordSendSuccess",
  components: { AuthHeader, AuthFooter },
  data() {
    return {
      form: { email: "" },
      timer: 30,
      showResetButton: false
    };
  },

  mounted() {
    this.timeOutSecond();
    this.form.email = sessionStorage.getItem("email");
  },

  methods: {
    ...mapActions({
      passwordRecovery: "forgotPassword/passwordRecovery"
    }),

    goToLogIn() {
      this.$router.push({ path: "/auth/sign-in" });
    },

    async resendPassword() {
      await this.passwordRecovery(this.form);
      this.timeOutSecond();
    },

    timeOutSecond() {
      let time = 30;
      this.showResetButton = false;

      let timerFunc = setInterval(() => {
        time--;
        this.timer = time;
        if (time <= 9) {
          this.timer = "0" + time;
        }
        if (time === 0) {
          this.showResetButton = true;
          clearInterval(timerFunc);
        }
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/authGeneral";
@import "./src/assets/scss/variables";
.send-icon {
  width: 120px;
  height: 120px;
  margin: 46px 0 40px 0;
}
.secondary-txt {
  font-size: $txt14;
  color: $secondary-color;
  font-weight: $regular;
  margin-bottom: 10px;
}
.main-txt {
  font-size: $txt16;
  font-weight: $semiBold;
  color: $primary-color;
}
.footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $neutral-color45;
  width: 100%;
  margin-top: 25px;
  padding-top: 15px;
  .bold-txt {
    font-size: $txt14;
    font-weight: $semiBold;
    color: $secondary-color;
    margin-bottom: 0;
    cursor: pointer;
    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }
  }
}
</style>
