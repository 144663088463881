import { mapMutations } from "vuex";

export default {
  props: {
    columnConfig: {
      type: Array,
      required: true,
      default: []
    },
    data: {
      type: Array,
      required: true,
      default: Array
    },
    mainData: {
      type: Array,
      required: true,
      default: Array
    },
    customClass: {
      type: String,
      required: false,
      default: ""
    },
    isDraggable: {
      type: Boolean,
      default: false
    },
    isHideHeader: {
      type: Boolean,
      default: false
    },
    isSubTable: {
      type: Boolean,
      default: false
    },
    fixedFirstAndLastColumn: {
      type: Boolean,
      default: false
    },
    isActions: {
      type: Boolean,
      default: true
    },
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    getSubList: {
      type: String,
      default: ''
    },

    showShadowOnScrollSub: {
      type: Boolean,
      default: false
    },
    selectedArrowList: {
      type: Array,
      default: []
    },
    rowData: {
      type: Object,
      default: null
    },
    mainTable: {
      type: Boolean,
      default: true
    },
    recursiveTreeIndex: {
      type: Number,
      default: 0
    },
    columnWithTreeIncrement: {
      type: Number
    },
    columnWithTree: {
      type: Number,
      default: 30
    },
    isTotalSum: {
      type: Boolean,
      default: false
    },
    selectedRowId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      selectedType: null,
      isShowTable: false,
      activeIndex: null,
      columnConfigData: [],
      dragging: null,
      draggedOver: null,
      showShadowOnScroll: false,
      activeLineOptions: {
        height: 0,
        top: 0
      }
    };
  },

  watch: {
    columnConfig: function (data) {
      this.columnConfigData = data;
    },
    data: function () {
      this.activeIndex = null
    }

  },

  methods: {
    ...mapMutations({
      setSubList: 'addProviderModal/setSubList'
    }),

    clickFunction(row, columnTitle) {
      this.$emit('clickColumnData', row, columnTitle)
    },
    /**
     * Drag and Drop  functions  columns table
     * **/
    handleDragStart() {
      const [event, index] = arguments;
      this.dragging = index;
      event.dataTransfer.effectAllowed = "move";
    },

    handleDragOver() {
      const [event, index] = arguments;
      this.draggedOver = index;
      event.target.classList.add("P-show-dragged-item");
      event.dataTransfer.dropEffect = "move";
      event.preventDefault();
    },

    handleDragLeave() {
      const [event] = arguments;
      event.target.classList.remove("P-show-dragged-item");
    },
    handleDragDrop() {
      const [event] = arguments;
      let arrayOfIndex = this.columnConfigData.map((x, index) => index);
      let index1 = arrayOfIndex.indexOf(this.dragging);
      let index2 = arrayOfIndex.indexOf(this.draggedOver);
      arrayOfIndex.splice(index1, 1);
      arrayOfIndex.splice(index2, 0, this.dragging);
      let newColumnConfig = [];
      arrayOfIndex.forEach(item => {
        newColumnConfig.push(this.columnConfigData[item]);
      });
      this.columnConfigData = newColumnConfig;
      this.updateColumns();
      this.$emit("saveColumnChangesFromDrag", arrayOfIndex);
    },

    /**
     * update  column  before change  columns
     * **/
    updateColumns() {
      this.$emit("updateColumns", this.columnConfigData);
    },

    /**
     * Open sub list  for table
     * with connected from back
     * dispatch  a  constant  value
     * @getSubList   value
     * **/
    showSubTable(event, row) {
      if (this.isSubTable && row.child && this.getSubList) {
        if (row.id === this.activeIndex) {
          this.activeIndex = null;
          this.$emit("updateColumnWith", this.recursiveTreeIndex);
        } else {
          this.$store.dispatch(this.getSubList, row).then(tableData => {

            tableData.results.map(subList => {
              if (subList.resellers.length) {
                subList.child = subList.resellers
              }
            })
            row.child = tableData.results

            this.setSubList(this.mainData)
            // console.log(tableData, 'asdasdasdasd')
            // let x =  this.getSubMenuItem(tableData.results, row.id)
            this.activeIndex = row.id;
            this.$emit("updateColumnWith", this.recursiveTreeIndex + 1);
          })
        }
      }
    },

    getSubMenuItem(dataItem, subMenuItems, selectedId) {
      this.data.map(item => {
        const {id, resellers} = item
        if (id === selectedId) {

        }
      })

      // if (dataItem.id === id) {
      //   subMenuItems.results.map(items => {
      //     if (items.resellers.length) {
      //       items.child = items.resellers
      //     }
      //     return items
      //   })
      //
      // }
      // if (subMenuItems) {
      //   for (let i = 0; i < subMenuItems.length; i++) {
      //     if (subMenuItems[i].id === id) {
      //       return subMenuItems[i];
      //     }
      //     let found = this.getSubMenuItem(subMenuItems[i].items, id);
      //     if (found) return found;
      //   }
      // }
    },


    scrollY(event) {
      if (this.fixedFirstAndLastColumn) {
        this.showShadowOnScroll = event.target.scrollLeft > 0;
      }
    },
    checkRow(row, index) {
      const body = {
        data: row,
        treeIndex: index
      };
      this.$emit("selectRow", body);
    },

    getSelectedRow(row) {
      return this.selectedArrowList.some(x => x.id === row.id);
    },
    selectRow(data) {
      this.$emit("selectRow", data);
    },

    /**
     * Sort  column table
     * **/
    sortColumns(column) {

      if (column.sortData && column.sortData.isSort) {
        this.columnConfigData.map(item => {
          if (item.sortData && item.sortData.isSort) {
            if (item.sortData.sortKey !== column.sortData.sortKey) {
              item.sortData.sortValue = ''
            }
          }
          return item
        })
        switch (column.sortData.sortValue) {
          case 'asc': {
            column.sortData.sortValue = 'desc'
            break
          }
          case 'desc': {
            column.sortData.sortValue = 'asc'
            break
          }
          default: {
            column.sortData.sortValue = 'desc'
          }
        }
        this.$emit("sortData", column.sortData);
      }
    },
    getHideColumnsCount() {
      return this.columnConfigData.filter(x => x.isShow).length === 1
    },

    uuid() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
  },

};
