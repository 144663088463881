import RequestService from "@/services/request.service";
import _ from "lodash";

const InitialState = {
  showBalanceComponents: false,
  showCreditComponents: false,
  balanceManage: {
    isReset: false,
    isSave: false,
    isEdit: false,
    paymentMethod: [
      {name: "Money Order", value: 0},
      {name: "Check", value: 1},
      {name: "Cash", value: 2}
    ],
    paymentMethodValue: null,
    routingNumber: null,
    balance: null,
    comment: null,
    isLoading: false
  },
  mainBalance: 0,
  dept: 0,
  credit: {
    isStartNow: false,
    isStart: false,
    isEdit: false,
    creditAmount: null,
    selectedCreditTerm: null,
    date: null,
    selectedCreditsPauseAfterDays: null,
    creditAutoextend: false,
    comment: null,
    radioValue: "Day",
    radio: ["Day", "Month"],
    isLoading: false,
    isStop: false
  },
  balanceCreditData: null
};
export const balanceCreditClient = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    /**
     * Set Data global for first level variables
     * **/
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    /***
     * for all second level object keys changing
     *  setDataSecondLevels({ 
     *    stateKey1: { changingKey: 'newVal' },
     *    stateKey2: { changingKey2: 'newVal' },  
     *  })
     **/
    setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if(state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },    
    
    /**
     * Save balance data  from  back
     * **/
    saveBalance(state) {
      state.balanceManage.isSave = true
      state.balanceManage.isEdit = false
    },

    /**
     * Reset balance manage for edit, change isEdit = true
     * **/
    setBalanceManageEdit(state) {
      state.balanceManage = JSON.parse(JSON.stringify(InitialState.balanceManage));
      state.balanceManage.isEdit = true;
    },

    /**
     * Reset Balance
     * **/
    resetBalanceManage(state) {
      state.balanceManage = JSON.parse(JSON.stringify(InitialState.balanceManage));
    },
    /**
     * Balance Data
     * **/
    setPaymentMethodValue(state, data) {
      state.balanceManage.paymentMethodValue = data;
    },
    setRoutingNumber(state, data) {
      state.balanceManage.routingNumber = data;
    },
    setBalance(state, data) {
      state.balanceManage.balance = data;
    },
    setComment(state, data) {
      state.balanceManage.comment = data;
    },
    setBalanceIsEdit(state, value) {
      state.balanceManage.isEdit = value
    },
    setBalanceIsSave(state, value) {
      state.balanceManage.isSave = value
    },

    /**
     * Credit Data
     * **/
    setRadio(state, data) {
      state.credit.radioValue = data;
    },
    setCreditComment(state, data) {
      state.credit.comment = data;
    },
    selectedCreditsPauseAfterDays(state, data) {
      state.credit.selectedCreditsPauseAfterDays = data;
    },
    setDate(state, date) {
      state.credit.date = date;
    },
    setCreditAmount(state, data) {
      state.credit.creditAmount = data;
    },
    selectedCreditTerm(state, data) {
      state.credit.selectedCreditTerm = data;
    },
    setCreditAutoextend(state, data) {
      state.credit.creditAutoextend = data;
    },
    setIsStartNow(state, data) {
      state.credit.isStartNow = data;
    },
    setIsStart(state, data) {
      state.credit.isStart = data;
    },
    setIsStop(state, value) {
      state.credit.isStop = value
    },
    /**
     * Reset credit for edit, change isEdit = true
     * **/
    setCreditEdit(state) {
      state.credit.isEdit = true;
    },

    /**
     * Reset Credit
     * **/
    resetCredit(state) {
      state.credit.isEdit = false
      state.credit.isStart = true
    },
    
    resetCreditData(state) {
      state.credit.isEdit = false
      state.credit.isStart = true

      state.credit = JSON.parse(JSON.stringify(InitialState.credit));
    },

    /**
     * Set balance and credit
     * **/
    setBalanceAndCredit(state, data) {
      state.balanceCreditData = data
      
      if (data.balance) {
        state.mainBalance = data.balance.toFixed(2)
      }
      if (data.comment) {
        state.balanceManage.comment = data.comment
      }
      if (data.credit) {
        data.credit.startNow ? state.credit.isStartNow = data.credit.startNow : ''
        data.credit.creditAmount ? state.credit.creditAmount = data.credit.creditAmount : ''
        data.credit.creditStartDate ? state.credit.date = data.credit.creditStartDate : ''
        data.credit.creditComment ? state.credit.comment = data.credit.creditComment : ''
        data.credit.creditTerm ? state.credit.selectedCreditTerm = data.credit.creditTerm : ''
        data.credit.days ? state.credit.radioValue = "Day" : state.credit.radioValue = "Month"   
        data.credit.creditAutoextend ? state.credit.creditAutoextend = data.credit.creditAutoextend : ''
      }
    },

    /**
     * set balance and credit loadings
     */
    setBalanceIsLoading(state, value) {
      state.balanceManage.isLoading = value
    },
    setCreditIsLoading(state, value) {
      state.credit.isLoading = value
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  },
  actions: {
    addBalance({commit, state, dispatch}, data) {
      return RequestService.create(`balances`, { ...data } )
          .then( async (r) => {
            await dispatch('clientsModule/getClientList', {}, { root: true })
            commit('saveBalance')
            state.balanceManage.isEdit = false
            return Promise.resolve(r.data)
          })
          .catch(e => console.log('error ', e))
    },
    // Add credit
    addCredit({commit, dispatch}, data) {
      return RequestService.create(`credits`, { ...data } )
          .then( async (r) => {
            await dispatch('clientsModule/getClientList', {}, { root: true })
            commit('resetCredit')
            commit('setIsStop', false)
          })
          .catch(e => console.log('error ', e))
    },
    // get balance / credit
    getBalanceCredit({ rootState, commit }) {
      return RequestService.read(`clients/balanceCredit/edit/${rootState.clientsModule.clientId}`)
          .then(r => {
            commit('setBalanceAndCredit', r.data)

            if (r.data.balance !== 0 && !r.data.credit) {
              commit('saveBalance'); // show balance list
            }
  
            if (!r.data.balance && r.data.credit) {
              commit('resetBalanceManage'); // show balance form
              commit('setIsStart', true); // show credit list
            }
  
            if (r.data.balance !== 0 && r.data.credit) {
              commit('saveBalance'); // show balance list
              commit('setIsStart', true); // show credit list
            }
  
            if (!r.data.balance && !r.data.credit) {
              commit('resetBalanceManage'); // show balance form
              commit('setIsStart', false); // show credit form
            }

            return Promise.resolve(r.data);
          })
          .catch(e => console.log('error ', e))
          .finally(() => {
            commit('setBalanceIsLoading', false)
            commit('setCreditIsLoading', false)
          })
    },
    stopCredit({commit, dispatch}, {clientId}) {
      return RequestService.update(`credits/stop/edit/${clientId}`)
          .then(async (r) => {
            commit('resetCreditData')            
            await dispatch('clientsModule/getClientList', {}, { root: true })
            return Promise.resolve(true)
          })
          .catch(e => console.log('error ', e))
    }
  }
}