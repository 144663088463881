import RequestService from "@/services/request.service";
import FlussonicsModel from "../../models/flussonics/FlussonicsModel";
const FileDownload = require("js-file-download");

const InitialState = {
    dataTable: [],
    // filter data
    filter: {
        limit: 20,
        sortBy: "_id:desc",
        page: 1,
    },
    filterData: null,
    filterModel: new FlussonicsModel(),
    //  for table pagination
    dataCount: 0,
    currentPage: 0,
    totalPages: 0,
    // for  update  table  columns
    defaultSettings: [],
    updateColumnsFromBack: "flussonicsModule/updateFlussonicsSettings",
    updateColumnSettingsCommit: "flussonicsModule/setDefaultSettings",
    isDefault: false,

    showWarningModal: false,

    flussonicsResult: false,
    isTableLoading: false,
    isOpenManageFlussonicModal: false,
    formData: {
        name: "",
        description: "",
    },
    isAddBtnLoading: false,
    flussonicId: null,

    modalTabs: [
        { name: "General", title: "general" },
        { name: "posts", title: "posts" },
        { name: "DVR/VOD", title: "dvrVod" },
        { name: "Custom Configs", title: "paymentMethod" }        
    ],

    activeTab: 1,
    enableList: [
        {
            name: "Select All",
            value: 1
        },
        {
            name: "Enable",
            value: 2
        },
        {
            name: "Disable",
            value: 3
        },
    ],
    collectStatList: [
        {
            name: "Select All",
            value: 1
        },
        {
            name: "Enable",
            value: 2
        },
        {
            name: "Disable",
            value: 3
        },
    ],
    remoteUpdateList: [
        {
            name: "Select All",
            value: 1
        },
        {
            name: "Enable",
            value: 2
        },
        {
            name: "Disable",
            value: 3
        },
    ],
    getChannelsMediaList: [
        {
            name: "Select All",
            value: 1
        },
        {
            name: "Enable",
            value: 2
        },
        {
            name: "Disable",
            value: 3
        },
    ],
    splitSourcesList: [
        {
            name: "Select All",
            value: 1
        },
        {
            name: "Enable",
            value: 2
        },
        {
            name: "Disable",
            value: 3
        },
    ],
    isEdgeList: [
        {
            name: "Select All",
            value: 1
        },
        {
            name: "Enable",
            value: 2
        },
        {
            name: "Disable",
            value: 3
        },
    ],
    hostOnlineStatusList: [
        {
            name: "Select All",
            value: 1
        },
        {
            name: "Online",
            value: 2
        },
        {
            name: "Offline",
            value: 3
        },
    ],
    flussonicOnlineStatusList: [
        {
            name: "Select All",
            value: 1
        },
        {
            name: "Online",
            value: 2
        },
        {
            name: "Offline",
            value: 3
        },
    ],
};

export const flussonicsModule = {
    namespaced: true,
    state: InitialState,
    mutations: {
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key];
            }
        },

        /***
         * for all second level object keys changing
         *  setDataSecondLevels({ 
         *    stateKey1: { changingKey: 'newVal' },
         *    stateKey2: { changingKey2: 'newVal' },  
         *  })
         **/
        setDataSecondLevels(state, data) {
            for (let firstLevel in data) {
                for (let secondLevel in data[firstLevel]) {
                    if (state[firstLevel][secondLevel] !== undefined) {
                        state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
                    }
                }
            }
        },
        resetFilterModel(state) {
            state.filter = {}
            state.filter.page = 1
            state.filter.limit = 10
            state.filter.sortBy = "_id:desc"
            state.filterData = {}
        },

        setFlussonicsData(state, data) {
            state.dataTable = data.results;
            state.currentPage = data.page;
            state.totalPages = data.totalPages;
            state.dataCount = data.totalResults;
        },
        setFilterPage(state, page) {
            state.filter.page = page;
            state.currentPage = page;
        },
        filterFlussonics(state) {
            const filterObject = {}

            if (state.filterModel.search) {
                filterObject.search = state.filterModel.search
            }
            if (state.filterModel.enabled !== null) {
                filterObject.enabled = state.filterModel.enabled
            }
            if (state.filterModel.collectStat !== null) {
                filterObject.collectStat = state.filterModel.collectStat
            }
            if (state.filterModel.remoteUpdate !== null) {
                filterObject.remoteUpdate = state.filterModel.remoteUpdate
            }
            if (state.filterModel.channelsMediainfo !== null) {
                filterObject.channelsMediainfo = state.filterModel.channelsMediainfo
            }
            if (state.filterModel.splitSources !== null) {
                filterObject.splitSources = state.filterModel.splitSources
            }
            if (state.filterModel.isEdge !== null) {
                filterObject.isEdge = state.filterModel.isEdge
            }
            if (state.filterModel.hostOnlineStatus !== null) {
                filterObject.hostOnlineStatus = state.filterModel.hostOnlineStatus
            }
            if (state.filterModel.flussonicOnlineStatus !== null) {
                filterObject.flussonicOnlineStatus = state.filterModel.flussonicOnlineStatus
            }
            if (state.filter?.sortBy) {
                filterObject.sortBy = state.filter.sortBy
            }

            state.filterData = filterObject
        },

        sortColumn(state, sort = null) {
            if (sort?.sortValue) {
                state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
            } else {
                if (!state.filterData.sortBy) {
                    state.filterData.sortBy = "_id:desc";
                }
            }
        },

        setDefaultSettings(state, data) {
            if (data.defaultSettings) {
                state.defaultSettings = data.defaultSettings;
                state.isDefault = data.isDefault
            }
        },
    },
    actions: {
        updateFlussonicsSettings({ state, commit }, data) {
            return RequestService.update(
                `users/settings`, {
                flussonicSettings: {
                    settingsExtended: data,
                    isDefault: state.isDefault,
                    limit: state.filter.limit
                }
            }
            ).then((r) => {
                return Promise.resolve(r.data)
            })
                .catch((e) => console.log(e))
        },

        getFlussonicsList({ commit, state, rootState }, filter = null) {
            let options = {
                page: state.currentPage ? state.currentPage : 1,
            }

            if (rootState.general.isSelectedAll) {
                options.all = true
            }
        
            if (filter) {
                options.limit = state.filter.limit
                Object.assign(options, filter)
            } else {
                Object.assign(options, state.filter)
            }

            let query = Object.keys(options)
                .map((key) => key + "=" + options[key])
                .join("&");

            const readOptions = {};
            const isExcelRequest = filter && filter.excel;
            if (isExcelRequest) {
                readOptions.responseType = "blob";
            } else {
                commit("setData", { isTableLoading: true });
            }

            return RequestService.read(`flussonics?${query}`, readOptions)
                .then((response) => {
                    if (!isExcelRequest) {
                        if (response.data.results && response.data.results.length) {
                            commit("setData", (state.flussonicsResult = true));
                        } else {
                            commit("setData", (state.flussonicsResult = false));
                        }
                        commit("setEquipmentsTypesData", response.data);
                        commit("setData", { isTableLoading: false });
                    } else {
                        FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
                    }
                })
                .catch((e) => console.log("error ", e))
                .finally(() => {
                    commit("setData", { isTableLoading: false })
                });
        },

        getFlussonicData({ commit }, id) {
            return RequestService.read(`flussonics/edit/${id}`)
                .then((response) => {
                    commit("setEquipmentTypeData", response.data);
                    return Promise.resolve(response.data);
                })
                .catch((e) => console.log(e));
        },

        createFlussonic({ commit, state, dispatch }) {
            let body = {
                name: [{ lang: "en", name: state.formData.name }],
                description: state.formData.description,
            };
            return RequestService.create(`flussonics`, body)
                .then(async (response) => {
                    await dispatch("getFlussonicsList");
                    return Promise.resolve(response.data);
                })
                .catch((e) => console.log("error", e))
                .finally(() => {
                    commit("setData", {
                        isAddBtnLoading: false,
                        isOpenManageFlussonicModal: false,
                    });
                    // commit("resetFormData");
                });
        },

        updateEquipmentType({ commit, state, dispatch }, id) {
            let body = {
                name: [{ lang: "en", name: state.formData.name }],
                description: state.formData.description,
            };
            return RequestService.update(`flussonics/edit/${id}`, body)
                .then(async (response) => {
                    await dispatch("getFlussonicsList");
                    return Promise.resolve(response.data);
                })
                .catch((e) => console.log("error", e))
                .finally(() => {
                    commit("setData", {
                        isAddBtnLoading: false,
                        isOpenManageFlussonicModal: false,
                    });
                    // commit("resetFormData");
                });
        },

        FlussonicDelete({ commit, dispatch }, body) {
            return RequestService.update(`flussonics/delete`, body)
                .then(async (r) => {
                    await dispatch("getFlussonicsList");
                    return Promise.resolve(r.data);
                })
                .catch((e) => console.log(e));
        },

    }
}

