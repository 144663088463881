import RequestService from "@/services/request.service";

const InitialState = {
    categoriesList: []
}

export const help = {
    namespaced: true,
    state: InitialState,
    mutations: {
        /**
        * for all first level keys changing  
        * setData({ key: 'value' })
        */
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key];
            }
        },
    },
    actions: {
        addCategory({ state }, data) {
            return RequestService.create('helps', data)
                .then(response => Promise.resolve(response.data))
                .catch(e => console.log(e))
        },

        deleteCategory({ state }, category) {
            return RequestService.delete(`helps/edit/${category}`)
                .catch(e => console.log(e))
        },

        editCategory({ state }, data) {
            let category = data?.category ? data?.category : null;
            delete data.category

            return RequestService.update(`helps/edit/${category}`, {...data})
                .then(response => Promise.resolve(response.data))
                .catch(e => console.log(e))
        },

        getCategories({ commit, rootState }, filter = null) {
            let options = {
                sortBy: "createdAt:asc",
                limit: 1000
            }

            if (rootState?.auth?.user?.rolesInfo?.cashier ) {
                options.type = 'cashier'
            }

            let query = Object.keys(options).map(
                key => key + '=' + options[key]
              ).join('&');

        
            return RequestService.read("helps?"+query)
                .then(response => {
                    commit('setData', { categoriesList: response.data.results })
                    return Promise.resolve(response.data)
                })
        }
    }
}