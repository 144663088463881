<template>
  <div class="active-filter-section">
    <h6 class="secondary--text">{{ heading }}</h6>
    <div class="active-filter-body">
      <template v-for="(item, key) of selectedList">
        <selected-list-box
          v-if="item.ifCondition"
          @resetData="$emit('resetData', item.resetArgument)"
          :box-text="item.boxText"
          :key="key"
        />
      </template>
    </div>
  </div>
</template>

<script>
import SelectedListBox from "@/components/filterComponents/SelectedListBox";

export default {
  name: "SelectedFilters",
  components: { SelectedListBox },
  props: {
    heading: {
      type: String,
      default: () => "Active Filters",
    },
    selectedList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>