
export function FormatClientAddress (input) {
    let address = '';
    if (input?.address) {
        address = address + input.address
    }
    if (input?.suite) {
        address = address + ' ' + input.suite
    }
    if (input?.city) {
        address = address + '<br/ >' + input.city
    }
    if (input?.province) {
        address = address + ' ' + input.province
    }
    if (input?.zip) {
        address = address + ' ' + input.zip
    }
    if (input?.country && input?.country !== 'US') {
        address = address + ' ' + input.country
    }
    return address;
}

export function FormatProviderAddress(input) {
    let address = '';
    if (input?.address) {
        address = address + input.address
    }
    if (input?.unit) {
        address = address + ' ' + input.unit
    }
    if (input?.city) {
        address = address + '<br />' + input.city
    }
    if (input?.state) {
        address = address + ' ' + input.state
    }
    if (input?.zip) {
        address = address + ' ' + input.zip
    }
    if (input?.country && input?.country !== 'US') {
        address = address + ' ' + input.country
    }
    return address;
}