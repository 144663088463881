export default class PricesFilterModel {
    constructor() {
        this.reset();
    }

    reset() {
        this.clientType = true
        this.priceGroup = ''
        this.discount = ''
        this.groupingMode = ''
        this.period = {
            name: 'All',
            value: 'all'
        }
    }
}