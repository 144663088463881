import RequestService from "@/services/request.service"

const InitialState = {
    providerDragComponents: [],
    clientDragComponents: [
        {
            name: "ClientContact",
            ref: "clientContact",
        },
        {
            name: "ClientAddress",
            ref: "clientAddress",
        },
        {
            name: "ClientPaymentMethod",
            ref: "clientsPaymentMethods",
        },
        {
            name: "ClientBalanceCredit",
            ref: "clientBalanceCredit",
        },
        {
            name: "ClientLocations",
            ref: "clientLocation",
        },
        // {
        //     name: 'ProfileClient',
        //     ref: ''
        // },
        {
            name: "ClientUsedDevices",
            ref: "",
        },
        {
            name: "ClientPackages",
            ref: "packagesClients",
        },
        {
            name: "ClientShippings",
            ref: "clientShippings",
        },
        {
            name: "ClientActivity",
            ref: "clientActivity",
        },
        {
            name: "ClientBillsList",
            ref: "",
        },
        {
            name: "ClientTransactionsList",
            ref: ""
        },
        {
            name: "ClientCommentsList",
            ref: ""
        }
    ],
    userDragComponents: [],
    generalSearchResult: [],
    searchedClientId: null,
    isGeneralSearch: false,
    showSearchComponents: false,
    lockDrags: false,
    tabsWidth: 48.4,
    hasValidTwilio: false
};
export const generalSearch = {
    namespaced: true,
    state: JSON.parse(JSON.stringify(InitialState)),
    actions: {
        search({ commit, rootState }, searchVal) {
            let generalSearchResult = []

            if (searchVal) {
                return RequestService.read(`clients/globalSearch?search=${searchVal}`)
                    .then(response => {
                        if (response.data.length) {
                            // client address showing logic
                            const createAddress = (address) => {
								let addressText = '';
								if (address) {
									if (address.address) {
										addressText = addressText + address.address
									}
									if (address.suite) {
										addressText = addressText + ' ' + address.suite
									}
									if (address.city) {
										addressText = addressText + ' ' + address.city
									}
									if (address.province) {
										addressText = addressText + ' ' + address.province
									}
									if (address.zip) {
										addressText = addressText + ' ' + address.zip
									}
									if (address.country && address.country !== 'US') {
										addressText = addressText + ' ' + address.country
									}
								}

								return addressText
							}

							const getClientStatus = (data) => {
								/*
									client status by subscriptionState value
									0 - Inactive
									1 - Pending
									2 - Canceled
									3 - Active
								*/
								
								if (data.subscriptionState === 0) {
									return `<span class="G-badge G-advanced-badge">Inactive</span>`
								}
							
								if (data.subscriptionState === 1) {
									return `<span class="G-badge G-pending-badge">Pending</span>`
								}
							
								if (data.subscriptionState === 2) {
									return `<span class="G-badge G-disabled-badge">Canceled</span>`
								}
							
								if (data.subscriptionState === 3) {
									if (data.inBlock === 0 && data.inPause === 0) {
										return `<span class="G-badge G-approve-badge">Active</span>`
									}
						
									if (data.inBlock === 0 && data.inPause === 1) {
										return `<span class="G-badge G-paused-badge">Paused</span>`
									}
						
									if (data.inBlock === 0 && data.inPause === 2) {
										return `<span class="G-badge G-paused-badge">Semipaused</span>`
									}
						
									if (data.inBlock === 1 && data.inPause === 0) {
										return `<span class="G-badge G-reject-badge">Blocked</span>`
									}
						
									if (data.inBlock === 2 && data.inPause === 0) {
										return `<span class="G-badge G-reject-badge">Semiblocked</span>`
									}
						
									if (data.inBlock === 1 && data.inPause === 1) {
										return `<span class="G-badge G-reject-badge">Blocked</span> <br>
											<span class="G-badge G-paused-badge">Paused</span>`
									}
						
									if (data.inBlock === 1 && data.inPause === 2) {
										return `<span class="G-badge G-reject-badge">Blocked</span> <br>
												<span class="G-badge G-paused-badge">Semipaused</span>`
									}
						
									if (data.inBlock === 2 && data.inPause === 1) {
										return `<span class="G-badge G-reject-badge">Semiblocked</span> <br>
											<span class="G-badge G-paused-badge">Paused</span>`
									}
						
									if (data.inBlock === 2 && data.inPause === 2) {
										return `<span class="G-badge G-reject-badge">Semiblocked</span> <br>
											<span class="G-badge G-paused-badge">Semipaused</span>`
									}
								}
						
								if (data.inBlock === undefined || data.inPause === undefined || (data.inBlock === 0 && data.inPause === 0)) {
									return ''
								}
							}

                            response.data.map(item => { 
                                generalSearchResult.push({
                                    id: item.id,
                                    name: `<b>${item.personalInfo.firstname} ${item.personalInfo.lastname} (${item.provider ? item.provider.name[0].name : 'No Provider Name'})</b><i>${ createAddress(item.addresses[0]) ? ', ' + createAddress(item.addresses[0]) : ' ' }${ item.phones.length ? ', ' + item.phones[0].phone + ', ' : ', ' }</i>
                                    &nbsp; ${ getClientStatus(item) }`
                                })
                            })
                            
                            commit('setData', { generalSearchResult })
                        } else {
                            commit('setData', { generalSearchResult: [] })
                        }
                    })
                    .catch(e => console.log(e))
            }

            if (!searchVal) {
                commit('setData', { generalSearchResult: [] })
            }
        },

        getDragStatuses({ rootState, commit }) {
            return RequestService.read('users/settings')
                .then(r => {
                    if (!!r.data.providerDragComponents) {
                        commit('setData', { providerDragComponents: r.data.providerDragComponents })
                    }

                    if (!!r.data.clientDragComponents) {
                        commit('setData', { clientDragComponents: r.data.clientDragComponents })
                    }

                    if (!!r.data.userDragComponents) {
                        commit('setData', { userDragComponents: r.data.userDragComponents })
                    }

                    if (!!r.data.generalSearch) {
                        if (r.data.generalSearch?.lockDrags) {
                            commit('setData', { lockDrags: r.data.generalSearch.lockDrags })
                        }
                        if (r.data.generalSearch?.tabsWidth) {
                            commit('setData', { tabsWidth: r.data.generalSearch.tabsWidth })
                        }
                    }
                    
                    // Client Activity settings
                    const activityLimit = r?.data?.clientActivitySettings?.limit ? r.data.clientActivitySettings.limit : rootState.clientActivityModule.filter.limit
                    const activitySearchLimit = r?.data?.clientActivitySettings?.searchLimit ? r.data.clientActivitySettings.searchLimit : rootState.clientActivityModule.filter.searchLimit

                    commit("clientActivityModule/setDefaultSettingsForBack", r.data, { root: true });
                    commit("clientActivityModule/setLimit", activityLimit, { root: true });
                    commit("clientActivityModule/setSearchLimit", activitySearchLimit, { root: true });
                

                    // Client Bills settings
                    const billsLimit = r?.data?.clientBillsSettings?.limit
                        ? r.data.clientBillsSettings.limit
                        : rootState.clientBillsModule.filter.limit;
            
                    const billsSearchLimit = r?.data?.clientBillsSettings?.searchLimit
                        ? r.data.clientBillsSettings.searchLimit
                        : rootState.clientBillsModule.filter.searchLimit;

                    commit("clientBillsModule/setDefaultSettingsForBack", r.data, { root: true });
                    commit("clientBillsModule/setLimit", billsLimit, { root: true });    
                    commit("clientBillsModule/setSearchLimit", billsSearchLimit, { root: true });    
                
                    
                    // Shippings settings
                    const shippingsLimit = r?.data?.shippingSettings?.limit 
                        ? r.data.shippingSettings.limit 
                        : rootState.shippingsModule.filter.limit
                        
                    const shippingsSearchLimit = r?.data?.shippingSettings?.searchLimit 
                        ? r.data.shippingSettings.searchLimit 
                        : rootState.shippingsModule.filter.searchLimit
                   
                    commit("shippingsModule/setDefaultSettingsForBack", r.data, { root: true });
                    commit("shippingsModule/setLimit", shippingsLimit, { root: true });
                    commit("shippingsModule/setSearchLimit", shippingsSearchLimit, { root: true });

                    
                    // Sidebar settings
                    if (!!r.data.manuSettings) {
                        commit("sidebar/setData", { 
                          isMinimize: r.data.manuSettings.isMinimize,
                          isFullHide: r.data.manuSettings.isFullHide,
                          themeDark: r.data.manuSettings.themeDark
                        }, { root: true })
                    }

                    // Transactions settings
                    const transactionsLimit = r?.data?.transactionSettings?.limit
                        ? r.data.transactionSettings.limit
                        : rootState.transactionsModule.filter.limit;
                        
                    const transactionsSearchLimit = r?.data?.transactionSettings?.searchLimit
                        ? r.data.transactionSettings.searchLimit
                        : rootState.transactionsModule.filter.searchLimit;

                    commit("transactionsModule/setDefaultSettingsForBack", r.data, { root: true });
                    commit("transactionsModule/setLimit", transactionsLimit, { root: true });
                    commit("transactionsModule/setSearchLimit", transactionsSearchLimit, { root: true });

                })
        },

        updateDragStatus({ commit, state }, { searchType, dragComponents }) {
            let dragKeyName = ''
            switch (searchType) {
                case 'client':
                    dragKeyName = 'clientDragComponents';
                    break;
                case 'provider':
                    dragKeyName = 'providerDragComponents';
                    break;
                case 'user':
                    dragKeyName = 'userDragComponents';
                    break;
            }

            return RequestService.update(`users/settings`, {
                [dragKeyName]: dragComponents,
            }).catch((e) => console.log(e))
        },

        resetDragStatuses({ commit, state }, { searchType }) {
            let dragKeyName = ''
            switch (searchType) {
                case 'client':
                    dragKeyName = 'clientDragComponents';
                    break;
                case 'provider':
                    dragKeyName = 'providerDragComponents';
                    break;
                case 'user':
                    dragKeyName = 'userDragComponents';
                    break;
            }

            return RequestService.update(`users/settings`, {
                [dragKeyName]: InitialState[dragKeyName],
            }).catch((e) => console.log(e))
        },

        updateSettings({ state }) {
            return RequestService.update(`users/settings`, {
                generalSearch: { 
                    lockDrags: state.lockDrags,
                    tabsWidth: state.tabsWidth
                } 
            }).catch((e) => console.log(e))
        }
    },
    mutations: {
        setData(state, data) {
            for (let key in data) {
                state[key] = data[key]
            }
        },
    }
};
