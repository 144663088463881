const flussonics = () => import("@/views/flussonics/Flussonics");

export const flussonicsRout = {
    path: "/flussonics",
    redirect: "/flussonics",
    name: "Flussonics",
    component: flussonics,
    children: [
      {
        path: "",
        name: "",
        component: flussonics,
      }
  
    ]
  };
  