import axios from "axios";
import router from "../router";
import store from "../store";

const BASE_URL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error && error.response && error.response.data && error.response.data.code) { // TODO handle else condition
      const [statusCode, text] = [error.response.data.code, error.response.data.message]

      if (statusCode === 403) {
        router.push("/error/forbidden");
      }

    }
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === 401
    ) {
      console.log(error)
      console.log(router);
      if (
        error.response.config &&
        !error.response.config.url.includes("/auth/login")
      ) {
        await store.dispatch("auth/logout");
        await router.push("/auth/sign-in");
        await location.reload(true);
     }
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use(function (config) {
  if (store.state.auth.tokens && store.state.auth.tokens.access.token) {
    config.headers.Authorization = "Bearer " + store.state.auth.tokens.access.token;
  }
  return config;
});

class RequestService {
  base_url = BASE_URL;
  constructor() {
    this.base_url = BASE_URL;
  }

  create(path, data, token = this.generateToken(), config = {}) {
    if (path !== 'subscriptions/location/') {
      store.commit('appModule/setData', { isLoading: true }) // for show button loader
    }
    return axios.post(this.generatePath(path), data, {
      cancelToken: token.token,
      ...config
    })
    .then((response) => {
        if (path !== 'subscriptions/location/' &&
            path !== 'invoices/sendInvoices' &&
            path !== 'invoices/invoiceFilter' && 
            path !== 'invoices/views' && 
            path !== 'clients/check-email-phone'
          ) {
          store.commit('appModule/setData', { isSaved: true }) // for show message after save
        } 
        return Promise.resolve(response)        
      },
      error => {
        return Promise.reject(error);
      }
    ).finally(() => {
      store.commit('appModule/setData', { isLoading: false }) // for hide button loader

      setTimeout(() => {        
        store.commit('appModule/setData', { isSaved: false })
      }, 1300) // for hide message after save
    }) 
  }

  read(path, options = {}, token = this.generateToken()) {
    return axios.get(this.generatePath(path), { ...options, cancelToken: token.token })
        .then((response) => {
              return Promise.resolve(response)
            },
            error => {
              return Promise.reject(error);
            }
        ).finally(() => {
          // store.commit('appModule/setData', { isLoading: false }) // for hide button loader
          //
          // setTimeout(() => {
          //   store.commit('appModule/setData', { isSaved: false })
          // }, 1300) // for hide message after save
        })

    // request.then(() => {
    //   console.log(`request ended ${token.toString()}`)
    // }).catch(() => {
    //   console.log(`request failed ${token.toString()}`)
    // })
    // return request;
  }

  update(path, data, token = this.generateToken()) {
    store.commit('appModule/setData', { isLoading: true }) // for show button loader

    return axios.patch(this.generatePath(path), data, {
      cancelToken: token.token
    })
    .then((response) => {        
        store.commit('appModule/setData', { isSaved: true }) // for show message after save
        return Promise.resolve(response)        
      },
      error => {
        return Promise.reject(error.response.data);
      }
    ).finally(() => {
      store.commit('appModule/setData', { isLoading: false }) // for hide button loader

      setTimeout(() => {        
        store.commit('appModule/setData', { isSaved: false })
      }, 1300) // for hide message after save
    })
  }

  delete(path, token = this.generateToken()) {
    return axios.delete(this.generatePath(path), {
      cancelToken: token.token
    }).then(() => {
      store.commit('appModule/setData', { isSaved: true }) // for show message after delete
    
    }).finally(() => {
      setTimeout(() => {        
        store.commit('appModule/setData', { isSaved: false })
      }, 1300) // for hide message after save
    });
  }

  generateToken() {
    return axios.CancelToken.source();
  }
  
  generatePath(path) {
    return this.base_url + path;
  }
}

export default new RequestService();
