import RequestService from "@/services/request.service";

const InitialState = {
  creditCard: {
    cardNumber: '',
    cardholderName: '',
    cvc: null,
    isLoading: false,
    expireDate: '',
    year: '',
    date: '',
    dayOrMonth: '',
  },
  paymentData: '',
  isLoading: false,
  isMonth: false,
  monthData: '',
  cardData: '',
  errorMessage: ''
}

export const securePaymentsModule = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    setCardNumber(state, value) {
      state.creditCard.cardNumber = value
    },
    setCardholderName(state, value) {
      state.creditCard.cardholderName = value
    },
    setCVC(state, value) {
      state.creditCard.cvc = value
    },
    setDayOrMonth(state, value) {
      state.creditCard.dayOrMonth = value
    },
    setExpireDate(state, value) {
      state.creditCard.expireDate = value
    },
    setPaymentData(state, data) {
      state.paymentData = data
    },
    setMonth(state, value) {
      state.creditCard.month = value
    },
    setYear(state, value) {
      state.creditCard.year = value
    },
    setMonthData(state, data) {
      state.monthData = data
    },
    setCardData(state, data) {
      state.cardData = data
    }
  },
  actions: {
    getPaymentData({ commit }, id) {
      // commit('setData', { isLoading: true })
      return RequestService.read(`invoices/payClient/${id}`)
        .then((response) => {
          commit('setPaymentData', response.data)
          return Promise.resolve(response.data)
        })
        .catch((e) => console.log(e))
    },
    payClientUpdated({ commit, state }, { id, data }) {
      return RequestService.update(`invoices/payClientUpdated/${id}`, { ...data })
        .then(async response => {
          console.log('response.data ', response.data);
          commit('setMonthData', response.data)
          return Promise.resolve(response.data)
        })
        .catch(e => console.log('error', e))
    },
    payClientInvoice({ commit, state }, { id, data }) {
      return RequestService.update(`invoices/payClientInvoice/${id}`, { ...data })
        .then(async response => {
          commit('setCardData', response.data)
          commit('setData', { isLoading: false })
          return Promise.resolve(response.data)
        })
        .catch(e => {
          console.log('error', e)
          commit('setData', { errorMessage: e.message })
        })
        .finally(() => {
          commit('setData', { isLoading: false })
        })
    },
  }
}