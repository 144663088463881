export const devicesList = [
  {
    locationName: 'location 1',
    login: '00000000',
    id: 'sad65a4das654asd',
    devices: [
      {
        type: 'Samsung',
        model: 'H907',
        lastActivityTime: '01/01/2021',
        modelCode: '123456',
        ipAddress: '123456789',
        macAddress: '123654557',
        serialNumber: '1234587',
        manufacturer: 'Samsung',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      },
      {
        type: 'Apple',
        model: 'H910',
        lastActivityTime: '01/00/2021',
        modelCode: '0000000',
        ipAddress: '11111111',
        macAddress: '2222222',
        serialNumber: '3333333',
        manufacturer: 'Apple',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      },
    ],
    profiles: [
      {
        avatar: '',
        nickname: "test",
        protectCode: "123456",
        ageGroup: '0-12',
        isAdmin: true
      }
    ]
  },
  {
    locationName: 'location 2',
    login: '11111111',
    id: 'sad65a4adasddas654asd',
    devices: [
      {
        type: 'Samsung',
        model: 'H907',
        lastActivityTime: '01/01/2021',
        modelCode: '123456',
        ipAddress: '123456789',
        macAddress: '123654557',
        serialNumber: '1234587',
        manufacturer: 'Samsung',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      }
    ]
  },
  {
    locationName: 'location 3',
    login: '22222222',
    id: 'sad65a4dasdfasfasfas654asd',
    devices: [
      {
        type: 'Apple',
        model: 'H910',
        lastActivityTime: '01/00/2021',
        modelCode: '0000000',
        ipAddress: '11111111',
        macAddress: '2222222',
        serialNumber: '3333333',
        manufacturer: 'Apple',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      },
    ]
  },
  {
    locationName: 'location 4',
    login: '33333333',
    id: 'sad65aasfasfas654asd',
    devices: [
      {
        type: 'Apple',
        model: 'H910',
        lastActivityTime: '01/00/2021',
        modelCode: '0000000',
        ipAddress: '11111111',
        macAddress: '2222222',
        serialNumber: '3333333',
        manufacturer: 'Apple',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      },
    ]
  },
  {
    locationName: 'location 5',
    login: '44444444',
    id: 'sad65a4daasfas654asd',
    devices: [
      {
        type: 'Samsung',
        model: 'H907',
        lastActivityTime: '01/01/2021',
        modelCode: '123456',
        ipAddress: '123456789',
        macAddress: '123654557',
        serialNumber: '1234587',
        manufacturer: 'Samsung',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      },
      {
        type: 'Apple',
        model: 'H910',
        lastActivityTime: '01/00/2021',
        modelCode: '0000000',
        ipAddress: '11111111',
        macAddress: '2222222',
        serialNumber: '3333333',
        manufacturer: 'Apple',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      },
    ]
  },
  {
    locationName: 'location 6',
    login: '55555555',
    id: 'sadfasfasfas654asd',
    devices: [
      {
        type: 'Samsung',
        model: 'H907',
        lastActivityTime: '01/01/2021',
        modelCode: '123456',
        ipAddress: '123456789',
        macAddress: '123654557',
        serialNumber: '1234587',
        manufacturer: 'Samsung',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      }
    ]
  },
  {
    locationName: 'location 7',
    login: '66666666',
    id: 'sad65a4dasdfasfasfasd',
    devices: [
      {
        type: 'Apple',
        model: 'H910',
        lastActivityTime: '01/00/2021',
        modelCode: '0000000',
        ipAddress: '11111111',
        macAddress: '2222222',
        serialNumber: '3333333',
        manufacturer: 'Apple',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      },
    ]
  },
  {
    locationName: 'location 8',
    login: '77777777',
    id: 'sad65a4da145fas654asd',
    devices: [
      {
        type: 'Samsung',
        model: 'H907',
        lastActivityTime: '01/01/2021',
        modelCode: '123456',
        ipAddress: '123456789',
        macAddress: '123654557',
        serialNumber: '1234587',
        manufacturer: 'Samsung',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      }
    ]
  },
  {
    locationName: 'location 9',
    login: '88888888',
    id: 'sa7u6uhfsfasfas654asd',
    devices: [
      {
        type: 'Samsung',
        model: 'H907',
        lastActivityTime: '01/01/2021',
        modelCode: '123456',
        ipAddress: '123456789',
        macAddress: '123654557',
        serialNumber: '1234587',
        manufacturer: 'Samsung',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      },
      {
        type: 'Apple',
        model: 'H910',
        lastActivityTime: '01/00/2021',
        modelCode: '0000000',
        ipAddress: '11111111',
        macAddress: '2222222',
        serialNumber: '3333333',
        manufacturer: 'Apple',
        userAgent: 'Test Testyan',
        timeShift: null,
        language: null,
        remoteControl: null,
        audioTrackDefault: null,
        httpCaching: null,
        streamQuality: null,
        isSD: false,
        isHD: false,
        isFHD: false,
        isUHD: false,
      },
    ]
  },

]