<template>
  <div class="ott-input-cont P-password" :class="customInputClass">
    <v-text-field
      :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
      :type="show ? 'text' : 'password'"
      :label="label"
      :height="height"
      :error="error"
      :value="inputValue"
      :dense="true"
      :readonly="readOnly"
      outlined
      @input="inputEmit"
      @click:append="showPassword"
    />
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  props: {
    value: {
      type: String
    },
    customInputClass: {
      type: String
    },
    error: {
      type: Boolean
    },
    label: {
      type: String,
      default: "Password"
    },
    height: {
      type: Number,
      default: 38
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      showCss: false,
      inputValue: ""
    };
  },
  watch: {
    value(data) {
      this.inputValue = data;
    }
  },
  mounted() {
    this.inputValue = this.value;
  },
  methods: {
    inputEmit(val) {
      this.$emit("emitFunc", val);
      this.$emit("input", val);
    },
    showPassword() {
      this.show = !this.show;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.ott-input-cont::v-deep {
  width: 100%;

  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    min-height: 30px !important;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details,
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding: 0 18px !important;
  }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
  }

  .v-text-field--outlined .v-input__slot fieldset {
    padding-left: 14px !important;
  }

  .v-input .v-label {
    font-size: $txt14 !important;
    font-weight: $regular !important;
    //color: #aeb6bd;
    color: $neutral-color;
  }

  .v-input--has-state.error--text .v-label {
    -webkit-animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  }

  .v-input--dense .v-label {
    top: 8px !important;
  }

  .mdi-eye-outline,
  .mdi-eye-off-outline {
    font-size: 20px;
    color: $neutral-color45;
  }
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
> .v-input__control
> .v-input__slot:hover
fieldset {
  color: $neutral-color;
}
.theme--light::v-deep {
  &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color: $neutral-color;
  }

  .v-label {
    color: $neutral-color;
    text-transform: capitalize !important;
  }

  // .v-text-field__slot input {
  //   color: $secondary-color !important;
  // }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color: $neutral-color;
  }
}

.theme--dark::v-deep {
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color: $primary-colorDM !important;
  }

  .v-label {
    color:$dark-gray !important;
    text-transform: capitalize !important;
  }

  // .v-text-field__slot input {
  //   color: $dark-gray !important;
  // }

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    color:$dark-gray !important;
  }
}
</style>
