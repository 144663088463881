import RequestService from "@/services/request.service";
import RegistrationFormModel from "../../models/auth/registrationFormModel";
const InitialState = {
  activeTab: 1,
  form: new RegistrationFormModel(),
  isLoading: false,
  countryCode:null,
  toSaveData:{}
};

export const registration = {
  namespaced: true,
  actions: {
    validateEmailUser({ commit }, queryOptions) {
      let query = Object.keys(queryOptions)
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      return RequestService.read(`users/email/check-mail?${query}`).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    validateEmailCompany({ commit }, queryOptions) {
      let query = Object.keys(queryOptions)
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      return RequestService.read(`ottProviders/emails/check-mail?${query}`).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    validatePhoneCompany({ commit }, queryOptions) {
      let query = Object.keys(queryOptions)
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      return RequestService.read(`ottProviders/phones/check-phone?${query}`).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    registration({ state,commit }) {
      return RequestService.create("auth/register-user-provider", state.toSaveData).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  state: InitialState,
  mutations: {
    changeTabRegistration(state, index) {
      state.activeTab = index;
    },
    fetchPersonalInfo(state, data) {
      state.form.fetch(data);
      state.form.__ob__.dep.notify();
    },

    fetchCompanyInfo(state, form) {
      state.toSaveData.firstname = state.form.firstname
      state.toSaveData.lastname = state.form.lastname
      state.toSaveData.password = state.form.password
      state.toSaveData.email = state.form.email
      state.toSaveData.companyName = [
        {
          lang: "us",
          name: form.companyName
        }
      ];
      state.toSaveData.companyEmail = form.companyEmail;
      state.toSaveData.website = form.website;
      state.toSaveData.phone =  { phoneNumber: form.phone, countryCode: state.countryCode };
      state.toSaveData.clientAmount = form.clientAmount;
      state.toSaveData.channelAmount = form.channelAmount;
      state.toSaveData.description = form.description;
    },
    setLoad(state, isLoad) {
      state.isLoading = isLoad;
    },
    setCountryCode(state, countryCode) {
      state.countryCode = countryCode;
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
