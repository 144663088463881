import { eventBus } from "@/main";
export default {
    data() {
        return {
            selectedAllVisibles: false,
            showShadowOnScroll: false,
            draggingParent: null,
            draggedOverParent: null,
            draggingChild: null,
            draggedOverChild: null,
            isDragParent: false,
            isDragChild: false,
            dragChildInfo: null,
            columnConfigData: []
        }
    },

    props: {
        columnConfig: {
            type: Array,
            required: true,
            default: []
        },
        data: {
            type: Array,
            required: true,
            default: []
        },
        fixedFirstAndLastColumn: {
            type: Boolean,
            default: false
        },
        isActions: {
            type: Boolean,
            default: true
        },
        isMultiSelect: {
            type: Boolean,
            default: false
        },
        hideLeftAction: {
            type: Boolean,
            default: false
        },
        recursiveTreeIndex: {
            type: Number,
            default: 0
        },
        selectedArrowList: {
            type: Array,
            default: []
        },
    },

    methods: {
        scrollY(event) {
            if (this.fixedFirstAndLastColumn) {
                this.showShadowOnScroll = event.target.scrollLeft > 0;
            }
        },

        /**
         * update  column  before change  columns
         * **/
        updateColumns() {
            this.$emit("updateColumns", this.columnConfigData);
        },

        /**
         * Drag and Drop  functions  columns  table  main  titles
         * **/
        handleDragStart() {
            const [event, index] = arguments;
            this.isDragParent = true
            this.draggingParent = index;
            event.dataTransfer.effectAllowed = "move";
        },

        handleDragOver() {
            if (!this.isDragChild) {
                const [event, index] = arguments;
                this.draggedOverParent = index;
                !this.isDragChild && event.currentTarget.classList.add("P-show-dragged-item");
                event.dataTransfer.dropEffect = "move";
                event.preventDefault();
            }
        },

        handleDragLeave() {
            const [event] = arguments;
            event.currentTarget.classList.remove("P-show-dragged-item");
        },
        handleDragDrop() {
            const [event] = arguments;
            let arrayOfIndex = this.columnConfigData.map((x, index) => index);
            let index1 = arrayOfIndex.indexOf(this.draggingParent);
            let index2 = arrayOfIndex.indexOf(this.draggedOverParent);
            arrayOfIndex.splice(index1, 1);
            arrayOfIndex.splice(index2, 0, this.draggingParent);
            let newColumnConfig = [];
            arrayOfIndex.forEach(item => {
                newColumnConfig.push(this.columnConfigData[item]);
            });
            this.columnConfigData = newColumnConfig;
            this.updateColumns();
            this.$emit("saveColumnChangesFromDrag", {arrayOfIndex});
            this.isDragParent = false
        },

        handleDragStartColumn() {
            const [event, index, parentIndex] = arguments;
            this.draggingChild = index;
            this.dragChildInfo = parentIndex;
            event.dataTransfer.effectAllowed = "move";
            this.isDragChild = true
        },

        handleDragOverColumn() {
            const [event, index, parentIndex] = arguments;
            if (parentIndex === this.dragChildInfo) {
                this.draggingParent = parentIndex
                this.draggedOverChild = index;
                !this.isDragParent && event.currentTarget.classList.add("P-show-dragged-item");
                event.preventDefault();
            }
        },

        handleDragLeaveColumn() {
            const [event] = arguments;
            event.currentTarget.classList.remove("P-show-dragged-item");
        },
        handleDragDropColumn() {
            const [event, index] = arguments;
            if (index === this.draggingParent) {
                let arrayOfIndex = this.columnConfigData.filter((y, i) => index === i)[0].subList.map((x, index) => index);
                let index1 = arrayOfIndex.indexOf(this.draggingChild);
                let index2 = arrayOfIndex.indexOf(this.draggedOverChild);
                arrayOfIndex.splice(index1, 1);
                arrayOfIndex.splice(index2, 0, this.draggingChild);
                let newColumnConfig = [];
                this.columnConfigData.forEach((item, index) => {
                    let newItem = {...item}
                    if (index === this.draggingParent) {
                        newItem.subList = []
                        arrayOfIndex.forEach(itemIndex => {
                            newItem.subList.push(item.subList[itemIndex])
                        })
                    }
                    newColumnConfig.push(newItem)
                })
                this.columnConfigData = newColumnConfig;
                this.updateColumns();
                this.$emit("saveColumnChangesFromDrag", {arrayOfIndex, parentIndex: index});
            }
            this.isDragChild = false

        },
        checkRow(row, index) {
            const body = {
                data: row,
                treeIndex: index
            };
            this.$emit("selectRow", body);
        },
        getSelectedRow(row) {
            return this.selectedArrowList.some(x => x.id === row.id);
        },


        /**
         * Sort  column table
         * **/
        sortColumns(column) {
            if (column.sortData && column.sortData.isSort) {
                this.columnConfigData.map(item => {
                    item.subList.map(subs => {
                        if (subs.sortData && subs.sortData.isSort) {
                            if (subs.sortData.sortKey !== column.sortData.sortKey) {
                                subs.sortData.sortValue = ''
                            }
                        }
                    })
                    return item
                })
                switch (column.sortData.sortValue) {
                    case 'asc': {
                        column.sortData.sortValue = 'desc'
                        break
                    }
                    case 'desc': {
                        column.sortData.sortValue = 'asc'
                        break
                    }
                    default: {
                        column.sortData.sortValue = 'desc'
                    }
                }
                this.$emit("sortData", column.sortData);
            }
        },
    },

    created() {
        
    },

    mounted() {
        let resetTableData = false;
        this.columnConfigData = this.columnConfig;

        /*
         Checking if there are changing columns, 
         Removing columns or any thing changing in columnConfigData doing Reset Column Configs
        */
        this.columnConfigData.map(item => {
            if (item?.subList.length) {
                item.subList.map(column => {
                    if (typeof column?.cellView !== 'function') {
                        resetTableData = true
                    }
                })  
            }
        })

        if (resetTableData) {
            eventBus.$emit('resetDataTableSettings')
        }
    },

    watch: {
        columnConfig(data) {
            this.columnConfigData = data;
        },
        data() {
            this.activeIndex = null
        },
        'selectedArrowList.length'(selectedCount) {
            if (!selectedCount) {
                this.selectedAllVisibles = false
            } else {
                this.selectedAllVisibles = true
            }
        },
        
    },

}
