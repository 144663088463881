import RequestService from "@/services/request.service";

const InitialState = {
  formData: {
    login: '',
    password: ''
  },

  telegramAccessToken: '',
  emailData: '',
  conversationData: '',
  isValid: false,
  isValidTwilio: false
}

export const conversationApiModule = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    setLogin(state, value) {
      state.formData.login = value
    },
    setPassword(state, value) {
      state.formData.password = value
    }
  },
  actions: {
    sendEmail({ commit }, { id, data }) {
      return RequestService.update(`url/${id}`, { ...data })
        .then(async response => {
          console.log('response.data ', response.data)
          return Promise.resolve(response.data)
        })
        .catch(e => console.log('error', e))
    },
    conversationUpdate({ commit }, { id, data }) {
      return RequestService.update(`ottProviders/conversationProviders/edit/${id}`, { ...data })
        .then(async response => {
          commit("setData", { conversationData: response.data })
          commit("setData", { telegramAccessToken: response.data.telegram?.authToken })
          commit("setData", { isValid: response.data.telegram?.isValid })
          commit("setData", { isValidTwilio: response.data.twilio?.isValid })
          return Promise.resolve(response.data)
        })
        .catch(e => console.log('error', e))
    },
    getConversation({ commit }, { id }) {
      return RequestService.read(`ottProviders/conversationProviders/edit/${id}`)
        .then(async response => {
          commit("setData", { conversationData: response.data })
          commit("setData", { isValid: response.data.telegram?.isValid })
          commit("setData", { isValidTwilio: response.data.twilio?.isValid })
          return Promise.resolve(response.data)
        })
        .catch(e => console.log('error', e))
    },
  }
}
