var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ds-calendar-event-menu"},[_c('v-dialog',_vm._b({attrs:{"max-width":"1000","content-class":_vm.contentClass,"disabled":!_vm.hasPopover},scopedSlots:_vm._u([{key:"activator",fn:function({}){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.eventTooltip,
            placement: 'auto',
            popperClass: 'tooltip-content',
            triggers: ['click', 'touch'],
            autoHide: true,
            shown: _vm.menu ? false : null,
            distance: 5,
            html: true
          }),expression:"{\n            content: eventTooltip,\n            placement: 'auto',\n            popperClass: 'tooltip-content',\n            triggers: ['click', 'touch'],\n            autoHide: true,\n            shown: menu ? false : null,\n            distance: 5,\n            html: true\n          }"}],staticClass:"ds-calendar-event",style:(_vm.style),on:{"dblclick":function($event){return _vm.editCheck($event)},"click":_vm.setSameWidthMonthEvents}},[(_vm.showName)?_c('span',[_vm._t("eventTimeTitle",function(){return [_c('b',{staticClass:"ds-ev-title"},[_vm._v(_vm._s(_vm.details.title))]),_c('span',{staticClass:"ds-ev-description"},[_vm._v(_vm._s(_vm.details.description))])]},null,{ calendarEvent: _vm.calendarEvent, details: _vm.details })],2):_c('span',[_vm._t("eventTimeEmpty",null,null,{ calendarEvent: _vm.calendarEvent, details: _vm.details })],2)])]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},'v-dialog',_vm.popoverProps,false),[_vm._t("eventPopover",null,null,{ calendarEvent: _vm.calendarEvent, calendar: _vm.calendar, details: _vm.details, close: _vm.close, menu: _vm.menu })],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }