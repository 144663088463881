export default class FullCalendarModel {
    constructor() {
        this.reset();
    }

    toSave() {
        return {};
    }

    reset() {
        this.search = "";
        this.date = {
            start: null,
            end: null
        },
        this.eventType = null;
        this.paymentType = null;
        this.state = null;
        this.equipmentInstaller = null;

        this.filterCount = [];
    }
    
    resetCurrentData(dataKey) {
        let index = this.filterCount.indexOf(dataKey);
        this.filterCount.splice(index, 1);
        switch (dataKey) {
            case "search": {
                this.search = "";
                break;
            }

            case "date": {
                this.date = {
                    start: null,
                    end: null
                };
                break;
            }

            case 'eventType': {
                this.eventType = null;
                break;    
            }

            case "paymentType": {
                this.paymentType = null;
                break;
            }

            case "state": {
                this.state = null;
                break;
            }

            case "equipmentInstaller": {
                this.equipmentInstaller = null;
                break;
            }
        }
    }

    getFilterCount() {
        if (this.search.trim() && !this.filterCount.includes("search")) {
            this.filterCount.push("search");
        }

        if (this.date?.start && this.date?.end && !this.filterCount.includes("date")) {
            this.filterCount.push("date");
        }

        if (this.eventType && !this.filterCount.includes("eventType")) {
            this.filterCount.push("eventType");
        }

        if (this.paymentType && !this.filterCount.includes("paymentType")) {
            this.filterCount.push("paymentType");
        }

        if (this.state && !this.filterCount.includes("state")) {
            this.filterCount.push("state");
        }

        if (this.equipmentInstaller && !this.filterCount.includes("equipmentInstaller")) {
            this.filterCount.push("equipmentInstaller");
        }
    }
}
