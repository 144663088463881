import { mapState } from 'vuex';
import contryFormats from "@/constants/countries-formats";

export default {
    computed: {
        ...mapState({
            authUserProviderCountry: state => state.manageAdminModule.authUserProviderCountry,
        }),

        ottDateFormat() {
            let format = 'M/d/yyyy';
            contryFormats.map(item => {
                if (item.isoCountryCode === this.authUserProviderCountry) {
                    format = item.format
                }
            })

            return format.toUpperCase()        
        }
    },   
}