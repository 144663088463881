export default {
  data() {
    return {
      isOpenFilter: false
    };
  },
  methods: {
    toggleOpenFilter() {
      this.isOpenFilter = !this.isOpenFilter;
    },
    closeFilter() {
      this.isOpenFilter = false;
    }
  }
};
