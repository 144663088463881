const InitialState = {
  formData: {
    companyName: null,
    website: null,
    country: null,
    timezone: null,
    clientAmount: 0,
    channelAmount: 0
  },
  isLoading: false
};


export const companyInfoAdminModule = {
  namespaced: true,
  actions: {},
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    updateCompanyInfo(state, data) {
      if (data.name.length) {
        state.formData.companyName = data.name[0].name;
      }
      if (data.website) {
        state.formData.website = data.website;
      }
      if (data.country) {
        state.formData.country = data.country;
      }
      if ('clientAmount' in data) {
        state.formData.clientAmount = data.clientAmount;
      }
      if ('channelAmount' in data) {
        state.formData.channelAmount = data.channelAmount;
      }
      if (data.timezone) {
        state.formData.timezone = data.timezone;
      }

      if (data.priceGroup) {
        state.formData.priceGroup = data.priceGroup;
      }
    },

    /**
     * Reset data
     * */
    resetData(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
    },

    /**
     * Set Company Info data
     * **/
    setCompanyName(state, value) {
      state.formData.companyName = value
    },
    setWebsite(state, value) {
      state.formData.website = value
    },
    setCountry(state, value) {
      state.formData.country = value
    },
    setTimeZone(state, value) {
      state.formData.timezone = value
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};

