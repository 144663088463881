const users = () => import("@/views/users/Users");
const activity = () => import("@/views/users-activity/UsersActivity");

export const usersRout = {
  path: "/users",
  redirect: "/",
  name: "Users",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "/users/list",
      name: "UsersList",
      component: users,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    },
    {
      path: "/users/activity",
      name: "Activity",
      component: activity,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    }
  ]
};
