import RequestService from "@/services/request.service";
import _ from "lodash";

const InitialState = {
    showComponents: false,
    commentsList: [],
    selectedCommentId: null,
    formData: {
        comment: '',
        sendNotification: false,
        reminderDate: null,
        isPrivate: false,
        time: null
    }
};

export const comments = {
    namespaced: true,
    state: JSON.parse(JSON.stringify(InitialState)),
    mutations: {
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key]
            }
        },

        setFormData(state, data) {
            for (const key in data) {
                state.formData[key] = data[key]
            }
        },

        resetFormData(state) {
            state.formData = { ...InitialState.formData }
        },
        resetState(state) {
            for (let key in state) {
                state[key] = JSON.parse(JSON.stringify(InitialState[key]));
            }
        },
    },
    actions: {
        addComment({ dispatch }, commentData) {
            return RequestService.create('comments', { ...commentData }).then(async () => {
                await dispatch('getComments', commentData.client)
            }).catch(e => console.log(e))
        },

        editComment({ dispatch, state }, commentData) {
            return RequestService.update(`comments/edit/${state.selectedCommentId}`, { ...commentData })
                .then(async () => { await dispatch('getComments', commentData.client) })
                .catch(e => console.log(e))
        },

        getComments({ commit }, client) {
            return RequestService.read(`comments?client=${client}&sortBy=createdAt:desc`).then((r) => {
                commit('setData', { commentsList: r.data.results })
            }).catch(e => console.log(e))
        },

        getComment({ commit }, commentId) {
            return RequestService.read(`comments/edit/${commentId}`).then((r) => {
                commit('setData', { comment: r.data.results })
            }).catch(e => console.log(e))
        },

        deleteComment({ dispatch , rootState }, { commentId, clientId }) {
            return RequestService.delete(`comments/edit/${commentId}`)
                .then(async () => {
                    await dispatch('getComments', clientId)
                    await dispatch('notifications/getNotifications', {}, { root: true })
                })
                .catch(e => console.log(e))
        },
    }
}