import ProvidersFilterModel from "@/models/providers/ProvidersFilterModel";
import RequestService from "@/services/request.service";

const InitialState = {
  minAndMaxValue: [0, 700],
  showWarningModal: false,
  isEditProvider: false,
  isOpenSyncModal: false,
  isOpenRefreshModal: false,
  resellersList: [],
  resellersWithParent: []
};

export const providers = {
  namespaced: true,
  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  },
  actions: {
    doMultipleAction({rootState, dispatch}, data) {
      return RequestService.update(`ottProviders/actionSettings/edit`, { ...data })
        .then(async () => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})      
        })
        .catch((e) => console.log(e))
    },

    syncLiveAction({rootState, dispatch}, data) {
      return RequestService.read(`ottProviders/syncLive`, { ...data })
        .then(async (res) => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(res);
        })
        .catch((e) => {
          console.log(e);
          return Promise.reject(e);
        })
    },

    multipleDelete({rootState, dispatch}, data) {
      return RequestService.update(`ottProviders/actionSettings/delete`, { ...data })
        .then(async () => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})  
          return Promise.resolve(true);    
        })
        .catch((e) => console.log(e))
    },

    getResellersList({ commit }, filter = {}) {
      let resellersList = []
      return RequestService.read(`ottProviders/filterValues`).then(response => {
        resellersList = response.data.map((item) => {
          return {
            ...item,
            id: item.value // for multiple select needs id key
          };
        })
        commit("setData", { resellersList })
      })
    },

    getResellersWithParent({ commit }, filter = {}) {
      let query = Object.keys(filter)
      .map((key) => key + "=" + filter[key])
      .join("&");

      let resellersWithParent = []
      return RequestService.read(`ottProviders/filterValues?${query}`).then(response => {
        resellersWithParent = response.data.map((item) => {
          return {
            ...item,
            id: item.value // for multiple select needs id key
          };
        })
        commit("setData", { resellersWithParent })
      })
    }
  },
};
