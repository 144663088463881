<template>
  <v-card class="ds-calendar-event-popover-card" :class="classes">
    <div>
      <template>
        <header class="header">
          <p class="white--text mb-0 pb-1">{{ modalTitle }}</p>
          <span
            class="mdi pb-1 mdi-close white--text cursor-pointer"
            @click="close"
          ></span>
        </header>
      </template>
    </div>
    <v-card-text class="body">
      <slot name="eventCreatePopoverBodyTop" v-bind="slotData"></slot>

      <div class="G-align-start">
        <v-list class="w-50 ps-0">
          <v-list-item class="px-0">
            <v-list-item-avatar class="ma-0 G-align-start" style="align-self: start;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-menu
                      v-model="showStartDates"
                      :close-on-content-click="false"
                      :nudge-width="200"
                      offset-x
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <v-btn color="primary" dark > -->
                        <v-icon v-bind="attrs" v-on="on">mdi-calendar</v-icon>
                        <!-- </v-btn> -->
                      </template>
                      <v-card>
                        <div class="G-date-picker">
                          <div class="P-date-picker">
                            <DatePicker
                              v-model="appointmentStartDate"
                              :min-date="newDateWithMainUserTZ"
                              :is-dark="$vuetify.theme.dark"
                              @input="appointmentStartChanged()"
                              locale="en"
                              class="ott-date-picker"
                            ></DatePicker>
                          </div>
                        </div>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                <span>Start Date</span>
              </v-tooltip>
            </v-list-item-avatar>

            <v-list-item-content class="py-0">
              <slot name="eventCreatePopoverOccurs" v-bind="slotData">
                <p v-if="appointmentStartDate && appointmentEndDate" class="text-center text-h5 font-weight-bold">
                  {{$moment(appointmentStartDate, 'MM/DD/YYYY HH:mm').format(formats.start)}}
                </p>
                <p v-if="appointmentStartDate && appointmentEndDate" class="text-center text-h6">
                  <!-- <span v-if="isTimeZone12Hour(companyTimeZone)"> -->
                       {{$moment(appointmentStartDate).format('hh:mm a')}} - 
                      {{$moment(appointmentEndDate).format('hh:mm a')}}
                    <!-- {{ convert24HourTo12Hour(appointmentStartDate.split(' ')[1]) }} - 
                    {{ convert24HourTo12Hour(appointmentEndDate.split(' ')[1]) }} -->
                  <!-- </span>
                  <span v-else>
                    {{$moment(appointmentStartDate, 'MM/DD/YYYY HH:mm').format('HH:mm')}} - 
                    {{$moment(appointmentEndDate, 'MM/DD/YYYY HH:mm').format('HH:mm')}}
                  </span> -->
                  <!-- {{ occurs }} -->
                </p>
              </slot>

              <transition name="fade">
                <v-list-item-title v-if="location" class="text-center">
                  Connections:
                  <i v-if="locationSubscriptions.length">{{
                    locationSubscriptions.join(", ")
                  }}</i>
                  <i v-if="!locationSubscriptions.length">~</i>
                </v-list-item-title>
              </transition>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div class="G-align-center w-50 px-0 mt-3">
          <div class="P-padding-25">
            <ott-input
              v-model="startTime"
              @input="startTimeOnInput"
              :min='timesMin'
              :max='timesMax'
              type="time"
              label="Start Time"
            ></ott-input>
          </div>

          <div class="P-padding-25">
            <ott-input
              v-model="endTime"
              @input="endTimeOnInput"
              :min='timesMin'
              :max='timesMax'
              type="time"
              label="End Time"
            ></ott-input>
          </div>

          <div class="P-padding-25">
            <v-radio-group
              v-model="ampm"
              @change="amPmChange"
              class="pt-0"
            >
              <div class="G-justify-between flex-column">
                <div class="P-radio-box">
                  <ott-radio
                    value="am"
                    label="am"
                  />
                </div>
                <div class="P-radio-box">
                  <ottRadio
                    value="pm"
                    label="pm"
                  />
                </div>
              </div>
            </v-radio-group>
          </div>
          
          <!-- <ds-schedule-times
            :schedule="calendarEvent?.schedule"
            :read-only="false"
          ></ds-schedule-times> -->
        </div>
      </div>

      <div class="d-flex G-flex-wrap P-form-data">
        <div class="P-padding-50">
          <ott-select
            v-model="title"
            :items="titlesList"
            :clear="true"
            :error="$v.formData.title.$error"
            label="Title*"
            selected-text="name"
          />
        </div>
        <div class="P-padding-50 P-customers">
          <ott-input
            v-show="!showCustomerSelect"
            v-model="customer"
            @emitFunc="searchCustomers"
            :error="showCustomerError ? customerError : false"
            :hideDetails="true"
            :autofocus="customerAutofocus"
            label="Customer*"
          ></ott-input>

          <ott-select
            v-if="showCustomerSelect"
            :value="customer"
            :items="[{
              name: formData.customer.name,
              value: formData.customer.name
            }]"
            :multiple="false"
            :persistentHint="true"
            @click="customerSelectClick"
            selectClass="P-customer-select"
            ref="customerSelect"
            label="Customer*"
            selected-text="name"
          />

          <div
            v-if="customersList.length && showCustomersList"
            class="P-customers__result P-menu-list"
          >
            <v-list>
              <v-list-item
                v-for="(item, key) of customersList"
                @click="selectCustomer(item)"
                :key="key"
                :ripple="false" 
              >
                <div class="list-item">
                  <li v-html="item.name" class="G-flex-center py-2" />
                </div>
              </v-list-item>
            </v-list>
          </div>
        </div>

        <div class="P-padding-50">
          <ott-select
            v-model="location"
            :clear="true"
            :items="locationList"
            :multiple="false"
            :persistentHint="true"
            :error="$v.formData.location.$error"
            label="Location*"
            @emitFunc="selectLocation($event)"
            @emmitClearData="clearSubscriptions"
            selected-text="name"
          />
        </div>

        <div v-if="title !== 3" class="P-padding-50">
          <ott-select
            v-model="equipmentInstaller"
            :clear="true"
            :items="installersList"
            :multiple="false"
            :persistentHint="true"
            :error="$v.formData.equipmentInstaller.$error"
            label="Equipment Installer*"
            selected-text="name"
          />
        </div>
        <div class="P-padding-50">
          <ott-select
            v-model="customerAddress"
            :items="clientAddresses"
            :clear="true"
            :error="$v.formData.customerAddress.$error"
            @emitFunc="selectCustomerAddress($event)"
            @emmitClearData="clearLatLong"
            label="Customer Address*"
            selected-text="name"
          />
        </div>

        <div class="P-padding-25">
          <ott-input
            v-model="customerLatitude"
            disabled
            readonly
            label="Latitude"
          ></ott-input>
        </div>

        <div class="P-padding-25">
          <ott-input
            v-model="customerLongitude"
            disabled
            readonly
            label="Longitude"
          ></ott-input>
        </div>

        <div class="P-padding-50">
          <ott-select
            v-model="officeAddress"
            :items="companyAddressList"
            :clear="true"
            :error="$v.formData.officeAddress.$error"
            label="Appartement or office address*"
            selected-text="name"
          />
        </div>
        <div :class="paymentType && paymentType === 'free' ? 'P-padding-25' : paymentType && paymentType !== 'free' ? 'P-padding-20' : 'P-padding-25'">
          <ott-select
            v-model="paymentType"
            :items="paymentTypes"
            :clear="false"
            :error="$v.formData.paymentType.$error"
            label="Payment*"
            selected-text="name"
          />
        </div>

        <div v-if="paymentType && paymentType !== 'free'" class="P-padding-10">
          <ott-input
            v-model="paymentPrice"
            :error="$v.formData.paymentPrice.$error"
            prefix="$"
            :min="0"
            type="number"
            label="Price"
          ></ott-input>
        </div>

        <div :class="paymentType && paymentType === 'free' ? 'P-padding-25' : paymentType && paymentType !== 'free' ? 'P-padding-20' : 'P-padding-25'">
          <ott-select
            v-model="state"
            :clear="false"
            :items="statusList"
            :multiple="false"
            :persistentHint="true"
            :error="$v.formData.state.$error"
            label="Status*"
            selected-text="name"
          />
        </div>

        <div class="P-padding-50">
            <ott-input
              v-model="comment"
              label="Comment"
            ></ott-input>
        </div>

        <div class="d-flex flex-wrap">
          
          <div class="P-padding-50 d-flex flex-column">
            <transition name="slide">
              <div class="h-100" v-if="comments.length">
                <v-card
                  class="mx-auto w-100 P-event-comment-cont"
                  tile
                >
                  <v-subheader>Comments</v-subheader>
                  <div class="comments-cont">
                    <v-list dense>
                      <v-list-item
                        v-for="(item, index) in comments"
                        :key="index"
                      >
                        <v-list-item-content>
                          <div class="text-14" :class="{'bordered-comment pb-4': index !== comments.length - 1}">
                            <p class="mb-1"><b>From:</b> <ins>{{ item.userName}}</ins></p>
                            <div class="d-flex align-baseline P-event-comment-content mb-1">
                              <b class="me-2">Content:</b>
                              <p class="text-wrap" v-html="item.comment" />
                            </div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-card>
              </div>
            </transition>

            <div>
<!-- 
              <quill-editor
                v-model="comment"
                :options="{ placeholder: 'Comment' }"
                :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
                ref="myQuillEditor"
              ></quill-editor> -->
            </div>
          </div>

          <transition name="fade">
            <div
              v-if="selectedAddressImage"
              class="P-padding-50"
            >
              <img
                @click="isShowImage = true"
                :src="selectedAddressImage" 
                class="P-address-image"
                title="Customer Address Image" 
              />
            </div>
          </transition>
        </div>
      </div>

      <slot name="eventCreatePopoverBodyBottom" v-bind="slotData"></slot>

      <div class="P-padding-100">
        <SaveResetButtons
          :left-click-func="close"
          :right-label="isEditMode ? 'Save' : 'Add'"
          :right-click-func="save"
          :is-loading="isLoading"
        />
      </div>
    </v-card-text>

    <slot name="eventCreatePopoverActions" v-bind="slotData"></slot>

    <image-modal
      v-if="isShowImage"
      @closeModal="isShowImage = false"
      :isOpenModal="isShowImage"
      :showEditBtn="false"
      :imageSrc="selectedAddressImage"
      modal-title="Address Image"
    />
    
  </v-card>
</template>

<script>
import {
  CalendarEvent,
  Calendar,
  Schedule,
  Day,
  Pattern,
  Functions as fn,
} from "dayspan";
import { mapState, mapMutations, mapActions } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import DatePicker from "v-calendar/src/components/DatePicker";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker";
import SaveResetButtons from "@/components/SaveResetButtons";
import ImageModal from "@/components/ImageModal";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";

export default {
  name: "dsCalendarEventCreatePopover",
  components: {
    OttInput,
    OttSelect,
    OttRadio,
    DatePicker,
    VueTimepicker,
    SaveResetButtons,
    ImageModal
  },

  validations: {
    formData: {
      title: { required },
      location: { required },
      equipmentInstaller: {
        required: requiredIf(function () {
          return this.title !== 3;
        }),
      },
      customerAddress: { required },
      officeAddress: { required },
      state: { required },
      paymentType: { required },
      paymentPrice: {
        required: requiredIf(function () {
          return this.paymentType && this.paymentType !== 'free';
        }),
      }
    },
  },

  mixins: [DateFormatMixin, ConvertTimeZoneMixin],

  props: {
    calendarEvent: {
      required: true,
      type: CalendarEvent,
    },

    calendar: {
      required: true,
      type: Calendar,
    },

    isEditMode: {
      type: Boolean,
      default: false
    },

    modalTitle: {
      type: String,
      default: () => 'Add Event'
    },

    close: {
      type: Function,
    },

    day: {
      type: Day,
    },

    formats: {
      validate(x) {
        return this.$dsValidate(x, "formats");
      },
      default() {
        return this.$dsDefaults().formats;
      },
    },

    labels: {
      validate(x) {
        return this.$dsValidate(x, "labels");
      },
      default() {
        return this.$dsDefaults().labels;
      },
    },
  },

  computed: {
    ...mapState({
      formData: (state) => state.fullCalendar.formData,
      customersList: (state) => state.fullCalendar.customersList,
      clientAllAddresses: (state) => state.addressClient.allAddresses,
      clientLocationList: (state) => state.locationClient.locationList,
      companyId: (state) => state.auth.user.provider.id,
      authUser: (state) => state.auth.user,
      companyAddressList: (state) => state.companyAddressesAdmin.fullAddressList,
      clientAllBillingAddresses: (state) => state.addressClient.allBillingAddress,
      clientAllShippingAddresses: (state) => state.addressClient.allShippingAddresses,
      subscriptions: (state) => state.packagesModule.subscriptions,
      usersList: (state) => state.users.dataTable,
      isLoading: (state) => state.appModule.isLoading,
      addEventByPlus: (state) => state.fullCalendar.addEventByPlus
    }),

    timesMin() {
      // if (this.isTimeZone12Hour(this.companyTimeZone)) {
        return '01:00'
      // } else {
      //   return '00:00'
      // }
    },
    timesMax() {
      // if (this.isTimeZone12Hour(this.companyTimeZone)) {
        return '12:59'
      // } else {
      //   return '23:59'
      // }
    },

    statusList() {
      if (!this.isEditMode) {
        return [
          {
            name: 'Active',
            value: 'active'
          }
        ]
      } else {
        return [
          {
            name: 'Active',
            value: 'active'
          },
          {
            name: 'Process',
            value: 'process'
          },
          {
            name: 'Completed',
            value: 'completed'
          },
        ]
      }
    },

    locationList() {
      const list = [];
      if (this.clientLocationList.length) {
        this.clientLocationList.map((item) => {
          list.push({
            name: `<div class="G-align-center"><span>${item.locationName}(${item.login})</span> <span class="ms-1">${this.getLocationStatus(item)}</span></div>`,
            value: item.id,
          }); 
        });
      }
      return list;
    },

    locationSubscriptions() {
      //get all subscriped packages not duplicated names
      let list = [];
      if (this.subscriptions.length) {
        this.subscriptions.map((item) => {
          if (!list.includes(this.getName(item.package.name))) {
            list.push(this.getName(item.package.name));
          }
        });
      }
      return list;
    },

    title: {
      set(title) {
        this.setFormData({ title });
      },
      get() {
        return this.formData.title;
      },
    },

    paymentType: {
      set(paymentType) {
        this.setFormData({ paymentType });
      },
      get() {
        return this.formData.paymentType;
      },
    },

    paymentPrice: {
      set(paymentPrice) {
        this.setFormData({ paymentPrice });
      },
      get() {
        return this.formData.paymentPrice;
      },
    },

    customer: {
      set(value) {
        this.setFormData({
          customer: {
            value: value,
            name: value,
            id: null,
          },
        });
      },
      get() {
        return this.formData.customer?.name;
      },
    },

    customerError() {
      return !this.customer ? true : false;
    },

    location: {
      set(location) {
        this.setFormData({ location });
      },
      get() {
        return this.formData.location;
      },
    },

    equipmentInstaller: {
      set(equipmentInstaller) {
        this.setFormData({ equipmentInstaller });
      },
      get() {
        return this.formData.equipmentInstaller;
      },
    },

    customerAddress: {
      set(customerAddress) {
        this.setFormData({ customerAddress });
      },
      get() {
        return this.formData.customerAddress;
      },
    },

    customerLatitude: {
      set(customerLatitude) {
        this.setFormData({ customerLatitude });
      },
      get() {
        return this.formData.customerLatitude;
      },
    },

    customerLongitude: {
      set(customerLongitude) {
        this.setFormData({ customerLongitude });
      },
      get() {
        return this.formData.customerLongitude;
      },
    },

    appointmentStartDate: {
      set(appointmentStartDate) {
        this.setFormData({ appointmentStartDate });
      },
      get() {
        return this.formData.appointmentStartDate;
      },
    },

    appointmentEndDate: {
      set(appointmentEndDate) {
        this.setFormData({ appointmentEndDate });
      },
      get() {
        return this.formData.appointmentEndDate;
      },
    },

    officeAddress: {
      set(officeAddress) {
        this.setFormData({ officeAddress });
      },
      get() {
        return this.formData.officeAddress;
      },
    },

    comment: {
      set(comment) {
        this.setFormData({ comment });
      },
      get() {
        return this.formData.comment;
      },
    },

    state: {
      set(state) {
        this.setFormData({ state });
      },
      get() {
        return this.formData.state;
      },
    },

    directions: {
      set(directions) {
        this.setFormData({ directions });
      },
      get() {
        return this.formData.directions;
      },
    },

    slotData() {
      return {
        calendarEvent: this.calendarEvent,
        calendar: this.calendar,
        close: this.close,
        details: this.details,
      };
    },

    classes() {
      return {
        "ds-event-cancelled": this.calendarEvent?.cancelled,
      };
    },

    // startDate() {
    //   return this.$moment(this.calendarEvent.event.data.eventData?.startDate).format(this.ottDateFormat + ' ' + 'HH:mm');
    // },

    // endDate() {
    //   return this.$moment(this.calendarEvent.event.data.eventData?.endDate).format(this.ottDateFormat + ' ' + 'HH:mm');
    // },

    isValid() {
      return this.$dayspan.isValidEvent(
        this.details,
        this.calendarEvent?.schedule,
        this.calendarEvent
      );
    },

    occurs() {
      return this.$dayspan.getEventOccurrence(
        this.calendarEvent?.schedule,
        this.calendarEvent?.start,
        this.labels,
        this.formats
      );
    },
  },

  data: (vm) => ({
    startTime: '',
    endTime: '',
    ampm: null,
    details: vm.buildDetails(),
    schedule: new Schedule(),
    showCustomersList: false,
    showCustomerError: false,
    showStartDates: false,
    showEndDates: false,
    titlesList: [
      {
        name: "New Connection",
        value: 'newConnection',
      },
      {
        name: "Technical",
        value: "technical",
      },
    ],
    paymentTypes: [
      {
        name: 'Paid',
        value: 'paid'
      },
      {
        name: 'Unpaid',
        value: 'unpaid'
      },
      {
        name: 'Pending',
        value: 'pending'
      },
      
      {
        name: 'Free',
        value: 'free'
      }
    ],
    clientAddresses: [],
    installersList: [],
    showCustomerSelect: false,
    customerAutofocus: false,
    selectedAddressImage: null,
    isShowImage: false,
    comments: [],
    commentId: null
  }),

  async created() {
    this.resetFormData();
    this.resetCustomerData();

    //Ggetting users list to show installers list
    await this.getUsersList({ limit: 100000 })
    if (this.usersList.length) {
      const installers = this.usersList.filter(item => item.rolesInfo.equipmentInstaller)
      installers.map((item) => {
        this.installersList.push({
          name: `${item.firstname} ${item.lastname} (${item.provider.name && item.provider.name.length ? item.provider.name[0].name : 'Unknown Company'})`,
          value: item.id
        })
      })
    }

    // Getting auth providers addresses to show in Office Addresses
    await this.getCompanyAddresses(this.companyId)
  },

  destroyed() {
  },

  async mounted() {
    if (this.calendarEvent.event.data.eventData) {
      this.setData({
        eventId: this.calendarEvent.event.data.eventData.id
      })
    }
    
    if (this.isEditMode) {
      if (this.calendarEvent.event.data.eventData?.client?.id) {
        await this.getClientData(this.calendarEvent.event.data.eventData?.client?.id).then(item => {
          this.setFormData({
            customer: {
              name: `<div class="G-align-center"><span class="me-2 G-align-center">${item?.personalInfo?.firstname} ${item?.personalInfo?.lastname} (${item?.provider ? item?.provider?.name[0].name : 'No Provider Name'})</span> ${ this.getClientStatus(item) }</div>`,
              value: `<div class="G-align-center"><span class="me-2 G-align-center">${item?.personalInfo?.firstname} ${item?.personalInfo?.lastname} (${item?.provider ? item?.provider?.name[0].name : 'No Provider Name'})</span> ${ this.getClientStatus(item) }</div>`,
              id: this.calendarEvent?.event?.data?.eventData?.client?.id,
            },
          })
        })

        this.showCustomerSelect = true
      }

      if (this.isEditMode) {
        // if is edit mode getting client locations and addresses to show them in selects
        await this.getClientLocations({ clientId: this.calendarEvent.event.data.eventData?.client?.id });
        await this.getClientAddresses(this.calendarEvent.event.data.eventData?.client?.id);

        // Getting client addresses to show in customer addresses
        this.clientAddresses = [...this.clientAllBillingAddresses, ...this.clientAllShippingAddresses]
      }

      let location = this.calendarEvent.event.data.eventData?.location
      let equipmentInstaller = this.calendarEvent.event.data?.eventData?.equipmentInstaller
      this.title = this.calendarEvent.event.data.eventData.title
      this.location = location?.id
      this.equipmentInstaller = equipmentInstaller?.id ? equipmentInstaller?.id : equipmentInstaller
      this.appointmentStartDate = this.calendarEvent.event.data.eventData?.startDate
      this.appointmentEndDate = this.calendarEvent.event.data.eventData?.endDate
      delete this.calendarEvent.event.data.eventData?.customerAddress?.updatedAt
      delete this.calendarEvent.event.data.eventData?.customerAddress?.createdAt 
      this.customerAddress = this.calendarEvent.event.data.eventData?.customerAddress
      
      this.customerLatitude = this.calendarEvent.event.data.eventData?.lat ? this.calendarEvent.event.data.eventData?.lat : this.customerAddress?.lat
      this.customerLongitude = this.calendarEvent.event.data.eventData?.long ? this.calendarEvent.event.data.eventData?.long : this.customerAddress?.long

      delete this.calendarEvent.event.data.eventData?.officeAddress?.updatedAt
      delete this.calendarEvent.event.data.eventData?.officeAddress?.createdAt
      this.officeAddress = this.calendarEvent.event.data.eventData?.officeAddress

      this.state = this.calendarEvent.event.data.eventData?.state
      this.paymentType = this.calendarEvent.event.data.eventData?.paymentType
      this.paymentPrice = this.calendarEvent.event.data.eventData?.paymentPrice
      this.directions = this.calendarEvent.event.data.eventData?.directions

      this.comments = this.calendarEvent.event.data.eventData?.comments

      if (this.comments.length) {
        this.comments.map((item) => {
          if (item.user === this.authUser.id) {
            this.commentId = item.id
            this.comment = item.comment
          }
        })
      }
    } 

    if (this.calendarEvent && !this.isEditMode) {
      this.appointmentStartDate = this.$moment(new Date(this.calendarEvent.start)).format('MM/DD/YYYY HH:mm');
      this.appointmentEndDate = this.$moment(new Date(this.calendarEvent.end)).format('MM/DD/YYYY HH:mm');
    }

    if (this.calendarEvent && this.isEditMode) {
      this.appointmentStartDate = this.$moment(new Date(this.calendarEvent.start), 'DD-MM-YYYY hh:mm').format('MM/DD/YYYY HH:mm');
      this.appointmentEndDate = this.$moment(new Date(this.calendarEvent.end), 'DD-MM-YYYY hh:mm').format('MM/DD/YYYY HH:mm');
    }

    if (this.addEventByPlus && !this.isEditMode) {
      this.appointmentStartDate = this.$moment(this.newDateWithMainUserTZ).format('MM/DD/YYYY HH:mm');
      this.appointmentEndDate = this.$moment(this.newDateWithMainUserTZ).add(1, 'hours');
    }

    // getting customer address image
    if (this.customerAddress && this.clientAllAddresses.length) {
      this.clientAllAddresses.map((item) => {
        if (item?.id === this.customerAddress?.id) {
          this.selectedAddressImage = 'data:image/jpeg;base64,' + item?.image
        }
      });
    }

    this.startTime = this.$moment(this.appointmentStartDate, 'MM/DD/YYYY HH:mm').format('hh:mm') 
    this.endTime =  this.$moment(this.appointmentEndDate, 'MM/DD/YYYY HH:mm').format('hh:mm')
    this.ampm = this.$moment(this.appointmentStartDate, 'MM/DD/YYYY HH:mm a').format('a') 

  },

  beforeDestroy() {
    this.resetFormData();
    this.resetCustomerData();
    this.$v.$reset();
    this.setData({ addEventByPlus: false })
  },

  methods: {
    ...mapMutations({
      setData: "fullCalendar/setData",
      setFormData: "fullCalendar/setFormData",
      resetFormData: "fullCalendar/resetFormData",
      clientAddressSetData: "addressClient/setData",
      clientLocationSetData: "locationClient/setData",
      packagesSetData: "packagesModule/setData",
    }),

    ...mapActions({
      searchCustomersAction: "fullCalendar/searchCustomers",
      getUsersList: 'users/getCalendarUsersList',
      getClientAddresses: "addressClient/fetchAddress",
      getClientLocations: "locationClient/getLocations",
      getLocationPackages: "packagesClients/getLocationPackages",
      getSubscriptions: "packagesModule/getSubscriptions",
      getCompanyAddresses: 'companyAddressesAdmin/getCompanyAddresses',   
      addEvent: "fullCalendar/addEvent",
      editEvent: "fullCalendar/editEvent",
      getClientData: "clientsModule/getClientData",
      addEventComment: "fullCalendar/addEventComment",
      editEventComment: "fullCalendar/editEventComment"
    }),

    amPmChange(type) {
      let [startHours, startMinutes] = this.$moment(this.appointmentStartDate, 'MM/DD/YYYY hh:mm').format('hh:mm').split(':')
      let [endHours, endMinutes] = this.$moment(this.appointmentEndDate, 'MM/DD/YYYY hh:mm').format('hh:mm').split(':')

      if (type === 'pm') {
        startHours = this.convert12HourTo24Hour(`${startHours}:${startMinutes}`).split(':')[0]
        startMinutes = this.convert12HourTo24Hour(`${startHours}:${startMinutes}`).split(':')[1]
        endHours = this.convert12HourTo24Hour(`${endHours}:${endMinutes}`).split(':')[0]
        endMinutes = this.convert12HourTo24Hour(`${endHours}:${endMinutes}`).split(':')[1]
      } else {
        startHours = this.convert24HourTo12Hour(`${startHours}:${startMinutes}`).split(':')[0]
        startMinutes = this.convert24HourTo12Hour(`${startHours}:${startMinutes}`).split(':')[1]
        endHours = this.convert24HourTo12Hour(`${endHours}:${endMinutes}`).split(':')[0]
        endMinutes = this.convert24HourTo12Hour(`${endHours}:${endMinutes}`).split(':')[1]
      
        if (startHours === '12') {
          startHours = '00'
        }

        if (endHours === '12') {
          endHours = '00'
        }
      }
      
      this.appointmentStartDate = this.$moment(this.appointmentStartDate).set({ hours: startHours, minutes: startMinutes }).format('MM/DD/YYYY HH:mm');
      this.appointmentEndDate = this.$moment(this.appointmentEndDate).set({ hours: endHours, minutes: endMinutes }).format('MM/DD/YYYY HH:mm');

    },

    startTimeOnInput($event) {
      if ($event.trim()) {
        let [hours, minutes] = $event.trim().toString().split(':')

        if (this.ampm === 'pm') {
          [hours, minutes] = this.convert12HourTo24Hour(`${hours}:${minutes}`).split(':')
        }

        if (hours === '12') {
          hours = '00'
        }

        if (hours[0] === '0')
          hours = hours.replace('0', '')
        if (minutes[0] === '0')
          minutes = minutes.replace('0', '')
        
          this.appointmentStartDate = this.$moment(this.appointmentStartDate, 'MM/DD/YYYY HH:mm').set({hours, minutes});
          this.appointmentEndDate = this.$moment(this.appointmentStartDate, 'MM/DD/YYYY HH:mm').add(1, 'minutes');

          this.endTime = this.appointmentEndDate.format('hh:mm')
      }
    },

    endTimeOnInput($event) {
      if ($event.trim()) {
        let [hours, minutes] = $event.trim().toString().split(':')
        let appointmentStartDate = this.appointmentStartDate
        let appointmentEndDate = this.appointmentEndDate

        if (this.ampm === 'pm') {
          [hours, minutes] = this.convert12HourTo24Hour(`${hours}:${minutes}`).split(':')
        }

        if (hours === '12') {
          hours = '00'
        }

        if (hours[0] === '0')
          hours = hours.replace('0', '')
        if (minutes[0] === '0')
          minutes = minutes.replace('0', '')
        
        // Checking if after set Hours, Minutes >= 1 then set times to usage
        appointmentEndDate = this.$moment(appointmentEndDate, 'MM/DD/YYYY HH:mm').set({hours, minutes});
        const datesDifferece = 
          this.$moment(appointmentEndDate, 'MM/DD/YYYY HH:mm')
            .diff(this.$moment(appointmentStartDate, 'MM/DD/YYYY HH:mm'), 'minutes');

        if (datesDifferece >= 1) {
          this.appointmentEndDate = this.$moment(this.appointmentEndDate).set({hours, minutes});
        }
      }
    },

    convert24HourTo12Hour(time24) {
      // Split the time into hours and minutes
      const [hours, minutes] = time24.split(":");

      // Convert hours and minutes to numbers
      const hoursNum = parseInt(hours, 10);
      const minutesNum = parseInt(minutes, 10);

      // Determine AM or PM
      const ampm = hoursNum >= 12 ? "pm" : "am";

      // Convert hours to 12-hour format
      const hours12 = hoursNum > 12 ? hoursNum - 12 : hoursNum === 0 ? 12 : hoursNum;

      // Ensure leading zeros for hours and minutes
      const hoursStr = hours12 < 10 ? `0${hours12}` : `${hours12}`;
      const minutesStr = minutesNum < 10 ? `0${minutesNum}` : `${minutesNum}`;

      // Create the 12-hour time string
      const time12 = `${hoursStr}:${minutesStr} ${ampm}`;

      return time12;
    },

    convert24HourTo12HourWithoutAm(time24) {
      // Split the time into hours and minutes
      const [hours, minutes] = time24.split(":");

      // Convert hours and minutes to numbers
      const hoursNum = parseInt(hours, 10);
      const minutesNum = parseInt(minutes, 10);

      // Convert hours to 12-hour format
      const hours12 = hoursNum > 12 ? hoursNum - 12 : hoursNum === 0 ? 12 : hoursNum;

      // Ensure leading zeros for hours and minutes
      const hoursStr = hours12 < 10 ? `0${hours12}` : `${hours12}`;
      const minutesStr = minutesNum < 10 ? `0${minutesNum}` : `${minutesNum}`;

      // Create the 12-hour time string
      const time12 = `${hoursStr}:${minutesStr}`;

      return time12;
    },

    convert12HourTo24Hour(time12h) {
      const [hours, minutes] = time12h.split(':');
      let hours24h = parseInt(hours, 10);

      // If the input time is 12:xx, it remains the same in 24-hour format
      if (hours24h === 12) {
        return `${hours}:${minutes}`;
      }

      // If the input time is 1:xx to 11:xx, add 12 to the hours
      if (hours24h >= 1 && hours24h <= 11) {
        hours24h += 12;
      }

      // Format hours and minutes as a 24-hour time string
      const formattedHours = String(hours24h).padStart(2, '0');
      const formattedMinutes = minutes;

      return `${formattedHours}:${formattedMinutes}`;
    },

    isTimeZone12Hour(timezone) {
      // Create a date object for a specific timezone
      const options = { timeZone: timezone, hour: 'numeric' };
      const date = new Date().toLocaleString('en-US', options);

      // Check if the "hour12" property of the format is true
      return new Intl.DateTimeFormat('en-US', options).resolvedOptions().hour12;
    },

    getClientStatus(item) {
      /*
          client status by subscriptionState value
          0 - Inactive
          1 - Pending
          2 - Canceled
          3 - Active
      */
      
      if (item?.subscriptionState === 0) {
          return `<span class="G-badge-calendar G-advanced-badge">Inactive</span>`
      }

      if (item?.subscriptionState === 1) {
          return `<span class="G-badge-calendar G-pending-badge">Pending</span>`
      }

      if (item?.subscriptionState === 2) {
          return `<span class="G-badge-calendar G-disabled-badge">Canceled</span>`
      }

      if (item?.subscriptionState === 3) {
          if (item?.inBlock === 0 && item?.inPause === 0) {
              return `<span class="G-badge-calendar G-approve-badge">Active</span>`
          }

          if (item?.inBlock === 0 && item?.inPause === 1) {
              return `<span class="G-badge-calendar G-paused-badge">Paused</span>`
          }

          if (item?.inBlock === 0 && item?.inPause === 2) {
              return `<span class="G-badge-calendar G-paused-badge">Semipaused</span>`
          }

          if (item?.inBlock === 1 && item?.inPause === 0) {
              return `<span class="G-badge-calendar G-reject-badge">Blocked</span>`
          }

          if (item?.inBlock === 2 && item?.inPause === 0) {
              return `<span class="G-badge-calendar G-reject-badge">Semiblocked</span>`
          }

          if (item?.inBlock === 1 && item?.inPause === 1) {
              return `<span class="G-badge-calendar G-reject-badge">Blocked</span> <br>
                  <span class="G-badge-calendar G-paused-badge">Paused</span>`
          }

          if (item?.inBlock === 1 && item?.inPause === 2) {
              return `<span class="G-badge-calendar G-reject-badge">Blocked</span> <br>
                      <span class="G-badge-calendar G-paused-badge">Semipaused</span>`
          }

          if (item?.inBlock === 2 && item?.inPause === 1) {
              return `<span class="G-badge-calendar G-reject-badge">Semiblocked</span> <br>
                  <span class="G-badge-calendar G-paused-badge">Paused</span>`
          }

          if (item?.inBlock === 2 && item?.inPause === 2) {
              return `<span class="G-badge-calendar G-reject-badge">Semiblocked</span> <br>
                  <span class="G-badge-calendar G-paused-badge">Semipaused</span>`
          }
      }

      if (item?.inBlock === undefined || item?.inPause === undefined || (item?.inBlock === 0 && item?.inPause === 0)) {
          return '<span></span>'
      }
    },

    customerSelectClick() {
      if (!this.customer.value || !this.customer.name) {
        const tempElement = document.createElement("div");

        // Set the innerHTML of the temporary element to the HTML string
        tempElement.innerHTML = this.formData.customer.value;

        // Access the text content
        let textContent = tempElement.textContent;

        if (textContent.includes('(')) {
          textContent = textContent.split('(')[0]
        }

        this.setFormData({
          customer: {
            value: textContent,
            name: textContent,
            id: this.formData.customer.id,
          },
        });
      }

      this.customerAutofocus = true
      this.showCustomerSelect = false
    },

    getLocationStatus(location) {
      /* 
        location status by subscriptionState value
        0 - Inactive
        1 - Pending
        2 - Canceled
        3 - Active
      */

      let status = ''
      let pauseDuration = null
      let expireDate = null
      let pauseStartDate = null
    
      //get pause duration
      if (location?.subscriptionExpireDate) {
        expireDate = this.$moment(location.subscriptionExpireDate)
        pauseStartDate = this.$moment(location.pauseStartDate)
        pauseDuration = this.convertMsToDays(expireDate.diff(pauseStartDate))
      }

      if (location.isBlockLocation === undefined || location.isPauseSubscriptions === undefined || (!location.isBlockLocation && !location.isPauseSubscriptions)) {
        status = ''
      }

      if (location.subscriptionState === 0) {
        status = `<span class="lh-11 G-flex-center flex-column G-badge G-advanced-badge">Inactive</span>`
      }

      if (location.subscriptionState === 1) {
        status = `<span class="lh-11 G-flex-center flex-column G-badge G-pending-badge"><span>Pending</span> ${ location?.subscriptionPendingDate ? `<span>${location.subscriptionPendingDate}</span>` : '' }</span>`
      }

      if (location.subscriptionState === 2) {
        status = `<span class="lh-11 G-flex-center flex-column G-badge G-disabled-badge"><span>Canceled</span> ${ location?.subscriptionCancelDate ? `<span>${location.subscriptionCancelDate}</span>` : '' }</span>`
      }

      if (location.subscriptionState === 3) {
        if (location.isBlockLocation && location.isPauseSubscriptions) {
          status = `<span class="lh-11 G-flex-center flex-column G-badge G-reject-badge mb-2">Blocked</span>
                    <span class="lh-11 G-flex-center flex-column G-badge G-paused-badge G-paused-badge-time"><span>Paused</span> <span>${pauseDuration}</span></span>`
        }

        if (location.isBlockLocation && !location.isPauseSubscriptions) {
          status = `<span class="lh-11 G-flex-center flex-column G-badge G-reject-badge">Blocked</span>`
        }

        if (!location.isBlockLocation && location.isPauseSubscriptions) {
          status = `<span class="lh-11 G-flex-center flex-column G-badge G-paused-badge G-paused-badge-time"><span>Paused</span> <span> ${pauseDuration}</span></span>`
        }

        if (!location.isBlockLocation && !location.isPauseSubscriptions) {
          status = `<span class="lh-11 G-flex-center flex-column G-badge G-approve-badge"><span>Active</span> ${ location?.subscriptionActivationDate ? `<span>${location.subscriptionActivationDate}</span>` : '' }</span>`
        }
      }

      return status
    },

    convertMsToDays(t) {
      var cd = 24 * 60 * 60 * 1000,
          ch = 60 * 60 * 1000,
          d = Math.floor(t / cd),
          h = Math.floor((t - d * cd) / ch),
          m = Math.round((t - d * cd - h * ch) / 60000);

      return `${d}d ${h}h ${m}m`
    },

    async searchCustomers() {
      if (this.customer) {
        this.showCustomersList = true;
        await this.searchCustomersAction(this.customer.trim());
      }

      if (!this.customer) {
        //if searchVal is empty, close customers list, empty client addresses, lat, long, locations
        this.resetCustomerData();
      }
    },

    async selectCustomer(customer) {
      this.setFormData({
        customer: { ...customer },
      });
      this.showCustomersList = false;
      this.showCustomerSelect = true

      await this.getClientAddresses(customer.id);
      await this.getClientLocations({ clientId: customer.id });

      // Getting client addresses to show in customer addresses
      this.clientAddresses = [...this.clientAllBillingAddresses, ...this.clientAllShippingAddresses]
    },

    async selectLocation(location) {
      this.packagesSetData({
        subscriptions: [],
      });

      if (location) await this.getSubscriptions({ location });
    },

     selectCustomerAddress(address) {
      //after selecting customer address, check if that address has lat or long get them
      this.clientAllAddresses.map((item) => {
        if (item?.id === address?.id) {
          if (item?.lat) this.customerLatitude = item.lat;
          if (item?.long) this.customerLongitude = item.long;
          if (item?.image) this.selectedAddressImage = 'data:image/jpeg;base64,' + item?.image
        }
      });
    },

    clearLatLong() {
      this.customerLatitude = null;
      this.customerLongitude = null;
      this.selectedAddressImage = null;
    },

    clearSubscriptions() {
      this.packagesSetData({
        subscriptions: [],
      });
    },

    getName(nameList) {
      let theName = null;
      if (nameList.length) {
        nameList.map(
          (name) =>
            (theName = name.lang === "en" ? name.name : nameList[0].name)
        );
      }
      return theName;
    },

    resetCustomerData() {
      this.showCustomersList = false;
      this.customerAddress = null;
      this.location = null;
      this.clientAddressSetData({ billingAddressList: [] });
      this.clearLatLong();
      this.clientLocationSetData({ locationList: [] });
      this.clearSubscriptions();
    },

    async save() {
      this.showCustomerError = true;
      this.$v.formData.$touch();
      let ev = this.getEvent("creating");

      if (!this.$v.formData.$error && !this.customerError) {
        this.$emit("creating", ev);

        if (!ev.handled && ev.details && ev.calendarEvent) {
          ev.created = ev.calendarEvent?.event;

          this.$dayspan.setEventDetails(
            ev.details,
            ev.created.data,
            ev.created,
            ev.calendarEvent
          );

          if (ev.calendar) {
            ev.calendar.addEvent(ev.created);
            ev.added = true;
          }

          this.$emit("created", ev);

          if (ev.calendar && ev.refresh) {
            ev.calendar.refreshEvents();
          }

          ev.handled = true;

          this.$emit("event-create", ev.created);
        }

        const event = {
          title: this.title,
          client: this.formData.customer.id,
          location: this.location,
          equipmentInstaller: this.equipmentInstaller,
          customerAddress: this.customerAddress,
          lat: this.customerLatitude,
          long: this.customerLongitude,
          officeAddress: this.officeAddress,
          description: this.comment, // key name need to change comment
          // directions: this.directions,
          state: this.state,
          paymentType: this.paymentType,
        };

        if (this.isEditMode) {
          event.startDate = this.$moment(this.appointmentStartDate).format('MM/DD/YYYY HH:mm')
          event.endDate = this.$moment(this.appointmentEndDate).format('MM/DD/YYYY HH:mm')
        } else {
          event.startDate = this.$moment(this.appointmentStartDate).format('MM/DD/YYYY HH:mm')
          event.endDate = this.$moment(this.appointmentEndDate).format('MM/DD/YYYY HH:mm')
        }
          
        if (this.paymentType && this.paymentType !== 'free') {
          event.paymentPrice = this.paymentPrice
        }

        if (!this.isEditMode) {
          const eventData = await this.addEvent(event);
          //check if exist comment adding it by API
          if (eventData?.data?.id && this.comment.trim()) {
            await this.addEventComment({
              eventId: eventData?.data?.id,
              comment: this.comment
            })
          }
        } else {
          const eventData = await this.editEvent(event);
          
          // In edit mode checking, if there is exist users comment doing edit, else adding new comment
          if (eventData?.data?.id && this.comment.trim() && this.commentId) {
            await this.editEventComment({
              eventId: eventData?.data?.id,
              commentId: this.commentId,
              comment: this.comment
            })
          } else {
            await this.addEventComment({
              eventId: eventData?.data?.id,
              comment: this.comment
            })
          }
        }
    
        this.finishEvent(ev);
      }
    },


    finishEvent(ev) {
      this.close();
      this.$emit("create-popover-closed", ev);
    },

    buildDetails() {
      let details = this.$dayspan.copyEventDetails(
        this.calendarEven?.event?.data
      );

      details.title = "";

      return details;
    },

    getEvent(type, extra = {}) {
      return fn.extend(
        {
          type: type,
          calendarEvent: this.calendarEvent,
          calendar: this.calendar,
          close: this.close,
          details: this.details,
          handled: false,
          added: false,
          refresh: true,
          close: true,
          $vm: this,
          $element: this.$el,
        },
        extra
      );
    },

    appointmentStartChanged() {
      this.appointmentStartDate = this.$moment(this.appointmentStartDate).format('MM/DD/YYYY HH:mm')
      this.appointmentEndDate = this.$moment(this.appointmentStartDate).add(1, 'hours').format('MM/DD/YYYY HH:mm')
    }
  },
};
</script>

<style scoped lang="scss">
@import "./src/assets/scss/formStyles";
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";
@import "./src/assets/scss/simpleTable";

.v-btn--floating.v-btn--left {
  margin-left: 0px !important;

  .v-icon {
    height: auto;
  }
}

.ds-calendar-event-popover-card {
  .v-dialog {
    overflow-y: unset !important;
    overflow-x: unset !important;
    overflow: unset !important;
  }
}

.theme--dark {
  .ds-calendar-event-popover-card {
    .body {
      background-color: $darkBgLighterDM;
    }
  }
}

.P-form-data {
  .v-messages {
    display: none !important;
  }
}

.v-text-field--full-width {
  width: 100%;
}

.v-card__text {
  padding: 16px 0;
  overflow-y: auto;
  max-height: 600px;

  .v-list {
    padding-bottom: 0px;

    .v-list__tile {
      height: auto !important;
    }
  }
}

.ds-create-popover-save {
  background-color: transparent !important;
}

.ds-color-option {
  width: 100%;
  color: white;
  padding: 4px;
}

.pencil-icon::v-deep {
  background-color: #424242;
  color: #fff;
  bottom: -115%;
}

.P-customers {
  position: relative;

  &__result {
    position: absolute;
    z-index: 2;
    width: 94.5%;
    padding: 0;
    box-shadow: 1px 1px 3px 1px rgb(165 170 198 / 29%);
    border-radius: 4px;
    background: white;
    overflow-y: auto;
    max-height: 114px;

    & .v-list {
      padding: 0;
      font-size: 14px;
    }
  }

  & .v-list-item {
    min-height: 38px;
  }
}

.vue__time-picker input.display-time {
  width: 100% !important;
}

.theme--light .header {
  @include main-gradient;
}
.theme--dark {
  .header {
    background: $secondaryMainDark !important;
  }
}
.header {
  height: 50px !important;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  p {
    font-size: $txt20;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
  span {
    font-size: 22px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.P-save-and-reset-block {
  margin-top: 6px !important;
}

.w-50 {
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.P-date-picker {
  .v-messages {
    display: none !important;
  }

  .v-input {
    margin-bottom: 5px !important;
  }
}

.P-address-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: all 0.4s;
}

.bordered-comment {
  border-bottom: 1px solid $neutral-color;
}

.text-14 {
  font-size: $txt14;
}

.h-100 {
  height: 100%;
}
</style>

<style lang="scss">
.v-toolbar__extension {
  display: none !important;
}

.vue__time-picker input.display-time,
.vue__time-picker {
  width: 100%;
}

.G-badge-calendar {
  width: max-content;
  padding: 0 10px;
  border-radius: 12px;
}

.lh-11 {
  line-height: 11px;
}

.ds-calendar-event-popover-card {
  .v-select__selection {
    max-width: 100% !important;
  }

  .P-customer-select .v-input__append-inner {
    display: none;
  }

  .v-input--dense >.v-input__control >.v-input__slot {
    margin-bottom: 0 !important;
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.v-overlay__scrim {
  background-color: unset !important;
  border-color: unset !important;
  opacity: 1 !important;
}

.P-event-comment-cont {
  & .comments-cont {
    max-height: 190px;
    overflow-y: auto;
  }
  & .v-list {
    padding-top: 0;
  }

  & .v-subheader {
    height: 33px;
  }
}

.P-event-comment-content {
  & p {
    margin-bottom: 0 !important;
  }
}
</style>