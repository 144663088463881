import RequestService from "@/services/request.service";

const InitialState = {
  showComponents: false,
  paymentType: {
    creditCard: 'CreditCard',
    bankAccount: 'BankAccount'
  },
  bankAccountType: {
    personal: 'personalAccount',
    business: 'businessAccount'
  },
  selectedPaymentType: 'CreditCard',
  editMode: false,
  addMode: false,

  creditCard: {
    cardNumber: '',
    cardholderName: '',
    brand: '',
    expireDate: null,
    cvc: null,
    month: '',
    year: '',
    address: '',
    token: '',
    country: '',
    city: '',
    suite: '',
    state: '',
    zip: '',
    existingAddress: '',
    default: true,
    inUse: true,
    anExistingAddress: false,
    isLoading: false,
    phone: ""
  },
  bankTransfer: {
    bankName:'',
    routingNumber:'',
    accountNumber:'',
    companyName:'',
    firstname:'',
    lastname:'',
    nickname:'',
    type: 'personalAccount',
    isLoading: false
  },
  cash: {
    month: ''
  },
  toSaveDataCreditCard: {},
  cloneCreditCard: null,
  cloneDataTransfer: null,
  activeIndex:null,

  paymentMethodId: null,
  selectedPaymentIndex: null,
  bankAccount: null,
  getPaymentList: false,
  paymentList: [],
  selectedCreditCard: null
};

export const paymentMethodClients = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    /**
     * Open add new payment method
     * **/

    addNewPaymentMethod(state) {
      state.addMode = true;
      state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
      state.bankTransfer = JSON.parse(JSON.stringify(InitialState.bankTransfer))
      state.selectedPaymentType = 'CreditCard'
      state.creditCard.anExistingAddress = false
    },

    closeAddEditPaymentData(state) {
      state.addMode = false;
      state.editMode = false;
      if (state.creditCard.id || state.bankTransfer.id) {
        state.paymentList = state.paymentList.map(item => {
          if (state.creditCard.id) {
            const cloneData = JSON.parse(JSON.stringify(state.cloneCreditCard))
            if (item.id === state.creditCard.id) {
              item = cloneData
            }
            return item
          }else{
            const cloneData = JSON.parse(JSON.stringify(state.cloneDataTransfer))
            if (item.id === state.bankTransfer.id) {
              item = cloneData
            }
          return item
          }
        })
      }
      state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
      state.bankTransfer = JSON.parse(JSON.stringify(InitialState.bankTransfer))
      state.creditCard.anExistingAddress = false
      state.cloneCreditCard = null;
      state.cloneDataTransfer = null;
      state.activeIndex = null
    },

    /**
     * Edit Payment Data
     * **/

    closeAddPayment(state){
      state.addMode = false;
      state.editMode = false;
      state.selectedPaymentType = 'CreditCard'
      state.creditCard.anExistingAddress = false
      state.cloneCreditCard = null;
      state.cloneDataTransfer = null;
      state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
      state.bankTransfer = JSON.parse(JSON.stringify(InitialState.bankTransfer))
      state.activeIndex = null
    },

    /**
     * Select  payment  type
     * **/
    selectPaymentType(state, data) {
      state.selectedPaymentType = data;
    },


    /***
    * for all second level object keys changing
    *  setDataSecondLevels({ 
    *    stateKey1: { changingKey: 'newVal' },
    *    stateKey2: { changingKey2: 'newVal' },  
    *  })
    **/
    setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if(state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },

    setCardNumber(state, value) {
      state.creditCard.cardNumber = value
    },
    setCardBrand(state, value) {
      state.creditCard.brand = value
    },
    setCardholderName(state, value) {
      state.creditCard.cardholderName = value
    },
    setExpireDate(state, value) {
      state.creditCard.expireDate = value
    },
    setCVC(state, value) {
      state.creditCard.cvc = value
    },
    setAddress(state, value) {
      state.creditCard.address = value
    },
    setCountry(state, value) {
      state.creditCard.country = value
    },
    setCity(state, value) {
      state.creditCard.city = value
    },
    setSuit(state, value) {
      state.creditCard.suite = value
    },
    setZip(state, value) {
      state.creditCard.zip = value
    },
    setExistingAddress(state, value) {
      state.creditCard.existingAddress = value
    },
    setMonth(state, value) {
      state.creditCard.month = value
    },
    setYear(state, value) {
      state.creditCard.year = value
    },
    setState(state, value) {
      state.creditCard.state = value
    },
    setIsDefault(state, value) {
      state.creditCard.default = value
    },

    setInUse(state, value) {
      state.creditCard.inUse = value
    },
    setPhone(state, value) {
      state.creditCard.phone = value
    },


    /**
     *  Set Bank Transfer Data
     * **/

    setBankName(state, value){
      state.bankTransfer.bankName = value
    },
    setRoutingNumber(state, value){
      state.bankTransfer.routingNumber = value
    },
    setAccountNumber(state, value){
      state.bankTransfer.accountNumber = value
    },
    setCompanyName(state, value){
      state.bankTransfer.companyName = value
    },
    setFirstName(state, value){
      state.bankTransfer.firstname = value
    },
    setLastName(state, value){
      state.bankTransfer.lastname = value
    },
    setNickName(state, value){
      state.bankTransfer.nickname = value
    },
    setType(state, value){
      state.bankTransfer.type = value
    },
    setCreditCardAnExisting(state, value) {
      state.creditCard.anExistingAddress = value
    },

    resetData(state) {
      state.editMode = false;
      state.addMode = false;
      state.creditCard.anExistingAddress = false;
      state.toSaveDataCreditCard = {};
      state.cloneDataTransfer = null;
      state.activeIndex = null;
      state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
      state.bankTransfer = JSON.parse(JSON.stringify(InitialState.bankTransfer))
      state.selectedPaymentType =  'CreditCard'
    },

    /**
     * Set payment method ID
     * **/
    setPaymentMethodId(state, value) {
      state.paymentMethodId = value
    },

    /**
     *  Set Add Mode
     *  Set Edit Mode
     * **/
    setAddMode(state, value) {
      state.addMode = value
    },
    setEditMode(state, value) {
      state.editMode = value
    },

    /**
     * Reset Payment Method
     * **/
    resetPaymentMethod(state) {
      state.editMode = false
      state.addMode = false
      state.bankTransfer = JSON.parse(JSON.stringify(InitialState.bankTransfer))
      state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
      state.creditCard.anExistingAddress = false;
    },

    /**
     * Payment List
     * **/
    setPaymentList(state, data) {
      state.paymentList = data
    },

    /**
     * Bank Account
     * **/
    setBankAccount(state, item) {
      state.bankAccount = item
    },

    // changeGetPaymentList(state, value) {
    //   state.getPaymentList = value
    // }
    setCreditCardIsLoading(state, value) {
      state.creditCard.isLoading = value
    },
    setBankTransferIsLoading(state, value) {
      state.bankTransfer.isLoading = value
    },

    resetState(state) {
      for (let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]));
      }
    },
  },
  actions: {
    addPaymentMethod({ commit, dispatch }, data) {
      return RequestService.create(`clients/paymentMethods`, {...data} )
      .then(async (response) => {
        commit('setPaymentMethodId', response.data.id)
        await dispatch('clientsModule/getClientList', {}, { root: true })
      })
      .catch(e => Promise.reject(e))
    },

    editPaymentMethod({ dispatch }, { paymentMethodId, data }) {
      return RequestService.update(`clients/paymentMethods/edit/${paymentMethodId}`, {...data})
      .then(async (r) => {
        await dispatch('clientsModule/getClientList', {}, { root: true })
      })
      .catch(e => Promise.reject(e))
    }, 

    // get all payment methods - payment methods []
    getPaymentMethod({commit}, {clientId}) {
      return RequestService.read(`clients/paymentMethods/${clientId}`)
        .then(response => {
          commit('setPaymentList', response.data)
          commit('setBankTransferIsLoading', false)
        })
        .catch(error => {
          console.log('error ', error)
        })
        .finally(() => { commit('setCreditCardIsLoading', false )})
    },
    // get selected bank account
    getBankAccount({commit}, {paymentMethodId}) {
      return RequestService.read(`clients/paymentMethods/edit/${paymentMethodId}`)
        .then(response => {
          commit('setBankAccount', response.data)
        })
        .catch(error => {
          console.log('error ', error)
        })
    },
    deleteBankAccount({dispatch}, {paymentMethodId}) {
      return RequestService.delete(`clients/paymentMethods/edit/${paymentMethodId}`)
        .then(async (r) => {
          await dispatch('clientsModule/getClientList', {}, { root: true })
        })
        .catch(e => console.log('error ', e))
    }
  }
};
