  <template>
  <div class="P-phone-number">
    <vue-tel-input-vuetify
      v-model="phoneNumber"
      :defaultCountry="countryCode"
      :label="label"
      :selectLabel="selectLabel"
      :placeholder="placeholder"
      :valid-characters-only="true"
      :error="error"
      :attach="true"
      :input-options="{ showDialCode: true, tabindex: 0 }"
      dense
      outlined
      mode="international"
      wrapperClasses="G-phone-cont"
      @input="changeNumber"
      @change="$emit('onChange')"
    >
    </vue-tel-input-vuetify>
    <!--  @keydown="onlyNumbers" -->

  </div>
</template>
<script>
import { mapState } from "vuex";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
export default {
  components: { VueTelInputVuetify },
  props: {
    value: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Phone Number*"
    },
    selectLabel: {
      type: String,
      default: "Code"
    },
    placeholder: {
      type: String,
      default: "Phone Number"
    }
  },
  data() {
    return {
      phoneNumber: ""
    };
  },
  watch: {
    value(data) {
      this.phoneNumber = data;
    }
  },

  computed: {
    ...mapState({
      countryCode: state => state.auth?.user?.geoInfo?.countryCode
    })
  },

  created() {
    this.defaultCountry = this.countryCode
  },
  
  mounted() {
    this.phoneNumber = this.value;
  },

  methods: {
    /**
     * Change phone number block function
     *
     * Output data list
     * @phone: string - current typing value
     * @event: string - current typing value
     * @isValid: Boolean - current typing phone number validation
     *
     * @number: object  with props
     * @number object
     * {
     * e164: string phone number
       input: "string phone number
       international: string phone number
       national: string phone number
       rfc3966: "string phone number
       significant: string phone number
        }
     * **/
    changeNumber() {
      const [value, obj] = arguments;
      this.$emit("input", value);
      this.$emit("onInput", value, obj);
    },
    onlyNumbers(event) {
      // let char = String.fromCharCode(event.keyCode);

      // if (!/^[0-9*#+-/=~․ժ։;`!@$%^&()_?><:"}{|\\]+$/.test(char)) {
      //   return event.preventDefault();
      // } else {
      //   return true;
      // }


      // if (/^[0-9\b _]+$/g.test(char)) return true
      // // if (/^[^a-z]*[0-9]+[^a-z]*$/.test(char)) return true
      // else event.preventDefault();
    },
    filter(event) {
      let phoneNumber = event.target.value.toString() + event.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(phoneNumber) && !/^\s*$/.test(phoneNumber)) {
        event.preventDefault();
      } else {
        return true
      }
      // if (/^[-+]?[0-9]*\.?[0-9]*$/.test(phoneNumber)) {
      //   return true
      // } else {
      //   event.preventDefault();
      // }

    }
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
.G-phone-cont::v-deep {

  .v-input__append-inner{
    margin-left: 25px !important;
  }
  .country-code {
    margin-right: 12px;
    width: 145px;
  }
  input {
    font-size: $txt14;
  }
  .v-input .v-label {
    font-size: $txt14 !important;
    font-weight: $regular !important;
    //color: $neutral-colorDM;
    color: $dark-gray;
  }

    .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
      min-height: 38px !important;
    }

    .v-text-field.v-text-field--enclosed .v-text-field__details,
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
      padding: 0 18px !important;
    }

    .v-text-field--outlined .v-input__slot fieldset {
      padding-left: 14px !important;
    }

    .v-input .v-label {
      font-size: $txt14 !important;
      font-weight: $regular !important;
      color: $neutral-color ;
      text-transform: capitalize;
    }

    .v-input--dense .v-label {
      top: 8px !important;
    }

    .v-text-field input {
      line-height: 24px !important;
      font-size: $txt14;
    }

    .v-input--has-state.error--text .v-label {
      -webkit-animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
      animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .v-text-field--outlined.v-input--has-state fieldset,
    .v-text-field--outlined.v-input--is-focused fieldset {
      border: 1.5px solid !important;
    }


  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
  > .v-input__control
  > .v-input__slot:hover
  fieldset {
    color: $neutral-color;
  }


  .theme--dark {
    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color: $primary-colorDM !important;
    }

    .v-label {
      color:$dark-gray !important;
      text-transform: capitalize !important;
    }

    .v-text-field__slot input {
      color: $white !important;
    }

    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color:$dark-gray !important;
    }
  }
  .theme--light {
    &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color: $neutral-color;
    }

    .v-label {
      color: $neutral-color;
      text-transform: capitalize !important;
    }

    .v-text-field__slot input {
      color: $secondary-color !important;
    }

    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color: $neutral-color;
    }
  }

}



</style>
