const providers = () => import("@/views/providers/Providers");

export const providersResellersRout = {
  path: "/providers-resellers",
  redirect: "/",
  name: "Providers and Resellers",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "",
      name: "List",
      component: providers,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    }
  ]
};
