export default class UserModel {
  constructor(data) {
    if (data) this.fetch(data);
  }

  fetch(data) {
    this.id = data.id;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.email = data.email;
    this.roles = data.roles;
    this.role = data.role;
    this.rolesInfo = data.rolesInfo;
    this.state = data.state;
    this.phone = data.phone;
    this.provider = data.provider;
    this.darkMode = data.darkMode;
    this.geoInfo = data.geoInfo;
    this.ip = data.ip;
    this.lang = data.lang;
    this.permissions = data.permissions;
    this.avatar = data.avatar;
  }
}
