const help = () => import("@/views/help/Help");

export const helpRout = {
    path: "/help",
    redirect: "/help",
    name: "Help",
    component: help,
    children: [
      {
        path: "",
        name: "",
        component: help,
      }
    ]
  };
  