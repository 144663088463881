import RequestService from "@/services/request.service";

const InitialState = {
  dataTable: [],

  dataCount: 0,
  currentPage: 1,
  totalPages: 1,
  rowsPerPageCount: 20,
  searchValue: "",

  isDefault: false,
  isLoading: false,
  isAddClientFromSearch: false,
};

export const clientsDeletedModule = {
  namespaced: true,
  state: { ...InitialState },
  mutations: {
    setIsLoading(state, flag) {
      state.isLoading = flag;
    },

    setRowsPerPageCount(state, count) {
      state.rowsPerPageCount = count;
    },

    setSearchValue(state, value) {
      if (state.searchValue !== value.trim()) {
        state.searchValue = value;
      }
    },

    setCurrentPage(state, page) {
      state.currentPage = page;
    },

    setClientsData(state, data) {
      const { list, count, page, limit } = data;

      state.dataTable = list;
      state.dataCount = count;
      state.currentPage = page;
      state.rowsPerPageCount = limit;
      state.totalPages = Math.ceil(count / limit);
    },

    resetState(state) {
      for (let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]));
      }
    },
  },
  actions: {
    getClientList({ commit, state }) {
      const { currentPage, rowsPerPageCount, searchValue } = state;

      let queryOptions = {
        page: currentPage,
        limit: rowsPerPageCount,
        search: searchValue.trim(),
      };

      const query = Object.keys(queryOptions)
        .filter(
          (key) => queryOptions[key] !== undefined && queryOptions[key] !== ""
        )
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      commit("setIsLoading", true);

      return RequestService.read(`clients/deleted?${query}`)
        .then((response) => {
          commit("setClientsData", response.data);
        })
        .catch((error) => {
          console.error("error ", error);
        })
        .finally(() => {
          commit("setIsLoading", false);
        });
    },

    search({ dispatch, state, commit }) {
      const searchValue = state.searchValue.trim();

      if (state.searchValue.length > 0 && searchValue === "") return;

      commit("setCurrentPage", 1);
      dispatch("getClientList");
    },
  },
};
