import RequestService from "@/services/request.service";
import moment from "moment";
const FileDownload = require('js-file-download');

import {
  loginStatusList, userStatusList
} from "./filterCustomist";
import UsersActivityFilterModel from "@/models/users-activity/UsersActivityFilterModel";

const InitialState = {
  filterModel: new UsersActivityFilterModel(),
    modal: {
      isOpen: false
    },

  loginStatusList: loginStatusList,
  userStatusList: userStatusList,
  // filter data
  filter: {
    limit: 20,
    sortBy: "_id:desc",
    page: 1
  },
  //  for  pagination  table
  dataCount: 0,
  currentPage: 0,
  totalPages: 0,
  filterData: {},


  // for  update  table  columns
  defaultSettings: [],
  updateColumnsFromBack: 'usersActivityModule/updateUserActivitySettings',
  updateColumnSettingsCommit: 'usersActivityModule/setDefaultSettings',
  isDefault: false,

  dataTable: [],
  usersActivityResult: false,
  isTableLoading: false,
  deleteUserIsLoading: false,
  showWarningModal: false,

};

export const usersActivityModule = {
  namespaced: true,
  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue
    },

    /**
     * Set client bills data
     */
    setUsersActivityData(state, data) {
      state.dataTable = data.results
      state.currentPage = data.page
      state.totalPages = data.totalPages
      state.dataCount = data.totalResults
    },
    resetState(state) {
      for (let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))
      }
    },

    /**
     * Set pagination filter
     * **/
    setFilterPage(state, page) {
      state.filter.page = page
      state.currentPage = page;
    },

    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filterData?.sortBy) { 
          state.filterData.sortBy = "_id:desc"; 
        }
      }
    },

    /**
     * Set Transaction Filter new Object
     * **/
    filterUsersActivity(state) {
      const filterObject = {}
      if (state.filterModel.search) {
        filterObject.search = state.filterModel.search
      }
      if (state.filterModel.loginDate?.start && state.filterModel.loginDate?.end) {
        filterObject.loginStartDate = 
          moment(state.filterModel.loginDate.start).format('MM/DD/YYYY');
        filterObject.loginEndDate = 
          moment(state.filterModel.loginDate.end).format('MM/DD/YYYY');
      }
      if (state.filterModel.loginStatus || state.filterModel.loginStatus === 0) {
        filterObject.loginStatus = state.filterModel.loginStatus
      }
      if (state.filterModel.userStatus || state.filterModel.userStatus === 0) {
        filterObject.userStatus = !!state.filterModel.userStatus
      }
      if (state.filterData?.sortBy) {
        filterObject.sortBy = state.filterData.sortBy
      }
      state.filterData = filterObject
    },
    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault
      }
    },

    setDefaultSettingsForBack(state, data) {
      if (data.userActivitySettings) {
        state.isDefault = data.userActivitySettings.isDefault
        state.defaultSettings = data.userActivitySettings.userActivityTable
      }
    },

  },
  actions: {
    getUsersActivityList({commit, state, rootState}, filter = null) {
      let options = {
        page: state.currentPage ? state.currentPage : 1
      }

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      if (filter) {
        options.limit = state.filter.limit
        options = {...options, ...filter}

      } else {
        options = {...options, ...state.filter}
      }
      
      let query = Object.keys(options).map(
          key => key + '=' + options[key]
      ).join('&')

      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = 'blob';
      } else {
        commit('setData', {isTableLoading: true})
      }

      return RequestService.read(`users/active?${query}`, readOptions)
        .then(response => {
          if (!isExcelRequest) {
            if (response.data.results && response.data.results.length) {
              commit("setData", { usersActivityResult: true });
            } else {
              commit("setData", { usersActivityResult: false });
            }
            commit('setUsersActivityData', response.data)
            commit('setData', {isTableLoading: false})
          } else {
            FileDownload(response.data, 'users-activity.xlsx'); // TODO actual file name from response.headers
          }
          return Promise.resolve(response.data)
        })
        .catch(e => console.log('error ', e))
        .finally(() => { commit('setData', {isTableLoading: false}) })
    },
    updateUserActivitySettings({state, commit}, data) {
      return RequestService.update(
        `users/settings`, {
          userActivitySettings: {
            userActivityTable: data,
            isDefault: state.isDefault,
            limit: state.filter.limit
          }
        }
      ).then(
        data => {
          console.log('data ', data)
        },
        error => {
          console.log('error ', error)
        }
      );
    },


    getUserActivitySettings({commit, state}) {
      return RequestService.read(
        `users/settings`
      ).then(
        data => {
          const limitData = data?.data?.userActivitySettings?.limit ? data.data.userActivitySettings.limit : state.filter.limit
          commit('setDefaultSettingsForBack', data.data)
          commit('setLimit', limitData)
          return Promise.resolve(data.data);
        },
        error => {
          console.log('error ', error)
        }
      );
    },
  }
}
