import RequestService from "@/services/request.service";

const InitialState = {
  isLoading: false,
  errorResend: false,
  email: ""
};

export const forgotPassword = {
  namespaced: true,
  actions: {
    passwordRecovery({ commit }, data) {
      return RequestService.create("auth/forgot-password", {
        email: data.email
      }).then(
        data => {
          commit("passwordRecoverySuccess", data);
          return Promise.resolve(data.data);
        },
        error => {
          commit("passwordRecoveryError", error);
          return Promise.reject(error);
        }
      );
    }
  },
  state: InitialState,
  mutations: {
    setLoad(state, isLoad) {
      state.isLoading = isLoad;
    },
    passwordRecoverySuccess(state) {
      state.errorResend = false;
      state.isLoading = false;
    },
    passwordRecoveryError(state, error) {
      if (error) {
        state.isLoading = false;
        state.errorResend = true;
      }
    },
    setEmail(state, email) {
      state.email = email;
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
