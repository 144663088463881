import RequestService from "@/services/request.service";
import moment from "moment";
import ClientActivityModel from "@/models/client-activity/ClientActivityModel";
import _ from "lodash";
const FileDownload = require("js-file-download");

const InitialState = {
    dataTable: [],
    // filter data
    filter: {
        limit: 20,
        searchLimit: 20,
        sortBy: "updatedAt:desc",
        page: 1,
    },
    filterData: {},
    filterModel: new ClientActivityModel(),
    //  for table pagination
    dataCount: 0,
    currentPage: 1,
    totalPages: 0,
    // for  update  table  columns
    defaultSettings: [],
    updateColumnsFromBack: "clientActivityModule/updateClientActivitySettings",
    updateColumnSettingsCommit: "clientActivityModule/setDefaultSettings",
    isDefault: false,
    clientActivityResult: false,
    isTableLoading: false,
    // filter list by type
    showColumnConfig: true,
};

/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  In the mutations part there is resetState(), 
  please new added variables add in that function as well
*/


export const clientActivityModule = {
    namespaced: true,
    state: InitialState,
    mutations: {
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key];
            }
        },

        resetFilterModel(state) {
            state.filter = {}
            state.filter.page = 1
            state.filter.limit = 10
            state.filter.searchLimit = 20
            state.filter.sortBy = "updatedAt:desc"
            state.filterData = {}
        },

        setClientActivityData(state, data) {
            state.dataTable = data.results;
            state.currentPage = data.page;
            state.totalPages = data.totalPages;
            state.dataCount = data.totalResults;
        },
        setFilterPage(state, page) {
            state.filter.page = page;
            state.currentPage = page;
        },
        filterClientActivity(state) {
            const filterObject = {}

            if (state.filterModel.search) {
                filterObject.search = state.filterModel.search
            }

            if (state.filterModel.date?.start && state.filterModel.date?.end) {
                filterObject.startDate = 
                    moment(state.filterModel.date.start).format('MM/DD/YYYY');
                filterObject.endDate = 
                    moment(state.filterModel.date.end).format('MM/DD/YYYY');
            }

            if (state.filterModel.allResellers) {
                filterObject.allResellers = state.filterModel.allResellers;
            }

            if (state.filterModel.resellers.length) {
                filterObject.resellers = state.filterModel.resellers;
            }

            if (state.filterData?.sortBy) {
                filterObject.sortBy = state.filterData.sortBy
            }

            state.filterData = filterObject
        },

        setLimit(state, newValue) {
            state.filter.limit = newValue
        },

        setSearchLimit(state, newValue) {
            state.filter.searchLimit = newValue
        },

        sortColumn(state, sort = null) {
            if (sort?.sortValue) {
                state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
            } else {
                if (!state.filterData?.sortBy) {
                    state.filterData.sortBy = "_id:desc";
                }
            }
        },

        setDefaultSettings(state, data) {
            if (data.defaultSettings) {
                state.defaultSettings = data.defaultSettings;
                state.isDefault = data.isDefault
            }
        },

        setDefaultSettingsForBack(state, data) {
            if (data.clientActivitySettings) {
                state.isDefault = data.clientActivitySettings.isDefault;
                state.defaultSettings = data.clientActivitySettings.clientActivityTable;
            }
        },

        resetState(state) {
            state.dataTable = []
            // filter data
            state.filter = {
                limit: 20,
                searchLimit: 20,
                sortBy: "updatedAt:desc",
                page: 1,
            },
            state.filterData = {}
            state.filterModel = new ClientActivityModel()
            //  for table pagination
            state.dataCount = 0
            state.currentPage = 1
            state.totalPages = 0
            // for  update  table  columns
            state.defaultSettings = []
            state.updateColumnsFromBack = "clientActivityModule/updateClientActivitySettings"
            state.updateColumnSettingsCommit = "clientActivityModule/setDefaultSettings"
            state.isDefault = false
            state.clientActivityResult = false
            state.isTableLoading = false
            // filter list by type
            state.showColumnConfig = true
        }
    },
    actions: {
        getClientActivityList({ commit, rootState, state }, filter = null) {
            const router = rootState.route;
            let options = {
                page: state.currentPage,
            }

            if (rootState.general.isSelectedAll) {
                options.all = true
            }
        
            if (filter) {
                if (rootState.route?.name !== 'Search') {
                    options.limit = state.filter.limit
                } else {
                    options.limit = state.filter.searchLimit
                }
                Object.assign(options, filter)
            } else {
                Object.assign(options, state.filter)
            }

            if (rootState.generalSearch.searchedClientId) {
                options.client = rootState.generalSearch.searchedClientId
            }

            let query = Object.keys(options)
                .map((key) => key + "=" + options[key])
                .join("&");

            const readOptions = {};
            const isExcelRequest = filter && filter.excel;
            if (isExcelRequest) {
                readOptions.responseType = "blob";
            } else {
                commit("setData", { isTableLoading: true });
            }

            return RequestService.read(`clients/activity?${query}`, readOptions)
                .then((response) => {
                    if (!isExcelRequest) {
                        if (response.data.results && response.data.results.length) {
                            commit("setData", (state.clientActivityResult = true));
                        } else {
                            commit("setData", (state.clientActivityResult = false));
                        }
                        commit("setClientActivityData", response.data);
                        commit("setData", { isTableLoading: false });
                    } else {
                        FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
                    }
                })
                .catch((e) => console.log("error ", e))
                .finally(() => {
                    commit("setData", { isTableLoading: false })
                });
        },

        getClientActivitySettings({ commit, state }) {
            return RequestService.read(`users/settings`).then(
              (res) => {
                const limit = res?.data?.clientActivitySettings?.limit ? res.data.clientActivitySettings.limit : state.filter.limit
                const searchLimit = res?.data?.clientActivitySettings?.searchLimit ? res.data.clientActivitySettings.searchLimit : state.filter.searchLimit

                commit("setDefaultSettingsForBack", res.data);
                commit('setLimit', limit)
                commit('setSearchLimit', searchLimit)
                return Promise.resolve(res.data);
              },
              (error) => {
                console.log("error ", error);
              }
            );
        },

        updateClientActivitySettings({ state }, data) {
            return RequestService.update(`users/settings`, {
                clientActivitySettings: {
                    clientActivityTable: data,
                    isDefault: state.isDefault,
                    limit: state.filter.limit,
                    searchLimit: state.filter.searchLimit
                },
            })
            .then((r) => {
                return Promise.resolve(r.data);
            })
            .catch((e) => console.log(e));
        },
    }   
}

