<template>
  <div class="Ott-checkbox-cont" :class="checkboxClass">
    <v-checkbox
      v-if="!tooltip"
      v-model="checkValue"
      :label="label"
      :value="dataVal"
      :error="error"
      :ripple="ripple"
      :indeterminate="indeterminate"
      :disabled="disabled"
      :class="{ 'P-test': dataVal || indeterminate, 'P-disable':disabled }"
      @change="updateData"
    />

    <v-tooltip v-if="tooltip" top>
      <template v-slot:activator="{ on }">
        <v-simple-checkbox
          v-model="checkValue"
          :label="label"
          :value="dataVal"
          :error="error"
          :ripple="ripple"
          :indeterminate="indeterminate"
          :disabled="disabled"
          :class="{ 'P-test': dataVal || indeterminate, 'P-disable':disabled }"
          @input="updateData"
          v-on="on"
        />
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      indeterminate: {
        type: Boolean,
        default: null
      },
      value: {
        type: [Boolean, Number]
      },
      dataVal: {
        type: Boolean
      },
      label: {
        type: String
      },
      ripple: {
        type: Boolean,
        default: false
      },
      checkboxClass: {
        type: String
      },
      error: {
        type: Boolean,
        default: false
      },
      tooltip: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        checkValue: false
      };
    },
    mounted() {
      if (this.dataVal){
        this.checkValue = this.dataVal;
      }else {
        this.checkValue = this.value;
      }

    },

    watch: {

      value: function (data) {
        this.checkValue = data;
      },
      dataVal: function (data) {
        this.checkValue = data;
      }
    },

    methods: {
      updateData(data) {
        this.$emit("input", data);
        this.$emit("changeCheckbox");
        this.$emit("updated")
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";

  .Ott-checkbox-cont::v-deep {
    .v-input--selection-controls {
      margin-top : 0;
    }

    .v-label {
      font-size      : $txt14;
      text-transform : capitalize !important;
    }

    .theme--light {
      &.v-label {
        color : $secondary-color;
      }

      &.v-icon {
        color : $neutral-color;
      }

      
    }

    .v-input--is-label-active {
      .v-input--selection-controls__input {
        &:before {
          opacity    : 1 !important;
          visibility : visible !important;
        }
      }
    }

    .theme--dark {
      &.v-input--is-disabled {
        .v-input--selection-controls__input {
          &:before {
            content          : "";
            position         : absolute;
            width            : 15px;
            height           : 15px;
            background-color : transparent;
            left             : 4px;
            top              : 4px;
            opacity          : 0;
            visibility       : hidden;
          }
        }
      }

      .v-input--selection-controls__input {
        &:before {
          content          : "";
          position         : absolute;
          width            : 12px;
          height           : 12px;
          background-color : #FFFFFF;
          left             : 6px;
          top              : 6px;
          opacity          : 0;
          visibility       : hidden;
        }
      }

      &.v-label {
        color : $white;
      }

      .v-icon {
        color : $white;
      }


      .P-test {

        .v-icon {
          color : $primary-color !important;
        }
      }

      .primary--text {
        color : $primary-colorDM !important;
      }
    }

    .v-input--indeterminate {
      .v-icon {
        color : $primary-color !important;
      }
    }

    .v-input--indeterminate {
      .v-input--selection-controls__input {
        &:before {
          opacity    : 1;
          visibility : visible;
        }
      }
    }

  }

  .P-test::v-deep {
    .v-icon {
      color : $primary-color !important;
    }
  }

  .P-disable::v-deep {
    .v-label {
      opacity : 0.5;
    }
  }

  .v-simple-checkbox {
    .theme--light {
      &.mdi-checkbox-marked{
        color : $primary-color;
      }
    }
  }
</style>
