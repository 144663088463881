<template>
  <div class="G-table-block">
    <div
      class="G-table-scroll-Y P-scroll-Y"
      ref="tableBody"
      @scroll="scrollY($event)"
    >
      <ul class="G-table-header">
        <li
          v-if="!hideLeftAction"
          class="P-action-left P-fixed-left"
          :class="[
            {
              'P-show-shadow': showShadowOnScroll,
            },
          ]"
        >
        <div class="P-column">
          <OttCheckbox
            v-if="isMultiSelect"
            v-model="selectedAllVisibles"
            @changeCheckbox="$emit('selectAllVisibles', selectedAllVisibles)"
          />
        </div>
        </li>
        <template v-for="(mainColumn, mainColumnIndex) in columnConfigData">
          <li v-if="mainColumn.isShow" class="G-flex G-flex-column" :key="mainColumnIndex">
            <div
              class="P-column P-main-title"
              :class="[
                {
                  'P-cursor-move': mainColumn.isDrag,
                  'P-show-shadow': showShadowOnScroll,
                },
              ]"
              :draggable="mainColumn.isDrag"
              @dragstart="handleDragStart($event, mainColumnIndex)"
              @dragover="handleDragOver($event, mainColumnIndex)"
              @dragleave="handleDragLeave($event)"
              @dragend="handleDragDrop($event)"
              @drop="handleDragLeave($event)"
              data-parent="true"
            >
              <span class="mdi mdi-drag P-drag-icon" />
              <p class="G-flex G-justify-center">
                {{ mainColumn.title }}
              </p>
            </div>
            <div class="P-column-sub-list G-flex">
              <template v-for="(column, indexColumn) in mainColumn.subList">
                <div
                  v-if="column.isShow"
                  :key="indexColumn"
                  :draggable="column.isDrag"
                  class="P-column G-flex G-align-center"
                  @dragstart="handleDragStartColumn($event, indexColumn, mainColumnIndex)"
                  @dragover="handleDragOverColumn($event, indexColumn, mainColumnIndex)"
                  @dragleave="handleDragLeaveColumn($event)"
                  @dragend="handleDragDropColumn($event, mainColumnIndex)"
                  @drop="handleDragLeaveColumn($event)"
                  @click="sortColumns(column)"
                  :style="{
                    minWidth: column.style.minWidth + 'px',
                    maxWidth: column.style.maxWidth + 'px',
                  }"
                  :class="[
                    {
                      'P-cursor-move': column.isDrag,
                    },
                  ]"
                >
                  <span class="mdi mdi-drag P-drag-icon" />
                  <p class="G-align-center">
                    {{ column.title }}
                    <span
                      v-if="column.sortData && column.sortData.isSort"
                      class="mdi P-sort-icon"
                      :class="{
                        'mdi-arrow-up':
                          !column.sortData.sortValue ||
                          column.sortData.sortValue === 'desc',
                        'mdi-arrow-down': column.sortData.sortValue === 'asc',
                        'P-active-sort': column.sortData.sortValue,
                      }"
                    ></span>
                  </p>
                </div>
              </template>
            </div>
          </li>
        </template>
        <li
          class="P-action-column"
          v-if="isActions"
          :class="{
            'P-fixed-right': fixedFirstAndLastColumn,
            'P-show-shadow': showShadowOnScroll,
          }"
        ></li>
      </ul>
      <div class="G-table-body">
        <ul
          v-for="(row, rowIndex) in data"
          :key="rowIndex"
          :class="{
            'P-table-odd': rowIndex % 2 === 0,
            'P-table-even': rowIndex % 2 !== 0,
          }"
        >
          <li
            v-if="!hideLeftAction"
            class="P-action-left P-fixed-left"
            :class="[
              {
                'P-show-shadow': showShadowOnScroll,
              },
            ]"
          >
            <div class="P-column">
              <OttCheckbox
                v-if="isMultiSelect"
                :data-val="getSelectedRow(row)"
                @changeCheckbox="checkRow(row, recursiveTreeIndex)"
              />
            </div>
          </li>

          <template v-for="(columnMain, columnMainIndex) in columnConfigData">
            <li
              v-if="columnMain.isShow"
              :key="columnMainIndex"
            >
                
            <template v-for="(column, columnIndex) in columnMain.subList">
              <div
                v-if="column.isShow"
                :key="columnIndex"
                :style="{
                  minWidth: column.style.minWidth + 'px',
                  maxWidth: column.style.maxWidth + 'px',
                }"
                class="P-parent-column"
              >
                <div class="P-column" v-html="typeof column?.cellView === 'function' ? column.cellView(row) : '-'"></div>
              </div>
            </template>
            </li>
          </template>
          <li
            v-if="isActions"
            :class="{
              'P-fixed-right': fixedFirstAndLastColumn,
              'P-show-shadow': showShadowOnScroll,
            }"
            class="P-action-column ott-menu"
          >
            <div class="P-column P-actions-part">
              <slot name="action" :rowData="row"></slot>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import OttGroupTableMixin from "./OttGroupTableMixin";
import OttCheckbox from "../vuetifyComponents/OttCheckbox";

export default {
  name: "OttGroupTable",
  components: { OttCheckbox },
  mixins: [OttGroupTableMixin],
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
@import "./style";

.P-table-odd {
  & .P-actions-part {
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    & .P-actions-part {
      opacity: 1;
    }
  }
}

.P-table-even {
  & .P-actions-part {
    opacity: 0;
    transition: all 0.3s;
  }

  &:hover {
    & .P-actions-part {
      opacity: 1;
    }
  }
}
</style>
