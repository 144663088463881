import RequestService from "@/services/request.service";

const InitialState = {
  selectedData: {
    selectProviderOrders: null,
    selectClientsOrder: null
  },

  providersList: [
    { name: "Easyship", value: 'easyship' },
    { name: "ShipRocket", value: 'shipRocket' },
    { name: "ShipStation", value: 'shipStation' },
    { name: "GoShippo", value: 'shippo' }
  ],

  easyShipData: {
    productionToken: "",
    isValid: null
  },
  shipRocketData: {
    email: "",
    password: ""
  },
  shipStationData: {
    apiKey: "",
    apiSecret: ""
  },
  goShippoData: {
    apiToken: ""
  },
  isEditEasyShip: false,
  isEditShipRocket: false,
  isEditShipStation: false,
  isEditGoShippo: false,
  cloneEasyShipData: JSON.parse(
    JSON.stringify({
      productionToken: "",
    })
  ),
  cloneShipRocketData: JSON.parse(
    JSON.stringify({
      email: "",
      password: ""
    })
  ),
  cloneShipStationData: JSON.parse(
    JSON.stringify({
      apiKey: "",
      apiSecret: ""
    })
  ),
  cloneGoShippoData: JSON.parse(
    JSON.stringify({
      apiToken: ""
    })
  ),
  toSaveData: {},
  isSaved: false
};

export const shipmentProviders = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  actions: {
    updateShippingProvider({ commit, state, rootState, dispatch }, providerId) {
      return RequestService.update(
        `ottProviders/shippingProviders/edit/${providerId}`,
        state.toSaveData
      ).then(
        async data => {
          commit("fetchData", data.data);
          // await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    getShippingProviders({state}, providerId){
      return RequestService.read(
        `ottProviders/shippingProviders/edit/${providerId}`
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },
    /**
     * to save data for add
     * **/

    toSaveData(state, providerId) {
        state.toSaveData.provider_default =
          state.selectedData.selectProviderOrders;
        state.toSaveData.client_default = state.selectedData.selectClientsOrder;
      if (state.easyShipData.productionToken) {
        state.toSaveData.easyship = state.easyShipData;
      }
      if (state.shipRocketData.password) {
        state.toSaveData.shiprocket = state.shipRocketData;
      }
      if (state.shipStationData.apiSecret) {
        state.toSaveData.shipstation = state.shipStationData;
      }
      if (state.goShippoData.apiToken) {
        state.toSaveData.goshippo = state.goShippoData;
      }
    },

    /**
     * fetch data from api
     * **/

    fetchData(state, data) {
      if (data.provider_default) {
        state.selectedData.selectProviderOrders = data.provider_default;
      }
      if (data.client_default) {
        state.selectedData.selectClientsOrder = data.client_default;
      }
      if (data.easyship) {
        state.easyShipData = data.easyship;
      }
      if (data.shiprocket) {
        state.shipRocketData = data.shiprocket;
      }
      if (data.shipstation) {
        state.shipStationData = data.shipstation;
      }

      if (data.goshippo) {
        state.goShippoData = data.goshippo;
      }
    },

    cancelEasyShipData(state) {
      if (state.cloneEasyShipData) {
        state.easyShipData = JSON.parse(
          JSON.stringify(state.cloneEasyShipData)
        );
      }
    },

    saveEasyShipData(state, data) {
      state.easyShipData = data;
      state.cloneEasyShipData = JSON.parse(JSON.stringify(data));
      state.isEditEasyShip = true;
    },

    cancelShipRocketData(state) {
      if (state.cloneShipRocketData) {
        state.shipRocketData = JSON.parse(
          JSON.stringify(state.cloneShipRocketData)
        );
      }
    },
    saveShipRocketData(state, data) {
      state.shipRocketData = data;
      state.cloneShipRocketData = JSON.parse(JSON.stringify(data));
      state.isEditShipRocket = true;
    },
    cancelShipStationData(state) {
      if (state.cloneShipStationData) {
        state.shipStationData = JSON.parse(
          JSON.stringify(state.cloneShipStationData)
        );
      }
    },

    saveShipStationData(state, data) {
      state.shipStationData = data;
      state.cloneShipStationData = JSON.parse(JSON.stringify(data));
      state.isEditShipStation = true;
    },

    saveGoShippoData(state, data) {
      state.goShippoData = data;
      state.cloneGoShippoData = JSON.parse(JSON.stringify(data));
      state.isEditGoShippo = true;
    },

    cancelGoShippoData(state) {
      if (state.cloneGoShippoData) {
        state.goShippoData = JSON.parse(
          JSON.stringify(state.cloneGoShippoData)
        );
      }
    },

    setIsEditEasyShip(state) {
      state.isEditEasyShip = true;
    },
    setIsEditShipRocket(state) {
      state.isEditShipRocket = true;
    },
    setIsEditShipStation(state) {
      state.isEditShipStation = true;
    },
    setIsEditGoShippo(state) {
      state.isEditGoShippo = true;
    },

    // set  easyShipData data
    setEasyShipDataProductionToken(state, value) {
      state.easyShipData.productionToken = value;
    },

    // set  shipRocketData data
    setShipRocketDataEmail(state, value) {
      state.shipRocketData.email = value;
    },
    setShipRocketDataPassword(state, value) {
      state.shipRocketData.password = value;
    },

    // set  shipStationData data
    setShipStationDataApiKey(state, value) {
      state.shipStationData.apiKey = value;
    },
    setShipStationDataApiSecret(state, value) {
      state.shipStationData.apiSecret = value;
    },

    // set  goShippoData data
    setGoShippoDataApiToken(state, value) {
      state.goShippoData.apiToken = value;
    },

    resetData(state) {
      state.selectedData.selectProviderOrders = null;
      state.selectedData.selectClientsOrder = null;

      state.easyShipData.productionToken = "";
      state.easyShipData.isValid = null;
      state.shipRocketData.email = "";
      state.shipRocketData.password = "";
      state.shipStationData.apiKey = "";
      state.shipStationData.apiSecret = "";
      state.cloneShipStationData = null;
      state.goShippoData.apiToken = "";
      state.cloneShipRocketData = null;
      state.cloneEasyShipData = null;
      state.cloneGoShippoData = null;
      state.isEditEasyShip = false;
      state.isEditShipRocket = false;
      state.isEditShipStation = false;
      state.isEditGoShippo = false;
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
