import Sign from "../../views/sign/Sign";

export const signRout = {
  path: "/sign",
  redirect: "/",
  name: "sign",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "",
      name: "",
      component: Sign,
      meta: {auth: false, roles: [], permissions: []}
    }

  ]
};
