<template>
  <div class="G-table-block" :class="{'G-flex': isSubTable, customClass }">
    <div
      :class="{ 'P-scroll-Y': !isHideHeader }"
      @scroll="scrollY($event)"
      class="G-table-scroll-Y"
      ref="tableBody"
    >
      <ul class="G-table-header" v-if="!isHideHeader">
        <li class="P-action-left" v-if="isMultiSelect || isSubTable" :class="{'P-disable-sub': !isSubTable
}"></li>
       <template v-for="(column, index) in columnConfigData">
        <li
          v-if="column.isShow"
          :draggable="column.isDrag"
          :class="[
            {
              'P-cursor-move': column.isDrag,
              'P-fixed-left': fixedFirstAndLastColumn && index===0,
              'P-show-shadow': showShadowOnScroll,
              'P-second-column': (fixedFirstAndLastColumn || isSubTable) && index===0,
              'P-second-column-disable': !isSubTable,
              'P-cursor-pointer': column.sortData && column.sortData.isSort
            },
            column.className
          ]"
          :style="{
            minWidth:
              column.style.minWidth +
              (index === 0
                ? (columnWithTreeIncrement - recursiveTreeIndex) *
                  columnWithTree
                : 0) +
              'px',
               maxWidth:isSubTable?
              (column.style.minWidth +
              (index === 0
                ? (columnWithTreeIncrement - recursiveTreeIndex) *
                  columnWithTree
                : 0) +
              'px') : (column.style.maxWidth? getHideColumnsCount()? '100%': column.style.maxWidth+'px'  : '100%')
          }"
          @dragstart="handleDragStart($event, index)"
          @dragover="handleDragOver($event, index)"
          @dragleave="handleDragLeave($event)"
          @dragend="handleDragDrop($event)"
          @drop="handleDragLeave($event)"
          @click="sortColumns(column)"
        >

          <span v-if="column.className" class="P-border-dashed-column"></span>
          <div class="P-column">
            <span class="mdi mdi-drag P-drag-icon" />
            <p class="G-align-center">
              {{ column.title }}
              <span
                v-if="column.sortData && column.sortData.isSort"
                class="mdi  P-sort-icon"
                :class="{
                  'mdi-arrow-up': !column.sortData.sortValue || column.sortData.sortValue==='desc',
                  'mdi-arrow-down': column.sortData.sortValue==='asc',
                  'P-active-sort':column.sortData.sortValue
                }"
              ></span>
            </p>
          </div>
        </li>
       </template>
        <li
          v-if="isActions"
          :class="{
            'P-fixed-right': fixedFirstAndLastColumn,
            'P-show-shadow': showShadowOnScroll || showShadowOnScrollSub
          }"
          class="P-action-column"
        ></li>
      </ul>

      <div class="G-table-body">
        <div
          v-for="(row, index) in data"
          :class="{
            'P-last-sub-table':
              index === data.length - 1 && recursiveTreeIndex !== 0,
            'P-active-sub':
              row.child && activeIndex === row.id && recursiveTreeIndex === 0,
            'P-main-column': recursiveTreeIndex === 0,
            'P-table-odd': index % 2 === 0 && recursiveTreeIndex === 0,
            'P-table-even': index % 2 !== 0 && recursiveTreeIndex === 0,
          }"
          class="P-sub-table"
        >
          <span class="P-sub-table-bg" />
          <ul
            :class="{
              'P-active-sub-table': row.child && activeIndex === row.id
            }"
          >
            <li
              v-if="isMultiSelect || isSubTable || isActions"
              :style="{
                minWidth: (isSubTable? 60 : 40) + columnWithTree * recursiveTreeIndex + 'px',
                maxWidth: isSubTable? (60 + columnWithTree * recursiveTreeIndex + 'px'): '100%'
              }"
              :class="{
                'P-disable-select': !isMultiSelect,
                'P-selected-column':
                  activeIndex === row.id || recursiveTreeIndex > 0,
                  'P-disable-sub': !isSubTable
              }"
              class="P-action-left"
            >
              <template v-if="recursiveTreeIndex > 0">
                <template v-for="(i, index) of recursiveTreeIndex">
                  <span
                    v-if="isSubTable"
                    :style="{
                      left:
                        (recursiveTreeIndex !== 0 ? columnWithTree * i : 0) + 'px'
                    }"
                    :class="{
                      'P-odd-style':
                        recursiveTreeIndex % 2 === 0 && recursiveTreeIndex !== 0,
                      'P-even-style':
                        recursiveTreeIndex % 2 !== 0 && recursiveTreeIndex !== 0,
                      'P-odd-sub':
                        i % 2 === 0 &&
                        recursiveTreeIndex !== 0 &&
                        i !== recursiveTreeIndex,
                      'P-even-sub':
                        i % 2 !== 0 &&
                        recursiveTreeIndex !== 0 &&
                        i !== recursiveTreeIndex,
                      'P-last-sub': i === recursiveTreeIndex
                    }"
                    class="P-sub-bg-color"
                  />
                </template>
              </template>
              <span v-if="isSubTable"
                :class="{
                  'P-odd-style': recursiveTreeIndex % 2 === 0,
                  'P-even-style': recursiveTreeIndex % 2 !== 0
                }"
                :style="{ left: columnWithTree * recursiveTreeIndex + 'px' }"
                class="P-sub-color"
              />
<!--              <span class="P-disable-lines"    :class="{-->
<!--                  'P-odd-style': recursiveTreeIndex % 2 === 0,-->
<!--                  'P-even-style': recursiveTreeIndex % 2 !== 0-->
<!--                }" :style="{left:(k !== 0 ? 30 * l - 16 : 14) + 'px'}"  v-for="(k, l) of recursiveTreeIndex" />-->
              <i
                v-for="(i, j) of recursiveTreeIndex"
                :style="{ left: (j !== 0 ? 30 * i - 16 : 14) + 'px' }"
                :class="{
                  'P-last-line': index === data.length - 1,
                  'P-disable-sub-line': recursiveTreeIndex !== i,
                  'P-remove-line': index === data.length - 2,
                  'P-line-main':
                    recursiveTreeIndex > 0 && recursiveTreeIndex !== i
                }"
                class="P-line"
              >
              </i>
              <div class="P-column">
                <span
                  v-if="row.child && isSubTable"
                  :class="{
                    'P-rotate-icon': activeIndex !== row.id
                  }"
                  class="mdi  P-arrow-sub mdi-menu-up"
                  @click="showSubTable($event, row)"
                ></span>
                <OttCheckbox
                  v-if="isMultiSelect"
                  :data-val="getSelectedRow(row)"
                  @changeCheckbox="checkRow(row, recursiveTreeIndex)"
                />
              </div>
            </li>
            <template v-for="(column, index) in columnConfigData">
            <li
              v-if="column.isShow"
              :style="{
                minWidth:
                  column.style.minWidth +
                  (index === 0
                    ? (columnWithTreeIncrement - recursiveTreeIndex) *
                      columnWithTree
                    : 0) +
                  'px',
                maxWidth:
                 isSubTable ?( column.style.minWidth +
                  (index === 0
                    ? (columnWithTreeIncrement - recursiveTreeIndex) *
                      columnWithTree
                    : 0) +
                  'px'):  (column.style.maxWidth? getHideColumnsCount()? '100%': column.style.maxWidth+'px'  : '100%'),
                left: (isSubTable? 60 : 40) + columnWithTree * recursiveTreeIndex + 'px'
              }"
              :class="[
                {
                  'P-fixed-left': fixedFirstAndLastColumn,
                  'P-show-shadow': showShadowOnScroll || showShadowOnScrollSub,
                  'P-second-column': (fixedFirstAndLastColumn || isSubTable) && index===0
                },
                column.className
              ]"
              @click="showSubTable($event, row)"
            >
              <span
                v-if="column.className"
                class="P-border-dashed-column"
              ></span>

              <span
                v-if="isSubTable"
                :class="{
                  'P-odd-style':
                    recursiveTreeIndex % 2 === 0 && recursiveTreeIndex !== 0,
                  'P-even-style':
                    recursiveTreeIndex % 2 !== 0 && recursiveTreeIndex !== 0
                }"
                class="P-sub-bg-color"
              />
              <span
                v-if="isSubTable"
                :class="{
                  'P-odd-style':
                    recursiveTreeIndex % 2 === 0 && recursiveTreeIndex !== 0,
                  'P-even-style':
                    recursiveTreeIndex % 2 !== 0 && recursiveTreeIndex !== 0
                }"
                class="P-sub-color"
              />
              <div 
                class="P-column" 
                v-html="typeof column?.cellView === 'function' ? column.cellView(row) : '-'" 
                @click="column.isColumnClicked ? clickFunction(row, column.title) : ''"
              ></div>
            </li>
            </template>
            <li
              v-if="isActions"
              :class="{
                'P-fixed-right': fixedFirstAndLastColumn,
                'P-show-shadow': showShadowOnScroll || showShadowOnScrollSub
              }"
              class="P-action-column ott-menu"
            >
              <span
                v-if="isSubTable"
                :class="{
                  'P-odd-style':
                    recursiveTreeIndex % 2 === 0 && recursiveTreeIndex !== 0,
                  'P-even-style':
                    recursiveTreeIndex % 2 !== 0 && recursiveTreeIndex !== 0
                }"
                class="P-sub-bg-color"
              />
              <span
                v-if="isSubTable"
                :class="{
                  'P-odd-style':
                    recursiveTreeIndex % 2 === 0 && recursiveTreeIndex !== 0,
                  'P-even-style':
                    recursiveTreeIndex % 2 !== 0 && recursiveTreeIndex !== 0
                }"
                class="P-sub-color"
              />

              <div class="P-column">
                <slot name="action" :rowData="row"></slot>
              </div>
            </li>
          </ul>
          <OttUniversalTable
            v-if="row.child && activeIndex === row.id"
            :column-config="columnConfig"
            :data="row.child"
            :is-hide-header="true"
            :is-sub-table="isSubTable"
            :is-multi-select="isMultiSelect"
            :selected-arrow-list="selectedArrowList"
            :main-table="false"
            :get-sub-list="getSubList"
            :recursive-tree-index="recursiveTreeIndex + 1"
            :main-data="mainData"
            :show-shadow-on-scroll-sub="
              showShadowOnScroll || showShadowOnScrollSub
            "
            :column-with-tree-increment="columnWithTreeIncrement"
            :fixed-first-and-last-column="fixedFirstAndLastColumn"
            @selectRow="selectRow"
            @updateColumnWith="updateColumnWidth"
          >
            <template slot-scope="props" slot="action">
              <slot name="action" :rowData="props"></slot>
            </template>
          </OttUniversalTable>
          <transition name="slide">
            <slot v-if="selectedRowId === row.id" name="rowBottomCont" />
          </transition>
        </div>
        <ul v-if="isTotalSum" class="P-total-sum-block">
          <li
            :style="{
              minWidth: (isSubTable? 60 : 40) + columnWithTree * recursiveTreeIndex + 'px',
              maxWidth: isSubTable?(60 + columnWithTree * recursiveTreeIndex + 'px'):'100%'
            }"
            class="P-action-left"
          ></li>
          <template v-for="(column, index) of columnConfig">
          <li
            v-if="column.isShow"
            :style="{
              minWidth:
                column.style.minWidth +
                (index === 0
                  ? (columnWithTreeIncrement - recursiveTreeIndex) *
                    columnWithTree
                  : 0) +
                'px',
                 maxWidth:
              isSubTable?(  column.style.minWidth +
                (index === 0
                  ? (columnWithTreeIncrement - recursiveTreeIndex) *
                    columnWithTree
                  : 0) +
                'px'):  (column.style.maxWidth? getHideColumnsCount()? '100%': column.style.maxWidth+'px'  : '100%'),

              left: (isSubTable? 60 : 40) + columnWithTree * recursiveTreeIndex + 'px'
            }"
            :class="[
              {
                'P-fixed-left': fixedFirstAndLastColumn,
                'P-show-shadow': showShadowOnScroll || showShadowOnScrollSub,
                'P-second-column': (isMultiSelect || isSubTable) && index===0
              },
              column.className
            ]"
          >
            <span v-if="column.className" class="P-border-dashed-column"></span>

            <div class="P-total-section G-flex-center flex-column" v-if="column.totalData">
              <p class="P-total-text primary--text">{{ column.totalText }}</p>
              <div v-html="column.cellTotalData(data)"></div>
            </div>
          </li>
          </template>
          <li
            v-if="isActions"
            class="P-action-column ott-menu"
            :class="{
              'P-fixed-right': fixedFirstAndLastColumn,
              'P-show-shadow': showShadowOnScroll || showShadowOnScrollSub
            }"
          ></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import OttUniversalTableMixin from "./OttUniversalTableMixin";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import { eventBus } from "@/main";

export default {
  components: { OttCheckbox },
  mixins: [OttUniversalTableMixin],
  name: "OttUniversalTable",
  mounted() {
    let resetTableData = false;
    this.columnConfigData = this.columnConfig;

    /*
      Checking if there are changing columns, 
      Removing columns or any thing changing in columnConfigData doing Reset Column Configs
    */
    this.columnConfigData.map(column => {
      if (typeof column?.cellView !== 'function') {
        resetTableData = true
      }
    })

    if (resetTableData) {
      eventBus.$emit('resetDataTableSettings')
    }
  },
  methods: {
    resetDataList() {
      this.selectedArrow = [];
      this.selectedArrow = [];
    },
    updateColumnWidth(data) {
      this.$emit("updateColumnWith", data);
    },
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.G-table-header-top {
  padding: 0 !important;
  margin: 0 !important;
  position: sticky;
  top: 0;
  z-index: 1;
}

.G-table-header {
  display: flex !important;
  align-items: center;
  padding: 0 !important;
  margin: 0 !important;
  transition: transform $transition;

  li {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0 20px;
    min-height: 40px;
    display: flex;
    align-items: center;
    background-color: $neutral-color7;
    justify-content: center;
    // position: relative;
    transition: $transition;
    user-select: none;

    &.P-cursor-move:hover {
      .P-drag-icon {
        opacity: 1;
        visibility: visible;
        cursor: grab;
      }
    }

    &.P-show-dragged-item {
      background-color: $neutral-color25;
    }

    p {
      color: $secondary-color;
      font-weight: bold;
      font-size: $txt12;
      margin: 0 !important;
      text-transform: capitalize;
    }

    &.P-cursor-pointer {
      cursor: pointer;
    }
  }
}

.P-drag-icon {
  color: $neutral-color;
  font-size: $txt20;
  transition: $transition;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.G-table-body {
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    padding: 0 !important;
    margin: 0 !important;
    position: relative;

    &:before {
      width: 2px;
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      transition: $transition;
      background-color: $primary-color;
      content: "";
    }

    &.P-selected-list {
      &:before {
        height: 100%;
      }
    }

    li {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 8px 20px;
      min-height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: $transition;
      background-color: $white;
      text-align: center;

      .mdi-check {
        font-size: $txt20;
        color: $green;
      }
    }

    &:hover {
      .P-action-column::v-deep {
        .v-btn {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.G-table-scroll-Y {
  display: grid;
  grid-auto-rows: min-content;

  &.P-scroll-Y {
    overflow: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #edeef4;
      box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
      background: #979797;
    }
  }

  &.G-hide-hover {
    &:before {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: transparent;
      z-index: 4;
      content: "";
      position: absolute;
    }
  }
}

.G-table-input {
  margin-bottom: 0;
}

.P-table-select {
  max-width: 50px;
  min-width: 50px;
  border-right: none !important;

  & + li {
    border-left: none !important;
  }
}

.P-action-column {
  width: 40px !important;
  min-width: 40px;
  padding: 0 !important;
}

.P-action-left {
  width: 60px !important;
  min-width: 60px;
  padding-left: 33px !important;
  padding-right: 0 !important;
  position: sticky !important;
  display: flex;
  justify-content: flex-end;
  left: 0;
  z-index: 20;
  border-left: 3px solid transparent;

  &.P-selected-column {
    border-left: 3px solid $primary-color;
  }

  .P-column {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &.P-disable-sub{
    padding-left: 15px !important;
    min-width: 40px !important;
  }

}

.P-action-left::v-deep {
  .v-icon.v-icon {
    font-size: 20px !important;
  }
}

.P-line {
  position: absolute;
  left: 24px;
  top: 0;
  border-left: 1px solid $neutral-color;
  height: 100%;
  z-index: 101;

  &:before {
    position: absolute;
    left: -1px;
    width: 10px;
    height: 10px;
    border: none;
    border-left: 1px solid $neutral-color;
    border-bottom: 1px solid $neutral-color;
    border-radius: 0 0 0 7px;
    top: 15px;
    content: "";
  }

  &.P-last-line {
    height: 20px;
  }

  &.P-line-main {
    height: 100%;
  }

  &.P-disable-sub-line {
    &:before {
      display: none;
    }
  }
}

.P-action-left::v-deep {
  .v-messages {
    display: none;
  }
}

.P-arrow-sub {
  position: absolute;
  right: 30px;
  top: -2px;
  font-size: 25px;
  color: $neutral-color;
  cursor: pointer;

  &.P-rotate-icon {
    transform: rotate(180deg);
  }
}

.P-fixed-right {
  position: sticky !important;
  right: 0;
  z-index: 20;

  &:before {
    position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 10px;
    transform: translateX(-100%);
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
    z-index: 9;
  }

  &.P-show-shadow {
    &:before {
      box-shadow: inset -10px 0 8px -8px #284e6626;
    }
  }
}

.P-fixed-left {
  &:first-child {
    position: sticky !important;
    left: 0;
    z-index: 9;

    &:before {
      position: absolute;
      top: 0;
      bottom: -1px;
      right: 0;
      width: 10px;
      transition: box-shadow 0.3s;
      content: "";
      pointer-events: none;
      z-index: 9;
    }

    &.P-show-shadow {
      &:before {
        box-shadow: inset 10px 0 8px -8px #284e6626;
      }
    }
  }
}

.P-second-column {
  &:nth-child(2) {
    position: sticky !important;
    left: 60px;
    z-index: 20;

    &:before {
      position: absolute;
      top: 0;
      bottom: -1px;
      right: -10px;
      width: 10px;
      transition: box-shadow 0.3s;
      content: "";
      pointer-events: none;
      z-index: 9;
    }

    &.P-show-shadow {
      &:before {
        box-shadow: inset 10px 0 8px -8px #284e6626;
      }
    }
   .P-border-dashed-column:before{
      display: none !important;
    }
  }
  &.P-second-column-disable{
    left: 40px;
  }

}

/*<!--&.P-main-column:nth-child(even) {-->*/
/*    <!--  > ul {-->*/
/*    <!--    li {-->*/
/*    <!--      background-color: $neutral-color7;-->*/
/*    <!--    }-->*/
/*    <!--  }-->*/
/*    <!--}-->*/

.P-sub-table {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: $transition;

  &:before {
    width: 100%;
    height: 7px;
    position: absolute;
    left: 0;
    top: -6px;
    transition: $transition;
    content: "";
    z-index: 30;
  }

  &:after {
    width: 100%;
    height: 7px;
    position: absolute;
    left: 0;
    bottom: -6px;
    transition: $transition;
    content: "";
    z-index: 30;
  }



  &.P-active-sub {
    &:before {
      box-shadow: inset 0 -2px 5px -1px #284e6626;
    }

    &:after {
      box-shadow: inset 0 2px 5px -1px #284e6626;
    }
  }
}

.P-table-even {
  &.P-sub-table {
    .P-sub-table-bg {
      position: absolute;
      left: 0;
      top: 0;
      background-color: $neutral-color7;
      width: 100%;
      height: 100%;
    }
  }
}

.G-table-block {
  position: relative;
  z-index: 1;
}

.P-sort-icon {
  color: $neutral-color;
  margin-left: 5px;
  &.P-active-sort{
   color: $secondary-color;
  }

}

.P-action-column::v-deep {
  .v-btn {
    opacity: 0;
    visibility: hidden;
    width: 40px;
    transition: $transition;
  }
}

.P-sub-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: transparent;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 80%;
    width: 100%;
  }
}

.P-table-odd {
  .P-sub-bg {
    &:before {
      background-color: red;
    }
  }

  .P-sub-bg {
    &:after {
      background-color: green;
    }
  }

  .P-action-left {
    .P-sub-bg {
      &:after {
        background-color: green;
        left: auto;
        border-radius: 40px 0 0 40px;
      }
    }
  }
}

.P-column {
  position: relative;
  z-index: 5;
  ::v-deep {
    p {
      margin-bottom: 0;
    }
    .P-channels,
    .P-active-packages {
      cursor: pointer;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

.P-table-odd {
  > ul {
    li {
      background-color: $white;
    }

    .P-sub-bg-color,
    .P-sub-color {
      background-color: $white;
    }
  }

  .P-sub-bg-color {
    background-color: $white;
  }

  .P-sub-color {
    background-color: $neutral-color7;
  }

  .P-column {
    position: relative;
  }

  .P-sub-color.P-even-style {
    background-color: $neutral-color7;
  }

  .P-sub-bg-color.P-even-style.P-even-sub {
    background-color: $neutral-color7 !important;
  }

  .P-sub-bg-color.P-even-style.P-odd-sub {
    background-color: $white !important;
  }

  .P-sub-color.P-odd-style {
    background-color: $white;
  }

  .P-sub-bg-color.P-odd-style {
    background-color: $neutral-color7;
  }

  .P-action-left {
    background-color: $white;

    .P-sub-color {
      border-radius: 9px 0 0 9px;
    }
  }

  .P-sub-table {
    &:last-child {
      .P-action-left {
        .P-sub-bg-color.P-odd-style {
          border-radius: 0 0 0 9px;
        }

        .P-sub-bg-color.P-even-style.P-last-sub {
          border-radius: 0 0 0 9px;
        }
      }
    }
  }
}

.P-table-even {



  > ul {
    li {
      background-color: $neutral-color7;
    }

    .P-sub-bg-color,
    .P-sub-color {
      background-color: $neutral-color7;
    }
  }

  .P-sub-bg-color {
    background-color: $neutral-color7;
  }

  .P-sub-color.P-even-style {
    background-color: $white;
  }

  .P-sub-bg-color.P-even-style.P-even-sub {
    background-color: $white !important;
  }

  .P-sub-bg-color.P-even-style.P-odd-sub {
    background-color: $neutral-color7 !important;
  }

  .P-sub-color.P-odd-style {
    background-color: $neutral-color7;
  }

  .P-sub-bg-color.P-odd-style {
    background-color: $white;
  }

  .P-action-left {
    background-color: $neutral-color7;

    .P-sub-color {
      border-radius: 9px 0 0 9px;
    }
  }

  .P-sub-table {
    &:last-child {
      .P-action-left {
        .P-sub-bg-color.P-odd-style {
          border-radius: 0 0 0 9px;
        }

        .P-sub-bg-color.P-even-style.P-last-sub {
          border-radius: 0 0 0 9px;
        }
      }
    }
  }
}

.P-table-odd {
  .P-active-sub-table {
    .P-action-column {
      background-color: transparent;

      .P-sub-bg-color.P-even-style {
        background-color: $neutral-color7;
        top: 3px;
      }
    }

    .P-action-column {
      .P-sub-bg-color.P-odd-style {
        background-color: $white;
        top: 3px;
      }
    }

    .P-action-left {
      .P-sub-bg-color.P-even-style.P-last-sub {
        border-radius: 9px 0 0 0;
        background-color: $neutral-color7;
        top: 3px;
      }

      .P-sub-bg-color.P-odd-style.P-last-sub {
        border-radius: 9px 0 0 0;
        background-color: $white;
        top: 3px;
      }
    }
  }
  .P-sub-table {
    cursor: pointer;
    .P-sub-color {
      transition: $transition;
    }
    &:hover {
      > ul {
        li {
          .P-sub-color.P-even-style {
            background-color: $neutral-color25;
          }
          .P-sub-color.P-odd-style {
            background-color: $neutral-color25;
          }
        }
      }
      .P-active-sub-table {
        .P-sub-color.P-even-style {
          background-color: $neutral-color7;
        }
        .P-sub-color.P-odd-style {
          background-color: $white;
        }
      }
    }
  }
}

.P-table-even {
  .P-active-sub-table {
    .P-action-column {
      background-color: transparent;
      .P-sub-bg-color.P-even-style {
        background-color: $white;
        top: 3px;
      }
    }

    .P-action-column {
      .P-sub-bg-color.P-odd-style {
        background-color: $neutral-color7;
        top: 3px;
      }
    }

    .P-action-left {
      .P-sub-bg-color.P-even-style.P-last-sub {
        border-radius: 9px 0 0 0;
        background-color: $white;
        top: 3px;
      }

      .P-sub-bg-color.P-odd-style.P-last-sub {
        border-radius: 9px 0 0 0;
        background-color: $neutral-color7;
        top: 3px;
      }
    }
  }
  .P-sub-table {
    cursor: pointer;
    .P-sub-color {
      transition: $transition;
    }
    &:hover {
      > ul {
        li {
          .P-sub-color.P-even-style {
            background-color: $neutral-color25;
          }
          .P-sub-color.P-odd-style {
            background-color: $neutral-color25;
          }
        }
      }
      .P-active-sub-table {
        .P-sub-color.P-even-style {
          background-color: $white;
        }
        .P-sub-color.P-odd-style {
          background-color: $neutral-color7;
        }
      }
    }
  }
}

.P-sub-bg-color {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.P-sub-color {
  position: absolute;
  width: 100%;
  height: 80%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.P-last-sub-table {
  ul {
    li {
      background-color: transparent;
      padding-bottom: 25px;

      .P-sub-color {
        height: 65%;
        top: 30px;
      }
    }
  }
}

.G-text-table {
  .mdi-check {
    font-size: $txt22 !important;
  }
}

.P-total-text {
  font-size: $txt12;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}
.P-total-section {
  width: 100%;
  > p {
    font-size: $txt12;
  }
}
.P-total-sum-block {
  li {
    border-top: 1px solid $neutral-color75;
    border-bottom: 1px solid $neutral-color75;
    padding: 10px !important;
  }
}
.P-border-dashed {
  .P-border-dashed-column {
    &:before {
      position: absolute;
      left: 0;
      width: 1px;
      height: 100%;
      border-left: 1px dashed $neutral-color45;
      content: "";
      top: 0;
      z-index: 15;
    }
    &:after {
      position: absolute;
      right: 0;
      width: 1px;
      height: 100%;
      border-left: 1px dashed $neutral-color45;
      content: "";
      top: 0;
      z-index: 15;
    }
  }

  & + .P-border-dashed {
    .P-border-dashed-column {
      &:before {
        opacity: 0;
      }
    }
  }
}
.P-arrow-sub {
  &:after {
    position: absolute;
    left: 12px;
    top: 23px;
    border-left: 1px solid #a5aac6;
    height: 120%;
    content: "";
    opacity: 0;
    visibility: hidden;
  }
}
.P-active-sub-table {
  .P-arrow-sub {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.P-main-column {
  .P-action-left {
    &:before {
      width: 2px;
      background-color: $primary-color;
      height: 0;
      transition: $transition;
      position: absolute;
      left: -3px;
      top: 0;
      content: "";
      z-index: 10;
    }
  }
  > ul {
    &:hover {
      .P-action-left {
        &:before {
          height: 100%;
        }
      }

    }
  }
  &.P-active-sub {
    .P-action-left {
      &:before {
        height: 0;
      }
    }
  }
}

.theme--dark {
  .P-total-sum-block {
    li {
      background-color: $secondaryMainDark;
    }
  }
  .G-table-header {
    li {
      background-color: $secondary-dark-66-dark;
      p {
        color: $white !important;

      }
    }
  }
  .P-table-odd {
    > ul {
      li {
        background-color: $secondaryMainDark;
      }

      .P-sub-bg-color,
      .P-sub-color {
        background-color: $secondaryMainDark;
      }
    }

    .P-sub-bg-color {
      background-color: $secondaryMainDark;
    }

    .P-sub-color {
      background-color: $secondary-dark-66-dark;
    }

    .P-column {
      position: relative;
    }

    .P-sub-color.P-even-style {
      background-color: $secondary-dark-66-dark;
    }

    .P-sub-bg-color.P-even-style.P-even-sub {
      background-color: $secondary-dark-66-dark !important;
    }

    .P-sub-bg-color.P-even-style.P-odd-sub {
      background-color: $secondaryMainDark !important;
    }

    .P-sub-color.P-odd-style {
      background-color: $secondaryMainDark;
    }

    .P-sub-bg-color.P-odd-style {
      background-color: $secondary-dark-66-dark;
    }

    .P-action-left {
      background-color: $secondaryMainDark;
    }
  }

  .P-table-even {
    > ul {
      li {
        background-color: $secondary-dark-66-dark;
      }

      .P-sub-bg-color,
      .P-sub-color {
        background-color: $secondary-dark-66-dark;
      }
    }

    .P-sub-bg-color {
      background-color: $secondary-dark-66-dark;
    }

    .P-sub-color.P-even-style {
      background-color: $secondaryMainDark;
    }

    .P-sub-bg-color.P-even-style.P-even-sub {
      background-color: $secondaryMainDark !important;
    }

    .P-sub-bg-color.P-even-style.P-odd-sub {
      background-color: $secondary-dark-66-dark !important;
    }

    .P-sub-color.P-odd-style {
      background-color: $secondary-dark-66-dark;
    }

    .P-sub-bg-color.P-odd-style {
      background-color: $secondaryMainDark;
    }

    .P-action-left {
      background-color: $secondary-dark-66-dark !important;
    }
  }

  .P-table-odd {
    .P-active-sub-table {
      .P-action-column {
        background-color: transparent;

        .P-sub-bg-color.P-even-style {
          background-color: $secondary-dark-66-dark;
        }
      }

      .P-action-column {
        .P-sub-bg-color.P-odd-style {
          background-color: $secondaryMainDark;
        }
      }

      .P-action-left {
        .P-sub-bg-color.P-even-style.P-last-sub {
          background-color: $secondary-dark-66-dark;
        }

        .P-sub-bg-color.P-odd-style.P-last-sub {
          background-color: $secondaryMainDark;
        }
      }
    }
    .P-sub-table {
      cursor: pointer;
      .P-sub-color {
        transition: $transition;
      }
      &:hover {
        > ul {
          li {
            .P-sub-color.P-even-style {
              background-color: $darkBg-color12MD;
            }
            .P-sub-color.P-odd-style {
              background-color: $darkBg-color12MD;
            }
          }
        }
        .P-active-sub-table {
          .P-sub-color.P-even-style {
            background-color: $secondary-dark-66-dark;
          }
          .P-sub-color.P-odd-style {
            background-color: $secondaryMainDark;
          }
        }
      }
    }
  }

  .P-table-even {
    .P-active-sub-table {
      .P-action-column {
        background-color: transparent;
        .P-sub-bg-color.P-even-style {
          background-color: $secondaryMainDark;
          top: 3px;
        }
      }

      .P-action-column {
        .P-sub-bg-color.P-odd-style {
          background-color: $secondary-dark-66-dark;
          top: 3px;
        }
      }

      .P-action-left {
        .P-sub-bg-color.P-even-style.P-last-sub {
          background-color: $secondaryMainDark !important;
        }

        .P-sub-bg-color.P-odd-style.P-last-sub {
          background-color: $secondary-dark-66-dark;
        }
      }
    }
    .P-sub-table {
      cursor: pointer;
      .P-sub-color {
        transition: $transition;
      }
      &:hover {
        > ul {
          li {
            .P-sub-color.P-even-style {
              background-color: $darkBg-color12MD;
            }
            .P-sub-color.P-odd-style {
              background-color: $darkBg-color12MD;
            }
          }
        }
        .P-active-sub-table {
          .P-sub-color.P-even-style {
            background-color: $secondaryMainDark;
          }
          .P-sub-color.P-odd-style {
            background-color: $secondary-dark-66-dark;
          }
        }
      }
    }
  }
}

  /*.P-disable-lines{*/
  /*  position: absolute;*/
  /*  left: 13px;*/
  /*  width: 8px;*/
  /*  height: 100%;*/
  /*  background-color: white;*/
  /*  top: 0;*/
  /*  z-index: 102;*/
  /*  display: none;*/
  /*}*/

  /*.P-last-sub-table{*/
  /*  .P-disable-lines{*/
  /*    display: block;*/
  /*  }*/
  /*}*/

  .P-last-sub-table{
    .G-table-block{

    }
  }
</style>
