const InitialState = {
  discountTypeSelect: {
    client: 2,
    provider: 3,
  },
  discountRoundingList: [
    {name: 'Off', value: 'off'},
    {name: 'Down', value: 'down'},
    {name: 'Up', value: 'up'},
  ],

  formData: {
    type: 2,
    name: '',
    defaultSalePercent: '',
    rounding: null,
    digits: null,
    status: false,
    description: '',
    sendNotifications: false
  },

  discountDate: { 
    start: null, 
    end: null 
  }
};

export const generalInfoDiscount = {
  namespaced: true,
  actions: {},

  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    /**
     * Reset Data
     * **/

    resetData(state) {
      // state.formData = JSON.parse(JSON.stringify(InitialState.formData)).formData
      state.formData.type = 2
      state.formData.name = ''
      state.formData.defaultSalePercent = ''
      state.formData.rounding = null
      state.formData.digits = null
      state.formData.status = false
      state.formData.description = ''
      state.formData.sendNotifications = false
      state.discountDate = { start: null, end: null }
    },

    setDataGeneralInfo(state, data = null) {
      delete data?.createdAt
      state.formData = data
      state.discountDate = {
        start: data.startDate,
        end: data.endDate
      }
      state.formData.status = data.status === 2
    },

    /**
     * Set  General Info Data
     * **/

    setDiscountType(state, value) {
      state.formData.type = value
    },
    setDiscountName(state, value) {
      state.formData.name = value;
    },
    setDefaultSalePercent(state, value) {
      state.formData.defaultSalePercent = value;
    },
    setRounding(state, value) {
      state.formData.rounding = value;
    },
    setDigits(state, value) {
      state.formData.digits = value;
    },
    setIsDisable(state, value) {
      state.formData.status = value;
    },
    setDescription(state, value) {
      state.formData.description = value;
    },
    setIsSendNotification(state, value) {
      state.formData.sendNotifications = value;
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
