<template>
  <div v-click-outside="clickInputOutside">
    <div
      :class="[{ openCont: showRangeCont }, { floatText: floating }]"
      @click="togglePriceSelect"
      class="range-select custom-outline-cont"
    >
      <small
        v-if="rangeData[0] || rangeData[1]"
        :class="showRangeCont ? 'primary--text' : 'neutral--text'"
      >{{ label }}</small
      >
      <div class="value-cont">
        <div v-if="rangeData[0] || rangeData[1]">
          <span class="secondary--text"> {{ rangeData[0] }}</span>
          <span class="secondary--text"> - {{ rangeData[1] }}</span>
        </div>
        <div v-else>
          <span class="P-range-label neutral--text">{{ label }}</span>
        </div>
        <div @click="resetData" v-if="rangeData[0] || rangeData[1]"
                class="icon icon-cancel neutral-color-75--text P-clear-icon"
        ></div>
        <div
          class="mdi mdi-menu-down primary--text"
          :class="[
            { 'rotate-icon': showRangeCont },
            { 'neutral--text': !showRangeCont }
          ]"
        ></div>
      </div>
    </div>
    <div class="range-cont-main">
      <div class="range-cont" v-if="showRangeCont">
        <!-- <vue-slider
          v-bind="options"
          :value="value"
          :disabled="options.max < 0"
          ref="slider"
          @change="changeRange"
        ></vue-slider> -->
        <div class="G-align-center mt-3 P-input-prices">
          <ott-input
            v-model="value[0]"
            @input="changeMinValue"
            @emitKeypress="minKeyPress"
            @onKeydown="minKeyDown"
            :min="min"
            :max="max"
            :error="minValError"
            :prefix="prefix"
            label="Min"
            type="number"
            customInputClass="P-pr-select-input"
          ></ott-input>
          <span class="P-to neutral--text">To</span>
          <ott-input
            v-model="value[1]"
            @input="changeMaxValue"
            @emitKeypress="maxKeyPress"
            @onKeydown="maxKeyDown"
            :min="min"
            :max="max"
            :error="maxValError"
            :prefix="prefix"
            label="Max"
            type="number"
            customInputClass="P-pr-select-input"
          ></ott-input>
          
          <img 
            :src="require('@/assets/images/right-arrow.png')" 
            class="save-icon cursor-pointer ms-2"
            :class="{
              'disabled-save': minValError || maxValError || (value[0] == 0 && value[1] == 0)
            }"
            @click="useRangeNumbers"
          />
        </div>      
      </div>
    </div>
  </div>
</template>

<script>
  import ClickOutside from "vue-click-outside";

  import vueSlider from "vue-slider-component";
  import "vue-slider-component/theme/default.css";
  import OttInput from "@/components/vuetifyComponents/OttInput";

  export default {
    name: "OttRangeSelect",
    components: {
      OttInput,
      vueSlider,
    },
    // ...
    props: {
      value: {
        type: Array,
        required: true
      },
      min: {
        type: Number,
        required: true,
      },
      max: {
        type: Number,
        required: true,
      },
      label: {
        type: String,
        required: true,
        default: ""
      },
      prefix: {
        type: String,
        default: ''
      }
    },

    data() {
      let newArray = [];
      if (newArray.length < 2) {
        if (newArray.length === 1) {
          newArray.push(newArray[0]);
        } else {
          newArray.push(this.min, this.max);
        }
      }

      return {
        showRangeCont: false,
        floating: true,
        enableCross: true,
        rangeData: [],
        options: {
          min: 0,
          max: 0
        }
      };
    },

    watch: {
      value (val) {
        if (val[0] === '' || val[1] === '') {
          this.resetData()
        }
        this.rangeData = val;
      },
  
    },
    
    directives: {
      ClickOutside
    },

    computed: {
      maxValError() {
        if (this.value[0] && +this.value[1] < +this.value[0]) {
          return true
        }
        return false 
      },

      minValError() {
        if (this.value[0] && +this.value[0] > +this.value[1]) {
          return true
        }
        return false 
      }
    },

    mounted() {
      this.rangeData = this.$props.value;
      this.options.min = this.min;
      this.options.max = this.max;
    },

    methods: {     
      changeRange(event) {
        this.$emit("input", event);
      },

      minKeyPress(event) {
        if (this.value[0].toString().startsWith('0') && event.key == '0') {
          event.preventDefault()
        }
      },

      maxKeyPress(event) {
        if (this.value[1].toString().startsWith('0') && event.key == '0') {
          event.preventDefault()
        }
      },

      changeMinValue(event) {
        if (event === '') {
          this.resetData()
        }

        if (event.startsWith('0')) {
          this.value[0] = this.value[0].toString().slice(1);
        }
      },
      changeMaxValue(event) {
        if (event === '') {
          this.resetData()
        }

        if (event.startsWith('0')) {
          this.value[1] = this.value[1].toString().slice(1);
        }
      },

      minKeyDown(event) {
        if (event.key === 'Backspace') {
         if (this.value[0].toString().length === 1) {
          setTimeout(() => {
            this.$emit("input", [0, this.value[1]]);
          },0)
         }
        }

        if (event.key === 'Delete') {
          if (this.value[0]) {
            setTimeout(() => {
              this.$emit("input", [0, this.value[1]]);
            },0)
          }
        }
      },

      maxKeyDown(event) {
        if (event.key === 'Backspace') {
          if (this.value[1].toString().length === 1) {
            setTimeout(() => {
              this.$emit("input", [this.value[0], 0]);
            },0)
          }
        }

        if (event.key === 'Delete') {
          if (this.value[1]) {
            setTimeout(() => {
              this.$emit("input", [this.value[0], 0]);
            },0)
          }
        }
      },

      triggerChange(list) {
        this.$emit("input", list);
      },
      floatText() {
        this.showRangeCont = true;
      },
      togglePriceSelect(e) {
        this.showRangeCont = !this.showRangeCont;
        e.stopPropagation()
      },
      resetData(){
        this.$emit('clearData')
      },
      clickInputOutside() {
        if (this.showRangeCont && !this.minValError && !this.maxValError && (this.value[0] || this.value[1])) {
          this.$emit("updateRange");
        }
        
        this.showRangeCont = false;

      },
      setValue(val) {
        console.log(val);
      },
      useRangeNumbers() {
        if (!this.minValError && !this.maxValError && (this.value[0] || this.value[1])) {
          this.$emit('updateRange', this.value)
        }
      }
    },
  };
</script>

<style scoped lang="scss">
  @import "./src/assets/scss/variables";
  @import "./src/assets/scss/mixins";

  .range-select {
    width                              : 100%;
    height                             : 38px;

    padding                            : 0 18px;
    transition-duration                : 0.3s;
    border-radius                      : $border-radius;
    -webkit-transition-timing-function : cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-timing-function         : cubic-bezier(0.25, 0.8, 0.25, 1);
    position: relative;
    margin-bottom: 5px;

    small {
      font-size      : 14px;
      color          : $neutral-color;
      text-transform : capitalize !important;
    }

    .value-cont {
      display         : flex;
      justify-content : space-between;
      align-items     : center;

      span {
        font-size : $txt14;
      }
    }

    .mdi {
      font-size : 24px;
      cursor    : pointer;
    }

    .rotate-icon {
      transform : rotate(-180deg);
    }
  }

  .range-cont-main {
    padding    : 2px;
    margin-top : -5px;

    .range-cont {
      @include box-shadow-sm;
      padding       : 20px 18px 0 18px;
      width         : 100%;
      height        : 110px;
      border        : 1px solid transparent;
      border-radius : $border-radius;
    }
  }

  .range-cont::v-deep {
    .vue-slider-process {
      background-color : $primary-color !important;
    }

    .vue-slider-dot {
      border           : 1.5px solid $neutral-color;
      width            : 18px !important;
      height           : 18px !important;
      border-radius    : 50% !important;
      background-color : $white !important;

      &:hover {
        cursor : pointer;
      }
    }

    .vue-slider-rail {
      background-color : $neutral-color45;
      height           : 2px !important;
      cursor           : pointer;
    }

    .vue-slider-dot-tooltip-inner {
      background-color : $primary-color;
      border-color     : $primary-color;
    }

    .vue-slider-dot-handle {
      box-shadow : none;
    }
  }

  .price-select {
    position : relative;

    .fe {
      position  : absolute;
      font-size : 20px;
      top       : 16px;
      right     : 10px;
      z-index   : 1;
      cursor    : pointer;
    }

    .fe-chevron-up {
      color : $primary-color;
    }
  }

  .value-cont {
    display : inline-block;
  }

  .floatText {
    small {
      position    : absolute;
      transform   : translateY(-12px) scale(0.75) !important;

      padding     : 0 5px;
      margin-left : -17px;
    }
  }

  .openCont {
    border : 1.5px solid $primary-color;

    small {
      position    : absolute;
      transform   : translateY(-12px) scale(0.75) !important;
      background  : white;
      padding     : 0 5px;
      margin-left : -17px;
    }

    .min-value {
      position : absolute;
      left     : 20px;
    }

    .max-value {
      position      : absolute;
      right         : 20px;
      margin-bottom : 20px;
    }
  }

  .P-to {
    padding    : 0 13px;
    font-size  : $txt14;
  }

  .range-cont::v-deep {
    .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
      min-height : 30px !important;
    }

    .v-text-field.v-text-field--enclosed .v-text-field__details,
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
      padding : 0 18px !important;
    }

    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color : $neutral-color;
    }

    .v-text-field--outlined .v-input__slot fieldset {
      padding-left : 14px !important;
    }

    .v-input .v-label {
      font-size   : $txt14 !important;
      font-weight : $regular !important;
      //color: $neutral-color;
      color       : $dark-gray;
    }

    .v-input--dense .v-label {
      top : 8px !important;
    }

    .v-text-field input {
      color       : $secondary-color !important;
      line-height : 24px !important;
      font-size   : $txt14;
    }

    .v-input--has-state.error--text .v-label {
      -webkit-animation : v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
      animation         : v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance : none;
      margin             : 0;
    }

    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
      color : $neutral-color;
    }

    .v-text-field--outlined.v-input--has-state fieldset,
    .v-text-field--outlined.v-input--is-focused fieldset {
      border : 1.5px solid !important;
    }
  }

  .theme--light {
    .floatText small {
      background : white;
    }

    .range-select {
      border : 1px solid $neutral-color;
    }
  }

  .theme--dark {
    .floatText small {
      background : $secondaryMainDark !important;
    }

    .range-select {
      border : 1px solid $neutral-colorDM;
    }

    .v-text-field input {
      color : $white !important;
    }

    .range-cont::v-deep {
      .vue-slider-process {
        background-color : $primary-colorDM !important;
      }

      .vue-slider-dot {
        border           : 1.5px solid $neutral-colorDM;
        background-color : $secondaryMainDark !important;
      }

      .vue-slider-dot-handle {
        background-color : $secondaryMainDark !important;
      }
    }

    .vue-slider-rail {
      background-color : $neutral-colorDM;
    }

    .vue-slider-dot-tooltip-inner {
      background-color : $primary-colorDM;
      border-color     : $primary-colorDM;
    }

    .range-cont::v-deep {
      box-shadow : 0px 0px 5px #ffffff26;

      .v-text-field input {
        color : $white !important;
      }
    }

    .price-select {
      .fe {
        .fe-chevron-up {
          color : $primary-colorDM;
        }
      }
    }

    .openCont {
      border : 1.5px solid $primary-colorDM;
    }

    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color : $neutral-colorDM;
    }

    &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
      color : $neutral-colorDM;
    }
  }

  .P-range-label {
    text-transform : capitalize;
  }

  .P-clear-icon{
    position: absolute;
    font-size: $txt12;
    cursor: pointer;
    right: 45px;
  }

  .save-icon {
    width: 23px;
    height: 23px;
  }

  .disabled-save {
    opacity: 0.5;
    transition: all 0.2s;
  }

</style>
