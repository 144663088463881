var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"G-table-block",class:{'G-flex': _vm.isSubTable, customClass: _vm.customClass }},[_c('div',{ref:"tableBody",staticClass:"G-table-scroll-Y",class:{ 'P-scroll-Y': !_vm.isHideHeader },on:{"scroll":function($event){return _vm.scrollY($event)}}},[(!_vm.isHideHeader)?_c('ul',{staticClass:"G-table-header"},[(_vm.isMultiSelect || _vm.isSubTable)?_c('li',{staticClass:"P-action-left",class:{'P-disable-sub': !_vm.isSubTable
}}):_vm._e(),_vm._l((_vm.columnConfigData),function(column,index){return [(column.isShow)?_c('li',{class:[
            {
              'P-cursor-move': column.isDrag,
              'P-fixed-left': _vm.fixedFirstAndLastColumn && index===0,
              'P-show-shadow': _vm.showShadowOnScroll,
              'P-second-column': (_vm.fixedFirstAndLastColumn || _vm.isSubTable) && index===0,
              'P-second-column-disable': !_vm.isSubTable,
              'P-cursor-pointer': column.sortData && column.sortData.isSort
            },
            column.className
          ],style:({
            minWidth:
              column.style.minWidth +
              (index === 0
                ? (_vm.columnWithTreeIncrement - _vm.recursiveTreeIndex) *
                  _vm.columnWithTree
                : 0) +
              'px',
               maxWidth:_vm.isSubTable?
              (column.style.minWidth +
              (index === 0
                ? (_vm.columnWithTreeIncrement - _vm.recursiveTreeIndex) *
                  _vm.columnWithTree
                : 0) +
              'px') : (column.style.maxWidth? _vm.getHideColumnsCount()? '100%': column.style.maxWidth+'px'  : '100%')
          }),attrs:{"draggable":column.isDrag},on:{"dragstart":function($event){return _vm.handleDragStart($event, index)},"dragover":function($event){return _vm.handleDragOver($event, index)},"dragleave":function($event){return _vm.handleDragLeave($event)},"dragend":function($event){return _vm.handleDragDrop($event)},"drop":function($event){return _vm.handleDragLeave($event)},"click":function($event){return _vm.sortColumns(column)}}},[(column.className)?_c('span',{staticClass:"P-border-dashed-column"}):_vm._e(),_c('div',{staticClass:"P-column"},[_c('span',{staticClass:"mdi mdi-drag P-drag-icon"}),_c('p',{staticClass:"G-align-center"},[_vm._v(" "+_vm._s(column.title)+" "),(column.sortData && column.sortData.isSort)?_c('span',{staticClass:"mdi P-sort-icon",class:{
                  'mdi-arrow-up': !column.sortData.sortValue || column.sortData.sortValue==='desc',
                  'mdi-arrow-down': column.sortData.sortValue==='asc',
                  'P-active-sort':column.sortData.sortValue
                }}):_vm._e()])])]):_vm._e()]}),(_vm.isActions)?_c('li',{staticClass:"P-action-column",class:{
            'P-fixed-right': _vm.fixedFirstAndLastColumn,
            'P-show-shadow': _vm.showShadowOnScroll || _vm.showShadowOnScrollSub
          }}):_vm._e()],2):_vm._e(),_c('div',{staticClass:"G-table-body"},[_vm._l((_vm.data),function(row,index){return _c('div',{staticClass:"P-sub-table",class:{
            'P-last-sub-table':
              index === _vm.data.length - 1 && _vm.recursiveTreeIndex !== 0,
            'P-active-sub':
              row.child && _vm.activeIndex === row.id && _vm.recursiveTreeIndex === 0,
            'P-main-column': _vm.recursiveTreeIndex === 0,
            'P-table-odd': index % 2 === 0 && _vm.recursiveTreeIndex === 0,
            'P-table-even': index % 2 !== 0 && _vm.recursiveTreeIndex === 0,
          }},[_c('span',{staticClass:"P-sub-table-bg"}),_c('ul',{class:{
              'P-active-sub-table': row.child && _vm.activeIndex === row.id
            }},[(_vm.isMultiSelect || _vm.isSubTable || _vm.isActions)?_c('li',{staticClass:"P-action-left",class:{
                'P-disable-select': !_vm.isMultiSelect,
                'P-selected-column':
                  _vm.activeIndex === row.id || _vm.recursiveTreeIndex > 0,
                  'P-disable-sub': !_vm.isSubTable
              },style:({
                minWidth: (_vm.isSubTable? 60 : 40) + _vm.columnWithTree * _vm.recursiveTreeIndex + 'px',
                maxWidth: _vm.isSubTable? (60 + _vm.columnWithTree * _vm.recursiveTreeIndex + 'px'): '100%'
              })},[(_vm.recursiveTreeIndex > 0)?[_vm._l((_vm.recursiveTreeIndex),function(i,index){return [(_vm.isSubTable)?_c('span',{staticClass:"P-sub-bg-color",class:{
                      'P-odd-style':
                        _vm.recursiveTreeIndex % 2 === 0 && _vm.recursiveTreeIndex !== 0,
                      'P-even-style':
                        _vm.recursiveTreeIndex % 2 !== 0 && _vm.recursiveTreeIndex !== 0,
                      'P-odd-sub':
                        i % 2 === 0 &&
                        _vm.recursiveTreeIndex !== 0 &&
                        i !== _vm.recursiveTreeIndex,
                      'P-even-sub':
                        i % 2 !== 0 &&
                        _vm.recursiveTreeIndex !== 0 &&
                        i !== _vm.recursiveTreeIndex,
                      'P-last-sub': i === _vm.recursiveTreeIndex
                    },style:({
                      left:
                        (_vm.recursiveTreeIndex !== 0 ? _vm.columnWithTree * i : 0) + 'px'
                    })}):_vm._e()]})]:_vm._e(),(_vm.isSubTable)?_c('span',{staticClass:"P-sub-color",class:{
                  'P-odd-style': _vm.recursiveTreeIndex % 2 === 0,
                  'P-even-style': _vm.recursiveTreeIndex % 2 !== 0
                },style:({ left: _vm.columnWithTree * _vm.recursiveTreeIndex + 'px' })}):_vm._e(),_vm._l((_vm.recursiveTreeIndex),function(i,j){return _c('i',{staticClass:"P-line",class:{
                  'P-last-line': index === _vm.data.length - 1,
                  'P-disable-sub-line': _vm.recursiveTreeIndex !== i,
                  'P-remove-line': index === _vm.data.length - 2,
                  'P-line-main':
                    _vm.recursiveTreeIndex > 0 && _vm.recursiveTreeIndex !== i
                },style:({ left: (j !== 0 ? 30 * i - 16 : 14) + 'px' })})}),_c('div',{staticClass:"P-column"},[(row.child && _vm.isSubTable)?_c('span',{staticClass:"mdi P-arrow-sub mdi-menu-up",class:{
                    'P-rotate-icon': _vm.activeIndex !== row.id
                  },on:{"click":function($event){return _vm.showSubTable($event, row)}}}):_vm._e(),(_vm.isMultiSelect)?_c('OttCheckbox',{attrs:{"data-val":_vm.getSelectedRow(row)},on:{"changeCheckbox":function($event){return _vm.checkRow(row, _vm.recursiveTreeIndex)}}}):_vm._e()],1)],2):_vm._e(),_vm._l((_vm.columnConfigData),function(column,index){return [(column.isShow)?_c('li',{class:[
                {
                  'P-fixed-left': _vm.fixedFirstAndLastColumn,
                  'P-show-shadow': _vm.showShadowOnScroll || _vm.showShadowOnScrollSub,
                  'P-second-column': (_vm.fixedFirstAndLastColumn || _vm.isSubTable) && index===0
                },
                column.className
              ],style:({
                minWidth:
                  column.style.minWidth +
                  (index === 0
                    ? (_vm.columnWithTreeIncrement - _vm.recursiveTreeIndex) *
                      _vm.columnWithTree
                    : 0) +
                  'px',
                maxWidth:
                 _vm.isSubTable ?( column.style.minWidth +
                  (index === 0
                    ? (_vm.columnWithTreeIncrement - _vm.recursiveTreeIndex) *
                      _vm.columnWithTree
                    : 0) +
                  'px'):  (column.style.maxWidth? _vm.getHideColumnsCount()? '100%': column.style.maxWidth+'px'  : '100%'),
                left: (_vm.isSubTable? 60 : 40) + _vm.columnWithTree * _vm.recursiveTreeIndex + 'px'
              }),on:{"click":function($event){return _vm.showSubTable($event, row)}}},[(column.className)?_c('span',{staticClass:"P-border-dashed-column"}):_vm._e(),(_vm.isSubTable)?_c('span',{staticClass:"P-sub-bg-color",class:{
                  'P-odd-style':
                    _vm.recursiveTreeIndex % 2 === 0 && _vm.recursiveTreeIndex !== 0,
                  'P-even-style':
                    _vm.recursiveTreeIndex % 2 !== 0 && _vm.recursiveTreeIndex !== 0
                }}):_vm._e(),(_vm.isSubTable)?_c('span',{staticClass:"P-sub-color",class:{
                  'P-odd-style':
                    _vm.recursiveTreeIndex % 2 === 0 && _vm.recursiveTreeIndex !== 0,
                  'P-even-style':
                    _vm.recursiveTreeIndex % 2 !== 0 && _vm.recursiveTreeIndex !== 0
                }}):_vm._e(),_c('div',{staticClass:"P-column",domProps:{"innerHTML":_vm._s(typeof column?.cellView === 'function' ? column.cellView(row) : '-')},on:{"click":function($event){column.isColumnClicked ? _vm.clickFunction(row, column.title) : ''}}})]):_vm._e()]}),(_vm.isActions)?_c('li',{staticClass:"P-action-column ott-menu",class:{
                'P-fixed-right': _vm.fixedFirstAndLastColumn,
                'P-show-shadow': _vm.showShadowOnScroll || _vm.showShadowOnScrollSub
              }},[(_vm.isSubTable)?_c('span',{staticClass:"P-sub-bg-color",class:{
                  'P-odd-style':
                    _vm.recursiveTreeIndex % 2 === 0 && _vm.recursiveTreeIndex !== 0,
                  'P-even-style':
                    _vm.recursiveTreeIndex % 2 !== 0 && _vm.recursiveTreeIndex !== 0
                }}):_vm._e(),(_vm.isSubTable)?_c('span',{staticClass:"P-sub-color",class:{
                  'P-odd-style':
                    _vm.recursiveTreeIndex % 2 === 0 && _vm.recursiveTreeIndex !== 0,
                  'P-even-style':
                    _vm.recursiveTreeIndex % 2 !== 0 && _vm.recursiveTreeIndex !== 0
                }}):_vm._e(),_c('div',{staticClass:"P-column"},[_vm._t("action",null,{"rowData":row})],2)]):_vm._e()],2),(row.child && _vm.activeIndex === row.id)?_c('OttUniversalTable',{attrs:{"column-config":_vm.columnConfig,"data":row.child,"is-hide-header":true,"is-sub-table":_vm.isSubTable,"is-multi-select":_vm.isMultiSelect,"selected-arrow-list":_vm.selectedArrowList,"main-table":false,"get-sub-list":_vm.getSubList,"recursive-tree-index":_vm.recursiveTreeIndex + 1,"main-data":_vm.mainData,"show-shadow-on-scroll-sub":_vm.showShadowOnScroll || _vm.showShadowOnScrollSub,"column-with-tree-increment":_vm.columnWithTreeIncrement,"fixed-first-and-last-column":_vm.fixedFirstAndLastColumn},on:{"selectRow":_vm.selectRow,"updateColumnWith":_vm.updateColumnWidth},scopedSlots:_vm._u([{key:"action",fn:function(props){return [_vm._t("action",null,{"rowData":props})]}}],null,true)}):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(_vm.selectedRowId === row.id)?_vm._t("rowBottomCont"):_vm._e()],2)],1)}),(_vm.isTotalSum)?_c('ul',{staticClass:"P-total-sum-block"},[_c('li',{staticClass:"P-action-left",style:({
              minWidth: (_vm.isSubTable? 60 : 40) + _vm.columnWithTree * _vm.recursiveTreeIndex + 'px',
              maxWidth: _vm.isSubTable?(60 + _vm.columnWithTree * _vm.recursiveTreeIndex + 'px'):'100%'
            })}),_vm._l((_vm.columnConfig),function(column,index){return [(column.isShow)?_c('li',{class:[
              {
                'P-fixed-left': _vm.fixedFirstAndLastColumn,
                'P-show-shadow': _vm.showShadowOnScroll || _vm.showShadowOnScrollSub,
                'P-second-column': (_vm.isMultiSelect || _vm.isSubTable) && index===0
              },
              column.className
            ],style:({
              minWidth:
                column.style.minWidth +
                (index === 0
                  ? (_vm.columnWithTreeIncrement - _vm.recursiveTreeIndex) *
                    _vm.columnWithTree
                  : 0) +
                'px',
                 maxWidth:
              _vm.isSubTable?(  column.style.minWidth +
                (index === 0
                  ? (_vm.columnWithTreeIncrement - _vm.recursiveTreeIndex) *
                    _vm.columnWithTree
                  : 0) +
                'px'):  (column.style.maxWidth? _vm.getHideColumnsCount()? '100%': column.style.maxWidth+'px'  : '100%'),

              left: (_vm.isSubTable? 60 : 40) + _vm.columnWithTree * _vm.recursiveTreeIndex + 'px'
            })},[(column.className)?_c('span',{staticClass:"P-border-dashed-column"}):_vm._e(),(column.totalData)?_c('div',{staticClass:"P-total-section G-flex-center flex-column"},[_c('p',{staticClass:"P-total-text primary--text"},[_vm._v(_vm._s(column.totalText))]),_c('div',{domProps:{"innerHTML":_vm._s(column.cellTotalData(_vm.data))}})]):_vm._e()]):_vm._e()]}),(_vm.isActions)?_c('li',{staticClass:"P-action-column ott-menu",class:{
              'P-fixed-right': _vm.fixedFirstAndLastColumn,
              'P-show-shadow': _vm.showShadowOnScroll || _vm.showShadowOnScrollSub
            }}):_vm._e()],2):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }