import RequestService from "@/services/request.service";
import PackagesFilterModel from "../../models/packages/PackagesFilterModel";
import PricesFilterModel from "../../models/packages/PricesFilterModel";
const FileDownload = require("js-file-download");

const InitialState = {
  showWarningModal: false,
  // filter data
  filterModel: new PackagesFilterModel(),
  filter: {
    limit: 20,
    sortBy: "_id:desc",
    page: 1,
  },

  filterPricesModel: new PricesFilterModel(),
  filterDataPrices: null,

  dataTable: [],
  allPackagesCount: 0,
  packageId: null,

  // for pagination
  currentPage: 0,
  dataCount: 0,
  totalPages: 0,

  rooms: [
    {
      text: 1,
      value: 1,
    },
    {
      text: 2,
      value: 2,
    },
    {
      text: 3,
      value: 3,
    },
    {
      text: 4,
      value: 4,
    },
    {
      text: 5,
      value: 5,
    },
  ],
  filterRangeData: {
    clients: [0, 1000],
    clientsTotal: [0, 1000],
  },

  // package edit tabs
  editTabs: [
    {
      name: "General Info",
      title: "generalInfo",
    },
    {
      name: "Prices",
      title: "prices",
    },
  ],
  activeTab: 0,
  disableTabs: 0,
  isOpenEditModal: false,

  // add price
  allPriceList: [],
  showPriceList: false,
  isOpenAddPriceModal: false,

  addPrice: {
    type: "day",
    numberOfMonthDay: "",
    priceFor: "clients",
    countOfRoom: "",
    firstRoom: "",
    secondRoom: "",
    thirdRoom: "",
    fourthRoom: "",
    fifthRoom: "",
    isLoading: false,
  },
  priceTypes: {
    day: "day",
    month: "month",
  },
  pricesFor: {
    clients: "clients",
    resale: "resale",
  },

  calculateByPercents: {
    discount: "",
    priceGroup: "",
    period: "",
    groupingMode: "",
  },

  packageInfo: null,

  filterData: {},

  // table loading
  isTableLoading: true,

  editedPriceId: null,
  editedPrice: null,
  isOpenChannelsModal: false,
  channelsList: null,
  channelsListResult: [],
  channelListTotalResults: null,

  // for  update  table  columns
  defaultSettings: [], // TODO CHANGE_SORT   cloneDefaultSettings: defaultColumnConfig, removed
  updateColumnsFromBack: "packagesModule/updatePackagesSettings",
  updateColumnSettingsCommit: "packagesModule/setDefaultSettings",
  isDefault: false,
  packagesListForSelect: [],
  packagesResult: false,
  discountList: [],

  priceFilter: {
    clientType: "",
    discount: "",
    priceGroup: "",
  },

  deletedPrice: null,
  showCancelBtn: false,

  isEditLoading: false,
  isLoadingBtn: false,
  groupingModeItems: [
    {
      name: "By Price Group",
      value: 1,
    },
    {
      name: "By Period",
      value: 2,
    },
  ],
  periodList: [],
  subscriptions: []
};

export const packagesModule = {
  namespaced: true,
  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    /**
     * Filter packages
     */
    filterPackages(state) {
      const filterObject = {};

      if (state.filterModel.search) {
        filterObject.search = state.filterModel.search;
      }
      if (state.filterModel.room) {
        filterObject.room = state.filterModel.room;
      }
      if (state.filterModel.clientPriceGroup || state.filterModel.clientPriceGroup === null) {
        filterObject.clientPriceGroup = state.filterModel.clientPriceGroup;
      }
      if (state.filterModel.clientDiscounts) {
        filterObject.clientDiscounts = state.filterModel.clientDiscounts;
      }
      if (state.filterModel.providerPriceGroup || state.filterModel.providerPriceGroup === null) {
        filterObject.providerPriceGroup = state.filterModel.providerPriceGroup;
      }
      if (
        state.filterModel.clients[1] &&
        (state.filterModel.clients[0] || state.filterModel.clients[0] === 0)
      ) {
        filterObject.clientsFrom = state.filterModel.clients[0];
        filterObject.clientsTo = state.filterModel.clients[1];
      }
      if (state.filterModel.providerDiscounts) {
        filterObject.providerDiscounts = state.filterModel.providerDiscounts;
      }
      if (
        state.filterModel.clientsTotal[1] &&
        (state.filterModel.clientsTotal[0] ||
          state.filterModel.clientsTotal[0] === 0)
      ) {
        filterObject.clientsTotalFrom = state.filterModel.clientsTotal[0];
        filterObject.clientsTotalTo = state.filterModel.clientsTotal[1];
      }
      if (state.filterModel.liveService) {
        filterObject.vEnable = 1;
      }
      if (state.filterModel.archiveService) {
        filterObject.aEnable = 1;
      }
      if (state.filterModel.timeshiftService) {
        filterObject.tEnable = 1;
      }
      if (state.filterModel.vodService) {
        filterObject.vEnable = 1;
      }
      if (state.filterData?.sortBy) {
        filterObject.sortBy = state.filterData.sortBy
      }

      state.filterData = filterObject;
    },

    filterPrices(state) {
      const filterObject = {};
      if (state.filterPricesModel.discount) {
        filterObject.discount = state.filterPricesModel.discount;
      }
      if (state.filterPricesModel.priceGroup) {
        if (state.filterPricesModel.priceGroup === "default") {
          filterObject.priceGroup = null;
        } else {
          filterObject.priceGroup = state.filterPricesModel.priceGroup;
        }
      }
      state.filterDataPrices = filterObject;
    },

    resetPricesFilter(state) {
      state.calculateByPercents = JSON.parse(
        JSON.stringify(InitialState.calculateByPercents)
      );
    },

    resetFilterPricesModel(state) {
      state.filterPricesModel = JSON.parse(
        JSON.stringify(InitialState.filterPricesModel)
      );
    },

    /**
     * Set is open edit modal
     */
    setIsOpenEditModal(state, value) {
      state.isOpenEditModal = value;
    },

    nextTab(state) {
      if (state.activeTab < state.editTabs.length) {
        state.activeTab = ++state.activeTab;
      }
    },

    setActiveTab(state, value) {
      state.activeTab = value;
    },
    resetPrices(state) {
      state.allPriceList = [];
      state.pricesRadioValue = "clientsPrices";
      state.addPrice = JSON.parse(JSON.stringify(InitialState.addPrice));
      state.showPriceList = false;
    },
    resetAddPrice(state) {
      state.addPrice = JSON.parse(JSON.stringify(InitialState.addPrice));
    },

    resetAddPriceInputs(state) {
      for (let key in InitialState.addPrice) {
        if (key !== "priceFor" && key !== "type") {
          state.addPrice[key] = JSON.parse(
            JSON.stringify(InitialState.addPrice[key])
          );
        }
      }
    },

    /**
     * Prices data
     */
    setIsOpenAddPriceModal(state, value) {
      state.isOpenAddPriceModal = value;
    },
    setNumberOfMonthDay(state, value) {
      state.addPrice.numberOfMonthDay = value;
    },
    setPackageId(state, value) {
      state.packageId = value;
    },
    setPriceGroup(state, value) {
      state.calculateByPercents.priceGroup = value;
    },
    setDiscount(state, value) {
      state.calculateByPercents.discount = value;
    },
    setFirstRoom(state, value) {
      state.addPrice.firstRoom = value;
    },
    setSecondRoom(state, value) {
      state.addPrice.secondRoom = value;
    },
    setThirdRoom(state, value) {
      state.addPrice.thirdRoom = value;
    },
    setFourthRoom(state, value) {
      state.addPrice.fourthRoom = value;
    },
    setFifthRoom(state, value) {
      state.addPrice.fifthRoom = value;
    },
    setAddPriceIsLoading(state, value) {
      state.addPrice.isLoading = value;
    },
    setClientType(state, value) {
      state.addPrice.priceFor = value;
    },

    /**
     * Set packages data
     */
    setPackagesData(state, data) {
      state.dataTable = data.results;
      state.currentPage = data.page;
      state.totalPages = data.totalPages;
      state.dataCount = data.totalResults;

      state.packagesListForSelect = data.results.map((item) => {
        item.value = item.id;
        return item;
      });
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue
    },

    /**
     * Set pagination filter
     * **/
    setFilterPage(state, page) {
      state.filter.page = page;
      state.currentPage = page;
    },
    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filterData?.sortBy) { 
          state.filterData.sortBy = "_id:desc"; 
        }
      }
    },

    setEditedPriceId(state, value) {
      state.editedPriceId = value;
    },

    /**
     * Set is open channels modal
     */
    setIsOpenChannelsModal(state, value) {
      state.isOpenChannelsModal = value;
    },
    setChannelsList(state, data) {
      state.channelsList = data;
      if (data.results && data.results.length) {
        state.channelsListResult = data.results;
        state.channelListTotalResults = data.totalResults;
      }
    },
    resetChannelsData(state) {
      state.channelsList = null;
      state.channelsListResult = [];
      state.channelListTotalResults = null;
    },

    /**
     * Reset filter
     */
    resetFilter(state) {
      state.filter = JSON.parse(JSON.stringify(InitialState.filter));
    },
    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault;
      }
    },

    setDefaultSettingsForBack(state, data) {
      if (data.packagesSettings) {
        state.isDefault = data.packagesSettings.isDefault;
        state.defaultSettings = data.packagesSettings.packagesTable;
      }
    },

    setPriceAllList(state, data) {
      if (data.prices && data.prices.length) {
        let listDays = [];
        let lisMonths = [];
        data.prices.map((item) => {
          item.md.includes("d") ? listDays.push(item) : "";
          item.md.includes("m") ? lisMonths.push(item) : "";
        });

        listDays = listDays.sort(
          (a, b) => a.md.split("d")[0] - b.md.split("d")[0]
        );
        lisMonths = lisMonths.sort(
          (a, b) => a.md.split("m")[0] - b.md.split("m")[0]
        );

        data.prices = [...listDays, ...lisMonths];

        state.allPriceList = data.prices;

        let pricePeriod = [
          {
            name: "All",
            value: "all",
          },
        ];

        data.prices.map((item) => {
          pricePeriod.push({
            name: item.md,
            value: item.md,
          });
        });

        state.periodList = pricePeriod;
      }
    },

    setShowPriceList(state, value) {
      state.showPriceList = value;
    },
    setDiscountList(state, data) {
      state.discountList = data;
    },

    setDeletedPrice(state, value) {
      state.deletedPrice = value;
    },

    setShowCancelBtn(state, value) {
      state.showCancelBtn = value;
    },

    setIsEditLoading(state, value) {
      state.isEditLoading = value;
    },
    setIsLoadingBtn(state, value) {
      state.isLoadingBtn = value;
    },

    resetState(state) {
      for (let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]));
      }
    },
  },
  actions: {
    getAllPackages({ commit }) {
      return RequestService.read(`packages?limit=100000`).then((response) => {
        commit("setData", { allPackagesCount: response.data.results.length });
      });
    },

    getSubscriptions({ commit }, options) {
      let query = Object.keys(options)
        .map((key) => key + "=" + options[key])
        .join("&");

      return RequestService.read(`subscriptions?${query}`).then((response) => {
        commit('setData', { subscriptions: response.data })
        return Promise.resolve(response.data)
      })
    },

    getPackagesList({ commit, state, rootState }, filter = null) {
      let options = {
        page: state.currentPage ? state.currentPage : 1,
      };

      if (filter) {
        options.limit = state.filter.limit
        Object.assign(options, filter);
      } else {
        Object.assign(options, state.filter);
      }

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      let query = Object.keys(options)
        .map((key) => key + "=" + options[key])
        .join("&");
      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = "blob";
      } else {
        commit("setData",  { isTableLoading: true });
      }

      return RequestService.read(`packages?${query}`, readOptions)
        .then((response) => {
          if (!isExcelRequest) {
            if (response.data.results && response.data.results.length) {
              commit("setData", (state.packagesResult = true));
            } else {
              commit("setData", (state.packagesResult = false));
            }
            commit("setIsLoadingBtn", false);
            commit("setPackagesData", response.data);
            commit("setData",  { isTableLoading: false });
          } else {
            FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
          }
        })
        .catch((e) => console.log("error ", e))
        .finally(() => commit("setData",  { isTableLoading: false }));
    },
    exportPackagesList({ commit, state }, filter = null) {
      let options = {
        page: state.currentPage ? state.currentPage : 1,
      };
      if (filter) {
        options.limit = state.filter.limit
        Object.assign(options, filter);
      } else {
        Object.assign(options, state.filter);
      }
      let query = Object.keys(options)
        .map((key) => key + "=" + options[key])
        .join("&");
      return RequestService.read(`packages?${query}`, {
        responseType: "blob",
      })
        .then((response) => {})
        .catch((e) => console.log("error ", e));
    },

    editPackage({ dispatch, state }, { packageId, body }) {
      return RequestService.update(`packages/edit/${packageId}`, { ...body })
        .then(async () => {
          await dispatch("getPackagesList", state.filterData);
        })
        .catch((e) => console.log("error ", e));
    },

    disablePackage({ dispatch, state }, { status, packageId }) {
      return RequestService.update(`packages/disable/${packageId}`, { state: status})
        .then(async () => {
          await dispatch("getPackagesList", state.filterData);
          return Promise.resolve(true);
        })
        .catch((e) => console.log("error ", e));
    },
    multipleDisableEnablePackage({ commit, dispatch, state }, data) {
      return RequestService.create(`packages/multiple`, { ...data })
        .then(async (response) => {
          await dispatch("getPackagesList", state.filterData);
          return Promise.resolve(response.data);
        })
        .catch((e) => console.log("error ", e));
    },

    // prices - add default prices
    addDefaultPrices({ commit, dispatch, state }, { packageId, data }) {
      return RequestService.create(`packages/prices/default/${packageId}`, {
        ...data,
      })
        .then(async (response) => {
          await dispatch("getPackagesList", state.filterData);
        })
        .catch((e) => console.log("error ", e));
    },
    // prices - get price
    getPrice({ commit, state }, { packageId, type }) {
      return RequestService.read(
        `packages/prices/${packageId}?clientType=${type}`
      )
        .then((response) => {
          if (response.data.prices && response.data.prices.length) {
            commit("setShowPriceList", true);
            commit("setPriceAllList", response.data);
            commit("setAddPriceIsLoading", false);
            commit("setIsEditLoading", false);
          }
          return Promise.resolve(response.data);
        })
        .catch((e) => console.log("error ", e));
    },

    getPriceByPriceGroup({ commit, state }, { packageId, filter = null }) {
      let options = {
        clientType: state.addPrice.priceFor === "resale" ? false : true,
      };
      if (filter) {
        Object.assign(options, filter);
      } else {
        Object.assign(options, state.priceFilter);
      }

      let query = Object.keys(options)
        .map((key) => key + "=" + options[key])
        .join("&");

      return RequestService.read(`packages/prices/${packageId}?${query}`)
        .then((response) => {
          if (response.data.prices && response.data.prices.length) {
            commit("setShowPriceList", true);
            commit("setPriceAllList", response.data);
            commit("setIsEditLoading", false);
            return Promise.resolve(response.data);
          } else {
            commit("setShowPriceList", false);
          }
        })
        .catch((e) => console.log("error ", e));
    },
    // prices - edit price
    editPriceList({ commit, dispatch, state }, { packageId, data }) {
      return RequestService.create(`packages/prices/edit/${packageId}`, {
        ...data,
      })
        .then(async (response) => {
          await dispatch("getPackagesList", state.filterData);
          console.log("editPrice ", response);
        })
        .catch((e) => console.log("error ", e));
    },

    // add new row (price)
    addNewPrice({ commit }, { packageId, data }) {
      return RequestService.create(`packages/prices/add/${packageId}`, {
        ...data,
      })
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((e) => console.log("error ", e));
    },

    getChannelsByPackageId({ commit }, { packageId }) {
      return RequestService.read(`channels/package/${packageId}`)
        .then((response) => {
          commit("setChannelsList", response.data);
        })
        .catch((e) => console.log("error ", e));
    },

    updatePackagesSettings({ state, commit }, data) {
      return RequestService.update(`users/settings`, {
        packagesSettings: {
          packagesTable: data,
          isDefault: state.isDefault,
          limit: state.filter.limit,
        },
      }).then(
        (data) => {
          console.log("data ", data);
        },
        (error) => {
          console.log("error ", error);
        }
      );
    },

    getPackagesSettings({ commit, state }) {
      return RequestService.read(`users/settings`).then(
        (res) => {
          const limitData = res?.data?.packagesSettings?.limit ? res.data.packagesSettings.limit : state.filter.limit
          commit("setDefaultSettingsForBack", res.data);
          commit('setLimit', limitData)
          return Promise.resolve(res.data);
        },
        (error) => {
          console.log("error ", error);
        }
      );
    },

    channelsSync({ commit, state, dispatch }, isExcel = false) {
      return RequestService.read(`channels/sync`)
        .then(async () => {
          await dispatch("getPackagesList", { ...state.filterData });
        })
        .catch((e) => console.log("error ", e));
    },
  },
};
