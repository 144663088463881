<template>
  <div class="Ott-select-cont" :class="selectClass">
    <v-select
      v-model="selected"
      :items="items"
      :label="label"
      :item-text="itemText"
      :item-value="itemValue"
      :outlined="outlined"
      :error="error"
      :height="height"
      :clearable="clear"
      :ripple="false"
      :attach="true"
      :append-icon="appendedIcon"
      :menu-props="{ maxHeight: 150, contentClass, offsetY: true, top, nudgeTop }"
      :disabled="disabled"
      :multiple="multiple"
      :persistent-hint="persistentHint"
      dense
      @change="selectChange"
      @input="inputChange"
      @click:clear="clearData"
      @click="click"
      @focus="focus"
    >
      <template v-slot:selection="{ item, index }">
        <template v-if="multiple">
          <div class="P-multi-select">
            <div
              v-if="isTranslate && index === 0"
              :class="{ 'P-multiple': multiple }"
              v-html="item[selectedText][0].name"
              class="v-select__selection v-select__selection--comma"
            />
            <div
              v-if="!isTranslate && index === 0"
              :class="{ 'P-multiple': multiple }"
              class="v-select__selection v-select__selection--comma"
            >
              {{ item[selectedText] }}
            </div>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selected.length - 1 }} others)
            </span>
          </div>
        </template>
        <template v-else>
          <div
            v-if="isTranslate"
            :class="{ 'P-multiple': multiple }"
            v-html="item[selectedText][0].name"
            class="v-select__selection v-select__selection--comma"
          />
          <div
            v-else
            :class="{ 'P-multiple': multiple }"
            v-html="item[selectedText]"
            class="v-select__selection v-select__selection--comma"
          />
        </template>
      </template>
      <template v-slot:item="{ item, on, attrs }">
        <!--        <div class="v-select__selection  v-select__selection&#45;&#45;comma" >-->
        <!--          {{item}}-->
        <!--          {{ item[selectedText] }}-->
        <!--        </div>-->
        <v-list-item
          v-if="!multiple"
          v-on="on"
          v-bind="attrs"
          :style="{ display: hideList.includes(item.value) ? 'none' : 'block' }"
        >
          <v-list-item-content>
            <li v-html="isTranslate ? item[selectedText][0].name : item[selectedText]" class="text-left list-style-type-none" />
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-on="on" v-bind="attrs" v-else>
          <v-list-item-icon>
            <v-icon>
              {{
                selected.includes(item.id)
                  ? "mdi-checkbox-marked"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-left">{{isTranslate ? item[selectedText][0].name : item[selectedText]}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "OttSelect",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectClass: {
      type: String,
    },
    itemText: {
      type: String,
    },
    optionText: {
      type: String,
    },
    selectedText: {
      type: String,
    },
    itemValue: {
      type: String,
    },
    object: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    nudgeTop: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: "Label",
    },
    height: {
      type: Number,
      default: 38,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    hideList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    error: {
      type: Boolean,
      default: false,
    },
    appendedIcon: {
      type: String,
    },
    isTranslate: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  data() {
    return {
      selected: null,
      selectedRel: null,
    };
  },
  mounted() {
    this.selected = this.value;
  },
  methods: {
    selectChange(value) {
      this.$emit("input", value);
      this.$emit("emitFunc", value);
    },
    inputChange(value) {
      this.$emit("input", value);
      this.$emit("emitInputChange", value);
    },
    clearData() {
      this.$emit("emmitClearData", this.selected);
    },
    focus() {
      this.$emit("emmitFocus");
    },
    click() {
      this.$emit("click");
    },
  },
  watch: {
    value(data) {
      this.selected = data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.Ott-select-cont::v-deep {
  .v-input--has-state.error--text .v-label {
    -webkit-animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  }

  .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    min-height: 30px !important;
  }

  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 18px;
  }

  .v-text-field--outlined .v-input__slot fieldset {
    padding-left: 14px !important;
  }

  .v-list {
    padding: 0 !important;
  }

  .v-input__icon--clear {
    .v-icon {
      color: $neutral-color;
      font-size: $txt16 !important;
    }
  }
}

.theme--light::v-deep {
  &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: $neutral-color;
  }

  .v-label {
    font-size: $txt14;
    color: $neutral-color;
    text-transform: capitalize !important;
  }

  .mdi-menu-down {
    color: $neutral-color;
  }

  .v-select__selection--comma {
    color: #0a3c68 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

.theme--dark::v-deep {
  &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: $neutral-colorDM;
  }

  .v-label {
    font-size: $txt14;
    color: $neutral-colorDM;
  }

  .mdi-menu-down {
    color: $neutral-colorDM;
  }

  .v-select__selection--comma {
    color: $white !important;
    font-size: $txt14 !important;
    font-weight: 400 !important;
  }
}
</style>
<style lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";

.v-menu__content {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border: none !important;
  @include box-shadow-not-top;
  max-width: 100% !important;

  .v-select-list {
    padding: 0;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #0a3c68 !important;
    font-size: $txt14 !important;
    font-weight: 400 !important;
  }

  .theme--light.v-select .v-select__selection--comma {
    color: #0a3c68 !important;
    font-size: $txt14 !important;
    font-weight: 400 !important;
  }

  .v-select__selection--comma {
    color: #0a3c68 !important;
    font-size: $txt14 !important;
    font-weight: 400 !important;
  }

  .v-select__selection {
    color: #0a3c68 !important;
    font-size: $txt14 !important;
    font-weight: 400 !important;
  }
}

.Ott-select-cont::v-deep {
  .v-input {
    display: inline-grid !important;
  }
}

.v-input {
  display: inline-grid !important;
  width: 100%;
}

.theme--dark {
  .P-multiple {
    background-color: $darkBg-color12MD;
  }
}

.P-multiple {
  background-color: $neutral-color15;
  border-radius: 30px;
  padding: 4px 6px !important;
}

.P-multi-select {
  .v-select__selection {
    max-width: 100px;
  }
}

.list-style-type-none {
  list-style-type: none;
}
</style>
