const fullCalendar = () => import("@/views/full-calendar/FullCalendar");

export const fullCalendarRout = {
    path: "/appointments",
    redirect: "/appointments",
    name: "Appointments",
    component: fullCalendar,
    children: [
      {
        path: "",
        name: "",
        component: fullCalendar,
      }
    ]
  };
  