export default class TableListResultModel {

    constructor(data) {
        this.access = {};
        this.refresh = {};

        if (data)
            this.fetch(data);
    }

    fetch(data) {
        if (data.access)
            this.access = data.access;
        if (data.refresh)
            this.refresh = data.refresh;
    }

    isValid() {
        return this.access.token !== undefined;
    }

}
