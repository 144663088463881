import RequestService from "@/services/request.service"

const InitialState = {
    activeStep: 1,
    isOpenBalanceRefillModal: false,

    // balance types
    balance: 1,
    balanceType: {
        refill: 1,
        refund: 2
    },
    /** payment methods, default - 0 (saved payment)
     *  0 - saved payment,
     *  1 - credit card,
     *  2 - bank account,
     *  3 - check
     */
    selectedPaymentMethod: 0,
    paymentMethods: [
        {
            value: 0,
            name: 'Saved Payment',
            icon: 'icon-checked'
        },
        {
            value: 1,
            name: 'Credit Card',
            icon: 'icon-credit-card'
        },
        {
            value: 2,
            name: 'Bank Account',
            icon: 'icon-bank-account'
        },
        {
            value: 3,
            name: 'Check',
            icon: 'icon-check'
        }
    ],
    // saves payment
    savedPayment: {
        first: 'first',
        second: 'second'
    },
    // credit card
    creditCard: {
        isExisting: true,
        cardNumber: '',
        cardHolderName: '',
        expiryDate: '',
        cvc: '',
        billingAddress: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        suite: '',
        save: false
    },
    // bank account
    bankAccountType: 'personal',
    bankAccount: {
        personal: 'personal',
        business: 'business',
        bankName: '',
        routingNumber: '',
        accountNumber: '',
        firstName: '',
        lastName: '',
        nickname: '',
        companyName: '',
        save: false
    },
    // check
    checkNumber: null,
    availableCheckNumber: 15478963548,
    mailByCheckeeper: true,

    printSuccess: 1,
    amount: '',
    providerId: ''
}

export const balanceRefill = {
    namespaced: true,
    state: JSON.parse(JSON.stringify(InitialState)),
    mutations: {
        openBalanceRefillModal(state) {
            state.isOpenBalanceRefillModal = true
        },
        setBalance(state, value) {
            state.balance = value
        },
        setBankAccountType(state, value) {
            state.bankAccountType = value
        },
        closeBalanceRefillModal(state) {
            state.isOpenBalanceRefillModal = false
        },
        changeActiveStep(state, value) {
            state.activeStep = value
        },
        changePrintSuccessValue(state, value) {
            state.printSuccess = value
        },
        // balance amount
        setBalanceAmount(state, value) {
            state.amount = value
        },
        // select payment method
        selectPaymentMethod(state, value) {
            state.selectedPaymentMethod = value
        },
        // credit card
        setCardNumber(state, value) {
            state.creditCard.cardNumber = value
        },
        setCardHolderName(state, value) {
            state.creditCard.cardHolderName = value
        },
        setAddress(state, value) {
            state.creditCard.address = value
        },
        setCity(state, value) {
            state.creditCard.city = value
        },
        setState(state, value) {
            state.creditCard.state = value
        },
        setZipCode(state, value) {
            state.creditCard.zipCode = value
        },
        setCountry(state, value) {
            state.creditCard.country = value
        },
        setSuite(state, value) {
            state.creditCard.suite = value
        },
        saveCreditCard(state, value) {
            state.creditCard.save = value
        },
        addCreditCard(state) {
            state.savedPaymentList.push(state.creditCard)
        },
        // check
        setCheckNumber(state, value) {
            state.checkNumber = value
        },
        setMailByCheckeeper(state, value) {
            state.mailByCheckeeper = value
        },
        // bank account
        setBankName(state, value) {
            state.bankAccount.bankName = value
        },
        setRoutingNumber(state, value) {
            state.bankAccount.routingNumber = value
        },
        setAccountNumber(state, value) {
            state.bankAccount.accountNumber = value
        },
        saveBankAccount(state, value) {
            state.bankAccount.save = value
        },
        // reset fields
        resetFields(state) {
            state.balance = 1
            state.amount = ''
            state.selectedPaymentMethod = 0
            state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
            state.bankAccount = JSON.parse(JSON.stringify(InitialState.bankAccount))
            state.bankAccountType = 'personal'
            state.checkNumber = ''
        },
    
        resetState(state) {
          for(let key in state) {
            state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
          }
        }

        // checkPaymentType(state) {
        //     if (state.selectedPaymentMethod === 0) {
        //         console.log('saved payment method')
        //     } else if (state.selectedPaymentMethod === 1) {
        //         console.log('credit card payment method')
        //     } else if (state.selectedPaymentMethod === 2) {
        //         console.log('bank account payment method')
        //     } else {
        //         console.log('check payment method')
        //     }
        // }
    },
    actions: {
        payBalance({ commit }, data) {
            return RequestService.create('balances/pay', {...data})
                .then((r) => {
                    return Promise.resolve(r.data)
                }).catch(e => console.log(e))
        }
        // for example add credit card payment
        // addPaymentMethod({ state }) {
        //     return RequestService.create(
        //         `ottProviders/paymentMethods/`, state.creditCard
        //     )
        //         .then(response => {
        //             console.log('response ', response)
        //         })
        //         .catch(error => {
        //             console.log('error ', error)
        //         })
        // }
    },
}
