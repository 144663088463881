<template>
  <ott-dialog
    v-if="isOpenReviewModal"
    :is-open="isOpenReviewModal"
    :width="1000"
    :show-footer="false"
    :show-header="true"
    :modalTitle="'Review detail'"
    @resetDataModal="closeModal"
    @outside="closeModal"
    close-value="Detail"
  >
    <template v-slot:modalBody>
      <review-detail @updateData="updateData" />
    </template>
  </ott-dialog>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import ReviewDetail from "@/views/review/modals/ReviewDetali";
export default {
  name: "DetailModal",
  components: { ReviewDetail, OttDialog },
  computed: {
    ...mapState({ isOpenReviewModal: state => state.review.isOpenReviewModal })
  },
  methods: {
    ...mapMutations({
      setIsOpenModal: 'review/setIsOpenModal'
    }),
    updateData() {
      this.$emit("updateData");
    },
    closeModal() {
      this.setIsOpenModal(false)
    }
  }
};
</script>

<style lang="scss" scoped></style>
