<template>
  <div class="P-columns-menu">
    <v-menu
      :close-on-content-click="false"
      attach=".P-columns-menu"
      origin="top left"
      transition="scale-transition"
      content-class="P-info-block"
      offset-y
      @click="$emit('onClick')"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="P-settings" v-bind="attrs" v-on="on" @mouseover="$emit('hoverColumnConfig')" @mouseleave="$emit('leaveColumnConfig')">
          <span class="mdi neutral--text mdi-settings-outline P-icon"></span>
        </div>
      </template>

      <v-card class="secondary66Dark">
        <div class="P-column-configs" :style="containerStyles">
          <ul>
            <template v-for="(items, index) of columnConfig">
              <li v-if="!items?.customUnshow" :key="index" class="d-flex">
                <OttCheckbox
                  v-model="items.isShow"
                  :data-val="items.isShow"
                  :label="items.title"
                  :disabled="index === 0 || items?.disabled"
                  @changeCheckbox="$emit('updateColumnsFromCheck')"
                />
              </li>
            </template>
          </ul>
        </div>
        <div class="P-reset-column G-flex G-justify-end">
          <OttButton text="Reset" class="primary-sm-btn" :click="updateDataColumn" v-if="!isDefault"/>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import OttButton from "@/components/vuetifyComponents/OttButton";

  export default {
    name: 'OttHideShowColumnsOption',
    components: {OttButton, OttCheckbox},
    props: {
      columnConfig: {
        type: Array,
        required: true
      },
      isDefault: {
        type: Boolean,
        default: false
      },
      containerStyles: {
        type: Object, 
        default: () => null
      }

    },
    data() {
      return {
        columns: []
      };
    },
    methods: {
      updateDataColumn() {
        this.$emit('resetDataSettingsTable')
      },
      sort(){
        let val = this.columnConfig;
        const copy = [].concat(val);
        const resp =  copy.sort(
            function(a, b) {
              if (a.defaultDragIndex === b.defaultDragIndex) {
                // Price is only important when cities are the same
                return a.mainIndex - b.mainIndex;
              }
              return b.defaultDragIndex > a.defaultDragIndex ? -1 : 1;
            });
        this.columns = resp;
        return resp;
      }
    },
    mounted() {
      // this.sort();
    },
    watch: {
      columnConfig(val) {
        //
        // this.sort(val);
      }
    }
  };
</script>
<style scoped lang="scss">
  @import "src/assets/scss/_variables.scss";

  .P-profile-image {
    width     : 35px;
    height    : 35px;
    display   : block;
    font-size : $txt20;
  }

  .P-column-configs {
    padding   : 15px 25px 0px 25px;
    max-width : 800px;

    ul {
      padding   : 0;
      display   : flex;
      flex-wrap : wrap;

      li {
        width         : 33.33%;
        margin        : 0;
        margin-bottom : 10px;
      }

      li::v-deep {
        .v-input {
          margin-top : 0;
        }

        .v-messages {
          display : none;
        }
      }
    }
  }

  .P-reset-column {
    padding : 0 25px 25px 25px;
  }

  .P-icon {
    position   : relative;
    z-index    : 5;
    transition : $transition;
  }

  .P-info-block {
    margin-top : 15px;
  }
</style>
<!--.P-settings {-->
<!--  width: 37px;-->
<!--  height: 37px;-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  justify-content: center;-->
<!--  font-size: 24px;-->
<!--  position: relative;-->
<!--  color: $neutral-color;-->

<!--  &:hover,-->
<!--  &[aria-expanded="true"] {-->
<!--    &:after {-->
<!--      opacity: 1;-->
<!--    }-->
<!--    .P-icon {-->
<!--      color: $secondary-color !important;-->
<!--    }-->
<!--  }-->

<!--  &:after {-->
<!--    background-color: $neutral-color7;-->
<!--    border-radius: 8px;-->
<!--    content: "";-->
<!--    display: inline-block;-->
<!--    height: 100%;-->
<!--    left: 0;-->
<!--    opacity: 0;-->
<!--    pointer-events: none;-->
<!--    position: absolute;-->
<!--    top: 0;-->
<!--    width: 100%;-->
<!--    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);-->
<!--    z-index: 0;-->
<!--  }-->
<!--}-->
