const Clients = () => import("@/views/clients/Clients");
const Payments = () => import("@/views/client-bills/ClientBills");
const Invoices = () => import("@/views/clients/Invoices");
const ClientActivity = () => import("@/views/client-activity/ClientActivity");
const ClientsDeleted = () => import("@/views/clients-deleted/ClientsDeleted");

import store from "@/store";

export const clientRout = {
  path: "/clients",
  redirect: "/",
  name: "Client",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "/clients/list",
      name: "ClientsList",
      component: Clients,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] },
      beforeEnter: (to, from, next) => {
          // reject the navigation
        let permissionObject = store.state.users.permissionsObject;
        let authUserPermission = store.state.auth.user;
        const permissionKey = "getClientList";
        let hasPermission = false;
        if (permissionObject) {
            
            hasPermission = this.permissionsObject.permissions.includes(permissionKey)
        } else {
            hasPermission = authUserPermission.permissions.includes(permissionKey)
        }
        if (!hasPermission) {

          next({ path: '/error/forbidden' });
        } else
        next();
     }
    },
    {
      path: "/clients/payments",
      name: "Payments",
      component: Payments,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    },
    {
      path: "/clients/invoices",
      name: "Invoices",
      component: Invoices,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    },
    {
      path: "/clients/activity",
      name: "ClientActivity",
      component: ClientActivity,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    },
    {
      path: "/clients/deleted",
      name: "ClientsDeleted",
      component: ClientsDeleted,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    }
  ]
};
