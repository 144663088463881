import RequestService from "@/services/request.service";

const InitialState = {
  ivr: {
    ivrToken: ''
  },
  ring: {
    ringToken: ''
  },
  check: {
    checkToken: '',
    secretKey: '',
    bankAccount: '',
    templateName: '',
    companyAddress: '',
    companyName: '',
    routingNumber: '',
    signatureImage: '',
    isValid: null
  },
  smartStreet: {
    smartStreetKey: "",
    smartyStreetAuthId: "",
    smartyStreetAuthToken: "",
    isValid: null
  },
  postalMethods: {
    postalMethodsKey: "",
    isValid: null
  },
  taxJar: {
    apiToken: "",
    isValid: null
  },
  twilio: {
    sId: "",
    authToken: "",
    fromNumber: ""
  },

  isEditIvr: false,
  isEditRing: false,
  isEditCheck: false,
  isEditSmartStreet: false,
  isEditPostalMethods: false,
  isEditTaxJar: false,
  isEditTwilio: false,
  cloneIvrData: JSON.parse(
    JSON.stringify({
      ivrToken: ''
    })
  ),
  cloneRingData: JSON.parse(
    JSON.stringify({
      ringToken:''
    })
  ),
  cloneSmartStreetData: JSON.parse(
    JSON.stringify({
      smartStreetKey: ''
    })
  ),
  clonePostalMethod: JSON.parse(
    JSON.stringify({
      postalMethodsKey: ''
    })
  ),
  cloneTaxJar: JSON.parse(
    JSON.stringify({
      apiToken: ''
    })
  ),
  cloneTwilio: JSON.parse(
    JSON.stringify({
      sId: '',
      authToken: '',
      fromNumber: ''
    })
  ),
  cloneCheckData: JSON.parse(
    JSON.stringify({
      checkToken: '',
      secretKey: '',
      bankAccount: '',
      templateName: '',
      companyAddress: '',
      companyName: '',
      routingNumber: '',
      signatureImage: '',
    })
  ),
  toSaveData: {},
};
export const otherApiIntegration = {
  namespaced: true,
  actions: {
    updateOtherApiIntegration({commit, state, rootState, dispatch}, providerId) {
      return RequestService.update(
        `ottProviders/otherApis/edit/${providerId}`,
        state.toSaveData
      ).then(
        async data => {
          commit("fetchData", data.data);
          // await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    getOtherApiIntegration({state}, providerId) {
      return RequestService.read(
        `ottProviders/otherApis/edit/${providerId}`
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    signViaMobile({state}, { providerId, phone }) {
      return RequestService.update('ottProviders/otherApis/smsCheckeeperSignature/'+providerId, { phone })
    }
  },
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },
    /**
     * to save data for add
     * **/

    toSaveData(state, providerId) {
      state.toSaveData.providerId = providerId

      if (state.ivr.ivrToken) {
        state.toSaveData.ivr = {apiToken: state.ivr.ivrToken}
      }
      if (state.ring.ringToken) {
        state.toSaveData.ring = {apiToken: state.ring.ringToken}
      }
      // if (state.taxJar.apiToken) {
        state.toSaveData.taxJar = {apiToken: state.taxJar.apiToken}
      // }
  

      state.toSaveData.checkeeper = {
        apiToken: state.check.checkToken,
        secret: state.check.secretKey,
        bankAccount: state.check.bankAccount,
        templateName: state.check.templateName,
        companyAddress: state.check.companyAddress,
        companyName: state.check.companyName,
        routingNumber: state.check.routingNumber,
        signatureImage: state.check.signatureImage,
      }

      // if (state.smartStreet.smartStreetKey) {
        state.toSaveData.smarty = {key: state.smartStreet.smartStreetKey}
      // }
      // if (state.postalMethods.postalMethodsKey) {
        state.toSaveData.postal = {secretKey: state.postalMethods.postalMethodsKey}
      // }
    },

    /**
     * fetch data from api
     * **/
    fetchData(state, data) {
      if (data.ivr) {
        state.ivr.ivrToken = data.ivr.apiToken
        state.isEditIvr = true
      }
      if (data.ring) {
        state.ring.ringToken = data.ring.apiToken
        state.isEditRing = true

      }
      if (data.taxJar) {
        state.taxJar.apiToken = data.taxJar.apiToken
        state.taxJar.isValid = data?.taxJar?.isValid
      }
      if (data.checkeeper) {
        if ('apiToken' in data.checkeeper) {
          state.check.checkToken = data.checkeeper.apiToken;
        }
        if ('secret' in data.checkeeper) {
          state.check.secretKey = data.checkeeper.secret;
        }
        if ('bankAccount' in data.checkeeper) {
          state.check.bankAccount = data.checkeeper.bankAccount;
        }
        if ('templateName' in data.checkeeper) {
          state.check.templateName = data.checkeeper.templateName;
        }
        if ('companyAddress' in data.checkeeper) {
          state.check.companyAddress = data.checkeeper.companyAddress;
        }
        if ('companyName' in data.checkeeper) {
          state.check.companyName = data.checkeeper.companyName;
        }
        if ('routingNumber' in data.checkeeper) {
          state.check.routingNumber = data.checkeeper.routingNumber;
        }
        if ('signatureImage' in data.checkeeper) {
          state.check.signatureImage = data.checkeeper.signatureImage;
        }
        if ('isValid' in data.checkeeper) {
          state.check.isValid = data.checkeeper.isValid;
        }
  
        state.isEditCheck = true

      }

      if (data.smarty) {
        state.smartStreet.smartStreetKey = data.smarty.key
        state.isEditSmartStreet = true
        state.smartStreet.isValid = data.smarty?.isValid
      }
      if (data.postal) {
        state.postalMethods.postalMethodsKey = data.postal.secretKey
        state.isEditPostalMethods = true
        state.postalMethods.isValid = data.postal.isValid
      }
    },

    //IVR
    saveIvrData(state, data) {
      state.ivr = data;
      state.cloneIvrData = JSON.parse(JSON.stringify(data));
      state.isEditIvr = true;
    },
    cancelIvrData(state) {
      if (state.cloneIvrData) {
        state.ivr = JSON.parse(JSON.stringify(state.cloneIvrData));
      }
    },

    //TaxJar
    saveTaxJarData(state, data) {
      state.taxJar = data;
      state.cloneTaxJar = JSON.parse(JSON.stringify(data));
      state.isEditTaxJar = true;
    },
    cancelTaxJar(state) {
      if (state.cloneTaxJar) {
        state.taxJar = JSON.parse(JSON.stringify(state.cloneTaxJar));
      }
    },

    //Ring
    saveRingData(state, data) {
      state.ring = data;
      state.cloneRingData = JSON.parse(JSON.stringify(data));
      state.isEditRing = true;
    },
    cancelRingData(state) {
      if (state.cloneRingData) {
        state.ring = JSON.parse(JSON.stringify(state.cloneRingData));
      }
    },

    //Checkeeper
    saveCheckData(state, data) {
      state.check = data;
      state.cloneCheckData = JSON.parse(JSON.stringify(data));
      state.isEditCheck = true;
    },
    cancelCheckData(state) {
      if (state.cloneCheckData) {
        state.check = JSON.parse(JSON.stringify(state.cloneCheckData));
      }
    },


    // -------------ivr
    setIvrToken(state, data) {
      state.ivr.ivrToken = data;
    },
    saveIvr(state, data) {
      state.ivr.isSave = !state.ivr.isSave;
    },
    // -------------ring
    setRingToken(state, data) {
      state.ring.ringToken = data;
    },

    // ----------------check
    setCheckToken(state, data) {
      state.check.checkToken = data;
    },
    setSecretKey(state, data) {
      state.check.secretKey = data;
    },
    setCheckData(state, data) {
      for (const key in data) {
        state.check[key] = data[key]
      }
    },
    // ---------------- smartStreet
    setSmartStreet(state, value) {
      state.smartStreet.smartStreetKey = value;
    },
    setSmartyStreetAuthId( state, value) {
      state.smartStreet.smartyStreetAuthId = value;
    },
    setSmartyStreetAuthToken( state, value) {
      state.smartStreet.smartyStreetAuthToken = value;
    },
    saveSmartStreetData(state, data) {
      state.smartStreet = data;
      state.cloneSmartStreetData = JSON.parse(JSON.stringify(data));
      state.isEditSmartStreet = true;
    },
    cancelSmartStreetData(state) {
      if (state.cloneSmartStreetData) {
        state.smartStreet = JSON.parse(
          JSON.stringify(state.cloneSmartStreetData)
        );
      }
    },
    // ---------------- postal methods
    setPostalMethod(state, value) {
      state.postalMethods.postalMethodsKey = value;
    },
    savePostalMethod(state, data) {
      state.postalMethods = data;
      state.clonePostalMethod = JSON.parse(JSON.stringify(data));
      state.isEditPostalMethods = true;
    },
    cancelPostalMethod(state) {
      if (state.clonePostalMethod) {
        state.postalMethods = JSON.parse(
          JSON.stringify(state.clonePostalMethod)
        );
      }
    },
    resetData(state) {
      state.ivr.ivrToken = null;
      state.ring.ringToken = null;
      state.check.checkToken = '';
      state.check.secretKey = '';
      state.check.bankAccount = '',
      state.check.templateName = '',
      state.check.companyAddress = '',
      state.check.companyName = '',
      state.check.routingNumber = '',
      state.check.signatureImage = '',
      state.taxJar.apiToken = '';
      state.cloneIvrData = null;
      state.cloneRingData = null;
      state.cloneCheckData = null;
      state.cloneTwilio = null;
      state.isEditTaxJar = false;
      state.isEditTwilio = false;
      state.isEditIvr = false;
      state.isEditRing = false;
      state.isEditCheck = false;
      state.isEditSmartStreet = false;
      state.isEditPostalMethods = false;
      state.toSaveData = {}
      state.smartStreet.smartStreetKey = ''
      state.smartStreet.smartyStreetAuthId = ''
      state.smartStreet.smartyStreetAuthToken = ''
      state.smartStreet.isValid = null
      state.postalMethods.postalMethodsKey = ''
      state.postalMethods.isValid = null
      state.taxJar.apiToken = ''
      state.taxJar.isValid = null
    },

    setIsEditIvr(state) {
      state.isEditIvr = true;
    },
    setIsEditRing(state) {
      state.isEditRing = true;
    },
    setIsEditTaxJar(state) {
      state.isEditTaxJar = true;
    },
    setIsEditTwilio(state) {
      state.isEditTwilio = true;
    },
    setIsEditCheck(state) {
      state.isEditCheck = true;
    },
    seIisEditSmartStreet(state) {
      state.isEditSmartStreet = true;
    },
    setIsEditPostalMethods(state) {
      state.isEditPostalMethods = true;
    },
    setTaxJarApiToken(state, value){
      state.taxJar.apiToken = value
    },
    setTwilioSID(state, value){
      state.twilio.sId = value
    },
    setTwilioAuthToken(state, value){
      state.twilio.authToken = value
    },
    setTwilioFromNumber(state, value){
      state.twilio.fromNumber = value
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
