/**
 * Colors objects for  change vueitify
 * main colors style
 * **/

const primary = {
  primaryMain: "#01B2B8",
  primaryDark: "#01B2B8",
  primaryLight: "#7FD8DB"
};
const secondary = {
  secondaryMain: "#0A3C68",
  secondaryDark: "#012F57",
  secondary66: "#114979",
  secondary07: "#5881A5",
  secondaryLight: "#8AAAC6"
};
const constColors = {
  white: "#FFFFFF",
  black: "#000000"
};
const neutral = {
  neutralMain: "#a5aac6",
  neutralMainDark: "#9AA0A6",
  neutralColor75: "#BBBFD4",
  neutralColor45: "#D6D8E5",
  neutralColor25: "#E8EAF1",
  neutralColor15: "#F3F4F7",
  neutralColor15DM: "#494A4D",
  neutralColor10: "#F5F6F8",
  neutralColor7: "#F8F8FA",
  neutralColorLink: "#8181a2"
};
const danger = {
  dangerLight: "#FF5B5B",
  dangerDark: "#F53A3A"
};
const badge = {
  lightBlueDarker: "#66D4FF",
  lightBlueLighter: "#E0F7FF",
  greenDarker: "#B8E6CA",
  greenLighter: "#FEE1E1",
  grayLighter: "#F5F5FE",
  grayDarker: "#DADBEB",
  redLighter: "#FEE1E1",
  redDarker: "#FCA7A7",
  purpleDarker: "#E68EFF",
  purpleLighter: "#F7D9FF"
};

const darkColors = {
  secondaryMainDark: "#303134",
  secondary66Dark: "#3A3B3F",
  darkBold: "#202124",
  darkColorLink: "#AEB6BD"
};
const other = {
  green: "#5FC788",
  red: "#FF6969",
  redMD: "#F43A5C",
  modalBackgroundDM: "#3C4043",
  modalBackgroundLM: "#FFFFFF",
  mainBackground: "#F6F6F9",
  mainBackgroundDM: "#202124",
  tooltipBgColor: "#747474",
  tooltipBgColorDM: "#3B3D40"
};
export default {
  primary,
  secondary,
  constColors,
  neutral,
  danger,
  badge,
  darkColors,
  other
};
