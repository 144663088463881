import {mapActions, mapMutations, mapState} from "vuex"
import { eventBus } from "@/main";
import _ from "lodash";

export default {

    name: 'ClientTransactions',

    data() {
        return {
            defaultColumnConfigData: [],
            columnConfigData: [],
            actions: [
                {
                    title: 'View',
                    value: 'view'
                },
                {
                    title: 'Print',
                    value: 'print'
                },
                {
                    title: 'Send',
                    value: 'send'
                },
                {
                    title: 'Void',
                    value: 'void'
                },
            ],
            showWarningModal: false,
            warningModalBtnText: '',
            warningModalIsDangerButton: false,
            warningModalIsLoading: false,
            warningModalType: '',
            selectedTransactionNumber: null,
            sendType: '',
            transactionSendType: {
                type: null,
                value: null
            },
            transactionSendTypes: [],
            selectedTransactionId: null,
            selectedTransactionClient: null,
            loading: false,
            sendPhoneNumber: '',
            disableSend: false, 
            validPhoneNumber: true,
            isValidateNumber: true,
            showPhoneError: false,
        }
    },

    computed: {
        ...mapState({
            filterModel: state => state.transactionsModule.filterModel,
            transactionsModule: state => state.transactionsModule,
            amountSum: state => state.transactionsModule.amountSum,

            // important variables vor get list and  change  columns
            filterData: state => state.transactionsModule.filterData,
            filter: state => state.transactionsModule.filter,
            dataTable: state => state.transactionsModule.dataTable,
            defaultSettings: state => state.transactionsModule.defaultSettings,
            updateColumnSettingsCommit: state => state.transactionsModule.updateColumnSettingsCommit,
            updateColumnsFromBack: state => state.transactionsModule.updateColumnsFromBack,
            isDefault: state => state.transactionsModule.isDefault,
            showColumnConfig: state => state.transactionsModule.showColumnConfig,
            resellersList: state => state.providers.resellersList,
            allClientPhones: state => state.contactClient.allPhones,
            authProvider: state => state.auth.user.provider.id,
        }),

        defaultColumnConfigs() {
            return [
                {
                    title:'General',
                    isShow: true,
                    isDrag: true,
                    hideFlag:false,
                    defaultDragIndex: 0,
                    mainIndex: 0,
                    subList: [
                        {
                            title:'Transaction ID',
                            isTranslate: false,
                            style: {minWidth: 140},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "transactionId",
                                sortValue: ""
                            },
                            key: "number",
                            "defaultHide": true,
                            "defaultDragIndex": 0,
                            "mainIndex": 0,
                            cellView: row => `<p class="G-text-table">${row.number}</p>`
                        },
                        {
                            title:'Date/Time',
                            isTranslate: false,
                            style: {minWidth: 200},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "executionDate",
                                sortValue: ""
                            },
                            key: "executionDate",
                            "defaultHide": true,
                            "defaultDragIndex": 1,
                            "mainIndex": 1,
                            cellView: row => `<p class="G-text-table">${ row.executionDate }</p>`
                        },
                        {
                            title:'Status',
                            isTranslate: false,
                            style: {minWidth: 130},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "transactionStatus",
                                sortValue: ""
                            },
                            key: "state",
                            "defaultHide": true,
                            "defaultDragIndex": 2,
                            "mainIndex": 2,
                            cellView: row => `<p class="G-text-table">${ this.getStatus(row.state) }</p>`
                        },
                        {
                            title:'Transaction Type',
                            isTranslate: false,
                            style: {minWidth: 240},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "transactionType",
                                sortValue: ""
                            },
                            key: "transaction_type",
                            "defaultHide": true,
                            "defaultDragIndex": 3,
                            "mainIndex": 3,
                            cellView: row => `
                            <p class="G-text-table">
                                ${
                                    row.transaction_type === 'B_TO_B' ? 'Balance Transfer' :
                                    row.transaction_type === 'TO_B' ? 'Balance Fill' :
                                    row.transaction_type === 'CASH' ? 'Cash' :
                                    row.transaction_type === 'CH_TO_B' ? 'Check' :
                                    row.transaction_type === 'MO_TO_B' ? 'Money Order' :
                                    row.transaction_type === 'C_TO_A' ? 'Credit Card/Bank Authorize' : row.transaction_type
                                }
                            </p>`
                        },
                        {
                            title:'From',
                            isTranslate: false,
                            style: {minWidth: 220},
                            isShow: true,
                            isDrag: true,
                            key: "initiatorFromName",
                            "defaultHide": true,
                            "defaultDragIndex": 4,
                            "mainIndex": 4,
                            cellView: row => `<p class="G-text-table">${ row.from_type === 1 ? row.from_provider?.name[0]?.name : row.from_client?.personalInfo?.firstname + ' ' + row.from_client?.personalInfo?.lastname}</p>`
                        },
                        {
                            title:'To',
                            isTranslate: false,
                            style: {minWidth: 220},
                            isShow: true,
                            isDrag: true,
                            key: "initiatorToName",
                            "defaultHide": true,
                            "defaultDragIndex": 5,
                            "mainIndex": 5,
                            cellView: row => `<p class="G-text-table">${ row.to_type === 1 ? row.to_provider.name[0].name : row.to_client?.personalInfo?.firstname + ' ' + row.to_client?.personalInfo?.lastname }</p>`
                        },
                        {
                            title:'Amount',
                            isTranslate: false,
                            style: {minWidth: 110},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "amount",
                                sortValue: ""
                            },
                            key: "amount",
                            "defaultHide": true,
                            "defaultDragIndex": 6,
                            "mainIndex": 6,
                            cellView: row => `<p class="G-text-table">
                                ${ this.numberToFinanceNumber(row.amount, { redText: row.state === 0 || row.state === 4 || row.state === 5 })}
                            </p>`
                        },
                        {
                            title:'Fees',
                            isTranslate: false,
                            style: {minWidth: 110},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "fee",
                                sortValue: ""
                            },
                            key: "fee",
                            "defaultHide": true,
                            "defaultDragIndex": 7,
                            "mainIndex": 7,
                            cellView: row => `<p class="G-text-table">
                            ${ this.numberToFinanceNumber(row.fee, { redText: row.state === 0 || row.state === 4 || row.state === 5 })}
                            </p>`
                        },
                        {
                            title:'Total Amount',
                            isTranslate: false,
                            style: {minWidth: 110},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "totalAmount",
                                sortValue: ""
                            },
                            key: "totalAmount",
                            "defaultHide": true,
                            "defaultDragIndex": 8,
                            "mainIndex": 8,
                            cellView: row => `<p class="G-text-table">
                            ${ this.numberToFinanceNumber(row.totalAmount, { redText: row.state === 0 || row.state === 4 || row.state === 5 })}
                            </p>`
                        },
                        {
                            title:'Pay Number',
                            isTranslate: false,
                            style: {minWidth: 150},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "payNumber",
                                sortValue: ""
                            },
                            key: "payNumber",
                            "defaultHide": true,
                            "defaultDragIndex": 9,
                            "mainIndex": 9,
                            cellView: row => {
                                let payNumber = '-'

                                switch(row?.transaction_type) {
                                    case 'CH_TO_B':
                                    case 'MO_TO_B':
                                        payNumber = `<p class="G-text-table">${ row?.sourcePay?.number } ${ row?.sourcePay?.bankName ? row?.sourcePay?.bankName : ''}</p>` // showing check/money-order number
                                    break;
                                    
                                    case 'C_TO_A':
                                        if (row?.sourcePay?.brand === 'bank') { // getting bank name with bank icon
                                            payNumber = `
                                                <div class="G-align-center">
                                                    <svg class="P-bank-account-icon">
                                                        <use xlink:href="/sprite.svg#bank-icon"></use>
                                                    </svg>
                        
                                                    <span class="ms-0">${ row?.sourcePay?.name }</span>
                                                </div>
                                            `
                                        } 
                                        
                                        if (row?.sourcePay?.brand !== 'bank') {  // getting card name with card icon
                                            let cardNumber = row?.sourcePay?.cardNumber
                                            payNumber = `
                                                <div class="G-align-center"> 
                                                    ${ row?.sourcePay?.brand ? `<div class="P-credit-card-image me-1" style="background-image: url(${ this.getCardBrand(row?.sourcePay?.brand) })"></div>` : ' '}
                                                    ${ typeof cardNumber === 'string' ? `<span class="ms-0 G-text-table">${ cardNumber.slice(-4) }</span>` : '' }
                                                </div>
                                            `
                                        }
                                    break;    
                                }
                                return payNumber
                            }
                        }
                    ]
                },
                {
                    title:'My Balance/Credit State',
                    isShow: true,
                    isDrag: true,
                    hideFlag:false,
                    defaultDragIndex: 1,
                    mainIndex: 1,
                    subList: [
                        {
                            title:'My Balance Before',
                            isTranslate: false,
                            style: {minWidth: 150},
                            isShow: true,
                            isDrag: true,
                            key: "balanceBefore",
                            "defaultHide": true,
                            "defaultDragIndex": 0,
                            "mainIndex": 8,
                            cellView: row => `<p class="G-text-table">${ 
                                typeof row.balanceBefore !== 'undefined' 
                                    ? this.numberToFinanceNumber(row.balanceBefore, { redText: row.state === 0 || row.state === 4 || row.state === 5 })
                                    : '-' 
                            }</p>`
                        },
                        {
                            title:'My Balance After',
                            isTranslate: false,
                            style: {minWidth: 150},
                            isShow: true,
                            isDrag: true,
                            key: "balanceAfter",
                            "defaultHide": true,
                            "defaultDragIndex": 1,
                            "mainIndex": 9,
                            cellView: row => `<p class="G-text-table">${
                                typeof row.balanceAfter !== 'undefined' 
                                    ? this.numberToFinanceNumber(row.balanceAfter, { redText: row.state === 0 || row.state === 4 || row.state === 5 })
                                    : '-' 
                            }</p>`
                            
                        },
                        // {
                        //     title:'My Debt ',
                        //     isTranslate: false,
                        //     style: {minWidth: 150},
                        //     isShow: true,
                        //     isDrag: true,
                        //     key: "providerDebt",
                        //     "defaultHide": true,
                        //     "defaultDragIndex": 2,
                        //     "mainIndex": 10,
                        //     cellView: row => `<p class="G-text-table">${ row.provider.length ? row?.provider[0]?.debt : '-' }</p>`
                        // },
                        // {
                        //     title:'My Credit Before',
                        //     isTranslate: false,
                        //     style: {minWidth: 150},
                        //     isShow: true,
                        //     isDrag: true,
                        //     key: "myCreditBefore",
                        //     "defaultHide": true,
                        //     "defaultDragIndex": 3,
                        //     "mainIndex": 11,
                        //     cellView: row => `<p class="G-text-table">${ row.provider.length && row.provider[0].myCreditBefore ? row.provider[0].myCreditBefore : '-' }</p>`
                        // },
                        // {
                        //     title:'My Credit After',
                        //     isTranslate: false,
                        //     style: {minWidth: 150},
                        //     isShow: true,
                        //     isDrag: true,
                        //     key: "myCreditAfter",
                        //     "defaultHide": true,
                        //     "defaultDragIndex": 4,
                        //     "mainIndex": 12,
                        //     cellView: row => `<p class="G-text-table">${ row.provider.length && row.provider[0].myCreditAfter ? row.provider[0].myCreditAfter : '-' }</p>`
                        // },
                        // {
                        //     title:'My Credit End Date/Time',
                        //     isTranslate: false,
                        //     style: {minWidth: 180},
                        //     isShow: true,
                        //     isDrag: true,
                        //     key: "myCreditEndDate",
                        //     "defaultHide": true,
                        //     "defaultDragIndex": 5,
                        //     "mainIndex": 13,
                        //     cellView: row => `<p class="G-text-table">${ row.provider.length && row.provider[0].myCreditEndDate ? row?.provider[0]?.myCreditEndDate : '-' }</p>`
                        // }
                    ]
                },
                {
                    title:'Bill',
                    isShow: true,
                    isDrag: true,
                    hideFlag:false,
                    defaultDragIndex: 2,
                    mainIndex: 2,
                    subList:  [
                        {
                            title:'Bill/Invoice N',
                            isTranslate: false,
                            style: {minWidth: 150},
                            isShow: true,
                            isDrag: true,
                            key: "invoicesNumber",
                            "defaultHide": true,
                            "defaultDragIndex": 0,
                            "mainIndex": 14,
                            cellView: row => row.invoice?.number ? `<a target='blank' href='${process.env.VUE_APP_API_URL}invoices/view/${row.invoice.id}?type=1'>${ row.invoice?.number}</a> ${row.isRefund? 'refund' : ''}` : `<p class="G-text-table">N/A</p>`
                            // cellView: row => row.invoice?.number ? `<a target='blank'>${ row.invoice?.number}</a>` : `<p class="G-text-table">N/A</p>`
                        },
                        // {
                        //     title:'Generation Date/Time',
                        //     isTranslate: false,
                        //     style: {minWidth: 180},
                        //     isShow: true,
                        //     isDrag: true,
                        //     key: "invoicesStartDate",
                        //     "defaultHide": true,
                        //     "defaultDragIndex": 1,
                        //     "mainIndex": 15,
                        //     cellView: row => `<p class="G-text-table">${ row.invoices && row.invoices.length ? row.invoices[0].startDate : '-' }</p>`
                        // },
                        {
                            title:'Merchant Transaction ID',
                            isTranslate: false,
                            style: {minWidth: 200},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "merchantTransactionId",
                                sortValue: ""
                            },
                            key: "transactionId",
                            "defaultHide": true,
                            "defaultDragIndex": 2,
                            "mainIndex": 16,
                            cellView: row => `<p class="G-text-table">${row.transactionId ? row.transactionId : 'N/A'}</p>`
                        },
                        {
                            title:'State Message',
                            isTranslate: false,
                            style: {minWidth: 480},
                            isShow: true,
                            isDrag: true,
                            key: "stateMessage",
                            "defaultHide": true,
                            "defaultDragIndex": 3,
                            "mainIndex": 17,
                            cellView: row => `<p class="G-text-table">${row.stateMessage ? row.stateMessage : '-'}</p>`
                        },
                    ]

                },
                {
                    title:'Initiator',
                    isShow: true,
                    isDrag: true,
                    hideFlag:false,
                    defaultDragIndex: 3,
                    mainIndex: 3,
                    subList:  [
                        {
                            title:'Source Type',
                            isTranslate: false,
                            style: {minWidth: 200},
                            isShow: true,
                            isDrag: true,
                            key: "source_type",
                            "defaultHide": true,
                            "defaultDragIndex": 0,
                            "mainIndex": 18,
                            cellView: row => `
                            <p class="G-text-table">
                                ${
                                    row.source_type === 'PAY_INVOICE' ? 'Client Made Payment' :
                                    row.source_type === 'EXECUTE_INVOICE' ? 'Buy Package For Client' :
                                    row.source_type === 'ADD_BALANCE' ? 'Balance Fill' :
                                    row.source_type === 'VOID_TRANSACTION' ? 'Void Transaction' :
                                    row.source_type === 'PAY_BALANCE' ? 'Balance Fill (Pay)' : row.source_type
                                }
                            </p>`
                        },
                        {
                            title:'By User',
                            isTranslate: false,
                            style: {minWidth: 200},
                            isShow: true,
                            isDrag: true,
                            sortData: {
                                isSort: true,
                                sortKey: "byUser",
                                sortValue: ""
                            },
                            key: "byUser",
                            "defaultHide": true,
                            "defaultDragIndex": 1,
                            "mainIndex": 19,
                            cellView: row => row.invoice?.user ?
                             `<p class="G-text-table">${row.invoice?.user?.firstname} ${row.invoice?.user?.lastname}</p>` :
                             `<p class="G-text-table">Autopayment</p>`
                        },
                    ]

                },
                {
                    title:'Participant',
                    isShow: true,
                    isDrag: true,
                    hideFlag:false,
                    defaultDragIndex: 4,
                    mainIndex: 4,
                    subList:  [
                        {
                            title:'Logins',
                            isTranslate: false,
                            style: {minWidth: 150},
                            isShow: true,
                            isDrag: true,
                            key: "locationLogin",
                            "defaultHide": true,
                            "defaultDragIndex": 0,
                            "mainIndex": 20,
                            cellView: row => `<p class="G-text-table">${ this.getParticipantLogins(row.invoice) }</p>`
                        },
                        // {
                        //     title:'Credit After',
                        //     isTranslate: false,
                        //     style: {minWidth: 150},
                        //     isShow: true,
                        //     isDrag: true,
                        //     sortData: {
                        //         isSort: true,
                        //         sortKey: "participantCreditAfter",
                        //         sortValue: ""
                        //     },
                        //     key: "creditAfter",
                        //     "defaultHide": true,
                        //     "defaultDragIndex": 2,
                        //     "mainIndex": 21,
                        //     cellView: row => `<p class="G-text-table">${ row.creditAfter ? row.creditAfter : '-' }</p>`
                        // },
                        // {
                        //     title:'Credit End Date',
                        //     isTranslate: false,
                        //     style: {minWidth: 150},
                        //     isShow: true,
                        //     isDrag: true,
                        //     sortData: {
                        //         isSort: true,
                        //         sortKey: "participantCreditEndDate",
                        //         sortValue: ""
                        //     },
                        //     key: "creditEndDate",
                        //     "defaultHide": true,
                        //     "defaultDragIndex": 3,
                        //     "mainIndex": 22,
                        //     cellView: row => `<p class="G-text-table">${ row.creditEndDate ? row.creditEndDate : '-' }</p>`
                        // },
                        // {
                        //     title:'Debt',
                        //     isTranslate: false,
                        //     style: {minWidth: 150},
                        //     isShow: true,
                        //     isDrag: true,
                        //     sortData: {
                        //         isSort: true,
                        //         sortKey: "participantDebt",
                        //         sortValue: ""
                        //     },
                        //     key: "debt",
                        //     "defaultHide": true,
                        //     "defaultDragIndex": 4,
                        //     "mainIndex": 23,
                        //     cellView: row => `<p class="G-text-table">${ row.debt ? row.debt : '-' }</p>`
                        // },
                    ]
                },
                // {
                //     title:'My Incomes/Expenses',
                //     isShow: true,
                //     isDrag: true,
                //     hideFlag:false,
                //     defaultDragIndex: 5,
                //     mainIndex: 5,
                //     subList:  [
                //         {
                //             title:'My Amount',
                //             isTranslate: false,
                //             style: {minWidth: 150},
                //             isShow: true,
                //             isDrag: true,
                //             sortData: {
                //                 isSort: true,
                //                 sortKey: "myAmount",
                //                 sortValue: ""
                //             },
                //             key: "myAmount",
                //             "defaultHide": true,
                //             "defaultDragIndex": 0,
                //             "mainIndex": 24,
                //             cellView: row => `<p class="G-text-table">${row.amount > 0 || row.amount < 0 ? row.amount.toFixed(2) : row.amount}</p>`
                //         },
                //         {
                //             title:'Payment Method',
                //             isTranslate: false,
                //             style: {minWidth: 150},
                //             isShow: true,
                //             isDrag: true,
                //             key: "sourcePay",
                //             "defaultHide": true,
                //             "defaultDragIndex": 1,
                //             "mainIndex": 25,
                //             cellView: row => `<p class="G-text-table">${ row.invoices?.length && row.invoices[0].sourcePay ? row?.invoices[0]?.sourcePay : '-' }</p>`
                //         },
                //     ]

                // },
            ];
        },

        warningModalHeading() {
            return `${this.warningModalBtnText} Transaction?`
        },
      
        warningModalText() {
            return `You're about to <span class='text-lowercase'><ins>${ this.warningModalBtnText }</ins></span> ${ this.selectedTransactionNumber } transaction! <br />  If you're not sure, you can cancel this operation.`
        },
      
        warningModalSendLabel() {
            if (this.transactionSendType.type === 'email_phone' || !this.transactionSendType.type || this.transactionSendType.type === 'email') {
                return 'Please select send type...'
            }

            if (this.transactionSendType.type === 'phone' && (this.validPhoneNumber && this.isValidateNumber)) {
                return 'Please select send type...'
            }

            if (this.transactionSendType.type === 'phone' && (!this.validPhoneNumber || !this.isValidateNumber)) {
                return 'Please update phone number to send...'
            }
        },

        rowsPerPage: {
            set(limit) {
              if (this.$route.name !== 'Search')
                this.setLimit(limit)
              else 
                this.setSearchLimit(limit)
            },
            get() {
              if (this.$route.name !== 'Search')
                return this.filter.limit;
              else  
                return this.filter.searchLimit;
            }
        },

    },

    async created() {
        this.loading = true
        this.filterTransactions()
        this.sortColumn();
        await this.getResellersWithParent({
            parent: true
        })
        if (this.$route.name !== 'Search') {
            await this.getResellersList()
            await this.getTransactionSettings()
        }

        if (this.defaultSettings.length) {
            this.hideShowColumns()
        }

        await this.createTransactionsTable()
        await this.getRegistredPayments()

        if (this.$route.name !== 'Search') {
            await this.getTransactionList(this.filterData).finally(() => this.loading = false)
        }

        this.resetSelectedList()

        this.$hubEventBus.$on("transaction-info", this.handleTransactionInfo); // if loggedIn get permissions with WS
    },

    beforeDestroy() {
        this.$hubEventBus.$off("transaction-info", this.handleTransactionInfo); // if loggedIn get permissions with WS

        this.filterModel.reset()
        this.setData({ filterData: {} })
    },

    destroyed() {
        this.setFilterPage(1)
        this.transactionsResetState()
    },

    mounted() {
        eventBus.$on('resetDataTableSettings', () => {
            this.resetDataTableSettings()
        }) 
    },

    methods: {
        ...mapActions({
            getTransactionList: "transactionsModule/getTransactionList",
            getTransactionSettings: "transactionsModule/getTransactionSettings",
            getResellersList: "providers/getResellersList",
            getResellersWithParent: "providers/getResellersWithParent",
            getRegistredPayments: "paymentGatewayAdmin/getRegistredPayments",
            getClientData: 'clientsModule/getClientData',
            voidTrasnaction: 'transactionsModule/voidTrasnaction',
            sendTransaction: 'transactionsModule/sendTransaction',
            getClientContacts: 'contactClient/getContact',
            addClientPhone: 'contactClient/addContactPhone',
            printTransaction: 'transactionsModule/printTransaction'
        }),
        ...mapMutations({
            setData: 'transactionsModule/setData',
            setLimit: 'transactionsModule/setLimit',
            setSearchLimit: 'transactionsModule/setSearchLimit',
            setFilterPage: 'transactionsModule/setFilterPage',
            sortColumn: 'transactionsModule/sortColumn',
            filterTransactions: 'transactionsModule/filterTransactions',
            billsSetData: "clientBillsModule/setData",
            shippingsSetData: "shippingsModule/setData",
            clientActivitySetData: "clientActivityModule/setData",
            setClientPhones: "contactClient/setAllPhones",
            clientContactSetData: "contactClient/setData",
            transactionsResetState: "transactionsModule/resetState"
        }),

        createToastMessage(response) {
            const toastOptions = {
              msg: response.message,
              position: "toast-bottom-right",
              defaultTimeout: 500
            }
      
            if (response.success) {
              toastOptions.type = "success"
              toastOptions.style = { 
                backgroundColor: "#01B2B8", 
                width: "max-content", 
                textTransform: "capitalize",
                opacity: '1'
              }
      
              this.$toastr.Add(toastOptions);
            } else {
              toastOptions.type = "error",
              toastOptions.style = { 
                backgroundColor: "red", 
                width: "max-content", 
                textTransform: "capitalize",
                opacity: '1'
              }
      
              this.$toastr.Add(toastOptions);
            }
        },

        actionsList(rowData) {
            let actions = _.clone(this.actions),
                isExistSmtp = false,
                isExistTwilio = false;
  
            // Checking is exist Twilio and SMTP
            if (rowData?.from_client?.provider) {
                if ('hasValidTwilio' in rowData?.from_client?.provider) {
                    isExistTwilio = rowData.from_client.provider.hasValidTwilio
                }
        
                if ('hasValidSmtp' in rowData.from_client.provider) {
                    isExistSmtp = rowData.from_client.provider.hasValidSmtp
                }
            }
        
            if (rowData?.to_client?.provider) {
                if ('hasValidTwilio' in rowData?.to_client?.provider) {
                    isExistTwilio = rowData.to_client.provider.hasValidTwilio
                }
                
                if ('hasValidSmtp' in rowData?.to_client?.provider) {
                    isExistSmtp = rowData.to_client.provider.hasValidSmtp
                }
            }
            
            if (!rowData?.voidable) {
                actions = actions.filter(item => item.value !== 'void')
                
            } else {
                actions = actions.filter(item => item.value)
            }

            if (!isExistSmtp && !isExistTwilio) {
                actions = actions.filter(item => item.value !== 'send')
            }
     
            return actions;
        },

        async onInputPhoneNumber(phone, obj) {
            if (this.sendPhoneNumber) {
                this.validPhoneNumber = obj.isValid;  
                // Remove non-alphanumeric characters from the input
                const sanitizedInput = obj.number.input.replace(/[^A-Za-z0-9]/g, '');
                // Check if the sanitized input is a valid number
                this.isValidateNumber = !isNaN(Number(sanitizedInput));

                if (this.transactionSendType.type === 'phone' && (this.validPhoneNumber && this.isValidateNumber)) {
                    this.editClientPhoneWithDuplicate()
                }
            }
        },

        async handleTransactionInfo(transactionInfo) {
            //After send invoice, create toast for show message
            this.createToastMessage( { success: transactionInfo.status, message: transactionInfo.message})
            if (transactionInfo.status && !transactionInfo.isPrint) {
              await this.getTransactionList(this.filterData)
              this.resetSelectedList()
            }
          },
        async createTransactionsTable() {
            let resetDataTable = false;

            if (!this.defaultSettings.length) {
                this.defaultColumnConfigData = this.defaultColumnConfigs.map((item) => Object.assign({}, item));
            }
    
            if (this.defaultSettings.length) {
                this.defaultColumnConfigData = []
                let subList = []
                
                // Checking if defaultColumnConfigs exist removing fist lavel reset column settings
                if (this.defaultSettings.length !== this.defaultColumnConfigs.length) {
                    resetDataTable = true;
                }

                //Compaire defaultColumnConfigs and defaultSettings, get defaultSettings's items isShow key, and defaultColumnConfigs's prototypes
                this.defaultSettings.map((i) => {
                    subList = Object.assign([], i.subList)
                    this.defaultColumnConfigs.map(async (j) => { 

                        // Checking if in defaultColumnConfig's sublists there is exist removing columns reset column settings
                        if (i.title === j.title && subList.length !== j.subList.length) {
                            resetDataTable = true
                        }

                        for (let subIndex = 0; subIndex < subList.length; subIndex++) {
                            for (let jSubIndex = 0; jSubIndex < j.subList.length; jSubIndex++ ) {
                                if (subList[subIndex].mainIndex === j.subList[jSubIndex].mainIndex) {
                                    subList[subIndex] = _.clone(j.subList[jSubIndex])
                                }
                            }
                        }

                        if (i.mainIndex === j.mainIndex) {
                            this.defaultColumnConfigData.push(i);
                            this.defaultColumnConfigData[this.defaultColumnConfigData.length-1].subList = Object.assign([], subList)
                            this.defaultColumnConfigData[this.defaultColumnConfigData.length-1].isShow = i.isShow
                        }
                   })
                })
            }

            if (resetDataTable) {
                await this.resetDataTableSettings()
            }

            this.columnConfigData = [];
            this.columnConfigData = this.OttClone(this.defaultColumnConfigData);
        },

        async resetData() {
            this.filterModel.reset();
            this.filterTransactions()
            await this.getTransactionList(this.filterData)
            this.resetSelectedList()
        },

        async actionClick(action, rowData) {
            this.selectedTransactionNumber = rowData?.number;
            this.selectedTransactionId = rowData.id;
            this.selectedTransactionClient = rowData?.invoice?.client;
            switch(action) {
                case 'print':
                    await this.printTransaction(rowData?.id).then(response => {
                        //After send invoice, create toast for show message
                        this.createToastMessage( { success: response.data.success, message: response.data.message})
                        if (response.data.noSetup) {
                            window.open(`${process.env.VUE_APP_API_URL}transactions/view/${rowData?.id}?type=1`, '_blank');
                        }
                      }).catch((response) => {
                        //After send invoice, create toast for show message
                        this.createToastMessage({ success: false, message: response?.response?.data?.message ?? response.message})
                      })
                    break;
                    case 'view':
                        window.open(`${process.env.VUE_APP_API_URL}transactions/view/${rowData?.id}?type=2&provider=${this.authProvider}`, '_blank');
                        break
                    case 'send':
                        this.warningModalType = 'send';
                        const clientData = await this.getClientData(rowData?.invoice?.client) ?? {}
                        
                        let emails = clientData?.emails ?? []
                        let phones = clientData?.phones ?? []
                        emails = emails.filter(item => item.forContactInvoice)
                        phones = phones.filter(item => item.forMessages)

                        let isExistSmtp = false
                        let isExistTwilio = false
                        
                        /* Checking transaction's clint's provider has SMTP or has Twilio */
                        if (rowData?.from_client?.provider) {
                            if ('hasValidTwilio' in rowData?.from_client?.provider) {
                                isExistTwilio = rowData?.from_client?.provider.hasValidTwilio
                            }
                    
                            if ('hasValidSmtp' in rowData.from_client.provider) {
                                isExistSmtp = rowData.from_client.provider.hasValidSmtp
                            }
                        }
                    
                        if (rowData?.to_client?.provider) {
                            if ('hasValidTwilio' in rowData?.to_client?.provider) {
                                isExistTwilio = rowData?.to_client?.provider.hasValidTwilio
                            }
                            
                            if ('hasValidSmtp' in rowData?.to_client?.provider) {
                                isExistSmtp = rowData.to_client.provider.hasValidSmtp
                            }
                        }
                        /***************************************************************/
                     

                        if (isExistSmtp && isExistTwilio) {
                            this.transactionSendTypes = [
                                {
                                    name: 'Email & Phone',
                                    value: 'email_phone'
                                }
                            ];
                        }

                        if (emails.length && isExistSmtp) {
                            emails.map(item => {
                                this.transactionSendTypes.push({
                                    name: `Email ${item.email}`,
                                    value: `email/${item.email}`
                                })
                            })
                        }

                        if (phones.length && isExistTwilio) {
                            //Setting selected transaction's client's phones
                            this.setClientPhones([...phones])
        
                            phones.map(item => {
                                this.transactionSendTypes.push({
                                    name: `Phone ${item.phone}`,
                                    value: `phone/${item.phone}`
                                })
                            })
                        }

                        this.showWarningModal = true;
                        this.warningModalBtnText = 'Send';
                        this.warningModalIsDangerButton = false;
                    break;
                case 'void':
                    this.warningModalType = 'void';
                    this.showWarningModal = true;
                    this.warningModalBtnText = 'Void';
                    this.warningModalIsDangerButton = true;
                    break;
            }

        },

        async closeWarningModal() {
            this.showWarningModal = false;
            this.warningModalIsLoading = false;
            this.selectedTransactionNumber = null;
            this.sendType = '';
            this.transactionSendType.type = null;
            this.transactionSendType.value = null;
            this.selectedTransactionId = null;
            this.selectedTransactionClient = null;
            this.sendPhoneNumber = '';
            this.validPhoneNumber = true;
            this.isValidateNumber = true;
            this.showPhoneError = false;
            this.disableSend = false;
            this.transactionSendTypes = [];
            // await this.getTransactionList(this.filterData)
        },

        sendTypeSelect() {
            if (this.sendType === 'email_phone') {
                this.transactionSendType.type = 'email_phone'
                this.transactionSendType.value = ''
            }

            if (this.sendType !== 'email_phone') {
                this.transactionSendType.type = this.sendType.split('/')[0]
                this.transactionSendType.value = this.sendType.split('/')[1]

                if (this.transactionSendType.type === 'phone') {
                    this.sendPhoneNumber = this.sendType.split('/')[1]

                    if (!this.isValidateNumber || !this.validPhoneNumber) {
                        this.disableSend = false
                    } else {
                        this.disableSend = true
                    }
                }
            }
        },

        async editClientPhoneWithDuplicate() {
            // !this.isDuplicatedPhone
            if (this.transactionSendType.type === 'phone' && (this.validPhoneNumber && this.isValidateNumber)) {
                let phones = _.clone(this.allClientPhones);

                // Editing phone update in phones list
                if (phones.length) {
                    phones.forEach((item) => {
                        if (item?.phone === this.sendType.split('/')[1]) {
                            item.phone = this.sendPhoneNumber

                            delete item?.updatedAt
                            delete item?.createdAt
                        }
                    });
                }

                // update editing phone in the transactionSendTypes
                this.transactionSendTypes.forEach(item => {
                    if (item.value.split('/')[1] === this.transactionSendType.value) {
                        item.name = `Phone ${this.sendPhoneNumber}`
                        item.value = `phone/${this.sendPhoneNumber}`
                    }
                })

                if (this.selectedTransactionClient) {
                    await this.addClientPhone({ phones, clientId: this.selectedTransactionClient })
                }
            }
          },

        async warningModalBtnFunc() {
            this.warningModalIsLoading = true
            switch (this.warningModalType) {
              case "send":
                const response = await this.sendTransaction({id: this.selectedTransactionId, data: this.transactionSendType})
                    if (response?.data) {
                        this.createToastMessage( { success: response.data.success, message: response.data.message})      
                    }
                this.closeWarningModal()
                break;
              case "void":
                await this.voidTrasnaction(this.selectedTransactionId).finally(() => this.closeWarningModal())
                break;
            }

            this.warningModalIsLoading = false
        },

        async updateTransactionsList(page) {
            if (page) {
                this.setFilterPage(page)
            }
            this.sortColumn();
            await this.getTransactionList(this.filterData)
            this.resetSelectedList()
        },

        async sortData(data) {
        },
        async resetDataTableSettings() {
            this.$store.commit(this.updateColumnSettingsCommit, { defaultSettings: this.defaultColumnConfigs, isDefault: true })
            await this.$store.dispatch(this.updateColumnsFromBack, this.defaultColumnConfigs).then(() => {
                this.createSaveDefaultData();
            })

            await this.getTransactionSettings()
            if (this.defaultSettings.length) {
                this.hideShowColumns()
            }
            await this.createTransactionsTable()
        },
        getStatus(state) {
            switch (state) {
                case 0: {
                    return '<span class="G-badge G-reject-badge G-flex G-align-center">Reject</span>'
                }
                case 1: {
                    return '<span class="G-badge G-approve-badge G-flex G-align-center">Approve</span>'
                }
                case 2: {
                    return '<span class="G-badge G-pending-badge G-flex G-align-center">Pending</span>'
                }
                case 3: {
                    return '<span class="G-badge G-pending-badge G-flex G-align-center">Refunded</span>'
                }
                case 4: {
                    return '<span class="G-badge G-pending-badge G-flex G-align-center">Refund Failed</span>'
                }
                case 5: {
                    return '<span class="G-badge G-reject-badge G-flex G-align-center">Voided</span>'
                }
            }
        },
        async updateLimit() {
            await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
                this.createSaveDefaultData();
            })

            // Get Transactions by filter
            this.setFilterPage(1)
            this.filterTransactions()
            this.sortColumn();
            await this.getTransactionList(this.filterData)
            this.resetSelectedList()
        },
        getBillNumber(invoices) {
            if (invoices && invoices.length) {
                return invoices[0].number
            } else {
                return '-'
            }
        },
        getParticipantLogins(data) {
            if (data) {
                let logins = []
                data.payloadCalculated.locations.map(item => {
                    logins.push(item.locationLogin)
                })
                return logins.toString().split(',').join('<br />')
            } else {
                return '-'
            }
        },
        async sortColumnData(data) {
            this.sortColumn(data)
            await this.getTransactionList(this.filterData)
        },
        async exportData() {
            this.filterTransactions()
            await this.getTransactionList({ ...this.filterData, excel: true })
            setTimeout(() => {
                this.resetSelectedList()
            },0)
        },
        tableSelectAll(selectedAllVisibles = null) {
            this.selectAllVisibles()

            if (selectedAllVisibles !== null && !selectedAllVisibles) {
                this.selectedArrowList = []
                this.selectAllList = false
            }
        },
        hoverColumnConfig() {
            if (this.$route.name === 'Search') {
                this.billsSetData({ showColumnConfig: false })
                this.shippingsSetData({ showColumnConfig: false })
                this.clientActivitySetData({ showColumnConfig: false })
            }
        },
        leaveColumnConfig() {
            if (this.$route.name === 'Search') {
                this.billsSetData({ showColumnConfig: true })
                this.shippingsSetData({ showColumnConfig: true })
                this.clientActivitySetData({ showColumnConfig: true })
            }
        },

        hideShowColumns() {
            this.defaultSettings.map((item) => {
                if (item.title === 'My Balance/Credit State') {
                    if (this.$route.name === 'Search') {
                        item.unshowForSearch = true 
                        item.isShow = false
                        item.disabled = true
                    } else {
                        if (item?.unshowForSearch) {
                            item.isShow = true
                            item.disabled = false
                        }
                    }
                }
            })

            this.defaultColumnConfigs.map((item) => {
                if (item.title === 'My Balance/Credit State') {
                    if (this.$route.name === 'Search') {
                        item.subList = item.subList.map(subItem => ({ ...subItem, isShow: false }))
                    } else {
                        if (item?.unshowForSearch) {
                            item.subList = item.subList.map(subItem => ({ ...subItem, isShow: true }))
                        }
                    }
                }
            })
        },
    }
}
