import RequestService from "@/services/request.service"
import { combineLatest } from "rxjs";

const InitialState = {
  isMinimize: false,
  isFullHide: false,
  themeDark: false,
  activeIndex: null,
  balance: 0,
  notificationAudio: new Audio('/sounds/notification.mp3'),
  notificationAlert: new Audio('http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3'),
  notificationVolume: 1,
  isMuted: false,
};
export const sidebar = {
  namespaced: true,
  state: InitialState,
  mutations: {
    setData(state, data) {
      for (let key in data) {
        state[key] = data[key]
      }
    },
    toggleMinimize(state, minimize) {
      state.isMinimize = minimize;
    },
    toggleFullHide(state) {
      state.isFullHide = !state.isFullHide;
    },
    setActiveIndex(state, value) {
      state.activeIndex = value
    },    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  },
  actions: {
    updateSettings({ state }) {
      return RequestService.update(`users/settings`, {
        manuSettings: {
          isMinimize: state.isMinimize,
          isFullHide: state.isFullHide,
          themeDark: state.themeDark,
          notificationVolume: state.notificationVolume,
          isMuted: state.isMuted,
        } 
      }).catch((e) => console.log(e))
    },

    getSettings({ commit, state }) {
      return RequestService.read('users/settings').then((r) => {
        if (!!r.data.manuSettings) {
          commit('setData', { 
            isMinimize: r.data.manuSettings.isMinimize,
            isFullHide: r.data.manuSettings.isFullHide,
            themeDark:  r.data.manuSettings?.themeDark,
          })

          if ('notificationVolume' in r.data.manuSettings) {
            commit('setData', { 
              notificationVolume: r.data.manuSettings.notificationVolume 
            })

            state.notificationAudio.volume = r.data.manuSettings?.notificationVolume // Set notification audio volume
            state.notificationAlert.volume = r.data.manuSettings?.notificationVolume // Set notification alert volume
          }

          if ('isMuted' in r.data.manuSettings) {
            commit('setData', { isMuted: r.data.manuSettings.isMuted })

            state.notificationAudio.muted = r.data.manuSettings?.isMuted // Set notification audio mute
            state.notificationAlert.muted = r.data.manuSettings?.isMuted // Set notification alert mute
          }
        }
      })
    }
  }
};
