<template>
    <div class="P-filters">
        <div class="P-input-block">
            <ott-input
                v-model="filterModel.search"
                @timeoutInput="filterData('search')"
                :timeoutInput="true"
                label="Search by company name"
                prependInnerIcon="mdi mdi-magnify  neutral--text"
            ></ott-input>
        </div>
        <div class="P-input-block">
            <ott-select
                v-model="filterModel.state"
                :items="statusList"
                :clear="true"
                @emitFunc="filterData('state')"
                label="Status"
                selected-text="name"
            >
            </ott-select>
        </div>
        <div class="P-input-block">
            <ott-range-select
                v-model="filterModel.channelsCount"
                :min="channelsOptions[0]"
                :max="channelsOptions[1]"
                @updateRange="filterData"
                @clearData="resetData('channelsCount')"
                label="Channels count "
            ></ott-range-select>
        </div>
        <h6 class="secondary--text">Client Count</h6>
        <div class="amount-client-list G-flex G-flex-wrap G-justify-between">
            <div
                v-for="(list, index) of clientsCount"
                :key="index"
                class="padding-box"
            >
            <span class="amount-box secondary--text" :class="{'active-amount': filterModel.clientsCount === index}" @click="selectAmount(index)">
              {{ list.value }}
            </span>
            </div>
        </div>
        <div class="date-picker-filter G-date-picker P-provider-date">
            <DatePicker
                v-model="filterModel.date"
                :masks="{ input: ottDateFormat }"
                :value="filterModel.date"
                :max-date="newDateWithMainUserTZ"
                :is-dark="$vuetify.theme.dark"
                @input="filterData"
                is-range
                locale="en"
                class="ott-date-picker"
            >
                <template v-slot="{ inputValue, inputEvents }">
                    <div class="ott-date-picker-cont Ott-input-class:">
                        <div class="G-calendar-input">
                            <v-text-field
                                v-model="inputValue.start"
                                v-on="inputEvents.start"
                                outlined
                                dense
                                height="38"
                                label="Start"
                            />
                            <span
                                class="P-date-icon neutral--text"
                                @click="resetData('date')"
                                :class="
                                  !inputValue.start
                                    ? 'mdi mdi-calendar-blank-outline P-calendar'
                                    : 'icon-cancel'
                                "
                            ></span>
                        </div>
                        <span class="pick-to neutral--text">To</span>
                        <div class="G-calendar-input">
                            <v-text-field
                                v-model="inputValue.end"
                                v-on="inputEvents.end"
                                outlined
                                dense
                                height="38px"
                                label="End"
                            />
                            <span
                                @click="resetData('date')"
                                :class="
                                    !inputValue.start
                                    ? 'mdi mdi-calendar-blank-outline P-calendar'
                                    : 'icon-cancel'
                                "
                                class="P-date-icon neutral--text"
                            ></span>
                        </div>
                    </div>
                </template>
            </DatePicker>
        </div>
        <SelectedFiltersList v-if="filterModel.filterCount.length" @update="filterData" />
    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";

    // mixins
    import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
    import DateFormatMixin from "@/mixins/DateFormatMixin";
    import FilterDataMixin from "@/views/clients/components/FilterDataMixin";
    // components
    import OttInput from "@/components/vuetifyComponents/OttInput";
    import OttSelect from "@/components/vuetifyComponents/OttSelect";
    import OttRangeSelect from "@/components/customComponents/OttRangeSelect";
    import SelectedFiltersList from "./SelectedFiltersList";

    export default {
        name: "FilterData",
        components: {SelectedFiltersList, OttRangeSelect, OttSelect, OttInput},
        mixins: [DateFormatMixin, FilterDataMixin, ConvertTimeZoneMixin],

        data () {
            return {
                channelsOptions: [5, 5000]
            }
        },
        computed: {
            ...mapState({
                filterModel: (state) => state.review.filterModel,
                filter: (state) => state.review.filterData,
                statusList: (state) => state.review.statusList,
                clientsCount: (state) => state.review.clientsCount
            })
        },
        methods: {
            ...mapActions({
                getReviewList: 'review/getReviewList'
            }),
            ...mapMutations({
                filterReviews: 'review/filterReviews',
                setFilterPage: 'review/setFilterPage',
                sortColumn: 'review/sortColumn'
            }),
            async filterData(type = null) {
                switch (type) {
                    case 'search': {
                        if (!this.filterModel.search) {
                            this.filterModel.resetCurrentData(type)
                        }
                        break
                    }
                    case 'state': {
                        if (!this.filterModel.state) {
                            this.filterModel.resetCurrentData(type)
                        }
                        break
                    }
                    case 'channelsCount': {
                        if (!this.filterModel.channelsCount) {
                            this.filterModel.resetCurrentData(type)
                        }
                        break
                    }
                    case 'date': {
                        if (!this.filterModel.date) {
                            this.filterModel.resetCurrentData(type)
                        }
                        break
                    }
                }

                this.filterModel.getFilterCount()
                this.filterReviews()
                this.setFilterPage(1)
                this.sortColumn();
                await this.getReviewList(this.filter)
                this.$emit('resetSelectedList')
            },
            async selectAmount(index) {
                this.filterModel.clientsCount = index
                this.filterModel.getFilterCount()
                this.filterReviews()
                this.setFilterPage(1)
                await this.getReviewList(this.filter)
                this.$emit('resetSelectedList')
            },
            async resetData(type) {
                this.filterModel.resetCurrentData(type)
                this.filterReviews()
                this.filterModel.getFilterCount()
                await this.getReviewList(this.filter)
                this.$emit('resetSelectedList')
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "src/assets/scss/variables";

    .P-filters {
        .P-input-block,
        .amount-client-list {
            margin-bottom: 20px;
        }

        .amount-client-list {
            margin-top: 20px;
        }
    }


    .padding-box {
        width: 30%;
        padding: 0;
        margin-bottom: 10px;
    }

    .amount-box {
        border-radius: 17px;
        padding: 7px;
        text-align: center;
        display: block;
        box-shadow: $box-shadow;
        font-size: $txt14;
        cursor: pointer;
        transition: 0.3s;

        &:hover,
        &.active-amount {
            background-color: $primary-color !important;
            color: $white !important;
        }
    }

    h6 {
        font-size: 1rem;
        font-weight: 600;
    }

    ::v-deep {
        .v-input__slot,
        .v-input--dense>.v-input__control>.v-input__slot,
        .range-select {
            margin-bottom: 0;
        }
        .v-text-field__details {
            display: none;
        }
    }
</style>