import RequestService from "@/services/request.service";

const InitialState = {
    formData: {
        equipment: '', 
        serialN: '' ,
        macAddress: '',
        price: '',
        comment: '',
        description: '',
        piece: null
    },
    isLoading: false,
    isAddMode: false,
    isEditMode: false,
    isReturnMode: false,
    equipmentFilter: null,
    equipmentsForSelect: [],
    clientEquipments: [],
    // clientBuydEquipments: [],
    allEquipments: [],
    equipmentsTotal: 0,
    selectedEquipmentIndex: null
}

export const equipments = {
    namespaced: true,
    state: JSON.parse(JSON.stringify(InitialState)),

    mutations: {
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key]
            }
        },

        setFormDataKeys(state, data) {
            for (const key in data) {
                state.formData[key] = data[key]
            }
        },
        
        resetFormData(state) {
            state.formData = Object.assign({}, InitialState.formData)
        },

        resetState(state) {
            for (let key in state) {
                state[key] = JSON.parse(JSON.stringify(InitialState[key]))
            }
        }
    },

    actions: {
        getEquipmentsList({ commit }) {
            let equipmentsForSelect = []
            return RequestService.read('equipments?sortBy=_id:desc`').then(r => {
                if (r.data.results.length) {
                    const equipments = r.data.results.filter(item => ('prices' in item))
                    if (equipments.length) {
                        equipments.map(item => {
                            if (item.enableForSale) {
                                equipmentsForSelect.push({
                                    value: item._id,
                                    name: item.name.map(name => name.lang === 'en' ? name.name : item.name[0].name)[0],
                                    type: item.type
                                })
                            }                  
                        })  
                    }
                      
                    commit('setData', {
                        equipmentsForSelect,
                        allEquipments: r.data.results
                    })
                }               
            })
        }
    }
}