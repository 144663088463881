import ServerStatistic from "../../views/statistic/ServerStatistic";

export const statisticRout = {
  path: "/statistics",
  redirect: "/",
  name: "Statistics",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "",
      name: "",
      component: ServerStatistic,
      meta: {auth: true, roles: ["ottprovider"], permissions: []}
    }

  ]
};
