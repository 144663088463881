import RequestService from "@/services/request.service";
import EquipmentsTypesFilterModel from "../../models/equipments/EquipmentsTypesFilterModel";
const FileDownload = require("js-file-download");

const InitialState = {
    dataTable: [],
    // filter data
    filter: {
        limit: 20,
        sortBy: "_id:desc",
        page: 1,
    },
    filterData: {},
    filterModel: new EquipmentsTypesFilterModel(),
    //  for table pagination
    dataCount: 0,
    currentPage: 0,
    totalPages: 0,
    // for  update  table  columns
    defaultSettings: [],
    updateColumnsFromBack: "equipmentsTypesModule/updateEquipmentTypesSettings",
    updateColumnSettingsCommit: "equipmentsTypesModule/setDefaultSettings",
    isDefault: false,
    showWarningModal: false,
    equipmentsTypeResult: false,
    isTableLoading: false,
    isOpenManageEquipmentModal: false,
    formData: {
        name: "",
        description: "",
    },
    isAddBtnLoading: false,
    equipmentTypeId: null,
};

export const equipmentsTypesModule = {
    namespaced: true,
    state: InitialState,
    mutations: {
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key];
            }
        },
        setEquipmentsTypesData(state, data) {
            state.dataTable = data.results;
            state.currentPage = data.page;
            state.totalPages = data.totalPages;
            state.dataCount = data.totalResults;
        },
        setFilterPage(state, page) {
            state.filter.page = page;
            state.currentPage = page;
        },
        setLimit(state, newValue) {
            state.filter.limit = newValue
        },
      
        setEquipmentTypeData(state, data) {
            if (data.name) {
                state.formData.name = data.name[0].name;
            }
            if (data.description) {
                state.formData.description = data.description;
            }
        },
        setName(state, value) {
            state.formData.name = value;
        },
        setDescription(state, value) {
            state.formData.description = value;
        },
        setDefaultSettings(state, data) {
            if (data.defaultSettings) {
              state.defaultSettings = data.defaultSettings;
              state.isDefault = data.isDefault
            }
        },
        setDefaultSettingsForBack(state, data) {
            if (data.equipmentTypeSettings) {
              state.isDefault = data.equipmentTypeSettings.isDefault
              state.defaultSettings = data.equipmentTypeSettings.equipmentTypeTable
            }
        },
        resetFormData(state) {
            state.formData.name = "";
            state.formData.description = "";
            state.equipmentTypeId = null;
        },
        sortColumn(state, sort = null) {
            if (sort?.sortValue) {
                state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
            } else {
                if (!state.filterData?.sortBy) {
                    state.filterData.sortBy = "_id:desc";
                }
            }
        },
        filterEquipmentTypes(state) {
            const filterObject = {};
            if (state.filterModel.search) {
                filterObject.search = state.filterModel.search;
            }
            if (state.filterData?.sortBy) {
                filterObject.sortBy = state.filterData.sortBy
            }
            state.filterData = filterObject;
        },
    },
    actions: {
        getEquipmentsTypesList({ commit, state, rootState }, filter = null) {
            let options = {
                page: state.currentPage ? state.currentPage : 1,
            };

            if (filter) {
                options.limit = state.filter.limit
                options = { ...options, ...filter };
            } else {
                options = { ...options, ...state.filter };
            }
            
            if (rootState.general.isSelectedAll) {
                options.all = true
            }

            let query = Object.keys(options)
                .map((key) => key + "=" + options[key])
                .join("&");

            const readOptions = {};
            const isExcelRequest = filter && filter.excel;
            if (isExcelRequest) {
                readOptions.responseType = 'blob';
            } else {
                commit('setData', { isTableLoading: true });
            }

            return RequestService.read(`equipmentTypes?${query}`, readOptions)
                .then((response) => {
                    if (!isExcelRequest) {
                        if (response.data.results && response.data.results.length) {
                            commit("setData", (state.equipmentsTypeResult = true));
                        } else {
                            commit("setData", (state.equipmentsTypeResult = false));
                        }
                        commit("setEquipmentsTypesData", response.data);
                        commit("setData", { isTableLoading: false });
                    } else {
                        FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
                    }
                })
                .catch((e) => console.log("error ", e))
                .finally(() =>  commit("setData", { isTableLoading: false }));
        },

        getEquipmentTypeData({ commit }, id) {
            return RequestService.read(`equipmentTypes/edit/${id}`)
                .then((response) => {
                    commit("setEquipmentTypeData", response.data);
                    return Promise.resolve(response.data);
                })
                .catch((e) => console.log(e));
        },

        updateEquipmentTypesSettings({state}, data) {
            return RequestService.update(`users/settings`, {
                equipmentTypeSettings: {
                    equipmentTypeTable: data,
                    isDefault: state.isDefault,
                    limit: state.filter.limit,
                },
            })
            .then((r) => { return Promise.resolve(r.data) })
            .catch((e) => console.log(e))
        },

        getEquipmentTypesSettings({ commit, state }) {
            return RequestService.read(`users/settings`)
                .then((data) => {
                  const limitData = data?.data?.equipmentTypeSettings?.limit ? data.data.equipmentTypeSettings.limit : state.filter.limit
                  commit('setDefaultSettingsForBack', data.data)
                  commit('setLimit', limitData)
                  return Promise.resolve(data.data)
                })
                .catch((e) => console.log(e))
        },

        createEquipmentType({ commit, state, dispatch }) {
            let body = {
                name: [{ lang: "en", name: state.formData.name }],
                description: state.formData.description,
            };
            return RequestService.create(`equipmentTypes`, body)
                .then(async (response) => {
                    await dispatch("getEquipmentsTypesList");
                    return Promise.resolve(response.data);
                })
                .catch((e) => console.log("error", e))
                .finally(() => {
                    commit("setData", {
                        isAddBtnLoading: false,
                        isOpenManageEquipmentModal: false,
                    });
                    commit("resetFormData");
                });
        },

        updateEquipmentType({ commit, state, dispatch }, id) {
            let body = {
                name: [{ lang: "en", name: state.formData.name }],
                description: state.formData.description,
            };
            return RequestService.update(`equipmentTypes/edit/${id}`, body)
                .then(async (response) => {
                    await dispatch("getEquipmentsTypesList");
                    return Promise.resolve(response.data);
                })
                .catch((e) => console.log("error", e))
                .finally(() => {
                    commit("setData", {
                        isAddBtnLoading: false,
                        isOpenManageEquipmentModal: false,
                    });
                    commit("resetFormData");
                });
        },

        equipmentTypeDelete({ commit, dispatch }, body) {
            return RequestService.update(`equipmentTypes/delete`, body)
                .then(async (r) => {
                    await dispatch("getEquipmentsTypesList");
                    return Promise.resolve(r.data);
                })
                .catch((e) => console.log(e));
        },
    },
};
