<template>
  <ott-dialog
    v-if="openImageEditor"
    @outside="$emit('closeModal')"
    @resetDataModal="$emit('closeModal')"
    :is-open="openImageEditor"
    :show-footer="false"
    :showHeader="false"
    :width="550"
  >
    <template v-slot:modalBody>
      <div class="P-crop-image-section">
        <div class="P-crop-image-title">
          <h3 class="secondary--text">{{ modalTitle }}</h3>
        </div>
        <div class="P-image-crop-data">
          <clipper-basic
            :src="imagePath"
            :corner="true"
            :outline="0"
            :bg-color="$vuetify.theme.dark ? '#494A4D' : '#ffffff'"
            :shadow="
              $vuetify.theme.dark ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.7)'
            "
            :scale="scale"
            :grid="true"
            ref="clipper"
            class="P-test"
          />
          <div class="P-zoom-image G-flex G-align-center">
            <span
              class="icon-image_black_24dp P-range-icons P-small-icon"
              @click="changeScale(1)"
            />
            <clipper-range
              class="P-range-line"
              :min="1"
              :max="4"
              v-model="scale"
              @input="zoomImage"
            />
            <span
              class="icon-image_black_24dp P-range-icons P-big-icon"
              @click="changeScale(4)"
            />
          </div>
        </div>
        <div class="P-buttons G-flex">
          <save-reset-buttons
            :left-click-func="() => $emit('closeModal')"
            :right-click-func="() => saveImage()"
            :is-loading="isLoading"
            right-label="Save"
          />
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
  import { mapState, mapMutations } from "vuex";
  import OttDialog from "@/components/vuetifyComponents/OttDialog";
  import SaveResetButtons from "@/components/SaveResetButtons";

  export default {
    name: "ImageEditor",
    components: {
      OttDialog,
      SaveResetButtons
    },
    props: {
      modalTitle: {
        type: String,
        default: 'Image Customize'
      },

      imagePath: {
        type: String,
        default: ''
      }, 

      openImageEditor: {
        type: Boolean,
        default: false
      }
    },
    computed: {
        ...mapState({
          isLoading: state => state.appModule.isLoading
        })
    },
    mounted() {
      setTimeout(() => {
        if (this.openImageEditor && this.imagePath) {
          let clipper =  document.querySelector('.vuejs-clipper-basic__zoom-area')
          clipper.style.top = '0'
          clipper.style.left = '0'
          clipper.style.width = '100%'
          clipper.style.height = '100%'
        }
      }, 100)
    },
    data() {
      return {
        scale: 1,
      }
    },

    methods: {
      ...mapMutations({
        setData: "equipmentsModule/setData"
      }),

      saveImage() {
        const canvas = this.$refs.clipper.clip();//call component's clip method
        let resultURL = canvas.toDataURL("image/jpeg", 1)
        this.$emit('save', resultURL)
        this.$emit('closeModal')

        // const canvas = this.$refs.clipper.clip();//call component's clip method
        // let resultURL = canvas.toDataURL("image/jpeg", 1)
        // this.setImagePath(resultURL)
        // let x = this.b64toBlob(resultURL, 'image/jpeg')
        // await this.uploadImage(x).then(data => {
        //   this.setAvatarData(data.id)
        // })
      },

      /**
       * convert To blob file
       * **/
      zoomImage(data) {
        if (data) {
          this.scale = data
          this.$refs.clipper.setWH$.next(data);
        } else {
          this.scale = 1
          this.$refs.clipper.setWH$.next(1.9);
        }
      },
      changeScale(data) {
        this.scale = data
        this.$refs.clipper.setWH$.next(data);
      },
    },
    watch: {
      scale(val) {
        if (val) {
          this.$refs.clipper.setWH$.next(+val);
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.P-crop-image-section {
  padding: 25px;
  max-height: 600px;
}

.P-crop-image-title {
  margin-bottom: 20px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

.theme--dark {
  .P-range-icons {
    color: $dark-gray;
  }
}
.theme--dark {
  .vuejs-clipper-basic__wrap {
    background-color: $dark-gray !important;
  }
  .vuejs-clipper-basic__panding {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

.P-range-icons {
  width: 26px;
  height: 26px;
  background-color: rgba(165, 170, 198, 0.15);
  color: $neutral-color;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  transition: $transition;
  cursor: pointer;

  &:hover {
    color: $secondary-color;
  }

  &.P-small-icon {
    font-size: 16px;
  }

  &.P-big-icon {
    font-size: 20px;
  }
}

.P-range-line {
  width: calc(100% - 52px);
}

.P-range-line::v-deep {
  .bar {
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }

  .stick {
    height: 3px;
    background-color: rgba(165, 170, 198, 0.35);
  }

  .wrap {
    height: 16px;
  }
}

.P-zoom-image {
  margin-top: 20px;
}

.P-test::v-deep {
  &.js-clipper-basic {
    .vuejs-clipper-basic__cover {
      .vuejs-clipper-basic__panding {
        width: 100% !important;
      }
    }
  }
}

.P-buttons {
  margin-top: 20px;
}

.P-test {
  border: 2px dashed $neutral-color;
}

</style>

