import DiscountFilterModel from "../../models/discount/DiscountFilterModel";
import moment from "moment";
import RequestService from "@/services/request.service";
const FileDownload = require('js-file-download');

const InitialState = {
  filterModel: new DiscountFilterModel(),

  modal: {
    isOpen: false,
    showWarningModal: false,
    sendNotificationModal: false,
  },

  loadingActions: false,

  activeTab: 0,
  disableTabs: 0,
  isTableLoading: false,

  showList: [
    {name: "General Info", title: "generalInfo"},
    {name: "Price Groups", title: "priceGroup"},
    {name: "Packages", title: "packages"},
    // {name: "Notifications", title: "notifications"},
  ],


  // filter data
  filter: {
    limit: 20,
    sortBy: "_id:desc",
    page: 1
  },
  //  for  pagination  table
  dataCount: 0,
  currentPage: 0,
  totalPages: 0,

  filterData: {},
  statusList: [
    {
      name: 'All',
      value: 1
    },
    {
      name: 'Disabled',
      value: 2
    },
    {
      name: 'Enabled',
      value: 3
    }
  ],
  discountTypeList: [
    {
      name: 'All',
      value: 1
    },
    {
      name: 'For Clients',
      value: 2
    },
    {
      name: 'For Providers',
      value: 3
    }, 
  ],


  discountId: null,

  discountData: {
    generalInfo: {
      type: null,
      name: '',
      startDate: null,
      endDate: null,
      defaultSalePercent: null,
      rounding: null,
      digits: null,
      enable: false,
      description: '',
      sendNotifications: false,
      // countriesAccepted: [],
      // countriesDenied: []
    },
    packages: [],
    priceGroups: [],
    notifications: {
      notificationsMode: '',
      repeatDays: '',
      beforeDays: '',
      notificationTextForUpcoming: '',
      notificationTextForCurrent: ''
    },
    provider: '',
    forDefaultPriceGroup: false
  },


  // for  update  table  columns
  defaultSettings: [],
  updateColumnsFromBack: 'discountModule/updateDiscountSettings',
  updateColumnSettingsCommit: 'discountModule/setDefaultSettings',
  isDefault: false,

  dataTable: [],
  discountModel: {
    enable: false,
    discountId: [],
  },
  disableFields: false,

  checkPriceData: {
    dateStart: null,
    dateEnd: null
  },
  checkedPriceGroups: ['61bf8ce88e41037ddbec4734'],

  discountsResult: false,
  clientDiscounts: [],
  providerDiscounts: [],
  isMultipleAction: false,
};

export const discountModule = {
  namespaced: true,

  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    /**
     * Toggle open modal
     * **/

    toggleOpenModal(state, value) {
      state.modal.isOpen = value
    },
    showWarningModal(state, value) {
      state.modal.showWarningModal = value
    },

    setDiscountId(state, id) {
      state.discountId = id
    },

    selectDiscounts(state, value) {
      state.discountModel.discountId = value
    },
    setIsEnable(state, value) {
      state.discountModel.enable = value
    },

    setClientAndProviderDiscounts(state, data) {
      data.map(item => {
        if (item.generalInfo.type === 2) {
          state.clientDiscounts.push({
            name: item.generalInfo.name,
            value: item.generalInfo._id
          })
        } else {
          state.providerDiscounts.push({
            name: item.generalInfo.name,
            value: item.generalInfo._id
          })
        }
      })
    },

    /**
     * Change modal tabs  onclick left tabs
     * **/

    clickActiveTab(state, data) {
      state.activeTab = data;
    },

    /**
     * Change modal  tabs onclick next button
     * **/
    nextTab(state) {
      if (state.activeTab < state.showList.length) {
        state.activeTab = ++state.activeTab;
        if (state.activeTab > state.disableTabs) {
          state.disableTabs = ++state.disableTabs;
        }
      }
    },
    disableSaveCloseButton(state) {
      state.disableTabs = state.showList.length;
    },

    setDiscountData(state, data) {
      if (data.generalInfo) {
        if (data.generalInfo.countriesAccepted) {
          delete data.generalInfo.countriesAccepted
        }
        if (data.generalInfo.countriesDenied) {
          delete data.generalInfo.countriesDenied
        }
        if (data.generalInfo.updatedAt) {
          delete data.generalInfo.updatedAt
        }
        if (data.generalInfo.id) {
          delete data.generalInfo.id
        }

        state.discountData.generalInfo = data.generalInfo

        if (typeof data.generalInfo.status === "number") {
          state.discountData.generalInfo.status = data.generalInfo.status
        } else {
          if (data.generalInfo.status) {
            state.discountData.generalInfo.status = 2
          } else {
            state.discountData.generalInfo.status = 3
          }
        }
        // countriesAccepted: [],
        // countriesDenied: []
      }
      if (data.packages) {
        state.discountData.packages = data.packages
      }
      if (data.priceGroups) {
        // if (typeof data.priceGroups[0] !== 'string') {
        //   state.discountData.priceGroups = data.priceGroups
        // } else {
          state.discountData.priceGroups = data.priceGroups
        // }

      }

      // if (data.forDefaultPriceGroup === true) {
      //   state.discountData.forDefaultPriceGroup = true
      // }

      if (data.notifications) {
        if (data.notifications.updatedAt) {
          delete data.notifications.updatedAt
        }
        if (data.notifications.id) {
          delete data.notifications.id
        }
      }
      if (data.notifications && state.discountData.generalInfo.sendNotifications) {
        state.discountData.notifications = data.notifications
      } else {
        delete state.discountData.notifications
      }
      if (state.discountId) {
        delete state.discountData.provider
      }
    },

    setDefaultPriceGroup(state, value) {
      state.discountData.forDefaultPriceGroup = value
    },

    resetDataModal(state) {
      state.discountData = JSON.parse(JSON.stringify(InitialState)).discountData
      state.discountId = null
      state.activeTab = 0
      state.disableTabs = 0
    },
    getDiscountData(state, data) {
      state.dataTable = data.results
      state.currentPage = data.page
      state.totalPages = data.totalPages
      // state.dataCount = state.dataTable.length
      state.dataCount = data.totalResults
    },

    setFilterPage(state, page) {
      state.filter.page = page
      state.currentPage = page;
    },

    filterDiscounts(state) {
      const filterObject = {}
      if (state.filterModel.search) {
        filterObject.search = state.filterModel.search
      }
      if (state.filterModel.past) {
        filterObject.past = true
      }
      if (state.filterModel.current) {
        filterObject.current = true
      }
      if (state.filterModel.upcoming) {
        filterObject.upcoming = true
      }
      if (state.filterModel.type) {
        filterObject.type = state.filterModel.type
      }
      if (state.filterModel.date?.start && state.filterModel.date?.end) {
        filterObject.startDate = 
          moment(state.filterModel.date?.start).format('MM/DD/YYYY');  
        filterObject.endDate = 
          moment(state.filterModel.date?.end).format('MM/DD/YYYY');
      }
      if (state.filterModel.status) {
        filterObject.status = state.filterModel.status
      }
      if (state.filterModel.priceGroup.length) {
        const filterPriceGroups = JSON.parse(JSON.stringify(state.filterModel.priceGroup))
        let defaultIndex = filterPriceGroups.indexOf('default')
        if (defaultIndex !== 1) {
          filterPriceGroups[defaultIndex] = null
        }
        filterObject.priceGroups = filterPriceGroups
      }
      if (state.filterModel.packages.length) {
        filterObject.packages = state.filterModel.packages
      }
      if (state.filterData?.sortBy) {
        filterObject.sortBy = state.filterData.sortBy
      }

      state.filterData = filterObject
    },

    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault
      }
    },

    setDefaultSettingsForBack(state, data) {
      if (data.discountSettings) {
        state.isDefault = data.discountSettings.isDefault
        state.defaultSettings = data.discountSettings.discountTable
      }
    },
    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filterData?.sortBy) { 
          state.filterData.sortBy = "_id:desc"; 
        }
      }
    },

    disableData(state) {
      if (state.discountData.generalInfo.dateEnd) {
        state.disableFields = state.discountId && state.discountData.generalInfo.dateEnd < new Date().toISOString()
      } else {
        state.disableFields = false
      }
    },

    setCheckedPriceGroups(state, data){
      state.checkedPriceGroups = data
    },

    setDiscountsResult(state, value) {
      state.discountsResult = value
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  },

  actions: {
    createDiscount({commit, state, dispatch}, clientId) {
      state.discountData.provider = clientId

      return RequestService.create(`discounts`, state.discountData)
        .then(data => {
          return data.data
        })
        .catch(e => console.log(e))
    },
    updateDiscount({commit, state,}) {
      state.discountData.priceGroups.map(item => {
        delete item._id
      })
      return RequestService.update(`discounts/edit/${state.discountId}`, state.discountData)
        .then(data => {
          return data.data
        })
        .catch(e => console.log(e))
    },

    getDiscountList({ commit, state, rootState}, filter) {
      let options = {
        page: 1,
      }
      if (filter) {
        options.limit = state.filter.limit
        options = {...options, ...filter}
      } else {
        options = {...options, ...state.filter}
      }

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = 'blob';
      } else {
        commit('setData', { isTableLoading: true });
      }
      // let query = Object.keys(options).map(
      //   key => key + '=' + options[key]
      // ).join('&');
      return RequestService.create(`discounts/getByFilter`, options, readOptions)
        .then(data => {
          if (!isExcelRequest) {
            if (data.data.results && data.data.results.length) {
              commit('setDiscountsResult', true)
            } else {
              commit('setDiscountsResult', false)
            }
            commit('setData', { isTableLoading: false })
            commit('setClientAndProviderDiscounts', data.data.results)
            commit('getDiscountData', data.data)
          } else {
            FileDownload(data.data, 'discounts.xlsx'); // TODO actual file name from response.headers
          }
          return Promise.resolve(data.data)
        })
        .catch(e => console.log(e))
        .finally(() =>  commit('setData', { isTableLoading: false }))
    },
    updateDiscountSettings({state, commit}, data) {
      return RequestService.update(
        `users/settings`, {
          discountSettings: {
            discountTable: data,
            isDefault: state.isDefault,
            limit: state.filter.limit
          }
        }
      ).then(
        data => {
          console.log('data ', data)
        },
        error => {
          console.log('error ', error)
        }
      );
    },


    getDiscountSettings({commit, state}) {
      return RequestService.read(
        `users/settings`
      ).then(
        data => {
          const limitData = data?.data?.discountSettings?.limit ? data.data.discountSettings.limit : state.filter.limit
          commit('setDefaultSettingsForBack', data.data)
          commit('setLimit', limitData)
          return Promise.resolve(data.data);
        },
        error => {
          console.log('error ', error)
        }
      );
    },

    deleteDiscount({ state, rootState }, discountsData = []) {
      const body = {}
      if (state.isMultipleAction && rootState.general.isSelectedAll) {
        body.all = true
      } else {
        body.discountId = state.discountModel.discountId.length ? 
          state.discountModel.discountId : 
          discountsData
      }
      
      return RequestService.update(
        `discounts/delete`, { ...body }
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          console.log('error ', error)
        }
      );
    },
    getDiscountDetails({}, discountId) {
      return RequestService.read(
        `discounts/edit/${discountId}`
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          console.log('error ', error)
        }
      );
    },
    setNotification({ state, rootState }) {
      const body = {}
      if (state.isMultipleAction && rootState.general.isSelectedAll) {
        body.all = true
      } else {
        body.discountId = state.discountModel.discountId
      }
    
      return RequestService.update(
        `discounts/sendNotifications`, { ...body }
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          console.log('error ', error)
        }
      );
    },
    enableDisableAction({ state, rootState }) {
      const body = {
        status: state.discountModel.enable
      }

      if (state.isMultipleAction && rootState.general.isSelectedAll) {
        body.all = true
      } else {
        body.discountId = state.discountModel.discountId
      }

      return RequestService.update(
        `discounts/enableDisable`, { ...body }
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          console.log('error ', error)
        }
      );
    },
    getCheckPriceGroups({commit, rootState}) {
      return RequestService.read(
        `discounts/priceGroups/${rootState.generalInfoDiscount.discountDate?.start}/${rootState.generalInfoDiscount.discountDate?.end}`
      ).then(
        data => {
          commit('setCheckedPriceGroups', data.data)
          return Promise.resolve(data.data);
        },
        error => {
          console.log('error ', error)
        }
      );
    }
  },

};
