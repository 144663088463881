import RequestService from "@/services/request.service";

export const notifications = {
    namespaced: true,
    state: {
        notifications: [],
    },
    actions: {
        getNotifications({ commit }) {
            return RequestService.read('notifications?limit=10000000000&sortBy=_id:desc').then(response => {
                /* Get Comment Notitifaction data */
                const notifications = [];
                if (response.data.results.length) {
                    const notificationData = response.data.results.filter(item => !item.isViewed)
                    if (notificationData.length) {
                        notificationData.map(item => {
                            const addObject = {
                                id: item.id,
                                isViewed: item.isViewed,
                                content: item.note.replace('notification to comment', '<span class="mb-1 d-inline-block"><ins>Notification To Comment</ins></span>'),
                                date: item.updatedAt,
                                client: item.client,
                                type: item.type,
                                isPrivate: item.comment?.isPrivate
                            };
                            if (item.type === 1) {
                                addObject.commentWritter = {
                                    avatar: item?.user?.avatar,
                                        fullName: item?.user?.firstname + ' ' + item?.user?.lastname,
                                        id: item?.user?.id
                                };
                                addObject.commentReader = {
                                    id: item?.client?.id,
                                        fullName: item?.client?.personalInfo?.firstname + ' ' + item?.client?.personalInfo?.lastname
                                };
                            }
                            notifications.push(addObject);
                        })
                    }
                }

                commit("setData", { notifications })
            })
        },

        updateNotification({ dispatch, rootState }, notificationId = null) {
            return RequestService.update(`notifications/edit/${notificationId}`, { isViewed: true }).then(async () => {
                await dispatch('getNotifications')
                
                /* Get comments if page is general search and 
                   there is exist searchedClientId */
                if (rootState.generalSearch.searchedClientId) {
                    await dispatch('comments/getComments', rootState.generalSearch.searchedClientId, { root: true })
                }                
            })
        },

        deleteNotification({ dispatch }, notificationId) {
            return RequestService.delete(`notifications/edit/${notificationId}`).then(() => {
                dispatch('getNotifications')
            })
        }

    },

    mutations: {
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key]
            }
        }
    }
};
