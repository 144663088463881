<template>
  <v-main class="ott-input-cont-main">
    <v-text-field
      :value="inputValue"
      :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
      :type="show ? 'text' : 'password'"
      :label="label"
      :height="height"
      :dense="dense"
      :error="error"
      outlined
      class="ott-input-cont"
      @input="inputEmit"
      @click:append="show = !show"
    />

    <password
      v-model="password"
      :strength-meter-only="true"
      :toggle="true"
      enableFeedback="enableFeedback"
      class="password-range"
    />
  </v-main>
</template>

<script>
import Password from "vue-password-strength-meter";
export default {
  name: "PasswordStrength",
  components: { Password },
  data() {
    return {
      show: false,
      password: null
    };
  },
  props: {
    inputValue: {
      type: String
    },
    label: {
      type: String,
      default: "Password"
    },
    error: {
      type: Boolean
    },
    height: {
      type: Number,
      default: 38
    },
    dense: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    inputEmit(val) {
      this.password = val;
      this.$emit("emitFunc", val);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
.password-range::v-deep {
  .Password__strength-meter {
    margin-top: 0;
  }
}
.ott-input-cont::v-deep {
  width: 100%;
  &.v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
    min-height: 30px !important;
  }


  .v-input__slot{
    padding: 0 18px !important;

  }
  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: $neutral-color;
  }
  .v-text-field--outlined .v-input__slot fieldset {
    padding-left: 14px !important;
  }
  &.v-input .v-label {
    font-size: $txt14 !important;
    font-weight: $regular !important;
    //color: $neutral-color;
    color: $dark-gray;
  }
  .v-input--dense .v-label {
    top: 8px !important;
  }
  .mdi-eye-outline,
  .mdi-eye-off-outline {
    font-size: 20px;
    color: $neutral-color45;
  }
  &.v-input--has-state.error--text .v-label {
    -webkit-animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  }
}
.ott-input-cont-main::v-deep {
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: $neutral-color;
  }
}
</style>
