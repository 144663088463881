import RequestService from "@/services/request.service";
import moment from "moment"

const InitialState = {

  locationList: [],
  selectedLocation: null,
  initialSelectedLocation: null,
  selectedLocationIndex: null,
  selectedDeviceIndex: null,
  usedDevices: [],

  timeShiftList: [
    { name: 0, value: 0 },
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
    { name: 11, value: 11 },
    { name: 12, value: 12 },
  ],
  languageList: [],
  remoteControlList: [],
  audioTrackDefaultList: [],
  httpCachingList: [],
  streamQualityList: [],
  fontSizes: [],
  isOpenUsedDevicesModal: false,
  savedDevice: {
    index: null,
    saved: false
  },
  statistics: [],
  fromToDate: {
    start: null,
    end: null
  },
  isLoading: false
};

export const usedDevicesClient = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    /**
     * Open used Devices modal
     */
    openUsedDevicesModal(state) {
      state.isOpenUsedDevicesModal = true
    },

    /**
     * Close used devices modal
     */
    closeUsedDevicesModal(state) {
      state.isOpenUsedDevicesModal = false
    },

    /**
     * Form data
     */

    setSettingsForm(state, { keyName, value }) {
      state.usedDevices[state.selectedDeviceIndex].settings[keyName] = value;
    },

    resetFromToDate(state) {
      state.fromToDate.start = null
      state.fromToDate.end = null
    },

    resetState(state) {
      for (let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))
      }
    }
  },

  actions: {
    getUsedDevicesOptions({ commit }) {
      return RequestService.read('clients/locations/usedDevices/options').then((r) => {
        commit('setData', {
          languageList: r.data.lang,
          remoteControlList: r.data.box_models,
          audioTrackDefaultList: r.data.audiotrack_default,
          httpCachingList: r.data.http_caching,
          streamQualityList: r.data.stream_quality,
          fontSizes: r.data.ui_font_size,
        })
      })
    },

    getUsedDevices({ state, commit }, data) {
      commit('setData', { isLoading: true })
      
      if (state.selectedLocation?.id) {
        const options = {
          sortBy: '_id:desc',
          syncCdn: data.syncCdn
        }

        if (state.fromToDate?.start && state.fromToDate?.end) {
          options.statisticsFrom =  
            moment(state.fromToDate?.start).format('MM/DD/YYYY');
          options.statisticsTo = 
            moment(state.fromToDate?.end).format('MM/DD/YYYY');
        }

        let query = Object.keys(options).map(
          key => key + '=' + options[key]
        ).join('&');

        return RequestService.read(`clients/locations/usedDevices/edit/${state.selectedLocation.id}?${query}`).then((r) => {
          r.data.map((device) => {
            if (!device.settings.remoteControl) {
              device.settings.remoteControl = ''
            }
          })

          commit('setData', { 
            usedDevices: r.data,
            isLoading: false
          })
        })
        .catch((e) => console.log(e))
        .finally(
          setTimeout(() => {
            commit('setData', { isLoading: false })
          },700)
        )
      }
    },

    saveUsedDevice({ state, commit }) {
      const usedDevice = { ...state.usedDevices[state.selectedDeviceIndex] }
      const settingsData = {
        id: usedDevice?.information?._id,
        settings: usedDevice.settings
      } 

      if (Object.entries(usedDevice).length) {
        return RequestService.update(`clients/locations/usedDevices/edit/${state.selectedLocation.id}`, settingsData)
          .then(() => {
            commit('setData', {
              savedDevice: {
                index: state.selectedDeviceIndex,
                saved: true
              }
            })
          })
          .catch((e) => console.log(e))
          .finally(() => {
            commit('setData', {
              savedDevice: {
                index: state.selectedDeviceIndex,
                saved: false
              }
            })
          })
      }
    },

    syncCdn({ state, commit }, requestData) {
        return RequestService.update(`clients/locations/usedDevices/syncCdn/${state.selectedLocation.id}`, requestData)
    },
  }
};
