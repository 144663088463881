import Payment from "../../views/payment/Payment";

export const paymentRout = {
  path: "/payment/:id",
  redirect: "/",
  name: "payment",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "",
      name: "",
      component: Payment,
      meta: {auth: false, roles: [], permissions: []}
    }
  ]
};
