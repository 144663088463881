<template>
    <div class="P-users-list">
        <div class="G-page-component" :class="{ 'P-filter-open': isOpenFilter }">
            <div class="G-page-title-block G-flex G-justify-between G-align-center">
                <div class="G-component-title">
                    <h3 class="secondary--text">Review</h3>
                </div>
            </div>
            <div class="G-component-header page-background">
                <div class="G-table-actions G-flex G-align-center">
                    <div class="action-cont-first G-flex G-align-center">
                        <OttCheckbox
                            :indeterminate="selectNotAll"
                            :dataVal="selectAllList"
                            tooltip="Select all visibles"
                            @changeCheckbox="selectAllVisibles"
                        />

                        <div class="G-select-all">
                            <OttCheckbox
                                tooltip="Select all"
                                v-model="isSelectedAll"
                                @changeCheckbox="selectAll"
                            />
                        </div>

                        <div v-if="(selectedArrowList.length || selectedArrowListSub.length) && (permissionIs('approveOttProvider') || permissionIs('registrationApprove'))"
                             class="G-flex"
                        >
                            <div class="approve-rej-icon-cont G-flex">
                                <v-tooltip
                                    top
                                    class="ott-tooltip"
                                    color="tooltipBgColor"
                                    attach=".approve-rej-icon-cont"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            @click="selectMultipleAction('Reject')"
                                            class="mdi mdi-cancel neutral--text"
                                            v-bind="attrs"
                                            v-on="on"
                                        />
                                    </template>
                                    <span>Reject</span>
                                </v-tooltip>
                                <v-tooltip
                                        top
                                        color="tooltipBgColor"
                                        attach=".approve-rej-icon-cont"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="selectMultipleAction('Approve')"
                                            class="mdi mdi-check-circle-outline neutral--text"
                                        />
                                    </template>
                                    <span>Approved</span>
                                </v-tooltip>
                            </div>
                            <div class="select-all-menu-cont ott-menu">
                                <v-menu offset-y attach=".select-all-menu-cont">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn dark icon v-bind="attrs" v-on="on" :ripple="false">
                                            <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                                v-for="(action, key) of actionsList"
                                                :key="key"
                                                :ripple="false"
                                                @click="selectMultipleAction(action.value)"
                                        >
                                            <v-list-item-title>{{ action.name }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                    </div>
                    <div class="G-excel-btn">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="P-settings" v-bind="attrs" v-on="on" @click="exportData">
                                    <span class="mdi mdi-file-excel neutral--text P-icon" />
                                </div>
                            </template>
                            <span>Download Excel</span>
                        </v-tooltip>
                    </div>
                    <OttHideShowColumnsOption
                        :is-default="review.isDefault"
                        :column-config="columnConfigData"
                        @updateColumnsFromCheck="updateColumnsFromCheck"
                        @resetDataSettingsTable="resetDataTableSettings"
                        class="P-users-columns"
                    />
                </div>
                <div>
                    <div
                        :class="{ 'P-filter-open': isOpenFilter }"
                        @click="toggleOpenFilter"
                        class="G-filter-btn"
                    >
                        <span class="mdi mdi-filter-variant"></span>
                        <p>Filter</p>
                        <div
                            v-if="filterModel.filterCount.length > 0"
                            class="active-filter-count"
                        >
                            <span>{{ filterModel.filterCount.length }}</span>
                        </div>
                        <span class="mdi mdi-menu-right"></span>
                    </div>
                </div>
            </div>
            <div class="G-component-body page-background">
                <div class="G-sub-block-title">
                    <h3 class="secondary--text">Review List</h3>
                </div>
                <OttUniversalTable
                    v-if="!loading"
                    :main-data="dataTable"
                    :data="dataTable"
                    :column-config="columnConfigData"
                    :selected-arrow-list="selectedArrowList"
                    :is-sub-table="false"
                    :is-multi-select="true"
                    :fixed-first-and-last-column="true"
                    :column-with-tree-increment="tableColumnWidth"
                    :is-total-sum="false"
                    @saveColumnChangesFromDrag="updateColumnsFromDrag"
                    @selectRow="selectRow"
                    @sortData="sortColumnData"
                    @updateColumnWith="updateColumn"
                    @updateColumns="updateColumns"
                >
                    <template v-if="permissionIs('approveOttProvider') || permissionIs('registrationApprove')" slot-scope="props" slot="action" >
                        <v-menu left content-class="P-actions-list">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn dark icon v-bind="attrs" v-on="on">
                                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                        v-for="(item, i) in menuItems"
                                        :key="i"
                                        @click="selectAction($event, props, item)"
                                >
                                    <div>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </div>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </OttUniversalTable>
                <OttPaginationForTable
                    v-if="reviewsResult && !loading"
                    v-model="rowsPerPage"
                    :data-count="review.dataCount"
                    :available-data-count="dataTable.length"
                    :current-page="review.currentPage"
                    :page-limit="review.totalPages"
                    @update="updateLimit"
                    @updatePage="updatePackagesList"
                />
                <NoDataInTable v-else />
            </div>
        </div>
        <div
            :class="{ 'P-filter-open': isOpenFilter }"
            class="G-filter-component page-background"
        >
            <div class="G-filter-header G-flex G-align-center G-justify-between">
                <p class="secondary--text">Filter by</p>
                <span class="mdi mdi-close neutral--text" @click="closeFilter"></span>
            </div>
            <div class="G-filter-body">
                <FilterData @resetSelectedList="resetSelectedList" />
            </div>
            <div class="G-filter-footer">
                <ott-button
                    class="primary-lg-btn"
                    text="Reset Filters"
                    :click="resetData"
                >
                </ott-button>
            </div>
        </div>
        <PageLoader v-if="review.isTableLoading || loading" />
        <DetailModal />
        <WarningModal
            v-if="showWarningModal"
            :isModalOpen="showWarningModal"
            :modalHeading="warningModalHeading"
            :modalText="warningModalText"
            :btnText="review.warningModalBtnText"
            :isDangerButton="review.warningModalIsDangerButton"
            :isLoading="warningModalIsLoading"
            @closeModal="closeModalByName('warningModal')"
            @btnFunction="warningModalBtnFunction"
        />
    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";
    import allCountry from "@/constants/all-countries";
    // mixins
    import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
    import FilterMixin from "@/mixins/FilterMixin";
    import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
    // components
    import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
    import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
    import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
    import OttButton from "@/components/vuetifyComponents/OttButton";
    import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
    import FilterData from "../review/components/FilterData";
    import PageLoader from "@/components/pageLoader/PageLoader";
    import WarningModal from "@/components/WarningModal";
    import DetailModal from "./modals/DetailModal";
    import NoDataInTable from "@/components/NoDataInTable";

    export default {
        name: "Reviews",
        components: {
            DetailModal,
            OttPaginationForTable,
            WarningModal,
            PageLoader,
            OttButton,
            OttUniversalTable,
            OttHideShowColumnsOption,
            OttCheckbox,
            FilterData,
            NoDataInTable
        },
        mixins: [OttUniversalTableForComponentsMixin, FilterMixin, ColumnConfigMixin],
        data() {
            return {
                menuItems: [
                    { title: "Detail", value: "detail" },
                    { title: "Approve", value: "approve" },
                    { title: "Reject", value: "reject" },
                ],
                warningModalIsLoading: false,
                showWarningModal: false,
                actionsList: [
                    { name: "Approve", value: "Approve" },
                    { name: "Reject", value: "Reject"}
                ],
                columnConfigData: [],
                defaultColumnConfigData: [],
                loading: false
            };
        },
        computed: {
            ...mapState({
                review: (state) => state.review,
                isDefault: (state) => state.review.isDefault,
                clientsCount: (state) => state.review.clientsCount,
                filterModel: state => state.review.filterModel,
                dataTable: state => state.review.dataTable,
                updateColumnSettingsCommit: state => state.review.updateColumnSettingsCommit,
                updateColumnsFromBack: state => state.review.updateColumnsFromBack,
                defaultSettings: state => state.review.defaultSettings,
                filterData: state => state.review.filterData,
                filter: state => state.review.filter,
                reviewsResult: state => state.review.reviewsResult,
            }),

            defaultColumnConfigs() {
                return [
                    {
                        title: "Full Name",
                        isTranslate: false,
                        style: {minWidth: 160},
                        isShow: true,
                        isDrag: true,
                        key: "firstname",
                        "defaultHide": true,
                        "defaultDragIndex": 0,
                        "mainIndex": 0,
                        cellView: row => `<p class="G-text-table">${row.user.firstname + ' ' + row.user.firstname}</p>`
                    },
                    {
                        title: "Status",
                        isTranslate: false,
                        style: {minWidth: 160},
                        isShow: true,
                        isDrag: true,
                        key: "status",
                        "defaultHide": true,
                        "defaultDragIndex": 1,
                        "mainIndex": 1,
                        sortData: {
                            isSort: true,
                            sortKey: "status",
                            sortValue: ""
                        },
                        cellView: row => `<p class="G-text-table">${this.getStatus(row.state)}</p>`
                    },
                    {
                        title: "IP Address",
                        isTranslate: false,
                        style: {minWidth: 160},
                        isShow: true,
                        isDrag: true,
                        key: "ipAddress",
                        "defaultHide": true,
                        "defaultDragIndex": 2,
                        "mainIndex": 2,
                        cellView: row => `<p class="G-text-table">${row.user?.geoInfo?.realIp}</p>`
                    },
                    {
                        title: "Email Address",
                        isTranslate: false,
                        style: {minWidth: 180},
                        isShow: true,
                        isDrag: true,
                        key: "email",
                        "defaultHide": true,
                        "defaultDragIndex": 3,
                        "mainIndex": 3,
                        cellView: row => `<p class="G-text-table">${row?.emails[0]?.address}</p>`
                    },
                    {
                        title: "Clients",
                        isTranslate: false,
                        style: {minWidth: 150},
                        isShow: true,
                        isDrag: true,
                        key: "clients",
                        "defaultHide": true,
                        "defaultDragIndex": 4,
                        "mainIndex": 4,
                        sortData: {
                            isSort: true,
                            sortKey: "clients",
                            sortValue: ""
                        },
                        cellView: row => `<p class="G-text-table">${this.getClientCountText(row.clientAmount)}</p>`
                    },
                    {
                        title: "Country",
                        isTranslate: false,
                        style: {minWidth: 150},
                        isShow: true,
                        isDrag: true,
                        key: "country",
                        "defaultHide": true,
                        "defaultDragIndex": 5,
                        "mainIndex": 5,
                        cellView: row => `<p class="G-text-table">${this.getCountry(row?.user?.geoInfo?.countryCode)}</p>`
                    },
                    {
                        title: "Company",
                        isTranslate: false,
                        style: {minWidth: 160},
                        isShow: true,
                        isDrag: true,
                        key: "company",
                        "defaultHide": true,
                        "defaultDragIndex": 6,
                        "mainIndex": 6,
                        cellView: row => `<p class="G-text-table">${row.name[0].name}</p>`
                    },
                    {
                        title: "Webpage",
                        isTranslate: false,
                        style: {minWidth: 160},
                        isShow: true,
                        isDrag: true,
                        key: "webpage",
                        "defaultHide": true,
                        "defaultDragIndex": 7,
                        "mainIndex": 7,
                        cellView: row => `<p class="G-text-table">${row.website}</p>`
                    },
                    {
                        title: "Company email",
                        isTranslate: false,
                        style: {minWidth: 180},
                        isShow: true,
                        isDrag: true,
                        key: "companyEmail",
                        "defaultHide": true,
                        "defaultDragIndex": 8,
                        "mainIndex": 8,
                        cellView: row => `<p class="G-text-table">${row?.user?.email}</p>`
                    },
                    {
                        title: "Phone Number",
                        isTranslate: false,
                        style: {minWidth: 180},
                        isShow: true,
                        isDrag: true,
                        key: "phone",
                        "defaultHide": true,
                        "defaultDragIndex": 9,
                        "mainIndex": 9,
                        cellView: row => `<p class="G-text-table">${'-'}</p>`
                    },
                    {
                        title: "Channels",
                        isTranslate: false,
                        style: {minWidth: 130},
                        isShow: true,
                        isDrag: true,
                        key: "channels",
                        "defaultHide": true,
                        "defaultDragIndex": 10,
                        "mainIndex": 10,
                        sortData: {
                            isSort: true,
                            sortKey: "channels",
                            sortValue: ""
                        },
                        cellView: row => `<p class="G-text-table">${row.channelAmount}</p>`
                    },
                    {
                        title: "Date",
                        isTranslate: false,
                        style: {minWidth: 160},
                        isShow: true,
                        isDrag: true,
                        key: "date",
                        "defaultHide": true,
                        "defaultDragIndex": 11,
                        "mainIndex": 11,
                        sortData: {
                            isSort: true,
                            sortKey: "date",
                            sortValue: ""
                        },
                        cellView: row => `<p class="G-text-table">${ row?.createdAt ? row.createdAt : '-'}</p>`
                    },
                    {
                        title: "Login attempt",
                        isTranslate: false,
                        style: {minWidth: 160},
                        isShow: true,
                        isDrag: true,
                        key: "loginAttempt",
                        "defaultHide": true,
                        "defaultDragIndex": 12,
                        "mainIndex": 12,
                        cellView: row => `<p class="G-text-table">${row.user.loginAttempt}</p>`
                    },
                    {
                        title: "ID",
                        isTranslate: false,
                        style: {minWidth: 160},
                        isShow: true,
                        isDrag: true,
                        key: "id",
                        "defaultHide": true,
                        "defaultDragIndex": 13,
                        "mainIndex": 13,
                        cellView: row => `<p class="G-text-table">${row.number}</p>`
                    },
                ]
            },

            rowsPerPage: {
                set(limit) {
                    this.setLimit(limit)
                },
                get() {
                    return this.filter.limit;
                }
            },

            warningModalHeading() {
                if (this.review.isMultipleAction) {
                    return `${this.review.warningModalBtnText} User(s)?`;
                } else {
                    return `${this.review.warningModalBtnText} this User?`;
                }
            },

            warningModalText() {
                if (this.review.isMultipleAction) {
                    return `You're about to <span class='text-lowercase'><ins>${this.review.warningModalBtnText}</ins></span> one or more Users!! <br /> If you're not sure, you can cancel this operation.`;
                } else {
                    return `You're about to <span class='text-lowercase'><ins>${this.review.warningModalBtnText}</ins></span> this user! <br /> If you're not sure, you can cancel this operation.`;
                }
            },
        },

        async created() {
            this.loading = true
            await this.getReviewSettings()
            this.createColumnConfigData()
            this.sortColumn();
            await this.getReviewList(this.filterData).finally(() => {
                this.loading = false
            })
            this.resetSelectedList()
        },

        beforeDestroy() {
            this.filterModel.reset()
            this.setData({ filterData: {} })
        },

        destroyed() {
            this.setFilterPage(1)
        },

        methods: {
            ...mapActions({
                approveReviewData: "review/approveReviewData",
                getReviewList: 'review/getReviewList',
                getReviewSettings: 'review/getReviewSettings'
            }),

            ...mapMutations({
                setData: "review/setData",
                openModal: "review/openModal",
                closeModalByName: "review/closeModal",
                reviewDetail: "review/reviewDetail",
                setApproveRejectID: "review/setApproveRejectID",
                filterReviews: 'review/filterReviews',
                setFilterPage: 'review/setFilterPage',
                setIsOpenModal: 'review/setIsOpenModal',
                setLimit: 'review/setLimit',
                sortColumn: 'review/sortColumn'
            }),

            async sortColumnData(data) {
                this.sortColumn(data);
                await this.getReviewList(this.filterData)
            },

            async updateLimit() {
                this.sortColumn();
                await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
                    this.createSaveDefaultData();
                })

                await this.getReviewList(this.filterData)
                this.resetSelectedList()
            },

            /**
             * Functions for review list data
             */
            getCountry(countryCode) {
                let name = ''
                allCountry.forEach(item => {
                    if (item.value === countryCode) {
                        name = item.name
                    }
                })
                return name
            },
            getStatus(state) {
                if (state === 0) {
                    return '<span class="G-badge G-pending-badge G-flex G-align-center">Pending</span>'
                } else {
                    return '<span class="G-badge G-reject-badge G-flex G-align-center">Reject</span>'
                }
            },
            getClientCountText(clientNumber) {
                return this.clientsCount.filter((x, index) => {
                    if (index === clientNumber) {
                        return x;
                    }
                })[0].value;
            },


            /**
             * Update Packages list
             */
            async updatePackagesList(page) {
                if (page) {
                    this.setFilterPage(page)
                }
                this.sortColumn();
                await this.getReviewList(this.filterData)
                this.resetSelectedList()
            },

            async warningModalBtnFunction() {
                this.warningModalIsLoading = true;
                let data = {}
                if (!this.isSelectedAll && this.review.approveRejectID.length) {
                    data.providers = this.review.approveRejectID
                }

                if (this.isSelectedAll) {
                    data.all = true
                }

                if (Object.keys(data).length) {
                    await this.approveReviewData({ ...data }).then(async (e) => {
                        this.closeModalByName("warningModal");
                        await this.getReviewList(this.filterData)
                        this.resetSelectedList()
                    }).finally(() => {
                        this.warningModalIsLoading = false;
                    });
                }
                
            },

            async exportData() {
                this.filterReviews();
                await this.getReviewList({ ...this.filterData, excel: true })
                this.resetSelectedList()
            },

            selectAction(event, data, action) {
                let actionName = action.value

                switch (actionName) {
                    case 'detail': {
                        this.setIsOpenModal(true);
                        this.reviewDetail(data);
                        break
                    }
                    case 'approve': {
                        this.setData({
                            warningModalBtnText: "Approve",
                            warningModalIsDangerButton: false,
                        });
                        this.openModal("warningModal");
                        break
                    }
                    case 'reject': {
                        this.setData({
                            warningModalBtnText: "Reject",
                            warningModalIsDangerButton: true,
                        });
                        this.openModal("warningModal");
                    }
                }

                const selectedRows = []
                selectedRows.push({
                    id: data.rowData.id,
                    state: actionName === 'approve' ? 1 : 2
                })

                this.setApproveRejectID(selectedRows)
            },

            selectMultipleAction(action) {
                // if (this.selectedArrowList.length) {
                    switch (action) {
                        case 'Approve': {
                            this.setData({
                                warningModalBtnText: "Approve",
                                warningModalIsDangerButton: false,
                            })
                            this.openModal("warningModal")
                            break
                        }
                        case 'Reject': {
                            this.setData({
                                warningModalBtnText: "Reject",
                                warningModalIsDangerButton: true,
                            })
                            this.openModal("warningModal")
                        }
                    }

                    let selectedRows = [ ...this.selectedArrowList.map(item => item.id) ]
                    let reviewList = []

                    selectedRows.map(item => {
                        reviewList.push({
                            id: item,
                            state: action === 'Approve' ? 1 : 2
                        })
                    })

                    this.setApproveRejectID(reviewList)
                // }
            },

            /**
             * Reset filters and settings
             */
            async resetData() {
                this.filterModel.reset()
                this.filterReviews()
                await this.getReviewList()
                this.resetSelectedList()
            },

            async resetDataTableSettings() {
                await this.resetDataTable() 
                await this.getReviewSettings()
                this.createColumnConfigData()
            },

            closeModal() {
                const [value] = arguments;
                this[value] = false;
            }
        },

        watch: {
            "review.modal.warningModal"(newVal) {
                this.warningModalIsLoading = false;
                this.showWarningModal = newVal;
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/variables.scss";

    .approve-rej-icon-cont {
        font-size: 24px;

        .mdi {
            padding: 0 7px;
            margin-top: 3px;
            display: inline-block;
            cursor: pointer;
        }

        .mdi-cancel,
        .mdi-check-circle-outline {
            border-radius: $border-radius;
        }
    }

    .approve-rej-icon-cont::v-deep {
        .v-tooltip__content {
            border-radius: 6px;
            padding: 7px 9px;
            font-size: $txt12;
        }
    }

    .P-filter-open {
        .mdi-menu-right {
            transform: rotate(180deg);
        }
    }

    .P-users-list {
        position: relative;
    }

    .G-excel-btn {
        border-left: unset;
        padding-left: unset;
    }

    .action-cont-first {
        border-right: 1.5px solid $neutral-color;
        height: 36px;
        align-items: center;

        .v-menu__content {
            max-width: 190px !important;
        }

        .select-all-menu-cont {
            margin-top: 5px;
        }
    }

    .P-users-columns::v-deep {
        padding-left: 10px;
        .P-column-configs {
            max-width: 477px;
        }

        .P-column-configs ul li {
            width: 50%;
        }

        .v-menu__content {
            max-height: 360px !important;
        }
    }

    .P-main-column {
        li {
            background-color: $darkBg-color12MD;
        }
    }

    .theme--dark {
        .P-users-list::v-deep {
            .P-main-column {
                &:hover {
                    li {
                        background-color: $darkBg-color12MD !important;
                    }
                }
            }
        }
    }

    .theme--dark.P-actions-list {
        .v-list {
            background-color: #3a3b3f !important;
        }
    }
</style>