export default {
  data() {
    return {
      daysList: [],
      monthsList: []
    };
  },

  created() {
    //get days
    let daysCount = this.$moment().daysInMonth();
    for (let i = 1; i <= daysCount; i++) {
      this.daysList.push({
        value: i
      })
    }

    //get months
    for (let i = 1; i <= 36; i++) {
      if (i <= 12 || i === 24 || i === 36) {
        this.monthsList.push({
          value: i
        })
      }
    }
  }
};
