import eventbus from "./eventbus";
const hubUrl = `${process.env.VUE_APP_API_ENDPOINT}/hub`;
let connection;
const hubEventBus = eventbus;
import store from "../store"
const connectionTimeout = 2000;

const BASE_URL = process.env.VUE_APP_API_WS_URL;

const connectFunction = function (url) {
    try {
        let authToken = ''
        let obj = localStorage.getItem("user");
        let user = JSON.parse(obj);
        if (user && user.tokens.access) {
            authToken = user.tokens.access.token;
        }
        let _url = url;
        if (!_url) _url = BASE_URL;
        if (!_url) _url = 'ws://66.175.219.7:3000/'
        connection = new WebSocket(`${_url}echo?token=${authToken}`)

        connection.onmessage = function(event) {
            try {
                if (event.data === `OK`) {
                    store.commit('appModule/setHubState', 5)
                    return;
                }
                let data = JSON.parse(event.data);
                let message = data.message;

                if (data.scope === 'transaction') {
                    let text;
                    if (message.state === 1) {
                        text = `executed`;
                    } else if (message.state === 0) text = `failed`
                    else if (message.state === 3) text = `refunded`
                }
                hubEventBus.$emit(data.scope, message )
            } catch (ex) {
                console.error(`bad ws message: ${ex.message}`);
            }
        }
        
        connection.onclose = function(event) {
            try {
                hubEventBus.$clean()
                store.commit('appModule/setHubState', 0)
                setTimeout(function() {
                    store.commit('appModule/setHubState', 2)
                    connectFunction();
                }, connectionTimeout);
            } catch (e) {
                console.error(e);
            }
        }

        connection.onopen = function(event) {
            try {
                store.commit('appModule/setHubState', connection.readyState)
            } catch (e) {
                console.error(e);
            }
        }
    } catch (exc) {

    }
}

export default {
    // called by Vue.use(FirstPlugin)
    install(Vue, options) {
        // create a mixin

        Vue.prototype.$sendData = function (data) {
            // some logic ...
            return connection.send(data)
        }
        Vue.prototype.$disconnectHub = function () {
            if (connection) {
                connection.close();
            }
        }
        Vue.prototype.$connectHub = connectFunction;
        Vue.$getHubState = function () {
            // some logic ...
        }

        // 4. add an instance method
        Vue.prototype.$myMethod = function (methodOptions) {
            // some logic ...
        }

        Vue.prototype.$hubEventBus = hubEventBus;
        Vue.prototype.$hubUrl = BASE_URL;
    },
};
