import RequestService from "@/services/request.service";
import moment from 'moment';
import _ from 'lodash';
const FileDownload = require('js-file-download');
import TransactionsFilterModel from "../../models/transactions/TransactionsFilterModel";

import {
  incomeType,
  initiatorType,
  paymentMethods,
  transactionStatus,
  transactionType,
} from "./customTypes";

const InitialState = {
  filterModel: new TransactionsFilterModel(),
  filterRangeData: {
    transactionAmount: [-50000, 50000],
  },

  transactionStatus: transactionStatus,
  transactionType: transactionType,
  initiatorType: initiatorType,
  incomeType: incomeType,
  paymentMethods: paymentMethods,

  // filter data
  filter: {
    limit: 20,
    searchLimit: 20,
    sortBy: "executionDate:desc",
    page: 1,
  },
  //  for  pagination  table
  dataCount: 0,
  currentPage: 1,
  totalPages: 0,
  filterData: {},

  isTableLoading: false,


  /**
   * !IMPORTANT FOR TABLE
   * for  update  table  columns
   **/
  defaultSettings: [],
  updateColumnsFromBack: "transactionsModule/updateTransactionSettings",
  updateColumnSettingsCommit: "transactionsModule/setDefaultSettings",
  isDefault: false,
  //
  dataTable: [],
  transactionsResult: false,
  clientTransactions: [],
  showColumnConfig: true,
  amountSum: null,
};

/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  In the mutations part there is resetState(), 
  please new added variables add in that function as well
*/

export const transactionsModule = {
  namespaced: true,
  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue;
    },

    setSearchLimit(state, newValue) {
      state.filter.searchLimit = newValue
    },

    /**
     * Set client bills data
     */
    setTransactionsData(state, data) {
      state.dataTable = data.results;
      state.currentPage = data.page;
      state.totalPages = data.totalPages;
      state.dataCount = data.totalResults;
    },

    resetState(state) {
      state.filterModel = new TransactionsFilterModel()
      state.filterRangeData = {
        transactionAmount: [-50000, 50000],
      }

      state.transactionStatus = transactionStatus
      state.transactionType = transactionType
      state.initiatorType = initiatorType
      state.incomeType = incomeType
      state.paymentMethods = paymentMethods

      // filter data
      state.filter = {
        limit: 20,
        searchLimit: 20,
        sortBy: "executionDate:desc",
        page: 1,
      }
      //  for  pagination  table
      state.dataCount = 0
      state.currentPage = 1
      state.totalPages = 0
      state.filterData = {}

      state.isTableLoading = false


      /**
       * !IMPORTANT FOR TABLE
       * for  update  table  columns
       **/
      state.defaultSettings = []
      state.updateColumnsFromBack = "transactionsModule/updateTransactionSettings"
      state.updateColumnSettingsCommit = "transactionsModule/setDefaultSettings"
      state.isDefault = false
      //
      state.dataTable = []
      state.transactionsResult = false
      state.clientTransactions = []
      state.showColumnConfig = true
      state.amountSum = null
    },
    /**
     * Set Settings from Back-ed
     * **/
    setDefaultSettingsForBack(state, data) {
      if (data.transactionSettings) {
        state.isDefault = data.transactionSettings.isDefault;
        state.defaultSettings = data.transactionSettings.transactionTable;
      }
    },

    /**
     * Set Settings from Commit
     * **/

    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault;
      }
    },

    /**
     * Set client bills data
     */
    setTransactionsData(state, data) {
      state.dataTable = data.results;
      state.currentPage = data.page;
      state.totalPages = data.totalPages;
      state.dataCount = data.totalResults;
    },

    /**
     * Set pagination filter
     * **/
    setFilterPage(state, page) {
      state.filter.page = page;
      state.currentPage = page;
    },
    /**
     * Set Transaction Filter new Object
     * **/
    filterTransactions(state) {
      const filterObject = {};
      if (state.filterModel.search) {
        filterObject.search = state.filterModel.search;
      }
      if (state.filterModel.executionDate?.start && state.filterModel.executionDate?.end) {
        filterObject.executionStartDate =
          moment(state.filterModel.executionDate?.start).format('MM/DD/YYYY');
        filterObject.executionEndDate =
          moment(state.filterModel.executionDate?.end).format('MM/DD/YYYY');
      }
      if (
        state.filterModel.transactionState ||
        state.filterModel.transactionState === 0
      ) {
        filterObject.transactionState = state.filterModel.transactionState;
      }
      if (state.filterModel.fee !== null) {
        filterObject.fee = state.filterModel.fee
      }
      if (
        state.filterModel.isRefund !== null
      ) {
        filterObject.isRefund = state.filterModel.isRefund;
      }
      if (state.filterModel.toProvider.length) {
        filterObject.toProvider = state.filterModel.toProvider
      }
      if (
        state.filterModel.transactionType ||
        state.filterModel.transactionType === 0
      ) {
        filterObject.transactionType = state.filterModel.transactionType;
      }
      if (state.filterModel.merchant) {
        filterObject.merchant = state.filterModel.merchant
      }
      if (state.filterModel.autopayment) {
        filterObject.autopayment = state.filterModel.autopayment
      }
      if (
        state.filterModel.transactionAmount[1] &&
        (state.filterModel.transactionAmount[0] ||
          state.filterModel.transactionAmount[0] === 0)
      ) {
        filterObject.amountMin = state.filterModel.transactionAmount[0];
        filterObject.amountMax = state.filterModel.transactionAmount[1];
      }
      if (
        state.filterModel.initiatorType ||
        state.filterModel.initiatorType === 0
      ) {
        filterObject.initiatorType = state.filterModel.initiatorType;
      }
      if (state.filterModel.allResellers) {
        filterObject.allResellers = true;
      }
      if (state.filterModel.resellers.length) {
        filterObject.resellers = state.filterModel.resellers;
      }
      if (state.filterModel.inOut) {
        if (state.filterModel.inOut === 'in') {
          filterObject.in = true;
        }
        
        if (state.filterModel.inOut === 'out') {
          filterObject.out = true;
        }
      }
      if (state.filterModel.v2) {
        filterObject.v2 = true;
      }
      if (state.filterData?.sortBy) {
        filterObject.sortBy = state.filterData.sortBy
      }
      state.filterData = filterObject;
    },
    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filterData?.sortBy) { 
          state.filterData.sortBy = "executionDate:desc"; 
        }
      }
    },
  },
  actions: {
    getTransactionList({ commit, state, rootState }, filter = null) {
      let options = {
        page: state.currentPage,
      };

      if (rootState.route?.name !== 'Search') {
        options.limit = state.filter.limit
      } else {
        options.limit = state.filter.searchLimit
      }

      const clonedFilter = filter ? _.cloneDeep(filter) : _.cloneDeep(state.filter)
      delete clonedFilter.searchLimit
      delete clonedFilter.limit
      options = Object.assign(options, clonedFilter);

      if (rootState.generalSearch.searchedClientId) {
        options.client = rootState.generalSearch.searchedClientId
      }

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      const { client } = options;
      if (client)
       delete options.client;

      let query = Object.keys(options)
        .map((key) => key + "=" + options[key])
        .join("&");

      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = 'blob';
      }
      
      if (!isExcelRequest) {
        commit('setData', { isTableLoading: true })
      }
      let constructedUrl = `transactions?${query}`;
      if (client) {
        constructedUrl = `transactions/client/${client}?${query}`;
      }
      return RequestService.read(constructedUrl, readOptions)
        .then((response) => {
          if (!isExcelRequest) {
            if (response.data.results && response.data.results.length) {
              commit("setData", { transactionsResult: true });
            } else {
              commit("setData", { transactionsResult: false });
            }
            commit("setTransactionsData", response.data);
            commit("setData", { 
              amountSum: response.data?.amountSum,
              isTableLoading: false
            });
          } else {
            FileDownload(response.data, 'transactions.xlsx'); // TODO actual file name from response.headers
          }
        })
        .catch((e) => console.log("error ", e))
        .finally(() =>  commit('setData', { isTableLoading: false }));
    },

    /**
     * Get TransactionSettingsList
     * **/
    getTransactionSettings({ commit, state }) {
      return RequestService.read(`users/settings`).then(
        (data) => {
          const limit = data?.data?.transactionSettings?.limit
            ? data.data.transactionSettings.limit
            : state.filter.limit;

          const searchLimit = data?.data?.transactionSettings?.searchLimit
            ? data.data.transactionSettings.searchLimit
            : state.filter.searchLimit;

          commit("setDefaultSettingsForBack", data.data);
          if (limit) {
            commit("setLimit", limit);
          }

          if (searchLimit) {
            commit("setSearchLimit", searchLimit);
          }
          
          return Promise.resolve(data.data);
        },
        (error) => {
          console.log("error ", error);
        }
      );
    },
    updateTransactionSettings({ state, commit }, data) {
      return RequestService.update(`users/settings`, {
        transactionSettings: {
          transactionTable: data,
          isDefault: state.isDefault,
          limit: state.filter.limit,
          searchLimit: state.filter.searchLimit
        },
      }).then(
        (data) => {
          console.log("data ", data);
        },
        (error) => {
          console.log("error ", error);
        }
      );
    },

    voidTrasnaction({ state }, id) {
      return RequestService.update(`transactions/void/${id}`).catch(e => console.log(e))
    },

    sendTransaction({ state }, {id, data}) {
      return RequestService.update(`transactions/send/${id}`, data).catch(e => console.log(e))
    },

    printTransaction({ state }, id) {
      return RequestService.update(`transactions/print/${id}`).catch(e => console.log(e))
    }
  },
};
