<template>
  <div class="Ott-radio-cont" :class="customRadioClass">
    <v-radio
      :label="label"
      :value="value"
      :ripple="ripple"
      :error="error"
      :customRadioClass="customRadioClass"
      :disabled="disabled"
    >
      <template slot="label">
        <slot name="customLabel"></slot>
      </template>
    </v-radio>
  </div>
</template>

<script>
/** use radio-group out of this component
     * example!
     * <v-radio-group v-model="radioGroup">
        <div v-for="n in 3">
          <ott-radio :value="n"></ott-radio>
        </div>
        </v-radio-group>
     *
     **/
export default {
  name: "OttRadio",
  props: {
    label: {
      type: String,
      default: ""
    },
    ripple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String | Number
    },
    customRadioClass: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
.v-input--has-state.error--text .v-label {
  -webkit-animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  animation: v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}
.Ott-radio-cont::v-deep {


  cursor: pointer;
  .v-input--selection-controls__input {
    height: 20px;
  }
  .v-icon.v-icon {
    font-size: 20px;
  }
  .theme--light {
    .v-label {
      color: $secondary-color;
      text-transform: capitalize !important;
    }
    .v-icon {
      color: $neutral-color;
    }
  }
  .theme--dark {
    .v-label {
      color: $white!important;
    }
    .v-icon {
      color: $neutral-color45;
    }
  }
  .v-icon.theme--dark.primary--text {
    color: $primary-colorDM !important;
  }
  .v-label {
    font-size: $txt14;
    font-weight: $regular;
    text-transform: capitalize;
  }
}
</style>
