import RequestService from "@/services/request.service";

const InitialState = {
  dateFormat: [
    {
      name: "mm/dd/yyyy",
      value: "mm/dd/yyyy"
    },
    {
      name: "dd/mm/yyyy",
      value: "dd/mm/yyyy"
    },
    {
      name: "yyyy/mm/dd",
      value: "yyyy/mm/dd"
    }
  ],
  currency: [
    {
      name: "USD",
      value: "USD"
    },
  ],
  paperFormat: [
    {
      name: "A4 paper (210 x 297 mm)",
      value: 'A4 paper (210 x 297 mm)'
    },
    {
      name: "American letter (216 x 279 mm)",
      value: 'American letter (216 x 279 mm)'
    }
  ],
  invoiceGenerateDay: [
    {
      name: 10,
      value: 10
    },
    {
      name: 15,
      value: 15
    },
    {
      name: 20,
      value: 20
    },
    {
      name: 25,
      value: 25
    }
  ],
  language: [
    {
      name: "English",
      value: "English"
    },
    {
      name: "Russian",
      value: "Russian"
    },
    {
      name: "Hebrew",
      value: "Hebrew"
    }
  ],
  templates: [
    {
      name: "Template 1",
      value: 'template1'
    },

  ],
  settingsData: {
    dateFormat: null,
    timezone: null,
    currency: null,
    language: null,
    paperFormat: null,
    invoiceGenerateDay: null,
    phone: null,
    email: null,
    postalMethod: null,
    autosend: 0,
    returnEnvelope: false,
    address: null,
    comment: "",
    website: ""
  },
  designData: {
    color: "#0A3C68",
    template: 'template1',
    invoiceTitle: "",
    quoteTitle: "",
    creditNoteTitle: "",
    logo:''
  },
  imageFile: null,
  imagePath: "",
  selectTab: 1,
  flagForWebsite: false,
  validationLogo: false,
  isEditSettings: false,
  isEditDesign: false,
  invoiceImage:'',
  invoiceHtml: null
};

export const invoiceAdmin = {
  namespaced: true,
  actions: {

    getInvoiceSettings({state, commit}, providerId) {
      return RequestService.read(
        `ottProviders/invoices/edit/${providerId}`
      ).then(
        data => {
          commit("fetchData", data.data);
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    manageInvoiceData({state, commit}, data){
      if (data?.data) {
        delete data.data?.design?.createdAt
        if (data?.data?.settings) {
          delete data.data.settings.createdAt
        }
      }
      return RequestService.update(
        `ottProviders/invoices/edit/${data.providerId}`, data.data
      ).then(
        data => {
          commit("fetchData", data.data);
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getPdf({state, commit}, providerId){
      return RequestService.read(
        `ottProviders/invoices/print/${providerId}`
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getHtml({state, commit}, providerId) {
      return RequestService.read(`ottProviders/invoices/html/${providerId}`)
        .then(data => {
          commit('setData', {invoiceHtml: data.data.html})
          return Promise.resolve(data.html);
        })
        .catch(error => Promise.reject(error))
    }
  },
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },

    /**
     * get invoice settings data
     * **/
    fetchData(state, data) {
      if(data.settings){
        state.settingsData = data.settings
        delete state.settingsData.updatedAt
        delete state.settingsData.id
      }
      if(data.design){
        state.designData = data.design
        delete state.designData.updatedAt
        delete state.designData.id
      }
    },
    /**
     * Create image path from edit  user
     * **/
    createImagePath(state){
      if(state.designData.logo){
        state.imagePath =`${process.env.VUE_APP_API_URL}files/icon/${state.designData.logo}`
      }
    },

    replaceHtml(state, action) {
      state.invoiceHtml = state.invoiceHtml.replace(action.type, action.payload)
    },


    /**
     * Invoice Settings tab
     * **/
    setDateFormat(state, value) {
      state.settingsData.dateFormat = value;
    },
    setTimeZone(state, value) {
      state.settingsData.timezone = value;
    },
    setCurrency(state, value) {
      state.settingsData.currency = value;
    },
    setLanguage(state, value) {
      state.settingsData.language = value;
    },
    setPaperFormat(state, value) {
      state.settingsData.paperFormat = value;
    },
    setInvoiceGenerateDate(state, value) {
      state.settingsData.invoiceGenerateDay = value;
    },
    setPhoneNumber(state, value) {
      state.settingsData.phone = value;
    },
    setEmail(state, value) {
      state.settingsData.email = value;
    },
    setPostalMethod(state, value) {
      state.settingsData.postalMethod = value;
    },
    setAutosend(state, value) {
      state.settingsData.autosend = value
    },
    setReturnEnvelope(state, value) {
      state.settingsData.returnEnvelope = value;
    },
    setAddress(state, value) {
      state.settingsData.address = value;
    },
    setComment(state, value) {
      state.settingsData.comment = value;
    },
    setWebSite(state, value) {
      state.settingsData.website = value;
    },

    resetDataSettings(state) {
      state.settingsData = JSON.parse(
        JSON.stringify(InitialState.settingsData)
      );
    },

    /**
     * Invoice design tab
     * **/

    setColor(state, value) {
      state.designData.color = value;
    },
    setLogo(state, value) {
      state.designData.logo = value;
    },

    setInvoiceTemplate(state, value) {
      state.designData.template = value;
    },

    setInvoiceTitle(state, value) {
      state.designData.invoiceTitle = value;
    },
    setQuoteTitle(state, value) {
      state.designData.quoteTitle = value;
    },
    setCreditNoteTitle(state, value) {
      state.designData.creditNoteTitle = value;
    },
    setImageFile(state, value) {
      state.imageFile = value;
    },
    setImagePath(state, value) {
      state.imagePath = value;
      state.validationLogo = false;
    },

    resetDataDesign(state) {
      state.designData = JSON.parse(JSON.stringify(InitialState.designData));
    },
    resetAllData(state) {
      state.designData = JSON.parse(JSON.stringify(InitialState.designData));
      state.settingsData = JSON.parse(
        JSON.stringify(InitialState.settingsData)
      );
      state.imageFile = null;
      state.imagePath = "";
      state.flagForWebsite = false;
      state.isEditSettings = false;
      state.isEditDesign = false;
      state.selectTab = 1
      state.invoiceImage = ''
    },
    setActiveTab(state, index) {
      state.selectTab = index;
    },
    setFlagWebsite(state) {
      state.flagForWebsite = true;
    },
    validationLogoPath(state) {
      state.validationLogo = !state.imagePath;
    },
    setIsEditInvoiceSettings(state) {
      state.isEditSettings = true;
    },
    setIsEditInvoiceDesign(state) {
      state.isEditDesign = true;
    },
    generateInvoiceImage(state, id = null){
      if(id){
        state.invoiceImage =`${process.env.VUE_APP_API_URL}ottProviders/invoices/print/${id}?type=2`
      }else{
        state.invoiceImage=''
      }
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }

  }
};
