export default class FlussonicsModel {
  constructor() {
    this.reset();
  }

  toSave() {
    return {};
  }

  reset() {
    this.search = '';
    this.enabled = null;
    this.collectStat = null;
    this.remoteUpdate = null;
    this.channelsMediainfo = null;
    this.splitSources = null;
    this.isEdge = null;
    this.hostOnlineStatus = null;
    this.flussonicOnlineStatus = null;
    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);
    this.filterCount.splice(index, 1);
    switch (dataKey) {
      case "search": {
        this.search = "";
        break;
      }
      case "enabled": {
        this.enabled = null;
        break;
      }
      case "collectStat": {
        this.collectStat = null;
        break;
      }
      case "remoteUpdate": {
        this.remoteUpdate = null;
        break;
      }
      case "channelsMediainfo": {
        this.channelsMediainfo = null;
        break;
      }
      case "splitSources": {
        this.splitSources = null;
        break;
      }
      case "isEdge": {
        this.isEdge = null;
        break;
      }
      case "hostOnlineStatus": {
        this.hostOnlineStatus = null;
        break;
      }
      case "flussonicOnlineStatus": {
        this.flussonicOnlineStatus = null;
        break;
      }
    }
  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes("search")) {
      this.filterCount.push("search");
    }
    if (this.enabled && !this.filterCount.includes("enabled")) {
      this.filterCount.push("enabled");
    }
    if (this.collectStat && !this.filterCount.includes("collectStat")) {
      this.filterCount.push("collectStat");
    }
    if (this.remoteUpdate && !this.filterCount.includes("remoteUpdate")) {
      this.filterCount.push("remoteUpdate");
    }
    if (this.channelsMediainfo && !this.filterCount.includes("channelsMediainfo")) {
      this.filterCount.push("channelsMediainfo");
    }
    if (this.splitSources && !this.filterCount.includes("splitSources")) {
      this.filterCount.push("splitSources");
    }
    if (this.isEdge && !this.filterCount.includes("isEdge")) {
      this.filterCount.push("isEdge");
    }
    if (this.hostOnlineStatus && !this.filterCount.includes("hostOnlineStatus")) {
      this.filterCount.push("hostOnlineStatus");
    }
    if (this.flussonicOnlineStatus && !this.filterCount.includes("flussonicOnlineStatus")) {
      this.filterCount.push("flussonicOnlineStatus");
    }
  }
}
