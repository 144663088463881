import RequestService from "@/services/request.service";
import moment from "moment";
import ShippingsModel from "@/models/shippings/ShippingsModel";
import _ from "lodash";
const FileDownload = require("js-file-download");

const InitialState = {
  showWarningModal: false,

  // filter data
  filterModel: new ShippingsModel(),
  filter: {
    limit: 20,
    searchLimit: 20,
    sortBy: "_id:desc",
    page: 1,
  },

  dataTable: [],
  allPackagesCount: 0,
  shippingId: null,

  // for pagination
  currentPage: 0,
  dataCount: 0,
  totalPages: 0,
  filterData: {},

  // table loading
  isTableLoading: true,

  // for  update  table  columns
  defaultSettings: [], // TODO CHANGE_SORT   cloneDefaultSettings: defaultColumnConfig, removed
  updateColumnsFromBack: "shippingsModule/updateShippingsSettings",
  updateColumnSettingsCommit: "shippingsModule/setDefaultSettings",
  isDefault: false,
  shippingsResult: false,
  isOpenShippingsModal: false,
  selectedShippingtId: null,
  showColumnConfig: true,
  shippingBalance: null
};

/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  In the mutations part there is resetState(), 
  please new added variables add in that function as well
*/

export const shippingsModule = {
  namespaced: true,
  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    /**
     * Filter shippings
     */
    filterShippings(state) {
      const filterObject = {};

      if (state.filterModel.search) {
        filterObject.search = state.filterModel.search;
      }

      if (state.filterModel.courier) {
        filterObject.courier = state.filterModel.courier;
      }

      if (state.filterModel.date?.start && state.filterModel.date?.end) {
        filterObject.startDate = 
          moment(state.filterModel.date?.start).format('MM/DD/YYYY');
        filterObject.endDate = 
          moment(state.filterModel.date?.end).format('MM/DD/YYYY');    
      }

      if (state.filterModel.allResellers) {
        filterObject.allResellers = state.filterModel.allResellers;
      }
      
      if (state.filterModel.resellers.length) {
        filterObject.resellers = state.filterModel.resellers;
      }
      
      if (state.filterData?.sortBy) {
        filterObject.sortBy = state.filterData?.sortBy
      }

      state.filterData = filterObject;
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue
    },

    setSearchLimit(state, newValue) {
      state.filter.searchLimit = newValue
    },
    
    /**
     * Set pagination filter
     * **/
    setFilterPage(state, page) {
      state.filter.page = page;
      state.currentPage = page;
    },
    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filterData?.sortBy) { 
          state.filterData.sortBy = "_id:desc"; 
        }
      }
    },

    /**
     * Reset filter
     */
    resetFilter(state) {
      state.filter = Object.assign({}, InitialState.filter);
    },
    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault;
      }
    },

    setDefaultSettingsForBack(state, data) {
      if (data.shippingSettings) {
        state.isDefault = data.shippingSettings.isDefault;
        state.defaultSettings = data.shippingSettings.shippingTable;
      }
    },

    resetState(state) {
      state.showWarningModal = false

      // filter data
      state.filterModel = new ShippingsModel()
      state.filter = {
        limit: 20,
        searchLimit: 20,
        sortBy: "_id:desc",
        page: 1,
      },

      state.dataTable = []
      state.allPackagesCount = 0
      state.shippingId = null

      // for pagination
      state.currentPage = 0
      state.dataCount = 0
      state.totalPages = 0
      state.filterData = {}

      // table loading
      state.isTableLoading = true

      // for  update  table  columns
      state.defaultSettings = [], // TODO CHANGE_SORT   cloneDefaultSettings: defaultColumnConfig, removed
      state.updateColumnsFromBack = "shippingsModule/updateShippingsSettings"
      state.updateColumnSettingsCommit = "shippingsModule/setDefaultSettings"
      state.isDefault = false
      state.shippingsResult = false
      state.isOpenShippingsModal = false
      state.selectedShippingtId = null
      state.showColumnConfig = true

      // shippingBalance
      state.shippingBalance = null

    }
  },
  actions: {
    getShippingsList({ commit, state, rootState }, filter = null) {
      let options = {
        page: state.currentPage ? state.currentPage : 1,
      };
      
      if (rootState.route?.name !== 'Search') {
        options.limit = state.filter.limit
      } else {
        options.limit = state.filter.searchLimit
      }

      const clonedFilter = filter ? _.cloneDeep(filter) : _.cloneDeep(state.filter)
      delete clonedFilter.searchLimit
      delete clonedFilter.limit

      options = Object.assign(options, clonedFilter);

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      let urI = `shippings`;

      if (rootState.generalSearch.searchedClientId) {
        options.client = rootState.generalSearch.searchedClientId;
        urI += `/client`
      }

      let query = Object.keys(options)
        .map((key) => key + "=" + options[key])
        .join("&");
      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      
      if (isExcelRequest) {
        readOptions.responseType = "blob";
      } else {
        commit("setData", { isTableLoading: true });
      }

      return RequestService.read(`${urI}?${query}`, readOptions)
        .then((response) => {
          if (!isExcelRequest) {
            if (response.data.results && response.data.results.length) {
              commit("setData", { shippingsResult : true });
            } else {
              commit("setData", { shippingsResult : false });
            }
            commit("setData", { 
                dataTable: response.data.results,
                currentPage: response.data.page,
                totalPages: response.data.totalPages,
                dataCount: response.data.totalResults,
                isTableLoading: false
            });
          } else {
            FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
          }

          if ('shippingBalance' in response.data) {
            commit('setData', { shippingBalance: response.data.shippingBalance })
          }
        })
        .catch((e) => console.log("error ", e))
        .finally(() => {
          commit("setData", { isTableLoading: false })
        });
    },

    getShippingsSettings({ commit, state }) {
      return RequestService.read(`users/settings`).then(
        (res) => {
          const limit = res?.data?.shippingSettings?.limit ? res.data.shippingSettings.limit : state.filter.limit
          const searchLimit = res?.data?.shippingSettings?.searchLimit ? res.data.shippingSettings.searchLimit : state.filter.searchLimit

          commit("setDefaultSettingsForBack", res.data);
          commit('setLimit', limit);
          commit('setSearchLimit', searchLimit);
          return Promise.resolve(res.data);
        },
        (error) => {
          console.log("error ", error);
        }
      );
    },

    generateLabel({ commit, state }, shippingId) {
      return RequestService.read(`shippings/generateLabel/${shippingId}`).then((response) => {
        // commit('setData', { couriersList: response.data.rates, hasError: false, shipping: null })
        // if (!data.isCheck) {
        //   commit('setData', { shipping: response.data.shipping })
        // }
        return Promise.resolve(true);
      })
          .catch((error) => {
            // const message = error.response.data.message;
            // commit('setData', { hasError: true, errorMessage: message, shipping: null })
            return Promise.resolve(false);
          })
    },

    deleteShipping({ commit }, shippingId) {
      return RequestService.delete(`shippings/edit/${shippingId}`).then(response => {
        console.log(response)
      })
    },

    cancelShipping({ commit }, shippingId) {
      return RequestService.update(`shippings/cancel/${shippingId}`).then(response => {
        console.log(response)
      })
    },

    updateShippingsSettings({state, commit}, data) {
      return RequestService.update(`users/settings`, {
        shippingSettings: {
          shippingTable: data,
          isDefault: state.isDefault,
          limit: state.filter.limit,
          searchLimit: state.filter.searchLimit
        }
      })
      .then((r) => { return Promise.resolve(r.data) })
      .catch((e) => console.log(e))
    },
  },
};
