const searchComponent = () => import("@/views/general-search/GeneralSearch");

export const generalSearchRout = {
    path: "/search/:type/:id",
    name: "Search",
    component: searchComponent,
    // children: [
    //   {
    //     path: "",
    //     name: "",
    //     component: generalSearch,
    //   }
  
    // ]
  };
  