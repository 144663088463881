<template>
  <div class="P-sign d-flex flex-column">
    <div class="P-sign-container">
      <VueSignaturePad
        width="100%"
        height="100%"
        ref="signaturePad"
        :options="options"
      />
    </div>
    <div class="P-buttons G-justify-center">
      <save-reset-buttons
        :left-click-func="() => clear()"
        :right-click-func="() => save()"
        right-label="Save"
        left-label="Clear"
      />
    </div>
  </div>
</template>

<script>
import SaveResetButtons from "@/components/SaveResetButtons";
export default {
  name: "SignPad",
  data() {
    return {
      statisticData: {},
      options: {
        penColor: "black",
      },
    };
  },

  components: {
    SaveResetButtons,
  },

  methods: {
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.$emit("save", { isEmpty, data });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/variables";
.P-sign-container {
  height: 55vh;
  box-shadow: 1px 1px 9px 4px lightgrey;
  border-radius: 10px;
}
</style>
