export const loginStatusList = [
  {
    name: 'Logout',
    value: 0,
  },
  {
    name: 'Active Now',
    value: 1,
  },
  {
    name: 'Unsuccessful',
    value: 2,
  }
]
export const userStatusList = [
  {
    name: 'Disabled',
    value: 0,
  },
  {
    name: 'Enabled',
    value: 1,
  }
]
