export default class ClientsFilterModel {
  constructor() {
    this.reset();
  }

  toSave() {
    return {};
  }

  reset() {
    this.search = '';
    this.subscriptionState = null;
    this.statusFilterType = null;
    this.providers = [];
    this.reseller = null;
    this.priceGroup = null;
    this.currency = null;
    this.balance = [0, 0];
    this.credit = [0, 0];
    this.creditDate = {
      start: null,
      end: null
    },
    this.creditExpired = false;
    this.creditAutoExtend = false;
    this.debt = [0, 0];
    this.isBlocked = false;
    this.inPaused = false;
    this.activePackages = [0, 0];
    this.packagesDate = {
      start: null,
      end: null
    },
    this.packageExpired = false;
    this.autopayment = false;
    this.server = null;
    this.timezone = null;
    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);
    this.filterCount.splice(index, 1);
    switch (dataKey) {
      case "search": {
        this.search = "";
        break;
      }
      case "subscriptionState": {
        this.subscriptionState = null;
        break;
      }
      case "statusFilterType": {
        this.statusFilterType = null;
        break;
      }
      case "providers": {
        this.providers = [];
        break;
      }
      case "reseller": {
        this.reseller = null;
        break;
      }
      case "priceGroup": {
        this.priceGroup = null;
        break;
      }
      case "currency": {
        this.currency = null;
        break;
      }

      case "balance": {
        this.balance = [0, 0];
        break;
      }
      case "credit": {
        this.credit = [0, 0];
        break;
      }
      case "creditDate": {
        this.creditDate = {
          start: null,
          end: null
        };
        break;
      }
      case "packagesDate": {
        this.packagesDate = {
          start: null,
          end: null
        };
        break;
      }
      case "creditExpired": {
        this.creditExpired = false;
        break;
      }
      case "creditAutoExtend": {
        this.creditAutoExtend = false;
        break;
      }
      case "debt": {
        this.debt = [0, 0];
        break;
      }
      case "isBlocked": {
        this.isBlocked = false;
        break;
      }
      case "inPaused": {
        this.inPaused = false;
        break;
      }
      case "activePackages": {
        this.activePackages = [0, 0];
        break;
      }
      case "packageExpired": {
        this.packageExpired = false;
        break;
      }
      case "autopayment": {
        this.autopayment = false;
        break;
      }
      case "server": {
        this.server = null;
        break;
      }
      case "timezone": {
        this.timezone = null;
        break;
      }
    }
  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes("search")) {
      this.filterCount.push("search");
    }
    if (this.subscriptionState !== null && !this.filterCount.includes("subscriptionState")) {
      this.filterCount.push("subscriptionState");
    }
    if (this.subscriptionState !== null && this.statusFilterType && !this.filterCount.includes("statusFilterType")) {
      this.filterCount.push("statusFilterType");
    }
    if (this.providers.length && !this.filterCount.includes("providers")) {
      this.filterCount.push("providers");
    }
    if (this.reseller && !this.filterCount.includes("reseller")) {
      this.filterCount.push("reseller");
    }
    if (this.priceGroup && !this.filterCount.includes("priceGroup")) {
      this.filterCount.push("priceGroup");
    }

    if (this.currency && !this.filterCount.includes("currency")) {
      this.filterCount.push("currency");
    }
    if (
      (this.balance[0] || this.balance[1]) &&
      !this.filterCount.includes("balance")
    ) {
      this.filterCount.push("balance");
    }
    if (
      (this.credit[0] || this.credit[1]) &&
      !this.filterCount.includes("credit")
    ) {
      this.filterCount.push("credit");
    }
    if (
      this.creditDate?.start &&
      this.creditDate?.end &&
      !this.filterCount.includes("creditDate")
    ) {
      this.filterCount.push("creditDate");
    }
    if (this.creditExpired && !this.filterCount.includes("creditExpired")) {
      this.filterCount.push("creditExpired");
    }
    if (this.creditAutoExtend && !this.filterCount.includes("creditAutoExtend")) {
      this.filterCount.push("creditAutoExtend");
    }
    if (
      (this.debt[0] || this.debt[1]) &&
      !this.filterCount.includes("debt")
    ) {
      this.filterCount.push("debt");
    }
    if (this.isBlocked && !this.filterCount.includes("isBlocked")) {
      this.filterCount.push("isBlocked");
    }
    if (this.inPaused && !this.filterCount.includes("inPaused")) {
      this.filterCount.push("inPaused");
    }
    if (
      (this.activePackages[0] || this.activePackages[1]) &&
      !this.filterCount.includes("activePackages")
    ) {
      this.filterCount.push("activePackages");
    }
    if (
      this.packagesDate?.start &&
      this.packagesDate?.end &&
      !this.filterCount.includes("packagesDate")
    ) {
      this.filterCount.push("packagesDate");
    }
    if (this.packageExpired && !this.filterCount.includes("packageExpired")) {
      this.filterCount.push("packageExpired");
    }
    if (this.autopayment && !this.filterCount.includes("autopayment")) {
      this.filterCount.push("autopayment");
    }
    if (this.server && !this.filterCount.includes("server")) {
      this.filterCount.push("server");
    }
    if (this.timezone && !this.filterCount.includes("timezone")) {
      this.filterCount.push("timezone");
    }
  }

}
