import RequestService from "@/services/request.service";

const InitialState = {
    generalInfo: {
        packageName: '',
        packageType: '',
        description: '',
        forClients: false,
        forResale: false,
        disable: false,
        timeshiftService: 0,
        archiveService: 0,
        vodService: 0
    },
    packageTypes: [
        {
            type: 'Base',
            value: 'base'
        },
        {
            type: 'Advanced',
            value: 'advanced'
        }
    ],
    packageInfo: null
}

export const packagesGeneralInfo = {
    namespaced: true,
    state: JSON.parse(JSON.stringify(InitialState)),
    mutations: {
        /**
         * General info data
         */
        setPackageName(state, value) {
            state.generalInfo.packageName = value
        },
        setPackageType(state, value) {
            state.generalInfo.packageType = value
        },
        setDescription(state, value) {
            state.generalInfo.description = value
        },
        setForClients(state, value) {
            state.generalInfo.forClients = value
        },
        setForResale(state, value) {
            state.generalInfo.forResale = value
        },
        setDisable(state, value) {
            state.generalInfo.disable = value
        },

        /**
         * Set general info
         */
        setGeneralInfo(state, data) {
            // package name
            if (data.name && data.name.length) {
                let packageName = data.name.filter(item => item.lang === 'en' || item.lang === 'EN')
                let packageNameRu = data.name.filter(item => item.lang === 'ru' || item.lang === 'RU')
                if (packageName && packageName.length) {
                    state.generalInfo.packageName = packageName[0].name
                } else {
                    state.generalInfo.packageName = packageNameRu[0].name
                }
            }
            // package description
            if (data.description && data.description.length) {
                state.generalInfo.description = data.description[0].name
            }
            // status (0 - disable, 1 - enable)
            if (!data.status) {
                state.generalInfo.disable = true
            }

            // package type (1 - base, 2 - advanced)
            if (data.type === 1) {
                state.generalInfo.packageType = 'base'
            } else {
                state.generalInfo.packageType = 'advanced'
            }
            // services
            if (data.tEnable) {
                state.generalInfo.timeshiftService = data.tEnable
            }
            if (data.aEnable) {
                state.generalInfo.archiveService = data.aEnable
            }
            if (data.vEnable) {
                state.generalInfo.vodService = data.vEnable
            }
            // for clients, for resale
            if (data.forClients) {
                state.generalInfo.forClients = data.forClients
            }
            if (data.forResale) {
                state.generalInfo.forResale = data.forResale
            }
        },

        /**
         * Reset general info
         */
        resetGeneralInfo(state) {
            state.generalInfo = JSON.parse(JSON.stringify(InitialState.generalInfo))
        },
        resetGeneralInfoNotServices(state) {
            state.generalInfo.packageName = ''
            state.generalInfo.packageType = ''
            state.generalInfo.description = ''
            state.generalInfo.forClients = false
            state.generalInfo.forResale = false
            state.generalInfo.disable = false
        },

        setPackageInfo(state, data) {
            state.packageInfo = data
        },

        resetState(state) {
            for (let key in state) {
                state[key] = JSON.parse(JSON.stringify(InitialState[key]))
            }
        }
    },
    actions: {
        getPackage({commit, state}, {packageId}) {
            return RequestService.read(`packages/edit/${packageId}`)
                .then(response => {
                    commit('setGeneralInfo', response.data)
                    commit('setPackageInfo', response.data)
                })
                .catch(e => console.log('error ', e))
        }
    }
}