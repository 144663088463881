export default class TransactionsFilterModel {
    constructor() {
        this.reset();
    }

    toSave() {
        return {};
    }

    reset() {
        this.search = '';
        this.allResellers = null;
        this.resellers = [];
        this.inOut = null;
        this.v2 = null;
        this.executionDate = {
            start: null,
            end: null
        };
        this.transactionState = null;
        this.fee = null;
        this.isRefund = null;
        this.transactionType = null;
        this.merchant = '';
        this.autopayment = false;
        this.transactionAmount = [0, 0];
        this.initiatorType = null;
        this.searchLogin = ''             // filter and click from table
        this.myIncomeType = null;
        this.toProvider = [];
        this.filterCount = [];
    }

    resetCurrentData(dataKey) {
        let index = this.filterCount.indexOf(dataKey);
        this.filterCount.splice(index, 1);
        switch (dataKey) {
            case "search": {
                this.search = "";
                break;
            }
            case "resellers": {
                this.resellers = [];
                break;
            }
            case "allResellers": {
                this.allResellers = false;
                break;
            }
            case "inOut": {
                this.inOut = null;
                break;
            }
            case "v2": {
                this.v2 = null;
                break;
            }
            case "executionDate": {
                this.executionDate = {
                    start: null,
                    end: null
                };
                break;
            }
            case "transactionState": {
                this.transactionState = null;
                break;
            }
            case "fee": {
                this.fee = null;
                break;
            }
            case "isRefund": {
                this.isRefund = null;
                break;
            }
            case "toProvider": {
                this.toProvider = [];
                break;
            }
            case "transactionType": {
                this.transactionType = null;
                break;
            }
            case "merchant": {
                this.merchant = '';
                break;
            }
            case 'autopayment': {
                this.autopayment = false
                break;
            }
            case "transactionAmount": {
                this.transactionAmount = [0, 0];
                break;
            }

            case "initiatorType": {
                this.initiatorType = null;
                break;
            }

            case "searchLogin": {
                this.searchLogin = '';
                break;
            }
            case "myIncomeType": {
                this.myIncomeType = null;
                break;
            }
        }

    }

    getFilterCount() {
        if (this.search.trim() && !this.filterCount.includes("search")) {
            this.filterCount.push("search");
        }
        if (this.resellers.length && !this.filterCount.includes("resellers")) {
            this.filterCount.push("resellers")
        }
        if (this.allResellers && !this.filterCount.includes("allResellers")) {
            this.filterCount.push("allResellers")
        }
        if (this.inOut && !this.filterCount.includes("inOut")) {
            this.filterCount.push("inOut")
        }
        if (this.v2 && !this.filterCount.includes("v2")) {
            this.filterCount.push("v2")
        }
        if (this.executionDate?.start && this.executionDate?.end && !this.filterCount.includes("executionDate")) {
            this.filterCount.push("executionDate");
        }
        if (this.transactionState !== null && !this.filterCount.includes("transactionState")) {
            this.filterCount.push("transactionState");
        }
        if (this.fee !== null && !this.filterCount.includes("fee")) {
            this.filterCount.push("fee");
        }
        if (this.isRefund !== null && !this.filterCount.includes("isRefund")) {
            this.filterCount.push("isRefund");
        }
        if ((this.toProvider
            .length) && !this.filterCount.includes("toProvider")) {
            this.filterCount.push("toProvider");
        }
        if ((this.transactionType || this.transactionType === 0) && !this.filterCount.includes("transactionType")) {
            this.filterCount.push("transactionType");
        }
        if (this.merchant && !this.filterCount.includes("merchant")) {
            this.filterCount.push("merchant");
        }
        if (this.autopayment && !this.filterCount.includes("autopayment")) {
            this.filterCount.push("autopayment");
        }
        if (
            (this.transactionAmount[0] || this.transactionAmount[1]) &&
            !this.filterCount.includes("transactionAmount")
        ) {
            this.filterCount.push("transactionAmount");
        }
        if ((this.initiatorType | this.initiatorType === 0) && !this.filterCount.includes("initiatorType")) {
            this.filterCount.push("initiatorType");
        }
        if (this.searchLogin.trim() && !this.filterCount.includes("searchLogin")) {
            this.filterCount.push("searchLogin");
        }
        if ((this.myIncomeType || this.myIncomeType === 0) && !this.filterCount.includes("myIncomeType")) {
            this.filterCount.push("myIncomeType");
        }
    }

}
