import { mapState } from "vuex"

export default {

    computed: {
        ...mapState({
            permissionsObject: (state) => state.users.permissionsObject,
            authUser: (state) => state.auth.user,
        })
    },

    methods: {
        permissionIs(permissionKey) {
            if (this.permissionsObject) {
                return this.permissionsObject.permissions.includes(permissionKey)
            } else {
                return this.authUser.permissions.includes(permissionKey)
            }
        },
    }
}