// import PasswordAssistance from "../../views/auth/PasswordAssistance";
import PasswordSendSuccess from "@/views/auth/PasswordSendSuccess";
import CreateNewPassword from "@/views/auth/CreateNewPassword";
import RegisterApproved from "@/views/auth/RegisterApproved";
import RegisterPending from "@/views/auth/RegisterPending";
import RegisterRejected from "@/views/auth/RegisterRejected";

const SignIn = () => import("@/views/auth/UserSignIn");
const SignUp = () => import("@/views/auth/UserSignUp");
const PasswordAssistance = () => import("@/views/auth/PasswordAssistance");

export const authRout = {
  path: "/auth",
  redirect: "/auth",
  name: "Auth",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "sign-in",
      name: "Sign in",
      component: SignIn
    },
    {
      path: "sign-up",
      name: "Sign up",
      component: SignUp
    },
    {
      path: "password-assistance",
      name: "Password Assistance",
      component: PasswordAssistance
    },
    {
      path: "password-send-success",
      name: "Password Send Success",
      component: PasswordSendSuccess
    },
    {
      path: "password-change",
      name: "Create New Password",
      component: CreateNewPassword
    },
    {
      path: "register-approved",
      name: "Register Approved",
      component: RegisterApproved
    },
    {
      path: "register-pending",
      name: " Register Pending",
      component: RegisterPending
    },
    {
      path: "register-rejected",
      name: " Register Rejected",
      component: RegisterRejected
    }
  ]
};
