<template>
  <div class="Ott-dialog">
    <v-dialog
      :maxWidth="maxWidth"
      :width="width"
      :value="isOpen"
      @click:outside="close"
      persistent
      :content-class="contentClass"
      attach=".Ott-dialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <template>
            <header class="header" v-if="showHeader">
              <p class="white--text mb-0" v-html="modalTitle" />
              <span
                class="mdi mdi-close white--text cursor-pointer"
                @click="closeModal"
              ></span>
            </header>
          </template>
          <div class="modal-body  modalBackground">
            <slot name="modalBody" v-bind:dataBody="data"></slot>
          </div>
          <div class="modal-footer  modalBackground" v-if="showFooter">
            <slot name="modalFooter">
              <button>Save</button>
            </slot>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "OttDialog",
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number
    },
    width: {
      type: Number,
      required: false,
      default: 1110
    },
    contentClass: {
      type: String,
      default: 'G-dialog'
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    modalTitle: {
      type: String
    },
    data: {
      type: Array
    },
    closeValue: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    closeModal() {
      this.$emit("resetDataModal");

    },
    close(e){
      this.$emit('outside',e)
    }

  }
};
</script>
<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";

.v-dialog{
  background-color: blue;
}

.Ott-dialog::v-deep {
  .G-dialog{
    animation-timing-function: unset !important;
    animation-name: unset!important;
  }
  .dialog-header {
    @include main-gradient;
  }
  .v-toolbar__content,
  .v-toolbar__extension {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 50px !important;
  }
}
.theme--light .header {
  @include main-gradient;
}
.theme--dark {
  .header {
    background: $secondaryMainDark !important;
  }
}
.header {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  p {
    font-size: $txt20;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
  span{
    font-size: 22px;
  }
}
.modal-footer {
  padding: 24px 24px;
}
.theme--light {
  .modal-footer {
    border-top: 1px solid $neutral-color45 !important;
  }
}
.theme--dark .modal-footer {
  border-top: 1px solid $borderDM !important;
}
.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 1280px) {
  .modal-body {
    max-height: 600px;
    overflow-y: auto;
  }

  .modal-body::-webkit-scrollbar {
    width: 7px;
  }
}
</style>

<style lang="scss">
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
  }
</style>