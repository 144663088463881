<template>
  <div>
    <selected-filters 
      :selectedList="selectedList"
      @resetData="updateData($event)"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  mixins: [DateFormatMixin],
  components: {
    SelectedFilters,
  },
  computed: {
    ...mapState({
      filterModel: (state) => state.review.filterModel,
      statusList: (state) => state.review.statusList,
      clientsCount: (state) => state.review.clientsCount,
    }),

    getStateName() {
      let name = "";
      if (this.filterModel.state || this.filterModel.state === 0) {
        name = this.statusList.filter(
          (x) => x.value === this.filterModel.state
        )[0].name;
      }
      return name;
    },

    getClientsCount() {
      let count = null;
      if (
        this.filterModel.clientsCount ||
        this.filterModel.clientsCount === 0
      ) {
        count = this.clientsCount.filter((x, index) => {
          if (index === this.filterModel.clientsCount) {
            return x;
          }
        })[0].value;
      }
      return count;
    },

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: `<b>Search</b>: ${this.filterModel.search}`,
          resetArgument: "search",
        },
        {
          ifCondition: this.filterModel.state || this.filterModel.state === 0,
          boxText: `<b>Status</b>: ${this.getStateName}`,
          resetArgument: "state",
        },
        {
          ifCondition:
            this.filterModel.channelsCount[0] ||
            this.filterModel.channelsCount[1],
          boxText:`
            <b>Channels Count</b>: 
            ${this.filterModel.channelsCount[0]} - 
            ${this.filterModel.channelsCount[1]}`,
          resetArgument: "channelsCount",
        },
        {
          ifCondition:
            this.filterModel.clientsCount ||
            this.filterModel.clientsCount === 0,
          boxText: `<b>Clients Count</b>: ${this.getClientsCount}`,
          resetArgument: "clientsCount",
        },
        {
          ifCondition: this.filterModel.date?.start && this.filterModel.date?.end,
          boxText: `
            <b>Date</b>: 
            ${this.getDate(this.filterModel.date?.start)} - 
            ${this.getDate(this.filterModel.date?.end)}`,
          resetArgument: "date",
        },
      ];
    },
  },
  methods: {
    getDate(date) {
      return this.$moment(date).format(this.ottDateFormat).valueOf();
    },
    updateData(data) {
      this.filterModel.resetCurrentData(data);
      this.$emit("update");
    },
  },
};
</script>
