const contact = () => import("@/views/settings/contact/Contact");

export const settingsRout = {
  path: "/clients",
  redirect: "/",
  name: "Client",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "/settings/contact",
      name: "Contact",
      component: contact,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    },
    {
      path: "/settings/admin-personal-info",
      name: "Admin Personal Info",
      component: contact,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    },

  ]
};
