import RequestService from "@/services/request.service";

const InitialState = {
  selectedLocation: null,
  showPayBtn: false,
  packagesPayBtnLabel: null,
  isRefund: false,
  allowCheckout: false,

  packagesList: [],
  packagesTotalPrice: 0,

  actionType: 1,
  dayMonthType: 'month',
  dayMonthValue: 1,
  subscribeToDate: null,

  actionTypes: {
    subscribeFromEndMaxExpire: 1,
    subscribeToEndMaxExpire: 2,
    subscribeToDate: 3,
    stop: 4,
  },

  actionsList: [
    { name: 'Subscribe From End Of Max Expire', value: 1 },
    { name: 'Subscribe To Date', value: 3 },
  ],

  packagesData: {},

  openModal: {
    isOpen: false
  },

  dataForPackages: {
    locations: [],
    equipments: [],
  },
  openPackagesFromManage: false,
}

export const packagesClients = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    /**
     * Set Data for all state keys
     * **/
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    setRoomsCount(state, roomsCount) {
      state.selectedLocation.roomsCount = roomsCount;
    },

    /**
     * mutation for inputs, selects change (custom v-models)
     */
    setPackagesDataLocationsKeys(state, data) {
      state.packagesData.locations.map(item => {
        if (state.selectedLocation.id === item.locationId) {
          for (const key in data) {
            item[key] = data[key];
          }
        }
      })
    },

    resetState(state) {
      for (let key in InitialState) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))
      }
    }

  },

  actions: {
    /**
     * Save packages
     * **/

    save({ dispatch }, data) {
      let locationId = data.locationId
      delete data.locationId;

      return RequestService.update(`clients/locations/edit/${locationId}`, { ...data })
        .then(async (r) => {
          await dispatch('clientsModule/getClientList', {}, { root: true })
        }).catch((e) => console.log(e));
    },
 
    getLocationPackages({ commit, state, rootState }, data = {}) {
      let totalPrice = 0;
      if (state.selectedLocation) {
        return RequestService.create(`subscriptions/location/`, { ...data }).then((r) => {
          commit('setData', {
            packagesTotalPrice: +r.data.totalPrice,
            isRefund: r.data.btnText === 'Refund',
            packagesPayBtnLabel: `${r.data.btnText} ${
              new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
              }).format(r.data.totalPrice)
            }`,
            allowCheckout: r.data.allowCheckout 
          })

          totalPrice = r.data.totalPrice
          if (state.packagesData.locations !== undefined) {
            state.packagesData.locations.map(i => {
              r.data.locations.map(j => {
                if (i.locationId === j.locationId) {
                  i.packageInfos = j.packageInfos
                  i.packagesList = j.packages
                  i.totalPrice = j.totalPrice
                  i.roomsCount = j.room
                  // commit('setPackagesData', {...j.packageInfos, ...j.packages, })
                }
              })
            })

          }
  
          if (totalPrice !== 0 &&
            !rootState.equipments.isAddMode &&
            !rootState.equipments.isEditMode &&
            !rootState.equipments.isReturnMode) {
            commit('setData', { showPayBtn: true })
          } else {
            commit('setData', { showPayBtn: false })
          }

          if (r.data.allowCheckout)
            commit('setData', { showPayBtn: true })
 

          /** equipnents part */
          if (r.data.equipment) {
            if (r.data.equipment.equipments !== undefined) {
              if (r.data.equipment.equipments.length) {
                commit('equipments/setData', { clientEquipments: r.data.equipment.equipments }, { root: true })
              }
            }
            commit('equipments/setData', {
              equipmentsTotal: +r.data.equipment.totalPrice
            }, { root: true })
          }

        }).catch((e) => console.log(e));
      }
    },

    setCheckoutInvoice({ state, commit }, data = {}) {
      // if (state.selectedLocation) {
        return RequestService.create(`subscriptions/checkout/`, { ...data }).then((r) => {
          if (Object.keys(r.data).length) {
            commit('checkoutClients/setData', {invoiceData: r.data}, { root: true })
            return Promise.resolve(true)
          }       
        }).catch((e) => console.log(e));
      // }
    }
  }
};
