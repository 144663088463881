import RequestService from "@/services/request.service";
import { FormatClientAddress } from '@/mixins/AddressFormat'

const InitialState = {
  showComponents: false,
  formData: {
    firstname: '',
    lastname: '',
    phone: '',
    address: '',
    country: '',
    city: '',
    province: '', // state / province
    suite: '',    // suite / unit
    zip: '',
    forContactInvoice: false, // isContactInvoice
    isBilling: false,
    isShipping: false,
    isResident: false,
    isLoading: false,
    lat: null,
    long: null,
    image: null
  },
  cloneData: null,
  isEditMode: false,
  isAddMode: false,
  isExistingName: false,
  selectedAddressIndex: null,
  // billing addresses for credit card
  allBillingAddress: [],
  allBillingAddresses: [],
  allShippingAddresses: [],
  billingAddressList: [],
  shippingAddresses: [],
  allAddresses: [],
  showSaveCancelButtons: false,
  showAddressList: false
};

export const addressClient = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    
    /**
     * reset address data
     * **/

    resetAddress(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
      state.isExistingName = false
    },

    resetAllData(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
      state.allAddresses = [];
      state.cloneData = null;
      state.isEditMode = false;
      state.isAddMode = true;
      state.isExistingName = false;
      state.showAddressList = false
    },

    addNewAddress(state, address) {
      state.allAddresses.push(address)
    },

    /**
     * set address data
     * **/

    /***
    * for all second level object keys changing
    *  setDataSecondLevels({ 
    *    stateKey1: { changingKey: 'newVal' },
    *    stateKey2: { changingKey2: 'newVal' },  
    *  })
    **/
     setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if(state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },
    
    setFormData(state, data) {
      for (const key in data) {
        state.formData[key] = data[key]
      }
    },

    setIsEditMode(state, value) {
      state.isEditMode = value
    },
    setIsAddMode(state, value) {
      state.isAddMode = value
    },
    setSelectedAddressIndex(state, value) {
      state.selectedAddressIndex = value
    },
    setBillingAddressList(state, data) {
      state.billingAddressList = data
    },
    checkForContactAddress(state, data) {
      if (data.forContactInvoice) {
        state.allAddresses.map(item => {
          return item.forContactInvoice = item.address === data.forContactInvoice
        });
      }
    },

    setAllAddresses(state, data) {
      data.map(item => {
        delete item.updatedAt
        delete item.createdAt
      })
      state.allAddresses = data      
    },

    setIsLoading(state, value) {
      state.formData.isLoading = value
    },

    setShowAddressList(state, value) {
      state.showAddressList = value
    },

    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  },
  
  actions: {
    addContactAddress({ rootState, commit, dispatch }, { addresses }) {
      const addressesList = addresses.map(({ createdAt, image, validationObject, validationMessage, isValid, ...rest }) => rest)

      return RequestService.update(`clients/edit/${rootState.clientsModule.clientId}`, { addresses: addressesList })
        .then(async (r) => {
          await dispatch('fetchAddress', rootState.clientsModule.clientId)          
          commit('setIsEditMode', false)
          commit('setIsAddMode', false)
          await dispatch('clientsModule/getClientList', {}, { root: true })
          return Promise.resolve(r.data)
        })
        .catch(e => console.log('error', e))
    },

    fetchAddress({dispatch, rootState, commit}, clientId = null) {
      return RequestService.read(`clients/edit/${clientId ? clientId : rootState.clientsModule.clientId}`)
        .then(r => {
          if (r.data) {
            commit('setShowAddressList', true)
          }
          
          dispatch("setAddresses", { addresses: r.data.addresses })
          return Promise.resolve(r.data.addresses);
        })
        .catch(e => console.log('error', e))
        .finally(() => { commit('setIsLoading', false) })
    },

    setAddresses({commit}, { addresses }) {
      commit('setAllAddresses', addresses)
      commit('setIsAddMode', false)

      let allBillingAddresses = [],
          allShippingAddresses = [],
          billingAddressList = [],
          shippingAddresses = [],
          address = '';

      let createAddress = (item) => {
        address = '';
        if (item.address) {
          address = address + item.address
        }
        if (item.suite) {
          address = address + ' ' + item.suite
        }
        if (item.city) {
          address = address + ' ' + item.city
        }
        if (item.province) {
          address = address + ' ' + item.province
        }
        if (item.zip) {
          address = address + ' ' + item.zip
        }
        if (item.country && item.country !== 'US') {
          address = address + ' ' + item.country
        }
      }

      if (addresses.length) {
        if (addresses.filter(x => x.isBilling).length) {
          billingAddressList = addresses.filter(x => x.isBilling);
        }

        if (addresses.filter(x => x.isShipping).length) {
          shippingAddresses = addresses.filter(x => x.isShipping);
        }

        if (billingAddressList.length) {
          commit('setData', { allBillingAddresses: [] })
          billingAddressList.forEach((item, i) => {
            createAddress(item)
            billingAddressList[i] = { 
              name: address,
              value: item.id
            }

            allBillingAddresses[i] = {
              name: address,
              value: item
            }
          })
        }

        if (shippingAddresses.length) {
          commit('setData', { allShippingAddresses: [] })

          shippingAddresses.forEach((item, i) => {
            createAddress(item)
            shippingAddresses[i] = { 
              name: address,
              value: item.id
            }

            allShippingAddresses[i] = { 
              name: address,
              value: item
            }
          })
        }

        allBillingAddresses = allBillingAddresses.map((address) => {
          const { createdAt, updatedAt, ...rest } = address.value;
          return { ...address, value: rest };
        });

        allShippingAddresses = allShippingAddresses.map((address) => {
          const { createdAt, updatedAt, ...rest } = address.value;
          return { ...address, value: rest };
        });

        commit('setData', 
          { 
            allBillingAddresses,
            allShippingAddresses,
            billingAddressList,
            shippingAddresses,
          }
        )
      }
    },

    getAddressImage({ state }, { address, lat, long }) {
      return RequestService.create('clients/getAddressImage', { address, lat, long, })
        .then((r) => {
          return Promise.resolve(r.data)
        })
    }
  },
};
