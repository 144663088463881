export default class ProvidersFilterModel {
  constructor() {
    this.reset();
  }
  
  toSave() {
    return {
      search: this.search
    };
  }

  reset() {
    this.search = "";
    this.country = null;
    this.clientsLimit = [0, 0];
    this.resellersLimit = [0, 0];
    this.totalLoginsLimit = [0, 0];
    this.activeLogins = [0, 0];
    this.priceGroup = '';
    this.balance = [0, 0];
    this.debt = [0, 0];
    this.creditAmount = [0, 0];
    this.creditAutoextend = false;
    this.daysRemainCredit = [0, 0];
    this.currentMonthPayments = [0, 0];
    this.currentMonthIncomes = [0, 0];
    this.registerDate = {
      start: null,
      end: null
    };
    this.creditDate = {
      start: null,
      end: null
    };
    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);
    this.filterCount.splice(index, 1);
    switch (dataKey) {
      case "search": {
        this.search = "";
        break;
      }

      case "country": {
        this.country = null;
        break;
      }
      case "clientsLimit": {
        this.clientsLimit = [0, 0];
        break;
      }
      case "resellersLimit": {
        this.resellersLimit = [0, 0];
        break;
      }
      case "totalLoginsLimit": {
        this.totalLoginsLimit = [0, 0];
        break;
      }
      case "activeLogins": {
        this.activeLogins = [0, 0];
        break;
      }
      case "priceGroup": {
        this.priceGroup = '';
        break;
      }
      case "balance": {
        this.balance = [0, 0];
        break;
      }
      case "debt": {
        this.debt = [0, 0];
        break;
      }
      case "creditAmount": {
        this.creditAmount = [0, 0];
        break;
      }
      case "daysRemainCredit": {
        this.daysRemainCredit = [0, 0];
        break;
      }
      case "currentMonthPayments": {
        this.currentMonthPayments = [0, 0];
        break;
      }
      case "currentMonthIncomes": {
        this.currentMonthIncomes = [0, 0];
        break;
      }
      case "creditAutoextend": {
        this.creditAutoextend = false;
        break;
      }
      case "creditDate": {
        this.creditDate = {
          start: null,
          end: null
        };
        break;
      }
      case "registerDate": {
        this.registerDate = {
          start: null,
          end: null
        };
        break;
      }
    }
  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes("search")) {
      this.filterCount.push("search");
    }
    if (this.country !== null && !this.filterCount.includes("country")) {
      this.filterCount.push("country");
    }
    if (this.priceGroup && !this.filterCount.includes("priceGroup")) {
      this.filterCount.push("priceGroup");
    }
    if (
      (this.clientsLimit[0] || this.clientsLimit[1]) &&
      !this.filterCount.includes("clientsLimit")
    ) {
      this.filterCount.push("clientsLimit");
    }
    if (
      (this.resellersLimit[0] || this.resellersLimit[1]) &&
      !this.filterCount.includes("resellersLimit")
    ) {
      this.filterCount.push("resellersLimit");
    }
    if (
      (this.totalLoginsLimit[0] || this.totalLoginsLimit[1]) &&
      !this.filterCount.includes("totalLoginsLimit")
    ) {
      this.filterCount.push("totalLoginsLimit");
    }
    if (
      (this.activeLogins[0] || this.activeLogins[1]) &&
      !this.filterCount.includes("activeLogins")
    ) {
      this.filterCount.push("activeLogins");
    }
    if (
      (this.balance[0] || this.balance[1]) &&
      !this.filterCount.includes("balance")
    ) {
      this.filterCount.push("balance");
    }
    if ((this.debt[0] || this.debt[1]) && !this.filterCount.includes("debt")) {
      this.filterCount.push("debt");
    }
    if (
      (this.creditAmount[0] || this.creditAmount[1]) &&
      !this.filterCount.includes("creditAmount")
    ) {
      this.filterCount.push("creditAmount");
    }
    if (
      (this.daysRemainCredit[0] || this.daysRemainCredit[1]) &&
      !this.filterCount.includes("daysRemainCredit")
    ) {
      this.filterCount.push("daysRemainCredit");
    }
    if (
      (this.currentMonthPayments[0] || this.currentMonthPayments[1]) &&
      !this.filterCount.includes("currentMonthPayments")
    ) {
      this.filterCount.push("currentMonthPayments");
    }
    if (
      (this.currentMonthIncomes[0] || this.currentMonthIncomes[1]) &&
      !this.filterCount.includes("currentMonthIncomes")
    ) {
      this.filterCount.push("currentMonthIncomes");
    }

    if (
      this.registerDate?.start &&
      this.registerDate?.end &&
      !this.filterCount.includes("registerDate")
    ) {
      this.filterCount.push("registerDate");
    }
    if (
      this.creditAutoextend &&
      !this.filterCount.includes("creditAutoextend")
    ) {
      this.filterCount.push("creditAutoextend");
    }    
    if (
      this.creditDate?.start &&
      this.creditDate?.end &&
      !this.filterCount.includes("creditDate")
    ) {
      this.filterCount.push("creditDate");
    }
  }
}
