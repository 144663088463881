import RequestService from "@/services/request.service";

const InitialState = {
  shippingStep: 1, // 1.Addresses, 2.Shipment Information, 3.Choose Courier, 4.Handover Options & Add-ons
  shippingReturnLabel: false,
  shipping: null,
  // Addresses
  isOpenFromForm: false,
  isOpenToForm: false,
  shipFromAddress: null,
  shipToAddress: null,
  shipFromAddressId: null,
  shipToAddressId: null,
  shipFromFormData: {
    company: true,
    companyName: '',
    phone: '',
    address: '',
    country: '',
    city: '',
    province: '', // state / province
    unit: '',    // suite / unit
    zip: '',
    lat: null,
    long: null,
    isForShipping: true,
    existingAddress: '',
    anExistingAddress: false,
  },
  shipToFormData: {
    companyName: '',
    firstname: '',
    lastname: '',
    phone: '',
    address: '',
    country: '',
    city: '',
    province: '', // state / province
    suite: '',    // suite / unit
    zip: '',
    lat: null,
    long: null,
    isShipping: true,
    existingAddress: '',
    anExistingAddress: false,
    isExistingName: false
  },

  // Information
  easyShipAllBoxes: [],
  easyShipBoxItems: [],
  showShipBoxErrors: false,
  groupedEquipmentsList: [],
  shipInfoBoxesData: [
    {
      box: null,
      width: null,
      height: null,
      length: null,
      weight: null,
      custom: false
    }
  ],
  categoriesList: [],

  // Couriers
  couriersList: [],
  selectedCourier: null,
  hasError: false,
  errorMessage: '',

  // Handover
  isPremiumShipping: null,
  addressOnLabel: '',
  pickupAddress: null,
  pickupDate: null,
  pickupTime: null,
  isStandartPickup: false,
};

export const shippingClients = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    setShipFromFormData(state, data) {
      for (const key in data) {
        state.shipFromFormData[key] = data[key]
      }
    },

    setShipToFormData(state, data) {
      for (const key in data) {
        state.shipToFormData[key] = data[key]
      }
    },

    setShipBoxesDataKeys(state, { key, value }) {
      state.shipInfoBoxesData[0][key] = value
    },

    resetState(state) {
      for (let key in state) {
        state[key] = InitialState[key] ? JSON.parse(JSON.stringify(InitialState[key])) : null
      }
    },

    resetShipFromForm(state) {
      state.shipFromFormData = Object.assign({}, InitialState.shipFromFormData)
    },

    resetShipToForm(state) {
      state.shipToFormData = Object.assign({}, InitialState.shipToFormData)
    },

    addEasyShipBox(state) {
      state.shipInfoBoxesData.push({
        box: null,
        width: null,
        height: null,
        length: null,
        weight: null,
        custom: false
      });
    },
  },
  actions: {
    //Shipping
    getAllEasyShipBoxes({ commit, state }) {
      let easyShipAllBoxes = []
      const easyShipBoxItems = []

      easyShipBoxItems.push({
        name: 'Custom Box',
        value: 'custom'
      })

      return RequestService.read('easyship/boxes').then((response) => {
        easyShipAllBoxes = response.data?.boxes

        if (easyShipAllBoxes.length) {
          easyShipAllBoxes.map((item) => {
            easyShipBoxItems.push({
              name: `${item.name} (${item.outer_length} x ${item.outer_width}  x ${item.outer_height} in)`,
              value: item.id
            })
          })
        }

        commit('setData', {
          easyShipAllBoxes,
          easyShipBoxItems
        })
      })
    },

    getShippingData({ commit, state }, body) {
      const shipBoxesData = JSON.parse(JSON.stringify(state.shipInfoBoxesData))
      // Change boxes custom keys
      shipBoxesData.forEach((item, index) => {
        if (item.box === 'custom') {
          item.custom = true
          delete item.box;
        } else {
          item.custom = false
        }
      })

      const data = {
        returnLabel: state.shippingReturnLabel == null ? false : state.shippingReturnLabel,
        client: body.clientId,
        isCheck: body.isCheck,
        shipFrom: state.shipFromAddressId,
        shipTo: state.shipToAddressId,
        equipments: body.equipments,
        boxes: shipBoxesData,
      }
      if (state.selectedCourier && state.shippingStep === 4) {
        if (state.selectedCourier) {
          data.selectedCourier = state.selectedCourier;
        }

        if (state.isPremiumShipping) {
          data.isPremiumShipping
        }
        
        data.isStandartPickup = state.isStandartPickup;
        data.addressOnLabel = state.addressOnLabel;
      }
      if (body.isCheck) {
        data.isPremiumShipping = true;
      }
      return Promise.resolve(data);
    },
    checkShipping({ commit, state }, data) {
      return RequestService.create('shippings', { ...data }).then((response) => {
        commit('setData', { couriersList: response.data.rates, hasError: false, shipping: null })
        if (!data.isCheck) {
          commit('setData', { shipping: response.data.shipping })
        }
        return Promise.resolve(true);
      })
          .catch((error) => {
        const message = error.response.data.message;
        commit('setData', { couriersList: [], hasError: true, errorMessage: message, shipping: null })
        return Promise.resolve(false);
      })
    },

    getCouriersList({ commit, state }) {
      return RequestService.read(`shippings/couriers/${state.shipping.id}`)
        .then((response) => {
          commit('setData', { couriersList: response.data, hasError: false })
        }).catch((error) => {
           const message = error.response.data.message;
            commit('setData', { couriersList: [], hasError: true, errorMessage: message })
          })
    },

    getCategoriesList({ commit }) {
      const categoriesList = []
      return RequestService.read("shippings/categories")
        .then((response) => {
          response.data.map(item => {
            categoriesList.push({
              value: item.id,
              name: item.name
            })
          })
          commit('setData', { categoriesList })
        })
    },
  },
};
