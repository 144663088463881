import "core-js/stable";
import Vue from "vue";
import App from "./App";

import store from "./store";
import router from "./router/index";
import vuetify from "@/plugins/vuetify";
import "./plugins/clipper";

import "./assets/scss/main.scss";
import "@/api/api";

import "./mixins/CloneMixin";
import "./mixins/AddressFormatMixin";
import "./mixins/BackendSyncMixin";
import PermissionsMixin from "@/mixins/PermissionsMixin";
Vue.mixin(PermissionsMixin)


// ************ TOASTER ************&
import Vuelidate from "vuelidate/src"; // path to vuetify export
import VueToastr from "vue-toastr";
// use plugin
Vue.use(VueToastr, {
  /* OverWrite Plugin Options if you need */
});


// ************ HUB PLUGIN WSS ************
import HubPlugin from './plugins/hub'
Vue.use(HubPlugin)


/************ Vue Quill Editor ************/
import VueQuillEditor from 'vue-quill-editor'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)


/************* Vue Apex Chart *************/
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)


/************* Day Span Vuefity (Full Calendar) *************/
// import DaySpanVuetify from 'dayspan-vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import DaySpan from 'dayspan-vuetify-2'
import DaySpanVuetify from '@/views/full-calendar/plugin'


Vue.use(DaySpanVuetify, {
  methods: {
      getDefaultEventColor: () => 'white'
  }
});
/********************************************************/

/**************** Floating Vue **************/
import {
  // Directives
  VTooltip,
  VClosePopper,
  // Components
  Dropdown,
  Tooltip,
  Menu
} from 'floating-vue'

Vue.use(VTooltip, {
  themes: {
    'info-tooltip': {
      $extend: 'tooltip',
      $resetCss: true,
    },
  },
})

Vue.directive('tooltip', VTooltip)
Vue.directive('close-popper', VClosePopper)

Vue.component('VDropdown', Dropdown)
Vue.component('VTooltip', Tooltip)
Vue.component('VMenu', Menu)

import 'floating-vue/dist/style.css'
/******************************************* */


import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);

import VueCardFormat from "vue-credit-card-validation";
Vue.use(VueCardFormat);

Vue.use(Vuelidate);
Vue.use(require('vue-moment'));

Vue.prototype.$log = console.log.bind(console);
Vue.prototype.$scrollTo = function(element, container, offset) {
  let top = element.offsetTop;
  if (container) container.scrollTo(0, top - offset);
  else window.scrollTo(0, top - offset);
};
Vue.config.productionTip = false;

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const eventBus = new Vue()

const app = new Vue({
  el: "#app",
  vuetify,
  Vuelidate,
  router,
  store,
  template: "<App/>",
  components: {
    App
  },
  mounted() {
    // You are able to access plugin from everywhere via this.$toastr
    // this.$toastr.defaultPosition = "toast-top-right";
  }
});

store.$app = app;
