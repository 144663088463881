import RequestService from "@/services/request.service";

const InitialState = {
    dvrFormData: {
        dvrPath: "",
        dvrDays: 0,
        dvrHours: 0,
        dvrDiskSpaceLimit: 0,
        dvrCachePath: "",
        dvrCacheDays: 0,
        dvrСacheHours: 0,
        dvrCacheSpaceLimit: 0,
    },

    vodFormData: {
        vodPrefix: "",
        vodCachePath: "",
        vodCacheDays: 0,
        vodCacheHours: 0,
        vodCacheSpaceLimit: 0,
        vodCacheMisses: 2,
        vodOtherConfigs: ""
    },

    vodPaths: {
        dragValue: '',
        values: []
    },

    vodList: [],
    isAddVod: false,

    isAddBtnLoading: false
}

export const dvrVodFlussonic = {
    namespaced: true,
    state: JSON.parse(JSON.stringify(InitialState)),
    mutations: {
        /**
        * for all first level keys changing  
        * setData({ key: 'value' })
        */
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key];
            }
        },

        /***
         * for all second level object keys changing
         *  setDataSecondLevels({ 
         *    stateKey1: { changingKey: 'newVal' },
         *    stateKey2: { changingKey2: 'newVal' },  
         *  })
         **/
        setDataSecondLevels(state, data) {
            for (let firstLevel in data) {
                for (let secondLevel in data[firstLevel]) {
                    if (state[firstLevel][secondLevel] !== undefined) {
                        state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
                    }
                }
            }
        },

        /***
         * Multiselect Draggable mutations
         **/

         addDraggable(state, { data, keyName }) {
            if (data.dragValue) {
                state[keyName].values.push({
                    name: data.dragValue,
                    id: new Date().getTime(),
                });
                state[keyName].dragValue = "";
            }
            return
        },

        setDragValue(state, { data, keyName }) {
            state[keyName].dragValue = data.value;
        },

        removeDraggable(state, { dragId, keyName }) {
            state[keyName].values = state[keyName].values.filter(
                (value) => value.id !== dragId
            );
        },

        addVod(state) {
            const data = {};
            data.vodPrefix = state.vodFormData.vodPrefix;
            data.vodCachePath = state.vodFormData.vodCachePath;
            data.vodCacheDays = state.vodFormData.vodCacheDays;
            data.vodCacheHours = state.vodFormData.vodCacheHours;
            data.vodCacheSpaceLimit = state.vodFormData.vodCacheSpaceLimit;
            data.vodCacheMisses = state.vodFormData.vodCacheMisses;
            data.vodOtherConfigs = state.vodFormData.vodOtherConfigs;
            data.vodPaths = [ ...state.vodPaths.values ];
            state.vodList.push(data)
        },

        resetDvrFormData(state) {
            state.dvrFormData = JSON.parse(JSON.stringify(InitialState.dvrFormData))
        },

        resetVodFormData(state) {
            state.vodFormData = JSON.parse(JSON.stringify(InitialState.vodFormData))
            state.vodPaths = JSON.parse(JSON.stringify(InitialState.vodPaths))
        },

        resetState(state) {
            for (let key in state) {
                state[key] = JSON.parse(JSON.stringify(InitialState[key]))
            }          
        }
    }
}