import {defaultColumnConfig} from "./defaultColumnConfig";
import moment from "moment";
import RequestService from "@/services/request.service";
import ClientsFilterModel from "@/models/clients/ClientsFilterModel";
const FileDownload = require('js-file-download');

const InitialState = {
  filterModel: new ClientsFilterModel(),
  modal: {
    isOpen: false,
    isOpenPackageList: false
  },
  
  isClientEdit: false,
  activeTab: 0,
  disableTabs: 0,
  disableButton: true,
  client: null,

  showList: [
    {name: "Personal Info", title: "personalInfo"},
    {name: "Contact", title: "contact"},
    {name: "Address", title: "address"},
    {name: "Payment Method", title: "paymentMethod"},
    {name: "Finance", title: "finance"},
    {name: "Balance / Credit", title: "balanceCredit"},
    {name: "Location", title: "location"},
    // {name: "Profile", title: "profile"},
    {name: "Used Devices", title: "usedDevices"},
    {name: "Packages and Equipments", title: "packages"},
  ],
  providersList: [],
  resellerList: [],

  serverList: [],
  serverBackups: [],

  filterRangeData: {
    balance: [0, 1000],
    credit: [0, 1000],
    debt: [0, 1000],
    activePackages: [0, 1000],
  },

  validateAllTabs: {},

  toSaveData: {},
  dataTable: [],

  //  for  pagination  table
  dataCount: 0,
  currentPage: 1,
  totalPages: 0,

  clientId: null,
  billNumber: null,

  filter: {
    limit: 20,
    sortBy: "_id:desc",
    page: 1
  },

  // for  update  table  columns
  defaultSettings: [],
  updateColumnsFromBack: 'clientsModule/updateClientsSettings',
  updateColumnSettingsCommit: 'clientsModule/setDefaultSettings',
  isDefault: false,

  // manage packages modal info
  isOpenManagePackagesModal: false,
  selectedPackage: null,
  managePackagesActionList: [
    {
      id: 1,
      value: "Stop"
    },
    {
      id: 2,
      value: "Subscribe To End Of Max Expire"
    },
    {
      id: 3,
      value: "Subscribe From End Of Max Expire"
    },
    {
      id: 4,
      value: "Subscribe To Date"
    }
  ],
  managePackagesAction: null,

  // table loading
  isTableLoading: false,
  extendedType: false,
  filterData: {},

  showWarningModal: false,

  // loading for 'save and close' button
  isDataLoading: false,

  clientsResult: false,
  isAddClient: true,
  isAddClientFromSearch: false,

  activePackagesModal: false,
  clientProviderId: null,
  // resellerSelect: null
  isOpenChangeServerModal: false,
  serverModeList: [
    {
      name: 'Has Server',
      value: 1,
    },
    {
      name: 'All Filtered',
      value: 2,
    },
    {
      name: 'Restore',
      value: 'restore',
    },
    {
      name: 'All',
      value: 3,
    },
    {
      name: 'All Selected',
      value: 4,
    }
  ],
  changeServerData: {
    mode: '',
    server: '',
    setSerever: '',
    backup: '',
  },



  changeRestoreLoader: false,
};

export const clientsModule = {
  namespaced: true,
  state: InitialState,
  mutations: {

    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    /***
     * for all second level object keys changing
     *  setDataSecondLevels({ 
     *    stateKey1: { changingKey: 'newVal' },
     *    stateKey2: { changingKey2: 'newVal' },  
     *  })
     **/
    setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if(state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue
    },

    resetFilterModel(state) {
      state.filter = {}
      state.filter.page = 1
      state.filter.limit = 10
      state.filter.sortBy = "_id:desc"
      state.filterData = {}
    },

    /**
     * Set filter pagination
     * **/

    setFilterPage(state, page) {
      state.filter.page = page
      state.currentPage = page;
    },

    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filterData?.sortBy) { 
          state.filterData.sortBy = "_id:desc"; 
        }
      }
    },

    /**
     * Open add or  edit user modal
     * **/
    openModal(state) {
      state.modal.isOpen = true;
    },
    closeClientsModal(state) {
      state.modal.isOpen = false;
    },
    /**
     * Close modal
     * **/
    closeModal(state) {
      state.modal.isOpen = false;
      state.activeTab = 0;
      state.toSaveData = {}
      state.clientId = null
      state.billNumber = null
      state.disableFlag = false
      state.disableButton = true
    },

    /**
     * Change modal tabs  onclick left tabs
     * **/
    clickActiveTab(state, data) {
      state.activeTab = data;
    },

    /**
     * Change modal  tabs onclick next button
     * **/
    nextTab(state) {
      if (state.activeTab < state.showList.length) {
        state.activeTab = ++state.activeTab;
        if (state.activeTab > state.disableTabs) {
          state.disableTabs = ++state.disableTabs;
        }
      }
    },

    /**
     * Set user id
     * **/

    setClientId(state, id) {
      state.clientId = id
    },

    setBillNumber(state, number) {
      state.billNumber = number
    },

    /**
     * Remove disable tabs
     * **/
    resetDisableTabs(state) {
      state.disableTabs = state.showList.length;
    },

    /**
     * change disable  save and  close button
     * **/

    setDisableButton(state, value) {
      state.disableButton = value
    },

    setDisableFlag(state, value) {
      state.disableFlag = value
    },

    /**
     * Open manage packages modal
     */
    openManagePackagesModal(state) {
      state.isOpenManagePackagesModal = true
    },

    /**
     * Close manage packages modal
     */
    closeManagePackagesModal(state) {
      state.isOpenManagePackagesModal = false
    },

    /**
     * set manage package action
     */
    toggleOpenPackageList(state, value) {
      state.modal.isOpenPackageList = value
    },

    /**
     * set disable tabs
     */
    setDisableTabs(state, value) {
      state.disableTabs = value
    },

    /**
     * Set clients data
     */
    setClientsData(state, data) {
      let clientList = []

      data.results.map(item => {
        if (item.client && item.client.length) {
          item.client.map(oneClient => {
            clientList.push(oneClient)
          })
        }
      })

      state.dataTable = data ? data.results : data
      // state.currentPage = data?.page
      state.totalPages = data?.totalPages
      state.dataCount = data?.totalResults

      if (state.extendedType) {
        state.dataTable = clientList
      }
    },

    /**
     * Set extended type
     */
    setExtendedType(state, data) {
      if (data === null || typeof data === "undefined")
        data = false;
      state.extendedType = data;
    },

    /**
     * filter Client list
     * **/

    filterClients(state) {
      const filterObject = {}
      if (state.filterModel.search) {
        filterObject.search = state.filterModel.search
      }
      if (state.filterModel.subscriptionState !== null) {
        if (state.filterModel.subscriptionState !== '0') {
          filterObject.subscriptionState = state.filterModel.subscriptionState
        } else {
          filterObject.subscriptionState = 0
        }
      }
      if (state.filterModel.subscriptionState !== null && state.filterModel.statusFilterType) {
        filterObject.statusFilterType = state.filterModel.statusFilterType
      }
      if (state.filterModel.providers.length) {
        filterObject.providers = [...state.filterModel.providers]
      }
      if (state.filterModel.reseller) {
        filterObject.reseller = state.filterModel.reseller
      }
      if (state.filterModel.priceGroup) {
        if (state.filterModel.priceGroup === 'default') {
          filterObject.priceGroup = null
        } else {
          filterObject.priceGroup = state.filterModel.priceGroup
        }
      }
      if (state.filterModel.currency && state.filterModel.currency !== 'default') {
        filterObject.currency = state.filterModel.currency+""
      } else if (state.filterModel.currency === 'default') {
        filterObject.currency = null
      }
      if (state.filterModel.balance[1]) {
        filterObject.balanceFrom = state.filterModel.balance[0]
        filterObject.balanceTo = state.filterModel.balance[1]
      } else {
        delete filterObject.balanceFrom
        delete filterObject.balanceTo
      }
      if (state.filterModel.credit[1]) {
        filterObject.creditAmountFrom = state.filterModel.credit[0]
        filterObject.creditAmountTo = state.filterModel.credit[1]
      } else {
        delete filterObject.creditAmountFrom
        delete filterObject.creditAmountTo
      }
      if (state.filterModel.creditDate?.start && state.filterModel.creditDate?.end) {
        filterObject.creditDateFrom = 
          moment(state.filterModel.creditDate.start).format('MM/DD/YYYY');
        filterObject.creditDateTo = 
          moment(state.filterModel.creditDate.end).format('MM/DD/YYYY');
      }
      if (state.filterModel.creditExpired) {
        filterObject.creditExpired = state.filterModel.creditExpired
      }
      if (state.filterModel.creditAutoExtend) {
        filterObject.creditAutoExtend = state.filterModel.creditAutoExtend
      }
      if (state.filterModel.debt[1]) {
        filterObject.debtFrom = state.filterModel.debt[0]
        filterObject.debtTo = state.filterModel.debt[1]
      }else {
        delete filterObject.debtFrom
        delete filterObject.debtTo
      }
      if (state.filterModel.isBlocked) {
        filterObject.isBlocked = state.filterModel.isBlocked
      }
      if (state.filterModel.inPaused) {
        filterObject.inPaused = state.filterModel.inPaused
      }
      if (state.filterModel.activePackages[1]) {
        filterObject.activePackagesFrom = state.filterModel.activePackages[0]
        filterObject.activePackagesTo = state.filterModel.activePackages[1]
      } else {
        delete filterObject.activePackagesFrom
        delete filterObject.activePackagesTo
      }
      if (state.filterModel.packagesDate?.start && state.filterModel.packagesDate?.end) {
        filterObject.packageExpireDateFrom = 
          moment(state.filterModel.packagesDate.start).format('MM/DD/YYYY');
        filterObject.packageExpireDateTo = 
          moment( state.filterModel.packagesDate.end).format('MM/DD/YYYY');
      }
      if (state.filterModel.packageExpired) {
        filterObject.packageExpired = state.filterModel.packageExpired
      }
      if (state.filterModel.autopayment) {
        filterObject.autopayment = state.filterModel.autopayment
      }
      if (state.filterModel.server) {
        filterObject.server = state.filterModel.server
      }
      if (state.filterModel.timezone) {
        filterObject.timezone = state.filterModel.timezone
      }
      if (state.filterData?.sortBy) {
        filterObject.sortBy = state.filterData.sortBy
      }

      state.filterData = filterObject
    },

    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault
      }
    },


    setDefaultSettingsForBack(state, data) {
      if (data.clientSettings) {
        state.isDefault = data.clientSettings.isDefault
        state.defaultSettings = data.clientSettings.settingsExtended
      }
    },

    setServerList(state, data) {
      state.serverList = []
      data.results.map(item => {
        // let engServer = item.name.filter(server => server.lang === 'en' || server.lang === 'EN')
        state.serverList.push({
          name: item.name.lang === "en" || item.name.lang === "EN" ? item.name.name : item.name[0].name,
          value: item.id,
          id: item.id
        })
      })
    },

    /**
     * Loading for 'save and close data'
     */
    setIsDataLoading(state, value) {
      state.isDataLoading = value
    },

    setIsAddClient(state, value) {
      state.isAddClient = value
    },

    setActivePackagesModal(state, value) {
      state.activePackagesModal = value
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    },

    resetServerData(state) {
      state.changeServerData = {
        mode: '',
        server: '',
        setSerever: '',
        backup: '',
      }
    }
  },
  actions: {
    getClientData({ state, commit }, clientId = null) {
      let id = clientId ? clientId : state.clientId
      return RequestService.read(`clients/edit/${id}`)
      .then((r) => {
        commit('setData', { client: r.data})
        if (r.data?.provider) {
          commit('setData', { clientProviderId: r.data?.provier?.id })
        }
        return Promise.resolve(r.data)
      })
      .catch(e => console.log(e))
    },

    getClientList({ rootState, commit, state }, filter = null) {
      let options = {
        page: state.currentPage ? state.currentPage : 1,
        extendedType: state.extendedType ? 1 : 0
      }

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      state.providerId ? options.providerId = state.providerId : null

      if (filter) {
        options.limit = state.filter.limit
        Object.assign(options, filter)
      } else {
        Object.assign(options, state.filter)
      }
      
      let query = Object.keys(options).map(
        key => key + '=' + options[key]
      ).join('&');

      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = 'blob';
      } else {
        commit('setData', { isTableLoading: true });
      }
      
      return RequestService.read(`clients?${query}`, readOptions)
        .then(response => {
          if (!isExcelRequest) {
            if (response.data.results && response.data.results.length) {
              commit('setData', state.clientsResult = true)
            } else {
              commit('setData', state.clientsResult = false)
            }

            commit('setClientsData', response.data)
            commit('personalInfoClient/setIsLoading', false, {root: true})      
            commit('setData', { isTableLoading: false });
          } else {
            FileDownload(response.data, 'clients.xlsx'); // TODO actual file name from response.headers
          }
        })
        .catch(error => {
          console.log('error ', error)
        })
        .finally(() => {  commit('setData', { isTableLoading: false }) })
    },

    getClientSettings({commit, state}) {
      return RequestService.read(
        `users/settings`
      ).then(
        data => {
          const limitData = data?.data?.clientSettings?.limit ? data.data.clientSettings.limit : state.filter.limit
          commit('setDefaultSettingsForBack', data.data)
          commit('setLimit', limitData)
          return Promise.resolve(data.data);
        },
        error => {
          console.log('error ', error)
        }
      );
    },
 
    updateClientsSettings({state, commit}, data) {
      return RequestService.update(
        `users/settings`, {
          clientSettings: {
            settingsExtended: data,
            isDefault: state.isDefault,
            limit: state.filter.limit
          }
        }
      )
      .then((r) => {
        return Promise.resolve(r.data)
      })
      .catch((e) => console.log(e))       
    },

    clientDelete({commit, dispatch}, {clientId}) {
      return RequestService.update(`clients/delete/${clientId}`)
        .then(async (r) => {
          await dispatch('clientsModule/getClientList', {}, { root: true })
          return Promise.resolve(r.data)
        })
        .catch(e => {
          console.log(e);
          return Promise.reject(e);
        })
    },

    doMultipleAction({dispatch}, data) {
      return RequestService.update(`clients/actionSettings/edit`, { ...data })
        .then(async (r) => {
          await dispatch('clientsModule/getClientList', {}, { root: true })    
          return Promise.resolve(r.data)
        })
        .catch((e) => console.log(e))
    },

    deleteClients({commit, dispatch}, data) {
      return RequestService.update(`clients/actionSettings/delete`, { ...data })
      .then(async (r) => {
        await dispatch('clientsModule/getClientList', {}, { root: true })  
        return Promise.resolve(r.data)
      })
      .catch((e) => console.log(e))
    },

    getServerList({ commit }) {
      return RequestService.read(`servers`)
          .then(response => {
            commit('setServerList', response.data)
          })
          .catch((e) => console.log(e));
    },

    getBeckupsList({ commit }) {
      return RequestService.read('clients/locations/serverBackups').then(response => {
        commit('setData', { serverBackups: response.data })
      })
    },

    changeServer({ commit }, data) {
      commit('setData', { changeRestoreLoader: true })   
      return RequestService.update('clients/locations/changeServer', { ...data }).then(() => {
        return Promise.resolve(true)
      }).finally(() => {
        commit('setData', { changeRestoreLoader: false }) 
      })
    },

    bekupServer({ commit }, data) {
      commit('setData', { changeRestoreLoader: true }) 
      return RequestService.update('clients/locations/restoreServer', { ...data }).then(() => {
        return Promise.resolve(true)
      }).finally(() => {
        commit('setData', { changeRestoreLoader: false }) 
      })
    },
  }
}
