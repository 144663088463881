<template>
  <div class="P-columns-menu">
    <v-menu
      :close-on-content-click="false"
      attach=".P-columns-menu"
      origin="top left"
      transition="scale-transition"
      content-class="P-info-block"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <div  class="P-settings" v-bind="attrs" v-on="on" @mouseover="$emit('hoverColumnConfig')" @mouseleave="$emit('leaveColumnConfig')">
          <span class="mdi neutral--text mdi-settings-outline P-icon"></span>
        </div>
      </template>

      <v-card class="secondary66Dark">
        <div class="P-column-configs">
          <ul>
            <template v-for="(items, index) of columnConfig">
              <li
                v-if="
                  (!('unshowForSearch' in items) && $route.name === 'Search') || $route.name !== 'Search'
                "
                :key="index" class="d-flex"
              >
                <OttCheckbox
                  v-model="items.isShow"
                  :data-val="items.isShow"
                  :label="items.title"
                  :disabled="index===0 || items?.disabled"
                  @changeCheckbox="$emit('updateColumnsFromCheck')"
                />
                <!-- :disabled="index===0" -->
                <div class="P-column-sub-list">
                  <ul>
                    <li v-for="(columns, indexColumn) of items.subList" :key="indexColumn">
                      <OttCheckbox
                          v-model="columns.isShow"
                          :data-val="columns.isShow"
                          :label="columns.title"
                          :disabled="indexColumn===0 && index===0 || !items.isShow"
                          @changeCheckbox="$emit('updateColumnsFromCheck')"
                      />
                    </li>
                  </ul>
                </div>
              </li>
            </template>
          </ul>
        </div>
        <div class="P-reset-column G-flex G-justify-end">
          <OttButton text="Reset" class="primary-sm-btn" :click="updateDataColumn" v-if="!isDefault"/>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
  import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
  import OttButton from "@/components/vuetifyComponents/OttButton";

  export default {
    name:'OttGroupTableList',
    components: {OttButton, OttCheckbox},
    props: {
      columnConfig: {
        type: Array,
        required: true
      },
      isDefault: {
        type: Boolean,
        default: false
      }

    },
    methods: {
      updateDataColumn() {
        this.$emit('resetDataSettingsTable')
      }
    }
  };
</script>
<style scoped lang="scss">
  @import "src/assets/scss/_variables.scss";

  .P-profile-image {
    width     : 35px;
    height    : 35px;
    display   : block;
    font-size : $txt20;
  }

  .P-column-configs {
    padding   : 15px 25px 0px 25px;
    max-width : 800px;

    >ul {
      padding   : 0;
      display   : flex;
      flex-wrap : wrap;

      >li {
        width         : 33.33%;
        margin        : 0;
        margin-bottom : 10px;
        flex-direction: column;
      }

      li::v-deep {
        .v-input {
          margin-top : 0;
        }

        .v-messages {
          display : none;
        }
      }
    }
  }

  .P-reset-column {
    padding : 0 25px 25px 25px;
  }

  .P-icon {
    position   : relative;
    z-index    : 5;
    transition : $transition;
  }

  .P-info-block {
    margin-top : 15px;
  }
</style>
<!--.P-settings {-->
<!--  width: 37px;-->
<!--  height: 37px;-->
<!--  display: flex;-->
<!--  align-items: center;-->
<!--  justify-content: center;-->
<!--  font-size: 24px;-->
<!--  position: relative;-->
<!--  color: $neutral-color;-->

<!--  &:hover,-->
<!--  &[aria-expanded="true"] {-->
<!--    &:after {-->
<!--      opacity: 1;-->
<!--    }-->
<!--    .P-icon {-->
<!--      color: $secondary-color !important;-->
<!--    }-->
<!--  }-->

<!--  &:after {-->
<!--    background-color: $neutral-color7;-->
<!--    border-radius: 8px;-->
<!--    content: "";-->
<!--    display: inline-block;-->
<!--    height: 100%;-->
<!--    left: 0;-->
<!--    opacity: 0;-->
<!--    pointer-events: none;-->
<!--    position: absolute;-->
<!--    top: 0;-->
<!--    width: 100%;-->
<!--    transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);-->
<!--    z-index: 0;-->
<!--  }-->
<!--}-->
