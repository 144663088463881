import RequestService from "@/services/request.service";

const InitialState = {
  isProductsTax: false,
  isServiceTax: false,
  productTax: null,
  serviceTax: null,
  formData: {
    country: null,
    state: null,
    productPercent: "",
    servicePercent: "",
    subList: []
  },
  countryTaxList: [],

  isAddMode: false,
  isEditMode: false,
  cloneData: null,
  editedIndex: null,
  isSubData: false,
  toSaveData: {
    serviceTaxByApi: false,
    serviceTaxPercent: null,
    productTaxByApi: false,
    productTaxPercent: null,
    countryTax: []
  }
};
export const saleTaxesAdmin = {
  namespaced: true,
  actions: {
    updateSaleTaxData({ commit, state }, providerId) {
      return RequestService.update(
        `ottProviders/edit/SalesTax/${providerId}`,
        state.toSaveData
      ).then(
        data => {
          commit("fetchData", data.data);
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getSaleTaxData({ commit, state }, providerId) {
      return RequestService.read(
        `ottProviders/edit/SalesTax/${providerId}`
      ).then(
        data => {
     if(data){
       commit("fetchData", data.data);
       return Promise.resolve(data.data);
     }
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    /**
     * to save data for  connect api
     * **/

    toSaveData(state) {
      state.toSaveData.serviceTaxByApi = state.isServiceTax;
      state.toSaveData.productTaxByApi = state.isProductsTax;
      if (!state.isServiceTax && state.serviceTax ) {
        state.toSaveData.serviceTaxPercent = +state.serviceTax.toString().split("%")[0]
      } else {
        state.toSaveData.serviceTaxPercent = null;
      }
      if (!state.isProductsTax && state.productTax) {
        state.toSaveData.productTaxPercent = +state.productTax.toString().split("%")[0];
      } else {
        state.toSaveData.productTaxPercent = null;
      }
      state.toSaveData.countryTax = [];
      state.countryTaxList.forEach(item => {
        if (item.country !== "US") {
          const body = {};
          if (item.country) {
            body.country = item.country;
          }
          if (item.state) {
            body.countryState = item.state;
          }
          if (item.productPercent) {
            body.productTaxPercent = +item.productPercent.toString().split("%")[0];            
          }
          if (item.servicePercent) {
            body.serviceTaxPercent = +item.servicePercent.toString().split("%")[0];
          }
          state.toSaveData.countryTax.push(body);
        } else {
          if (item.subList) {
            item.subList.map(item => {
              const body = {};
              if (item.country) {
                body.country = item.country;
              }
              if (item.state) {
                body.countryState = item.state;
              }
              if (item.productPercent) {
                body.productTaxPercent = +item.productPercent.toString().split("%")[0]; 
              }
              if (item.servicePercent) {
                body.serviceTaxPercent = +item.servicePercent.toString().split("%")[0];
              }
              state.toSaveData.countryTax.push(body);
            });
          }
        }
      });
    },

    /**
     * Fetch data  from  api
     * **/

    fetchData(state, data) {
      state.isServiceTax = data.serviceTaxByApi;
      state.isProductsTax = data.productTaxByApi;
      state.productTax = data.productTaxPercent
        ? data.productTaxPercent + "%"
        : null;
      state.serviceTax = data.serviceTaxPercent
        ? data.serviceTaxPercent + "%"
        : null;
      state.countryTaxList = [];
      if(data.countryTax){
        const stateList = [];
        const usCountrySTateLength = data.countryTax.filter(
          x => x.country === "US"
        ).length;
        let counter = 0;

        data.countryTax.forEach(item => {
          const body = {
            id: null,
            country: "",
            state: "",
            productPercent: "",
            servicePercent: "",
            subList: []
          };
          if (item.id) {
            body.id = item.id;
          }
          if (item.country) {
            body.country = item.country;
          }
          if (item.countryState) {
            body.state = item.countryState;
          }
          if (item.serviceTaxPercent) {
            body.servicePercent = item.serviceTaxPercent + "%";
          }
          if (item.productTaxPercent) {
            body.productPercent = item.productTaxPercent + "%";
          }
          if (item.country !== "US") {
            state.countryTaxList.push(body);
          } else {
            stateList.push(body);
            ++counter;
            if (counter === usCountrySTateLength) {
              body.subList = stateList;
              state.countryTaxList.push(body);
            }
          }
        });
      }
    },

    addCountryTax(state, data) {
      const body = {
        country: data.country,
        state: data.state,
        productPercent: data.productPercent,
        servicePercent: data.servicePercent,
        subList: []
      };
      if (data.country === "US") {
        if (state.countryTaxList.length) {
          if (state.countryTaxList.some(x => x.country === data.country)) {
            state.countryTaxList.forEach(item => {
              if (item.country === data.country) {
                item.subList.push(data);
              }
            });
          } else {
            body.subList.push(data);
            state.countryTaxList.push(body);
          }
        } else {
          body.subList.push(data);
          state.countryTaxList.push(body);
        }
      } else {
        state.countryTaxList.push(data);
      }
      state.isAddMode = false;
      state.isEditMode = false;
    },
    addNewCountryTax(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
      state.isAddMode = true;
      state.cloneData = null;
    },

    deleteTaxCountry(state, body) {
      if (body.isSub) {
        state.countryTaxList.map((item, index) => {
          if (item.country === "US") {
            item.subList.splice(body.index, 1);
            if (!item.subList.length) {
              state.countryTaxList.splice(index, 1);
            }
          }
        });
      } else {
        state.countryTaxList.splice(body.index, 1);
      }
      state.isEditMode = false;
      state.isAddMode = false;
      state.cloneData = null;
      state.editedIndex = null;
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
    },

    setProductTax(state, value) {
      state.productTax = value;
    },
    setServiceTax(state, value) {
      state.serviceTax = value;
    },
    resetServiceTax(state) {
      state.serviceTax = null;
    },
    resetProductTax(state) {
      state.productTax = null;
    },

    //  set form Data

    setCountry(state, value) {
      state.formData.country = value;
    },
    setState(state, value) {
      state.formData.state = value;
    },
    setProductPercent(state, value) {
      state.formData.productPercent = value;
    },
    setServicePercent(state, value) {
      state.formData.servicePercent = value;
    },

    editTaxData(state, data) {
      state.formData = data.data;
      state.editedIndex = data.index;
      state.isSubData = data.isSubData;
      state.isEditMode = true;
      state.isAddMode = true;
      state.cloneData = JSON.parse(JSON.stringify(state.formData));
    },

    closeEdit(state) {
      state.isEditMode = false;
      state.isAddMode = false;
      state.cloneData = null;
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
      state.editedIndex = null;
    },

    resetDataClose(state) {
      state.isEditMode = false;
      state.isAddMode = false;
      state.cloneData = null;
      state.isProductsTax = false;
      state.isServiceTax = false;
      state.productTax = null;
      state.serviceTax = null;
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
      state.editedIndex = null;
      state.countryTaxList = [];
    },
    saveEditData(state, data) {
      state.isEditMode = false;
      state.isAddMode = false;

      if (state.isSubData) {
        state.countryTaxList.map(item => {
          if (item.country === data.country) {
            item.subList.map((subItem, index) => {
              if (index === state.editedIndex) {
                subItem.country = data.country;
                subItem.state = data.state;
                subItem.productPercent = data.productPercent;
                subItem.servicePercent = data.servicePercent;
              }
            });
          }
        });
      } else {
        state.countryTaxList.map((item, index) => {
          if (index === state.editedIndex) {
            item.country = data.country;
            item.state = data.state;
            item.productPercent = data.productPercent;
            item.servicePercent = data.servicePercent;
          }
        });
      }
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
    },

    resetData(state) {
      state.isEditMode = false;
      state.isAddMode = false;
      state.formData = JSON.parse(JSON.stringify(state.cloneData));
      if (state.isSubData) {
        state.countryTaxList.map(item => {
          if (item.country === state.formData.country) {
            item.subList.map((subItem, index) => {
              if (index === state.editedIndex) {
                subItem.country = state.formData.country;
                subItem.state = state.formData.state;
                subItem.productPercent = state.formData.productPercent;
                subItem.servicePercent = state.formData.servicePercent;
              }
            });
          }
        });
      } else {
        state.countryTaxList.map((item, index) => {
          if (index === state.editedIndex) {
            item.country = state.formData.country;
            item.state = state.formData.state;
            item.productPercent = state.formData.productPercent;
            item.servicePercent = state.formData.servicePercent;
          }
        });
      }
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
