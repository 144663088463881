import RequestService from "@/services/request.service";

const InitialState = {
  balanceManage: {
    isReset: false,
    isSave: false,
    isEdit: false,
    paymentMethod: [
      { name: "Money Order", value: 0 },
      { name: "Check", value: 1 },
      { name: "Cash", value: 2 }
    ],
    paymentMethodValue: null,
    routingNumber: null,
    balance: null,
    comment: null,
  },

  mainBalance: 0,
  dept: 0,
  credit: {
    isStartNow: false,
    isStart: false,
    isEdit: false,
    creditAmount: null,
    selectedCreditTerm: null,
    clientsPauseAfterDays: [
      { value: "1" },
      { value: "2" },
      { value: "3" },
      { value: "4" },
      { value: "5" },
      { value: "6" },
      { value: "7" },
      { value: "8" },
      { value: "9" },
      { value: "10" }
    ],
    date: null,
    selectedCreditsPauseAfterDays: null,
    creditAutoextend: false,
    comment: null,
    radioValue: "Day",
    radio: ["Day", "Month"]
  },
  creditData: null,
  cloneDataBalance: null,
  cloneCredit: null,
  toSaveBalance: {},
  toSaveCredits: {},
};
export const balanceCredit = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  actions: {
    getBalanceCreditData({ commit }, providerId) {
      return RequestService.read(`ottProviders/balanceCredit/edit/${providerId}`).then(
        r => {
          commit('fetchData', r.data)

          if (r.data.balance !== 0 && !r.data.credit) {
            commit('saveBalance'); // show balance list
          }

          if (!r.data.balance && r.data.credit) {
            commit('resetBalanceManage'); // show balance form
            commit('setIsStartNow', true); // show credit list
          }

          if (r.data.balance !== 0 && r.data.credit) {
            commit('saveBalance'); // show balance list
            commit('setIsStartNow', true); // show credit list
          }

          if (!r.data.balance && !r.data.credit) {
            commit('resetBalanceManage'); // show balance form
            commit('setIsStartNow', false); // show credit form
          }
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    addBalance({ commit, state, rootState, dispatch }) {
      return RequestService.create(`balances`, state.toSaveBalance)
        .then(
          async data => {
            commit('saveBalance')
            await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, { root: true })
            return Promise.resolve(data.data);
          },
          error => {
            return Promise.reject(error);
          }
        )
    },
    addCredit({ state, commit, rootState, dispatch }) {
      return RequestService.create(`credits`, state.toSaveCredits)
        .then(
          async data => {
            await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, { root: true })
            return Promise.resolve(data.data);
          },
          error => {
            return Promise.reject(error);
          }
        )
    },
    stopCredit({ commit, rootState, dispatch }, id) {
      return RequestService.update(`credits/stop/${id}`, {}).then(
        async data => {
          commit('stopCredit')
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, { root: true })
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },

    /**
     * stop credit Fata
     * **/
    stopCredit(state) {
      state.creditData = null;
      state.credit = JSON.parse(JSON.stringify(InitialState.credit))
    },

    cancelEditCredit(state) {
      if (state.cloneCredit) {
        state.credit = JSON.parse(JSON.stringify(state.cloneCredit))
        state.cloneCredit = null
      }
      state.credit.isEdit = false;
      state.credit.isStart = true;
    },
    resetDataCredit(state) {
      state.credit = JSON.parse(JSON.stringify(InitialState.credit))
      state.cloneCredit = null
      state.credit.isEdit = false;
      state.credit.isStart = false;
    },

    /**
     * get balance and  credit data
     * **/
    fetchData(state, data) {
      state.mainBalance = data.balance
      if (data.balance) {
        state.balanceManage.balance = data.balance
        state.balanceManage.isSave = true
      }
      if (data.credit) {
        state.creditData = data.credit.id
      }
      state.dept = data.dept
      if (data.credit) {
        state.credit.isEdit = false;
        state.credit.isStart = true;

        if (data.credit.creditStartDate) {
          state.credit.isStartNow = true
        }
        state.credit.date = data.credit.creditStartDate;

        state.credit.isStartNow = data.credit.startNow;
        state.credit.creditAmount = data.credit.creditAmount;
        state.credit.selectedCreditTerm = data.credit.creditTerm
        state.credit.radioValue = data.credit.days ? 'Day' : 'Month';
        state.credit.selectedCreditsPauseAfterDays = data.credit.clientsPauseAfterDays;
        state.credit.comment = data.credit.creditComment;
        state.credit.creditAutoextend = data.credit.creditAutoextend;
      }
    },

    /**
     * save create balance  model
     * **/
    saveBalanceManage(state, data) {
      state.cloneDataBalance = JSON.parse(JSON.stringify(state.balanceManage));
      state.balanceManage.isEdit = false;
      state.balanceManage.isSave = !state.balanceManage.isSave;
      state.toSaveBalance.providerId = data.providerId;
      state.toSaveBalance.balance = data.data.balance
      state.toSaveBalance.method = data.data.paymentMethodValue
      if (data.data.paymentMethodValue === 0) {
        state.toSaveBalance.moneyOrder = data.data.routingNumber
      }
      if (data.data.paymentMethodValue === 1) {
        state.toSaveBalance.checkNumber = data.data.routingNumber
      }

    },
    /**
     * Save balance data  from  back
     * **/
    saveBalance(state) {
      state.balanceManage.isSave = true
    },
    //--------------------balanceManage
    setBalanceManageEdit(state, data) {
      state.balanceManage = JSON.parse(
        JSON.stringify(InitialState.balanceManage)
      );
      state.balanceManage.isSave = !state.balanceManage.isSave;
      state.balanceManage.isEdit = true;

    },

    resetBalanceManage(state) {
      if (state.balanceManage.isEdit) {
        state.balanceManage = JSON.parse(
          JSON.stringify(InitialState.balanceManage)
        );
        state.balanceManage.isEdit = false;
        state.balanceManage.isSave = true;
      } else {
        state.balanceManage = JSON.parse(
          JSON.stringify(InitialState.balanceManage)
        );
      }
    },
    setPaymentMethodValue(state, data) {
      state.balanceManage.paymentMethodValue = data;
    },
    setRoutingNumber(state, data) {
      state.balanceManage.routingNumber = data;
    },
    setBalance(state, data) {
      state.balanceManage.balance = data;
    },
    setComment(state, data) {
      state.balanceManage.comment = data;
    },

    //--------------------Credit
    setRadio(state, data) {
      state.credit.radioValue = data;
    },
    setCreditComment(state, data) {
      state.credit.comment = data;
    },
    selectedCreditsPauseAfterDays(state, data) {
      state.credit.selectedCreditsPauseAfterDays = data;
    },
    setDate(state, date) {
      state.credit.date = date;
    },
    setCreditAmount(state, data) {
      state.credit.creditAmount = data;
    },
    selectedCreditTerm(state, data) {
      state.credit.selectedCreditTerm = data;
    },
    setCreditAutoextend(state, data) {
      state.credit.creditAutoextend = data;
    },
    setIsStartNow(state, data) {
      state.credit.isStartNow = data;
    },
    setStartCredit(state, data) {
      state.credit.isEdit = false;
      state.credit.isStart = !state.credit.isStart;
      if (state.credit.isStartNow) {
        state.credit.date = new Date();
        state.toSaveCredits.creditStartDate = new Date();
      } else {
        state.toSaveCredits.creditStartDate = data.data.date;
      }
      state.toSaveCredits.providerId = data.providerId;
      state.toSaveCredits.startNow = data.data.isStartNow;
      state.toSaveCredits.creditAmount = data.data.creditAmount;
      state.toSaveCredits.creditTerm = data.data.selectedCreditTerm;
      state.toSaveCredits.days = state.credit.radioValue === 'Day';
      state.toSaveCredits.months = state.credit.radioValue !== 'Day';
      state.toSaveCredits.clientsPauseAfterDays = data.data.selectedCreditsPauseAfterDays;
      state.toSaveCredits.creditComment = data.data.comment;
      state.toSaveCredits.creditAutoextend = data.data.creditAutoextend;
    },
    setCreditEdit(state, data) {
      state.credit.isStart = !state.credit.isStart;
      state.credit.isEdit = true;
      state.cloneCredit = JSON.parse(JSON.stringify(state.credit))
    },
    resetData(state) {
      state.balanceManage.isEdit = false;
      state.balanceManage.isSave = false;
      state.balanceManage.isReset = false;
      state.balanceManage.paymentMethodValue = null;
      state.balanceManage.routingNumber = null;
      state.balanceManage.balance = null;
      state.balanceManage.comment = null;

      state.credit.isStartNow = false;
      state.credit.isStart = false;
      state.credit.isEdit = false;
      state.credit.creditAmount = null;
      state.credit.date = null;
      state.credit.selectedCreditsPauseAfterDays = null;
      state.credit.comment = null;
      state.credit.radioValue = "Day";
      state.credit.selectedCreditTerm = null;
      state.creditData = null

    },
    resetState(state) {
      for (let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))
      }
    },
  }
};
