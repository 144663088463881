export default class EquipmentsFilterModel {
  constructor() {
    this.reset();
  }

  toSave() {
    return {};
  }

  reset() {
    this.search = "";
    this.price = [0, 0];
    this.type = null;

    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);
    switch (dataKey) {
      case "search": {
        this.search = "";
        break;
      }

      case "price": {
        this.price = [0, 0];
        break;
      }

      case "type": {
        this.type = null;
        break;
      }
    }
    this.filterCount.splice(index, 1);
  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes("search")) {
      this.filterCount.push("search");
    }

    if (
      (this.price[0] || this.price[1]) &&
      !this.filterCount.includes("price")
    ) {
      this.filterCount.push("price");
    }

    if (this.type && !this.filterCount.includes("type")) {
      this.filterCount.push("type");
    }
  }
}
