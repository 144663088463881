const InitialState = {

  isSelectAll: false,
  selectedPriceGroupList: [],

  discountPriceGroups: []

};

export const priceGroupDiscount = {
  namespaced: true,
  actions: {},

  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {

    setPriceGroupData(state, data) {
      let arrayPrices = []

      data.map(price => {
        arrayPrices.push(price?.item)
      })

      state.discountPriceGroups = arrayPrices

      // let x= data.map(x=>x.id)
      // state.selectedPriceGroupList = data
    },

    updatePriceGroup(state, data) {
      if (state.discountPriceGroups.includes(data.id)) {
        let index = state.discountPriceGroups.indexOf(data.id)
        state.discountPriceGroups.splice(index, 1)
      } else {
        state.discountPriceGroups.push(data.id)
      }
    },

    resetPriceGroupList(state) {
      state.discountPriceGroups = []
    },

    selectAllPriceGroupList(state, priceGroups) {

      if (state.discountPriceGroups.length !== priceGroups.length) {
        priceGroups.forEach(item => {
          if (!state.discountPriceGroups.includes(item.id)) {
            state.discountPriceGroups.push(item.id)
          }
        })
      } else {
        state.discountPriceGroups = []
      }
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
