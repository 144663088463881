export const autoPaymentList = [
  {
    name: 'All',
    value: 1
  },
  {
    name: 'Enabled',
    value: 2
  }, {
    name: 'Disabled',
    value: 3
  },
]
export const paymentStatusList = [
  {
    name: "Paid",
    id: 1,
    value: 1,
  },
  {
    name: "Unpaid",
    id: 2,
    value: 2,
  },
  // {
  //   name: "Refunded",
  //   id: 3,
  //   value: 3,
  // },
  // {
  //   name: "Canceled",
  //   id: 4,
  //   value: 4,
  // },
  // {
  //   name: "Upcoming",
  //   id: 5,
  //   value: 5,
  // },
  // {
  //   name: "Declined",
  //   id: 6,
  //   value: 6,
  // }
]
export const paymentActonByList = [
  {
    name: 'All',
    value: 1
  },
  {
    name: 'Client',
    value: 2
  },
  {
    name: 'Provider',
    value: 3
  },
  {
    name: 'Parent Provider',
    value: 4
  }

]
export const paymentMethodList = [
  {
    name: "All",
    id: 1,
    value: 1,
  },
  {
    name: "Card",
    id: 2,
    value: 2,
  },
  {
    name: "Bank",
    id: 3,
    value: 3,
  },
  {
    name: "Cash",
    id: 4,
    value: 4,
  },
  {
    name: "Money order",
    id: 5,
    value: 5,
  }
]
export const billSentMethodList = [
  {
    name: "Postal Methods",
    id: 1,
    value: 1,
  },
  {
    name: "Mail",
    id: 2,
    value: 2,
  },
  {
    name: "Email",
    id: 3,
    value: 3,
  },
]
export const billInfoTypeList = [
  {
    name: 'Balance',
    value: 1
  },
  {
    name: 'Subscription',
    value: 2
  }
]
