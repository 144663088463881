const discount = () => import("@/views/discounts/Discounts");

export const discountsRout = {
  path: "/discounts",
  redirect: "/",
  name: "Discount",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "",
      name: "",
      component: discount,
      meta: {auth: true, roles: ["ottprovider"], permissions: []}
    }

  ]
};
