export default class EquipmentsTypesFilterModel {
    constructor() {
        this.reset();
    }

    toSave() {
        return {};
    }

    reset() {
        this.search = "";
        this.filterCount = [];
    }

    resetCurrentData(dataKey) {
        let index = this.filterCount.indexOf(dataKey);
        switch (dataKey) {
            case "search": {
                this.search = "";
                break;
            }
        }
        this.filterCount.splice(index, 1);
    }

    getFilterCount() {
        if (this.search.trim() && !this.filterCount.includes("search")) {
            this.filterCount.push("search");
        }
    }
}