const shippings = () => import("@/views/shippings/Shippings");

export const shippingsRout = {
    path: "/shippings",
    redirect: "/shippings",
    name: "Shippings",
    component: shippings,
    children: [
      {
        path: "",
        name: "",
        component: shippings,
      }
    ]
  };
  