export default class ClientActivityModel {
    constructor() {
      this.reset();
    }
  
    toSave() {
      return {};
    }
  
    reset() {
      this.search = '';
      this.allResellers = false;
      this.resellers = [];
      this.date = {
        start: null,
        end: null
      },
      this.filterCount = [];
    }
  
    resetCurrentData(dataKey) {
      let index = this.filterCount.indexOf(dataKey);
      this.filterCount.splice(index, 1);
      switch (dataKey) {
        case "search":
          this.search = "";
          break;
      
        case "allResellers":
          this.allResellers = false;
          break;
        
        case "resellers": 
          this.resellers = [];
          break;

        case "date":
          this.date = {
            start: null,
            end: null
          };
          break;
      
      }
    }
  
    getFilterCount() {
      if (this.search.trim() && !this.filterCount.includes("search")) {
        this.filterCount.push("search");
      }

      if (this.allResellers && !this.filterCount.includes("allResellers")) {
        this.filterCount.push("allResellers");
      }

      if (this.resellers.length && !this.filterCount.includes("resellers")) {
        this.filterCount.push("resellers");
      }

      if (
        this.date?.start &&
        this.date?.end &&
        !this.filterCount.includes("date")
      ) {
        this.filterCount.push("date");
      }
    }
  }
  