import RequestService from "@/services/request.service";

const InitialState = {
  openModal: {
    isOpen: false
  },

  showComponents: false,

  formData: {
    locationName: '',
    roomsCount: 1,
    maxDevice: 5,
    timezone: null,
    server: '',
    parentalCode: '123456',
    isBlockLocation: false,
    isPauseSubscriptions: false,
    VODEnable: true,
    archiveEnable: true,
    timeShiftEnable: true,
    comment: null
  },

  maxDeviceList: [
    { name: 5, value: 5 },
    { name: 10, value: 10 },
    { name: 20, value: 20 },
    { name: 50, value: 50 },
  ],

  serverList: [],

  locationList: [],
  initialLocationList: [],

  isEditMode: false,
  isAddMode: false,
  cloneFormData: null,
  randomLogin: '',
  randomPass: '',
  lastChangedPassword: null,
  editLocationId: null,
  passwordIsUpdated: false,
  isLoading: false,
  locationComments: []
};

export const locationClient = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {

    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    /**
     * open and close update  password modal
     * **/

    toggleModalResetPassword(state, value) {
      state.openModal.isOpen = value
    },

    /**
     * Add new location
     * **/

    updateLocations(state, locations = []) {
      state.locationList = [...locations];
      state.initialLocationList = JSON.parse(JSON.stringify(locations));
      state.isAddMode = false;
      state.isEditMode = false;
    },

    /**
     * Update formData for edit
     * **/

    updateFormData(state, data) {
      data.parentalCode = "123456";
      state.formData = data;
      state.isEditMode = true;
      state.isAddMode = false;
    },

    /**
     * Reset  form Data
     * **/

    resetFormData(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
    },

    resetAllData(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
      state.locationList = [];
      state.initialLocationList = [];
      state.isEditMode = false;
      state.isAddMode = false;
      state.cloneFormData = null;
    },

    /**
     * Set Location packages
     * **/

    setLocationPackages(state, { locationId, packages }) {
      state.locationList.map(item => {
        if (item._id === locationId) {
          item.packages = [...packages]
        }
      })
    },

    /**
     * Set from data
     * **/

    setFormData(state, data) {
      for (const key in data) {
        state.formData[key] = data[key]
      }
    },

    setLocationIsAdd(state, value) {
      state.isAddMode = value
    },
    setLocationIsEdit(state, value) {
      state.isEditMode = value
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  },
  actions: {
    async add({ dispatch, commit }, data) {
      return RequestService.create("clients/locations", { ...data })
          .then(async (response) => {
            await dispatch('getLocations')
            await dispatch('clientsModule/getClientList', {}, { root: true })
            commit('setData', { isLoading: false })
          })
          .catch((e) => console.log(e))
          .finally(() => { commit('setData', { isLoading: false }) })
    },

    async editLocation({ state, dispatch, commit }, data) {
      console.log(data, 'data')
      const editLocationId = state.editLocationId ? state.editLocationId : data?.locationId
      delete data.parentalCode;
      delete data.locationId;

      return RequestService.update(`clients/locations/edit/${editLocationId}`, { ...data })
          .then(async (r) => {
            await dispatch('getLocations')
            await dispatch('clientsModule/getClientList', {}, { root: true })
            commit('setData', { isLoading: false })
          })
          .catch((e) => console.log(e))
          .finally(() => { commit('setData', { isLoading: false }) })
    },

    getRandomLoginPass({ commit, rootState }, { clientId } = { clientId: null }) {
      let client = clientId ? clientId : rootState.clientsModule.clientId
      return RequestService.read(`clients/locations/randomLoginPassword/${client}`).then((data) => {
        commit("setData", {
          randomLogin: data.data.login,
          randomPass: data.data.password,
          lastChangedPassword: data.data.lastChangedPassword
        })
      }).catch((e) => console.log(e))
    },

    updateRandomLoginPass({ state, dispatch }, payload) {
      return RequestService.update(`clients/locations/edit/reset-password/${state.editLocationId}`, { ...payload })
    },

    getLocations({ commit, rootState }, { clientId } = { clientId: null }) {
      let sortText = "";
      let client = clientId ? clientId : rootState.clientsModule.clientId; 

      return RequestService.read(`clients/locations/${client}${sortText}`).then((r) => {
        commit("updateLocations", []); // first remove all old data
        commit("updateLocations", r.data);
        if (r.data.length) {
          if (!rootState.packagesClients.openPackagesFromManage) {
            commit("packagesClients/setData", { selectedLocation: r.data[0] }, {root: true})
          }
          return Promise.resolve(true)
        }
      }).catch((e) => console.log(e))
    },

    getLocation({ commit }, locationId) {
      return RequestService.read(`clients/locations/edit/${locationId}`).then((data) => {
        commit("updateFormData", data.data);
      }).catch((e) => console.log(e));
    },

     deleteLocation({ dispatch }, locationId) {
      return RequestService.delete(`clients/locations/edit/${locationId}`).then(async (r) => {
        await dispatch('getLocations')
        await dispatch('clientsModule/getClientList', {}, { root: true })
      }).catch((e) => console.log(e))
    },
  }
};
