import Vue from "vue";

Vue.mixin({
    methods: {
        OttClone(input) {
            if (input) {
                if (input.constructor.name == "Array") {
                    // do something
                    const resp = [];
                    for(const item of input){
                        resp.push(Object.assign({}, item));
                    }
                    return resp;
                }
            }
            return input
        },
    },
})