import RequestService from "@/services/request.service";

const InitialState = {
  modal: {
    isOpen: false
  },
  paymentState: 2,
  hasError: false,
  errorMessage: '',
  paymentErrorMessage: "Payment Error",
  paymentMessage: "Payment Error",
  activeStep: 1,
  activePaymentType: '',

  checkedStep: 0,
  balance: 1000,
  payFromBalance: null,

  addCreditFormData: {
    credit: 0,
    creditDayType: 'day',
    creditValue: null

  },
  // Credit  path
  dayTypes: {
    day: 'day',
    month: 'month'
  },
  isAddCredit: false,
  invoiceId: null,
  // Check
  check: {
    number: null,
    mailByCheckeeper: true
  },
  moneyOrder: {
    number: null,
  },
  bankName: '',
  bankNames: [],
  // Fast credit
  fastCredit: {
    creditTerm: '',
    billSendByEmail: false,
    billSendByPostalMethod: false
  },
  fastCreditType: {
    day: 'Day',
    month: 'Month',
  },

  accessShipping: false,

  // Confirmation step
  orderReviewData: {
    firstOffice: {
      office: "Office (1234567) Room - 1",
      info: [
        {
          name: "Package name",
          action: "Prolong",
          startDate: "10/1/2021",
          endDate: "10/1/2021",
          endDateWas: "(was 10/30/2021)",
          monthDays: "1m 0d",
          MPrice: "$50",
          amount: "$50"
        },
        {
          name: "Package name",
          action: "Prolong",
          startDate: "10/1/2021",
          endDate: "10/1/2021",
          endDateWas: "(was 10/30/2021)",
          monthDays: "1m 0d",
          MPrice: "$50",
          amount: "$50"
        },
      ]
    },
    secondOffice: {
      office: "Office (1234567) Room - 2",
      info: [
        {
          name: "Package name",
          action: "Prolong",
          startDate: "10/1/2021",
          endDate: "10/1/2021",
          endDateWas: "(was 10/30/2021)",
          monthDays: "1m 0d",
          MPrice: "$50",
          amount: "$50"
        },
        {
          name: "Package name",
          action: "Prolong",
          startDate: "10/1/2021",
          endDate: "10/1/2021",
          endDateWas: "(was 10/30/2021)",
          monthDays: "1m 0d",
          MPrice: "$50",
          amount: "$50"
        },
      ]
    }
  },
  confirm: {
    invoiceSendByEmail: false,
    invoiceSendByPostalMethod: false,
    receiptSendByEmail: false
  },
  // Order summary
  orderSummary: {
    home: "$50",
    office: "$20",
    tax: "$10",
    bankFee: "$5",
    subtotal: "$85",
    payFromCard: "$10",
    balanceHistory: {
      InitialState: "$200",
      newCredit: "",
      fullOrderPayment: "$10",
      afterConfirmState: "$200"
    },
    creditHistory: {
      InitialState: "$0",
      InitialStateUpTo: "(up to 10/5/2021)",
      newCredit: "$0",
      newCreditUpTo: "(up to 10/5/2021)",
      fullOrderPayment: "$10",
      afterConfirmState: "$200"
    }
  },

  invoiceData: {},
  checkoutData: {},
};

export const checkoutClients = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    /**
     * for all first level keys changing  
     * setData({ key: 'value' })
     */
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    /***
     * for all second level object keys changing
     *  setDataSecondLevels({
     *    stateKey1: { changingKey: 'newVal' },
     *    stateKey2: { changingKey2: 'newVal' },  
     *  })
     **/
    setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if (state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },

    /**
     * change step
     * **/

    changeStep(state, step) {
      state.activeStep = step
    },

    /**
     * Open Modal checkout
     * **/
    openModal(state) {
      state.modal.isOpen = true
    },


    /**
     * Close Modal checkout
     * **/
    closeModal(state) {
      state.modal.isOpen = false
      state.activeStep = 1
    },

    /**
     * toggle open add credit block
     * **/
    toggleSetCreditOpen(state, value) {
      state.isAddCredit = value
    },

    /**
     * Set Fast Credit Data
     */
    setCreditTerm(state, value) {
      state.fastCredit.creditTerm = value
    },
    setBillSendByEmail(state, value) {
      state.fastCredit.billSendByEmail = value
    },
    setBillSendByPostalMethod(state, value) {
      state.fastCredit.billSendByPostalMethod = value
    },

    /**
     * Set Confirmation step Data
     */
    setInvoiceSendByEmail(state, value) {
      state.confirm.invoiceSendByEmail = value
    },
    setInvoiceSendByPostalMethod(state, value) {
      state.confirm.invoiceSendByPostalMethod = value
    },
    setReceiptSendByEmail(state, value) {
      state.confirm.receiptSendByEmail = value
    },

    resetState(state) {
      for (let key in state) {
        state[key] = InitialState[key] ? JSON.parse(JSON.stringify(InitialState[key])) : null
      }
    }
  },
  actions: {
    payInvoice({ state, commit, dispatch }, payData) {
      if (state.invoiceData.id !== undefined) {
        return RequestService.create(`invoices/pay/${state.invoiceData.id}`, { ...payData }).then(async (r) => {
          commit('setData', { invoiceData: r.data.invoice })
          commit('setData', { checkoutData: r.data.invoice })
          if (!r.data.status) {
            commit('setData', { paymentState: 0, paymentErrorMessage: r.data.message ? r.data.message : `error while paying invoice` })
          } else {
            if (r.data.invoice && r.data.invoice.state === 1) {
              // await this.cancel();
            }
            if (r.data.transaction && r.data.transaction.state !== 1) {
              commit('setData', { paymentState: 0, paymentErrorMessage: r?.transaction?.stateMessage })
            }
            if (r.data.transaction && r.data.transaction.state === 1) {
              commit('setData', { paymentState: 1, paymentMessage: `Payment Successful!` })
            }

          }
          return Promise.resolve(r.data)
        }, r=>{
          return Promise.reject(r);
        })
      }
    },

    updatePayData({ state, commit, dispatch }, payData) {
      if (state.invoiceData.id !== undefined) {
        return RequestService.update(`invoices/updatePayData/${state.invoiceData.id}`, { ...payData }).then(async (r) => {
          commit('setData', { invoiceData: r.data.invoice })
          commit('setData', { checkoutData: r.data.invoice })
          return Promise.resolve(r.data)
        })
      }
    },

    addBankName({ commit }, bankName) {
      return RequestService.create('bankNames', { name: bankName })
    },

    getBankNames({ commit }) {
      return RequestService.read('bankNames?limit=100000').then((r) => {
        if (r.data.results.length) {
          commit('setData', { bankNames: r.data.results })
        }
      })
    },

    // Send from checkout last step, sending transaction
    sendInvoice({ state }, {id, data}) {
      return RequestService.update(`transactions/sendByInvoice/${id}`, data).catch(e => console.log(e))
    },

    // Send from checkout Order Summary, before checkout successed
    sendPayment({ state }, {id, data}) {
      return RequestService.update(`invoices/sendCheckoutToClient/${id}`, data).catch(e => console.log(e))
    },

  },
};
