export default class DiscountFilterModel {
  constructor() {
    this.reset();
  }

  toSave() {
    return {};
  }

  reset() {
    this.search = '';
    this.current = false;
    this.upcoming = false;
    this.past = false;
    this.date = {
      start: null,
      end: null
    };
    this.status = null;
    this.type = null;
    this.priceGroup = [];
    this.packages = [];

    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);
    switch (dataKey) {
      case "search": {
        this.search = "";
        break;
      }
      case "current": {
        this.current = false;
        break;
      }
      case "upcoming": {
        this.upcoming = false;
        break;
      }
      case "past": {
        this.past = false;
        break;
      }
      case "date": {
        this.date = {
          start: null,
          end: null
        };
        break;
      }
      case "status": {
        this.status = null;
        break;
      }
      case "type": {
        this.type = null;
        break;
      }
      case "priceGroup": {
        this.priceGroup = [];
        break;
      }
      case "packages": {
        this.packages = [];
        break;
      }

    }
    this.filterCount.splice(index, 1);

  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes("search")) {
      this.filterCount.push("search");
    }
    if (this.current && !this.filterCount.includes("current")) {
      this.filterCount.push("current");
    }
    if (this.upcoming && !this.filterCount.includes("upcoming")) {
      this.filterCount.push("upcoming");
    }
    if (this.past && !this.filterCount.includes("past")) {
      this.filterCount.push("past");
    }
    if (this.date?.start && this.date?.end && !this.filterCount.includes("date")) {
      this.filterCount.push("date");
    }
    if ((this.status) && !this.filterCount.includes("status")) {
      this.filterCount.push("status");
    }
    if (this.type && !this.filterCount.includes("type")) {
      this.filterCount.push("type");
    }
    if (this.priceGroup.length && !this.filterCount.includes("priceGroup")) {
      this.filterCount.push("priceGroup");
    }
    if (this.packages.length && !this.filterCount.includes("packages")) {
      this.filterCount.push("packages");
    }
  }

}
