const packages = () => import("@/views/packages/Packages");

export const packagesRout = {
  path: "/packages",
  redirect: "/",
  name: "Packages",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "",
      name: "",
      component: packages,
      meta: {auth: true, roles: ["ottprovider"], permissions: []}
    }

  ]
};
