import RequestService from "@/services/request.service";

const InitialState = {
  fileUploadProgress: 0
};

export const fileUpload = {
  namespaced: true,
  actions: {
    uploadImage({ commit }, file) {
      commit('setData', { fileUploadProgress: 0 })
      let formData = new FormData();
      formData.append('file', file);
    
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          commit('setData', { fileUploadProgress: progress })
        },
      };
    
      return RequestService.create('files', formData, RequestService.generateToken(), config).then(
        data => {
          commit('fetchData', data.data);
          commit('setData', { fileUploadProgress: 0 })
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getImage({commit}, id) {
      return RequestService.read(`files/edit/${id}`).then(
        data => {
          commit('fetchData', data.data)
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    deleteImage({commit}, id) {
      return RequestService.delete(`files/edit/${id}`).then(
        data => {
          return Promise.resolve();
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
  },

  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
          state[key] = data[key];
      }
    },

    fetchData(state, data){
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
