<template>
  <div class="gantt-container">
  <g-gantt-chart
    :chart-start="myChartStart"
    :chart-end="myChartEnd"
    width="100%"
      style="position: relative;"
  >
    <g-gantt-row
      v-for="row in invoiceData"
      :key="row.label"
      :label="row.label"
      :bars="row.bars"
      bar-start="myStart"
      bar-end="myEnd"
    />
  </g-gantt-chart>
  </div>
</template>

<script>


import {GGanttChart, GGanttRow} from 'vue-ganttastic'

export default {
  name: 'GanttChartt',
  components: {
    GGanttChart,
    GGanttRow
  },
  props: {
    invoiceData: Array,
    myChartStart: String,
    myChartEnd: String
  },
  data() {
    return {
      chartData: [],
      rows: [
        {
          label: "My row #1",
          bars: [
            {
              myStart: "2020-03-01 12:10",
              myEnd: "2020-03-01 16:35"
            }
          ]
        },
        {
          label: "My row #2",
          bars: [
            {
              myStart: "2020-03-02 01:00",
              myEnd: "2020-03-02 12:00"
            },
            {
              myStart: "2020-03-02 13:00",
              myEnd: "2020-03-02 22:00"
            }
          ]
        }
      ]
    };
  },
  watch: {
    invoiceData: {
      deep: true,
      handler() {
        this.prepareChartData();
      }
    }
  },
  mounted() {
    this.prepareChartData();
  },
  methods: {
    prepareChartData() {
      // this.chartData = this.invoiceData.map(invoice => {
      //   // Transform your invoice data into the format required by the chart
      //   // Example: [invoiceNumber, startDate, endDate]
      //   return [invoice.invoiceNumber, invoice.startDate, invoice.endDate];
      // });
      // this.drawChart();
    },
    drawChart() {
      // Initialize and draw your Gantt chart using this.chartData
      // Refer to your specific charting library's documentation for details
    }
  }
};
</script>

<style>
/* Add any styles for your Gantt chart component */
</style>


<style>
.gantt-container {
  width: 100%; /* Adjust the width as needed */
  overflow-x: auto; /* Allows horizontal scrolling */
}

g-gantt-chart {
  min-width: 1500px; /* Adjust based on your needs */
  /* other styles */
}
</style>