import Vue from "vue";

Vue.mixin({
    methods: {
        /**
         * Functions for providers list data
         */
        getSyncColor(storeState, syncState) {
            const state = storeState || syncState;
            switch (state) {
                case 0: {
                    return `#F53A3A`
                }
                case 1: {
                    return `#5FC788`
                }
                case 2: {
                    return `#012F57`
                }
                default: {
                    return `#F53A3A`
                }
            }
        },
    },
})