
const InitialState = {
  modal: {
    isOpen: false
  },
};

export const uploadImageModal = {
  namespaced: true,
  actions: {},

  state: InitialState,
  mutations: {
    openModal(state, data) {
      state.modal.isOpen = true;
    },
    closeModal(state, data) {
      state.modal.isOpen = false;
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }

  }
};
