import RequestService from "@/services/request.service";

const InitialState = {
  modal: {
    isOpen: false,
  },

  activeTab: 0,
  disableTabs: 15,
  showList: [
    { name: "General Info", title: "generalInfo" },
    { name: "Company Info", title: "companyInfo" }, 
    { name: "Company Addresses", title: "companyAddresses" },
    { name: "Company Contact", title: "contact" },
    { name: "Admin Personal info", title: "adminPersonalInfo" },
    {
      name: "Info For Clients Apps",
      title: "infoForClientsApps"
    },
    { name: "Price Group", title: "priceGroup" },
    { name: "Payment Methods", title: "paymentMethods" },
    { name: "Bill/Invoices Generation", title: "billInvoicesGeneration" },
    { name: "Label/Receipt Printers", title: "labelReceiptPrinters" },
    { name: "Sale Taxes", title: "saleTaxes" },
    { name: "Payment Gateway", title: "paymentGateway" },
    { name: "Shipping Providers", title: "shippingProviders" },
    { name: "Other Api Integrations", title: "otherApiIntegrations" },
    { name: "Conversation API", title: "conversationApi" },
    { name: "Ui & Access Customization", title: "uiAndAccessCustomization" },
    { name: "Checkeeper Layout", title: "checkeeperLayout" },
  ],
  providerId: null,
  providerData: null,
  registeredData: null,
  authUserProviderCountry: '',
  parentId: null,
  hasValidTwilio: false,
  hasValidSmtp: false
};


export const manageAdminModule = {
  namespaced: true,
  actions: {
    getAdminData({rootState, commit}) {
      return RequestService.read(
        `ottProviders/edit/${rootState.auth.user.provider.id}`,
      ).then(
        data => {
          if (data.data !== undefined) {
            commit('setData', { 
              authUserProviderCountry: data.data.country,
              parentId: data.data?.parent ? data.data.parent._id : null,
              hasValidTwilio: data.data?.hasValidTwilio,
              hasValidSmtp: data.data?.hasValidSmtp
            })
          }
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    editAdmin({commit, state}, data) {
      return RequestService.update(
        `ottProviders/edit/${state.providerId}`,
        data
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    validateEmail({ commit }, queryOptions) {
      let query = Object.keys(queryOptions)
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      return RequestService.read(`ottProviders/emails/check-mail?${query}`).then(
        async data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    validatePhone({ commit }, queryOptions) {
      let query = Object.keys(queryOptions)
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      return RequestService.read(`ottProviders/phones/check-phone?${query}`).then(
        async data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },

    /**
     * change tab
     * **/
    changeActiveTab(state, data) {
      state.activeTab = data;
    },

    /**
     * toggle open modal
     * **/

    toggleOpenModal(state, value) {
      state.modal.isOpen = value
    },

    setProviderId(state, value) {
      state.providerId = value
    },

    /**
     * Set  provider data
     * **/
    setProviderData(state, data) {
      state.providerData = data
    },
    resetActiveTabs(state) {
      state.activeTab = 0;
      state.disableTabs = 0;
      state.providerId = null;
      state.providerData = null;
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    },

    setRegisteredData(state, data) {
      state.registeredData = data
    }

  }
};

