import EquipmentsFilterModel from "../../models/equipments/EquipmentsFilterModel";
import RequestService from "@/services/request.service";
const FileDownload = require("js-file-download");

// lodash
import _ from "lodash";

const InitialState = {
  filterModel: new EquipmentsFilterModel(),

  modal: {
    isOpen: false,
    showWarningModal: false,
  },

  equipmentModel: {
    equipmentId: [],
  },

  typeList: [],

  selectedEquipmentId: "",
  isEditMode: false,
  activeTab: 0,
  showList: [
    { name: "Information", title: "information" },
    { name: "Price", title: "price" },
    { name: "Options", title: "options" },
    { name: "Product Image", title: "productImage" },
    { name: "Discount", title: "dicount" }
  ],
  isTableLoading: false,

  types: [],

  // filter data
  filter: {
    limit: 20,
    sortBy: "_id:desc",
    page: 1,
  },
  //  for  pagination  table
  dataCount: 0,
  currentPage: 0,
  totalPages: 0,

  filterData: null,

  equipmentId: null,

  equipmentData: {
    name: "",
    description: "",
    type: null,
    price: null,
    enableForSale: false,
    isService: false,
    sku: '',
    information: {
      category: null,
      manufacturer: null,
      certification: null,
      condition: null,
      standart: null,
      os: null,
      unitSize: null,
      height: null,
      width: null,
      length: null,
      unitWeight: null,
      productWeight: null,
    },
    priceTab: {
      saleRent: 1,
      dayMonthType: null,
      numberOfMD: null,
      firstPc: null,
      secondPc: null,
      thirdPc: null,
      fourthPc: null,
      fifthPc: null,
      priceList: [
        //   md: "10d",
        //   priceGroup: "default",
        //   showPriceGroupSelect: false,
        //   pieces: [
        //     {
        //       piece: 8,
        //       price: 20,
        //       showInp: false,
        //     },
        //     {
        //       piece: 9,
        //       price: 20,
        //       showInp: false,
        //     },
        //     {
        //       piece: 10,
        //       price: 20,
        //       showInp: false,
        //     },
        //     {
        //       piece: 18,
        //       price: 20,
        //       showInp: false,
        //     },
        //   ]
        // },
      ],
    },
    options: {
      selectedColor: null,
      isAddColor: false,
      colorName: null,
      colorCode: null,
      colorVariantStock: null,
      isDefaultColor: false,
      colorsList: [],
    },
    productImage: {
      imagesList: [],
      colorCode: null,
      colorFromList: null,
      selectedImage: null,
    },
    discount: {
      selectedDiscounts: []
    }
  },
  openImageEditor: false,
  colorsSelectList: [],

  equipmentsResult: false,

  // for  update  table  columns
  defaultSettings: [],
  updateColumnsFromBack: "equipmentsModule/updateEquipmentsSettings",
  updateColumnSettingsCommit: "equipmentsModule/setDefaultSettings",
  isDefault: false,

  dataTable: [],
};


export const equipmentsModule = {
  namespaced: true,

  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    setLimit(state, newValue) {
      state.filter.limit = newValue;
    },
    setFilterPage(state, page) {
      state.filter.page = page
      state.currentPage = page;
    },
    setEquipmentData(state, { firstLevel, secondLevel, value }) {
      state.equipmentData[firstLevel][secondLevel] = value;
    },
    setEquipmentDataKeys(state, { key, value }) {
      state.equipmentData[key] = value
    },
    setEditingEquipment(state, data) {

      let type = state.types.filter((elem) => elem.value === data.type.id)[0]
        .value;

      // check if needed keys exist in the data thay has changed valu then get those values
      if ('name' in data) {
        state.equipmentData.name = data.name[0].name;
      }

      if ('description' in data) {
        state.equipmentData.description = data.description;
      }

      if ('type' in data) {
        state.equipmentData.type = type;
      }

      if ('price' in data) {
        state.equipmentData.price = data.price;
      }

      if ('enableForSale' in data) {
        state.equipmentData.enableForSale = data.enableForSale;
      }

      if ('isService' in data) {
        state.equipmentData.isService = data.isService;
      }

      if ('sku' in data) {
        state.equipmentData.sku = data.sku;
      }

      if ('information' in data) {
        for (const key in data.information) {
          if (data.information[key]) {
            state.equipmentData.information[key] = data.information[key]
          }
        }
      }

      if ('options' in data) {
        for (const key in data.options) {
          if (key === 'colorsList' && data.options.colorsList.length) {
            state.equipmentData.options.colorsList = data.options.colorsList
          }

          if (key !== 'colorsList' && data.options[key]) {
            state.equipmentData.options[key] = data.options[key]
          }
        }
      }

      if ('productImage' in data) {
        for (const key in data.productImage) {
          if (key === 'imagesList' && data.productImage.imagesList.length) {
            state.equipmentData.productImage.imagesList = data.productImage.imagesList
          }

          if (key !== 'imagesList' && data.productImage[key]) {
            state.equipmentData.productImage[key] = data.productImage[key]
          }
        }
      }

      if ('prices' in data) {
        state.equipmentData.priceTab.priceList = data.prices
      }

      if ('discount' in data) {
        state.equipmentData.discount = data.discount
      }
    },
    resetEquipmentData(state) {
      state.equipmentData = {
        name: "",
        description: "",
        type: null,
        price: null,
        enableForSale: false,
        isService: false,
        sku: '',
        information: {
          category: null,
          manufacturer: null,
          certification: null,
          condition: null,
          standart: null,
          os: null,
          unitSize: null,
          height: null,
          width: null,
          length: null,
          unitWeight: null,
          productWeight: null,
        },
        priceTab: {
          saleRent: 1,
          dayMonthType: null,
          numberOfMD: null,
          firstPc: null,
          secondPc: null,
          thirdPc: null,
          fourthPc: null,
          fifthPc: null,
          priceList: [],
        },
        options: {
          selectedColor: null,
          isAddColor: false,
          colorName: null,
          colorCode: null,
          colorVariantStock: null,
          isDefaultColor: false,
          colorsList: [],
        },
        productImage: {
          imagesList: [],
          colorCode: null,
          colorFromList: null,
          selectedImage: null,
        },
        discount: {
          selectedDiscounts: []
        }
      }
    },
    spliceProductImage(state, index) {
      state.equipmentData.productImage.imagesList.splice(index, 1);
    },
    resetEquipmentDataPriceForm(state) {
      state.equipmentData.priceTab.dayMonthType = null;
      state.equipmentData.priceTab.numberOfMD = null;
      state.equipmentData.priceTab.firstPc = null;
      state.equipmentData.priceTab.secondPc = null;
      state.equipmentData.priceTab.thirdPc = null;
      state.equipmentData.priceTab.fourthPc = null;
      state.equipmentData.priceTab.fifthPc = null;
    },

    /**
     * Toggle open modal
     * **/

    toggleOpenModal(state, value) {
      state.modal.isOpen = value;
    },
    showWarningModal(state, value) {
      state.modal.showWarningModal = value;
    },
    selectEquipments(state, value) {
      state.equipmentModel.equipmentId = value;
    },
    getEquipments(state, data) {
      state.dataTable = data.results;
      state.currentPage = data.page;
      state.totalPages = data.totalPages;
      state.dataCount = data.totalResults;
    },

    filterEquipments(state) {
      const filterObject = {};
      if (state.filterModel.search) {
        filterObject.search = state.filterModel.search;
      }
      if (state.filterModel.price.length) {
        if (state.filterModel.price[1] > 0) {
          filterObject.priceFrom = state.filterModel.price[0];
          filterObject.priceTo = state.filterModel.price[1];
        }
      }
      if (state.filterModel.type) {
        filterObject.type = state.filterModel.type;
      }

      state.filterData = filterObject;
    },

    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault;
      }
    },

    setDefaultSettingsForBack(state, data) {
      if (data.equipmentSettings) {
        state.isDefault = data.equipmentSettings.isDefault;
        state.defaultSettings = data.equipmentSettings.equipmentTable;
      }
    },

    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filter.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filter?.sortBy) { 
          state.filter.sortBy = "_id:desc"; 
        }
      }
    },

    setColorListItemKey(state, { colorCode, key, value }) {
      state.equipmentData.options.colorsList.map(item => {
        if (item.colorCode === colorCode) {
          item[key] = value
        }
      })
    },

    addNewColor(state, data) {
      let duplicatedColors = 0;

      if (state.equipmentData.options.colorsList.length) {
        state.equipmentData.options.colorsList.map(item => {
          if (item.colorCode === data.colorCode) {
            duplicatedColors++
          }
        })

        if (!duplicatedColors) { // if added color not duplicated than push
          state.equipmentData.options.colorsList.push({ ...data })
        }

      } else {
        state.equipmentData.options.colorsList.push({ ...data })
      }
    },

    spliceColorFromList(state, index) {
      if (state.equipmentData.options.colorsList.length) {
        state.equipmentData.options.colorsList.splice(index, 1)
      }
    },

    nextTab(state) {
      if (state.activeTab < state.editTabs.length) {
        state.activeTab = ++state.activeTab;
      }
    },

    resetState(state) {
      for (let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]));
      }
    },
  },

  actions: {
    createEquipment({ commit, state, dispatch }) {
      // state.discountData.provider = clientId
      let body = {
        name: [{ lang: "en", name: state.equipmentData.name }],
        type: state.equipmentData.type,
        sku: state.equipmentData.sku,
        enableForSale: state.equipmentData.enableForSale,
        isService:  state.equipmentData.isService,
        prices: state.equipmentData.priceTab.priceList.length ? [...state.equipmentData.priceTab.priceList] : [],
      };
      // price: state.equipmentData.price,
      
      // check if intormation keys has value then add in body
      const information = {}
      for (const key in state.equipmentData.information) {
        if (state.equipmentData.information[key] !== null) {
          information[key] = state.equipmentData.information[key]
        }
      }

      body.information = { ...information }

      if (state.equipmentData.description) {
        body.description = state.equipmentData.description
      }
      
      return RequestService.create(`equipments`, body)
        .then(async (response) => {
          commit("setData", { selectedEquipmentId: response.data.id });
          await dispatch("getEquipmentsList");
          return response.data;
        })
        .catch((e) => console.log(e));
    },

    updateEquipment({ commit, state, dispatch }, data) {
      return RequestService.update(`equipments/edit/${state.selectedEquipmentId}`, { ...data })
        .then(async (data) => {
          await dispatch("getEquipmentsList");
          return Promise.resolve(data.data);
        })
        .catch((e) => console.log(e));
    },

    getEquipmentsList({ commit, state, rootState }, filter = null) {
      let options = {};

      if (state.filterData) {
        options = {
          ...options,
          ...state.filter,
          ...state.filterData,
          ...filter,
        };
      } else {
        options = { ...options, ...state.filter, ...filter };
      }
      
      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      let query = Object.keys(options)
        .map((key) => key + "=" + options[key])
        .join("&");

      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = 'blob';
      } else {
        commit('setData', { isTableLoading: true });
      }

      return RequestService.read(`equipments?${query}`, readOptions)
        .then((r) => {
          if (!isExcelRequest) {
            if (r.data.results && r.data.results.length) {
              commit("setData", { equipmentsResult: true });
            } else {
              commit("setData", { equipmentsResult: false });
            }
            commit("setData", {
              dataTable: r.data.results,
              currentPage: r.data.page,
              totalPages: r.data.totalPages,
              dataCount: r.data.totalResults,
              isTableLoading: false
            });
          } else {
            FileDownload(r.data, "equipment.xlsx"); // TODO actual file name from response.headers
          }
        })
        .catch((e) => console.log(e))
        .finally(() => commit('setData', { isTableLoading: false }))
    },

    getEquipmentData({ commit }, id) {
      return RequestService.read(`equipments/edit/${id}`)
        .then((response) => {
          commit("setEditingEquipment", response.data);
          return Promise.resolve(response.data);
        })
        .catch((e) => console.log(e));
    },

    getEquipmentsTypes({ commit }) {
      return RequestService.read(`equipments/equipmentTypes`)
        .then((response) => {
          const types = [];
          response.data.map((item) => {
            types.push({
              value: item.id,
              name: item.name.filter(item => item.lang === 'en')[0].name
            });
          });

          commit("setData", { types });
        })
        .catch((e) => console.log(e));
    },

    deleteEquipments({ commit, dispatch }, data) {
      return RequestService.update(`equipments/delete`, { ...data })
        .then(async (response) => {
          commit("showWarningModal", false);
          await dispatch("getEquipmentsList");
          return Promise.resolve(response.data);
        })
        .catch((e) => console.log(e));
    },

    getEquipmentsSettings({ commit, state }) {
      return RequestService.read(`users/settings`).then(
        (data) => {
          const limitData = data?.data?.equipmentSettings?.limit
            ? data.data.equipmentSettings.limit
            : state.filter.limit;
          commit("setDefaultSettingsForBack", data.data);
          commit("setLimit", limitData);
          return Promise.resolve(data.data);
        },
        (error) => {
          console.log("error ", error);
        }
      );
    },

    updateEquipmentsSettings({ state }, data) {
      return RequestService.update(`users/settings`, {
        equipmentSettings: {
          equipmentTable: data,
          isDefault: state.isDefault,
          limit: state.filter.limit,
        },
      })
        .then((r) => {
          return Promise.resolve(r.data);
        })
        .catch((e) => console.log(e));
    },

    enableDisableAction({ commit }, body = {}) {
      return RequestService.update(`equipments/enableDisable`, { ...body })
        .then((data) => {
          return Promise.resolve(data.data);
        })
        .catch((e) => console.log(e))
    },
  },
};
