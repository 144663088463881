<template>
  <div class="P-page-loader" :class="{ 'opacity-background': opacityBackground && !$vuetify.theme.dark }">
    <div class="lds-roller ma-auto" 
      :class="{ 'left-19': !isMinimize }">
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    name:'PageLoader',
    props: {
      opacityBackground: {
        type: Boolean,
        default: () => true
      }
    },
    computed: {
      ...mapState({
        isMinimize: state => state.sidebar.isMinimize,
        isFullHide: state => state.sidebar.isFullHide,
      })
    }
  }
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
  .P-page-loader{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
  }
  .opacity-background {
    background-color: rgba(255,255,255,0.4);
  }
  .lds-roller {
    display: inline-block;
    position: fixed;
    width: 80px;
    height: 80px;
    top: 22%;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.4s;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $primary-color;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .left-19 {
    left: 19.5%;
  }
</style>
