import Review from "@/views/review/Reviews";

let redirectURL
if (localStorage.getItem("redirect") !== null && localStorage.getItem("user") !== null) {
  redirectURL = localStorage.getItem("redirect")
}

export const reviewRout = {
  path: "/",
  redirect:  redirectURL ? redirectURL : "/review",
  name: "Review Provider",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "review",
      name: "Review",
      component: Review,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    }
  ]
};
