<template>
  <div class="P-client-payments">
  <div>
    <p>autopayments</p>
  <table border="1">
    <thead>
      <tr>
        <th>Id</th>
        <th>Login</th>
        <th>Name</th>
        <th>Retry Count</th>
        <th>Next Retry Hours</th>
        <th>Left To Expire Hours</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in recurrings" :key="item.id">
        <td><a target="_blank" :href="`/search/client/${item.id}`">view client</a></td>
        <td>{{ item.login }}</td>
        <td>{{ `${item.name?.firstname} ${item.name?.lastname}` }}</td>
        <td>{{ item.recurringAttempts }}</td>
        <td>{{ item.nextRetryHour === 0 ? 'now' : item.nextRetryHour.toFixed(2) }}</td>
        <td>{{ item.leftHours.toFixed(2) }}</td>
      </tr>
    </tbody>
  </table>
  </div><div>
    <p>telegram bots (running {{ telegramBots?.length }})</p>
  <table border="1">
    <thead>
      <tr>
        <th>token</th>
        <th>provider</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in telegramBots" :key="item.id">
        <td>{{ item.token }}</td>
        <td>{{ item.provider }}</td>
      </tr>
    </tbody>
  </table>
  </div>

  <div>
    <span>financial flow</span>

            <div class="clientInvoiceContentItem">
              <OttButtonLoader
                :is-loading="isLoading"
                :button-text="rightLabel"
                :className="`primary-sm-btn ${!showSaveBtn ? 'd-none' : ''}`"
                @onClick="recalculateFunctionHandle()"
              />
            </div>
    <div v-for="item in clients" :key="item.id" class="clientContainer">
    <div>
      <div class="clientListContainer">
        <span style="font-size: large;">{{ item.clientName }}</span>
          <div class="clientInoivceContainer" scroll>
          <ul>
            <li class="clientInvoiceContent" v-for="invoiceItem in item.invoices" :key="`clientInvoice${invoiceItem.invoiceId}`" >
              <div class="clientInvoiceContentItem"><ott-checkbox v-model="invoiceItem.newPayload.valid" label="Disable" /></div>
              <div class="clientInvoiceContentItem"><a @click="openInvoice(invoiceItem.invoiceId)"><span >{{ invoiceItem.invoiceNumber }}</span></a></div>
              <div class="clientInvoiceContentItem"><span :class="{ green: invoiceItem.newPayload?.toProviderPrice && invoiceItem.newPayload?.toProviderPrice > 0,
              red: invoiceItem.newPayload?.toProviderPrice && invoiceItem.newPayload?.toProviderPrice < 0}">{{ typeof invoiceItem.newPayload?.toProviderPrice !== undefined ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(invoiceItem.newPayload?.toProviderPrice) : null }}</span></div>
<div class="clientInvoiceContentItem">
  <span style="color: green">BUYS</span>:
              {{ invoiceItem.newPayload?.buys ? invoiceItem.newPayload.buys.map(r=>`${r.packageName}`).toString() : '' }}
            </div>
<!-- <div class="clientInvoiceContentItem">
              {{ invoiceItem.newPayload?.buys ? invoiceItem.newPayload.buys.map(r=>`(${r.oldStartDate} - ${r.oldEndDate})`).toString() : '' }}
            </div> -->
            <div class="clientInvoiceContentItem">
              {{ invoiceItem.newPayload?.buys ? invoiceItem.newPayload.buys.map(r=>`(${r.startDate} - ${r.expireNew})`).toString() : '' }}
            </div>
            <div class="clientInvoiceContentItem">
              {{ invoiceItem.newPayload?.buys ? invoiceItem.newPayload.buys.map(r=>`(${r.newStartDate} - ${r.newEndDate})`).toString() : '' }}
            </div>
            <!-- <div class="clientInvoiceContentItem">
              {{ invoiceItem.newPayload?.interval }}
            </div> -->
            <div class="clientInvoiceContentItem">
  <span  style="color:red">REFUNDS:</span>
              {{ invoiceItem.newPayload?.refunds ? invoiceItem.newPayload.refunds.map(r=>`${r.packageName}`).toString() : '' }}
            </div>
            <div class="clientInvoiceContentItem">
              {{ invoiceItem.newPayload?.refunds ? invoiceItem.newPayload.refunds.map(r=>`(${r.startDate} - ${r.endDate})`).toString() : '' }}
            </div>
            <div class="clientInvoiceContentItem">
              {{ invoiceItem.newPayload?.refunds ? invoiceItem.newPayload.refunds.map(r=>`(${r.newStartDate} - ${r.newEndDate})`).toString() : '' }}
            </div>
            <div class="clientInvoiceContentItem" v-if="!invoiceItem.newPayload?.refunds?.length">
                N/A
            </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      chart here
      <GanttChart :invoiceData="constructClientInvoicesChartData(item)"  :myChartStart="constructClientInvoicesStartDate(item)"  :myChartEnd="constructClientInvoicesEndDate(item)"/>
    </div>
    </div>
  </div>
  </div>
</template>

<script>
  import SyncStatisticModel from "./SyncStatisticModel";
import DatePicker from "v-calendar/src/components/DatePicker";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";

import OttButton from "@/components/vuetifyComponents/OttButton";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

  import GanttChart from './GanttChart.vue';
  import { mapState, mapMutations, mapActions } from "vuex";
  export default {
    data() {
      return {
        invoiceData: [{invoiceNumber: 1, startDate: new Date(), endDate: new Date().setDate(new Date().getDate)}],
        model: new SyncStatisticModel(),
        statisticData: {},
        isLoading: false,
        showSaveBtn: true,
        recurrings: [],
        telegramBots:[],
        rightLabel: 'recalculate',
        leftLabel: 'recalculate',
        clients: [],
      }
    },
    components: {
      OttCheckbox,OttButtonLoader, OttButton,GanttChart
    },
    mounted() {
      const self = this;
      this.$hubEventBus.$on("service-info-subscription", this.handleResponse);
      this.getStatistics();
      this.getTelegrams();
      this.getTransactions();
    },
    mixins: [],
    methods: {
      openInvoice(invoiceId) {
        window.open(`${process.env.VUE_APP_API_URL}invoices/view/${invoiceId}?type=1`, '_blank');
      },
    ...mapActions({
      getServiceStatisticInfo: "statisticModule/getServiceStatisticInfo",
      getTransactionInfo: "statisticModule/getTransactionInfo",
      getServiceTelegramInfo: "statisticModule/getServiceTelegramInfo",
      recalculateInvoice: "statisticModule/recalculateInvoice",
    }),
    constructClientInvoicesStartDate(item) {
      return item.invoices[0].invoiceDate;
    },
    constructClientInvoicesEndDate(item) {
      return item.invoices[item.invoice.length - 1].invoiceDate;
    },
    constructClientInvoicesEndDate() {
      
    },
    constructClientInvoicesChartData(item) {
      const list = [];
      for(const invoice of item.invoices) {
        console.log(invoice)
        const pushObj = {
        label: invoice.invoiceNumber,
        bars: [
            ]
        };
        invoice.newPayload.buys.forEach((buy) => {
          if (buy.newStartDate && buy.newEndDate){
            pushObj.bars.push({
                myStart: buy.newStartDate,
                myEnd: buy.newEndDate,
                ganttBarConfig: {
                  hasHandles: true,
                  label: buy.packageName,
                  background: "red",
                  backgroundColor: "green",
                }
              });
          }
        });
        invoice.newPayload.refunds.forEach((refund) => {
          if (refund.newStartDate && refund.newEndDate){
            pushObj.bars.push({
                myStart: refund.newStartDate,
                myEnd: refund.newEndDate,
                ganttBarConfig: {
                  label: refund.packageName,
                  background: "red",
                  backgroundColor: "red",
                }
              });
          }
        });
        list.push(pushObj);
      }
return list;
    },
    recalculateFunctionHandle() {
        this.recalculateInvoice().then((data) => {
          console.log(data)
        }, (err) => {
          console.log(err)
        });
    },
    rightClickFunc(data){
console.log('right click', data);
    },
      handleResponse(data) {
        this.statisticData = data;
      },
      getStatistics() {
        let self = this;
        this.getServiceStatisticInfo().then(data => {
          console.log(`statistic data`, data);
          self.recurrings = data.data.recurrings;
        })
      },
      getTelegrams() {
        let self = this;
        this.getServiceTelegramInfo().then(data => {
          console.log(`telegram data`, data);
          self.telegramBots = data.data.bots;
        })
      },
      getTransactions() {
        let self = this;
        this.getTransactionInfo().then(data => {
          console.log(`transaction data`, data);
          self.clients = data.data.clients;
        })
      }
    },
    beforeDestroy() {
      this.$hubEventBus.$off("service-info-subscription", this.handleResponse);
    }
  }
</script>

<style lang="scss">
@import "src/assets/scss/variables";

.P-user-page-image {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
  fill: #0a3c68;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-no-photos {
    color: $neutral-color;
    display: block;
    font-size: 40px;
  }
}
</style>

<style lang="scss" scoped>

  @import "src/assets/scss/variables";

  .P-users-columns {
    padding-left: 10px;
  }

  .P-filter-open {
    .mdi-menu-right {
      transform : rotate(180deg);
    }
  }

  .P-client-payments {
    position : relative;
  }

  .approve-rej-icon-cont {
    font-size : 24px;

    .mdi {
      padding : 0 7px;
      display : inline-block;
    }

    .mdi-cancel,
    .mdi-check-circle-outline {
      border-radius : $border-radius;
    }
  }

  .approve-rej-icon-cont::v-deep {
    .v-tooltip__content {
      border-radius : 6px;
      padding       : 7px 9px;
      font-size     : $txt12;
    }
  }

  .select-all-menu-cont {
    .P-icon {
      font-size : 26px !important;
    }

    ::v-deep {
      .v-menu__content {
        max-width : 190px !important;
      }
    }
  }

  .select-all-menu-cont button .mdi-dots-vertical,
  .settings-btn button .mdi-settings-outline {
    height        : 38px;
    font-size     : 24px;
    width         : 38px;
    border-radius : 4px;
  }

  .theme--light {
    .active-filter-count {
      background-color : #e5f5f6 !important;
      color            : $primary-color !important;
    }

    .action-cont-first-cont::v-deep {
      .v-icon {
        color : $neutral-color;
      }

      .mdi-minus-box {
        color : $primary-color !important;
      }
    }

    .select-all-menu-cont button[aria-expanded="true"] .mdi-dots-vertical,
    .settings-btn button[aria-expanded="true"] .mdi-settings-outline {
      background : $neutral-color15;
      color      : $secondary-color !important;
    }

    .select-all-menu-cont button .mdi-dots-vertical:hover,
    .settings-btn button .mdi-settings-outline:hover {
      background : $neutral-color15;
      color      : $secondary-color !important;
    }

    .icon-active {
      background    : $neutral-color;
      color         : $secondary-color !important;
      border-radius : $border-radius;
    }
  }

  .theme--dark {
    .action-cont-first-cont::v-deep {
      .v-icon {
        color : $neutral-colorDM !important;

        &.v-icon:after {
          opacity : 0;
        }
      }

      .mdi-minus-box {
        color : $primary-colorDM !important;
      }

      .primary--text {
        color : $primary-colorDM !important;
      }
    }

    .action-cont-first {
      border-right : 1.5px solid $borderDM;
    }
  }

  .G-table-actions {
    .v-menu__content {
      max-height : inherit !important;
    }
  }

  .v-menu__content.P-actions-list {
    max-height : inherit !important;
  }

  .G-excel-btn {
    border-left: unset;
    padding-left: unset;
  }

  .action-cont-first {
    display       : flex;
    border-right  : 1.5px solid $neutral-color;
    height        : 36px;
    align-items   : center;

    .v-input--selection-controls {
      margin-top : 23px;
    }
  }

  .P-icon {
    position   : relative;
    z-index    : 5;
    transition : $transition;
  }

  .P-settings {
    .P-icon {
      font-size : 22px;
    }
  }

  .P-bills-switch {
    margin       : 0 15px;
    padding      : 0 15px;
    border-left  : 1.5px solid $neutral-color;
    border-right : 1.5px solid $neutral-color;


    &::v-deep {
      .P-text {
        white-space : nowrap;
      }

      .v-input {
        width       : max-content !important;
        margin-top  : 0;
        padding: 10px 0;
      }
    }
  }
  .P-radio{
    margin-top: 0;
    padding: 10px 0;
  }
  .P-radio-box + .P-radio-box{
    margin-left: 15px;
  }

  .clientInvoiceContent {
    display: flex;
    margin: 10px;
    flex-wrap: wrap;
  }
.green {
  display: inline-flex;
  align-items: center;
  height: 23px;
  background: $danger-color-light 0 0 no-repeat padding-box;
  border-radius: 12px;
  color: white;
  justify-content: center;
  width: auto;
  padding: 0 10px;
  span {
    height: 16px;
    text-align: left;
    font: normal normal 600 12px Segoe UI;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }
}
  
.red {
  display: inline-flex;
  align-items: center;
  height: 23px;
  background: $danger-color-light 0 0 no-repeat padding-box;
  border-radius: 12px;
  color: white;
  justify-content: center;
  width: auto;
  padding: 0 10px;
  span {
    height: 16px;
    text-align: left;
    font: normal normal 600 12px Segoe UI;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }
}
  
  .gray {
    display: inline-flex;
    align-items: center;
    height: 23px;
    background: $danger-color-light 0 0 no-repeat padding-box;
    border-radius: 12px;
    color: white;
    justify-content: center;
    width: auto;
    padding: 0 10px;
    span {
      height: 16px;
      text-align: left;
      font: normal normal 600 12px Segoe UI;
      letter-spacing: 0;
      color: #ffffff;
      opacity: 1;
    }
  }

  .clientInvoiceContentItem {
    margin: 10px;
  }
  .clientListContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .clientContainer {
    border: 1px solid red;
    margin: 5px;
  }
  
  .clientInoivceContainer {
    max-height: 200px;
    overflow-y: auto;
  }
</style>
