import RequestService from "@/services/request.service";

const InitialState = {
  formData:{
    phone: null,
    email: null,
    address: null,
    comment: null
  },
  toSaveData:{},
};

export const contactInfoForClientsApps = {
  namespaced: true,
  actions: {
    updateInfoForClients({ commit, state, rootState, dispatch }, providerId) {
      return RequestService.update(
        `ottProviders/infos/edit/${providerId}`,
        state.toSaveData
      ).then(
        async data => {
          commit("fetchData", data);
          // await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    getInfoForClients({commit,state}, providerId){
      return RequestService.read(
        `ottProviders/infos/edit/${providerId}`
      ).then(
        data => {
          commit('fetchData', data.data)
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for(const key in data) {
        state[key] = data[key]
      }
    },

    saveData(state){
      state.toSaveData = {}
        state.toSaveData.phone = state.formData.phone

        state.toSaveData.address = state.formData.address

        state.toSaveData.email = state.formData.email
        
        state.toSaveData.comment = state.formData.comment

    },
    fetchData(state, data){
      if(data.phone){
        state.formData.phone = data.phone;
      }
      if(data.address){
        state.formData.address = data.address;
      }
      if(data.email){
        state.formData.email = data.email;
      }
      if(data.comment){
        state.formData.comment = data.comment;
      }
    },
    resetData(state) {
      state.formData.phone = null;
      state.formData.address = null;
      state.formData.email = null;
      state.formData.comment = null;
    },
    setEmail(state,value){
      state.formData.email = value
    },
    setPhone(state,value){
      state.formData.phone = value
    },
    setAddress(state,value){
      state.formData.address = value
    },
    setComment(state,value){
      state.formData.comment = value
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
