<template>
  <div class="P-pagination-section G-flex G-align-center G-justify-end">
    <div class="P-pagination-limit G-flex G-align-center">
      <p class="secondary--text P-limit-text">Rows per page:</p>
      <div class="P-select-limit">
        <OttSelect
          v-model="selectedValue"
          :items="limitList"
          :top="true"
          selected-text="value"
          @emitFunc="selectChange"
        />
      </div>
    </div>
    <div class="P-pagination-count G-flex G-align-center">
      <p class="secondary--text">
        <!-- {{(currentPage-1)*selectedValue + 1}} - {{pageLimit===currentPage? dataCount: (currentPage*selectedValue)}} -->
        {{ availableDataCount }}
      </p>
      <span class="secondary--text">of</span>
      <p class="secondary--text">{{dataCount}}</p>
    </div>
    <div class="P-pagination-arrow G-flex-center">
      <span @click="updatePage(false)" class="P-arrow-style mdi mdi-chevron-left "></span>
      <span class="secondary--text">{{ currentPage }}</span>
      <span @click="updatePage(true)" class="P-arrow-style mdi mdi-chevron-right "></span>
    </div>
  </div>
</template>
<script>
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import { eventBus } from "@/main";

export default {
  components: { OttSelect },
  data() {
    return {
      selectedValue:null
    };
  },
  props: {
    limitList: {
      type: Array,
      required: false,
      default: () => {
        return [
          { name: 5, value: 5 },
          { name: 10, value: 10 },
          { name: 15, value: 15 },
          { name: 20, value: 20 },
          { name: 50, value: 50 },
          { name: 100, value: 100 },
          { name: 200, value: 200 },
        ];
      }
    },
    value:{
      type:Number,
      default:10,
      required:true
    },
    availableDataCount:{
      type:Number,
      default:0,
      required:true
    },
    currentPage:{
      type:Number,
      default:1,
      required:true
    },
    dataCount:{
      type:Number,
      required:true,
      default:0
    },
    pageLimit:{
      type:Number,
      required:true,
      default:0
    },

  },
  watch: {
    value(data) {
      this.selectedValue = data;
    }
  },
  mounted() {
    this.selectedValue = this.value
  },
  methods: {
    selectChange() {
      this.$emit("input", this.selectedValue);
      this.$emit("update");
      eventBus.$emit("rowsPerPageChanged");
    },
    updatePage(isIncrement){
      let changePage = this.currentPage
      if(isIncrement){
        if(changePage<this.pageLimit){
          ++changePage
        }
      }else{
        if(changePage>1){
          --changePage
        }
      }
      this.$emit("updatePage",changePage);
    }
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";

.P-limit-text {
  font-size: $txt12;
  margin-bottom: 5px;
  margin-right: 15px;
  text-transform: capitalize;
}

.P-pagination-limit::v-deep {
  .v-text-field__details {
    display: none;
  }
  .v-label, legend{
    display: none;
  }
  fieldset{
    top: 0;
    height: 38px;
  }
}

.P-pagination-section {
  margin-top: 24px;
}

.P-select-limit {
  width: 95px;
}

.P-pagination-count {
  margin: 0 20px;

  p {
    margin-bottom: 0;
    font-size: $txt14;
  }

  span {
    display: block;
    margin: 0 10px;
    font-size: $txt14;

  }
}

.P-arrow-style {
  font-size: $txt18;
  cursor: pointer;
  color: $neutral-color;

  & + .P-arrow-style {
    margin-left: 25px;
  }
}

.P-pagination-arrow {
  margin-left: 20px;
}
</style>
