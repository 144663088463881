import RequestService from "@/services/request.service";

const InitialState = {
    reset: false,
    isSave: true,
    isContactForm: true,
    isEditEmail: false,
    isEditPhone: false,
    isAddEmail: false,
    isAddPhone: false,
    editableEmailDataIndex: null,
    editablePhoneDataIndex: null,
    emailFormValidations: {
        emailValue: false,
        smtpServer: false,
        smtpPort: false,
        username: false,
        useForSentPassword: false
    },
    phoneFormValidations: {
        phone: false
    },
    emailForm: {
        id: null,
        emailValue: "",
        inUse: false,
        inMain: false,
        isInfo: false,
        isSupport: false,
        forInvoice: false,
        value: "",
        useForSent: {
            isForSent: false,
            smtpServer: "",
            smtpPort: null,
            username: "",
            useForSentPassword: "",
            useSSL: false
        }
    },
    phoneForm: {
        id: null,
        phone: null,
        value: "",
        inUse: false,
        inMain: false,
        isMobile: false,
        isSupport: false,
        forInvoice: false
    },
    emails: [],
    phones: [],
    validateEmail: false,
    isError: false,
    isCheckError: false,
    errorMessage: "",
    validatePhone: false,
    isErrorPhone: false,
    isCheckErrorPhone: false,
    errorMessagePhone: "",
    cloneEmail: null,
    clonePhone: null,
    isLoading: false,
};

export const contactModule = {
    namespaced: true,
    state: JSON.parse(JSON.stringify(InitialState)),
    actions: {
        manageEmail({ commit, rootState, dispatch }, data) {
            return RequestService.update(
                `ottProviders/emails/edit/${data.id}`,
                data.data
            ).then(
                async data => {
                    await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, { root: true })
                    return Promise.resolve(data.data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        addEmail({ commit, rootState, dispatch }, data) {
            return RequestService.create(
                `ottProviders/emails`,
                data
            ).then(
                async data => {
                    await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, { root: true })
                    return Promise.resolve(data.data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        deleteEmail({ rootState, dispatch }, emailId) {
            return RequestService.delete(
                `ottProviders/emails/edit/${emailId}`,
            ).then(
                async data => {
                    await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, { root: true })
                    return Promise.resolve(data.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        deletePhone({ rootState, dispatch }, phoneId) {
            return RequestService.delete(
                `ottProviders/phones/edit/${phoneId}`,
            ).catch(() => {
                return Promise.reject(error);
            });
        },
        addPhone({ commit, rootState, dispatch }, data) {
            return RequestService.create(
                `ottProviders/phones`,
                data
            ).then(
                async data => {
                    await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, { root: true })
                    return Promise.resolve(data.data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
        managePhone({ commit, rootState, dispatch }, data) {
            return RequestService.update(
                `ottProviders/phones/edit/${data.id}`,
                data.data
            ).then(
                async data => {
                    await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, { root: true })
                    return Promise.resolve(data.data);
                },
                error => {
                    return Promise.reject(error);
                }
            )
        },
    },
    mutations: {
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key]
            }
        },

        checkIsMainEmail(state, data) {
            if (data.inMain) {
                state.emails.map(item => {
                    return (item.inMain = item.emailValue === data.emailValue);
                });
            }
        },
        checkIsMainPhone(state, data) {
            if (data.inMain) {
                state.phones.map(item => {
                    return (item.inMain = item.phone === data.phone);
                });
            }
        },
        setError(state, data) {
            state.isError = data.value;
            state.errorMessage = data.message;
        },
        setCheckError(state, data) {
            state.isCheckError = data.value;
            state.errorMessage = data.message;
        },
        setErrorPhone(state, data) {
            state.isErrorPhone = data.value;
            state.errorMessagePhone = data.message;
        },
        setCheckErrorPhone(state, data) {
            state.isCheckErrorPhone = data.value;
            state.errorMessagePhone = data.message;
        },
        updateContactData(state, data) {
            state.emails = data.emails.map((item, index) => {
                return {
                    emailValue: item.address,
                    inUse: !item.inUse,
                    inMain: item.isMain,
                    isInfo: item.isInfo,
                    isSupport: item.isSupport,
                    forInvoice: item.forInvoice,
                    value: item.id,
                    id: item.id,
                    providerId: item.providerId,
                    useForSent: {
                        isForSent: item.forSend,
                        smtpServer: item.smtp && item.smtp.server ? item.smtp.server : "",
                        smtpPort: item.smtp && item.smtp.port ? item.smtp.port : "",
                        username: item.smtp && item.smtp.username ? item.smtp.username : "",
                        useForSentPassword:
                            item.smtp && item.smtp.password ? item.smtp.password : "",
                        useSSL: item.smtp && item.smtp.ssl ? item.smtp.ssl : false
                    }
                };
            });

            state.phones = data.phones.map(item => {
                return {
                    phone: item.number,
                    value: item.id,
                    inUse: !item.inUse,
                    inMain: item.isMain,
                    isMobile: item.isMobile,
                    isSupport: item.isSupport,
                    forInvoice: item.forInvoice,
                    id: item.id,
                    providerId: item.providerId,

                };
            });
        },

        resetContactForm(state, data) {
            state.emailForm = JSON.parse(JSON.stringify(InitialState.emailForm));
            state.phoneForm = JSON.parse(JSON.stringify(InitialState.phoneForm));
            state.emails = [];
            state.phones = [];
            state.isSave = true;
            state.isEditEmail = false;
            state.isEditPhone = false;
            state.isAddEmail = false;
            state.isAddPhone = false;
            state.reset = !state.reset;
            state.isError = false;
            state.isCheckError = false;
            state.validateEmail = false;
            state.validatePhone = false;
            state.isErrorPhone = false;
            state.isCheckErrorPhone = false;
            state.cloneEmail = null;
            state.editableEmailDataIndex = null;
            state.editablePhoneDataIndex = null;
            state.clonePhone = null;
            state.errorMessage = '';
            state.errorMessagePhone = '';

        },

        save(state, date) {
            state.isSave = !state.isSave;
            state.emails.push(JSON.parse(JSON.stringify(state.emailForm)));
            state.phones.push(JSON.parse(JSON.stringify(state.phoneForm)));
            state.emailForm = JSON.parse(JSON.stringify(InitialState.emailForm));
            state.phoneForm = JSON.parse(JSON.stringify(InitialState.phoneForm));
        },

        //------------------------ edit email

        openEditEmail(state, index) {
            state.isEditEmail = true;
            state.emailForm = JSON.parse(JSON.stringify(state.emails[index]));
            state.editableEmailDataIndex = index;
            state.cloneEmail = JSON.parse(JSON.stringify(state.emailForm));

        },

        saveEditEmailForm(state, date) {
            state.emails[state.editableEmailDataIndex] = JSON.parse(
                JSON.stringify(state.emailForm)
            );
            state.editableEmailDataIndex = null;
            state.isEditEmail = false;
            state.emailForm = JSON.parse(JSON.stringify(InitialState.emailForm));
            // state.phoneForm = JSON.parse(JSON.stringify(InitialState.phoneForm));
        },
        cancelEditEmailForm(state, data) {
            state.isEditEmail = false;
            state.isError = false;
            state.isCheckError = false;
            state.validateEmail = false;
            state.errorMessage = '';
            // state.emailForm = JSON.parse(JSON.stringify(InitialState.emailForm));
            // state.phoneForm = JSON.parse(JSON.stringify(InitialState.phoneForm));
            let emailForm = JSON.parse(JSON.stringify(state.cloneEmail));
            state.emails = state.emails.map((item, index) => {
                if (index === state.editableEmailDataIndex) {
                    item = emailForm
                }
                return item

            })
            state.cloneEmail = null
            state.emailForm = null
            state.emailForm = JSON.parse(JSON.stringify(InitialState.emailForm));

        },


        deleteEmail(state, data) {
            state.emails.splice(data.index, 1);
            if (data.data.inMain) {
                state.emails[0].inMain = true;
                state.emails[0].inUse = false;
            }

        },

        //------------------------ edit phone
        openEditPhone(state, index) {
            state.isAddPhone = false;
            state.isEditPhone = true;
            state.phoneForm = JSON.parse(JSON.stringify(state.phones[index]));
            state.editablePhoneDataIndex = index;
            state.clonePhone = JSON.parse(JSON.stringify(state.phoneForm))
        },

        saveEditPhoneForm(state, date) {
            state.phones[state.editablePhoneDataIndex] = JSON.parse(
                JSON.stringify(state.phoneForm)
            );
            state.editablePhoneDataIndex = null;
            state.isEditPhone = false;
            state.isEditEmail = false;

            // state.emailForm = JSON.parse(JSON.stringify(InitialState.emailForm));
            state.phoneForm = JSON.parse(JSON.stringify(InitialState.phoneForm));
        },
        cancelEditPhoneForm(state, data) {
            state.isEditPhone = false;
            state.validatePhone = false;
            state.isErrorPhone = false;
            state.isCheckErrorPhone = false;
            state.errorMessagePhone = "";
            let phoneForm = JSON.parse(JSON.stringify(state.clonePhone))
            state.phones = state.phones.map((item, index) => {
                if (index === state.editablePhoneDataIndex) {
                    item = phoneForm
                }
                return item
            })
            state.clonePhone = null
            state.clonePhone = null
            state.phoneForm = JSON.parse(JSON.stringify(InitialState.phoneForm));
        },

        deletePhone(state, data) {
            state.phones.splice(data.index, 1);
            if (data.data.inMain) {
                state.phones[0].inMain = true;
                state.phones[0].inUse = false;
            }
        },

        //------------------------ add email
        addEmail(state, date) {
            state.isAddEmail = true;
            state.isEditEmail = true;
            state.isLoading = false

        },
        addEmailCancel(state, data) {
            state.isAddEmail = false;
            state.isEditEmail = false;
            state.validateEmail = false;
            state.isError = false;
            state.isCheckError = false;
            state.errorMessage = "";
            state.emailForm = JSON.parse(JSON.stringify(InitialState.emailForm));
        },
        addEmailSave(state, data) {
            state.isAddEmail = false;
            state.isEditEmail = false;
            state.validateEmail = false;
            state.isError = false;
            state.isCheckError = false;
            state.errorMessage = "";
            state.emailForm.value = state.emailForm.emailValue;
            if (data) {
                state.emails.push(data)
            } else {
                state.emails.push(JSON.parse(JSON.stringify(state.emailForm)));

            }
            state.emailForm = JSON.parse(JSON.stringify(InitialState.emailForm));
        },

        //------------------------ add phone
        addPhone(state, date) {
            state.isAddPhone = true;
            state.isAddEmail = true;
            state.isEditPhone = true;
            state.isLoading = false
        },

        addPhoneCancel(state, data) {
            state.isAddPhone = false;
            state.isEditPhone = false;
            state.validatePhone = false;
            state.isErrorPhone = false;
            state.isCheckErrorPhone = false;
            state.clonePhone = null
            state.errorMessagePhone = "";
            state.phoneForm = JSON.parse(JSON.stringify(InitialState.phoneForm));
        },
        addPhoneSave(state, data) {
            state.isAddPhone = false;
            state.isEditPhone = false;
            state.isAddEmail = false;
            state.validatePhone = false;
            state.isErrorPhone = false;
            state.isCheckErrorPhone = false;
            state.errorMessagePhone = "";
            state.phoneForm.value = state.phoneForm.phone;
            if (data) {
                state.phones.push(data);
            } else {
                state.phones.push(JSON.parse(JSON.stringify(state.phoneForm)));
            }

            state.phoneForm = JSON.parse(JSON.stringify(InitialState.phoneForm));
            state.clonePhone = null

        },

        //  ---------------------email form
        updateEmailValue(state, date) {
            state.emailForm.emailValue = date;
        },
        updateInUse(state, date) {
            state.emailForm.inUse = date;
        },
        updateInMain(state, date) {
            state.emailForm.inMain = date;
        },
        updateIsInfo(state, date) {
            state.emailForm.isInfo = date;
        },
        updateIsSupport(state, date) {
            state.emailForm.isSupport = date;
        },
        updateForInvoice(state, date) {
            state.emailForm.forInvoice = date;
        },
        updateUseForSentIsForSent(state, date) {
            state.emailForm.useForSent.isForSent = date;
        },
        updateUseForSentSmtpServer(state, date) {
            state.emailForm.useForSent.smtpServer = date;
        },
        updateUseForSentSmtpPort(state, date) {
            state.emailForm.useForSent.smtpPort = date;
        },
        updateUseForSentUsername(state, date) {
            state.emailForm.useForSent.username = date;
        },
        updateUseForSentUseForSentPassword(state, date) {
            state.emailForm.useForSent.useForSentPassword = date;
        },
        updateUseForSentUseSSL(state, date) {
            state.emailForm.useForSent.useSSL = date;
        },
        isEditEmail(state, value) {
            state.isEditEmail = value
        },

        //  ---------------------phone form
        updatePhoneFormPhone(state, data) {
            state.phoneForm.phone = data;
        },
        updatePhoneFormInUse(state, data) {
            state.phoneForm.inUse = data;
        },
        updatePhoneFormInMain(state, data) {
            state.phoneForm.inMain = data;
        },
        updatePhoneFormIsInfo(state, data) {
            state.phoneForm.isMobile = data;
        },
        updatePhoneFormIsSupport(state, data) {
            state.phoneForm.isSupport = data;
        },
        updatePhoneFormForInvoice(state, data) {
            state.phoneForm.forInvoice = data;
        },
        setCheckValidateEmail(state, value) {
            state.validateEmail = value;
        },
        setCheckValidatePhone(state, value) {
            state.validatePhone = value;
        },
        isEditPhone(state, value) {
            state.isEditPhone = value
        },
        resetState(state) {
            for (let key in state) {
                state[key] = JSON.parse(JSON.stringify(InitialState[key]))
            }
        },
    }
};
