import RequestService from "@/services/request.service";

const InitialState = {
  domain: '',
  dns: "",
  isLoading: false
};

export const uiAndAccessAdmin = {
  namespaced: true,
  actions: {
    updateUiAndAccess({ commit, state }, providerId) {
      return RequestService.update(
        `ottProviders/uis/edit/${providerId}`,
        {domain:state.domain}
      ).then(
        data => {
          // commit("fetchData", data.data);
          commit('setData', { isLoading: false })
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      ).finally(() => commit('setData', { isLoading: false }))
    },
    getUiAndAccess({ commit, state }, providerId) {
      return RequestService.read(
        `ottProviders/uis/edit/${providerId}`
      ).then(
        data => {
          commit("fetchData", data.data);
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    fetchData(state, data){
      if(data.domain){
        state.domain = data.domain
      }
      if(data.dns){
        state.dns = data.dns
      }
    },
    setDomain(state, data) {
      state.domain = data;
    },
    setDncName(state, data) {
      state.dns = data;
    },
    resetData(state) {
      state.domain = '';
      state.dns = "";
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
