import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

// Containers
const MainContainer = () => import("@/views/MainContainer");
const testPic = () => import("../components/PictureCuter");

import { authRout } from "./auth";
import { reviewRout } from "./reviewProvider";
import { errorRout } from "./error";
import { providersResellersRout } from "./providers";
import { usersRout} from "./users";
import { clientRout } from "./clients";
import { settingsRout } from "./settings";
import { discountsRout } from "./discounts";
import { equipmentsRout } from "./equipments";
import { packagesRout } from "./packages";
import { transactionsRout } from "./transactions";
import { forbiddenRout } from "./forbidden";
import { statisticRout } from "./statistic";
import { flussonicsRout } from "./flussonics";
import { generalSearchRout } from "./general-search"
import { fullCalendarRout } from "./full-calendar"
import { shippingsRout } from "./shippings"
import { signRout} from "./sign"
import { paymentRout } from "./payment"
import { chatRout } from "./chat"
import { helpRout } from "./help"

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  scrollBehavior: () => ({y: 0}),
  routes: [
    {
      path: "/",
      redirect: "/review",
      name: "Home",
      component: MainContainer,
      children: [reviewRout, providersResellersRout, usersRout, clientRout, settingsRout, discountsRout, equipmentsRout, packagesRout, transactionsRout, forbiddenRout, statisticRout, flussonicsRout, generalSearchRout, fullCalendarRout, shippingsRout, helpRout, chatRout]
    },
    signRout,
    authRout,
    errorRout,
    paymentRout,
    
    // {
    //   path: "/test",
    //   name: "test",
    //   component: testPic
    // }
  ]
});

router.beforeEach((to, from, next) => {
  let needLogin = false; // need login on every page
  if (to.meta.auth) needLogin = to.meta.auth;

  let pageFound = to.matched.length > 0;
  let loggedIn = store.state.auth.status.loggedIn;

  let user = !!localStorage.getItem("user");

  store.commit('setRoute', to)
  
  if (!pageFound) {
    next({path: "/error/nor-found"});
  } else if (needLogin && !loggedIn) {
    next({path: "/auth/sign-in"});
  } else {
    next();
  }
});

export default router;
