<template>
  <div class="ds-calendar-event-menu">
    <v-dialog
      max-width="1000"
      :content-class="contentClass"
      :disabled="!hasPopover"
      v-model="menu"
      v-bind="popoverProps"
    >
      <template v-slot:activator="{}">
        <div
          class="ds-calendar-event"
            v-tooltip="{
              content: eventTooltip,
              placement: 'auto',
              popperClass: 'tooltip-content',
              triggers: ['click', 'touch'],
              autoHide: true,
              shown: menu ? false : null,
              distance: 5,
              html: true
            }"
          :style="style"
          @dblclick="editCheck($event)"
          @click="setSameWidthMonthEvents"
        >
        <!-- @mouseenter="mouseEnterEvent"
        @mouseleave="mouseLeaveEvent"
        @mousedown="mouseDownEvent"
        @mouseup="mouseUpEvent" -->
          <span v-if="showName">
            <slot name="eventTimeTitle" v-bind="{ calendarEvent, details }">
              <b class="ds-ev-title">{{ details.title }}</b>
              <span class="ds-ev-description">{{
                details.description
              }}</span>
            </slot>
          </span>
          <span v-else>
            <slot
              name="eventTimeEmpty"
              v-bind="{ calendarEvent, details }"
            ></slot>
          </span>
        </div>
      </template>

      <slot
        name="eventPopover"
        v-bind="{ calendarEvent, calendar, details, close, menu }"
      ></slot>
    </v-dialog>
  </div>
</template>

<script>
import { CalendarEvent, Calendar, Day, Functions as fn } from "dayspan";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  name: "dsCalendarEvent",

  props: {
    calendarEvent: {
      required: true,
      type: CalendarEvent,
    },

    calendar: {
      required: true,
      type: Calendar,
    },

    index: {
      type: Number,
      default() {
        return this.$dsDefaults().index;
      },
    },

    popoverProps: {
      validate(x) {
        return this.$dsValidate(x, "popoverProps");
      },
      default() {
        return this.$dsDefaults().popoverProps;
      },
    },

    isPlaceholderWithDay: {
      type: Day,
    },
  },

  mixins: [ConvertTimeZoneMixin, DateFormatMixin],

  computed: {
    eventTooltip() {
      return `
        <div 
          class="
            pa-2 P-event-table-cont
            ${
              this.calendarEvent?.event?.data?.eventData?.state === 'active' ? 'bg-white' :
              this.calendarEvent?.event?.data?.eventData?.state === 'process' ? 'bg-yellow' :
              this.calendarEvent?.event?.data?.eventData?.state === 'completed' ? 'bg-green' :
              this.calendarEvent?.event?.data?.eventData?.state === 'cancel' ? 'bg-red' : 'bg-white'
            }
          "
          style='border: 4px solid ${this.calendarEvent?.event?.data?.eventData?.user?.color ? this.calendarEvent?.event?.data?.eventData?.user?.color : '' }'
        >
          <table style='border-collape: collapse;' class='font-weight-black P-event-tooltip-table'>
            <tr><td valign='top'>Event By:</td> <td valign='top' class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.user?.firstname} ${this.calendarEvent?.event?.data?.eventData?.user?.lastname}</td></tr>
            
            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
            
            <tr><td valign='top'>Equipment Installer:</td> <td valign='top' class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.equipmentInstaller?.firstname} ${this.calendarEvent?.event?.data?.eventData?.equipmentInstaller?.lastname}</td></tr>
            
            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
            
            <tr>
              <td valign='top'>Status:</td> 
              <td class='ps-2'>${this.camelCaseToTitleCase(this.calendarEvent?.event?.data?.eventData?.state)}</td>
            </tr>
            
            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
            
            <tr>
              <td valign='top'>Date:</td>
              <td valign='top' class='ps-2'>
                <p>
                  ${this.$moment(this.calendarEvent?.event?.data?.eventData?.startDate, 'DD-MM-YYYY').format(this.ottDateFormat)} - 
                  ${this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate, 'DD-MM-YYYY').format(this.ottDateFormat)}
                </p> 
                <p>
                  ${this.$moment(this.calendarEvent?.event?.data?.eventData?.startDate, 'DD-MM-YYYY hh:mm a').format('hh:mm a')} - 
                  ${this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate, 'DD-MM-YYYY hh:mm a').format('hh:mm a')}
                </p> 
              </td>
            </tr>
            
            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
            
            <tr><td valign='top'>Title:</td> <td valign='top' class='ps-2'>${this.camelCaseToTitleCase(this.calendarEvent?.event?.data?.eventData?.title)}</td></tr>
            
            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
            
            <tr>
              <td valign='top'>Customer:</td>
              <td valign='top' class='ps-2 max-w-250'>
                ${ this.calendarEvent?.event?.data?.eventData?.client?.personalInfo?.firstname } 
                ${ this.calendarEvent?.event?.data?.eventData?.client?.personalInfo?.lastname }
                (${ this.calendarEvent?.event?.data?.eventData?.client?.provider ? this.calendarEvent?.event?.data?.eventData?.client?.provider?.name[0].name : 'No Provider Name' })
              </td>
            </tr>

            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>

            <tr>
              <td valign='top'>Customer P/hones:</td>
              <td valign='top' class='ps-2'>
                ${
                  this.calendarEvent?.event?.data?.eventData?.client?.phones.length 
                    ? this.calendarEvent?.event?.data?.eventData?.client?.phones.map(phone => `<a href="tel:${phone.phone}">${phone.phone}</a>`).join('<br />')
                    : '-'
                }
              </td>
            </tr>
            
            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
            
            <tr><td valign='top'>Address:</td> <td valign='top' class='ps-2'>${this.FormatClientAddress(this.calendarEvent?.event?.data?.eventData?.customerAddress)}</td></tr>
            
            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
            
            <tr>
              <td valign='top'>Location:</td> 
              <td valign='top' class='ps-2'>
                <table>
                  <tr>
                    <td>Name:</td>
                    <td class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.location?.locationName}</td>
                  </tr>
                  <tr>
                    <td>Login:</td>
                    <td class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.location?.login}</td>
                  </tr>
                  <tr>
                    <td>Password:</td>
                    <td class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.location?.password}</td>
                  </tr>
                  <tr>
                    <td>Rooms:</td>
                    <td class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.location?.roomsCount}</td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>

            <tr>
              <td valign='top'>Connections:</td>
              <td valign='top' class='ps-2'>
                ${
                  this.getName(this.calendarEvent?.event?.data?.eventData?.client?.info?.locations.filter(item => item.login === this.calendarEvent?.event?.data?.eventData?.location?.login)[0]?.packageSubscriptoins)
                }
              </td>
            </tr>
            
            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>

            <tr><td valign='top'>Payment Type:</td> <td valign='top' class='ps-2'>
              ${
                typeof this.calendarEvent?.event?.data?.eventData?.paymentType === 'string' 
                  ? this.calendarEvent?.event?.data?.eventData.paymentType.charAt(0).toUpperCase() + this.calendarEvent?.event?.data?.eventData.paymentType.slice(1)
                  : '-'
              } 
              ${
                typeof this.calendarEvent?.event?.data?.eventData?.paymentPrice === 'number'
                  ? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    }).format(this.calendarEvent.event.data.eventData.paymentPrice)
                  : ''
              }
            </td></tr>

            <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
            ${
              this.calendarEvent?.event?.data?.eventData?.comments.length 
                ? `
                  <tr>
                    <td valign='top'>Comments:</td>
                    <td valign='top' class='ps-2'>
                      <div class='max-w-250 P-comments-parent'>
                        ${
                          this.calendarEvent?.event?.data?.eventData?.comments.map(item => {
                            return `
                              <div class="P-event-comment">
                                <p class="P-event-comment__user-name">
                                  <span>${ item.userName }</span>
                                  <span>${ item.updatedAt }</span>
                                </p>
                                <p>${ item.comment }</p>

                                ${ 
                                  item?.isCancel 
                                    ? '<p class="canceled">Canceled</p>'
                                    : '' 
                                }
                                <hr class="my-2">
                              </div>
                            `
                          }).join('')
                        }
                      </div>
                    </td>
                  </tr>` 
                : ''
            }
          </table>
        </div> 
      `
    },

    style() {
      return this.isPlaceholderWithDay
        ? this.$dayspan.getStylePlaceholderFull(
            this.details,
            this.calendarEvent,
            this.index,
            this.isPlaceholderWithDay
          )
        : this.$dayspan.getStyleFull(
            this.details,
            this.calendarEvent,
            this.index
          );
    },

    contentClass() {
      return this.$dayspan.fullscreenPopovers ? "ds-fullscreen" : "";
    },

    sameDayEvents() {
      return this.calendarEvent.event.schedule
        .iterateSpans(this.calendarEvent.day, true)
        .list();
    },

    hasPrefix() {
      return !this.calendarEvent.fullDay && this.sameDayEvents.length > 0;
    },

    hasIcon() {
      return !!(this.$dayspan.supports.icon && this.details.icon);
    },

    getPrefix() {
      return this.$dayspan.getPrefix(this.calendarEvent, this.sameDayEvents);
    },

    showName() {
      return this.isPlaceholderWithDay
        ? this.isPlaceholderWithDay.sameDay(this.calendarEvent.start)
        : this.calendarEvent.starting ||
            (this.calendar &&
              !this.calendar.filled.contains(this.calendarEvent.time.start) &&
              this.calendar.filled.start.sameDay(this.calendarEvent.day));
    },

    hasPopover() {
      return !!this.$scopedSlots.eventPopover;
    },

    details() {
      return this.calendarEvent.event.data;
    },
  },

  data: (vm) => ({
    menu: false,
  }),

  methods: {
    close() {
      this.menu = false;
    },

    setSameWidthMonthEvents() {
      const eventElements = document.querySelectorAll('.ds-calendar-event-menu');

      // Get the number of children of the parent element
      eventElements.forEach(eventElement => {
        let parentChildren = eventElement.parentElement.children.length
        if (parentChildren > 1) {
          eventElement.style.width = 100 / + parentChildren + '%';
        } else {
          eventElement.style.width = '100%'
        }
      });
    },

    getName(arr = []) {
      if (arr.length) {
        const names = arr.map((item) => {
          if (item?.name) {
            const matchingName = item.name.find(
              (name) => name.lang === "en" || name.lang === "ru"
            );
            if (matchingName) {
              return matchingName.name;
            }
          }
          return "-";
        });
        return names.join(", ");
      } else {
        return "-";
      }
    },

    editCheck($event) {
      // checking if event's End Date and current Date not have days difference then open edit modal
      const endDate = this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate.toString(), 'DD-MM-YYYY');
      const currentTime = this.$moment(this.newDateWithMainUserTZ);
      // Compare the day of endDate with the current day
      if (
        !endDate.isBefore(currentTime, 'day') &&
        this.calendarEvent?.event?.data?.eventData?.state !== 'cancel'
      ) {
        if (this.handlesEvents($event)) {
          if (this.hasPopover) {
            this.menu = !this.menu;
          }
        }
      }      
    },

    camelCaseToTitleCase(camelCase = '') {
      // Split the camelCase string into words
      const words = camelCase.split(/(?=[A-Z])/);
      
      // Capitalize the first letter of each word and join them with a space
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },

    mouseEnterEvent($event) {
      if (this.handlesEvents($event)) {
        this.$emit(
          "mouse-enter-event",
          this.getEvent("mouse-enter-event", $event)
        );
      }
    },

    mouseLeaveEvent($event) {
      if (this.handlesEvents($event)) {
        this.$emit(
          "mouse-leave-event",
          this.getEvent("mouse-leave-event", $event)
        );
      }
    },

    mouseDownEvent($event) {
      if (this.handlesEvents($event)) {
        this.$emit(
          "mouse-down-event",
          this.getEvent("mouse-down-event", $event)
        );
      }
    },

    mouseUpEvent($event) {
      if (this.handlesEvents($event)) {
        this.$emit("mouse-up-event", this.getEvent("mouse-up-event", $event));
      }
    },

    handlesEvents($event) {
      var handles = !this.isPlaceholderWithDay;

      if (handles && $event) {
        $event.stopPropagation();
      }

      return handles;
    },

    getEvent(type, $event, extra = {}) {
      return fn.extend(
        {
          type: type,
          calendarEvent: this.calendarEvent,
          calendar: this.calendar,
          details: this.details,
          left: $event.button === 0,
          right: $event.button === 1,
          handled: false,
          $event: $event,
          $vm: this,
          $element: this.$el,
        },
        extra
      );
    },
  },
};
</script>

<style scoped lang="scss">
.ds-calendar-event-menu {
  width: 100%;
  height: 20px;
  pointer-events: none;
  position: relative;
}

.ds-calendar-event {
  margin: 1px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.5em;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  user-select: none;
  display: block;
  margin: 0;
  border-radius: 2px;
  pointer-events: all;
}

.P-event-table-cont {
  border-radius: 6px;
}

.ds-ev-description {
  margin-top: -3px;
}
</style>

<style lang="scss">
@import "src/assets/scss/variables";

.v-popper, .v-popper__inner, .v-popper__wrapper, .v-popper__popper {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: unset !important;
  width: max-content !important;
  border-radius: 6px; 

}

.v-popper__inner {
  width: 100%;
  height: 100%;
}
.v-popper__inner > div {
  opacity: 1;
  color: black;
  margin: 0;
  padding: 0;
  font-size: 16px;
  border-radius: 6px;
  font-weight: bold;
}

.v-popper__popper:has(.bg-white) {
  & .v-popper__inner > div {
    background-color: white;
  }
}

.v-popper__popper:has(.bg-yellow) {
  & .v-popper__inner > div {
    background-color: #fff4d9;
  }
}

.v-popper__popper:has(.bg-green) {
  & .v-popper__inner > div {
    background-color: #e7f8ed;
  }
}

.v-popper__popper:has(.bg-red) {
  & .v-popper__inner > div {
    background-color: #fee1e0;
  }
} 

.v-popper__arrow-container {
  display: none;
}

.max-w-250 {
  max-width: 250px !important;
  word-break: break-word !important;
}

.P-comments-parent {
  max-height: 99px;
  overflow-y: auto;
}

.P-event-comment {
  &__user-name {
    color: $primary-color;
    font-size: $txt14;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px !important;
  }

  & .canceled {
    border: 2px solid red;
    padding: 5px;
    border-radius: 5px;
    width: max-content;
    color: red;
    text-shadow: 1px 1px 1px white;
    font-size: 15px;
    margin-top: 8px;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }  
}
</style>