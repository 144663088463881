export const defaultColumnConfig = [{
  "defaultHide": true,
  "defaultDragIndex": 0,
  "mainIndex": 0,
  "name": "Discount Name"
},
  {
    "defaultHide": true,
    "defaultDragIndex": 1, "mainIndex": 1,
    "name": "Start Date"
  },
  {
    "defaultHide": true,
    "defaultDragIndex": 2,
    "mainIndex": 2,
    "name": "End Date"
  },
  {
    "defaultHide": true,
    "defaultDragIndex": 3,
    "mainIndex": 3,
    "name": "Timeline Status"
  },
  {
    "defaultHide": true,
    "defaultDragIndex": 4,
    "mainIndex": 4,
    "name": "Type"
  }, {
    "defaultHide": true,
    "defaultDragIndex": 5, "mainIndex": 5,
    "name": "Price Groups"
  },
  {
    "defaultHide": true,
    "defaultDragIndex": 6,
    "mainIndex": 6,
    "name": "Status"
  }, {
    "defaultHide": true,
    "defaultDragIndex": 7,
    "mainIndex": 7,
    "name": "Default Sale Percent"
  },
  {
    "defaultHide": true,
    "defaultDragIndex": 8,
    "mainIndex": 8,
    "name": "Packages"
  }]
