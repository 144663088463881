<template>
  <sign-pad @save="saveSign" />
</template>

<script>
import SignPad from "@/components/SignPad";

export default {
  name: "Sign",
  components: {
    SignPad
  },
  methods: {
    saveSign(event) {
      console.log(event);
    },
  },
};
</script>