<template>
  <ott-dialog
    :is-open="isOpenModal"
    :show-footer="false"
    :modal-title="modalTitle"
    :width="width"
    content-class="P-image-modal-content"
    @outside="$emit('closeModal')"
    @resetDataModal="$emit('closeModal')"
  >
    <template #modalBody>
      <div :class="{'mt-3': showEditBtn}">
        <div class="P-image-modal d-flex align-center justify-center">
          <img
            :src="imageSrc"
            :class="imgClasses"
            :alt="modalTitle"
            :title="modalTitle"
          />
        </div>

        <ott-button-loader
          v-if="showEditBtn"
          class-name=" w-max-content mx-auto my-3"
          button-text="Edit Image"
          @onClick="openImageEditor()"
        />

        <image-editor
          v-if="showImageEditor"
          @closeModal="() => showImageEditor = false"
          :open-image-editor="showImageEditor"
          :image-path="base64Img"
          @save="saveImageChanges($event)"
        />
      </div>
    </template>
  </ott-dialog>
</template>

<script>
// components
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import ImageEditor from "@/components/ImageEditor";

export default {
  name: "ImageModal",
  components: { OttDialog, OttButtonLoader, ImageEditor },
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_API_URL;
    },

    getRandomNumber() {
      return Math.floor(Math.random() * (1000000000000000 - 1 + 1)) + 1;
    },
  },
  data() {
    return {
      showImageEditor: false,
      base64Img: null,
    };
  },
  props: {
    modalTitle: {
      type: String,
      default: () => "",
    },

    modalContentClass: {
      type: String,
      default: ""
    },

    imageSrc: {
      type: String,
      default: () => "",
    },

    imgClasses: {
      type: Object,
      default: () => null,
    },

    isOpenModal: {
      type: Boolean,
      default: () => false,
    },

    showEditBtn: {
      type: Boolean,
      default: () => false,
    },

    width: {
      type: Number,
      required: false,
      default: 1110
    },
  },
  methods: {
    async openImageEditor() {
      // get blob file from imageUrl then get base64 from blob file for image-editor
      const imageUrlToBlob = await this.imageUrlToBlob(this.imageSrc);
      this.base64Img = await this.convertBase64(imageUrlToBlob);
      this.showImageEditor = true
    },

    async imageUrlToBlob(imageUrl) {
      return fetch(imageUrl).then((response) => response.blob());
    },

    // Convert Image to base64
    convertBase64(image) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(image);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },

    /**
     * convert Base64 To blob file
     * **/
    b64toFile(base64) {
      let arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], "img-" + this.getRandomNumber + '.jpg', {
        type: mime,
      });
    },

    async saveImageChanges(changedImg) {
      const changedImgFile = this.b64toFile(changedImg)
      this.$emit('imageChange', changedImgFile)
      this.showImageEditor = false
    },
  },
};
</script>

<style scoped lang="scss">
.P-image-modal {
  & img {
    // width: 100%;
    // height: 100%;
    object-fit: contain;
  }
}
</style> 

<style lang="scss">
  .P-image-modal-content {
    width: max-content !important;
  }
</style>
