import RequestService from "@/services/request.service";
import FullCalendarModel from "@/models/full-calendar/FullCalendarModel";
import _ from "lodash";
import moment from 'moment';

const InitialState = {
    formData: {
        title: null,
        paymentType: null,
        paymentPrice: null,
        customer: {
            name: null,
            value: null,
            id: null
        },
        location: null,
        equipmentInstaller: null,
        customerAddress: null,
        customerLatitude: null,
        customerLongitude: null,
        officeAddress: null,
        appointmentStartDate: null,
        appointmentEndDate: null,
        startTime: null,
        endTime: null,
        comment: '',
        directions: null,
        state: 'active',
    },
    filterModel: new FullCalendarModel(),
    filterData: null,
    calendarEvents: [],
    customersList: [],
    eventId: null,
    isOpenFilter: false,
    addEventByPlus: false
}

export const fullCalendar = {
    namespaced: true,
    state: InitialState,
    mutations: {
        /**
        * for all first level keys changing  
        * setData({ key: 'value' })
        */
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key];
            }
        },

        filterCalendarEvents(state) {
            const filterObject = {};
            if (state.filterModel.search) {
                filterObject.search = state.filterModel.search;
            }

            if (state.filterModel.date?.start && state.filterModel.date?.end) {
                filterObject.startDate =
                    moment(state.filterModel.date.start).format('MM/DD/YYYY');
                filterObject.endDate =
                    moment(state.filterModel.date.end).format('MM/DD/YYYY');
            }
            if (state.filterModel.eventType) {
                filterObject.eventType = state.filterModel.eventType;
            }

            if (state.filterModel.paymentType) {
                filterObject.paymentType = state.filterModel.paymentType;
            }
            if (state.filterModel.state) {
                filterObject.state = state.filterModel.state;
            }
            if (state.filterModel.equipmentInstaller) {
                filterObject.equipmentInstaller = state.filterModel.equipmentInstaller;
            }

            state.filterData = filterObject;
        },

        setFormData(state, data) {
            for (const key in data) {
                // TODO CHANGE IF NEED
                data[key] = typeof data[key] === "string" && data[key] && data[key].includes('<p>') ? data[key].replace(/<p>|<\/p>/g, '') : data[key]
                state.formData[key] = data[key];
            }
        },

        resetFormData(state) {
            state.formData = {
                title: null,
                paymentType: null,
                paymentPrice: null,
                customer: {
                    name: null,
                    value: null,
                    id: null
                },
                location: null,
                equipmentInstaller: null,
                customerAddress: null,
                customerLatitude: null,
                customerLongitude: null,
                officeAddress: null,
                appointmentStartDate: null,
                appointmentEndDate: null,
                startTime: null,
                endTime: null,
                comment: '',
                directions: null,
                state: 'active',
            }
        }

    },
    actions: {
        searchCustomers({ commit }, searchVal) {
            let customersList = []

            if (searchVal) {
                return RequestService.read(`clients/globalSearch?search=${searchVal}`)
                    .then(response => {
                        if (response.data.length) {
                            response.data.map(item => {
                                const getClientStatus = (item) => {
                                    /*
                                        client status by subscriptionState value
                                        0 - Inactive
                                        1 - Pending
                                        2 - Canceled
                                        3 - Active
                                    */

                                    if (item.subscriptionState === 0) {
                                        return `<span class="G-badge-calendar G-advanced-badge">Inactive</span>`
                                    }

                                    if (item.subscriptionState === 1) {
                                        return `<span class="G-badge-calendar G-pending-badge">Pending</span>`
                                    }

                                    if (item.subscriptionState === 2) {
                                        return `<span class="G-badge-calendar G-disabled-badge">Canceled</span>`
                                    }

                                    if (item.subscriptionState === 3) {
                                        if (item.inBlock === 0 && item.inPause === 0) {
                                            return `<span class="G-badge-calendar G-approve-badge">Active</span>`
                                        }

                                        if (item.inBlock === 0 && item.inPause === 1) {
                                            return `<span class="G-badge-calendar G-paused-badge">Paused</span>`
                                        }

                                        if (item.inBlock === 0 && item.inPause === 2) {
                                            return `<span class="G-badge-calendar G-paused-badge">Semipaused</span>`
                                        }

                                        if (item.inBlock === 1 && item.inPause === 0) {
                                            return `<span class="G-badge-calendar G-reject-badge">Blocked</span>`
                                        }

                                        if (item.inBlock === 2 && item.inPause === 0) {
                                            return `<span class="G-badge-calendar G-reject-badge">Semiblocked</span>`
                                        }

                                        if (item.inBlock === 1 && item.inPause === 1) {
                                            return `<span class="G-badge-calendar G-reject-badge">Blocked</span> <br>
                                                <span class="G-badge-calendar G-paused-badge">Paused</span>`
                                        }

                                        if (item.inBlock === 1 && item.inPause === 2) {
                                            return `<span class="G-badge-calendar G-reject-badge">Blocked</span> <br>
                                                    <span class="G-badge-calendar G-paused-badge">Semipaused</span>`
                                        }

                                        if (item.inBlock === 2 && item.inPause === 1) {
                                            return `<span class="G-badge-calendar G-reject-badge">Semiblocked</span> <br>
                                                <span class="G-badge-calendar G-paused-badge">Paused</span>`
                                        }

                                        if (item.inBlock === 2 && item.inPause === 2) {
                                            return `<span class="G-badge-calendar G-reject-badge">Semiblocked</span> <br>
                                                <span class="G-badge-calendar G-paused-badge">Semipaused</span>`
                                        }
                                    }

                                    if (item.inBlock === undefined || item.inPause === undefined || (item.inBlock === 0 && item.inPause === 0)) {
                                        return '<span></span>'
                                    }
                                }

                                customersList.push({
                                    id: item.id,
                                    name: `<div class="G-align-center"><span class="me-2 G-align-center">${item.personalInfo.firstname} ${item.personalInfo.lastname} (${item.provider ? item.provider.name[0].name : 'No Provider Name'})</span> ${getClientStatus(item)}</div>`,
                                    value: item.personalInfo.firstname + ' ' + item.personalInfo.lastname
                                })
                            })

                            commit('setData', { customersList })
                        } else {
                            commit('setData', { customersList: [] })
                        }
                    })
                    .catch(e => console.log(e))
            }

            if (!searchVal) {
                commit('setData', { customersList: [] })
            }
        },

        addEvent({ commit }, data) {
            return RequestService.create('calendarEvents', { ...data })
                .then((r) => Promise.resolve(r))
                .catch((e) => console.log(e))
        },

        deleteEvent({ commit }, id) {
            return RequestService.delete(`calendarEvents/edit/${id}`).catch((e) => console.log(e))
        },

        editEvent({ state }, data = {}) {
            let eventId = data?.eventId ? data?.eventId : state.eventId
            delete data.eventId
            
            if (eventId) {
                return RequestService.update(`calendarEvents/edit/${eventId}`, { ...data })
                    .then((r) => Promise.resolve(r))
                    .catch((e) => console.log(e))
            }
        },

        addEventComment({ state }, { eventId, comment, isCancel = false }) {
            console.log(comment);
            let data = {
                comment
            }

            if (isCancel) {
                data.isCancel = true
            }

            return RequestService.create(`calendarEvents/comment/${eventId}`, { ...data }).catch((e) => console.log(e))
        },

        editEventComment({ state }, { eventId, comment, commentId }) {
            return RequestService.update(`calendarEvents/comment/${eventId}`, { 
                comment,
                id: commentId
            }).catch((e) => console.log(e))
        },

        getEvents({ commit }, filter = {}) {
            const options = {
                limit: 10000000,
                sortBy: '_id:desc'
            }

            for (const key in filter) {
                options[key] = filter[key]
            }

            let query = Object.keys(options).map(
                key => key + '=' + options[key]
            ).join('&');

            return RequestService.read(`calendarEvents?${query}`)
                .then(response => {
                    if (response.data.results) {
                        commit("setData", { calendarEvents: response.data.results })
                    }
                }).catch((e) => console.log(e))
        },
    }
}