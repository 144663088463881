import {devicesList} from "./devicesListExample";

const InitialState = {

  timeShiftList: [
    {name: 'TimeShift 1', value: 'TimeShift 1'},
    {name: 'TimeShift 2', value: 'TimeShift 2'},
    {name: 'TimeShift 3', value: 'TimeShift 3'},
    {name: 'TimeShift 4', value: 'TimeShift 4'},
    {name: 'TimeShift 5', value: 'TimeShift 5'},
  ],
  languageList: [
    {name: 'Language 1', value: 'Language 1'},
    {name: 'Language 2', value: 'Language 2'},
    {name: 'Language 3', value: 'Language 3'},
    {name: 'Language 4', value: 'Language 4'},
  ],
  ageGroupList: [
    {name: 'Age Group 1', value: 'Age Group 1'},
    {name: 'Age Group 2', value: 'Age Group 2'},
    {name: 'Age Group 3', value: 'Age Group 3'},
    {name: 'Age Group 4', value: 'Age Group 4'},
  ],
  inactivityTimeoutList: [
    {name: 'Inactivity Timeout 1', value: 'Inactivity Timeout 1'},
    {name: 'Inactivity Timeout 2', value: 'Inactivity Timeout 2'},
    {name: 'Inactivity Timeout 3', value: 'Inactivity Timeout 3'},
    {name: 'Inactivity Timeout 4', value: 'Inactivity Timeout 4'},
  ],
  audioTrackDefaultList: [
    {name: 'Audio Track Default 1', value: 'Audio Track Default 1'},
    {name: 'Audio Track Default 2', value: 'Audio Track Default 2'},
    {name: 'Audio Track Default 3', value: 'Audio Track Default 3'},
    {name: 'Audio Track Default 4', value: 'Audio Track Default 4'}
  ],
  channelsOrderModeList: [
    {name: 'Channels Order Mode 1', value: 'Channels Order Mode 1'},
    {name: 'Channels Order Mode 2', value: 'Channels Order Mode 2'},
    {name: 'Channels Order Mode 3', value: 'Channels Order Mode 3'},
    {name: 'Channels Order Mode 4', value: 'Channels Order Mode 4'},
  ],

  formData: {
    avatar: null,
    nickname: '',
    protectCode: '',
    language: null,
    inactivityTimeout: null,
    timeShift: null,
    audioTrackDefault: null,
    channelsOrderMode: null,
    isEnableProfile: false,
    isAdmin: false,
    protectBoot: false,
    protectSetting: false,
    ageGroup: null,
    protectMediaByAge: false,
    hideMediaByAge: false,
    isVodEnable: false,
    vodHide: {
      isBlood: false,
      isViolence: false,
      isObscene: false,
      isPorn: false,
      isHorror: false
    },
    vodProtect: {
      isBlood: false,
      isViolence: false,
      isObscene: false,
      isPorn: false,
      isHorror: false
    }
  },

  isEditMode: false,
  isAddMode: false,
  cloneData: null,
  profilesList: devicesList,
  selectedLocation: null,

  imageData: null
};

export const profileClients = {
  namespaced: true,
  actions: {},

  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {


    /**
     * Select Location Data
     * **/
    selectLocation(state, data) {
      state.selectedLocation = data
    },
    setIsAddMode(state, value) {
      state.isAddMode = value
    },
    setIsEditMode(state, value) {
      state.isEditMode = value
    },


    /**
     * Set Data profile
     * **/

    setImagePath(state, value) {
      state.formData.avatar = value
    },
    setImageData(state, data) {
      state.imageData = data
    },
    setNikName(state, value) {
      state.formData.nickname = value
    },
    setProtectCode(state, value) {
      state.formData.protectCode = value
    },
    setLanguage(state, value) {
      state.formData.language = value
    },
    setInactivityTimeout(state, value) {
      state.formData.inactivityTimeout = value
    },
    setTimeShift(state, value) {
      state.formData.timeShift = value
    },
    setAudioTrackDefault(state, value) {
      state.formData.audioTrackDefault = value
    },
    setChannelsOrderMode(state, value) {
      state.formData.channelsOrderMode = value
    },
    setIsEnableProfile(state, value) {
      state.formData.isEnableProfile = value
    },
    setIsAdmin(state, value) {
      state.formData.isAdmin = value
    },
    setProtectBoot(state, value) {
      state.formData.protectBoot = value
    },
    setProtectSetting(state, value) {
      state.formData.protectSetting = value
    },
    setAgeGroup(state, value) {
      state.formData.ageGroup = value
    },
    setProtectMediaByAge(state, value) {
      state.formData.protectMediaByAge = value
    },
    setHideMediaByAge(state, value) {
      state.formData.hideMediaByAge = value
    },
    setIsVodEnable(state, value) {
      state.formData.isVodEnable = value
    },
    setIsHideBlood(state, value) {
      state.formData.vodHide.isBlood = value
    },
    setIsHideViolence(state, value) {
      state.formData.vodHide.isViolence = value
    },
    setIsHideObscene(state, value) {
      state.formData.vodHide.isObscene = value
    },
    setIsHidePorn(state, value) {
      state.formData.vodHide.isPorn = value
    },
    setIsHideHorror(state, value) {
      state.formData.vodHide.isHorror = value
    },
    setIsProtectBlood(state, value) {
      state.formData.vodProtect.isBlood = value
    },
    setIsProtectViolence(state, value) {
      state.formData.vodProtect.isViolence = value
    },
    setIsProtectObscene(state, value) {
      state.formData.vodProtect.isObscene = value
    },
    setIsProtectPorn(state, value) {
      state.formData.vodProtect.isPorn = value
    },
    setIsProtectHorror(state, value) {
      state.formData.vodProtect.isHorror = value
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }

};
