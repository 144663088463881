import {mapActions, mapMutations, mapState} from "vuex";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import SelectedFiltersList from "./SelectedFiltersList";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
import OttRangeSelect from "@/components/customComponents/OttRangeSelect";
import DatePicker from "v-calendar/src/components/DatePicker";
import timeZoneList from "@/constants/time-zone-list";

export default {
  components: {
    OttRangeSelect,
    OttSelectAutocomplete,
    OttCheckbox,
    SelectedFiltersList,
    OttSelect,
    OttInput,
    DatePicker
  },
  data() {
    return {
      rolesList: [],
      timeZoneList: timeZoneList,
      statusesList: [
        {
          name: 'Active',
          value: 3
        },
        {
          name: 'Pending',
          value: 1
        },
        {
          name: 'Paused',
          value: 4
        },
        {
          name: 'Blocked',
          value: 5
        },
        {
          name: 'Canceled',
          value: 2
        },
        {
          name: 'Inactive',
          value: '0'
        }
      ],
      statusTypes: [
        {
          name: 'Client',
          value: 'client'
        },
        {
          name: 'Location',
          value: 'location'
        }
      ]
    };
  },

  computed: {
    ...mapState({
      filterModel: state => state.clientsModule.filterModel,
      filter: state => state.clientsModule.filterData,
      filterRangeData: state => state.clientsModule.filterRangeData,
      currencyList: state => state.financeClient.currencyList,
      serverList: state => state.clientsModule.serverList,
      priceGroupList: state => state.priceGroupAdmin.clientsPriceGroups,
      resellersList: state => state.providers.resellersList,
    })
  },

  methods: {
    ...mapActions({
      getClientList: 'clientsModule/getClientList'
    }),
    ...mapMutations({
      setData: 'clientsModule/setData',
      filterClients: 'clientsModule/filterClients',
      setFilterPage: 'clientsModule/setFilterPage',
      sortColumn: 'clientsModule/sortColumn',
    }),

    async filterData(type) {
      this.setFilterPage(1)
      this.filterClients()
      switch (type) {
        case 'search': {
          if (!this.filterModel.search) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'subscriptionState': {
          if (this.filterModel.subscriptionState === null) {
            this.filterModel.resetCurrentData(type)
            this.filterModel.resetCurrentData('statusFilterType')
          }
          break
        }
        case 'statusFilterType': {
          if (this.filterModel.statusFilterType === null) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'providers': {
          if (!this.filterModel.providers.length) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'resellerId': {
          if (!this.filterModel.resellerId) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'priceGroup': {
          if (!this.filterModel.priceGroup) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'currency': {
          if (!this.filterModel.currency) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'creditExpired': {
          if (!this.filterModel.creditExpired) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'creditAutoExtend': {
          if (!this.filterModel.creditAutoExtend) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'isBlocked': {
          if (!this.filterModel.isBlocked) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'inPaused': {
          if (!this.filterModel.inPaused) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'packageExpired': {
          if (!this.filterModel.packageExpired) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'autopayment': {
          if (!this.filterModel.autopayment) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'server': {
          if (!this.filterModel.server) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
        case 'timezone': {
          if (!this.filterModel.timezone) {
            this.filterModel.resetCurrentData(type)
          }
          break
        }
      }

      this.filterModel.getFilterCount();
      this.sortColumn();
      await this.getClientList(this.filter)
      this.$emit('resetSelectedList')
    },
    async resetData(type) {
      this.filterModel.resetCurrentData(type)
      this.filterClients()
      this.filterModel.getFilterCount()
      await this.getClientList(this.filter)
      this.$emit('resetSelectedList')
    }

  },
};
