<template>
  <div class="active-filter-item neutral-color-15 secondary--text">
    <p v-html="boxText" />
    <span @click="$emit('resetData')" class="mdi mdi-close G-cursor-pointer"></span>
  </div>
</template>
<script>
export default {
  name: "SelectedListBox",
  props: {
    boxText: {
      type: String,
      default: () => "",
    },
    deletedType: {
      type: String,
      default: () => "",
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
.active-filter-item {
  display: flex;
  justify-content: space-between;
  padding: 2px 10px;
  margin: 7.5px;
  border-radius: 20px;
  min-width: 80px;

  p {
    margin-bottom: 0;
    font-size: $txt14;
    font-weight: $regular;
    margin-right: 5px;
  }

  span {
    font-size: $txt14;
  }
}
</style>
