import Transactions from "@/views/transactions/Transactions";

export const transactionsRout = {
  path: "/",
  redirect: "/transactions",
  name: "Transactions",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "transactions",
      name: "TransactionsList",
      component: Transactions,
      meta: { auth: true, roles: ["ottprovider"], permissions: [] }
    }
  ]
};
