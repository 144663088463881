<template>
  <div class="auth-cont ">
    <auth-header />
    <div class="auth-body">
      <div
        class="main-icon"
        :style="{
          backgroundImage:
            `url(${require('@/assets/images/designImages/pending.svg')})`
        }"
      />
      <h6>Thank you !</h6>
      <p class="description">
        Please wait 3-5 business days. Your account is being reviewed by the
        management. We appreciate your patience!
      </p>
    </div>
    <auth-footer />
  </div>
</template>

<script>
import AuthFooter from "./components/authFooter";
import AuthHeader from "./components/authHeader";

export default {
  name: "RegisterPending",
  components: {
    AuthHeader,
    AuthFooter
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/authGeneral";
.description {
  padding: 15px 0 30px;
}
  .auth-body{
    text-transform: capitalize;
  }
</style>
