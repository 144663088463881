var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"G-table-block"},[_c('div',{ref:"tableBody",staticClass:"G-table-scroll-Y P-scroll-Y",on:{"scroll":function($event){return _vm.scrollY($event)}}},[_c('ul',{staticClass:"G-table-header"},[(!_vm.hideLeftAction)?_c('li',{staticClass:"P-action-left P-fixed-left",class:[
          {
            'P-show-shadow': _vm.showShadowOnScroll,
          },
        ]},[_c('div',{staticClass:"P-column"},[(_vm.isMultiSelect)?_c('OttCheckbox',{on:{"changeCheckbox":function($event){return _vm.$emit('selectAllVisibles', _vm.selectedAllVisibles)}},model:{value:(_vm.selectedAllVisibles),callback:function ($$v) {_vm.selectedAllVisibles=$$v},expression:"selectedAllVisibles"}}):_vm._e()],1)]):_vm._e(),_vm._l((_vm.columnConfigData),function(mainColumn,mainColumnIndex){return [(mainColumn.isShow)?_c('li',{key:mainColumnIndex,staticClass:"G-flex G-flex-column"},[_c('div',{staticClass:"P-column P-main-title",class:[
              {
                'P-cursor-move': mainColumn.isDrag,
                'P-show-shadow': _vm.showShadowOnScroll,
              },
            ],attrs:{"draggable":mainColumn.isDrag,"data-parent":"true"},on:{"dragstart":function($event){return _vm.handleDragStart($event, mainColumnIndex)},"dragover":function($event){return _vm.handleDragOver($event, mainColumnIndex)},"dragleave":function($event){return _vm.handleDragLeave($event)},"dragend":function($event){return _vm.handleDragDrop($event)},"drop":function($event){return _vm.handleDragLeave($event)}}},[_c('span',{staticClass:"mdi mdi-drag P-drag-icon"}),_c('p',{staticClass:"G-flex G-justify-center"},[_vm._v(" "+_vm._s(mainColumn.title)+" ")])]),_c('div',{staticClass:"P-column-sub-list G-flex"},[_vm._l((mainColumn.subList),function(column,indexColumn){return [(column.isShow)?_c('div',{key:indexColumn,staticClass:"P-column G-flex G-align-center",class:[
                  {
                    'P-cursor-move': column.isDrag,
                  },
                ],style:({
                  minWidth: column.style.minWidth + 'px',
                  maxWidth: column.style.maxWidth + 'px',
                }),attrs:{"draggable":column.isDrag},on:{"dragstart":function($event){return _vm.handleDragStartColumn($event, indexColumn, mainColumnIndex)},"dragover":function($event){return _vm.handleDragOverColumn($event, indexColumn, mainColumnIndex)},"dragleave":function($event){return _vm.handleDragLeaveColumn($event)},"dragend":function($event){return _vm.handleDragDropColumn($event, mainColumnIndex)},"drop":function($event){return _vm.handleDragLeaveColumn($event)},"click":function($event){return _vm.sortColumns(column)}}},[_c('span',{staticClass:"mdi mdi-drag P-drag-icon"}),_c('p',{staticClass:"G-align-center"},[_vm._v(" "+_vm._s(column.title)+" "),(column.sortData && column.sortData.isSort)?_c('span',{staticClass:"mdi P-sort-icon",class:{
                      'mdi-arrow-up':
                        !column.sortData.sortValue ||
                        column.sortData.sortValue === 'desc',
                      'mdi-arrow-down': column.sortData.sortValue === 'asc',
                      'P-active-sort': column.sortData.sortValue,
                    }}):_vm._e()])]):_vm._e()]})],2)]):_vm._e()]}),(_vm.isActions)?_c('li',{staticClass:"P-action-column",class:{
          'P-fixed-right': _vm.fixedFirstAndLastColumn,
          'P-show-shadow': _vm.showShadowOnScroll,
        }}):_vm._e()],2),_c('div',{staticClass:"G-table-body"},_vm._l((_vm.data),function(row,rowIndex){return _c('ul',{key:rowIndex,class:{
          'P-table-odd': rowIndex % 2 === 0,
          'P-table-even': rowIndex % 2 !== 0,
        }},[(!_vm.hideLeftAction)?_c('li',{staticClass:"P-action-left P-fixed-left",class:[
            {
              'P-show-shadow': _vm.showShadowOnScroll,
            },
          ]},[_c('div',{staticClass:"P-column"},[(_vm.isMultiSelect)?_c('OttCheckbox',{attrs:{"data-val":_vm.getSelectedRow(row)},on:{"changeCheckbox":function($event){return _vm.checkRow(row, _vm.recursiveTreeIndex)}}}):_vm._e()],1)]):_vm._e(),_vm._l((_vm.columnConfigData),function(columnMain,columnMainIndex){return [(columnMain.isShow)?_c('li',{key:columnMainIndex},[_vm._l((columnMain.subList),function(column,columnIndex){return [(column.isShow)?_c('div',{key:columnIndex,staticClass:"P-parent-column",style:({
                minWidth: column.style.minWidth + 'px',
                maxWidth: column.style.maxWidth + 'px',
              })},[_c('div',{staticClass:"P-column",domProps:{"innerHTML":_vm._s(typeof column?.cellView === 'function' ? column.cellView(row) : '-')}})]):_vm._e()]})],2):_vm._e()]}),(_vm.isActions)?_c('li',{staticClass:"P-action-column ott-menu",class:{
            'P-fixed-right': _vm.fixedFirstAndLastColumn,
            'P-show-shadow': _vm.showShadowOnScroll,
          }},[_c('div',{staticClass:"P-column P-actions-part"},[_vm._t("action",null,{"rowData":row})],2)]):_vm._e()],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }