<template>
  <div class="ds-calendar-event ds-calendar-event-hour" :content-class="contentClass" :style="style">
    <v-dialog
      :disabled="!hasPopover"
      v-model="menu"
      v-bind="popoverProps"
      max-width="1000"
    >
      <template v-slot:activator="{}">
            <!-- popperShowTriggers: ['hover'],
            popperHideTriggers: ['hover'], -->
        <div
          v-tooltip="{
            content: eventTooltip,
            placement: 'auto',
            popperClass: 'tooltip-content',
            triggers: ['click', 'touch'],
            autoHide: true,
            shown: menu ? false : null,
            distance: 5,
            html: true
          }"
          class="ds-calendar-event-span"
          @click="setEventsSideBySide"
          @dblclick="editCheck($event)"
          @mouseenter="mouseEnterEvent"
          @mouseleave="mouseLeaveEvent"
          @mousedown="mouseDownEvent"
          @mouseup="mouseUpEvent"
        >
          <span v-if="showName">
            <slot name="eventTimeTitle" v-bind="{ calendarEvent, details }">
              <b class="ds-ev-title">{{ details.title }}</b>
              <span class="ds-ev-description">{{
                details.description
              }}</span>
            </slot>
          </span>
          <span v-else>
            <slot
              name="eventTimeEmpty"
              v-bind="{ calendarEvent, details }"
            ></slot>
          </span>
        </div>
      </template>
      <slot
        name="eventPopover"
        v-bind="{ calendarEvent, calendar, details, close, menu }"
      ></slot>

      <slot name="deleteIcon" />

    </v-dialog>
  </div>
</template>

<script>
import {
  Day,
  Constants,
  CalendarEvent,
  Calendar,
  Functions as fn,
} from "dayspan";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import { hideAllPoppers } from 'floating-vue'

export default {
  name: "dsCalendarEventTime",

  props: {
    calendarEvent: {
      required: true,
      type: CalendarEvent,
    },

    calendar: {
      required: true,
      type: Calendar,
    },

    popoverProps: {
      validate(x) {
        return this.$dsValidate(x, "popoverProps");
      },
      default() {
        return this.$dsDefaults().popoverProps;
      },
    },

    isPlaceholderWithDay: {
      type: Day,
    },
  },

  mixins: [ConvertTimeZoneMixin, DateFormatMixin],

  computed: {
    eventTooltip() {
      return `
      <div 
        class="
          P-event-table-cont
          ${
            this.calendarEvent?.event?.data?.eventData?.state === 'active' ? 'bg-white' :
            this.calendarEvent?.event?.data?.eventData?.state === 'process' ? 'bg-yellow' :
            this.calendarEvent?.event?.data?.eventData?.state === 'completed' ? 'bg-green' :
            this.calendarEvent?.event?.data?.eventData?.state === 'cancel' ? 'bg-red' : 'bg-white'
          }
        "
        style='border: 4px solid ${this.calendarEvent?.event?.data?.eventData?.user?.color ? this.calendarEvent?.event?.data?.eventData?.user?.color : '' }'
      >
        <table 
          style='border-collape: collapse;' class='font-weight-black P-event-tooltip-table'>
          <tr><td valign='top'>Event By:</td> <td valign='top' class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.user?.firstname} ${this.calendarEvent?.event?.data?.eventData?.user?.lastname}</td></tr>
          
          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
          
          <tr><td valign='top'>Equipment Installer:</td> <td valign='top' class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.equipmentInstaller?.firstname} ${this.calendarEvent?.event?.data?.eventData?.equipmentInstaller?.lastname}</td></tr>
          
          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
          
          <tr>
            <td valign='top'>Status:</td> 
            <td class='ps-2'>${this.camelCaseToTitleCase(this.calendarEvent?.event?.data?.eventData?.state)}</td>
          </tr>
          
          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
          
          <tr>
            <td valign='top'>Date:</td> 
            <td valign='top' class='ps-2'>
              <p>
                ${this.$moment(this.calendarEvent?.event?.data?.eventData?.startDate, 'DD-MM-YYYY').format(this.ottDateFormat)} - 
                ${this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate, 'DD-MM-YYYY').format(this.ottDateFormat)}
              </p> 
              <p>
                ${this.$moment(this.calendarEvent?.event?.data?.eventData?.startDate, 'DD-MM-YYYY hh:mm a').format('hh:mm a')} - 
                ${this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate, 'DD-MM-YYYY hh:mm a').format('hh:mm a')}
              </p> 
            </td>
          </tr>
          
          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
          
          <tr><td valign='top'>Title:</td> <td valign='top' class='ps-2'>${this.camelCaseToTitleCase(this.calendarEvent?.event?.data?.eventData?.title)}</td></tr>
          
          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
          
          <tr>
            <td valign='top'>Customer:</td>
            <td valign='top' class='ps-2 max-w-250'>
              ${ this.calendarEvent?.event?.data?.eventData?.client?.personalInfo?.firstname } 
              ${ this.calendarEvent?.event?.data?.eventData?.client?.personalInfo?.lastname }
              (${ this.calendarEvent?.event?.data?.eventData?.client?.provider ? this.calendarEvent?.event?.data?.eventData?.client?.provider?.name[0].name : 'No Provider Name' })
            </td>
          </tr>

          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>

          <tr>
            <td valign='top'>Customer Phones:</td>
            <td valign='top' class='ps-2'>
              ${
                this.calendarEvent?.event?.data?.eventData?.client?.phones.length 
                  ? this.calendarEvent?.event?.data?.eventData?.client?.phones.map(phone => `<a href="tel:${phone.phone}">${phone.phone}</a>`).join('<br />')
                  : '-'
              }
            </td>
          </tr>
          
          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
          
          <tr><td valign='top'>Address:</td> <td valign='top' class='ps-2'>${this.FormatClientAddress(this.calendarEvent?.event?.data?.eventData?.customerAddress)}</td></tr>
          
          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
          
          <tr>
            <td valign='top'>Location:</td> 
            <td valign='top' class='ps-2'>
              <table>
                <tr>
                  <td>Name:</td>
                  <td class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.location?.locationName}</td>
                </tr>
                <tr>
                  <td>Login:</td>
                  <td class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.location?.login}</td>
                </tr>
                <tr>
                  <td>Password:</td>
                  <td class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.location?.password}</td>
                </tr>
                <tr>
                  <td>Rooms:</td>
                  <td class='ps-2'>${this.calendarEvent?.event?.data?.eventData?.location?.roomsCount}</td>
                </tr>
              </table>
            </td>
          </tr>

          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>

          <tr>
            <td valign='top'>Connections:</td>
            <td valign='top' class='ps-2'>
              ${
                this.getName(this.calendarEvent?.event?.data?.eventData?.client?.info?.locations.filter(item => item.login === this.calendarEvent?.event?.data?.eventData?.location?.login)[0]?.packageSubscriptoins)
              }
            </td>
          </tr>
          
          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>

          <tr><td valign='top'>Payment Type:</td> <td valign='top' class='ps-2'>
            ${
              typeof this.calendarEvent?.event?.data?.eventData?.paymentType === 'string' 
                ? this.calendarEvent?.event?.data?.eventData.paymentType.charAt(0).toUpperCase() + this.calendarEvent?.event?.data?.eventData.paymentType.slice(1)
                : '-'
            } 
            ${
              typeof this.calendarEvent?.event?.data?.eventData?.paymentPrice === 'number'
                ? new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                  }).format(this.calendarEvent.event.data.eventData.paymentPrice)
                : ''
            }
          </td></tr>

          <tr><td><hr class='my-1'/></td><td><hr class='my-1'/></td></tr>
          ${
            this.calendarEvent?.event?.data?.eventData?.comments.length 
              ? `
                <tr>
                  <td valign='top'>Comments:</td>
                  <td valign='top' class='ps-2'>
                    <div class='max-w-250 P-comments-parent'>
                      ${
                        this.calendarEvent?.event?.data?.eventData?.comments.map(item => {
                          return `
                            <div class="P-event-comment">
                              <p class="P-event-comment__user-name">
                                <span>${ item.userName }</span>
                                <span>${ item.updatedAt }</span>
                              </p>
                              <p>${ item.comment }</p>

                              ${ 
                                item?.isCancel 
                                  ? '<p class="canceled">Canceled</p>'
                                  : '' 
                              }
                              <hr class="my-2">
                            </div>
                          `
                        }).join('')
                      }
                    </div>
                  </td>
                </tr>` 
              : ''
          }
        </table>
       </div> 
      `
    },

    style() {
      return this.isPlaceholderWithDay
        ? this.$dayspan.getStylePlaceholderTimed(
            this.details,
            this.calendarEvent,
            this.isPlaceholderWithDay
          )
        : this.$dayspan.getStyleTimed(this.details, this.calendarEvent);
    },

    showName() {
      return (
        this.calendarEvent.starting ||
        (this.calendar &&
          !this.calendar.span.contains(this.calendarEvent.time.start))
      );
    },

    hasPopover() {
      return !!this.$scopedSlots.eventPopover;
    },

    hasIcon() {
      return !!(this.$dayspan.supports.icon && this.details.icon);
    },

    contentClass() {
      return this.$dayspan.fullscreenPopovers ? "ds-fullscreen" : "";
    },

    details() {
      return this.calendarEvent.event.data;
    },
  },

  data: (vm) => ({
    menu: false,
  }),

  methods: {
    setEventsSideBySide() {
      // Select all elements with class ds-calendar-event-hour
        const elements = document.querySelectorAll('.ds-calendar-event-hour');

        // Create an object to group elements by the combination of top and data-id
        const groups = {};

        elements.forEach((element) => {
          const parent = element.closest('.ds-day');
          if (parent) {
            const dataId = parent.getAttribute('data-id');
            const top = element.style.top;
            const groupKey = dataId + '-' + top;
            if (!groups[groupKey]) {
              groups[groupKey] = [];
            }
            groups[groupKey].push(element);
          }
        });

        // Iterate through groups and set the new width value
        for (const groupKey in groups) {
          const group = groups[groupKey];
          let left = 0;
          if (group.length > 1) {
              const newWidth = 100 / group.length;
              group.forEach((element) => {
                element.style.left = left + '%';
                element.style.width = newWidth + '%';
                left += newWidth;
              });
          } else {
            group.forEach((element) => {
              element.style.left = '0';
              element.style.width = '100%';
            });
          }
        }
    },

    close() {
      this.menu = false;
    },

    getName(arr = []) {
      if (arr.length) {
        const names = arr.map((item) => {
          if (item?.name) {
            const matchingName = item.name.find(
              (name) => name.lang === "en" || name.lang === "ru"
            );
            if (matchingName) {
              return matchingName.name;
            }
          }
          return "-";
        });
        return names.join(", ");
      } else {
        return "-";
      }
    },
    
    editCheck($event) {
      // checking if event's End Date and current Date not have days difference then open edit modal
      const endDate = this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate.toString(), 'DD-MM-YYYY');
      const currentTime = this.$moment(this.newDateWithMainUserTZ);
      
      // Compare the day of endDate with the current day
      if (
        !endDate.isBefore(currentTime, 'day') &&
        this.calendarEvent?.event?.data?.eventData?.state !== 'cancel'
      ) {
        if (this.handlesEvents($event)) {
          if (this.hasPopover) {
            this.menu = !this.menu;
          }
        }
      }     
    },
    
    camelCaseToTitleCase(camelCase = '') {
      // Split the camelCase string into words
      const words = camelCase.split(/(?=[A-Z])/);
      
      // Capitalize the first letter of each word and join them with a space
      return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },

    mouseEnterEvent($event) {
      const endDate = this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate.toString(), 'DD-MM-YYYY hh:mm a');
      const currentTime = this.$moment(this.newDateWithMainUserTZ);

      if (this.calendarEvent?.event?.data?.eventData?.state === 'cancel' || endDate.isBefore(currentTime, 'day')) {
        $event.stopPropagation()
      }

      if (
        this.handlesEvents($event) && 
        this.calendarEvent?.event?.data?.eventData?.state !== 'cancel' &&
        !endDate.isBefore(currentTime, 'day')
      ) {
        this.$emit(
          "mouse-enter-event",
          this.getEvent("mouse-enter-event", $event)
        );
      }
    },

    mouseLeaveEvent($event) {
      const endDate = this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate.toString(), 'DD-MM-YYYY hh:mm a');
      const currentTime = this.$moment(this.newDateWithMainUserTZ);

      if (this.calendarEvent?.event?.data?.eventData?.state === 'cancel' || endDate.isBefore(currentTime, 'day')) {
        $event.stopPropagation()
      }
      
      if (
        this.handlesEvents($event) && 
        this.calendarEvent?.event?.data?.eventData?.state !== 'cancel' && 
        !endDate.isBefore(currentTime, 'day')
      ) {
        this.$emit(
          "mouse-leave-event",
          this.getEvent("mouse-leave-event", $event)
        );
      }
    },

    mouseDownEvent($event) {
      const endDate = this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate.toString(), 'DD-MM-YYYY hh:mm a');
      const currentTime = this.$moment(this.newDateWithMainUserTZ);

      if (this.calendarEvent?.event?.data?.eventData?.state === 'cancel' || endDate.isBefore(currentTime, 'day')) {
        $event.stopPropagation()
      }

      if (
        this.handlesEvents($event) && 
        this.calendarEvent?.event?.data?.eventData?.state !== 'cancel' &&
        !endDate.isBefore(currentTime, 'day')
      ) {
        this.$emit(
          "mouse-down-event",
          this.getEvent("mouse-down-event", $event)
        );
      }
    },

    mouseUpEvent($event) {
      const endDate = this.$moment(this.calendarEvent?.event?.data?.eventData?.endDate.toString(), 'DD-MM-YYYY hh:mm a');
      const currentTime = this.$moment(this.newDateWithMainUserTZ);

      if (this.calendarEvent?.event?.data?.eventData?.state === 'cancel' || endDate.isBefore(currentTime, 'day')) {
        $event.stopPropagation()
      }

      if (
        this.handlesEvents($event) && 
        this.calendarEvent?.event?.data?.eventData?.state !== 'cancel' &&
        !endDate.isBefore(currentTime, 'day')
      ) {
        this.$emit("mouse-up-event", this.getEvent("mouse-up-event", $event));
      }
    },

    handlesEvents($event) {
      var handles = !this.isPlaceholderWithDay;

      if (handles && $event) {
        $event.stopPropagation();
      }

      return handles;
    },

    getEvent(type, $event, extra = {}) {
      return fn.extend(
        {
          type: type,
          calendarEvent: this.calendarEvent,
          calendar: this.calendar,
          details: this.details,
          offset: this.getRelativeTimeAt($event),
          left: $event.button === 0,
          right: $event.button === 1,
          handled: false,
          $event: $event,
          $vm: this,
          $element: this.$el,
        },
        extra
      );
    },

    getRelativeTimeAt($event) {
      var bounds = this.$el.getBoundingClientRect();
      var y = ($event.clientY - bounds.y) / this.$dayspan.dayHeight;
      var millis = y * Constants.MILLIS_IN_DAY;

      return millis;
    },
  },
};
</script>

<style scoped lang="scss">
.ds-calendar-event-menu {
  width: 100%;
}

.ds-calendar-event-menu {
  transition: all 0.4s;
}

.ds-calendar-event {
  margin: 1px;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.5em;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  user-select: none;
  border-radius: 2px;
  pointer-events: none;

  .v-menu__activator {
    align-items: end;
  }

  .ds-calendar-event-span {
    width: 100%;
    height: 100%;
    pointer-events: all;
  }

}

.P-event-table-cont {
  border-radius: 6px;
}

.ds-ev-description {
  margin-top: -3px;
}
</style>

<style lang="scss">
@import "src/assets/scss/variables";

.v-popper, .v-popper__inner, .v-popper__wrapper, .v-popper__popper {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: unset !important;
  width: max-content !important;
  border-radius: 6px; 

}

.v-popper__inner {
  width: 100%;
  height: 100%;
}
.v-popper__inner > div {
  opacity: 1;
  color: black;
  margin: 0;
  padding: 0;
  font-size: 16px;
  border-radius: 6px;
  font-weight: bold;
}

.v-popper__popper:has(.bg-white) {
  & .v-popper__inner > div {
    background-color: white;
  }
}

.v-popper__popper:has(.bg-yellow) {
  & .v-popper__inner > div {
    background-color: #fff4d9;
  }
}

.v-popper__popper:has(.bg-green) {
  & .v-popper__inner > div {
    background-color: #e7f8ed;
  }
}

.v-popper__popper:has(.bg-red) {
  & .v-popper__inner > div {
    background-color: #fee1e0;
  }
} 

.v-popper__arrow-container {
  display: none;
}

.max-w-250 {
  max-width: 250px !important;
  word-break: break-word !important;
}

.P-comments-parent {
  max-height: 99px;
  overflow-y: auto;
}

.P-event-comment {
  &__user-name {
    color: $primary-color;
    font-size: $txt14;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px !important;
  }

  & .canceled {
    border: 2px solid red;
    padding: 5px;
    border-radius: 5px;
    width: max-content;
    color: red;
    text-shadow: 1px 1px 1px white;
    font-size: 15px;
    margin-top: 8px;
  }

  hr {
    margin-top: 8px;
    margin-bottom: 8px;
  }  
}
</style>


