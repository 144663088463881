import RequestService from "@/services/request.service";

const InitialState = {
 formData:{
   email:'',
   password:'',
   confirmPassword:''
 },
  isValidate:false

};

export const securityLogin = {
  namespaced: true,
  actions: {

  },

  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    /***
     * for all second level object keys changing
     *  setDataSecondLevels({ 
     *    stateKey1: { changingKey: 'newVal' },
     *    stateKey2: { changingKey2: 'newVal' },  
     *  })
    **/
    setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if(state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },

    /**
     * Fetch Data
     * **/

    fetchData(state, data){
      state.formData.email = data.email
    },

    resetData(state){
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
      state.isValidate = false
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
