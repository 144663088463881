<template>
  <div class="auth-cont ">
    <auth-header />
    <div class="auth-body">
      <div
        class="main-icon"
        :style="{
          backgroundImage:
            `url(${require('@/assets/images/designImages/approved.svg')})`
        }"
      />
      <h6>Approved</h6>
      <p class="description">
        Congratulations, your account has been approved
      </p>
      <router-link to="/auth/sign-in" class="sign-in-btn">Sign in </router-link>
    </div>

    <auth-footer />
  </div>
</template>

<script>
import AuthFooter from "./components/authFooter";
import AuthHeader from "./components/authHeader";

export default {
  name: "RegisterApproved",
  components: {
    AuthHeader,
    AuthFooter
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/authGeneral";
.description {
  padding: 15px 0 30px;
}
.sign-in-btn {
  padding: 10px 26px;
  background-color: $green;
  color: $white;
  font-size: $txt16;
  font-weight: $semiBold;
  border-radius: $border-radius;
  &:hover {
    background-color: $green-strong;
  }
}
</style>
