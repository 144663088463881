
const InitialState = {
  formData: {
    image: null,
    avatar:'',
    firstname: '',
    lastname: '',
    phone: '',
    accessEnable: true,
    showUnsuccessfulTransactions: true,
    color: null,
    address: ''
  },
  imagePath: '',
  cloneImagePath:'',
  isValidate:false,
};

export const generalInfoUser = {
  namespaced: true,
  actions: {},

  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    /**
     * for all first level keys changing  
     * setData({ key: 'value' })
    */
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    setFormData(state, data) {
      for (const key in data) {
        state.formData[key] = data[key]
      }
    },

    /**
     * Fetch data
     * **/
    fetchData(state, data){
      state.formData.firstname = data.firstname
      state.formData.lastname = data.lastname
      state.formData.address = data?.address
      state.formData.color = typeof data.color === 'string' ? data.color : data.color?.hex
      if(data.avatar){
        state.formData.avatar = data.avatar
        state.cloneImagePath = JSON.parse(JSON.stringify(data.avatar))
      }
      state.formData.phone = data.phone.phoneNumber
      state.formData.accessEnable = data.accessEnable
      state.formData.showUnsuccessfulTransactions = data.showUnsuccessfulTransactions
    },

    /**
     * Reset data  when closed modal
     * **/

    resetData(state){
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
      state.imagePath = '';
      state.isValidate = false

    },

    /**
     * get image path after cancel image clip
     * **/
    getImagePat(state){
      state.imagePath = state.cloneImagePath
    },

    /**
     * Create image path from edit  user
     * **/
    createImagePath(state){
      if(state.formData.avatar){
        state.imagePath =`${process.env.VUE_APP_API_URL}files/icon/${state.formData.avatar}`
      }
    },

    setImagePath(state, value) {
      state.imagePath = value
    },

    /**
     * Reset upload Image
     * **/

    resetUploadImage(state,){
      state.formData.image = null
      state.imagePath = ''
    },


    /**
     * set data generation info
     * **/
    setAvatarData(state, data) {
      state.formData.avatar = data
    },

   
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
