import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import colors from "../assets/scss/colors";

Vue.use(Vuetify);

/**
 *  Use vuetify plugin
 *  @main.js
 *  Change colors for project by vuetify
 *  example.
 *  @customStyleName: color type,
 *  color types it must be a color.js file for export  colors objects
 *  in html usage
 *  @primary--text for colors
 *  @primary for background-colors
 */

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: colors.primary.primaryMain,
        "primary-dark": colors.primary.primaryDark,
        "primary-light": colors.primary.primaryLight,
        secondary: colors.secondary.secondaryMain,
        "secondary-dark": colors.secondary.secondaryDark,
        "secondary-light": colors.secondary.secondaryLight,
        "secondary-66": colors.secondary.secondary66,
        "secondary-07": colors.secondary.secondary07,
        white: colors.constColors.white,
        black: colors.constColors.black,
        neutral: colors.neutral.neutralMain,
        "neutral-color-75": colors.neutral.neutralColor75,
        "neutral-color-45": colors.neutral.neutralColor45,
        "neutral-color-25": colors.neutral.neutralColor25,
        "neutral-color-15": colors.neutral.neutralColor15,
        "neutral-color-10": colors.neutral.neutralColor10,
        "neutral-color-7": colors.neutral.neutralColor7,
        "error-dark": colors.danger.dangerDark,
        "error-light": colors.danger.dangerLight,
        "light-blue-darker": colors.badge.lightBlueDarker,
        "light-blue-lighter": colors.badge.lightBlueLighter,
        "green-darker": colors.badge.greenDarker,
        "green-lighter": colors.badge.greenLighter,
        "gray-lighter": colors.badge.grayLighter,
        "gray-darker": colors.badge.grayDarker,
        "red-lighter": colors.badge.redLighter,
        "red-darker": colors.badge.redDarker,
        "purple-darker": colors.badge.purpleDarker,
        green: colors.other.green,
        red: colors.other.red,
        "purple-lighter": colors.badge.purpleLighter,
        "page-background": colors.constColors.white,
        "page-background-dark": colors.constColors.white,
        "page-background-side-bar": colors.secondary.secondaryMain,
        modalBackground: colors.other.modalBackgroundLM,
        mainBackground: colors.other.mainBackground,
        tooltipBgColor: colors.other.tooltipBgColor,
        "P-background-neutral7": colors.neutral.neutralColor7,
        "neutral-color-link": colors.neutral.neutralColorLink
      },
      dark: {
        primary: colors.constColors.white,
        "primary-dark": colors.badge.redLighter,
        secondary: colors.constColors.white,
        "page-background-side-bar": colors.darkColors.secondaryMainDark,
        "secondary-66": colors.darkColors.secondary66Dark,
        "page-background": colors.darkColors.secondaryMainDark,
        "page-background-dark": colors.darkColors.darkBold,
        neutral: colors.neutral.neutralMainDark,
        modalBackground: colors.other.modalBackgroundDM,
        red: colors.other.redMD,
        mainBackground: colors.other.mainBackgroundDM,
        tooltipBgColor: colors.other.tooltipBgColorDM,
        "neutral-color-15": colors.neutral.neutralColor15DM,
        "neutral-color-link": colors.darkColors.darkColorLink,
        secondary66Dark: colors.darkColors.secondary66Dark
      }
    }
  }
});
