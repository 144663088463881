const Error404 = () => import("@/views/auth/Error404");

export const errorRout = {
  path: "/error",
  name: "Error",
  component: {
    render(c) {
      return c("router-view");
    }
  },
  children: [
    {
      path: "nor-found",
      name: "Page not  fount",
      component: Error404
    }
  ]
};
