export const transactionStatus = [
    {
        name: 'Reject',
        value: 0
    },
    {
        name: 'Approve',
        value: 1
    },
    {
        name: 'Void',
        value: 5
    },
]
export const transactionType = [
    {
        name: 'Balance To Balance',
        value: 'B_TO_B'
    },
    {
        name: 'Balance Fill (To Balance)',
        value: 'TO_B'
    },
    {
        name: 'Cash',
        value: 'CASH'
    },
    {
        name: 'Check',
        value: 'CH_TO_B'
    },
    {
        name: 'Money Order',
        value: 'MO_TO_B'
    },
    {
        name: 'Credit Card/Bank',
        value: 'C_TO_A'
    },
]
export const initiatorType = [
    {
        name: 'Self',
        value: 0
    },
    {
        name: 'Parent',
        value: 1
    },
    {
        name: 'Client',
        value: 2
    },
    {
        name: 'Reseller',
        value: 3
    }
]

export const incomeType = [
    {
        name: 'Card',
        value: 0
    },
    {
        name: 'Bank',
        value: 1
    },
    {
        name: 'Cash',
        value: 2
    },
    {
        name: 'Check',
        value: 3
    },
    {
        name: 'MoneyOrder',
        value: 4
    }
]

export const paymentMethods = [
    {
        name: 'All',
        value: 0
    },
    {
        name: 'Card',
        value: 1
    },
    {
        name: 'Bank',
        value: 2
    },
    {
        name: 'Cash',
        value: 3
    }
]
