export default class RegistrationFormModel {
  constructor() {
    this.resetData();
  }

  fetch(data) {
    if (data.firstname) this.firstname = data.firstname;
    if (data.lastname) this.lastname = data.lastname;
    if (data.email) this.email = data.email;
    if (data.password) this.password = data.password;
    if (data.companyName) this.companyName = data.companyName;
    if (data.companyName) this.companyName = data.companyName;
    if (data.companyEmail) this.companyEmail = data.companyEmail;
    if (data.website) this.website = data.website;
    if (data.phone) this.phone = data.phone;
    if (data.clientAmount) this.clientAmount = data.clientAmount;
    if (data.description) this.description = data.description;
    if (data.description) this.description = data.description;
  }
  toSave() {
    return {
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      password: this.password,
      companyName: this.companyName,
      companyEmail: this.companyEmail,
      website: this.website,
      phone: this.phone,
      clientAmount: this.clientAmount,
      channelAmount: this.channelAmount,
      description: this.description
    };
  }

  resetData() {
    this.firstname = "";
    this.lastname = "";
    this.email = "";
    this.password = "";
    this.companyName = "";
    this.companyEmail = "";
    this.website = "";
    this.phone = "";
    this.clientAmount = null;
    this.channelAmount = "";
    this.description = "";
  }
}
