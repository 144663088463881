import axios from "axios";

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2MGI3OGIzMmUxOTE2YzFlNDIxZWJiNjEiLCJpYXQiOjE2MjI3Mjg2NTMsImV4cCI6MTY0MjcwODY1M30.F2FhevQfSjDiWWn79xL47Ir_LZz7GHQC23kiy4vHJ2c";
//http://66.175.219.7:3000/v1/ottProviders/registration?limit=30&state=0
//http://66.175.219.7:3000/v1/ottProviders/registration

const backUrl = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : 'http://127.0.0.1:3000/v1/';

export const API = axios.create({
  baseURL: backUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  }
});
API.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
API.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
