<template>
  <div class="Ott-select-cont" :class="selectClass">
    <v-autocomplete
      v-model="selected"
      :items="items"
      :item-text="selectedText"
      :height="height"
      :menu-props="{ maxHeight: 150, contentClass }"
      :disabled="disabled"
      :attach="true"
      :label="label"
      :ripple="false"
      :error="error"
      dense
      outlined
      @change="selectChange"
      :clearable="clear"

    >
      <template slot="selection" slot-scope="data">
        <div class="v-select__selection  v-select__selection--comma">
          {{ data.item[selectedText] }}
        </div>
      </template>
      <template slot="item" slot-scope="data">
        <div class="v-select__selection  v-select__selection--comma">
          {{ data.item[selectedText] }}
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
  export default {
    name: "OttSelectAutocomplete",
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      clear: {
        type: Boolean,
        default: false
      },
      contentClass: {
        type: String,
        default: ""
      },
      items: {
        type: Array
      },
      selectClass: {
        type: String
      },
      itemText: {
        type: String
      },
      optionText: {
        type: String
      },
      selectedText: {
        type: String
      },
      itemValue: {
        type: String
      },
      object: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: "Label"
      },
      height: {
        type: Number,
        default: 38
      },
      outlined: {
        type: Boolean,
        default: true
      },
      error: {
        type: Boolean,
        default: false
      },
      appendedIcon: {
        type: String
      },
      value: {}
    },
    data() {
      return {
        selected: null,
        selectedRel: null
      };
    },
    watch: {
      value(data) {
        this.selected = data;
      }
    },
    mounted() {
      this.selected = this.value;
    },
    methods: {
      selectChange(value) {
        this.$emit("input", value);
        this.$emit("emitFunc");
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";

  .Ott-select-cont::v-deep {
    .v-input__icon--clear {
      .v-icon {
        color     : $neutral-color;
        font-size : $txt16 !important;
      }
    }

    input {
      color       : #0a3c68 !important;
      font-size   : 14px !important;
      font-weight : 400 !important;
    }

    .v-input--has-state.error--text .v-label {
      -webkit-animation : v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
      animation         : v-shake 0s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }

    .v-menu__content {
      margin-top : 0;
    }

    .v-text-field--outlined.v-input--dense.v-text-field--outlined
    > .v-input__control
    > .v-input__slot {
      min-height : 30px !important;
    }

    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      padding : 0 18px;
    }

    .v-text-field--outlined .v-input__slot fieldset {
      padding-left : 14px !important;
    }

    .v-list {
      padding : 0 !important;
    }

    .v-select__selections {
      flex-wrap : nowrap !important;
    }

  }

  .theme--light::v-deep {
    &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color : $neutral-color;
    }

    .v-label {
      font-size      : $txt14;
      color          : $neutral-color;
      text-transform : capitalize !important;
    }

    .mdi-menu-down {
      color : $neutral-color;
    }

    .v-select__selection--comma {
      color       : #0a3c68 !important;
      font-size   : 14px !important;
      font-weight : 400 !important;
    }
  }

  .theme--dark::v-deep {
    input {
      color       : white !important;
      font-size   : 14px !important;
      font-weight : 400 !important;
    }

    &.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color : $neutral-colorDM;
    }

    .v-label {
      font-size : $txt14;
      color     : $neutral-colorDM;
    }

    .mdi-menu-down {
      color : $neutral-colorDM;
    }

    .v-select__selection--comma {
      color       : $white !important;
      font-size   : $txt14 !important;
      font-weight : 400 !important;
    }
  }
</style>
<style lang="scss">
  @import "./src/assets/scss/variables";
  @import "./src/assets/scss/mixins";

  .v-menu__content {
    border-top-left-radius  : 0 !important;
    border-top-right-radius : 0 !important;
    border                  : none !important;
    @include box-shadow-not-top;
    max-width               : 100% !important;

    .v-select-list {
      padding : 0;
    }

    .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color       : #0a3c68 !important;
      font-size   : $txt14 !important;
      font-weight : 400 !important;
    }

    .theme--light.v-select .v-select__selection--comma {
      color       : #0a3c68 !important;
      font-size   : $txt14 !important;
      font-weight : 400 !important;
    }

    .v-select__selection--comma {
      color       : #0a3c68 !important;
      font-size   : $txt14 !important;
      font-weight : 400 !important;
    }

    .v-select__selection {
      color       : #0a3c68 !important;
      font-size   : $txt14 !important;
      font-weight : 400 !important;
    }
  }


  .theme--dark {
  }
</style>
