<template>
  <selected-filters
    :selectedList="selectedList"
    @resetData="resetData($event)"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  name: "SelectedFiltersList",
  components: { SelectedFilters },
  mixins: [DateFormatMixin],

  computed: {
    ...mapState({
      filterModel: (state) => state.transactionsModule.filterModel,
      transactionsModule: (state) => state.transactionsModule,
      filter: (state) => state.transactionsModule.filterData,
      resellersList: (state) => state.providers.resellersList,
      resellersWithParent: (state) => state.providers.resellersWithParent,
    }),

    getToProviders() {
      let resellers = [];

      if (this.resellersWithParent.length) {
        resellers = this.resellersWithParent
      } else {
        resellers = this.resellersList
      }

      let providersNames = '<span>'
      if (resellers.length) {
        resellers.map((i) => {
          this.filterModel.toProvider.map((j) => {
            if(i.id === j) {
              providersNames += `${i.name}${this.filterModel.toProvider.length > 1 ? '<br />' : ''}`
            }
          })
        })
      }
      providersNames += '</span>'

      return providersNames;
    },

    getResellers() {
      let providersNames = '<span>'
      if (this.resellersList.length) {
        this.resellersList.map((i) => {
          this.filterModel.resellers.map((j) => {
            if (i.id === j) {
              providersNames += `${i.name}${this.filterModel.resellers.length > 1 ? '<br />' : ''}`
            }
          })
        })
      }
      
      providersNames += '</span>'

      return providersNames;
    },

    selectedList() {
      return [
        {
          ifCondition: this.filterModel.search.trim(),
          boxText: "<b>Search</b>: " + this.filterModel.search,
          resetArgument: "search",
        },
        {
          ifCondition: this.filterModel.resellers.length,
          boxText: "<div class='G-flex-center'><span class='me-2'><b>Resellers</b>:</span> " + this.getResellers,
          resetArgument: "resellers",
        },
        {
          ifCondition: this.filterModel.allResellers,
          boxText: "<b>All</b>: Yes",
          resetArgument: "allResellers",
        },
        {
          ifCondition: this.filterModel.inOut ,
          boxText: `<b>In / Out</b>: <span class="text-capitalize">${this.filterModel.inOut}</span>`,
          resetArgument: "inOut",
        },
        {
          ifCondition: this.filterModel.executionDate?.start && this.filterModel.executionDate?.end,
          boxText: "<b>Execution Date</b>: " + this.getDate(this.filterModel.executionDate?.start) + ' - ' + this.getDate(this.filterModel.executionDate?.end),
          resetArgument: "executionDate",
        },
        {
          ifCondition:
            !!this.filterModel.transactionState ||
            this.filterModel.transactionState === 0,
          boxText: "<b>State</b>: " + this.getSelectedData("transactionState"),
          resetArgument: "transactionState",
        },
        {
          ifCondition: this.filterModel.fee !== null,
          boxText: `<b>Fees</b>: ${ 
            this.filterModel.fee === 'none' ? 'None' : 
            this.filterModel.fee === true ? 'With Fee' : 
            this.filterModel.fee === false ? 'Without Fee' : ''
          }`,
          resetArgument: "fee",
        },
        {
          ifCondition: this.filterModel.isRefund === false || this.filterModel.isRefund === true,
          boxText: `<b>Is Refund</b>: ${ this.filterModel.isRefund ? 'Refund' : 'Buy' }`,
          resetArgument: "isRefund",
        },
        {
          ifCondition: this.filterModel.toProvider.length ,
          boxText: "<div class='G-flex-center'><span class='me-2'><b>To Providers</b>:</span> " + this.getToProviders,
          resetArgument: "toProvider",
        },
        {
          ifCondition:
            !!this.filterModel.transactionType || this.filterModel.transactionType === 0,
          boxText: "<b>Type</b>: " + this.getSelectedData("transactionType"),
          resetArgument: "transactionType",
        },
        {
          ifCondition: this.filterModel.merchant && typeof this.filterModel.merchant === 'string',
          boxText: "<b>Payment Gateway</b>: " +  this.filterModel.merchant.charAt(0).toUpperCase() + this.filterModel.merchant.slice(1),
          resetArgument: "merchant",
        },
        {
          ifCondition:
            this.filterModel.autopayment,
          boxText: "<b>Autopayment</b>: Yes",
          resetArgument: "autopayment",
        },
        {
          ifCondition: !!(
            this.filterModel.transactionAmount[0] || this.filterModel.transactionAmount[1]
          ),
          boxText: "<b>Total Amount</b>: " + this.getTotalAmountValue(),
          resetArgument: "transactionAmount",
        },
        {
          ifCondition:
            !!this.filterModel.initiatorType || this.filterModel.initiatorType === 0,
          boxText: "<b>Initiator Type</b>: " + this.getSelectedData("initiatorType"),
          resetArgument: "initiatorType",
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      getTransactionList: "transactionsModule/getTransactionList",
    }),
    ...mapMutations({
      filterTransactions: "transactionsModule/filterTransactions"
    }),

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterTransactions()
      await this.getTransactionList(this.filter);
    },

    getDate(date) {
      return this.$moment(date).format(this.ottDateFormat).valueOf();
    },

    getSelectedData(type) {
      switch (type) {
        case "transactionState": {
          if (
            this.filterModel.transactionState ||
            this.filterModel.transactionState === 0
          ) {
            return this.transactionsModule.transactionStatus.filter(
              (x) => x.value === this.filterModel.transactionState
            )[0].name;
          }
          break;
        }
        case "transactionType": {
          if (
            this.filterModel.transactionType ||
            this.filterModel.transactionType === 0
          ) {
            return this.transactionsModule.transactionType.filter(
              (x) => x.value === this.filterModel.transactionType
            )[0].name;
          }
          break;
        }
        case "initiatorType": {
          if (
            this.filterModel.initiatorType ||
            this.filterModel.initiatorType === 0
          ) {
            return this.transactionsModule.initiatorType.filter(
              (x) => x.value === this.filterModel.initiatorType
            )[0].name;
          }
          break;
        }
      }
    },
    getTotalAmountValue() {
      if (
        this.filterModel.transactionAmount[0] ||
        this.filterModel.transactionAmount[1]
      ) {
        return (
          "Transaction Amount: " +
          this.filterModel.transactionAmount[0] +
          " - " +
          this.filterModel.transactionAmount[1]
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.active-filter-section {
  margin-top: 20px;

  h6 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }

  .active-filter-body {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-top: 15px;

    .active-filter-item {
      display: flex;
      justify-content: space-between;
      padding: 2px 10px;
      margin: 7.5px;
      border-radius: 20px;
      min-width: 100px;

      p {
        margin-bottom: 0;
        font-size: $txt14;
        font-weight: $regular;
        margin-right: 5px;
      }

      span {
        font-size: $txt14;
      }
    }
  }
}
</style>
