export default class ClientBillsFilterModel {
  constructor() {
    this.reset();
  }

  toSave() {
    return {};
  }

  reset() {
    this.search = '';
    this.sentUnsent = null;
    this.providerId = null;
    this.clientId = ''  //for  click  on  table client
    this.subscriptionAmount = [0, 0];
    this.totalAmount = [0, 0];
    this.dateForPay = {
      start: null,
      end: null
    };
    this.autoPayment = null;
    this.paymentStatus = [];
    this.paymentActonBy = null;
    this.paymentActionProviderName = '' // for  click  on table Payment action name
    this.paymentActionUserName = '' //for click on table Payment action  user name,
    this.loginId = '' //for click on table Payment action login
    this.paymentMethod = [];
    this.dateForPayment = {
      start: null,
      end: null
    };
    this.billSentMethod = [];
    this.dateForBillSent = {
      start: null,
      end: null
    };
    this.resellerId = null;
    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);
    this.filterCount.splice(index, 1);
    switch (dataKey) {
      case "search": {
        this.search = "";
        break;
      }
      case "sentUnsent": {
        this.sentUnsent = null;
        break;
      }
      case "providerId": {
        this.providerId = null
      }
      case "clientId": {
        this.clientId = "";
        break;
      }
      case "subscriptionAmount": {
        this.subscriptionAmount = [0, 0];
        break;
      }
      case "totalAmount": {
        this.totalAmount = [0, 0];
        break;
      }
      case "dateForPay": {
        this.dateForPay = {
          start: null,
          end: null
        };
        break;
      }
      case "autoPayment": {
        this.autoPayment = null;
        break;
      }
      case "paymentStatus": {
        this.paymentStatus = [];
        break;
      }
      case "paymentActonBy": {
        this.paymentActonBy = null;
        break;
      }
      case "paymentActionProviderName": {
        this.paymentActionProviderName = "";
        break;
      }
      case "paymentActionUserName": {
        this.paymentActionUserName = "";
        break;
      }

      case "paymentMethod": {
        this.paymentMethod = [];
        break;
      }
      case "dateForPayment": {
        this.dateForPayment = {
          start: null,
          end: null
        };
        break;
      }
      case "billSentMethod": {
        this.billSentMethod = [];
        break;
      }
      case "dateForBillSent": {
        this.dateForBillSent = {
          start: null,
          end: null
        };
        break;
      }
      case "resellerId": {
        this.resellerId = null;
        break;
      }
    }
  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes("search")) {
      this.filterCount.push("search");
    }
    if (this.sentUnsent !== null && !this.filterCount.includes("sentUnsent")) {
      this.filterCount.push("sentUnsent");
    }
    if (this.providerId !== null && !this.filterCount.includes("providerId")) {
      this.filterCount.push("providerId");
    }
    if (this.clientId.trim() && !this.filterCount.includes("clientId")) {
      this.filterCount.push("clientId");
    }
    if (
      (this.subscriptionAmount[0] || this.subscriptionAmount[1]) &&
      !this.filterCount.includes("subscriptionAmount")
    ) {
      this.filterCount.push("subscriptionAmount");
    }
    if (
      (this.totalAmount[0] || this.totalAmount[1]) &&
      !this.filterCount.includes("totalAmount")
    ) {
      this.filterCount.push("totalAmount");
    }

    if (this.dateForPay?.start && this.dateForPay?.end && !this.filterCount.includes("dateForPay")) {
      this.filterCount.push("dateForPay");
    }

    if (this.autoPayment && !this.filterCount.includes("autoPayment")) {
      this.filterCount.push("autoPayment");
    }
    if (this.paymentStatus.length && !this.filterCount.includes("paymentStatus")) {
      this.filterCount.push("paymentStatus");
    }
    if (this.paymentActonBy && !this.filterCount.includes("paymentActonBy")) {
      this.filterCount.push("paymentActonBy");
    }
    if (this.paymentActionProviderName.trim() && !this.filterCount.includes("paymentActionProviderName")) {
      this.filterCount.push("paymentActionProviderName");
    }
    if (this.paymentActionUserName.trim() && !this.filterCount.includes("paymentActionUserName")) {
      this.filterCount.push("paymentActionUserName");
    }
    if (this.paymentMethod.length && !this.filterCount.includes("paymentMethod")) {
      this.filterCount.push("paymentMethod");
    }
    if (this.dateForPayment?.start && this.dateForPayment?.end && !this.filterCount.includes("dateForPayment")) {
      this.filterCount.push("dateForPayment");
    }
    if (this.billSentMethod.length && !this.filterCount.includes("billSentMethod")) {
      this.filterCount.push("billSentMethod");
    }
    if (this.dateForBillSent?.start && this.dateForBillSent?.end && !this.filterCount.includes("dateForBillSent")) {
      this.filterCount.push("dateForBillSent");
    }
    if (this.resellerId && !this.filterCount.includes("resellerId")) {
      this.filterCount.push("resellerId");
    }
  }

}
