import ClientBillsFilterModel from "@/models/client-bills/ClientBillsFilterModel";
import RequestService from "@/services/request.service";
import moment from "moment";
import _ from "lodash";
const FileDownload = require("js-file-download");

import {
  autoPaymentList,
  billInfoTypeList,
  billSentMethodList,
  paymentActonByList,
  paymentMethodList,
  paymentStatusList,
} from "./filterCustomist";
import { defaultColumnConfig } from "../discount/defaultColumnConfig";

const InitialState = {
  filterModel: new ClientBillsFilterModel(),
  filterRangeData: {
    subscriptionAmount: [0, 1000],
    totalAmount: [0, 1000],
  },

  autoPaymentList: autoPaymentList,
  paymentStatusList: paymentStatusList,
  paymentActonByList: paymentActonByList,
  paymentMethodList: paymentMethodList,
  billSentMethodList: billSentMethodList,
  billInfoTypeList: billInfoTypeList,

  // filter data
  filter: {
    limit: 20,
    searchLimit: 20,
    sortBy: "_id:desc",
    page: 1,
  },
  //  for  pagination  table
  dataCount: 0,
  currentPage: 1,
  totalPages: 0,
  filterData: {},

  // for  update  table  columns
  defaultSettings: [],
  cloneDefaultSettings: defaultColumnConfig,
  updateColumnsFromBack: "clientBillsModule/updateClientBillsSettings",
  updateColumnSettingsCommit: "clientBillsModule/setDefaultSettings",
  isDefault: false,

  dataTable: [],
  clientBillsResult: false,
  isTableLoading: true,
  resellerClients: false,
  clientBills: [],
  showColumnConfig: true,
  sent: null,
  queue: null,
  autoSendMilliseconds: null,
  sentFromSocket: null,
  queueFromSocket: null,
  totalSum: 0,
  postalMethodsBalance: null
};

/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  In the mutations part there is resetState(), 
  please new added variables add in that function as well
*/

export const clientBillsModule = {
  namespaced: true,
  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue;
    },

    setSearchLimit(state, newValue) {
      state.filter.searchLimit = newValue;
    },

    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault;
      }
    },

    setDefaultSettingsForBack(state, data) {
      if (data.clientBillsSettings) {
        state.isDefault = data.clientBillsSettings.isDefault;
        state.defaultSettings = data.clientBillsSettings.clientBillsTable;
      }
    },

    /**
     * Set client bills data
     */
    setClientBillsData(state, data) {
      state.dataTable = data.results;
      state.currentPage = data.page;
      state.totalPages = data.totalPages;
      state.dataCount = data.totalResults;
    },
    resetState(state) {
      state.filterModel = new ClientBillsFilterModel()
      state.filterRangeData = {
        subscriptionAmount: [0, 1000],
        totalAmount: [0, 1000],
      }
    
      state.autoPaymentList = autoPaymentList
      state.paymentStatusList = paymentStatusList
      state.paymentActonByList = paymentActonByList
      state.paymentMethodList = paymentMethodList
      state.billSentMethodList = billSentMethodList
      state.billInfoTypeList = billInfoTypeList
    
      // filter data
      state.filter = {
        limit: 20,
        searchLimit: 20,
        sortBy: "_id:desc",
        page: 1,
      }
      //  for  pagination  table
      state.dataCount = 0
      state.currentPage = 1
      state.totalPages = 0
      state.filterData = {}
    
      // for  update  table  columns
      state.defaultSettings = []
      state.cloneDefaultSettings = defaultColumnConfig
      state.updateColumnsFromBack = "clientBillsModule/updateClientBillsSettings"
      state.updateColumnSettingsCommit = "clientBillsModule/setDefaultSettings"
      state.isDefault = false
    
      state.dataTable = []
      state.clientBillsResult = false
      state.isTableLoading = true
      state.resellerClients = false
      state.clientBills = []
      state.showColumnConfig = true
      state.sent = null
      state.queue = null
      state.autoSendMilliseconds = null
      state.sentFromSocket = null
      state.queueFromSocket = null
      state.totalSum = 0,
      state.postalMethodsBalance = null
    },

    /**
     * Set pagination filter
     * **/
    setFilterPage(state, page) {
      state.filter.page = page;
      state.currentPage = page;
    },

    filterClientBills(state) {
      const filterObject = {}
      if (state.filterModel.search) {
        filterObject.search = state.filterModel.search
      }
      if (state.filterModel.sentUnsent !== null && state.filterModel.sentUnsent !== 'all') {
        filterObject.sent = state.filterModel.sentUnsent
      }
      if (state.filterModel.providerId) {
        filterObject.providerId = state.filterModel.providerId
      }

      if (state.filterModel.subscriptionAmount[1]) {
        filterObject.subscriptionAmountFrom = state.filterModel.subscriptionAmount[0]
        filterObject.subscriptionAmountTo = state.filterModel.subscriptionAmount[1]
      } else {
        delete filterObject.subscriptionAmountFrom
        delete filterObject.subscriptionAmountTo
      }
      if (state.filterModel.totalAmount[1]) {
        filterObject.totalAmountFrom = state.filterModel.totalAmount[0]
        filterObject.totalAmountTo = state.filterModel.totalAmount[1]
      } else {
        delete filterObject.totalAmountFrom
        delete filterObject.totalAmountTo
      }

      // switch (state.filterModel.autoPayment) {
      //   case 1: {
      //     filterObject.autoPayment = state.filterModel.autoPayment
      //     break
      //   }
      //   case 2: {
      //     filterObject.autoPayment = state.filterModel.autoPayment
      //     break
      //   }
      //   case 3: {
      //     filterObject.autoPayment = state.filterModel.autoPayment
      //   }
      // }


      if (state.filterModel.dateForPay?.start && state.filterModel.dateForPay?.end) {
        filterObject.dateForPayStart = 
          moment(state.filterModel.dateForPay.start).format('MM/DD/YYYY');
        filterObject.dateForPayEnd = 
          moment(state.filterModel.dateForPay.end).format('MM/DD/YYYY');
      }
      if (state.filterModel.dateForBillSent?.start && state.filterModel.dateForBillSent?.end) {
        filterObject.dateForBillSentStart = 
          moment(state.filterModel.dateForBillSent.start).format('MM/DD/YYYY');  
        filterObject.dateForBillSentEnd = 
          moment(state.filterModel.dateForBillSent.end).format('MM/DD/YYYY'); 
      }
      if (state.filterModel.dateForPayment?.start && state.filterModel.dateForPayment?.end) {
        filterObject.dateForPaymentStart = 
          moment(state.filterModel.dateForPayment.start).format('MM/DD/YYYY');  
        filterObject.dateForPaymentEnd = 
          moment(state.filterModel.dateForPayment.end).format('MM/DD/YYYY');
      }
      if (state.filterModel.paymentStatus.length) {
        filterObject.paymentStatus = [...state.filterModel.paymentStatus]
      }
      if (state.filterModel.paymentActonBy) {
        filterObject.paymentActonBy = state.filterModel.paymentActonBy
      }
      if (state.filterModel.paymentMethod.length) {
        filterObject.paymentMethod = [...state.filterModel.paymentMethod]
      }
      if (state.filterModel.billSentMethod.length) {
        filterObject.billSentMethod = [...state.filterModel.billSentMethod]
      }
      if (state.filterData?.sortBy) {
        filterObject.sortBy = state.filterData.sortBy
      }
      state.filterData = filterObject
    },

    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filterData?.sortBy) { 
          state.filterData.sortBy = "_id:desc"; 
        }
      }
    },
  },
  actions: {
    getBillsList({ commit, state, rootState }, filter = null) {
      let options = {
        page: state.currentPage,
      };

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      if (rootState.route?.name !== 'Search') {
        options.limit = state.filter.limit
      } else {
        options.limit = state.filter.searchLimit
      }

      const clonedFilter = filter ? _.cloneDeep(filter) : _.cloneDeep(state.filter)
      delete clonedFilter.searchLimit
      delete clonedFilter.limit

      options = Object.assign(options, clonedFilter);

      if (rootState.generalSearch.searchedClientId) {
        options.client = rootState.generalSearch.searchedClientId
      }

      const readOptions = {};
      const isExcelRequest = filter && filter.excel;

      if (isExcelRequest) {
        readOptions.responseType = "blob";
      } else {
        commit("setData", { isTableLoading: true });
      }

      // set sent, queue value default 0
      commit('setData', { 
        sent: null,
        queue: null,
        autoSendMilliseconds: null,
        totalSum: 0
      })

      return RequestService.create('invoices/invoiceFilter', options, RequestService.generateToken(), readOptions)
        .then((response) => {
          if (!isExcelRequest) {
            if (response.data.results && response.data.results.length) {
              commit("setData", (state.clientBillsResult = true));
            } else {
              commit("setData", (state.clientBillsResult = false));
            }

            commit("setClientBillsData", response.data);
            commit("setData", { isTableLoading: false });
          } else {
            FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
          }

          if ('sent' in response.data) {
            commit('setData', { sent: response.data.sent })
          } 

          if ('inQueue' in response.data) {
            commit('setData', { queue: response.data.inQueue })
          } 

          if ('postalInoviceNextSchedule' in response.data) {
            commit('setData', { autoSendMilliseconds: response.data.postalInoviceNextSchedule })
          } 
          
          if ('totalSum' in response.data) {
            commit('setData', { totalSum: response.data.totalSum })
          }

          if ('postalMethodsBalance' in response.data) {
            commit('setData', { postalMethodsBalance: response.data.postalMethodsBalance })
          }

          return Promise.resolve(response.data);
        })
        .catch((e) => console.log("error ", e))
        .finally(() => commit("setData", { isTableLoading: false }));
    },

    // getClientBills({ commit }, clientId) {
    //   return RequestService.read(`invoices/invoiceFilter?limit=10000000&client=${clientId}`).then((r) => {
    //     commit('setData', { clientBills: r.data.results })
    //   })
    // },

    getInvoice({ commit }, invoiceId) {
      return RequestService.read(`invoices/${invoiceId}`)
        .then((response) => {
          const [btnText, totalPrice] = [
            response.data.payloadCalculated.btnText,
            response.data.payloadCalculated.totalPrice,
          ];

          commit(
            "clientsModule/setData",
            { clientId: response.data.client },
            { root: true }
          );
          commit(
            "checkoutClients/setData",
            { invoiceData: response.data },
            { root: true }
          );
          commit(
            "packagesClients/setData",
            {
              packagesPayBtnLabel: `${btnText} ${
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                }).format(totalPrice)
              }`,
            },
            { root: true }
          );

          return Promise.resolve(true);
        })
        .catch((e) => console.log("error ", e));
    },

    updateClientBillsSettings({ state, commit }, data) {
      return RequestService.update(`users/settings`, {
        clientBillsSettings: {
          clientBillsTable: data,
          isDefault: state.isDefault,
          limit: state.filter.limit,
          searchLimit: state.filter.searchLimit
        },
      }).then(
        (data) => {
          console.log("data ", data);
        },
        (error) => {
          console.log("error ", error);
        }
      );
    },

    getClientBillsSettings({ commit, state }) {
      return RequestService.read(`users/settings`).then(
        (data) => {
          const limit = data?.data?.clientBillsSettings?.limit
            ? data.data.clientBillsSettings.limit
            : state.filter.limit;

          const searchLimit = data?.data?.clientBillsSettings?.searchLimit
            ? data.data.clientBillsSettings.searchLimit
            : state.filter.searchLimit;

          commit("setDefaultSettingsForBack", data.data);
          commit("setLimit", limit);
          commit("setSearchLimit", searchLimit);

          return Promise.resolve(data.data);
        },
        (error) => {
          console.log("error ", error);
        }
      );
    },

    viewBills({ rootState, commit }, invoiceIds = []) {
      let data = {}
      if (invoiceIds.length) {
        data = { invoices: [...invoiceIds] }
      }

      if (rootState.general.isSelectedAll && invoiceIds.length > 1) {
        data.all = true
      } 

      return RequestService.create('invoices/views', { ...data }, RequestService.generateToken(), {
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', 'file.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    },

    printBills({ rootState }, invoiceIds = []) {
      let data = {}
      if (invoiceIds.length) {
        data = { invoices: [...invoiceIds] }
      }

      if (rootState.general.isSelectedAll && invoiceIds.length > 1) {
        data.all = true
      } 

      return RequestService.create('invoices/prints', { ...data }, RequestService.generateToken(), {
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', 'bills.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    },

    sendIncoices({ dispatch, rootState }, invoiceIds = []) {
      let data = {}
      if (invoiceIds.length) {
        data = { invoices: [...invoiceIds] }
      }

      if (rootState.general.isSelectedAll && invoiceIds.length > 1) {
        data.all = true
      } 

      return RequestService.create('invoices/sendInvoices', { ...data }).then(async r => {
        await dispatch('getBillsList')
        return Promise.resolve(r.data)
      })
    },

    cancelIncoices({ dispatch, rootState }, invoiceIds = []) {
      let data = {}
      if (invoiceIds.length) {
        data = { invoices: [...invoiceIds] }
      }

      if (rootState.general.isSelectedAll && invoiceIds.length > 1) {
        data.all = true
      } 

      return RequestService.create('invoices/cancelInvoices', { ...data }).then(async r => {
        await dispatch('getBillsList')
        return Promise.resolve(r.data)
      })
    },

    getBillStatisticInfo({ commit }) {
      return RequestService.read('/statistic/getBillStatisticInfo').then(response => {
        if (response?.data) {
          if ('postalInoviceNextSchedule' in response.data) {
            commit('setData', { autoSendMilliseconds: response.data.postalInoviceNextSchedule })
          }

          if ('sent' in response.data) {
            commit('setData', { sentFromSocket: response.data.sent})
          }

          if ('inQueue' in response.data) {
            commit('setData', { queueFromSocket: response.data.inQueue})
          }
        }
      })
    }
  },
};
