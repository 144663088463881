import { eventBus } from "@/main";

export default {
   data() {
      return {
         acceptToRessetSettings: false
      }
   },
   watch: {
      acceptToRessetSettings(newVal) {
         /* 
          * If there is a columns settings problem, for example from the front end or backend
          * removed some columns accepted to reset settings for the show list
         **/
         if (newVal) {
            this.resetDataTableSettings()
         }
      }
   },
   mounted() {
      eventBus.$on('resetDataTableSettings', () => {
         this.resetDataTableSettings()
      }) 
   },
   methods: {
      createColumnConfigData() {
         if (!this.defaultSettings.length) {
            this.defaultColumnConfigData = this.defaultColumnConfigs.map((item) => Object.assign({}, item));
         }

         if (this.defaultSettings.length) {
            this.defaultColumnConfigData = []
            const defaultColumnConfigs = this.defaultColumnConfigs.map((item) => Object.assign({}, item))
            //Compaire defaultColumnConfigs and defaultSettings, get defaultSettings's items isShow key, and defaultColumnConfigs's prototypes
            this.defaultSettings.map((i, index) => {
               defaultColumnConfigs.map((j) => {
                  if (i.mainIndex === j.mainIndex) {
                     this.defaultColumnConfigData.push(j);
                     
                     if (this.defaultColumnConfigData[index]) {
                        this.defaultColumnConfigData[index].isShow = i.isShow
                     } else {
                        this.acceptToRessetSettings = true
                     }
                  }

                  if (i.title === j.title && i.mainIndex !== j.mainIndex) {
                     this.acceptToRessetSettings = true
                  }

               })
            })
         }

         this.columnConfigData = [];
         this.columnConfigData = this.OttClone(this.defaultColumnConfigData);
      },

      async resetDataTable() {
         this.$store.commit(this.updateColumnSettingsCommit, { defaultSettings: this.defaultColumnConfigs, isDefault: true })
         await this.$store.dispatch(this.updateColumnsFromBack, this.defaultColumnConfigs).then(() => {
           this.createSaveDefaultData();
         })
      },
   }
}