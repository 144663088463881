import RequestService from "@/services/request.service";

const InitialState = {
  showEmailComponents: false,
  showPhoneComponents: false,
  emailSection: true,
  emailData: {
    email: '',
    isMain: false,
    forContactInvoice: false,
    isLoading: false
  },
  selectedEmailIndex: null,

  phoneSection: true,
  phoneData: {
    phone: '',
    name: '',
    isCall: false,
    isMessage: false,
    isLoading: false
  },
  selectedPhoneIndex: null,

  manageType: {
    email: 'email',
    phone: 'phone',
  },

  isEditMode: false,
  isAddMode: false,
  manageValue: null,

  allEmails: [],
  allPhones: [],
  clientData: []
};

export const contactClient = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
    
    /**
     * change Manage data
     * @isEditMode
     * @isAddMode
     * @manageValue
     * **/
    changeManageValue(state, value) {
      state.manageValue = value
    },
    changeIsEditMode(state, value) {
      state.isEditMode = value
    },
    changeIsAddMode(state, value) {
      state.isAddMode = value
    },

    /**
     * Reset email data
     * **/
    resetEmailData(state) {
      state.emailData = JSON.parse(JSON.stringify(InitialState.emailData))
    },

    /**
     * Reset phone data
     * **/
    resetPhoneData(state) {
      state.phoneData = JSON.parse(JSON.stringify(InitialState.phoneData))
    },

    resetAllData(state) {
      state.emailData = JSON.parse(JSON.stringify(InitialState.emailData))
      state.phoneData = JSON.parse(JSON.stringify(InitialState.phoneData))
      state.allEmails = []
      state.allPhones = []
    },


    /**
     * Set Email data
     * **/
    setEmail(state, value) {
      state.emailData.email = value
    },
    setIsMain(state, value) {
      state.emailData.isMain = value
    },
    setForContactInvoice(state, value) {
      state.emailData.forContactInvoice = value
    },
    setSelectedEmailIndex(state, value) {
      state.selectedEmailIndex = value
    },
    setEmailSection(state, value) {
      state.emailSection = value
    },

    /**
     * Set Phone data
     * **/
    setPhone(state, value) {
      state.phoneData.phone = value
    },
    setName(state, value) {
      state.phoneData.name = value
    },
    setIsCall(state, value) {
      state.phoneData.isCall = value
    },
    setIsMessage(state, value) {
      state.phoneData.isMessage = value
    },
    setSelectedPhoneIndex(state, value) {
      state.selectedPhoneIndex = value
    },
    setPhoneSection(state, value) {
      state.phoneSection = value
    },


    /**
     * Set Loading
     */
    setIsEmailLoading(state, value) {
      state.emailData.isLoading = value
    },
    setIsPhoneLoading(state, value) {
      state.phoneData.isLoading = value
    },


    setAllEmails(state, data) {
      data.map(item => {
        delete item.updatedAt
        delete item.createdAt
        delete item.id
      })
      state.allEmails = data
    },
    setAllPhones(state, data) {
      data.map(item => {
        delete item.updatedAt
        delete item.createdAt
      })
      state.allPhones = data
    },

    /**
     * Check is main email
     */
    checkIsMainEmail(state, data) {
      if (data.isMain) {
        state.allEmails.map(item => {
          return item.isMain = item.email === data.isMain
        });
      }
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  },
  actions: {
    // contact email
    addContactEmail({ dispatch, commit }, { emails, clientId }) {
      const emailsList = emails.map(({ createdAt, ...rest }) => rest)

      return RequestService.update(`clients/edit/${clientId}`, { emails: emailsList })
        .then( async response => {
          await dispatch('clientsModule/getClientList', {}, { root: true })
          return Promise.resolve(response.data)
        })
        .catch(e => console.log('error', e))
        .finally(() => { commit('setIsEmailLoading', false) })
    },
    // contact phone
    addContactPhone({commit}, {phones, clientId}) {
      const phonesList = phones.map(({ createdAt, ...rest }) => rest)

      return RequestService.update(`clients/edit/${clientId}`, { phones: phonesList })
          .catch(e => console.log('error', e))
          .finally(() => { commit('setIsPhoneLoading', false) })
    },
    getContact({dispatch, commit}, {clientId}) {
      return RequestService.read(`clients/edit/${clientId}`)
        .then(r => {
          dispatch('setContactData', { emails: r.data.emails, phones: r.data.phones })
          commit('setData', { clientData: r.data })
          return Promise.resolve(r.data)
        })
        .catch(e => console.log('error ', e))
    },

    setContactData({commit}, {emails, phones}) {
      commit('setAllEmails', emails)
      commit('setAllPhones', phones)
      commit('setIsEmailLoading', false)
      commit('setIsPhoneLoading', false)
    },

    checkDuplicatedEmailPhone({commit}, data) {
      return RequestService.create('clients/check-email-phone', {...data})
      .then((r) => { return Promise.resolve(r.data) })
      .catch((error) => console.log(error))
    }
  }
};
