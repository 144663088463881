export default class ReviewFilterModel {
  constructor() {
    this.reset();
  }

  toSave() {
    const body = {};
    if (this.search) {
      body.search = this.search;
    }
    if (this.state) {
      body.state = this.state === 'pending' ? 0 : 2;
    }
    if (this.channelsCount[0]) {
      body.channelMinCount = this.channelsCount[0];
    }
    if (this.channelsCount[1]) {
      body.channelMaxCount = this.channelsCount[1];
    }
    if (this.clientsCount || this.clientsCount === 0) {
      body.clientAmount = this.clientsCount;
    }
    if (this.date?.start) {
      body.dateFrom = this.date?.start;
    }
    if (this.date?.end) {
      body.dateTo = this.date?.end;
    }
    return body;
  }

  reset() {
    this.search = "";
    this.state = null;
    this.channelsCount = [0, 0];
    this.clientsCount = null;
    this.date = {
      start: null,
      end: null
    };
    this.filterCount = [];
  }

  resetCurrentData(dataKey) {
    let index = this.filterCount.indexOf(dataKey);

    this.filterCount.splice(index, 1);
    switch (dataKey) {
      case "search": {
        this.search = "";
        break;
      }
      case "state": {
        this.state = null;
        break;
      }
      case "channelsCount": {
        this.channelsCount = [0, 0];
        break;
      }
      case "clientsCount": {
        this.clientsCount = null;
        break;
      }
      case "date": {
        this.date = {
          start: null,
          end: null
        };
        break;
      }
    }
  }

  getFilterCount() {
    if (this.search.trim() && !this.filterCount.includes("search")) {
      this.filterCount.push("search");
    }
    if (this.state !== null && !this.filterCount.includes("state")) {
      this.filterCount.push("state");
    }
    if (
      this.clientsCount !== null &&
      !this.filterCount.includes("clientsCount")
    ) {
      this.filterCount.push("clientsCount");
    }
    if (
      (this.channelsCount[0] || this.channelsCount[1]) &&
      !this.filterCount.includes("channelsCount")
    ) {
      this.filterCount.push("channelsCount");
    }
    if (
      this.date?.start &&
      this.date?.end &&
      !this.filterCount.includes("date")
    ) {
      this.filterCount.push("date");
    }
  }
}
