import RequestService from "@/services/request.service";

const InitialState = {
  labelData: {
    address: null,
    ipAddress: "",
    port: "",
    printerModel: "",
    pagesPerSheet: 1,
    isReceiptPrinter: "isShippingLabel"
  },
  printerType: {
    isShippingLabel: "isShippingLabel",
    isReceiptPrinter: "isReceiptPrinter"
  },
  labelPrintersList: [],
  isEditMode: false,
  isAddLabel: false,
  editedIndex: null,
  toSaveData:{},
  isSaved: false
};

export const labelPrinters = {
  namespaced: true,
  actions: {
    addLabelPrinter({ commit, rootState, state, dispatch }, providerId){
      return RequestService.create(
        `ottProviders/printers`,
        state.toSaveData
      ).then(
        async data => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    updateLabelPrinter({ commit, rootState, state, dispatch }, id) {
      return RequestService.update(
        `ottProviders/printers/edit/${id}`,
        state.toSaveData
      ).then(
        async data => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    getLabelPrinterList({commit,state}, providerId){
      return RequestService.read(
        `ottProviders/printers?providerId=${providerId}&limit=1000`
      ).then(
        data => {
          commit("fetchData", data.data.results);
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    deleteLabelPrinterList({rootState, dispatch},id){
      return RequestService.delete(
        `ottProviders/printers/edit/${id}`
      ).then(
        async data => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data?.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    /**
     * save  data  for add
     * **/

    toSaveData(state, providerId){
      state.toSaveData.isShipping = state.labelData.isReceiptPrinter===state.printerType.isShippingLabel;
      state.toSaveData.ip = state.labelData.ipAddress;
      state.toSaveData.pagesPerSheet = state.labelData.pagesPerSheet
      if(state.labelData.printerModel){
        state.toSaveData.model = state.labelData.printerModel;
      }
      state.toSaveData.port = state.labelData.port;
      state.toSaveData.address = state.labelData.address;
      if(!state.isEditMode){
        state.toSaveData.providerId = providerId;
      }
    },

    /**
     * fetch data
     * **/
     fetchData(state, data){
      state.labelPrintersList = []
      data.map(item=>{
        let itemData = {}
        itemData.address = item.address
        itemData.ipAddress = item.ip
        itemData.port = item.port
        itemData.printerModel = item.model
        itemData.id = item.id
        itemData.pagesPerSheet = item?.pagesPerSheet ?? 1
        itemData.isReceiptPrinter = item.isShipping? state.printerType.isShippingLabel: state.printerType.isReceiptPrinter
        state.labelPrintersList.push(itemData)
      })
    },

    addLabelList(state) {
      // state.labelPrintersList.push(data);
      state.isAddLabel = false;
      state.isEditMode = false;
      state.toSaveData = {}
      state.labelData = JSON.parse(JSON.stringify(InitialState.labelData))

    },
    addNewList(state) {
      state.labelData = JSON.parse(JSON.stringify(InitialState.labelData));
      state.isAddLabel = true;
    },

    deleteLabel(state, index) {
      state.labelPrintersList.splice(index, 1);
      state.isEditMode = false;
      state.isAddLabel = false;
      state.editedIndex = null;
      state.labelData = JSON.parse(JSON.stringify(InitialState.labelData));
      state.labelData = {}
    },
    editLabelData(state, data) {
      state.labelData = JSON.parse(JSON.stringify(data.data));
      state.editedIndex = data.index;
      state.isEditMode = true;
      state.isAddLabel = true;
    },
    closeEdit(state) {
      state.isEditMode = false;
      state.isAddLabel = false;
      state.labelData = JSON.parse(JSON.stringify(InitialState.labelData));
      state.editedIndex = null;
      state.toSaveData = {}
    },

    resetDataClose(state) {
      state.isEditMode = false;
      state.isAddLabel = false;
      state.labelData = JSON.parse(JSON.stringify(InitialState.labelData));
      state.editedIndex = null;
      state.labelPrintersList = [];
      state.toSaveData = {}
    },

    resetData(state) {
      state.isEditMode = false;
      state.isAddLabel = false;
      state.labelData = JSON.parse(JSON.stringify(InitialState.labelData));
      state.editedIndex = null;
    },

    setAddress(state, value) {
      state.labelData.address = value;
    },
    setIpAddress(state, value) {
      state.labelData.ipAddress = value;
    },
    setPort(state, value) {
      state.labelData.port = value;
    },
    setPrinterModel(state, value) {
      state.labelData.printerModel = value;
    },
    setPagesPerSheet(state, value) {
      state.labelData.pagesPerSheet = value;
    },
    setIsReceiptPrinter(state, value) {
      state.labelData.isReceiptPrinter = value;
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
