import { mapMutations, mapState } from "vuex";
import { eventBus } from "@/main";

export default {
    /**
     * @defaultSettings  from  Page Component
     * @columnConfigData  from  Page Component
     * **/
    data() {
        return {
            selectedArrowList: [],
            selectNotAll: false,
            selectAllList: false,
        }
    },

    computed: {
        ...mapState({
            general: state => state.general
        }),

        isSelectedAll: {
            get() {
                return this.general.isSelectedAll
            },
            set(isSelectedAll) {
                this.generalSetData({ isSelectedAll })
            }
        }
    },

    watch: {
        selectNotAll(newVal) {
          if (newVal) {
            this.isSelectedAll = false
          }
        },
    
        selectedArrowList(selectedList) {
          if (!selectedList.length) {
            this.isSelectedAll = false
          }
        }
      },

    created() {
        eventBus.$on('rowsPerPageChanged', async () => {
          this.resetSelectedList()
        }) 
    },

    mounted() {
        this.cloneColumnConfigData = Object.assign([], this.columnConfigData)
    },
    methods: {
        ...mapMutations({
            generalSetData: 'general/setData'
        }),

        /**
         * Update columns from table
         * **/
        updateColumns(data) {
            this.columnConfigData = data;
            this.isShowButton = true
        },

        /**
         * Create Ott Group Table  default settings
         * **/
        createSaveDefaultData() {
            if (this.columnConfigData.length) {
                let defaultSavedColumns = this.columnConfigData
                let NewColumnConfig = [];
                for (let i = 0; i < defaultSavedColumns.length; i++) {
                    // this.cloneColumnConfigData[
                    //     defaultSavedColumns[i].defaultDragIndex
                    //     ].isShow = defaultSavedColumns[i].defaultHide;
                    // let subList = []
                    // for (let j = 0; j < defaultSavedColumns[i]?.subList?.length; j++) {
                    //     this.cloneColumnConfigData[defaultSavedColumns[i].defaultDragIndex].subList[defaultSavedColumns[i].subList[j].defaultDragIndex].isShow =
                    //     defaultSavedColumns[i].subList[j].defaultHide
                    //     subList.push(this.cloneColumnConfigData[defaultSavedColumns[i].defaultDragIndex].subList[defaultSavedColumns[i].subList[j].defaultDragIndex])
                    // }
                    // this.cloneColumnConfigData[defaultSavedColumns[i].defaultDragIndex].subList = subList
                    NewColumnConfig.push(
                        this.cloneColumnConfigData[defaultSavedColumns[i].defaultDragIndex]
                    );
                }
                // this.columnConfigData = NewColumnConfig;
                this.$store.commit(this.updateColumnSettingsCommit, {
                    defaultSettings: defaultSavedColumns,
                    isDefault: this.isDefault
                })

            } else {
                this.setData({defaultSettings: Object.assign([], this.columnConfigData)})
            }
        },

        /**
         * Update Columns List  from Drag Table Columns
         *  data :{arrayOfIndex:[],parentIndex}
         * **/

        updateColumnsFromDrag(data) {
            if (this.columnConfigData.length) {
                let defaultSavedColumns = Object.assign([], this.columnConfigData)
                if (data.parentIndex || data.parentIndex === 0) { 
                    for (let i = 0; i < this.columnConfigData.length; i++) {
                        if (data.parentIndex === i) {
                            for (let j = 0; j < this.columnConfigData[i].subList.length; j++) {
                                this.columnConfigData[i].subList[j].defaultDragIndex =
                                    defaultSavedColumns[i].subList[data.arrayOfIndex[j]].defaultDragIndex;
                                this.columnConfigData[i].subList[j].defaultHide =
                                    defaultSavedColumns[i].subList[data.arrayOfIndex[j]].defaultHide;
                                this.columnConfigData[i].subList[j].name =
                                    defaultSavedColumns[i].subList[data.arrayOfIndex[j]].name;
                            }
                        }
                    }

                } else {
                    data.arrayOfIndex.map((item, i) => {
                        if (this.columnConfigData[i].defaultDragIndex === item) {
                            this.columnConfigData[i].defaultDragIndex = i;
                        }
                    })
                    /*for (let i = 0; i < this.columnConfigData.length; i++) {
                        if (this.columnConfigData[i].defaultDragIndex === data.arrayOfIndex[i]) {
                            this.columnConfigData[i].defaultDragIndex =
                            defaultSavedColumns[data.arrayOfIndex[i]].defaultDragIndex;
                        this.columnConfigData[i].defaultHide =
                            defaultSavedColumns[data.arrayOfIndex[i]].defaultHide;
                        this.columnConfigData[i].title =
                            defaultSavedColumns[data.arrayOfIndex[i]].title;
                        this.columnConfigData[i].subList =
                            defaultSavedColumns[data.arrayOfIndex[i]].subList;
                        }
                    }*/
                }
            }

            this.$store.commit(this.updateColumnSettingsCommit, {
                defaultSettings: this.columnConfigData,
                isDefault: false
            })  

            this.$store.dispatch(this.updateColumnsFromBack, this.columnConfigData)
        },

        updateColumnsFromCheck() {
            if (this.columnConfigData.length) {
                for (let i = 0; i < this.columnConfigData.length; i++) {
                    this.columnConfigData[i].defaultHide = this.columnConfigData[
                        this.columnConfigData[i].mainIndex
                        ].isShow;
                    // for (let j = 0; j < this.columnConfigData[i].subList.length; j++) {
                    //     this.columnConfigData[i].subList[j].defaultHide = this.columnConfigData[
                    //         this.columnConfigData[i].mainIndex
                    //         ].subList[j].isShow
                    //     if ((!this.columnConfigData[i].defaultHide || (this.columnConfigData[i].defaultHide && this.columnConfigData[i].subList.every(x => !x.defaultHide)) && !this.columnConfigData[i].hideFlag)) {
                    //         this.columnConfigData[i].hideFlag = true
                    //         this.columnConfigData[
                    //             this.columnConfigData[i].mainIndex
                    //             ].hideFlag = true
                    //         this.columnConfigData[i].defaultHide = false
                    //         this.columnConfigData[
                    //             this.columnConfigData[i].mainIndex
                    //             ].isShow = false
                    //         this.columnConfigData[i].subList[j].defaultHide = false
                    //         this.columnConfigData[
                    //             this.columnConfigData[i].mainIndex
                    //             ].subList[j].isShow = false
                    //     }

                    //     if (this.columnConfigData[i].defaultHide && this.columnConfigData[i].subList.every(x => !x.defaultHide) && this.columnConfigData[i].hideFlag) {
                    //         this.columnConfigData[i].hideFlag = false
                    //         this.columnConfigData[
                    //             this.columnConfigData[i].mainIndex
                    //             ].hideFlag = false
                    //         this.columnConfigData[i].defaultHide = true
                    //         this.columnConfigData[
                    //             this.columnConfigData[i].mainIndex
                    //             ].isShow = true
                    //         this.columnConfigData[i].subList.map(item => item.defaultHide = true)
                    //         this.columnConfigData[
                    //             this.columnConfigData[i].mainIndex
                    //             ].subList.map(item => item.isShow = true)
                    //     }
                    // }
                }
            }
            this.updateColumns(this.columnConfigData)
            this.$store.commit(this.updateColumnSettingsCommit, {
                defaultSettings: this.columnConfigData,
                isDefault: false
            })
            this.$store.dispatch(this.updateColumnsFromBack, this.columnConfigData)
        },

        /**
         * Select all visibles from  parent  component
         * **/
        selectAllVisibles() {
            if (this.dataTable.length !== this.selectedArrowList.length) {
                let idArrays = this.selectedArrowList.map(x => x.id);
                this.dataTable.forEach(item => {
                    let index = idArrays.indexOf(item.id);
                    if (index === -1) {
                        this.selectedArrowList.push(item);
                        this.selectAllList = true;
                    }
                });
            } else {
                this.dataTable.forEach(item => {
                    let index = this.selectedArrowList.indexOf(item.id);
                    this.selectedArrowList.splice(index, 1);
                });
            }
            this.selectNotAll = false;

        },

        selectAll() {
            if (!this.selectedArrowList.length) {
              this.selectAllList = false
            }
      
            if (this.dataTable.length !== this.selectedArrowList.length) {
              this.selectAllVisibles()
            }
      
            if (this.isSelectedAll) {
              this.selectAllList = true
            } else {
              this.resetSelectedList()
            }
        },

        numberToFinanceNumber(number, data = null) {
            const formattedNumber = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(number);
      
            if (number === 0) {
              return '$0.00'
            }
      
            if (data?.redText) {
              return `<span style="color: red;">${formattedNumber}</span>`;
            }
      
            if (number < 0) {
              return `${formattedNumber}`;
            } else {
              return `<span style="color: #6CCF97;">${formattedNumber}</span>`;
            }
          },

        /**
         * Select row  from parent component
         * **/

        selectRow(data) {
            if (data.treeIndex === 0) {
                let idArrays = this.selectedArrowList.map(x => x.id);
                if (this.selectedArrowList.length) {
                    let index = idArrays.indexOf(data.data.id);
                    if (index >= 0) {
                        this.selectedArrowList.splice(index, 1);
                    } else {
                        this.selectedArrowList.push(data.data);
                    }
                } else {
                    this.selectedArrowList.push(data.data);
                }
            }
            this.selectNotAll = !!(
                this.selectedArrowList.length !== this.dataTable.length &&
                this.selectedArrowList.length
            );
            if (!this.selectedArrowList.length) {
                this.selectAllList = false;
            }
            this.selectAllList = !!(
                this.selectedArrowList.length === this.dataTable.length &&
                this.selectedArrowList.length
            );
        },
        /**
         * Reset Selected List
         * **/
        resetSelectedList() {
            this.selectedArrowList = [];
            this.selectAllList = false;
            this.selectNotAll = false;
        }
    }
}
