const InitialState = {
  modeList: [
    {
      name: 'Manual Only',
      value: 'manualOnly',
    },
    {
      name: 'Immediately ',
      value: 'immediately',
    },
    {
      name: 'Before Start',
      value: 'beforeStart',
    }
  ],

  formData: {
    notificationsMode: null,
    beforeDays: null,
    repeatDays: null,
    notificationTextForUpcoming: '',
    notificationTextForCurrent: ''
  }
};

export const notificationDiscount = {
  namespaced: true,
  actions: {},

  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {


    /**
     * Reset Data
     * **/

    resetData(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState)).formData
    },

    setNotificationData(state, data) {
      state.formData = data
    },

    /**
     * Set Form Data
     * **/

    setNotificationMode(state, value) {
      state.formData.notificationsMode = value
    },
    setBeforeDays(state, value) {
      state.formData.beforeDays = value
    },
    setRepeatDays(state, value) {
      state.formData.repeatDays = value
    },
    setForUpcoming(state, value) {
      state.formData.notificationTextForUpcoming = value
    },
    setForClient(state, value) {
      state.formData.notificationTextForCurrent = value
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }

  }
};
