import { eventBus } from "@/main";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      // defaultSettings: [], // for save  default changes array
      selectedArrowList: [],
      selectedArrowListSub: [],
      tableColumnWidth: 0,
      selectNotAll: false,
      selectAllList: false,
      showActionsForSubList: false,
      cloneColumnConfigData: [],
      // updateColumnsFromBack:''  //   store data  for  update  colums
    };
  },

  watch: {
    selectNotAll(newVal) {
      if (newVal) {
        this.isSelectedAll = false
      }
    },

    selectedArrowList(selectedList) {
      if (!selectedList.length) {
        this.isSelectedAll = false
      }
    }
  },

  computed: {
    ...mapState({
      general: state => state.general
    }),

    isSelectedAll: {
      get() {
        return this.general.isSelectedAll
      },
      set(isSelectedAll) {
        this.generalSetData({ isSelectedAll })
      }
    } 
  },

  created() {
    eventBus.$on('rowsPerPageChanged', async () => {
      this.resetSelectedList()
    }) 
  },

  mounted() {
    this.cloneColumnConfigData = Object.assign([], this.columnConfigData)
  },

  methods: {
    ...mapMutations({
      generalSetData: 'general/setData'
    }),

    changeCheckbox() {
    },

    updateColumns(data) {
      this.columnConfigData = data;
      this.isShowButton = true
    },

    updateColumnsFromCheck() {
      if (this.defaultColumnConfigData.length) {
        let defaultSavedColumns = this.columnConfigData;
        for (let i = 0; i < this.columnConfigData.length; i++) {
            let currentFiltered = this.columnConfigData.filter(r=>r.key === this.columnConfigData[i].key);
            if (currentFiltered.length) {
              this.columnConfigData[i].isShow = this.columnConfigData[i].isShow === null ? false : currentFiltered[0].isShow;
            }
        }
      } else {
        for (let i = 0; i < this.columnConfigData.length; i++) {
          this.columnConfigData[i].isShow = this.columnConfigData[i].isShow === null ? false : this.columnConfigData[i].isShow;
        }
      }
      this.$store.commit(this.updateColumnSettingsCommit, { defaultSettings: Object.assign([], this.columnConfigData), isDefault: false })

      if (this.updateColumns) {
        this.$store.dispatch(this.updateColumnsFromBack, this.columnConfigData)
      }
    },

    updateColumnsFromDrag(data) {
      const indexes = {};
      data.forEach((value,index) => {
        const foundList = this.columnConfigData.filter( r=> r.defaultDragIndex === value);
        if (foundList.length) {
          indexes[foundList[0].key] = index
        }
      })
      if (this.columnConfigData.length) {
        this.columnConfigData
        let defaultSavedColumns = JSON.parse(JSON.stringify(this.columnConfigData))
        for (let i = 0; i < this.columnConfigData.length; i++) {
          this.columnConfigData[i].defaultDragIndex = indexes[this.columnConfigData[i].key];

          // this.defaultSettings[i].defaultHide =
          //   defaultSavedColumns[data[i]].defaultHide;
          // this.defaultSettings[i].name =
          //   defaultSavedColumns[data[i]].name;
        }
      } else {
        for (let i = 0; i < this.columnConfigData.length; i++) {
          this.columnConfigData[i].defaultDragIndex = indexes[i];
        }
      }

      this.columnConfigData.map(item => item.isDrag = true)
      this.columnConfigData[0].isDrag = false

      this.$store.commit(this.updateColumnSettingsCommit, { defaultSettings: this.columnConfigData, isDefault: false })
      if (this.updateColumns) {
        this.$store.dispatch(this.updateColumnsFromBack, this.columnConfigData)
      }
    },

    createSaveDefaultData() {
      if (this.columnConfigData.length) {

        let defaultSavedColumns = Object.assign([], this.columnConfigData)
        let NewColumnConfig = [];
        for (let i = 0; i < defaultSavedColumns.length; i++) {
          // this.cloneColumnConfigData[
          //   defaultSavedColumns[i].defaultDragIndex
          //   ].isShow = defaultSavedColumns[i].defaultHide;
          // NewColumnConfig.push(
          //   this.cloneColumnConfigData[defaultSavedColumns[i].defaultDragIndex]
          // );

          const currentFiltered = this.columnConfigData.filter(r=>r.key === defaultSavedColumns[i].key);
          if (currentFiltered.length) {
            Object.assign(currentFiltered[0], defaultSavedColumns[i]);
            NewColumnConfig.push(Object.assign({}, currentFiltered[0]));
          }
        }
        this.columnConfigData =  NewColumnConfig.sort(
            function(a, b) {
              if (a.defaultDragIndex === b.defaultDragIndex) {
                // Price is only important when cities are the same
                return a.mainIndex - b.mainIndex;
              }
              return b.defaultDragIndex > a.defaultDragIndex ? -1 : 1;
            });
        this.$store.commit(this.updateColumnSettingsCommit, { defaultSettings: defaultSavedColumns, isDefault: this.isDefault})

      } else {
        this.$store.commit(this.updateColumnSettingsCommit, { defaultSettings: [], isDefault: this.isDefault })

        this.columnConfigData.forEach((item, index) => {
          this.columnConfigData.push({
            isShow: item.isShow === null ? false : item.isShow,
            defaultDragIndex: index,
            mainIndex: index,
            name: item.title,
            key: item.key,
          });
        });
      }
      this.columnConfigData.map(item=>{
        item.isDrag = true
      })

      this.columnConfigData[0].isDrag = false
    },

    selectAllVisibles() {
      if (this.dataTable.length !== this.selectedArrowList.length) {
        let idArrays = this.selectedArrowList.map(x => x.id);
        this.dataTable.forEach(item => {
          let index = idArrays.indexOf(item.id);
          if (index === -1) {
            this.selectedArrowList.push(item);
            this.selectAllList = true;
          }
        });
      } else {
        this.dataTable.forEach(item => {
          let index = this.selectedArrowList.indexOf(item.id);
          this.selectedArrowList.splice(index, 1);
        });
      }
      this.selectNotAll = false;
    },

    selectAll() {
      if (!this.selectedArrowList.length) {
        this.selectAllList = false
      }

      if (this.dataTable.length !== this.selectedArrowList.length) {
        this.selectAllVisibles()
      }

      if (this.isSelectedAll) {
        this.selectAllList = true
      } else {
        this.resetSelectedList()
      }
    },

    selectRow(data) {

      if (data.treeIndex === 0) {
        let idArrays = this.selectedArrowList.map(x => x.id);
        if (this.selectedArrowList.length) {
          let index = idArrays.indexOf(data.data.id);
          if (index >= 0) {
            this.selectedArrowList.splice(index, 1);
          } else {
            this.selectedArrowList.push(data.data);
          }
        } else {
          this.selectedArrowList.push(data.data);
        }
      } else {
        let idArrays = this.selectedArrowListSub.map(x => x.id);
        if (this.selectedArrowListSub.length) {
          let index = idArrays.indexOf(data.data.id);
          if (index >= 0) {
            this.selectedArrowListSub.splice(index, 1);
          } else {
            this.selectedArrowListSub.push(data.data);
          }
        } else {
          this.selectedArrowListSub.push(data.data);
        }
      }
      this.selectNotAll = !!(
        this.selectedArrowList.length !== this.dataTable.length &&
        this.selectedArrowList.length
      );
      if (!this.selectedArrowList.length) {
        this.selectAllList = false;
      }
      this.selectAllList = !!(
        this.selectedArrowList.length === this.dataTable.length &&
        this.selectedArrowList.length
      );
    },
    getDataFromActions(data) {
      if (data.rowData && (data.rowData.id || data.rowData._id)) {
        return data.rowData;
      } else {
        return this.getDataFromActions(data.rowData);
      }
    },
    updateColumn(data) {
      if (data < 0) {
        this.tableColumnWidth = 0;
      } else {
        this.tableColumnWidth = data;
      }
    },

    numberToFinanceNumber(number, data = null) {
      const formattedNumber = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      }).format(number);

      if (number === 0) {
        return '$0.00'
      }

      if (data?.redText) {
        return `<span style="color: red;">${formattedNumber}</span>`;
      }

      if (number < 0) {
        return `${formattedNumber}`;
      } else {
        return `<span style="color: #6CCF97;">${formattedNumber}</span>`;
      }
    },
    /**
     * Reset Selected List
     * **/
    resetSelectedList() {
      this.selectedArrowList = [];
      this.selectAllList = false;
      this.selectNotAll = false;
      this.selectedArrowListSub = []
    }
  },
};
