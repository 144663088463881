 const InitialState = {
  companyAddressesForm: {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    countryCode:'',

    password: null,
    confirmPassword: null,
    id: null
  },
  geoInfo: {},
  user: null,
  flagForEmail: false,
  flagForPhone: false,
  isValidatePersonalInfo: false,
   countryCode:''
};

export const personalInfoAdminModule = {
  namespaced: true,
  actions: {},
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setCountryCode(state,value){
      state.companyAddressesForm.countryCode = value
    },
    resetData(state) {
      state.companyAddressesForm = JSON.parse(
        JSON.stringify(InitialState.companyAddressesForm)
      );
      state.flagForEmail = false
      state.flagForPhone = false
      state.isValidatePersonalInfo  = false
      state.countryCode = ''
    },
    setFlagEmail(state) {
      state.flagForEmail = true;
    },
    setFlagPhone(state) {
      state.flagForPhone = true;
    },
    validateEmail(state, value) {
      state.isValidatePersonalInfo = value;
    },
    updatePersonalInfo(state, data) {
      if (data?.user?.email) {
        state.companyAddressesForm.email = data.user.email;
      }
      if (data?.user?.firstname) {
        state.companyAddressesForm.firstName = data.user.firstname;
      }
      if (data?.user?.lastname) {
        state.companyAddressesForm.lastName = data.user.lastname;
      }
      if (data?.user) {
        state.user = data.user;
      }
      if (data?.user?.phone) {
        state.companyAddressesForm.phone = data.user.phone.phoneNumber;
        state.companyAddressesForm.countryCode = data.user.phone.countryCode;
      }
      if (data?.user?.geoInfo?.realIp) {
        state.geoInfo.realIp = data.user.geoInfo.realIp;
      }
      if (data?.user?.geoInfo?.countryCode) {
        state.geoInfo.countryCode = data.user.geoInfo.countryCode;
      }
      if (data?.user?.geoInfo?.domain) {
        state.geoInfo.domain = data.user.geoInfo.domain;
      }
      if (data?.user?.geoInfo?.network) {
        state.geoInfo.network = data.user.geoInfo.network;
      }
      if (data?.user?.geoInfo?.isp) {
        state.geoInfo.isp = data.user.geoInfo.isp;
      }
      if (data?.user?.geoInfo?.organization) {
        state.geoInfo.organization = data.user.geoInfo.organization;
      }
      if (data?.user?.geoInfo?.location) {
        state.geoInfo.location = data.user.geoInfo.location;
      }
      if (data?.user?.geoInfo?.postalCode) {
        state.geoInfo.postalCode = data.user.geoInfo.postalCode;
      }
      if (data?.user?.id) {
        state.companyAddressesForm.id = data.user.id;
      }
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
