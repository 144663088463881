import RequestService from "@/services/request.service";

const InitialState = {
  formData: {
    currency: null,
    priceGroup: null,
    paymentForPackages: null,
    paymentForDevice: null,
    isPaperlessBilling: false,
  },
  paymentForPackagesObj: null,
  paymentList: [],
  paymentListWithIcons: [],
  currencyList: [
    {
      name: [{
        lang: "em",
        name: "USD",
      }],
      value: 'default'
    }
  ],
  isLoading: false,
  showFinanceForm: false
};

export const financeClient = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },

    /**
     * Set finance Data
     * **/

    setFormData(state, data) {
      if ('currency' in data) {
        if (data?.currency) state.formData.currency = data.currency+""
      }
      
      if ('priceGroup' in data) {
        state.formData.priceGroup = data.priceGroup
      } 

      if ('forPackages' in data) {
        if (data?.forPackages) {
          state.formData.paymentForPackages = data.forPackages?.id
        } else {
          state.formData.paymentForPackages = null
        }
      } else {
        state.formData.paymentForPackages = null
      }

      if ('forDevicesRent' in data) {
        if (data?.forDevicesRent) state.formData.paymentForDevice = data.forDevicesRent
      } 

      if ('paperlessBilling' in data) state.formData.isPaperlessBilling = data.paperlessBilling
    },

    setCurrency(state, value) {
      state.formData.currency = value
    },
    setPriceGroup(state, value) {
      state.formData.priceGroup = value
    },
    setPaymentForPackages(state, value) {
      state.formData.paymentForPackages = value
    },
    setPaymentForDevice(state, value) {
      state.formData.paymentForDevice = value
    },
    setIsPaperlessBilling(state, value) {
      state.formData.isPaperlessBilling = value
    },

    /**
     * Reset Finance Data
     * **/
    resetFinanceData(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
    },

    /**
     * All currencies
     * **/
    allCurrencies(state, data) {
      state.currencyList = [
        {
          name: [{
            lang: "em",
            name: "USD",
          }],
          value: 'default'
        }
      ]
      
      if (data.results.length) {
        state.currencyList = data.results.map(item => {
          item.value = item.id
          return item
        })
      }
    },

    /**
     * Set loading
     */
    setIsLoading(state, value) {
      state.isLoading = value
    },

    /**
     * Set payment list
     */
    setPaymentList(state, data) {
      state.paymentList = []
      state.paymentListWithIcons = []

      if (data.length) {
        data.forEach((item) => {
          if (item?.creditCard) { // if exist credit card
            if (item.creditCard.brand) {
              state.paymentList.push({
                name: item.creditCard.brand.charAt(0).toUpperCase() + item.creditCard.brand.slice(1) + ' ' + item.creditCard.cardNumber.slice(-4),
                value: item.id,
              })
  
              state.paymentListWithIcons.push({
                name: item.creditCard.cardNumber.slice(-4),
                brand: item.creditCard.brand,
                value:item.id,
              })
            }
          }
  
          if (item?.bankTransfer) { // if exist bank account
            state.paymentList.push({
              name: item.bankTransfer.bankName.charAt(0).toUpperCase() + item.bankTransfer.bankName.slice(1) + ' ' + item.bankTransfer.accountNumber.slice(-4),
              value: item.id,
            })
  
            state.paymentListWithIcons.push({
              name: item.bankTransfer.accountNumber.slice(-4),
              brand: 'bank',
              value: item.id,
            })
          }
        })
      }

    },

    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  },
  actions: {
    addFinance({commit, dispatch}, {clientId, finance}) {
      return RequestService.update(`clients/edit/${clientId}`, {finance} )
        .then(async response => {
          await dispatch('clientsModule/getClientList', {}, { root: true })
          return Promise.resolve(response.data)
        })
        .catch(e => console.log('error', e))
        .finally(() => { commit('setIsLoading', false) })
      
    },
    addCurrency({dispatch}, data) {
      return RequestService.create(`currencys`, {...data})
          .then(async (r) => {
            await dispatch('clientsModule/getClientList', {}, { root: true })
          })
          .catch(e => console.log('error ', e))
    },
    getCurrencyList({commit}) {
      return RequestService.read(`currencys`)
          .then(response => {
            commit('allCurrencies', response.data)
          })
          .catch(e => console.log('error' , e))
    },
    getPaymentList({ commit, rootState }) {
      if (rootState.clientsModule.clientId) {
        return RequestService.read(`clients/paymentMethods/${rootState.clientsModule.clientId}`)
        .then(response => {

            commit('setPaymentList', response.data)
          })
          .catch(e => console.log('error' , e))
      }
    }
  }
};
