<template>
  <ott-dialog
    :is-open="isModalOpen"
    :show-footer="false"
    :show-header="false"
    :width="modalWidth"
  >
    <template #modalBody>
      <div class="P-reject-modal">
        <div class="P-reject-modal__header G-align-center">
          <span class="mdi mdi-alert-outline red--text" v-if="isDangerButton"></span>
          <span class="mdi mdi mdi-alert-outline mdi-check-circle-outline green--text" v-else></span>
          <h3 :class="{ 'dark-title': $vuetify.theme.dark }" class="P-reject-modal__header-title" v-html="modalHeading"></h3>
        </div>
        <div v-if="modalText" v-html="modalText"></div>
        <slot name="body" />
        <div class="P-reject-modal__footer G-justify-end">
          <ott-button
            :click="closeModal"
            :text="closeBtnText"
            custom-class="neutral-sm-btn P-cancel-btn"
          />
          <ott-button-loader
            :button-text="btnText"
            :className="`${buttonClasses} text-capitalize`"
            :is-loading="isLoading"
            @onClick="$emit('btnFunction')"
          />
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
// components
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttButton from "@/components/vuetifyComponents/OttButton";
export default {
  name: "WarningModal",
  components: { OttButton, OttButtonLoader, OttDialog },
  props: {
    isModalOpen: {
      type: Boolean,
      default: () => false,
    },
    modalHeading: {
      type: String,
      default: () => "",
    },
    modalText: {
      type: String,
      default: () => "",
    },
    btnText: {
      type: String,
      default: () => "",
    },
    isDangerButton: {
      type: Boolean,
      default: () => true
    },
    buttonClass: {
      type: String,
      default: () => null
    },
    modalWidth: {
      type: Number,
      default: () => 400
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    closeBtnText: {
      type: String,
      default: "Cancel"
    }
  },
  computed: {
    buttonClasses() {
      if (!this.buttonClass) {
        return this.isDangerButton ? 'red-sm-btn' : 'green-sm-btn'
      }else if (this.buttonClass) {
        return this.isDangerButton ? `red-sm-btn ${this.buttonClass}` : `green-sm-btn ${this.buttonClass}`
      }else {
        return this.buttonClass
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/deleteModal";
.dark-title {
  color: white !important;
}
</style>
