import RequestService from "@/services/request.service";

const InitialState = {
    ipHostData: {
        dragValue: '',
        values: []
    },
    apiEnabledData: {
        dragValue: '',
        values: []
    },
    formData: {
        adminLogin: '',
        adminPass: '',
        pushPass: '',
        ownToken: '',
        globalAuthUrl: '',
        logLevel: false,
        nvidiaMonitor: false,
        isEdge: false,
        enabled: false,
        collectStat: true,
        collectMediaInfo: false,
        splitSources: false,
        remoteUpdate: true
    },
    isAddBtnLoading: false
}

export const generalFlussonic = {
    namespaced: true,
    state: JSON.parse(JSON.stringify(InitialState)),
    mutations: {
        /**
        * for all first level keys changing  
        * setData({ key: 'value' })
        */
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key];
            }
        },

        /***
         * for all second level object keys changing
         *  setDataSecondLevels({ 
         *    stateKey1: { changingKey: 'newVal' },
         *    stateKey2: { changingKey2: 'newVal' },  
         *  })
         **/
        setDataSecondLevels(state, data) {
            for (let firstLevel in data) {
                for (let secondLevel in data[firstLevel]) {
                    if (state[firstLevel][secondLevel] !== undefined) {
                        state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
                    }
                }
            }
        },

        /***
         * Multiselect Draggable mutations
         **/

        addDraggable(state, { data, keyName }) {
            if (data.dragValue) {
                state[keyName].values.push({
                    name: data.dragValue,
                    id: new Date().getTime(),
                });
                state[keyName].dragValue = "";
            }
            return
        },

        setDragValue(state, { data, keyName }) {
            state[keyName].dragValue = data.value;
        },

        removeDraggable(state, { dragId, keyName }) {
            state[keyName].values = state[keyName].values.filter(
                (value) => value.id !== dragId
            );
        },

        resetState(state) {
            for (let key in state) {
                state[key] = JSON.parse(JSON.stringify(InitialState[key]))
            }
        }
    },
    actions: {}
}