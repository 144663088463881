<template>
  <v-app class="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import createScript from "./utils/createScript";

export default {
  computed: {
    ...mapState({
      loggedIn: (state) => state.auth.loggedIn,
      user: (state) => state.auth.user,
      searchedClientId: (state) => state.generalSearch.searchedClientId,
      notificationAudio: (state) => state.sidebar.notificationAudio,
      notificationVolume: (state) => state.sidebar.notificationVolume,
      isMuted: (state) => state.sidebar.isMuted,
    }),
  },

  watch: {
    loggedIn(val) {
      if (val) {
        this.$connectHub(); // TODO connect to wss while token exists.
      }
    },

    "$route.name"(routeName) {
      if (this.searchedClientId && routeName !== "Search") {
        this.generalSearchSetData({ searchedClientId: null });
      }
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.$connectHub();
      this.$hubEventBus.$on("permissions", this.handlePermissions); // if loggedIn get permissions with WS

      this.$hubEventBus.$on("sync-info", this.handleSyncResponse);
      this.$hubEventBus.$on("sms-info", this.handleSmsResponse);
      this.$hubEventBus.$on(
        "notification-info",
        this.handleNotificationInfoResponse
      );
    }
  },
  beforeDestroy() {
    this.$hubEventBus.$off("sync-info", this.handleSyncResponse);
    this.$hubEventBus.$off("sms-info", this.handleSmsResponse);
    this.$hubEventBus.$off(
      "notification-info",
      this.handleNotificationInfoResponse
    );
  },

  updated() {
    const jQueryScriptId = "jQuery";
    const scriptLoaded = Boolean(document.getElementById(jQueryScriptId));

    if (this.loggedIn && this.user.rolesInfo.admin) {
      if (!scriptLoaded) {
        createScript(
          "https://code.jquery.com/jquery-latest.js",
          jQueryScriptId
        ).then(() => {
          createScript(
            "https://ottstream.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-vg1gsr/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=9048b5bb"
          );
        });
      }
    }
  },

  methods: {
    ...mapActions({
      getNotifications: "notifications/getNotifications",
    }),

    createToastMessage(response) {
      const toastOptions = {
        msg: response.message,
        position: "toast-bottom-right",
        defaultTimeout: 500,
      };

      if (response.success) {
        toastOptions.type = "success";
        toastOptions.style = {
          backgroundColor: "#01B2B8",
          width: "max-content",
          textTransform: "capitalize",
          opacity: "1",
        };

        this.$toastr.Add(toastOptions);
      } else {
        (toastOptions.type = "error"),
          (toastOptions.style = {
            backgroundColor: "red",
            width: "max-content",
            textTransform: "capitalize",
            opacity: "1",
          });

        this.$toastr.Add(toastOptions);
      }
    },
    ...mapMutations({
      usersSetData: "users/setData",
      notificationsSetData: "notifications/setData",
      markProviderSyncState: "appModule/makeProviderSyncState",
      generalSearchSetData: "generalSearch/setData",
    }),
    handleSyncResponse(data) {
      console.log(
        `sync state data arrived ${data.providerId} ${data.syncState}`
      );
      this.markProviderSyncState(data);
    },
    handleSmsResponse(data) {
      this.createToastMessage({ success: data.status, message: data.message });
    },
    async handleNotificationInfoResponse(data) {
      await this.getNotifications();
      this.playNotification();
    },

    playNotification() {
      this.notificationAudio.volume = this.notificationVolume;
      this.notificationAudio.muted = this.isMuted;
      this.notificationAudio.play();
    },

    handlePermissions(permissionsObject) {
      this.usersSetData({ permissionsObject });
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application

@import "assets/scss/index";
@import "assets/fonts/style.css";
.app {
  & > .v-application--wrap {
    overflow-y: auto;
  }
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-text-field__details,
.v-messages {
  display: none !important;
}

.w-max-content {
  width: max-content !important;
}
</style>
