import RequestService from "@/services/request.service";

const InitialState = {
  formData: {
    firstname: '',
    lastname: '',
    dateOfBirth: null,
    gender: 'male',
    comment: '',
    provider: null,
    providerName: '-',
  },

  genderType: {
    male: 'male',
    female: 'female',
    other: 'other',
  },

  isLoading: false,
  showSaveBtn: true,
  disableFormItems: true
};

export const personalInfoClient = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },

    /**
     * Reset data
     * **/

    resetData(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
    },

    resetAllData(state){
      state.formData = JSON.parse(JSON.stringify(InitialState.formData))
    },

    /**
     * Set form data
     * **/

    setFirstName(state, value) {
      state.formData.firstname = value
    },
    setLastName(state, value) {
      state.formData.lastname = value
    },
    setDateOfBirth(state, value) {
      state.formData.dateOfBirth = value
    },
    setGender(state, value) {
      state.formData.gender = value
    },
    setClientProvider(state, value) {
      state.formData.provider = value
    },
    setPersonalInfoData(state, data) {
      if (data?.firstname) {
        state.formData.firstname = data.firstname
      }
      if (data?.lastname) {
        state.formData.lastname = data.lastname
      }
      if (data?.dateOfBirth) {
        state.formData.dateOfBirth = data.dateOfBirth
      }
      if (data?.sex) {
        state.formData.gender = data.sex
      }
      if (data?.provider) {
        state.formData.provider = data.provider
      }
    },
    setAdditionalInfoData(state, data) {
      if (data?.provider) {
        state.formData.providerName = data?.provider.name?.length ? data?.provider?.name[0].name : 'Provider has no name'
        state.formData.provider = data?.provider?.id
      }
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
    setShowSaveBtn(state, value) {
      state.showSaveBtn = value
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  },
  actions: {
    addPersonalInfo({commit, dispatch}, {personalInfo}) {
      return RequestService.create(`clients`, {personalInfo} )
          .then(async (response) => {
            commit("clientsModule/setClientId", response.data.id, { root: true })
            await dispatch('clientsModule/getClientList', {}, { root: true })
            return Promise.resolve(response.data)
          })
          .catch(e => console.log('error', e))
          .finally(() => { commit('setIsLoading', false )})
    },
    editPersonalInfo({dispatch, commit}, {personalInfo, clientId}) {
      return RequestService.update(`clients/edit/${clientId}`, {personalInfo} )
          .then(async (r) => {
            await dispatch('clientsModule/getClientList', {}, { root: true })
            return Promise.resolve(r.data)
          })
          .catch(e => console.log('error', e))
          .finally(() => { commit('setIsLoading', false )})
    },
    getPersonalInfo({dispatch}, {clientId}) {
      return RequestService.read(`clients/edit/${clientId}`)
          .then(r => {
            dispatch('setAdditionalInfo', r.data)
            dispatch('setPersonalInfo', r.data.personalInfo)
          })
          .catch(e => console.log('error ', e))
    },

    setPersonalInfo({commit}, personalInfo) {
      commit('setPersonalInfoData', personalInfo)
      commit('setIsLoading', false)
    },
    setAdditionalInfo({commit}, personalInfo) {
      commit('setAdditionalInfoData', personalInfo)
    }
  }
};
