<template>
  <div class="P-filters P-transaction-filter">
    <div class="P-input-block">
      <ott-input
          v-model="filterModel.search"
          @timeoutInput="filterData('search')"
          :timeoutInput="true"
          label="Search"
          prependInnerIcon="mdi mdi-magnify  neutral--text"
      ></ott-input>
    </div>

    <div class="P-input-block">
      <ott-checkbox
        v-model="filterModel.allResellers"
        @updated="filterData('allResellers')"
        label="All Reseller Client"
        checkboxClass="me-2"
      />
    </div>
    <!-- <div class="P-input-block">
      <ott-checkbox
        v-model="filterModel.v2"
        @updated="filterData('v2')"
        label="V2 (updated transactions)"
        checkboxClass="me-2"
      />
    </div> -->

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.inOut"
        @emitFunc="filterData('inOut')"
        label="In / Out"
        :items="inOutList"
        :persistentHint="true"
        :clear="true"
        selected-text="name"
      />
    </div>

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.resellers"
        @emitFunc="selectResellers"
        :clear="true"
        :items="resellersList"
        :multiple="true"
        :persistentHint="true"
        label="Reseller Clients"
        selected-text="name"
      ></ott-select>
    </div>

    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.executionDate"
        :masks="{ input: ottDateFormat }"
        :max-date="newDateWithMainUserTZ"
        :popover="{ visibility: 'click' }"
        :is-dark="$vuetify.theme.dark"
        @input="filterData"
        is-range
        locale="en"
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
            <div class="ott-date-picker-cont Ott-input-class:">
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.start"
                        v-on="inputEvents.start"
                        outlined
                        dense
                        height="38"
                        label="Execution Start"
                    />
                    <span
                        class="P-date-icon neutral--text"
                        @click="resetData('executionDate')"
                        :class="
                          !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                    ></span>
                </div>
                <span class="pick-to neutral--text">To</span>
                <div class="G-calendar-input">
                    <v-text-field
                        v-model="inputValue.end"
                        v-on="inputEvents.end"
                        outlined
                        dense
                        height="38px"
                        label="Execution End"
                    />
                    <span
                        @click="resetData('executionDate')"
                        :class="
                            !inputValue.start
                            ? 'mdi mdi-calendar-blank-outline P-calendar'
                            : 'icon-cancel'
                        "
                        class="P-date-icon neutral--text"
                    ></span>
                </div>
            </div>
        </template>
      </DatePicker>
    </div>
    <div class="P-input-block">
      <ott-select
          label="Transaction State "
          :items="transactionsModule.transactionStatus"
          :persistentHint="true"
          :clear="true"
          selected-text="name"
          v-model="filterModel.transactionState"
          @emitFunc="filterData('transactionState')"
      >
      </ott-select>
    </div>

    <div class="P-input-block">
      <ott-select
        v-model="filterModel.fee"
        label="Fees"
        :items="fees"
        :persistentHint="true"
        :clear="true"
        selected-text="name"
        @emitFunc="filterData('fee')"
      >
      </ott-select>
    </div>
    <div class="P-input-block">
      <ott-select
        label="Refund & Buy"
        :items="buyRefundList"
        :persistentHint="true"
        :clear="true"
        selected-text="name"
        v-model="filterModel.isRefund"
        @emitFunc="filterData('isRefund')"
      />
    </div>

    <div class="P-input-block"> 
      <ott-select
        v-model="filterModel.toProvider"
        @emitFunc="filterData('toProvider')"
        :items="resellersWithParent.length ? resellersWithParent : resellersList"
        :multiple="true"
        :clear="true"
        :persistentHint="true"
        label="To Provider"
        selected-text="name"
      ></ott-select>
    </div>

    <div class="P-input-block">
      <ott-select
          label="Transaction Type "
          :items="transactionsModule.transactionType"
          :persistentHint="true"
          :clear="true"
          selected-text="name"
          v-model="filterModel.transactionType"
          @emitFunc="filterData('transactionType')"
      >
      </ott-select>
    </div>

    <transition name="slide">
      <div v-if="filterModel.transactionType === 'C_TO_A'">
        <div class="P-input-block">
          <ott-select
            v-model="filterModel.merchant"
            label="Payment Gateway"
            :items="registredPaymentsList"
            :persistentHint="true"
            :clear="true"
            selected-text="name"
            @emitFunc="filterData('transactionType')"
          >
          </ott-select>
        </div>

        <div class="P-input-block">
          <ott-checkbox
            v-model="filterModel.autopayment"
            @changeCheckbox="filterData('autopayment')"
            label="Autopayment"
          />
        </div>
      </div>
    </transition>

    <div class="P-input-block">
      <ott-range-select
        v-model="filterModel.transactionAmount"
        :min="filterRangeData.transactionAmount[0]"
        :max="filterRangeData.transactionAmount[1]"
        @updateRange="filterData"
        @clearData="resetData('transactionAmount')"
        label="Transaction Amount"
        prefix="$"
      ></ott-range-select>
    </div>
    <!-- <div class="P-input-block">
      <ott-select
          label="Initiator Type "
          :items="transactionsModule.initiatorType"
          :persistentHint="true"
          :clear="true"
          selected-text="name"
          v-model="filterModel.initiatorType"
          @emitFunc="filterData('initiatorType')"
      >
      </ott-select>
    </div> -->

    <selected-filters-list v-if="filterModel.filterCount.length" />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DatePicker from "v-calendar/src/components/DatePicker";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRangeSelect from "@/components/customComponents/OttRangeSelect";
import SelectedFiltersList  from "./SelectedFiltersList";

export default {
  name: "FilterData",
  components: {OttRangeSelect, OttSelect, OttInput, OttCheckbox, DatePicker, SelectedFiltersList},

  mixins: [DateFormatMixin, ConvertTimeZoneMixin],

  data() {
    return {
      inOutList: [
        {
          name: 'In',
          value: 'in'
        },
        {
          name: 'Out',
          value: 'out'
        }
      ],
      buyRefundList: [
        {
          name: 'Refund',
          value: true
        },
        {
          name: 'Buy',
          value: false
        },
      ],
      fees: [
        {
          name: 'With Fee',
          value: true
        },
        {
          name: 'Without Fee',
          value: false
        },
        {
          name: 'None',
          value: 'none'
        }
      ],
    }
  },

  computed: {
    ...mapState({
      filterModel: (state) => state.transactionsModule.filterModel,
      filterRangeData: (state) => state.transactionsModule.filterRangeData,
      transactionsModule: (state) => state.transactionsModule,
      filter: (state) => state.transactionsModule.filterData,
      resellersWithParent: (state) => state.providers.resellersWithParent,
      resellersList: (state) => state.providers.resellersList,
      registredPaymentsList: (state) => state.paymentGatewayAdmin.registredPaymentsList
    }),
  },

  methods: {
    ...mapActions({
      getTransactionList: 'transactionsModule/getTransactionList',
      getResellersWithParent: "providers/getResellersWithParent",
    }),
    ...mapMutations({
      filterTransactions: 'transactionsModule/filterTransactions',
      setFilterPage: "transactionsModule/setFilterPage",
      sortColumn: 'transactionsModule/sortColumn',
      providersSetData: "providers/setData",
    }),

    /**
     * Get price group list
     * **/

    async filterData(type) {
      for (const property in this.filterModel) {
        if(
          (
            this.filterModel[type]===null || 
            (typeof this.filterModel[type]==='string' && !this.filterModel[type]) ||
            (typeof this.filterModel[type]==='boolean' && !this.filterModel[type])
          ) ||
          (Array.isArray(this.filterModel[type]) && !this.filterModel[type].length) && type === property){
          this.filterModel.resetCurrentData(type);
        }
      }

      this.setFilterPage(1);
      this.filterModel.getFilterCount();
      this.sortColumn()
      this.filterTransactions();
      await this.getTransactionList(this.filter)
    },

    async selectResellers() {
      await this.filterData('resellers')
      if (this.filterModel.resellers.length) {
        await this.getResellersWithParent({
          providers: [...this.filterModel.resellers],
          parent: true
        })
      } else {
        this.providersSetData({ resellersWithParent: [] })
        await this.getResellersWithParent({
          parent: true
        })
      }
    },

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterTransactions()
      await this.getTransactionList(this.filter);
    },

    dateHidePopover(type) {
      this.$refs[type].hidePopover();
    },
  },
};
</script>
<style lang="scss" scoped>
.P-input-block {
  margin-bottom : 20px;
}

.P-ml-20 {
  margin-left : 20px;
}

.P-checkboxes {
  margin-bottom : 10px;
}
::v-deep {
  .v-text-field__details {
    display: none;
  }
  .v-input--dense>.v-input__control>.v-input__slot {
    margin-bottom: 0;
  }
}
</style>

<style lang="scss">
 .P-transaction-filter {
    .v-messages {
      display: none;
    }

    .v-input__slot {
      margin-bottom: 0;
    }
  }
</style>
