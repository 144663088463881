<template>
  <v-container fluid class="P-main-content">
    <v-row class="G-justify-center">
      <v-col v-if="!isShowLoader && paymentData.invoice.state !== 1 && !cardData"  sm="12" md="6" lg="6" xl="6" xxl="4">
        <div class="G-page-component">
          <div class="G-component-title">
            <h3 class="secondary--text mb-4">Secure payment of invoice #{{ paymentData ? paymentData.invoice.number : '' }}</h3>
          </div>

          <div class="G-component-body page-background">
            <p class="text-center mb-0">Amount Due:</p>
            <h4 class="text-center">USD {{ paymentData ? paymentData.invoice.amount : '' }}</h4>

            <div>
              <p class="G-flex G-justify-between mb-0">
                <span>Invoice Total:</span>
                <span v-if="paymentData">
                  {{ 
                    new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    }).format(isChanged ? monthData.totalPrice : paymentData.invoice.payloadCalculated.totalPrice)
                  }}
                </span>
              </p>
              <p class="G-flex G-justify-between mb-0">
                <span>Card Fee:</span>
                <span>
                  {{ 
                    new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                    }).format(paymentData.invoice.payloadCalculated.bankFee)
                  }}
                </span>
              </p>
            </div>


            <div class="G-flex G-justify-between G-align-center day-month-types">
              <div class="radio-block">
                <v-radio-group v-model="dayMonthType">
                  <div class="d-flex flex-wrap">
                    <div class="P-radio me-3">
                      <ott-radio label="Day" value="d" />
                    </div>
                    <div class="P-radio">
                      <ott-radio label="Month" value="m" />
                    </div>
                  </div>
                </v-radio-group>
              </div>
              <div class="P-padding-20 pr-0">
                <ott-select
                  v-model="dayOrMonth"
                  :error="$v.creditCard.dayOrMonth.$error"
                  selected-text="value"
                  :items="dayMonthType === 'd' ? daysList : monthsList"
                  :label="dayMonthType === 'd' ? 'Day*' : 'Month*'"
                  @emitInputChange="changeDayOrMonth"
                />
              </div>
            </div>
          </div>

          <div class="G-component-body page-background mt-4 mb-4">
            <p class="mb-3">Credit card details</p>
            <div class="G-flex G-flex-wrap">
              <div class="P-padding-100 px-0">
                <ottInput
                  v-model="cardholderName"
                  :error="$v.creditCard.cardholderName.$error"
                  @emitKeypress="onlyLetters($event)"
                  label="Card holder name*"
                />
              </div>
              <div class="P-padding-100 px-0">
                <ottInput
                  v-model="cardNumber"
                  :error="$v.creditCard.cardNumber.$error"
                  v-cardformat:formatCardNumber
                  label="Card number*"
                  class="card-number"
                />
              </div>
              <div class="P-padding-33 ps-0">
                <ott-input
                  v-model="expireDate"
                  v-cardformat:formatCardExpiry
                  :error="!validateExpiryDate || $v.creditCard.expireDate.$error"
                  :customInputClass="'P-expiry-date'"
                  @emitFunc="expiryDateValidation"
                  label="Expiry date*"
                />
              </div> 
              <div class="P-padding-33 pe-0">
                <ottInput
                  v-model="cvc"
                  v-cardformat:formatCardCVC
                  :error="$v.creditCard.cvc.$error"
                  label="CVC/CVV*"
                />
              </div>
              <div class="P-padding-100 pt-0 px-0">
                <ott-switch
                  v-model="isAutoPayment"
                  :className="'secondary-dark--text'"
                  label="Auto Payment"
                />
              </div>
            </div>
            <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
            <ott-button-loader
              @onClick="payByCredit"
              class="primary-sm-btn"
              button-text="Pay by credit card"
              :is-loading="isLoading"
            />
            <p class="my-3 text-center">or</p>
            <ott-button
              :click="payByCredit"
              class="primary-sm-btn"
              text="Proceed by online payment"
            />
          </div>
        </div>
      </v-col>
      <PageLoader v-if="isShowLoader" />


      <v-col v-if="(paymentData && paymentData.invoice.state === 1) || (cardData && cardData.invoice.state === 1)" sm="12" md="6" lg="4" xl="4" xxl="4">
        <div class="text-center">
          <v-icon color="success" x-large>mdi-check-circle</v-icon>
          <h2 class="mt-4">Thanks for your payment</h2>

          <div class="mt-10">
            <p class="G-flex G-justify-between mb-2">
              <span>Payment Amount</span>
              <span v-if="paymentData">
                {{ 
                  new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                  }).format(paymentData.invoice.payloadCalculated.totalPrice)
                }}
              </span>
            </p>
            <div class="G-flex G-justify-between G-align-center mb-2">
              <span>Payment Method</span>
              <div 
                v-if="paymentData && paymentData.transaction"
                :style="{backgroundImage:`url('${getCardBrand(paymentData.transaction.sourcePay.brand)}')`}"
                class="P-credit-card-image"
              />
            </div>
            <p class="G-flex G-justify-between mb-2">
              <span>Payment Date</span>
              <span>{{ paymentData ? paymentData.invoice.updatedAt : '' }}</span>
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttButton from "@/components/vuetifyComponents/OttButton";

import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import PageLoader from "@/components/pageLoader/PageLoader";

import { minLength, required, requiredIf} from "vuelidate/lib/validators";
import {mapActions, mapMutations, mapState} from "vuex";
import DaysMonthMixin from "@/mixins/DaysMonthMixin";
import CreditCardsMixin from "@/mixins/CreditCardsMixin";

export default {
  components: {
    OttButton,
    OttButtonLoader,
    OttInput,
    OttSelect,
    OttRadio,
    OttSwitch,
    PageLoader,
  },
  mixins: [DaysMonthMixin, CreditCardsMixin],
  data() {
    return {
      // invoiceNumber: '000530',
      // amount: 100,
      dayMonthType: 'd',
      validateExpiryDate: true,
      isAutoPayment: null,
      isShowLoader: false,
      isChanged: false
    };
  },
  validations: {
    creditCard: {
      cardNumber: {
        required,
      },
      cardholderName: {
        required
      },
      dayOrMonth: {
        required
      },
      cvc: {
        required
      },
      expireDate: {
        required
      },
    }
  },
  async created() {
    this.toggleMinimize(true)
    this.isShowLoader = true
    await this.getPaymentData(this.$route.params.id)
    this.isShowLoader = false

  },
  mounted() {
    // setTimeout(() => {
    //   this.isShowLoader = false
    // }, '1000');
  },
  methods: {
    ...mapActions({
      getPaymentData: 'securePaymentsModule/getPaymentData',
      payClientUpdated: 'securePaymentsModule/payClientUpdated',
      payClientInvoice: 'securePaymentsModule/payClientInvoice'
    }),
    ...mapMutations({
      setData: 'securePaymentsModule/setData',
      setCardNumber: 'securePaymentsModule/setCardNumber',
      setCardholderName: 'securePaymentsModule/setCardholderName',
      setDayOrMonth: 'securePaymentsModule/setDayOrMonth',
      setCVC: 'securePaymentsModule/setCVC',
      setExpireDate: 'securePaymentsModule/setExpireDate',
      toggleMinimize: 'sidebar/toggleMinimize',
      setYear: 'securePaymentsModule/setYear',
      setMonth: 'securePaymentsModule/setMonth',
    }),
    async payByCredit() {
      this.$v.creditCard.$touch()

      if (!this.$v.creditCard.$error) {
        this.setData({ isLoading: true })
        const dayOrMonth = this.dayMonthType === 'd' ? { day: this.creditCard.dayOrMonth } : { month: this.creditCard.dayOrMonth }
        const data = {
          creditCard: {
            cardNumber: this.creditCard.cardNumber,
            cardholderName: this.creditCard.cardholderName,
            month: this.creditCard.month,
            year: this.creditCard.year,
            cvc: this.creditCard.cvc,      
          },
          ...dayOrMonth,
          autopayment: this.isAutoPayment ? true : false,
        }

        try {
          await this.payClientInvoice({ id: this.$route.params.id, data })
          console.log('cardData ', this.cardData);
          return Promise.resolve(true)
        } catch(e) {
          if (e.response.status >= 300) {
            this.error = true;
            this.errorMessage = e.response.data.message
          }
          return Promise.resolve(true)
        } 
      }

      
    },
    /**
     * Only letters and spaces - regex
     * **/
    onlyLetters(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[a-zA-Z\s]*$/.test(char)) return true;
      else event.preventDefault();
    },
    expiryDateValidation() {
      let month = new Date().getMonth();
      let year = new Date()
        .getFullYear()
        .toString()
        .split("")
        .splice(2, 2)
        .join("");
      if (this.creditCard.expireDate) {
        let x = this.creditCard.expireDate.split("/");
        if (+x[0] > 12) {
          x[0] = "12";
        }
        x[1] && this.setYear(x[1].trim())
        x[0] && this.setMonth(x[0].trim())
        if(x.length===2){
          this.setExpireDate(x[0] + "/" + x[1])
          this.validateExpiryDate =
            (+x[0] >= month + 1 && +x[1] === +year) || (+x[1] > +year && +x[0] > 0);
        }
      }
    },
    async changeDayOrMonth(value) {
      const id = this.$route.params.id
      const data = this.dayMonthType === 'd' ? { day: value } : { month: value }
      await this.payClientUpdated({ id, data })

      this.isChanged = true
      console.log('month or day data ', this.monthData);
    },
  },
  computed: {
    ...mapState({
      creditCard: state => state.securePaymentsModule.creditCard,
      paymentData: state => state.securePaymentsModule.paymentData,
      isLoading: state => state.securePaymentsModule.isLoading,
      monthData: state => state.securePaymentsModule.monthData,
      cardData: state => state.securePaymentsModule.cardData,
      errorMessage: state => state.securePaymentsModule.errorMessage,
    }),
    cardNumber: {
      get() {
        return this.creditCard.cardNumber
      },
      set(value) {
        this.setCardNumber(value)
      }
    },
    cardholderName: {
      get() {
        return this.creditCard.cardholderName.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
      },
      set(value) {
        this.setCardholderName(value)
      }
    },
    dayOrMonth: {
      get() {
        return this.creditCard.dayOrMonth
      },
      set(value) {
        this.setDayOrMonth(value)
      }
    },
    cvc: {
      get() {
        return this.creditCard.cvc
      },
      set(value) {
        this.setCVC(value)
      }
    },
    expireDate: {
      get() {
        return this.creditCard.expireDate
      },
      set(value) {
        this.setExpireDate(value)
      }
    },
  }
}
</script>
<style scoped lang="scss">
@import "src/assets/scss/formStyles";

.P-main-content {
  overflow-y: auto;
  height: calc(98vh - 0px);
}
.radio-block {
  margin-left: -2px;
}

@media (max-width: 500px) {
  .G-page-component {
    padding: 6px;
  }
  .P-padding-33 {
    width: 50%;
  }
  .day-month-types {
    flex-direction: column;
    margin-top: 20px;
    .P-padding-40 {
      width: 50%;
      padding-left: 0 !important;
    }
  }
}
</style>