import ReviewFilterModel from "../../models/review/reviewFilterModel";
import RequestService from "@/services/request.service";
const FileDownload = require('js-file-download');

const InitialState = {
  filterModel: new ReviewFilterModel(),
  filter: {
    limit: 20,
    sortBy: "_id:desc",
    page: 1
  },
  filterData: {},
  // for table and  pagination
  dataTable: [],
  dataCount: 0,
  currentPage: 0,
  totalPages: 0,

  clientsCount: [
    { value: "0 - Startup" },
    { value: "1 - 10K" },
    { value: "10K - 20K" },
    { value: "20K - 50K" },
    { value: "50K - 100K" },
    { value: "100K+" }
  ],
  statusList: [
    { name: "Pending", value: 'pending' },
    { name: "Rejected", value: 'rejected' }
  ],

  modal: {
    isDetailModal: false,
    warningModal: false,
    approveRejectID: [],
    rowId: null,
    rowData: null
  },

  isOpenReviewModal: false,

  channelMaxValue: 0,
  channelMinValue: 0,
  warningModalBtnText: "",
  warningModalIsDangerButton: true,
  isMultipleAction: false,

  // for  update  table  columns
  defaultSettings: [],
  updateColumnsFromBack: 'review/updateReviewSettings',
  updateColumnSettingsCommit: 'review/setDefaultSettings',
  isDefault: false,
  reviewsResult: false,

  // approveRejectID: []
  isTableLoading: false
};

export const review = {
  namespaced: true,
  actions: {
    getReviewList({ rootState, state, commit }, filter = null) {
      let options = {
        page: state.currentPage ? state.currentPage : 1
      }

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      if (filter) {
        options.limit = state.filter.limit
        Object.assign(options, filter)
      } else {
        Object.assign(options, state.filter)
      }
      
      let query = Object.keys(options).map(
          key => key + '=' + options[key]
      ).join('&')
      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = 'blob';
      } else {
        commit("setIsTableLoading", true)
      }
      return RequestService.read(`ottProviders/registration?${query}`, readOptions)
          .then(response => {
            if (!isExcelRequest) {
              if (response.data.results && response.data.results.length) {
                commit('setData', state.reviewsResult = true)
              } else {
                commit('setData', state.reviewsResult = false)
              }

              commit("setReviewData", response.data)
              commit("setIsTableLoading", false)
              return Promise.resolve(response.data)
            } else {
              FileDownload(response.data, 'reviewa.xlsx'); // TODO actual file name from response.headers
            }
          })
          .catch((e) => console.log(e))
          .finally(() => { commit("setIsTableLoading", false) })
    },
    getReviewSettings({ commit, state }) {
      return RequestService.read(`users/settings`)
          .then((data) => {
            const limitData = data?.data?.reviewSettings?.limit ? data.data.reviewSettings.limit : state.filter.limit
            commit('setDefaultSettingsForBack', data.data)
            commit('setLimit', limitData)
            return Promise.resolve(data.data)
          })
          .catch((e) => console.log(e))
    },
    updateReviewSettings({state, commit}, data) {
      return RequestService.update(`users/settings`, {
        reviewSettings: {
          reviewTable: data,
          isDefault: state.isDefault,
          limit: state.filter.limit
        }
      })
          .then((r) => { return Promise.resolve(r.data) })
          .catch((e) => console.log(e))
    },
    approveReviewData({state, commit}, data) {
      return RequestService.update(`ottProviders/registration/approve`, data)
          .then(response => {
            return Promise.resolve(response.data)
          })
          .catch((e) => console.log(e))
    }
  },

  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    setIsTableLoading(state, value) {
      state.isTableLoading = value
    },

    setIsOpenModal(state, value) {
      state.isOpenReviewModal = value
    },

    /**
     * Set pagination filter
     * **/
    setFilterPage(state, page) {
      state.filter.page = page;
      state.currentPage = page;
    },
    resetFilterModel(state) {
      state.filter = {}
      state.filter.page = 1
      state.filter.limit = 10
      state.filter.sortBy = "_id:desc"
      state.filterData = {}
    },

    sortColumn(state, sort = false) {
      if (sort?.sortValue) {
        state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue
      } else {
        if (!state.filterData?.sortBy) { 
          state.filterData.sortBy = "_id:desc"; 
        }
      }
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue
    },

    setReviewData(state, data) {
      state.dataTable = data ? data.results : data
      state.currentPage = data?.page
      state.totalPages = data?.totalPages
      state.dataCount = data?.totalResults
    },

    filterReviews(state) {
      const filterObject = {}
      if (state.filterModel.search) {
        filterObject.search = state.filterModel.search
      }
      if (state.filterModel.state === 'pending') {
        filterObject.state = 0
      } else if (state.filterModel.state === 'reject') {
        filterObject.state = 2
      }
      if (state.filterModel.channelsCount[1]) {
        filterObject.channelMinCount = state.filterModel.channelsCount[0]
        filterObject.channelMaxCount = state.filterModel.channelsCount[1]
      }
      if (state.filterModel.clientsCount !== null) {
        filterObject.clientAmount = state.filterModel.clientsCount
      }
      if (state.filterModel.date?.start && state.filterModel.date?.end) {
        filterObject.dateFrom = state.filterModel.date?.start
        filterObject.dateTo = state.filterModel.date?.end
      }
      if (state.filterData?.sortBy) {
        filterObject.sortBy = state.filterData.sortBy
      }

      state.filterData = filterObject
    },

    reviewDetail(state, data) {
      state.rowData = data.rowData
    },

    closeModal(state, data) {
      state.modal[data] = false;
    },
    openModal(state, data) {
      state.modal[data] = true;
    },
    setApproveRejectID(state, data) {
      state.approveRejectID = data;
    },

    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings;
        state.isDefault = data.isDefault
      }
    },

    setDefaultSettingsForBack(state, data) {
      if (data.reviewSettings) {
        state.isDefault = data.reviewSettings.isDefault
        state.defaultSettings = data.reviewSettings.reviewTable
      }
    },
  }
};
