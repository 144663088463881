import UsersFilterModel from "../../models/users/UsersFilterModel";
import RequestService from "@/services/request.service";
const FileDownload = require('js-file-download');

const InitialState = {
  filterModel: new UsersFilterModel(),
  modal: {
    isOpen: false
  },
  activeTab: 0,
  disableTabs: 0,
  disableButton: true,
  disableFlag: false,
  showList: [
    {name: "General Info", title: "generalInfo"},
    {name: "Security & Login", title: "securityLogin"},
    {name: "Roles", title: "roles"},
    {name: "Bot", title: "bot"},
  ],
  enableList: [{name: 'Enable', value: 'enable'}, {name: 'Disable', value: 'disable'}],

  validateAllTabs: {},

  toSaveData: {
    accessEnable:true
  },
  dataTable: [],

  //  for  pagination  table
  dataCount: 0,
  currentPage: 0,
  totalPages: 0,

  // actions history
  historyQueryOptions : {
    limit: 20,
    page: 1
  },

  showActionsHistory: false,
  historyDataTable: [],
  historyDataCount: 0,
  historyCurrentPage: 0,
  historyTotalPages: 0,

  userId: null,

  showRoles: true,

  filter: {
    limit: 20,
    sortBy: "_id:desc",
    page: 1
  },

  // for  update  table  columns
  defaultSettings: [],
  updateColumnsFromBack: 'users/updateUsersSettings',
  updateColumnSettingsCommit: 'users/setDefaultSettings',
  isDefault: false,
  deleteUserIsLoading: false,
  showWarningModal: false,

  usersResult: false,
  isTableLoading: false,
  permissionsObject: null,
  historyDate: {
    start: null,
    end: null
  },
  userData: ''
};

export const users = {
  namespaced: true,
  actions: {
    addUser({state, commit}) {
      return RequestService.create(
        `users`,
        state.toSaveData
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    updateUser({state, commit}, userId) {
      return RequestService.update(
        `users/edit/${userId}`,
        state.toSaveData
      ).then(
        data => {
          commit("setData", { userData: data.data })
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getUsersList({ rootState, state, commit }, filter = null) {
      let options = {
        page: state.currentPage ? state.currentPage : 1
      }

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      if (filter) {
        options.limit = ('limit' in filter) ? filter.limit : state.filter.limit
        options = {...options, ...filter}

      } else {
        options = {...options, ...state.filter}
      }
      
      let query = Object.keys(options).map(
          key => key + '=' + options[key]
      ).join('&')

      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = 'blob';
      } else {
        commit('setData', { isTableLoading: true })
      }

      return RequestService.read(
        `users/registration?${query}`, readOptions
      ).then(
        data => {
          if (!isExcelRequest) {
            if (data.data.results && data.data.results.length) {
              commit('setData', state.usersResult = true)
            } else {
              commit('setData', state.usersResult = false)
            }
            commit('setData', {
              dataCount : data.data.totalResults,
              dataTable : data.data.results.filter(item => item.status),
              currentPage : data.data.page,
              totalPages : data.data.totalPages,
              isTableLoading: false
            })
          } else {
            FileDownload(data.data, 'report.xlsx'); // TODO actual file name from response.headers
          }
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      ).finally(() => commit('setData', { isTableLoading: false }));
    },
    getCalendarUsersList({ rootState, state, commit }, filter = null) {
      let options = {
        page: state.currentPage ? state.currentPage : 1
      }

      if (rootState.general.isSelectedAll) {
        options.all = true
      }

      if (filter) {
        options.limit = ('limit' in filter) ? filter.limit : state.filter.limit
        options = {...options, ...filter}

      } else {
        options = {...options, ...state.filter}
      }
      
      let query = Object.keys(options).map(
          key => key + '=' + options[key]
      ).join('&')

      const readOptions = {};
      const isExcelRequest = filter && filter.excel;
      if (isExcelRequest) {
        readOptions.responseType = 'blob';
      } else {
        commit('setData', { isTableLoading: true })
      }

      return RequestService.read(
        `users/calendar?${query}`, readOptions
      ).then(
        data => {
          if (!isExcelRequest) {
            if (data.data.results && data.data.results.length) {
              commit('setData', state.usersResult = true)
            } else {
              commit('setData', state.usersResult = false)
            }
            commit('setData', {
              dataCount : data.data.totalResults,
              dataTable : data.data.results.filter(item => item.status),
              currentPage : data.data.page,
              totalPages : data.data.totalPages,
              isTableLoading: false
            })
          } else {
            FileDownload(data.data, 'report.xlsx'); // TODO actual file name from response.headers
          }
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      ).finally(() => commit('setData', { isTableLoading: false }));
    },

    getUserById({commit}, userId) {
      return RequestService.read(
        `users/edit/${userId}`,
      ).then(
        data => {
          commit("setData", { userData: data.data })
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    deleteUsers({commit}, data) {
      return RequestService.update(`users/deleteMultiply/`, {...data})
        .then(response => {
          return Promise.resolve(response.data)
        })
        .catch(e => console.log(e))
    },
    enableDisableUsers({commit}, data) {
      return RequestService.update(`users/enableDisable`, {...data})
      .then(r => { return Promise.resolve(r.data) }).catch(e => console.log(e))
    },
    resetPasswordUser({commit}, data) {
      return RequestService.create(
        `users/email/reset-password`, data
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    resetUsersLoginCount({commit}, userId) {
      return RequestService.read(
        `users/reset-login-count/${userId}`
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getUsersSettings({commit, state}) {
      return RequestService.read(
        `users/settings`
      ).then(
        data => {
          const limitData = data?.data?.defaultSettings?.limit ? data.data.defaultSettings.limit : state.filter.limit
          commit('setDefaultSettingsForBack', data.data)
          commit('setLimit', limitData)
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    updateUsersSettings({state}, data) {
      return RequestService.update(
        `users/settings`, {
          defaultSettings: {
            defaultSettingsColumns: data,
            isDefault: state.isDefault,
            limit: state.filter.limit
          }
        }
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    checkUsersEmail({ commit }, queryOptions) {
      let query = Object.keys(queryOptions)
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      return RequestService.read(`users/email/check-mail?${query}`).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    checkUsersPhone({ commit }, queryOptions) {
      let query = Object.keys(queryOptions)
        .map((key) => key + "=" + queryOptions[key])
        .join("&");

      return RequestService.read(`users/phone/check-phone?${query}`).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getUserActionHistory({ state, commit }, { userId = null, filter = {} }) {
      let queryOptions = { ...state.historyQueryOptions }
      
      if (state.historyDate?.start && state.historyDate?.end) {
        queryOptions.from = state.historyDate?.start
        queryOptions.to = state.historyDate?.end
      }

      if (filter) {
        queryOptions = {...queryOptions, ...filter}
      }
      let query = Object.keys(queryOptions).map(key => key + '=' + queryOptions[key]).join('&');

      return RequestService.read( `users/activity/${ userId }?${query}`)
        .then(response => {
          commit('setData', {
            historyDataCount : response.data.totalResults,
            historyDataTable : response.data.results,
            historyCurrentPage : response.data.page,
            historyTotalPages : response.data.totalPages,
          })       
      }).catch(e => console.log(e))
    },
  },

  state: InitialState,
  mutations: {
    /**
     * for all first level keys changing
     * setData({ key: 'value' })
    */
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    setHistoryQuery(state, data) {
      for (const key in data) {
        state.historyQueryOptions[key] = data[key];
      }
    },

    setLimit(state, newValue) {
      state.filter.limit = newValue
    },

    setFilterPage(state, newValue) {
      state.filter.page = newValue
      state.currentPage = newValue;
    },

    setHistoryFilterPage(state, newVal) {
      state.historyCurrentPage = newVal;
    },

    /***
     * for all second level object keys changing
     *  setDataSecondLevels({
     *    stateKey1: { changingKey: 'newVal' },
     *    stateKey2: { changingKey2: 'newVal' },
     *  })
    **/
    setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if(state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },

    /**
     * Set  settings  changes  for  column  list
     * **/
    setDefaultSettings(state, data) {
      if (data.defaultSettings) {
        state.defaultSettings = data.defaultSettings
        state.isDefault = data.isDefault
      }
    },

    setDefaultSettingsForBack(state, data) {
      if (data.defaultSettings && data.defaultSettings.defaultSettingsColumns) {
        state.defaultSettings = data.defaultSettings.defaultSettingsColumns
        state.isDefault = data.defaultSettings.isDefault
      }
    },

    /**
     * Filter Data
     * **/

    filterUsers(state) {
      if (state.filterModel.search) {
        state.filter.search = state.filterModel.search
      } else {
        delete state.filter.search
      }

      if (state.filterModel.roles.length) {
        let cashier = state.filterModel.roles.some(item => item === 0)
        let support = state.filterModel.roles.some(item => item === 1)
        let admin = state.filterModel.roles.some(item => item === 2)
        let advancedCashier = state.filterModel.roles.some(item => item === 3)
        let equipmentInstaller = state.filterModel.roles.some(item => item === 4)
        cashier ? state.filter.cashier = true : delete state.filter.cashier
        support ? state.filter.support = true : delete state.filter.support
        admin ? state.filter.admin = true : delete state.filter.admin
        advancedCashier ? state.filter.advancedCashier = true : delete state.filter.advancedCashier
        equipmentInstaller ? state.filter.equipmentInstaller = true : delete state.filter.equipmentInstaller
      } else {
        delete state.filter.cashier
        delete state.filter.support
        delete state.filter.admin
        delete state.filter.advancedCashier
        delete state.filter.equipmentInstaller
      }

      if (state.filterModel.enable) {
        state.filter.accessEnable = state.filterModel.enable === 'enable'
      } else {
        delete state.filter.accessEnable
      }
    },

    sortColumn(state, sort = null) {
      if (sort?.sortValue) {
        state.filter.sortBy = sort.sortKey + ":" + sort.sortValue;
      } else {
        if (!state.filter?.sortBy) { 
          state.filter.sortBy = "_id:desc"; 
        }
      }
    },
    resetFilterModel(state) {
      state.filter = {}
      state.filter.page = 1
      state.filter.limit = 10
      state.filter.sortBy = "_id:desc"
    },

    /**
     * Create data model for add user
     * **/
    toSaveData(state, data) {
      if (data.generalInfo !== undefined) {
        data.generalInfo.avatar.length ? state.toSaveData.avatar = data.generalInfo.avatar : ''
        data.generalInfo.firstname !== undefined ? state.toSaveData.firstname = data.generalInfo.firstname : ''
        data.generalInfo.lastname !== undefined ? state.toSaveData.lastname = data.generalInfo.lastname : ''
        data.generalInfo.address !== undefined ? state.toSaveData.address = data.generalInfo.address : ''
        data.generalInfo.phone !== undefined ? state.toSaveData.phone = {phoneNumber: data.generalInfo.phone, countryCode: data.generalInfo.countryCode} : ''
        data.generalInfo.accessEnable !== undefined ? state.toSaveData.accessEnable = data.generalInfo.accessEnable : ''
        data.generalInfo.showUnsuccessfulTransactions !== undefined ? state.toSaveData.showUnsuccessfulTransactions = !!data.generalInfo.showUnsuccessfulTransactions : ''
        data.generalInfo.color?.hexa !== undefined ? state.toSaveData.color =  data.generalInfo.color : ''
        if (data.generalInfo.color) {
          typeof data.generalInfo.color === 'string' ? 
            state.toSaveData.color = data.generalInfo.color : 
            state.toSaveData.color = data.generalInfo.color?.hex 
        }
    
      }

      if (data.securityLogin !== undefined) {
        data.securityLogin.email !== undefined ? state.toSaveData.email = data.securityLogin.email : ''
        data.securityLogin.password !== undefined ? state.toSaveData.password = data.securityLogin.password : ''
        data.securityLogin.confirmPassword !== undefined ? state.toSaveData.confirmPassword = data.securityLogin.confirmPassword : ''
      }

      data.role !== undefined ? state.toSaveData.roles = data.role : ''
      data.rolesInfo !== undefined ? state.toSaveData.rolesInfo = data.rolesInfo : ''
      state.toSaveData.provider = data.userId
    },

    /**
     * Open add or  edit user modal
     * **/
    openModal(state, data) {
      state.modal.isOpen = true;

    },
    /**
     * Close modal
     * **/
    closeModal(state, data) {
      state.modal.isOpen = false;
      state.activeTab = 0;
      state.disableTabs = 0;
      state.toSaveData = {accessEnable:true}
      state.userId = null
      state.disableFlag = false
      state.disableButton = true
      state.showRoles =  true
    },

    /**
     * Change modal  tabs onclick next button
     * **/
    nextTab(state, data) {
      if (state.activeTab < state.showList.length - 1) {
        state.activeTab = ++state.activeTab;
        if (state.activeTab > state.disableTabs) {
          state.disableTabs = ++state.disableTabs;
        }
      }
    },

    /**
     * Remove disable tabs
     * **/
    disableSaveCloseButton(state) {
      state.disableTabs = state.showList.length;
    },

    resetHistoryDate(state) {
      state.historyDate.start = null
      state.historyDate.end = null
    },

    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))
      }
    },

    setUserData(state, data) {
      state.userData = data
    }

  }
};
