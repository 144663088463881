import RequestService from "@/services/request.service";

const InitialState = {


  paymentType: {
    creditCard: 'CreditCard',
    bankAccount: 'BankAccount'
  },
  bankAccountType: {
    personal: 'Personal',
    business: 'Business'
  },
  selectedPaymentType: 'CreditCard',
  paymentList: [],
  editMode: false,
  addMode: false,

  creditCard: {
    cardNumber: '',
    cardholderName: '',
    brand: '',
    expireDate: null,
    cvc: null,
    month: '',
    year: '',
    address: '',
    country: '',
    city: '',
    suite: '',
    state: '',
    zip: '',
    existingAddress: '',
    default: true,
    inUse: true,
    anExistingAddress: false,
    isLoading: false,
    phone: ''
  },
  bankTransfer: {
    bankName:'',
    routingNumber:'',
    accountNumber:'',
    companyName:'',
    firstname:'',
    lastname:'',
    nickname:'',
    type:"Personal",
    default: true,
    inUse: true,
  },
  anExistingAddress: false,
  toSaveDataCreditCard: {},
  cloneCreditCard: null,
  cloneDataTransfer: null,
  activeIndex:null,
  disableCheckTab: false
};

export const paymentMethodsAdmin = {
  namespaced: true,
  actions: {
    addPaymentMethod({state, rootState}) {
      // if (state.anExistingAddress) { 
      //   rootState.companyAddresses.addressesList.map(address => { 
      //     if(address.id === state.creditCard.existingAddress) { 
      //       state.toSaveDataCreditCard.creditCard.existingAddress = {name:address.name} 
      //     } 
      //  })
      // }

      return RequestService.create(
        `ottProviders/paymentMethods`, state.toSaveDataCreditCard
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getPaymentDataList({commit}, providerId) {
      return RequestService.read(
        `ottProviders/paymentMethods?providerId=${providerId}&limit=1000`
      ).then(
        data => {
          commit('fetchData', data.data.results)
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    deletePaymentData({commit}, id) {
      return RequestService.delete(
        `ottProviders/paymentMethods/edit/${id}`
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    updateCreditCard({state,rootState}){
      // if (state.anExistingAddress) { 
      //   rootState.companyAddresses.addressesList.map(address => { 
      //     if(address.id === state.creditCard.existingAddress) { 
      //       state.toSaveDataCreditCard.creditCard.existingAddress = {name:address.name} 
      //     } 
      //  })
      // }

      return RequestService.update(
        `ottProviders/paymentMethods/edit/${state.creditCard.id}`,
        state.toSaveDataCreditCard
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    updateCreditCardBeforeDelete({state,commit}, data){
      return RequestService.update(
        `ottProviders/paymentMethods/edit/${data.id}`,
        data.data
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    updateBankAccount({state,commit}){
      return RequestService.update(
        `ottProviders/paymentMethods/edit/${state.bankTransfer.id}`,
        state.toSaveDataCreditCard
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    getPaymentMethod({ commit }, id) {
      return RequestService.read(
        `ottProviders/paymentMethods/edit/${id}`
      ).then(r => console.log(r, 'paymentMethod'))
    }
  },
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {

    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    /**
     * get payment list
     * **/

    fetchData(state, data) {
      state.paymentList = data.map(item => {
        const body = {}
        if (item.creditCard) {
          body.paymentMethod = item.paymentMethod
          body.cardNumber = item.creditCard.cardNumber
          body.cardholderName = item.creditCard.cardholderName
          body.expireDate = item.creditCard.month + '/' + item.creditCard.year
          body.month = item.creditCard.month
          body.year = item.creditCard.year
          body.cvc = item.creditCard.cvc
          body.brand = item.creditCard.brand
          if (item.creditCard.anExistingAddress) {
            body.existingAddress = item.creditCard.existingAddress
            body.address = ''
            body.country = ''
            body.city = ''
            body.suite = ''
            body.state = ''
            body.zip = ''
            body.phone = ''
          } else {
            body.existingAddress = ''
            body.address = item.creditCard?.billingAddress?.address
            body.country = item.creditCard?.billingAddress?.country
            body.city = item.creditCard?.billingAddress?.city
            body.suite = item.creditCard?.billingAddress?.suite
            body.state = item.creditCard?.billingAddress?.state
            body.zip = item.creditCard?.billingAddress?.zip
            body.phone = item.creditCard?.billingAddress?.phone
          }
        }else{
          body.paymentMethod = item.paymentMethod
          body.bankName = item.bankTransfer?.bankName
          body.routingNumber = item.bankTransfer?.routingNumber
          body.accountNumber = item.bankTransfer?.accountNumber
          if(item.bankTransfer.personalAccount){
            body.firstname = item.bankTransfer?.personalData?.firstname
            body.lastname = item.bankTransfer?.personalData?.lastname
            body.nickname = item.bankTransfer?.personalData?.nickname
            body.companyName = ''
          }else{
            body.firstname = ''
            body.lastname = ''
            body.nickname = ''
            body.companyName = item.bankTransfer.companyName
          }
          body.type = item.bankTransfer.personalAccount? state.bankAccountType.personal :state.bankAccountType.business
        }
        body.id = item.id
        body.default = item.default
        body.inUse = item.inUse

        return body
      })
    },

    /**
     * Save  data  for create Credit Card
     * **/

    toSaveData(state, {providerId, cardBrand} = {providerId, cardBrand : null}) {
      state.toSaveDataCreditCard = {
        creditCard: {
          billingAddress:{}
        }
      }
      state.toSaveDataCreditCard.paymentMethod = state.selectedPaymentType===state.paymentType.creditCard? 0: 1;
      if(!state.editMode){
        state.toSaveDataCreditCard.providerId = providerId;
      }
      state.toSaveDataCreditCard.default = state.creditCard.default;
      state.toSaveDataCreditCard.inUse = state.creditCard.inUse;

      state.toSaveDataCreditCard.creditCard.cardNumber = state.creditCard.cardNumber;
      state.toSaveDataCreditCard.creditCard.cardholderName = state.creditCard.cardholderName;
      state.toSaveDataCreditCard.creditCard.cvc = state.creditCard.cvc;
      state.toSaveDataCreditCard.creditCard.month = state.creditCard.month;
      state.toSaveDataCreditCard.creditCard.year = state.creditCard.year;
      state.toSaveDataCreditCard.creditCard.anExistingAddress = !!state.anExistingAddress;
      cardBrand ? state.toSaveDataCreditCard.creditCard.brand = cardBrand : '';

      if (state.anExistingAddress) {
        state.toSaveDataCreditCard.creditCard.existingAddress  = state.creditCard.existingAddress;
       delete  state.toSaveDataCreditCard.creditCard.billingAddress;

      } else {
        state.toSaveDataCreditCard.creditCard.billingAddress.address = state.creditCard.address;
        state.toSaveDataCreditCard.creditCard.billingAddress.country = state.creditCard.country;
        state.toSaveDataCreditCard.creditCard.billingAddress.city = state.creditCard.city;
        state.toSaveDataCreditCard.creditCard.billingAddress.suite = state.creditCard.suite;
        state.toSaveDataCreditCard.creditCard.billingAddress.state = state.creditCard.state;
        state.toSaveDataCreditCard.creditCard.billingAddress.zip = state.creditCard.zip;
        state.toSaveDataCreditCard.creditCard.billingAddress.phone = state.creditCard.phone;
      }
    },

    toSaveDataBankAccount(state,providerId){
      state.toSaveDataCreditCard = {
        bankTransfer: {
          personalData:{}
        }
      }
      if(!state.editMode){
        state.toSaveDataCreditCard.providerId = providerId;
      }
      state.toSaveDataCreditCard.default = state.bankTransfer.default;
      state.toSaveDataCreditCard.inUse = state.bankTransfer.inUse;
      state.toSaveDataCreditCard.bankTransfer.bankName = state.bankTransfer.bankName;
      state.toSaveDataCreditCard.bankTransfer.routingNumber = state.bankTransfer.routingNumber;
      state.toSaveDataCreditCard.bankTransfer.accountNumber = state.bankTransfer.accountNumber;
      if(state.bankTransfer.type===state.bankAccountType.personal){
        state.toSaveDataCreditCard.bankTransfer.personalData.firstname = state.bankTransfer.firstname;
        state.toSaveDataCreditCard.bankTransfer.personalData.lastname = state.bankTransfer.lastname;
        state.toSaveDataCreditCard.bankTransfer.personalData.nickname = state.bankTransfer.nickname;
      }else{
        state.toSaveDataCreditCard.bankTransfer.companyName = state.bankTransfer.companyName;
        delete  state.toSaveDataCreditCard.bankTransfer.personalData;

      }
      state.toSaveDataCreditCard.paymentMethod = state.selectedPaymentType===state.paymentType.creditCard? 0: 1;
      state.toSaveDataCreditCard.bankTransfer.personalAccount = state.bankTransfer.type===state.bankAccountType.personal;
    },


    /**
     * Open add new payment method
     * **/

    addNewPaymentMethod(state) {

      state.addMode = true;
      state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
      state.bankTransfer = JSON.parse(JSON.stringify(InitialState.bankTransfer))
      state.selectedPaymentType = 'CreditCard'
      state.anExistingAddress = false
    },

    closeAddEditPaymentData(state) {
      state.addMode = false;
      state.editMode = false;
      if (state.creditCard.id || state.bankTransfer.id) {
        state.paymentList = state.paymentList.map(item => {
          if (state.creditCard.id) {
            const cloneData = JSON.parse(JSON.stringify(state.cloneCreditCard))
            if (item.id === state.creditCard.id) {
              item = cloneData
            }
            return item
          }else{
            const cloneData = JSON.parse(JSON.stringify(state.cloneDataTransfer))
            if (item.id === state.bankTransfer.id) {
              item = cloneData
            }
          return item
          }
        })
      }
      state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
      state.bankTransfer = JSON.parse(JSON.stringify(InitialState.bankTransfer))
      state.anExistingAddress = false
      state.cloneCreditCard = null;
      state.cloneDataTransfer = null;
      state.activeIndex = null
    },

    /**
     * Edit Payment Data
     * **/

    closeAddPayment(state){
      state.addMode = false;
      state.editMode = false;
      state.selectedPaymentType = 'CreditCard'
      state.anExistingAddress = false
      state.cloneCreditCard = null;
      state.cloneDataTransfer = null;
      state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
      state.bankTransfer = JSON.parse(JSON.stringify(InitialState.bankTransfer))
      state.activeIndex = null

    },

    /**
     * Select  payment  type
     * **/
    selectPaymentType(state, data) {
      state.selectedPaymentType = data;
    },


    /**
     *  Set Credit Card Data
     * **/

    setCardNumber(state, value) {
      state.creditCard.cardNumber = value
    },
    setCardholderName(state, value) {
      state.creditCard.cardholderName = value
    },
    setExpireDate(state, value) {
      state.creditCard.expireDate = value
    },
    setCVC(state, value) {
      state.creditCard.cvc = value
    },
    setAddress(state, value) {
      state.creditCard.address = value
    },
    setCountry(state, value) {
      state.creditCard.country = value
    },
    setCity(state, value) {
      state.creditCard.city = value
    },
    setSuit(state, value) {
      state.creditCard.suite = value
    },
    setZip(state, value) {
      state.creditCard.zip = value
    },
    setExistingAddress(state, value) {
      state.creditCard.existingAddress = value
    },
    setMonth(state, value) {
      state.creditCard.month = value
    },
    setYear(state, value) {
      state.creditCard.year = value
    },
    setState(state, value) {
      state.creditCard.state = value
    },
    setIsDefault(state, value) {
      state.creditCard.default = value
    },

    setInUse(state, value) {
      state.creditCard.inUse = value
    },
    setPhone(state, value) {
      state.creditCard.phone = value
    },


    /**
     *  Set Bank Transfer Data
     * **/

    /***
    * for all second level object keys changing
    *  setDataSecondLevels({ 
    *    stateKey1: { changingKey: 'newVal' },
    *    stateKey2: { changingKey2: 'newVal' },  
    *  })
    **/
     setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if(state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },

    setBankName(state, value){
      state.bankTransfer.bankName = value
    },
    setRoutingNumber(state, value){
      state.bankTransfer.routingNumber = value
    },
    setAccountNumber(state, value){
      state.bankTransfer.accountNumber = value
    },
    setCompanyName(state, value){
      state.bankTransfer.companyName = value
    },
    setFirstName(state, value){
      state.bankTransfer.firstname = value
    },
    setLastName(state, value){
      state.bankTransfer.lastname = value
    },
    setNickName(state, value){
      state.bankTransfer.nickname = value
    },
      setType(state, value){
      state.bankTransfer.type = value
    },

    resetData(state) {
      state.paymentList = [];
      state.editMode = false;
      state.addMode = false;
      state.anExistingAddress = false;
      state.toSaveDataCreditCard = {};
      state.cloneDataTransfer = null;
      state.activeIndex = null;
      state.creditCard = JSON.parse(JSON.stringify(InitialState.creditCard))
      state.bankTransfer = JSON.parse(JSON.stringify(InitialState.bankTransfer))
      state.selectedPaymentType =  'CreditCard'
    },
    
    resetState(state) {
      for(let key in InitialState) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    },
    
  }
};
