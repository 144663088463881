<template>
  <selected-filters
    :selectedList="selectedList"
    @resetData="resetDataFunction($event)"
  />
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";
import timeZoneList from "@/constants/time-zone-list";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  components: { SelectedFilters },
  mixins: [ DateFormatMixin ],

  data(){
    return{
      timeZoneList,
    }
  },

  mounted() {
    console.log(this.currencyList, 'currencyList')
  },

  computed: {
    ...mapState({
      filterModel: state => state.clientsModule.filterModel,
      filterData: state => state.clientsModule.filterData,
      resellersList: state => state.providers.resellersList,
      priceGroupList: state => state.priceGroupAdmin.clientsPriceGroups,
      currencyList: state => state.clientsModule.currencyList,
      serverList: state => state.clientsModule.serverList,
    }),

    selectedList() {
      return [
        {
          ifCondition: this.filterModel.search.trim(),
          boxText: `<b>Search</b>: ${this.filterModel.search}`,
          resetArgument: 'search'
        },
        {
          ifCondition: this.filterModel.subscriptionState !== null,
          boxText: `<b>Status</b>: ${this.getStatusName}`,
          resetArgument: 'subscriptionState'
        },
        {
          ifCondition: this.filterModel.subscriptionState !== null && this.filterModel.statusFilterType,
          boxText: `<b>Filter Type</b>: <span class="text-capitalize">${this.filterModel.statusFilterType}</span>`,
          resetArgument: 'statusFilterType'
        },
        {
          ifCondition: this.filterModel.providers.length,
          boxText: "<div class='G-flex-center'><span class='me-2'><b>Providers</b>:</span> " + this.getProvidersNames,
          resetArgument: 'providers'
        },
        {
          ifCondition: !!this.filterModel.reseller,
          boxText: `<b>Reseller</b>: ${this.getResellerName}`,
          resetArgument: 'reseller'
        },
        {
          ifCondition: !!this.filterModel.priceGroup,
          boxText: `<b>Price Group</b>: ${this.getPriceGroupName}`,
          resetArgument: 'priceGroup'
        },
        {
          ifCondition: !!this.filterModel.currency,
          boxText: `<b>Currency</b>: ${this.getCurrencyName}`,
          resetArgument: 'currency'
        },
        {
          ifCondition: !!(this.filterModel.balance[0] || this.filterModel.balance[1]),
          boxText: `<b>Balance</b>: ${this.getBalanceValue}`,
          resetArgument: 'balance'
        },
        {
          ifCondition: !!(this.filterModel.credit[0] || this.filterModel.credit[1]),
          boxText: `<b>Credit</b>: ${this.getCreditValue}`,
          resetArgument: 'credit'
        },
        {
          ifCondition: this.filterModel.creditDate?.start && this.filterModel.creditDate?.end,
          boxText: `<b>Credit Date</b>: ${this.getCreditDateValue}`,
          resetArgument: 'creditDate'
        },
        {
          ifCondition: this.filterModel.creditExpired,
          boxText: '<b>Credit Expired</b>: Yes',
          resetArgument: 'creditExpired'
        },
        {
          ifCondition: this.filterModel.creditAutoExtend,
          boxText: '<b>Credit Auto Extend</b>: Yes',
          resetArgument: 'creditAutoExtend'
        },
        {
          ifCondition: !!(this.filterModel.debt[0] || this.filterModel.debt[1]),
          boxText: `<b>Debt</b>: ${this.getDebtValue}`,
          resetArgument: 'debt'
        },
        {
          ifCondition: this.filterModel.isBlocked,
          boxText: '<b>Is Blocked</b>: Yes',
          resetArgument: 'isBlocked'
        },
        {
          ifCondition: this.filterModel.inPaused,
          boxText: '<b>In Pause</b>: Yes',
          resetArgument: 'inPaused'
        },
        {
          ifCondition: !!(this.filterModel.activePackages[0] || this.filterModel.activePackages[1]),
          boxText: '<b>Active Packages</b>: ' + this.getActivePackagesValue,
          resetArgument: 'activePackages'
        },
        {
          ifCondition: this.filterModel.packagesDate?.start && this.filterModel.packagesDate?.end,
          boxText: '<b>Packages Date</b>: ' + this.getPackageDateValue,
          resetArgument: 'packagesDate'
        },
        {
          ifCondition: this.filterModel.packageExpired,
          boxText: '<b>Package Expired</b>: Yes',
          resetArgument: 'packageExpired'
        },
        {
          ifCondition: this.filterModel.autopayment,
          boxText: '<b>Auto Payment</b>: Yes',
          resetArgument: 'autopayment'
        },
        {
          ifCondition: !!this.filterModel.server,
          boxText: '<b>Server</b>: ' + this.getServerName,
          resetArgument: 'server'
        },
        {
          ifCondition: !!this.filterModel.timezone,
          boxText: '<b>Timezone</b>: ' + this.getTimezoneName,
          resetArgument: 'timezone'
        },
      ]
    },
    
    getProvidersNames() {
      let providersNames = '<span>'
      if (this.resellersList.length) {
        this.resellersList.map((i) => {
          this.filterModel.providers.map((j) => {
            if(i.id === j) {
              providersNames += `${i.name}${this.filterModel.providers.length > 1 ? '<br />' : ''}`
            }
          })
        })
      }
      providersNames += '</span>'

      return providersNames;
    },

    getStatusName() {
      let status = '';
      switch(this.filterModel.subscriptionState) {
        case '0':
          status = 'Inactive';
          break;
        case 1: 
          status = 'Pending';
          break;
        case 2:
          status = 'Canceled';
          break;
        case 3:
          status = 'Active';
          break;
        case 4:
          status = 'Paused';
          break;    
        case 5:
          status = 'Blocked';
          break;    
      }
      return status;
    },
      
    getResellerName(){
      let name = ''
      if (this.filterModel.reseller){
        name = this.resellersList.filter(x=>x.value===this.filterModel.reseller)[0].name
      }
      return name
    },

    getPriceGroupName(){
      let name = ''
      if (this.filterModel.priceGroup){
        name = this.filterModel.priceGroup
      }
      return name
    },

    getCurrencyName(){
      let name = ''
      if (this.filterModel?.currency){
        name = this.currencyList.filter(x=>x.value===this.filterModel.currency)[0]?.name ? 
          this.currencyList.filter(x=>x.value===this.filterModel.currency)[0]?.name : 
          this.currencyList.filter(x=>x.value===this.filterModel.currency)[0]?.value
      }
      return name
    },

    getBalanceValue(){
      let value = ''
      if (this.filterModel.balance[0] || this.filterModel.balance[1]){
        value = this.filterModel.balance[0] + ' - ' + this.filterModel.balance[1]
      }
      return value
    },

    getCreditValue(){
      let value = ''
      if (this.filterModel.credit[0] || this.filterModel.credit[1]){
        value = this.filterModel.credit[0] + ' - ' + this.filterModel.credit[1]
      }
      return value
    },

    getDebtValue(){
      let value = ''
      if (this.filterModel.debt[0] || this.filterModel.debt[1]){
        value = this.filterModel.debt[0] + ' - ' + this.filterModel.debt[1]
      }
      return value
    },

    getActivePackagesValue(){
      let value = ''
      if (this.filterModel.activePackages[0] || this.filterModel.activePackages[1]){
        value = this.filterModel.activePackages[0] + ' - ' + this.filterModel.activePackages[1]
      }
      return value
    },
    
    getCreditDateValue(){
      let value = ''
      if (this.filterModel.creditDate?.start && this.filterModel.creditDate?.end){
        value = this.$moment(this.filterModel.creditDate.start).format(this.ottDateFormat).valueOf() + ' - ' + this.$moment(this.filterModel.creditDate.end).format(this.ottDateFormat).valueOf()
      }
      return value
    },

    getPackageDateValue(){
      let value = ''
      if (this.filterModel.packagesDate?.start && this.filterModel.packagesDate?.end){
        value = this.$moment(this.filterModel.packagesDate.start).format(this.ottDateFormat).valueOf() + ' - ' + this.$moment(this.filterModel.packagesDate.end).format(this.ottDateFormat).valueOf()
      }
      return value
    },

    getServerName(){
      let name = ''
      if (this.filterModel.server){
        const filtered = this.serverList.filter(x => x.value === this.filterModel.server);
        name = filtered.length ? filtered[0].name : '';
      }
      return name
    },
    
    getTimezoneName(){
      let name = ''
      if (this.filterModel.timezone){
        name = this.timeZoneList.filter(x=>x.value===this.filterModel.timezone)[0].name
      }
      return name
    },
  },

  methods: {
    ...mapActions({
      getClientList: 'clientsModule/getClientList',
    }),

    ...mapMutations({
      filterClients: 'clientsModule/filterClients'
    }),

    async resetDataFunction(resetArgument) {
      typeof resetArgument === 'string' ? await this.resetData(resetArgument) : await resetArgument()
    },

    async resetData(type) {
      this.filterModel.resetCurrentData(type);

      if (type === 'subscriptionState') {
        this.filterModel.resetCurrentData('statusFilterType');
      }
      this.filterClients()
      this.filterModel.getFilterCount()
      await this.getClientList(this.filterData)
    },
  }, 
};
</script>

