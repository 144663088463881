const contryFormats = [
    {
        country: 'Albania',
        isoCountryCode: 'AL',
        format: 'yyyy-MM-dd'
    },
    {
        country: 'United Arab Emirates',
        isoCountryCode: 'AE',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Argentina',
        isoCountryCode: 'AR',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Australia',
        isoCountryCode: 'AU',
        format: 'd/MM/yyyy'
    },
    {
        country: 'Austria',
        isoCountryCode: 'AT',
        format: 'dd.MM.yyyy'
    },
    {
        country: 'Belgium',
        isoCountryCode: 'BE',
        format: 'd/MM/yyyy'
    },
    {
        country: 'Bulgaria',
        isoCountryCode: 'BG',
        format: 'yyyy-M-d'
    },
    {
        country: 'Bahrain',
        isoCountryCode: 'BH',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Bosnia and Herzegovina',
        isoCountryCode: 'BA',
        format: 'yyyy-MM-dd'
    },
    {
        country: 'Belarus',
        isoCountryCode: 'BY',
        format: 'd.M.yyyy'
    },
    {
        country: 'Bolivia',
        isoCountryCode: 'BO',
        format: 'dd-MM-yyyy'
    },
    {
        country: 'Brazil',
        isoCountryCode: 'BR',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Canada',
        isoCountryCode: 'CA',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Switzerland',
        isoCountryCode: 'CH',
        format: 'dd.MM.yyyy'
    },
    {
        country: 'Chile',
        isoCountryCode: 'CL',
        format: 'dd-MM-yyyy'
    },
    {
        country: 'China',
        isoCountryCode: 'CN',
        format: 'yyyy-M-d'
    },
    {
        country: 'Colombia',
        isoCountryCode: 'CO',
        format: 'd/MM/yyyy'
    },
    {
        country: 'Costa Rica',
        isoCountryCode: 'CR',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Cyprus',
        isoCountryCode: 'CY',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Czech Republic',
        isoCountryCode: 'CZ',
        format: 'd.M.yyyy'
    },
    {
        country: 'Germany',
        isoCountryCode: 'DE',
        format: 'dd.MM.yyyy'
    },
    {
        country: 'Denmark',
        isoCountryCode: 'DK',
        format: 'dd-MM-yyyy'
    },
    {
        country: 'Dominican Republic',
        isoCountryCode: 'DO',
        format: 'MM/dd/yyyy'
    },
    {
        country: 'Algeria',
        isoCountryCode: 'DZ',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Ecuador',
        isoCountryCode: 'EC',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Egypt',
        isoCountryCode: 'EG',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Spain',
        isoCountryCode: 'ES',
        format: 'd/MM/yyyy'
    },
    {
        country: 'Estonia',
        isoCountryCode: 'EE',
        format: 'd.MM.yyyy'
    },
    {
        country: 'Finland',
        isoCountryCode: 'FI',
        format: 'd.M.yyyy'
    },
    {
        country: 'France',
        isoCountryCode: 'FR',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'United Kingdom',
        isoCountryCode: 'GB',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Greece',
        isoCountryCode: 'GR',
        format: 'd/M/yyyy'
    },
    {
        country: 'Guatemala',
        isoCountryCode: 'GT',
        format: 'd/MM/yyyy'
    },
    {
        country: 'Hong Kong',
        isoCountryCode: 'HK',
        format: 'yyyy年M月d日'
    },
    {
        country: 'Honduras',
        isoCountryCode: 'HN',
        format: 'MM-dd-yyyy'
    },
    {
        country: 'Croatia',
        isoCountryCode: 'HR',
        format: 'dd.MM.yyyy.'
    },
    {
        country: 'Hungary',
        isoCountryCode: 'HU',
        format: 'yyyy.MM.dd.'
    },
    {
        country: 'Indonesia',
        isoCountryCode: 'ID',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'India',
        isoCountryCode: 'IN',
        format: 'd/M/yyyy'
    },
    {
        country: 'Ireland',
        isoCountryCode: 'IE',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Iraq',
        isoCountryCode: 'IQ',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Iceland',
        isoCountryCode: 'IS',
        format: 'd.M.yyyy'
    },
    {
        country: 'Israel',
        isoCountryCode: 'IL',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Italy',
        isoCountryCode: 'IT',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Jordan',
        isoCountryCode: 'JO',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Japan',
        isoCountryCode: 'JP',
        format: 'yyyy/MM/dd'
    },
    {
        country: 'South Korea',
        isoCountryCode: 'KR',
        format: 'yyyy. M. d'
    },
    {
        country: 'Kuwait',
        isoCountryCode: 'KW',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Lebanon',
        isoCountryCode: 'LB',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Libya',
        isoCountryCode: 'LY',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Lithuania',
        isoCountryCode: 'LT',
        format: 'yyyy.M.d'
    },
    {
        country: 'Luxembourg',
        isoCountryCode: 'LU',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Latvia',
        isoCountryCode: 'LV',
        format: 'yyyy.d.M'
    },
    {
        country: 'Morocco',
        isoCountryCode: 'MA',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Mexico',
        isoCountryCode: 'MX',
        format: 'd/MM/yyyy'
    },
    {
        country: 'Macedonia',
        isoCountryCode: 'MK',
        format: 'd.M.yyyy'
    },
    {
        country: 'Malta',
        isoCountryCode: 'MT',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Montenegro',
        isoCountryCode: 'ME',
        format: 'd.M.yyyy.'
    },
    {
        country: 'Malaysia',
        isoCountryCode: 'MY',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Nicaragua',
        isoCountryCode: 'NI',
        format: 'MM-dd-yyyy'
    },
    {
        country: 'Netherlands',
        isoCountryCode: 'NL',
        format: 'd-M-yyyy'
    },
    {
        country: 'Norway',
        isoCountryCode: 'NO',
        format: 'dd.MM.yyyy'
    },
    {
        country: 'New Zealand',
        isoCountryCode: 'NZ',
        format: 'd/MM/yyyy'
    },
    {
        country: 'Oman',
        isoCountryCode: 'OM',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Panama',
        isoCountryCode: 'PA',
        format: 'MM/dd/yyyy'
    },
    {
        country: 'Peru',
        isoCountryCode: 'PE',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Philippines',
        isoCountryCode: 'PH',
        format: 'M/d/yyyy'
    },
    {
        country: 'Poland',
        isoCountryCode: 'PL',
        format: 'dd.MM.yyyy'
    },
    {
        country: 'Puerto Rico',
        isoCountryCode: 'PR',
        format: 'MM-dd-yyyy'
    },
    {
        country: 'Portugal',
        isoCountryCode: 'PT',
        format: 'dd-MM-yyyy'
    },
    {
        country: 'Paraguay',
        isoCountryCode: 'PY',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Qatar',
        isoCountryCode: 'QA',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Romania',
        isoCountryCode: 'RO',
        format: 'dd.MM.yyyy'
    },
    {
        country: 'Russia',
        isoCountryCode: 'RU',
        format: 'dd.MM.yyyy'
    },
    {
        country: 'Saudi Arabia',
        isoCountryCode: 'SA',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Serbia and Montenegro',
        isoCountryCode: 'CS',
        format: 'd.M.yyyy.'
    },
    {
        country: 'Sudan',
        isoCountryCode: 'SD',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Singapore',
        isoCountryCode: 'SG',
        format: 'M/d/yyyy'
    },
    {
        country: 'El Salvador',
        isoCountryCode: 'SV',
        format: 'MM-dd-yyyy'
    },
    {
        country: 'Serbia',
        isoCountryCode: 'RS',
        format: 'd.M.yyyy.'
    },
    {
        country: 'Slovakia',
        isoCountryCode: 'SK',
        format: 'd.M.yyyy'
    },
    {
        country: 'Slovenia',
        isoCountryCode: 'SI',
        format: 'd.M.yyyy'
    },
    {
        country: 'Sweden',
        isoCountryCode: 'SE',
        format: 'yyyy-MM-dd'
    },
    {
        country: 'Syria',
        isoCountryCode: 'SY',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Thailand',
        isoCountryCode: 'TH',
        format: 'd/M/2555'
    },
    {
        country: 'Tunisia',
        isoCountryCode: 'TN',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Turkey',
        isoCountryCode: 'TR',
        format: 'dd.MM.yyyy'
    },
    {
        country: 'Taiwan',
        isoCountryCode: 'TW',
        format: 'yyyy/M/d'
    },
    {
        country: 'Ukraine',
        isoCountryCode: 'UA',
        format: 'dd.MM.yyyy'
    },
    {
        country: 'Uruguay',
        isoCountryCode: 'UY',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'United States',
        isoCountryCode: 'US',
        format: 'M/d/yyyy'
    },
    {
        country: 'Venezuela',
        isoCountryCode: 'VE',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Vietnam',
        isoCountryCode: 'VN',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'Yemen',
        isoCountryCode: 'YE',
        format: 'dd/MM/yyyy'
    },
    {
        country: 'South Africa',
        isoCountryCode: 'ZA',
        format: 'yyyy/MM/dd'
    },
]

export default contryFormats;