import RequestService from "@/services/request.service";
import { VListItemContent } from "vuetify/lib";

const InitialState = {
  isAutopay: false,
  isCards: false,
  isBank: false,

  selectedData: {
    selectAutoPay: null,
    selectCards: null,
    selectBank: null,
    autopayInterval: 0,
    autopayRetryCount: 0
  },

  // merchantList: [
  //   {name: "Authorize", value: 'authorize'},
  //   {name: "PayPal", value: 'paypal'},
  //   {name: "Stripe", value: 'stripe'}
  //   {name: "Clover", value: 'clover'}
  // ],
  autopayList: [],
  cardList: [],
  bankList: [],

  authorizeData: {
    apiLoginId: "",
    transactionKey: "",
    currentSignatureKey: "",
    isValid: null
  },

  payPalData: {
    username: "",
    password: "",
    signature: "",
    isValid: null
  },
  
  stripeData: {
    publicKey: "",
    secretKey: "",
    isValid: null
  },

  cloverData: {
    merchantId: "",
    secretKey: "",
    isValid: null
  },

  squareData: {
    applicationId: '',
    locationId: '',
    secretKey: '',
    isValid: null,
    isValidApplicationId: false
  },

  cloneAuthorizeData: JSON.parse(
    JSON.stringify({apiLoginId: "", transactionKey: "", currentSignatureKey: "", isValid: null})
  ),

  clonePayPalData: JSON.parse(
    JSON.stringify({
      username: "",
      password: "",
      signature: "", 
      isValid: null
    })
  ),
  
  cloneStripeData: JSON.parse(
    JSON.stringify({
      publicKey: "",
      secretKey: "", 
      isValid: null
    })
  ),

  cloneCloverData: JSON.parse(
    JSON.stringify({
      merchantId: "",
      secretKey: "", 
      isValid: null
    })
  ),

  cloneSquareData: JSON.parse(
    JSON.stringify({
      applicationId: '',
      locationId: '',
      secretKey: '',
      isValid: null
    })
  ),

  autoPayFee: {
    enabled: false,
    fixed: 0,
    percent: 0,
  },

  cardsFee: {
    enabled: false,
    fixed: 0,
    percent: 0,
  },

  bankFee: {
    enabled: false,
    fixed: 0,
    percent: 0,
  },

  isEditAuthorize: false,
  isEditPayPal: false,
  isEditStripe: false,
  isEditClover: false,
  isEditSquare: false,
  toSaveData: {},
  hideList: [],
  selectedDataFocus: null,
};

export const merchant = {
  namespaced: true,
  actions: {
    updatePaymentGateway({ commit, state, rootState, dispatch }, providerId) {
      commit('deleteIsValidKeys')

      return RequestService.update(
        `ottProviders/paymentGateways/edit/${providerId}`,
        state.toSaveData
      ).then(
        async data => {
          commit("fetchData", data.data);
          // await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    getPaymentSelectList({commit, state}, providerId) {
      return RequestService.read(
        `ottProviders/paymentGateways/methods/${providerId}`,
        state.toSaveData
      ).then(
        data => {
          commit('createPaymentList',data.data)
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    getPaymentGateway({state}, providerId) {
      return RequestService.read(
        `ottProviders/paymentGateways/edit/${providerId}`
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },
  state: InitialState,
  mutations: {
    /**
     * for all first level keys changing  
     * setData({ key: 'value' })
     */
    setData(state, data){
      for (let key in data) {
        state[key] = data[key]
      }
    },

    /***
     * for all second level object keys changing
     *  setDataSecondLevels({ 
     *    stateKey1: { changingKey: 'newVal' },
     *    stateKey2: { changingKey2: 'newVal' },  
     *  })
     **/
    setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if(state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },

    /**
     * get Payment  list
     * **/

    createPaymentList(state, data){
      if (data.length) {
        let autopay = data.filter(item => item.type === 0)
        let cards = data.filter(item => item.type === 1)
        let banks = data.filter(item => item.type === 2)

        state.autopayList = autopay[0].methods.map(item => {
          const body = {}
          switch(item) {
            case 'authorize': {
              body.value = item;
              body.name =  item.charAt(0).toUpperCase() + item.slice(1);
              break
            }
            case 'paypal': {
              body.value = item;
              body.name =  item.charAt(0).toUpperCase() + item.slice(1);
              break
            }
            case 'stripe': {
              body.value = item;
              body.name =  item.charAt(0).toUpperCase() + item.slice(1);
            }
            case 'clover': {
              body.value = item;
              body.name =  item.charAt(0).toUpperCase() + item.slice(1);
            }
            case 'square': {
              body.value = item;
              body.name =  item.charAt(0).toUpperCase() + item.slice(1);
            }
          }
          return body
        })

        state.cardList = cards[0].methods.map(item => {
          const body = {}
          switch(item) {
            case 'authorize': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
              break
            }
            case 'paypal': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
              break
            }
            case 'stripe': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
            }
            case 'clover': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
            }
            case 'square': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
            }
          }
          return body
        })

        state.bankList = banks[0].methods.map(item => {
          const body = {}
          switch(item) {
            case 'authorize': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
              break
            }
            case 'paypal': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
              break
            }
            case 'stripe': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
            }
            case 'clover': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
            }
            case 'square': {
              body.value = item;
              body.name = item.charAt(0).toUpperCase() + item.slice(1);
            }
          }
          return body
        })
      }
    },

    /**
     * to save data for add
     * **/

    toSaveData(state, providerId) {
      state.toSaveData.autopay = state.selectedData.selectAutoPay
      state.toSaveData.cards = state.selectedData.selectCards
      state.toSaveData.bank = state.selectedData.selectBank

      state.toSaveData.autopayInterval = +state.selectedData.autopayInterval
      state.toSaveData.autopayRetryCount = +state.selectedData.autopayRetryCount


      state.toSaveData.autopayCollectFeeClient = !!state.isAutopay
      state.toSaveData.cardsCollectFeeClient = !!state.isCards
      state.toSaveData.bankCollectFeeClient = !!state.isBank
      state.toSaveData.providerId = providerId

      state.toSaveData.autoPayFee = state.autoPayFee
      state.toSaveData.cardsFee = state.cardsFee
      state.toSaveData.bankFee = state.bankFee

      // if (state.authorizeData.apiLoginId) {     
        state.toSaveData.authorize = state.authorizeData
      // }

      // if (state.stripeData.secretKey) {     
        state.toSaveData.stripe = state.stripeData
      // }
  
      state.toSaveData.clover = state.cloverData
      state.toSaveData.square = state.squareData
      // if (state.payPalData.username) {     
        state.toSaveData.paypal = state.payPalData
      // }
    },

    deleteIsValidKeys(state) {
      if (state.authorizeData.isValid !== undefined) {
        delete state.authorizeData.isValid
      }

      if (state.stripeData.isValid !== undefined) {
        delete state.stripeData.isValid
      }

      if (state.cloverData.isValid !== undefined) {
        delete state.cloverData.isValid
      }

      if (state.squareData.isValid !== undefined) {
        delete state.squareData.isValid
      }

      if (state.payPalData.isValid !== undefined) {
        delete state.payPalData.isValid
      }

      if (state.autoPayFee.isValid !== undefined) {
        delete state.autoPayFee.isValid
      }

      if (state.cardsFee.isValid !== undefined) {
        delete state.cardsFee.isValid
      }

      if (state.bankFee.isValid !== undefined) {
        delete state.bankFee.isValid
      }

    },

    /**
     * fetch data from api
     * **/

    fetchData(state, data = {}) {
      state.selectedData.selectAutoPay = data.autopay;
      state.selectedData.selectCards = data.cards;
      state.selectedData.selectBank = data.bank;
      
      state.selectedData.autopayInterval = data?.autopayInterval;
      state.selectedData.autopayRetryCount = data?.autopayRetryCount;

      state.isAutopay = data.autopayCollectFeeClient
      state.isCards = data.cardsCollectFeeClient
      state.isBank = data.bankCollectFeeClient

      state.autoPayFee = data.autoPayFee
      state.cardsFee = data.cardsFee
      state.bankFee = data.bankFee

      //////authorizeData
      state.authorizeData = data.authorize; 
      if (data.authorize.apiLoginId === undefined) {
        state.authorizeData.apiLoginId = ""
      }

      if (data.authorize.transactionKey === undefined) {
        state.authorizeData.transactionKey = ""
      }

      if (data.authorize.currentSignatureKey === undefined) {
        state.authorizeData.currentSignatureKey = ""
      }    
     
      ////////payPalData
      state.payPalData = data.paypal;
      if (data.paypal.username === undefined) {
        state.payPalData.username = ""
      }

      if (data.paypal.password === undefined) {
        state.payPalData.password = ""
      }

      if (data.paypal.signature === undefined) {
        state.payPalData.signature = ""
      }
      
      ////////stripeData
      state.stripeData = data.stripe;
      if (data.stripe.publicKey === undefined) {
        state.stripeData.publicKey = ""
      }

      if (data.stripe.secretKey === undefined) {
        state.stripeData.secretKey = ""
      }

      ////////cloverData
      if ('clover' in data) {
        state.cloverData = data?.clover;
        if (data?.clover?.merchantId === undefined) {
          state.cloverData.merchantId = ""
        }

        if (data?.clover?.secretKey === undefined) {
          state.cloverData.secretKey = ""
        }
      }

      ////////squareData
      if ('square' in data) {
        state.squareData = data?.square;
        if (data?.square?.applicationId === undefined) {
          state.squareData.applicationId = ""
        }

        if (data?.square?.locationId === undefined) {
          state.squareData.locationId = ""
        }

        if (data?.square?.secretKey === undefined) {
          state.squareData.secretKey = ""
        }
      }
    },

    cancelAuthorizeData(state) {
      if (state.cloneAuthorizeData) {
        state.authorizeData = JSON.parse(
          JSON.stringify(state.cloneAuthorizeData)
        );
      }
    },

    saveAuthorizeData(state, data) {
      state.authorizeData = data;
      state.cloneAuthorizeData = JSON.parse(JSON.stringify(data));
      state.isEditAuthorize = true;
    },

    cancelPayPalData(state) {
      if (state.clonePayPalData) {
        state.payPalData = JSON.parse(JSON.stringify(state.clonePayPalData));
      }
    },

    savePayPalData(state, data) {
      state.payPalData = data;
      state.clonePayPalData = JSON.parse(JSON.stringify(data));
      state.isEditPayPal = true;
    },

    saveStripeData(state, data) {
      state.stripeData = data;
      state.cloneStripeData = JSON.parse(JSON.stringify(data));
      state.isEditStripe = true;
    },
    cancelStripeData(state) {
      if (state.cloneStripeData) {
        state.stripeData = JSON.parse(JSON.stringify(state.cloneStripeData));
      }
    },

    saveCloverData(state, data) {
      state.cloverData = data;
      state.cloneCloverData = JSON.parse(JSON.stringify(data));
      state.isEditClover = true;
    },
    cancelCloverData(state) {
      if (state.cloneCloverData) {
        state.cloverData = JSON.parse(JSON.stringify(state.cloneCloverData));
      }
    },

    saveSquareData(state, data) {
      state.squareData = data;
      state.cloneSquareData = JSON.parse(JSON.stringify(data));
      state.isEditSquare = true;
    },
    cancelSquareData(state) {
      if (state.cloneSquareData) {
        state.squareData = JSON.parse(JSON.stringify(state.cloneSquareData));
      }
    },

    setIsAutopay(state, value) {
      state.isAutopay = value;
    },
    setIsCards(state, value) {
      state.isCards = value;
    },
    setIsBank(state, value) {
      state.isBank = value;
    },

    setIsEditAuthorize(state) {
      state.isEditAuthorize = true;
    },
    setIsEditPayPal(state) {
      state.isEditPayPal = true;
    },
    setIsEditStripe(state) {
      state.isEditStripe = true;
    },
    setSelectedDataFocus(state, value) {
      state.selectedDataFocus = value;
    },

    //Authorize 
    setApiLoginId(state, value) {
      state.authorizeData.apiLoginId = value
    },

    setTransactionKey(state, value) {
      state.authorizeData.transactionKey = value
    },

    setCurrentSignatureKey(state, value) {
      state.authorizeData.currentSignatureKey = value
    },

    //PayPal 
    setUsername(state, value) {
      state.payPalData.username = value
    },

    setPassword(state, value) {
      state.payPalData.password = value
    },

    setSignature(state, value) {
      state.payPalData.signature = value
    },

    //Stripe 
    setPublicKey(state, value) {
      state.stripeData.publicKey = value
    },

    setSecretKey(state, value) {
      state.stripeData.secretKey = value
    },

    //Clover 
    setIsEditClover(state) {
      state.isEditClover = true;
    },

    setCloverMerchantId(state, value) {
      state.cloverData.merchantId = value
    },

    setCloverSecretKey(state, value) {
      state.cloverData.secretKey = value
    },

    //Square
    setSquareApplicationId(state, value) {
      state.squareData.applicationId = value
    },
    setSquareLocationId(state, value) {
      state.squareData.locationId = value
    },
    setSquareSecretKey(state, value) {
      state.squareData.secretKey = value
    },
    setSquareIsValidApplicationId(state, value) {
      state.squareData.isValidApplicationId = value
    },

   /**
     * change select  data  and remove  from selected list
     * **/

    updateMerchant(state, value) {
      if (state.selectedDataFocus) {
        let indexSelectedData = state.hideList.indexOf(state.selectedDataFocus)
        if (indexSelectedData >= -1) {
          state.hideList.splice(indexSelectedData, 1)
        }
      }
      let index = state.hideList.indexOf(value)
      if (index === -1 && value) {
        state.hideList.push(value)
      }
    },
    clearMerchantList(state, value) {
      let index = state.hideList.indexOf(value)
      state.selectedDataFocus = null
      if (index >= -1) {
        state.hideList.splice(index, 1)
      }
    },
    getMerchantListDataFromUpdater(state) {
      if (state.selectedData.selectAutoPay && state.hideList.indexOf(state.selectedData.selectAutoPay)===-1) {
        state.hideList.push(state.selectedData.selectAutoPay)
      }
      if (state.selectedData.selectCards && state.hideList.indexOf(state.selectedData.selectCards)===-1) {
        state.hideList.push(state.selectedData.selectCards)
      }
      if (state.selectedData.selectBank && state.hideList.indexOf(state.selectedData.selectBank)===-1) {
        state.hideList.push(state.selectedData.selectBank)
      }
      if (state.selectedData.autopayInterval && state.hideList.indexOf(state.selectedData.autopayInterval)===-1) {
        state.hideList.push(state.selectedData.autopayInterval)
      }
      if (state.selectedData.autopayRetryCount && state.hideList.indexOf(state.selectedData.autopayRetryCount)===-1) {
        state.hideList.push(state.selectedData.autopayRetryCount)
      }
    },
    // selectedData: {
    //   selectAutoPay: null,
    //   selectCards: null,
    //   selectBank: null
    // },

    resetData(state) {
      state.isAutopay = false;
      state.isCards = false;
      state.isBank = false;

      state.selectedData.selectAutoPay = null;
      state.selectedData.selectCards = null;
      state.selectedData.selectBank = null;
      state.selectedData.autopayInterval = 0;
      state.selectedData.autopayRetryCount = 0;
      
      state.authorizeData.apiLoginId = "";
      state.authorizeData.transactionKey = "";
      state.authorizeData.currentSignatureKey = "";
      state.payPalData.username = "";
      state.payPalData.password = "";
      state.payPalData.signature = "";
      state.stripeData.publicKey = "";
      state.stripeData.secretKey = "";
      state.cloverData.merchantId = "";
      state.cloverData.secretKey = "";
      state.squareData.applicationId = "";
      state.squareData.locationId = "";
      state.squareData.secretKey = "";
      state.squareData.isValidApplicationId = false;
      state.cloneAuthorizeData = null;
      state.clonePayPalData = null;
      state.cloneStripeData = null;
      state.cloneCloverData = null;
      state.cloneSquareData = null;
      state.isEditAuthorize = false;
      state.isEditPayPal = false;
      state.isEditStripe = false;
      state.isEditClover = false;
      state.isEditSquare = false;
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
