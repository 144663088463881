import RequestService from "@/services/request.service";

const InitialState = {
  priceGroupType: 1,

  formData: {
    name: '',
    type: 1,
    percent: null,
    digits: null,
    round: null,
    default: false
  },
  discountRoundingList: [
    {name: 'Off', value: 1},
    {name: 'Down', value: 2},
    {name: 'Up', value: 3},
  ],

  editMode: false,
  addMode: false,
  saveData: null,
  priceGroupList: [],
  cloneData: null,
  priceGroupAllList: [],
  clientsPriceGroups: [],
  providersPriceGroups: []
}

export const priceGroupAdmin = {
  namespaced: true,
  actions: {
    getPriceGroupById({commit}, priceGroupId){
      return RequestService.read(
        `priceGroups/edit/${priceGroupId}`,
      ).then(data => {
        return Promise.resolve(data.data)
      })
    },
    getPriceGroupList({commit, state}) {
      return RequestService.read(
        `priceGroups?type=${state.priceGroupType}&limit=1000`,
      ).then(
        data => {
          commit('fetchData', data.data)
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getPriceGroupByTypeList({commit}, options) {
      if (options?.type === 1) {
        commit('setData', { clientsPriceGroups: [] })
      }

      if (options?.type === 2) {
        commit('setData', { providersPriceGroups: [] })
      }

      let query = Object.keys(options).map(
        key => key + '=' + options[key]
      ).join('&');

      return RequestService.read(
        `priceGroups?${query}&limit=1000`,
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    addPriceGroup({commit, state}) {
      return RequestService.create(
        `priceGroups`, state.saveData
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    deletePriceGroup({}, data) {
      return RequestService.delete(
        `priceGroups/edit/${data}`
      ).catch((e) => console.log(e));
    },
    editPriceGroup({commit, state}) {
      return RequestService.update(
        `priceGroups/edit/${state.formData.id}`, state.saveData,
      ).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
  },
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for(const key in data) {
        state[key] = data[key]
      }
    },

    /**
     * Fetch data ist
     * **/

    fetchData(state, data) {
      state.priceGroupList = data.results
    },


    /**
     * Save data  to create price group
     * **/
    saveData(state) {

      state.saveData = {
        ...state.formData,
        name: [{lang: "en", name: state.formData.name}],
      }
      if (state.saveData.id) {
        delete state.saveData.id
      }
    },
    /***
     * select  price Group Data
     * */

    ChangePriceGroup(state, value) {
      state.priceGroupType = value;
      state.formData.type = value
    },
    addNewPriceGroup(state) {
      state.addMode = true;
      state.formData = {
        ...JSON.parse(JSON.stringify(InitialState.formData)),
        type: state.priceGroupType
      };
    },

    editPriceGroup(state, itemData) {
      state.editMode = true;
      state.formData.name = itemData.name[0].name;
      state.formData.type = itemData.type;
      state.formData.percent = itemData.percent;
      state.formData.digits = itemData.digits;
      state.formData.round = itemData.round;
      state.formData.default = itemData.default;
      state.formData.id = itemData.id;
      state.cloneData = JSON.parse(JSON.stringify(itemData))
    },

    closeData(state) {
      state.editMode = false;
      state.addMode = false;
      state.saveData = null;
      state.formData = {
        ...JSON.parse(JSON.stringify(InitialState.formData)),
        type: state.priceGroupType
      };
    },

    resetData(state) {
      if (state.priceGroupList.length) {
        state.priceGroupList.map(item => {
          if (item.id === state.formData.id) {
            item = state.cloneData
          }
          return item
        })
        this.cloneData = null
        state.editMode = false;
        state.addMode = false;
        state.saveData = null;
      }
      state.formData = {
        ...JSON.parse(JSON.stringify(InitialState.formData)),
        type: state.priceGroupType
      };
    },

    generateAllPriceGroups(state, data) {
      // client and provider price groups
      if (data && data.length > 0) {
        state.priceGroupAllList = [...data[0].results, ...data[1].results].map(item => {
          item.value = item.id
          return item
        })
        state.priceGroupAllList.unshift({
          name: [{ 
            name: 'Default',
            lang: 'en'
          }],
          value: 'default',
          id: 'default'
        })
      } else { // client price groups
        state.clientsPriceGroups = data.results.map(item => {
          item.value = item.id
          return item
        })
        state.clientsPriceGroups.unshift({
          name: [{ 
            name: 'Default',
            lang: 'en'
          }],
          value: 'default'
        })
      }
    },

    setProvidersPriceGroups(state, data) {
      if (data.results && data.results.length) {
        state.providersPriceGroups = data.results.map(item => {
          item.value = item.id
          return item
        })
        state.providersPriceGroups.unshift({
          name: [{ 
            name: 'Default',
            lang: 'en' 
          }],
          value: null
        })
      }
    },

    /**
     * Set Data discount
     * **/

    setGroupName(state, value) {
      state.formData.name = value
    },
    setInitialDiscount(state, value) {
      state.formData.percent = value
    },
    setDigits(state, value) {
      state.formData.digits = value
    },
    setDiscountRounding(state, value) {
      state.formData.round = value
    },
    
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
