<template>
  <div class="Ott-button-cont P-loader-button" :class="className">
    <v-btn @click="onClick" :ripple="ripple" :disabled="disabled">
      <div class="P-text G-flex G-align-center" v-if="!isLoading">
        <p v-if="buttonText">{{ buttonText }}</p>
        <div
          v-if="iconAfter"
          :class="iconAfterClass"
          class="after-icon"
        />
      </div>
      <div class="lds-ellipsis" v-if="isLoading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    iconAfterClass: {
      type: String,
      default: "mdi mdi-arrow-right"
    },
    iconAfter: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
      required: false
    },
    buttonText: {
      type: String,
      default: "Button",
      required: false
    },
    ripple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if (!this.isLoading) {
        this.$emit("onClick");
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";
.P-loader-button {
  position: relative;
  p {
    margin-bottom: 0;
  }
  button {
    width: 100%;
    height: 100%;
  }

  .fe-shopping-bag,
  .lds-ellipsis {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 20px;
  margin-left: 2px;
}

.lds-ellipsis div {
  position: absolute;
  top: 7px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}

@mixin button {
  font-weight: $semiBold;
  font-size: $txt16;
  text-transform: inherit !important;
  letter-spacing: 0.0492857143em !important;
  box-shadow: none;
  border-radius: $border-radius;
}
@mixin primaryColor-light {
  background-color: $primary-color !important;
  color: $white !important;
}
@mixin primaryColor-dark {
  background-color: $primary-colorDM;
  color: $white;
}
@mixin neutralColor-light {
  background-color: $neutral-color25;
  color: $secondary-color;
}
@mixin neutralColor-dark {
  background-color: $neutralBtnMD !important;
  color: $white !important;
}
@mixin shadow-light {
  @include box-shadow-sm;
  color: $secondary-color;
  background-color: $white;
}
@mixin shadow-dark {
  background-color: deeppink !important;
  color: orange !important;
}
.buttonSm {
  padding: 8px 24px !important;
  height: 38px !important;
  min-width: 86px !important;
}
.buttonMd {
  padding: 10px 26px !important;
  height: 40px !important;
}
.buttonIcon {
  width: 40px;
  height: 40px;
  font-size: $txt20;
}
.before-icon {
  margin-right: 14px;
  font-size: $txt20 !important;
}
.after-icon {
  margin-left: 6px;
  font-size: $txt20 !important;
}

/*theme*/
.theme--light {
  .primaryBtn {
    @include primaryColor-light;
    &:hover {
      cursor: pointer;
      background-color: $primary-color-dark !important;
    }
  }
  .primary-disabled {
    @include button;
    @include primaryColor-light;
    opacity: 0.5;
  }
}
.theme--dark {
  .primaryBtn {
    @include primaryColor-dark;

    &:hover {
      cursor: pointer;
      background-color: $primary-colorDM;
      color: $white;
    }
  }

  .primary-disabled {
    @include button;
    @include primaryColor-dark;
    opacity: 0.5;
  }
}
.theme--light {
  .neutralBtn {
    @include neutralColor-light;

    &:hover {
      cursor: pointer;
      color: $white;
      background-color: $primary-color-dark;
      .before-icon {
        color: $white;
        font-size: $txt20 !important;
      }
    }
    .before-icon {
      color: $neutral-color;
      font-size: $txt20 !important;
    }
  }

  .neutral-disabled {
    @include button;
    @include primaryColor-light;
    opacity: 0.5;
  }
}
.theme--dark {
  .neutralBtn {
    @include neutralColor-dark;

    &:hover {
      cursor: pointer;
      color: orange;
      background-color: purple;
    }
  }

  .neutral-disabled {
    @include button;
    @include primaryColor-dark;
    opacity: 0.5;
  }
}
.theme--light {
  .shadowBtn {
    @include shadow-light;
    border: 1.5px solid transparent !important;

    &:hover {
      cursor: pointer;
      color: $primary-color !important;
      border: 1.5px solid $primary-color !important;
    }
    &:hover::before {
      opacity: 0 !important;
    }
  }
  .iconBtn {
    @include shadow-light;
    color: $neutral-color;
    border: 1.5px solid transparent !important;
    &:hover {
      cursor: pointer;
      color: $primary-color !important;
      border: 1.5px solid $primary-color !important;
    }
    &:hover::before {
      opacity: 0 !important;
    }
  }

  .shadow-disabled {
    @include button;
    @include shadow-light;
    opacity: 0.5;
  }
}
.theme--dark {
  .shadowBtn {
    @include shadow-dark;
    &:hover {
      cursor: pointer;
      background-color: yellow;
      color: purple;
    }
  }
  .iconBtn {
    @include shadow-light;
    color: red;
    border: 1.5px solid transparent !important;
    &:hover {
      cursor: pointer;
      color: green !important;
      border: 1.5px solid yellow !important;
    }
    &:hover::before {
      opacity: 0 !important;
    }
  }
  .primary-disabled {
    @include button;
    @include primaryColor-dark;
    opacity: 0.5;
  }
}
.theme--light {
  .iconDangerBtn {
    background-color: $danger-color-light;
    color: $white;
    &:hover {
      background-color: $danger-color-dark;
    }
  }
}

/*.Ott-button-cont {*/
/*  width: 100%;*/
/*}*/

/*primary*/
.primary-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    @extend .primaryBtn;
  }

  .v-btn--disabled {
    @extend .primary-disabled;
  }
}

.primary-md-btn {
  button {
    @include button;
    @extend .buttonMd;
    @extend .primaryBtn;
  }

  .v-btn--disabled {
    @extend .primary-disabled;
  }
}

.primary-lg-btn {
  width: 100%;
  button {
    @include button;
    width: 100%;
    @extend .buttonMd;
    @extend .primaryBtn;
  }
  .v-btn--disabled {
    @extend .primary-disabled;
  }
  .after-icon {
    margin-left: 14px;
    font-size: 1.25rem;
    position: absolute;
    right: -10px;
  }
}

.primary-icon-btn::v-deep {
  button {
    @include button;
    @extend .buttonIcon;
    @extend .primaryBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 30px;
    font-size: 20px;
    padding: 10px 10px;
  }
}

/*neutral*/
.neutral-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    @extend .neutralBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .neutral-disabled;
  }
}

.neutral-md-btn {
  button {
    @include button;
    @extend .buttonMd;
    @extend .neutralBtn;
  }

  .v-btn--disabled {
    @extend .neutral-disabled;
  }
}

.neutral-lg-btn {
  width: 100%;
  button {
    @include button;
    width: 100%;
    @extend .buttonMd;
    @extend .neutralBtn;
  }

  .v-btn--disabled {
    @extend .neutral-disabled;
  }
  .after-icon {
    margin-left: 14px;
    font-size: 1.25rem;
    position: absolute;
    right: -10px;
  }
}
/*shadow*/
.shadow-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    @extend .shadowBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
}
.shadow-md-btn {
  button {
    @include button;
    @extend .buttonMd;
    @extend .shadowBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
}
.shadow-lg-btn {
  width: 100%;
  button {
    @include button;
    width: 100%;
    @extend .buttonMd;
    @extend .shadowBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
  .after-icon {
    margin-left: 14px;
    font-size: 1.25rem;
    position: absolute;
    right: -10px;
  }
}
.shadow-icon-btn::v-deep {
  button {
    @include button;
    @extend .buttonIcon;
    @extend .iconBtn;
  }
  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 30px;
    padding: 10px 10px;
  }
}

.danger-icon-btn {
  ::v-deep {
    button {
      @include button;
      @extend .buttonIcon;
      @extend .iconDangerBtn;
    }

    .v-btn.v-btn--disabled.v-btn--has-bg {
      @extend .shadow-disabled;
    }
    .only-icon-btn {
      font-size: $txt20;
    }

    .v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      min-width: 30px;
      padding: 10px 10px;
    }
  }
}
.red-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    background-color: $red !important;
    color: $white;
    &:hover {
      background-color: $danger-color-dark !important;
    }
  }
}
.theme--dark {
  .red-sm-btn {
    button {
      background-color: $redDM !important;
      &:hover {
        background-color: $redDarkDM !important;
      }
    }
  }
}

.green-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    background-color: $green !important;
    color: $white;
    &:hover {
      background-color: $greenDM !important;
    }
  }
}
.theme--dark {
  .green-sm-btn {
    button {
      background-color: $green !important;
      &:hover {
        background-color: $greenDM !important;
      }
    }
  }
}

::v-deep {
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #01B2B8 !important;
  }
}
</style>
