import UserModel from "@/models/users/UserModel";
import TokensModel from "@/models/auth/TokensModel";
import RequestService from "@/services/request.service";

const user = JSON.parse(localStorage.getItem("user"));

const InitialState = {
  status: { loggedIn: false },
  loggedIn: false,
  user: new UserModel(),
  tokens: new TokensModel(),
  userEmail: null,
  smartyStreetPublicKey: '',
  smartySrteetAuthId: '',
  smartyStreetAuthToken: '',
};

if (user) {
  InitialState.status.loggedIn = !!user.tokens;
  InitialState.user = new UserModel(user.sendUser);
  InitialState.tokens = new TokensModel(user.tokens);
  InitialState.loggedIn = !!InitialState.tokens?.access?.token;
}

export const auth = {
  namespaced: true,
  actions: {
    login({ commit }, user) {
      return RequestService.create("auth/login", {
        email: user.email,
        password: user.password
      }).then(
        response => {
          commit("loginSuccess", response.data);
          const smartyStreetKeys = {
            smartyStreetPublicKey: response.data.config?.smartyStreetPublicKey,
            smartyStreetAuthId: response.data.config?.smartyStreetAuthId,
            smartyStreetAuthToken: response.data.config?.smartyStreetAuthToken,
          }

          localStorage.setItem("redirect", response.data.redirect)
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      localStorage.removeItem("user");
      commit("logout");
      commit('sidebar/setActiveIndex', 0, { root: true })
    },
    passwordReset({ commit }, data) {
      return RequestService.create("auth/reset-password", {
        password: data.password,
        confirmPassword: data.confirmPassword,
        token: data.token
      }).then(
        data => {
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }
  },

  state: InitialState,
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },
    loginSuccess(state, data) {
      localStorage.setItem("user", JSON.stringify(data));
      state.status.loggedIn = true;
      state.loggedIn = true;
      state.tokens = new TokensModel(data.tokens);
      state.user = new UserModel(data.sendUser);
    },
    logout(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("redirect");
      state.status.loggedIn = false;
      state.loggedIn = false;
      state.user = new UserModel();
      state.tokens = new TokensModel();
    },
    updateAuthUser(state, data) {
      state.user = new UserModel(data);
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    }
  }
};
