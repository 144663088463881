const InitialState = {
    hubState: 0,
    providerSyncState: {},
    locationSyncState: {},
    requests: {},
    isSaved: false,
    isLoading: false
}

export const appModule = {
  namespaced: true,
  state: InitialState,
  actions: {
    success({commit}, data) {},
    setHubState({ dispatch, commit }, state) {
      commit('setHubState', state);
    },
    setRequestState({ dispatch, commit }, state) {
      commit('setHubState', state);
    },
  },
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key]
      }
    },
    makeProviderSyncState(state, data) {
        if (typeof data.providerId != "undefined" && typeof data.syncState !== "undefined") {
            state.providerSyncState[data.providerId] = data.syncState;
        }
    },
    makeLocationSyncState(state, data) {
        if (typeof data.locationId != "undefined" && typeof data.syncState !== "undefined") {
            state.locationSyncState[data.locationId] = data.syncState;
        }
    },
    setHubState(state, hubState) {
      state.hubState = hubState;
    },
  }
};
