<template>
  <div class="Ott-button-cont active " :class="customClass">
    <v-btn @click="click" :disabled="disabled" :ripple="ripple" dense>
      <div v-if="!isLoading" class="G-flex G-align-center">
        <div
            v-if="iconBefore && !onlyIcon"
            :class="iconBeforeClass"
            class="before-icon"
        />
        <span v-if="!onlyIcon">{{ text }}</span>
        <div
            v-if="iconAfter && !onlyIcon"
            :class="iconAfterClass"
            class="after-icon"
        />
      </div>
      <div class="lds-ellipsis" v-if="isLoading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div v-if="onlyIcon" :class="iconOnlyClass" class="only-icon-btn"></div>
    </v-btn>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "OttButton",
  props: {
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    iconBefore: {
      type: Boolean,
      default: false
    },
    iconAfter: {
      type: Boolean,
      default: false
    },
    onlyIcon: {
      type: Boolean,
      default: false
    },
    iconBeforeClass: {
      type: String,
      default: "mdi mdi-arrow-left"
    },
    iconAfterClass: {
      type: String,
      default: "mdi mdi-arrow-right"
    },
    iconOnlyClass: {
      type: String,
      default: "mdi mdi-plus"
    },
    text: {
      type: String,
      default: "Button"
    },
    loaderId: {
      type: String,
      default: null,
    },
    ripple: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String
    },

    click: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      authProviderId: (state) => state.auth.user.provider.id,
      selectedPaymentMethod: (state) =>
          state.balanceRefill.selectedPaymentMethod,
      paymentMethods: (state) => state.balanceRefill.paymentMethods,
      paymentMethodsAdmin: (state) => state.paymentMethodsAdmin,
      paymentList: state => state.appModule.paymentList
    }),
  },
  data() {
    return {};
  },
  methods: {
    // click() {
    //   this.$emit("click");
    // }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";

/*button classes*/
/*size - sm/md/lg*/
/*background color- primary/neutral/white*/

/*  ...  primary-sm-btn , primary-md-btn  , primary-lg-btn (full width)  /
    ...  neutral-sm-btn , neutral-md-btn  , neutral-lg-btn (full width)  /
    ...  shadow-sm-btn , shadow-md-btn  , shadow-lg-btn (full width)    /
    ...  shadow-icon-btn, primary-icon-btn, danger-icon-btn
    icon settings   iconBefore="true" iconBeforeClass="ex. mdi mdi-arrow-right"
                    iconAfter="true" iconAfterClass="ex. mdi mdi-arrow-right"
                    onlyIcon ="true" iconOnlyClass="ex. mdi mdi-arrow-right"



    */

@mixin button {
  font-weight: $semiBold;
  font-size: $txt16;
  letter-spacing: 0.0492857143em !important;
  box-shadow: none;
  border-radius: $border-radius;
  width: 100%;
  text-transform: capitalize !important;
}
@mixin primaryColor-light {
  background-color: $primary-color !important;
  color: $white !important;
}
@mixin primaryColor-dark {
  background-color: $primary-colorDM !important;
  color: $white !important;
}
@mixin neutralColor-light {
  background-color: $neutral-color25;
  color: $secondary-color;
}
@mixin neutralColor-dark {
  background-color: $neutralBtnMD !important;
  color: $white !important;
}
@mixin shadow-light {
  @include box-shadow-sm;
  color: $secondary-color;
  background-color: $white;
}
@mixin shadow-dark {
  background-color: deeppink !important;
  color: orange !important;
}
.buttonSm {
  padding: 8px 16px !important;
  height: 38px !important;
  min-width: 86px !important;
}
.buttonMd {
  padding: 10px 26px !important;
  height: 40px !important;
}
.buttonIcon {
  width: 30px;
  height: 30px;
  font-size: $txt20;
}
.before-icon {
  margin-right: 7px;
  font-size: $txt20 !important;
}
.after-icon {
  margin-left: 14px;
  font-size: $txt20 !important;
}
/*theme*/
.theme--light {
  .primaryBtn {
    @include primaryColor-light;
    &:hover {
      cursor: pointer;
      background-color: $primary-color-dark !important;
    }
  }
  .primary-disabled {
    @include button;
    @include primaryColor-light;
    opacity: 0.5;
  }
}
.theme--dark {
  .primaryBtn {
    @include primaryColor-dark;

    &:hover {
      cursor: pointer;
      background-color: $primary-colorDM !important;
      color: $white;
    }
  }
  .primary-disabled {
    @include button;
    @include primaryColor-dark;
    opacity: 0.5;
  }
}
.theme--light {
  .neutralBtn {
    @include neutralColor-light;
    &:hover {
      cursor: pointer;
      color: $white;
      background-color: $primary-color-dark;
      .before-icon {
        color: $white !important;
        font-size: $txt20 !important;
      }
    }
    .before-icon {
      color: $neutral-color;
      font-size: $txt20 !important;
    }
  }
  .neutral-disabled {
    @include button;
    @include primaryColor-light;
    opacity: 0.5;
  }
}
.theme--dark {
  .neutralBtn {
    @include neutralColor-dark;
    &:hover {
      cursor: pointer;
      color: $white !important;
      background-color: $primary-colorDM!important;
      .before-icon {
        color: $white !important;
        font-size: $txt20 !important;
      }
    }
    .before-icon {
      color: $white !important;
    }
  }
  .neutral-disabled {
    @include button;
    @include primaryColor-dark;
    opacity: 0.5;
  }
}
.theme--light {
  .shadowBtn {
    @include shadow-light;
    border: 1.5px solid transparent !important;

    &:hover {
      cursor: pointer;
      color: $primary-color-dark !important;
      border: 1.5px solid $primary-color-dark !important;
    }
    &:hover::before {
      opacity: 0 !important;
    }
  }
  .iconBtn {
    @include shadow-light;
    color: $neutral-color;
    border: 1.5px solid transparent !important;
    &:hover {
      cursor: pointer;
      color: $primary-color-dark  !important;
      border: 1.5px solid $primary-color-dark  !important;
    }
    &:hover::before {
      opacity: 0 !important;
    }
  }
  .shadow-disabled {
    @include button;
    @include shadow-light;
    opacity: 0.5;
  }
}
.theme--dark {
  .shadowBtn {
    @include shadow-dark;
    &:hover {
      cursor: pointer;
      background-color: yellow;
      color: purple;
    }
  }
  .iconBtn {
    @include shadow-light;
    color: red;
    border: 1.5px solid transparent !important;
    &:hover {
      cursor: pointer;
      color: green !important;
      border: 1.5px solid yellow !important;
    }
    &:hover::before {
      opacity: 0 !important;
    }
  }
  .primary-disabled {
    @include button;
    @include primaryColor-dark;
    opacity: 0.5;
  }
}
.theme--light {
  .iconDangerBtn {
    background-color: $danger-color-light;
    color: $white;
    &:hover {
      background-color: $danger-color-dark;
    }
  }
}

/*.Ott-button-cont {*/
/*  width: 100%;*/
/*}*/

/*primary*/
.primary-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    @extend .primaryBtn;
  }

  .v-btn--disabled {
    @extend .primary-disabled;
  }
  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .neutral-disabled;
  }
}

.primary-md-btn {
  button {
    @include button;
    @extend .buttonMd;
    @extend .primaryBtn;
  }

  .v-btn--disabled {
    @extend .primary-disabled;
  }
}

.primary-lg-btn {
  width: 100%;
  button {
    @include button;
    width: 100%;
    @extend .buttonMd;
    @extend .primaryBtn;
  }
  .v-btn--disabled {
    @extend .primary-disabled;
  }
  .after-icon {
    margin-left: 14px;
    font-size: 1.25rem;
    position: absolute;
    right: -10px;
  }
}

.primary-icon-btn::v-deep {
  button {
    @include button;
    @extend .buttonIcon;
    @extend .primaryBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 30px;
    font-size: 20px;
    padding: 10px 10px;
  }
}

/*neutral*/
.neutral-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    @extend .neutralBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .neutral-disabled;
  }
}
.primary-md-btn {
  button {
    @include button;
    @extend .buttonSm;
    @extend .neutralBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .neutral-disabled;
  }
}

.neutral-md-btn {
  button {
    @include button;
    @extend .buttonMd;
    @extend .neutralBtn;
  }

  .v-btn--disabled {
    @extend .neutral-disabled;
  }
}

.neutral-lg-btn {
  width: 100%;
  button {
    @include button;
    width: 100%;
    @extend .buttonMd;
    @extend .neutralBtn;
  }

  .v-btn--disabled {
    @extend .neutral-disabled;
  }
  .after-icon {
    margin-left: 14px;
    font-size: 1.25rem;
    position: absolute;
    right: -10px;
  }
}
/*shadow*/
.shadow-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    @extend .shadowBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
}
.shadow-md-btn {
  button {
    @include button;
    @extend .buttonMd;
    @extend .shadowBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
}
.shadow-lg-btn {
  width: 100%;
  button {
    @include button;
    width: 100%;
    @extend .buttonMd;
    @extend .shadowBtn;
  }

  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
  .after-icon {
    margin-left: 14px;
    font-size: 1.25rem;
    position: absolute;
    right: -10px;
  }
}
.shadow-icon-btn::v-deep {
  button {
    @include button;
    @extend .buttonIcon;
    @extend .iconBtn;
  }
  .v-btn.v-btn--disabled.v-btn--has-bg {
    @extend .shadow-disabled;
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
    min-width: 30px;
    padding: 10px 10px;
  }
}

.danger-icon-btn {
  ::v-deep {
    button {
      @include button;
      @extend .buttonIcon;
      @extend .iconDangerBtn;
    }

    .v-btn.v-btn--disabled.v-btn--has-bg {
      @extend .shadow-disabled;
    }
    .only-icon-btn {
      font-size: $txt20;
    }

    .v-btn:not(.v-btn--round).v-size--default {
      height: 40px;
      min-width: 30px;
      padding: 10px 10px;
    }
  }
}
.red-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    background-color: $red !important;
    color: $white;
    &:hover {
      background-color: $danger-color-dark !important;
    }
  }
}
.red-transparent-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    background-color: $white !important;
    color: $secondary-color;
    &:hover {
      color: $white;
      background-color: $danger-color-dark !important;
    }
  }
}
.transparent-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    background-color: $white !important;
    color: $secondary-color;
    &:hover {
      color: $secondary-color;
      background-color: $neutral-color25;
    }
  }
}

.theme--dark {
  .red-sm-btn {
    button {
      background-color: $redDM !important;
      &:hover {
        background-color: $redDarkDM !important;
      }
    }
  }
  .transparent-sm-btn {
    button {
      background-color: transparent !important;
      color: $white;
      &:hover {
        color: $white;
        background-color: $neutral-color25;
      }
    }
  }
  .red-transparent-sm-btn {
    button {
      background-color: transparent !important;
      color: $white;
      &:hover {
        color: $white;
        background-color: $redDarkDM !important;
      }
    }
  }
}

.green-sm-btn {
  button {
    @include button;
    @extend .buttonSm;
    background-color: $green !important;
    color: $white;
    &:hover {
      background-color: $greenDM !important;
    }
  }
}
.theme--dark {
  .green-sm-btn {
    button {
      background-color: $green !important;
      &:hover {
        background-color: $greenDM !important;
      }
    }
  }
  .primary-sm-btn, .primary-md-btn{
    button{
      &:hover{
        background-color: $primary-color !important;
      }
    }
  }
}

.fe-shopping-bag,
.lds-ellipsis {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: 0;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 20px;
  margin-left: 2px;
}

.lds-ellipsis div {
  position: absolute;
  top: 7px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
</style>
