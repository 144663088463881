import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            auth: (state) => state.auth,
        }),

        newDateWithMainUserTZ() {
            if (this.auth.user.provider.timezone !== undefined) {
                return new Date().toLocaleString("en-US", {
                    timeZone: this.auth.user.provider.timezone
                }) 
            } else { return new Date() }     
        }
    },
 };
  