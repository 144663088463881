import RequestService from "@/services/request.service";
import moment from "moment";
import ChatModel from "@/models/chat/ChatModel";
import _ from "lodash";
const FileDownload = require("js-file-download");

const InitialState = {
    dataTable: [],
    // filter data
    filter: {
        limit: 20,
        sortBy: "updatedAt:desc",
        page: 1,
    },
    filterData: {},
    filterModel: new ChatModel(),
    //  for table pagination
    dataCount: 0,
    currentPage: 1,
    totalPages: 0,
    // for  update  table  columns
    defaultSettings: [],
    updateColumnsFromBack: "chatModule/updateChatSettings",
    updateColumnSettingsCommit: "chatModule/setDefaultSettings",
    isDefault: false,
    chatResult: false,
    isTableLoading: false,
    // filter list by type
    listType: 'own',
    resellerActivity: false,
    showColumnConfig: true,
    smsProgress: 0,
    isShowProgress: false
};

/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  In the mutations part there is resetState(), 
  please new added variables add in that function as well
*/


export const statisticModule = {
    namespaced: true,
    state: InitialState,
    mutations: {
        setData(state, data) {
            for (const key in data) {
                state[key] = data[key];
            }
        },

        resetFilterModel(state) {
            state.filter = {}
            state.filter.page = 1
            state.filter.limit = 10
            state.filter.sortBy = "updatedAt:desc"
            state.filterData = {}
        },

        setChatData(state, data) {
            state.dataTable = data.results;
            state.currentPage = data.page;
            state.totalPages = data.totalPages;
            state.dataCount = data.totalResults;
        },
        setFilterPage(state, page) {
            state.filter.page = page;
            state.currentPage = page;
        },
        filterChat(state) {
            const filterObject = {}

            if (state.filterModel.search) {
                filterObject.search = state.filterModel.search
            }

            if (state.filterModel.date?.start && state.filterModel.date?.end) {
                filterObject.startDate = 
                    moment(state.filterModel.date.start).format('MM/DD/YYYY');
                filterObject.endDate = 
                    moment(state.filterModel.date.end).format('MM/DD/YYYY');
            }

            if (state.filterModel.allResellers) {
                filterObject.allResellers = state.filterModel.allResellers;
            }

            if (state.filterModel.own) {
                filterObject.own = state.filterModel.own;
            }

            if (state.filterModel.resellers.length) {
                filterObject.resellers = state.filterModel.resellers;
            }

            if (state.filterData?.sortBy) {
                filterObject.sortBy = state.filterData.sortBy
            }

            state.filterData = filterObject
        },

        setLimit(state, newValue) {
            state.filter.limit = newValue
        },

        sortColumn(state, sort = null) {
            if (sort?.sortValue) {
                state.filterData.sortBy = sort.sortKey + ":" + sort.sortValue;
            } else {
                if (!state.filterData?.sortBy) {
                    state.filterData.sortBy = "_id:desc";
                }
            }
        },

        setDefaultSettings(state, data) {
            if (data.defaultSettings) {
                state.defaultSettings = data.defaultSettings;
                state.isDefault = data.isDefault
            }
        },

        setDefaultSettingsForBack(state, data) {
            if (data.chatSettings) {
                state.isDefault = data.chatSettings.isDefault;
                state.defaultSettings = data.chatSettings.chatTable;
            }
        },

        resetState(state) {
            state.dataTable = []
            // filter data
            state.filter = {
                limit: 20,
                sortBy: "updatedAt:desc",
                page: 1,
            },
            state.filterData = {}
            state.filterModel = new ChatModel()
            //  for table pagination
            state.dataCount = 0
            state.currentPage = 1
            state.totalPages = 0
            // for  update  table  columns
            state.defaultSettings = []
            state.updateColumnsFromBack = "chatModule/updateChatSettings"
            state.updateColumnSettingsCommit = "chatModule/setDefaultSettings"
            state.isDefault = false
            state.chatResult = false
            state.isTableLoading = false
            // filter list by type
            state.listType = 'own'
            state.resellerActivity = false
            state.showColumnConfig = true
        }
    },
    actions: {
        getServiceStatisticInfo({ commit, state }) {
            commit("setData", { isTableLoading: true });

            const readOptions = {};
            return RequestService.read(`statistic/getServiceSubscriptoinInfo`, readOptions)
                // .then((response) => {
                //     if (!isExcelRequest) {
                //         if (response.data.results && response.data.results.length) {
                //             commit("setData", (state.chatResult = true));
                //         } else {
                //             commit("setData", (state.chatResult = false));
                //         }
                //         commit("setChatData", response.data);
                //         commit("setData", { isTableLoading: false });
                //     } else {
                //         FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
                //     }
                // })
                // .catch((e) => console.log("error ", e))
                // .finally(() => {
                //     commit("setData", { isTableLoading: false })
                // });
        },
        getTransactionInfo({ commit, state }) {
            commit("setData", { isTableLoading: true });

            const readOptions = {};
            return RequestService.read(`statistic/getTransactions`, readOptions)
                // .then((response) => {
                //     if (!isExcelRequest) {
                //         if (response.data.results && response.data.results.length) {
                //             commit("setData", (state.chatResult = true));
                //         } else {
                //             commit("setData", (state.chatResult = false));
                //         }
                //         commit("setChatData", response.data);
                //         commit("setData", { isTableLoading: false });
                //     } else {
                //         FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
                //     }
                // })
                // .catch((e) => console.log("error ", e))
                // .finally(() => {
                //     commit("setData", { isTableLoading: false })
                // });
        },

        getServiceTelegramInfo({ commit, state }) {
            commit("setData", { isTableLoading: true });

            const readOptions = {};
            return RequestService.read(`statistic/getServiceTelegramInfo`, readOptions)
                // .then((response) => {
                //     if (!isExcelRequest) {
                //         if (response.data.results && response.data.results.length) {
                //             commit("setData", (state.chatResult = true));
                //         } else {
                //             commit("setData", (state.chatResult = false));
                //         }
                //         commit("setChatData", response.data);
                //         commit("setData", { isTableLoading: false });
                //     } else {
                //         FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
                //     }
                // })
                // .catch((e) => console.log("error ", e))
                // .finally(() => {
                //     commit("setData", { isTableLoading: false })
                // });
        },
        recalculateInvoice({ commit, state }, incomingData) {
            commit("setData", { isTableLoading: true });

            const readOptions = {};
            return RequestService.update(`statistic/recalculateInvoice`, incomingData)
                // .then((response) => {
                //     if (!isExcelRequest) {
                //         if (response.data.results && response.data.results.length) {
                //             commit("setData", (state.chatResult = true));
                //         } else {
                //             commit("setData", (state.chatResult = false));
                //         }
                //         commit("setChatData", response.data);
                //         commit("setData", { isTableLoading: false });
                //     } else {
                //         FileDownload(response.data, "report.xlsx"); // TODO actual file name from response.headers
                //     }
                // })
                // .catch((e) => console.log("error ", e))
                // .finally(() => {
                //     commit("setData", { isTableLoading: false })
                // });
        },



        getChatSettings({ commit, state }) {
            return RequestService.read(`users/settings`).then(
              (res) => {
                const limitData = res?.data?.chatSettings?.limit ? res.data.chatSettings.limit : state.filter.limit
                commit("setDefaultSettingsForBack", res.data);
                commit('setLimit', limitData)
                return Promise.resolve(res.data);
              },
              (error) => {
                console.log("error ", error);
              }
            );
        },

        updateChatSettings({ state }, data) {
            return RequestService.update(`users/settings`, {
                chatSettings: {
                    chatTable: data,
                    isDefault: state.isDefault,
                    limit: state.filter.limit,
                },
            })
            .then((r) => {
                return Promise.resolve(r.data);
            })
            .catch((e) => console.log(e));
        },
        sendMessage({ state, commit }, { clientId, data }) {
            return RequestService.create(`chats/client/${clientId}`, {
               ...data
            })
            .then((response) => {
                commit("setData", { smsProgress: 100 })
                return Promise.resolve(response.data);
            })
            .catch((e) => console.log("error ", e))
        },
    }   
}

