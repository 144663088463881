<template>
  <div class="P-client-payments">
    <div class="G-page-component" :class="{ 
      'P-filter-open': isOpenFilter,
      'pa-0': $route.name === 'Search'
    }">
      <div :class="{ 'mb-0' : $route.name === 'Search' }" class="G-page-title-block G-flex G-justify-between G-align-center">
        <div class="G-component-title">
          <h3 :class="{ 'P-small-header': $route.name === 'Search' }" class="secondary--text">Transactions</h3>
        </div>
      </div>
      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <OttCheckbox
                :indeterminate="selectNotAll"
                :dataVal="selectAllList"
                :value="selectedArrowList.length ? true : false"
                tooltip="Select all visibles"
                @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
          </div>
          <div class="G-excel-btn">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="P-settings" v-bind="attrs" v-on="on" @click="exportData">
                  <span class="mdi mdi-file-excel neutral--text P-icon" />
                </div>
              </template>
              <span>Download Excel</span>
            </v-tooltip>
          </div>
          <OttGroupTableList
              v-if="showColumnConfig"
              :column-config="columnConfigData"
              :is-default="isDefault"
              :class="{'G-excel-btn': $route.name !== 'Search'}"
              @updateColumnsFromCheck="updateColumnsFromCheck"
              @resetDataSettingsTable="resetDataTableSettings"
              @hoverColumnConfig="hoverColumnConfig"
              @leaveColumnConfig="leaveColumnConfig"
              class="P-users-columns"
          />
        </div>
        <div v-if="$route.name !== 'Search'">
          <div
              class="G-filter-btn"
              @click="toggleOpenFilter"
              :class="{ 'P-filter-open': isOpenFilter }"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
                class="active-filter-count"
                v-if="filterModel.filterCount.length > 0"
            >
              <span>{{ filterModel.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div
        class="G-component-body page-background"
      >
        <div v-if="$route.name !== 'Search'" class="G-sub-block-title G-justify-between align-center">
          <h3 class="secondary--text">Transactions List</h3>

          <div class="G-align-center">
            <div class="G-total-sum G-flex-center px-3">
              Total Sum {{      
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                }).format(amountSum)
              }}
            </div>
          </div>
        </div>

        <OttGroupTable
            v-if="(!loading && $route.name !== 'Search') || $route.name === 'Search'"
            :column-config='columnConfigData'
            :data="dataTable"
            :fixedFirstAndLastColumn="true"
            @saveColumnChangesFromDrag="updateColumnsFromDrag"
            @updateColumns="updateColumns"
            @sortData="sortColumnData"
            @selectRow="selectRow"
            @selectAllVisibles="selectAllVisibles"
            :is-multi-select="true"  
            :selected-arrow-list="selectedArrowList"
        >
          <v-menu slot-scope="props" slot="action" left content-class="P-actions-list">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item 
                v-for="(action, key) in actionsList(props.rowData)"
                :key="key"
                @click="actionClick(action.value, props.rowData)">
                <div>
                  <v-list-item-title>{{ action.title }}</v-list-item-title>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </OttGroupTable> 

        <OttPaginationForTable
          v-if="(transactionsModule.transactionsResult && !loading && $route.name !== 'Search') ||
          (transactionsModule.transactionsResult && $route.name === 'Search')"
          v-model="rowsPerPage"
          :data-count="transactionsModule.dataCount"
          :available-data-count="dataTable.length"
          :page-limit="transactionsModule.totalPages"
          :current-page="transactionsModule.currentPage"
          @update="updateLimit"
          @updatePage="updateTransactionsList"

        />
        <NoDataInTable v-else />

      </div>
    </div>
    <div
        v-if="$route.name !== 'Search'"
        :class="{ 'P-filter-open': isOpenFilter }"
        class="G-filter-component page-background"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
            :click="resetData"
            class="primary-lg-btn"
            text="Reset Filters"
        >
        </ott-button>
      </div>
    </div>
    <PageLoader v-if="(transactionsModule.isTableLoading  || loading) && $route.name !== 'Search'"/>
    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :btnText="warningModalBtnText"
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="closeWarningModal" 
      @btnFunction="warningModalBtnFunc"
    >
      <div class="mb-58 send-select-cont" v-if="warningModalBtnText === 'Send'" slot="body">
        <br />
        <p class="mb-3 txt-14"><i>{{ warningModalSendLabel }}</i></p>
        <transition name="slide">
          <div v-show="sendType.split('/')[0] === 'phone' && (!validPhoneNumber || !isValidateNumber)" class="mb-3">
            <ott-phone-number
              v-model="sendPhoneNumber"
              :error="!validPhoneNumber || !isValidateNumber"
              @onInput="onInputPhoneNumber"
            />
          </div>
        </transition>

        <ott-select
          v-model="sendType"
          :items="transactionSendTypes"
          @emitFunc="sendTypeSelect"
          selected-text="name"
          selected-value="value"
          label="Send Type"
        />
      </div>
    </WarningModal>
  </div>
</template>

<script>

import TransactionsMixin from "./TransactionsMixin";
// components
import WarningModal from "@/components/WarningModal";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import NoDataInTable from "@/components/NoDataInTable";
import PageLoader from "@/components/pageLoader/PageLoader";
import FilterMixin from "@/mixins/FilterMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import CreditCardsMixin from "@/mixins/CreditCardsMixin";
import FilterData from "./components/FilterData";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttGroupTable from "@/components/OttGroupTable/OttGroupTable";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";

import OttGroupTableList from "@/components/OttGroupTable/OttGroupTanbleList";
import OttGroupTableForComponentsMixin from "@/components/OttGroupTable/OttGroupTableForComponentsMixin";

export default {
  components: {
    WarningModal,
    OttGroupTableList,
    OttGroupTable,
    OttSelect,
    OttRadio,
    OttSwitch,
    FilterData,
    PageLoader,
    NoDataInTable,
    OttPaginationForTable, OttUniversalTable, OttCheckbox, OttButton,
    OttPhoneNumber
  },
  mixins: [TransactionsMixin, OttGroupTableForComponentsMixin, FilterMixin, DateFormatMixin, CreditCardsMixin]
}
</script>

<style lang="scss" scoped>

@import "src/assets/scss/variables";

.P-users-columns {
  padding-left: 10px;
}

.P-filter-open {
  .mdi-menu-right {
    transform : rotate(180deg);
  }
}

.G-sub-block-title {
  padding-bottom : 22px;
  padding-left   : 24px;
  margin         : 0 -24px 22px;

  h3 {
    font-size      : $txt18;
    font-weight    : $semiBold;
    text-transform : capitalize;
  }

  border-bottom  : 1px solid $neutral-color45;
}


.P-client-payments {
  position : relative;
}

.approve-rej-icon-cont {
  font-size : 24px;

  .mdi {
    padding : 0 7px;
    display : inline-block;
  }

  .mdi-cancel,
  .mdi-check-circle-outline {
    border-radius : $border-radius;
  }
}

.approve-rej-icon-cont::v-deep {
  .v-tooltip__content {
    border-radius : 6px;
    padding       : 7px 9px;
    font-size     : $txt12;
  }
}

.select-all-menu-cont {
  .P-icon {
    font-size : 26px !important;
  }

  ::v-deep {
    .v-menu__content {
      max-width : 190px !important;
    }
  }
}

.select-all-menu-cont button .mdi-dots-vertical,
.settings-btn button .mdi-settings-outline {
  height        : 38px;
  font-size     : 24px;
  width         : 38px;
  border-radius : 4px;
}

.theme--light {
  .active-filter-count {
    background-color : #e5f5f6 !important;
    color            : $primary-color !important;
  }

  .action-cont-first-cont::v-deep {
    .v-icon {
      color : $neutral-color;
    }

    .mdi-minus-box {
      color : $primary-color !important;
    }
  }

  .select-all-menu-cont button[aria-expanded="true"] .mdi-dots-vertical,
  .settings-btn button[aria-expanded="true"] .mdi-settings-outline {
    background : $neutral-color15;
    color      : $secondary-color !important;
  }

  .select-all-menu-cont button .mdi-dots-vertical:hover,
  .settings-btn button .mdi-settings-outline:hover {
    background : $neutral-color15;
    color      : $secondary-color !important;
  }

  .icon-active {
    background    : $neutral-color;
    color         : $secondary-color !important;
    border-radius : $border-radius;
  }
}

.theme--dark {
  .action-cont-first-cont::v-deep {
    .v-icon {
      color : $neutral-colorDM !important;

      &.v-icon:after {
        opacity : 0;
      }
    }

    .mdi-minus-box {
      color : $primary-colorDM !important;
    }

    .primary--text {
      color : $primary-colorDM !important;
    }
  }

  .action-cont-first {
    border-right : 1.5px solid $borderDM;
  }
}

.G-table-actions {
  .v-menu__content {
    max-height : inherit !important;
  }
}

.v-menu__content.P-actions-list {
  max-height : inherit !important;
}

.G-excel-btn {
  border-left: unset;
  padding-left: unset;
}

.action-cont-first {
  display       : flex;
  border-right  : 1.5px solid $neutral-color;
  height        : 36px;
  align-items   : center;

  .v-input--selection-controls {
    margin-top : 23px;
  }
}

.P-icon {
  position   : relative;
  z-index    : 5;
  transition : $transition;
}

.P-client-payments::v-deep {

  .P-column {
    width : 100%;
  }

  .G-text-table {
    word-break : break-all;
  }

  .P-logins {
    p {
      & + p {
        margin-left : 6px;
        cursor      : pointer;
      }
    }


  }

  .P-checked {
    width      : 100%;
    color      : $green;
    font-size  : $txt23;
    text-align : center;
  }

  .P-payment-status {
    padding       : 1px 8px;
    border-radius : 30px;
    font-weight   : bold;
    display       : inline-block;

    &.P-canceled-status {
      background-color : $yellow-lighter;
      border           : 1px solid #FF7F2C;
      color            : #FF7F2C;

    }

    &.P-paid-status {
      background-color : $green-lighter;
      border           : 1px solid $green;
      color            : $green;
    }

    &.P-refunded-status {
      background-color : $purple-lighter;
      border           : 1px solid $purple-darker;
      color            : $purple-darker-1;
    }

    &.P-upcoming-status {
      background-color : $yellow-lighter;
      border           : 1px solid $orange;
      color            : $orange;
    }

    &.P-unpaid-status {
      background-color : $red-lighter;
      border           : 1px solid $danger-new;
      color            : $danger-new;
    }

    &.P-declined-status {
      background-color : $light-blue-lighter;
      border           : 1px solid $light-blue-darker;
      color            : $light-blue-darker;
    }
  }
}

.P-settings {
  .P-icon {
    font-size : 22px;
  }
}

.P-bills-switch {
  margin       : 0 15px;
  padding      : 0 15px;
  border-left  : 1.5px solid $neutral-color;
  border-right : 1.5px solid $neutral-color;


  &::v-deep {
    .P-text {
      white-space : nowrap;
    }

    .v-input {
      width      : max-content !important;
      margin-top : 0;
      padding    : 10px 0;
    }
  }
}

.P-radio {
  margin-top : 0;
  padding    : 10px 0;
}

.P-radio-box + .P-radio-box {
  margin-left : 15px;
}

.P-small-header {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.v-menu__content.P-actions-list {
  max-height: inherit!important;
}

.mb-58 {
  margin-bottom: 58px;
}

.txt-14 {
  font-size: $txt15;
}

::v-deep {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  .v-text-field__details {
    display: none;
  }

  .v-input--dense>.v-input__control>.v-input__slot {
    margin-bottom: 0;
  }
}
</style>
