<template>
  <div class="auth-header">
    <div
      class="main-logo"
      :style="{
        backgroundImage:
          `url(${require('@/assets/images/main/logo-main.svg')})`
      }"
    />
  </div>
</template>

<script>
export default {
  name: "authHeader"
};
</script>

<style scoped lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";

.auth-header {
  width: 100%;
  padding: 63px 0 45px 0;
  .main-logo {
    width: 147px;
    height: 92px;
    margin: 0 auto;
  }
}
</style>
