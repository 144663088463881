import RequestService from "@/services/request.service";

const InitialState = {
  addressTypeList: {
    company: "company",
    personal: "personal"
  },

  addressesList: [],
  addressesLists: [],
  billingAddresses: [],
  invoiceAddresses: [],
  fullAddressList: [],
  isAddMode: true,
  isEditMode: false,
  editedIndex: null,
  formData: {
    company: true,
    companyName: "",
    firstname: "",
    lastname: "",
    phoneNumber: "",
    phone: {},
    officeName: "",
    address: "",
    country: "",
    city: "",
    state: "",
    unit: "",
    zip: "",
    isForShipping: false,
    isBilling: false,
    inUse: false,
    isMain: false,
    forInvoice: false,
    isWarehouse: false,
    acceptSelfPickup: false,
    acceptCurrierPickup: false,
    name: ""
  },

  addFormData:null,
  countryCode: "",
  addressType: "company",
  cloneFormData: null,

  error:false,
  errorMessage:'',
};

export const companyAddresses = {
  namespaced: true,
  actions: {
    createAddressList({ commit, rootState, state, dispatch }, providerId) {
      return RequestService.update(
        `ottProviders/edit/companyAddress/${providerId}`,
        state.addressesList
      ).then(
        async data => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    manageCompanyAddress({state, rootState, dispatch},id){
      if (state.addFormData) {
        delete state.addFormData.createdAt
      }
      return RequestService.update(
        `ottProviders/addresses/edit/${id}`,
        state.addFormData
      ).then(
        async data => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    addCompanyAddress({ state, rootState, dispatch, commit }){
      if (state.addFormData) {
        delete state.addFormData.createdAt
      }
      return RequestService.create(
        `ottProviders/addresses`, state.addFormData
      ).then(
       async data => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve(data.data);
        },
        error => {
          return Promise.reject(error);
        }
      )
    },
    deleteCompanyAddress({rootState, dispatch}, id){
      return RequestService.delete(
        `ottProviders/addresses/edit/${id}`
      ).then(
        async data => {
          await dispatch('addProviderModal/getProvidersList', rootState.addProviderModal.filter, {root:true})
          return Promise.resolve();
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    getCompanyAddresses({commit}, providerId){
      return RequestService.read(
        `ottProviders/addresses/${providerId}`
      ).then(
        response => {
          let billingAddresses = [], 
              address = '', 
              invoiceAddresses = [],
              fullAddressList = [],
              addressesList = [];
  
          const createAddress = (item) => {
            address = '';
            if (item.address) {
              address = address + item.address;
            }
            if (item.unit) {
              address = address + ' ' + item.unit;
            }
            if (item.city) {
              address = address + ' ' + item.city;
            }
            if (item.state) {
              address = address + ' ' + item.state;
            }
            if (item.zip) {
              address = address + ' ' + item.zip;
            }
            if (item.country && item.country !== 'US') {
              address = address + ' ' + item.country
            }
          }
  
          if (response.data.length) {
            if (response.data.filter(x => x.isBilling).length) {
              billingAddresses = response.data.filter(x => x.isBilling);
            }

            if (response.data.filter(x => x.forInvoice).length) {
              invoiceAddresses = response.data.filter(x => x.forInvoice);
            }

            response.data.forEach((item, i) => {
              delete item.createdAt
              delete item.updatedAt

              fullAddressList.push({
                name: address,
                value: item
              })

              addressesList.push({
                name: address,
                value: item.id
              })
            })
          }

          if (billingAddresses.length) {
            billingAddresses.forEach((item, i) => {
              createAddress(item)
              billingAddresses[i] = {
                name: address,
                value : item.id
              }
            })
          }

          if (invoiceAddresses.length) {
            commit('setData', { invoiceAddresses: [] })

            invoiceAddresses.forEach((item, i) => {
              createAddress(item)
              invoiceAddresses[i] = { 
                name: address,
                value: item.id
              }
            })
          }
         
          commit('setData', { billingAddresses, invoiceAddresses, fullAddressList, addressesList })

          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    }

  },
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },

    checkIsMainAddress(state, data) {
      if (data.inMain) {
        state.addressesList.map(item => {
          return (item.inMain = item.emailValue === data.emailValue);
        });
      }
    },

    // update Address from create

    updateAddressList(state, data) {
      state.addressesList = data.map((item, index) => {
        item.phoneNumber = item.phone.number;
        item.inUse = !item.inUse
        let address = '';
        if (item.address) {
          address = address + item.address;
        }
        if (item.unit) {
          address = address + ' ' + item.unit;
        }
        if (item.city) {
          address = address + '<br />' + item.city;
        }
        if (item.state) {
          address = address + ' ' + item.state;
        }
        if (item.zip) {
          address = address + ' ' + item.zip;
        }
        if (item.country && item.country !== 'US') {
          address = address + ' ' + item.country
        }

        item.name = address;
        item.value = item.id;
        return item;
      });
    },

    // Add new address
    addNewAddress(state, data) {
      const body = {}
      state.addFormData = JSON.parse((JSON.stringify(data.data)))
      state.addFormData.phone = { number: data.data.phoneNumber, countryCode: state.countryCode };

      if (state.addressType === state.addressTypeList.company) {
       delete state.addFormData.firstname ;
        delete state.addFormData.lastname;
      } else {
       delete state.addFormData.companyName;
      }
      state.addFormData.inUse = !data.data.inUse;
      if(data.isEditMode){
        delete  state.addFormData.providerId
      }else{
        state.addFormData.providerId = data.providerId;
      }

      state.addFormData.isWarehouse = data.data.isWarehouse? data.data.isWarehouse: false;

      delete state.addFormData.phoneNumber
      delete state.addFormData.name
      delete state.addFormData.value
      delete state.addFormData.id
      delete state.addFormData.updatedAt
      delete state.addFormData.createdAt
      state.addFormData.company = state.addressType === state.addressTypeList.company
      state.addFormData.inUse = !JSON.parse((JSON.stringify(data.data.inUse)))
      // state.addressesList.push(data);
      state.isAddMode = false;
      state.isEditMode = false;

    },

    // Open add new address
    openAddNewAddress(state) {
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
      state.isAddMode = true;
      state.cloneFormData = null;
      state.addressType = state.addressTypeList.company;
    },

    //delete address from list
    deleteAddress(state, index) {
      state.isEditMode = false;
      state.isAddMode = false;
      state.cloneFormData = null;
      state.editedIndex = null;
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
    },

    editAddress(state, data) {
      state.formData = data.data;
      state.editedIndex = data.index;
      if (data.data.company) {
        state.addressType = state.addressTypeList.company;
      } else {
        state.addressType = state.addressTypeList.personal;
      }
      state.isEditMode = true;
      state.isAddMode = true;
      state.cloneFormData = JSON.parse(JSON.stringify(state.formData));
    },

    // check is main address


    // save edited address
    saveEditData(state, data) {
      state.isEditMode = false;
      state.isAddMode = false;
      state.addressesList.map((item, index) => {
        if (index === state.editedIndex) {
          if (state.addressType === state.addressTypeList.company) {
            data.firstname = "";
            data.lastname = "";
          } else {
            data.companyName = "";
          }
          item = data;
        }
      });
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
    },
    closeAddAddress(state) {
      state.isEditMode = false;
      state.isAddMode = false;
      state.cloneFormData = null;
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
      state.editedIndex = null;
    },
    resetAllData(state) {
      state.addressesList = [];
      state.isAddMode = false;
      state.isEditMode = false;
      state.error = false;
      state.editedIndex = null;
      state.errorMessage = "";
      state.countryCode = "";
      state.addressType = "company";
      state.cloneFormData = null;
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
    },
    // reset Edited Data Address
    resetEditedAddress(state) {
      state.isEditMode = false;
      state.isAddMode = false;
      state.formData = JSON.parse(JSON.stringify(state.cloneFormData));
      state.addressesList = state.addressesList.map((item, index) => {
        if (index === state.editedIndex) {
          if (state.addressType === state.addressTypeList.company) {
            state.formData.firstname = "";
            state.formData.lastname = "";
          } else {
            state.formData.companyName = "";
          }
          item = state.formData;
        }
        return item;
      });
      state.formData = JSON.parse(JSON.stringify(InitialState.formData));
    },

    // duplicate error data
    setError(state, data){
      state.error = data.error;
      state.errorMessage = data.errorMessage
    },

    //  Set address from data

    /***
    * for all second level object keys changing
    *  setDataSecondLevels({ 
    *    stateKey1: { changingKey: 'newVal' },
    *    stateKey2: { changingKey2: 'newVal' },  
    *  })
    **/
    setDataSecondLevels(state, data) {
      for (let firstLevel in data) {
        for (let secondLevel in data[firstLevel]) {
          if(state[firstLevel][secondLevel] !== undefined) {
            state[firstLevel][secondLevel] = data[firstLevel][secondLevel]
          }
        }
      }
    },

    setCountryCode(state, value) {
      if (value) {
        state.countryCode = value.toLowerCase();
      }
    },

    setCompanyName(state, value) {
      state.formData.companyName = value;
    },
    setFirstName(state, value) {
      state.formData.firstname = value;
    },
    setLastName(state, value) {
      state.formData.lastname = value;
    },
    setAddressType(state, value) {
      state.addressType = value;
    },
    setPhoneNumber(state, value) {
      state.formData.phoneNumber = value;
    },
    setOfficeName(state, value) {
      state.formData.officeName = value;
    },
    setAddressName(state, value) {
      state.formData.address = value;
    },
    setCountry(state, value) {
      state.formData.country = value;
    },
    setCity(state, value) {
      state.formData.city = value;
    },
    setState(state, value) {
      state.formData.state = value;
    },
    setUnit(state, value) {
      state.formData.unit = value;
    },
    setZip(state, value) {
      state.formData.zip = value;
    },
    setIsForShipping(state, value) {
      state.formData.isForShipping = value;
    },
    setIsBilling(state, value) {
      state.formData.isBilling = value;
    },
    setInUse(state, value) {
      state.formData.inUse = value;
    },
    setIsMain(state, value) {
      state.formData.isMain = value;
    },
    setForInvoice(state, value) {
      state.formData.forInvoice = value;
    },
    setIsWarehouse(state, value) {
      state.formData.isWarehouse = value;
    },
    setAcceptSelfPickup(state, value) {
      state.formData.acceptSelfPickup = value;
    },
    setAcceptCourierPickup(state, value) {
      state.formData.acceptCurrierPickup = value;
    },
    setAddressFullName(state, value) {
      state.formData.name = value;
    },
    setCompanyAddressIsEdit(state, value) {
      state.isEditMode = value
    },
    setCompanyAddressIsAdd(state, value) {
      state.isAddMode = value
    },
    resetState(state) {
      for(let key in state) {
        state[key] = JSON.parse(JSON.stringify(InitialState[key]))       
      }
    },
  }
};
