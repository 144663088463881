<template>
  <div class="auth-cont create-new-cont">
    <div>
      <auth-header />
      <div class="auth-body">
        <h5 class="title">Create new password </h5>
        <password-strength
          @emitFunc="validationFunc('password', $event)"
          :error="$v.form.password.$error"
          label="New password"
        />
        <div class="info-txt">
          <span class="mdi mdi-information-outline " />
          <p>
            The password must contain at least 8 characters, 1 letter and 1
            number
          </p>
        </div>
        <ott-input
          v-model="form.confirmPassword"
          :error="$v.form.confirmPassword.$error"
          type="password"
          label="Confirm password"
        />

        <OttButtonLoader
          @onClick="createNewPassword"
          :is-loading="isLoading"
          button-text="Save changes"
          className="primary-lg-btn"
        />
      </div>
    </div>
    <auth-footer />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required, minLength, helpers, sameAs } from "vuelidate/lib/validators";
import AuthFooter from "./components/authFooter";
import AuthHeader from "./components/authHeader";
import PasswordInput from "@/components/PasswordInput";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import PasswordStrength from "@/components/PasswordStrength";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
const letterAndNumber = helpers.regex(
  "letterAndNumber",
  /(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/
);
export default {
  name: "CreateNewPassword",
  components: {
    OttButtonLoader,
    PasswordStrength,
    OttButton,
    OttInput,
    PasswordInput,
    AuthHeader,
    AuthFooter
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        letterAndNumber
      },
      confirmPassword: { required, sameAsPassword: sameAs("password") }
    }
  },
  data() {
    return {
      form: {
        password: "",
        confirmPassword: ""
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.appModule.isLoading
    })
  },
  methods: {
    ...mapActions({
      passwordReset: "auth/passwordReset"
    }),

    validationFunc() {
      const [filedName, value] = arguments;
      this.form[filedName] = value;
      this.$v.form[filedName].$touch();
    },
    async createNewPassword() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        if (this.$route.query.token) {
          const body = {
            password: this.form.password,
            confirmPassword: this.form.confirmPassword,
            token: this.$route.query.token
          };
          await this.passwordReset(body)
            .then(
              () => {
                this.$router.push("/auth/sign-in").catch(e => {
                  console.log(e);
                });
              },
              error => {
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
              }
            )
            .catch(e => {
              console.log(e);
            });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/authGeneral";
.info-txt {
  display: flex;
  color: $neutral-color;
  font-size: $txt14;
  font-weight: $regular;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
  span {
    font-size: 20px;
    margin-right: 10px;
  }
  p {
    margin-bottom: 0;
  }
}
.create-new-cont::v-deep {
  .Ott-button-cont {
    margin-top: 10px;
    width: 100%;
  }
  .title {
    margin-bottom: 30px;
  }
}
</style>
