import RequestService from "@/services/request.service";

const InitialState = {
  isShowModal: false
}

export const botUserModule = {
  namespaced: true,
  state: JSON.parse(JSON.stringify(InitialState)),
  mutations: {
    setData(state, data) {
      for (const key in data) {
        state[key] = data[key];
      }
    },
  },
  actions: {
    generatePasswordTG({ commit, state }, { id, data }) {
      return RequestService.update(`users/telegram-generate-password/${id}`, { ...data })
        .then(async response => {
          commit('users/setUserData', response.data.user, { root: true })
          return Promise.resolve(response.data)
        })
        .catch(e => console.log('error', e))
    },
    sendPasswordTG({ commit, state }, { id, data }) {
      return RequestService.create(`users/telegram-send-password/${id}`, { ...data })
        .then(async response => {
          commit('setData', { isShowModal: false })
          return Promise.resolve(response.data)
        })
        .catch(e => console.log('error', e))
    }
  }
}