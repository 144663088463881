<template>
  <div>
    <div class="modal-body">
      <div class="G-badge G-pending-badge pending" v-if="reviewTable.rowData.state === 0">
        Pending
      </div>
      <div class="G-badge G-reject-badge reject" v-else>
        Reject
      </div>
      <h6 class="secondary--text">Personal information</h6>
      <div class="desc-row">
        <span class="neutral--text">Full Name</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.user.firstname }} {{ reviewTable.rowData.user.lastname }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Email Address</span>
        <p class="secondary--text">{{ reviewTable.rowData.user.email }}</p>
      </div>
      <h6 class="secondary--text">Company information</h6>
      <div class="desc-row">
        <span class="neutral--text">Company name</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.name[0].name || "-" }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Description</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.description || "-" }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Email Address</span>

        <p class="secondary--text">
          {{ reviewTable.rowData.emails[0].address || "-" }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Webpage</span>
        <a
          :href="createWebSite(reviewTable.rowData.website)"
          target="_blank"
          class="primary--text"
        >
          {{ reviewTable.rowData.website || "-" }}
        </a>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Phone</span>
        <p class="secondary--text">{{ reviewTable.rowData.user.phone.phoneNumber || "-" }}</p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Clients</span>
        <p class="secondary--text">
          {{ getClientCountText(reviewTable.rowData.clientAmount) || "-" }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Channels</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.channelAmount || "-" }}
        </p>
      </div>

      <h6 class="secondary--text">GeoIP Info</h6>
      <div class="desc-row">
        <span class="neutral--text">Country code</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.user.geoInfo.countryCode || "-" }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Location</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.user.geoInfo.location || "-" }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">IP Address</span>
        <p class="secondary--text">{{ reviewTable.rowData.user.geoInfo.realIp || "-" }}</p>
      </div>

      <div class="desc-row">
        <span class="neutral--text">Network</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.user.geoInfo.network || "-" }}
        </p>
      </div>

      <div class="desc-row">
        <span class="neutral--text">Postal code</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.user.geoInfo.postalCode || "-" }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">ISP</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.user.geoInfo.isp || "-" }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Organization</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.user.geoInfo.organization || "-" }}
        </p>
      </div>
      <div class="desc-row">
        <span class="neutral--text">Domain</span>
        {{ reviewTable.rowData.user.geoInfo.domain || "-" }}
      </div>

      <h6 class="secondary--text">Registration time</h6>
      <div class="desc-row">
        <span class="neutral--text">Date/Time</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.createdAt || "-" }}
        </p>
      </div>
      <h6 class="secondary--text">Login Attempts after Registration</h6>
      <div class="desc-row">
        <span class="neutral--text">Login Attempt</span>
        <p class="secondary--text">
          {{ reviewTable.rowData.user.loginAttempt }}
        </p>
      </div>
    </div>
    <div class="footer-cont">
      <ott-button
        :click="openApprove"
        class="neutral-sm-btn"
        custom-class="approve"
        icon-before
        text="Approve"
        icon-before-class="mdi mdi-check-circle-outline"
      />
      <ott-button
        :click="openReject"
        class="neutral-sm-btn"
        icon-before
        custom-class="reject-btn"
        text="Reject"
        icon-before-class="mdi mdi-cancel"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";

export default {
  name: "ReviewDetail",
  components: { OttButton, OttDialog },
  computed: {
    ...mapState({
      reviewTable: state => state.review,
      rowData: state => state.review.rowData,
      clientsCount: state => state.review.clientsCount
    })
  },

  methods: {
    ...mapMutations({
      setDatas: "review/setData",
      setApproveRejectID: "review/setApproveRejectID",
      openModal: "review/openModal",
    }),

    getClientCountText(clientNumber) {
      return this.clientsCount.filter((x, index) => {
        if (index === clientNumber) {
          return x;
        }
      })[0].value;
    },

    openApprove() {
      this.setApproveRejectID([
        { id: this.reviewTable.rowData.id, state: 1 }
      ]);
      this.setDatas({
        warningModalBtnText: "Approve",
        warningModalIsDangerButton: false,
        isMultipleAction: false    
      })
      this.openModal("warningModal");
    },

    openReject() {
      this.setApproveRejectID([
        { id: this.reviewTable.rowData.id, state: 2 }
      ]);
      this.setDatas({
        warningModalBtnText: "Reject",
        warningModalIsDangerButton: true,
        isMultipleAction: false  
      })
      this.openModal("warningModal");
    },

    createWebSite(webSite) {
      let webSiteLInk = webSite;
      if (
        webSite.indexOf("http://") === -1 &&
        webSite.indexOf("https://") === -1
      ) {
        if (webSite.indexOf("http://") === -1) {
          webSiteLInk = "http://" + webSite;
        }
        if (webSite.indexOf("https://") === -1) {
          webSiteLInk = "https://" + webSite;
        }
      }
      return webSiteLInk;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";
h6 {
  font-size: $txt16;
  font-weight: $semiBold;
  margin-bottom: 22px;
  text-transform: capitalize;
}
.modal-body {
  padding: 24px;
}

@media (max-width: 1280px) {
  .modal-body {
    max-height: 600px;
    overflow-y: auto;
  }

  .modal-body::-webkit-scrollbar {
    width: 7px;
  }
}

.desc-row {
  display: flex;
  margin-bottom: 14px;
  align-items: center;
  p,
  a {
    margin-bottom: 0;
    font-weight: $regular;
    font-size: $txt14;
  }
  span {
    width: 170px;
    min-width: 170px;
    font-weight: $semiBold;
    font-size: $txt12;
    text-transform: capitalize;
  }
}
.footer-cont {
  display: flex;
  justify-content: flex-end;
  padding: 24px !important;
  border-top: 1px solid $neutral-color45;
}
.approve {
  margin-right: 24px;
}
.theme--light {
  .pending {
    border: 1px solid $yellow-darker;
    color: $orange;
    background-color: $yellow-lighter;
  }
  .reject {
    border: 1px solid $red-darker;
    color: $danger-color-dark;
    background-color: $red-lighter;
  }
}
.theme--dark {
  .pending {
    border: 1px solid $orange;
    color: $white;
    background-color: $orange;
  }
  .reject {
    color: $white;
    border: 1px solid $redDM;
    background-color: $redDM;
  }
}
.pending,
.reject {
  position: absolute;
  right: 24px;
}

.approve::v-deep .theme--dark:hover {
  button {
    background-color: $greenDM !important;
  }
}
.reject-btn::v-deep .theme--dark:hover {
  button {
    background-color: $redDM !important;
  }
}
.approve::v-deep {
  button:hover {
    background-color: $greenDM !important;
  }
}
.reject-btn::v-deep {
  button:hover {
    background-color: $redDM !important;
  }
}
</style>
