<template>
  <div class="auth-footer">
    <div
      class="footer-logo"
      :style="{
        backgroundImage:
          `url(${require('@/assets/images/main/short-logo-netral.svg')})`
      }"
    />
    <p>
      OTT Stream is a place, where a group of professionals will help the
      providers to grow and gain more knowledge.
    </p>
  </div>
</template>

<script>
export default {
  name: "authFooter"
};
</script>

<style scoped lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";

.auth-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0 50px;
  position: relative;
  border-top: 1px solid $neutral-color45;
  .footer-logo {
    width: 45px;
    height: 65px;
    padding: 15px 0 20px;
    margin-top: 15px;
  }

  p {
    text-align: center;
    font-size: $txt14;
    color: $secondary-color;
    font-weight: $regular;
  }
}
</style>
