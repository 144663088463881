<template>
    <div class="P-no-data-wrapper G-align-center G-justify-center G-flex-column mt-6">
        <div class="P-icon-block G-align-center G-justify-center">
            <img src="@/assets/svgs/NoDataIcon.svg" width="55" />
        </div>
        <p class="P-text">No data available in table</p>
    </div>
</template>

<script>
    export default {
        name: "NoDataInTable"
    }
</script>

<style lang="scss" scoped>
    @import "src/assets/scss/variables";

    .P-no-data-wrapper {
        height: calc(100% - 100px);
        .P-icon-block {
            width: 120px;
            height: 120px;
            background: #F6F6F9 0 0 no-repeat padding-box;
            border-radius: 50%;
        }
        .P-text {
            margin: 32px 0 0;
            font-size: $txt16;
            font-weight: $semiBold;
            color: #A5AAC6;
        }
    }
</style>